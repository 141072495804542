import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";

import { PRIMARY_300, PRIMARY_50, PRIMARY_600, PRIMARY_700 } from "../../../../constant/colors";
import { Typography } from "antd";
import { sizes } from "../../../../constant/styles";

const { Text } = Typography;

export const InfoNotice = ({ text, fontSize, customStyle = {}, additionalData }) => {
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: PRIMARY_50,
        padding: "10px 0px",
        borderRadius: 8,
        border: `1px solid ${PRIMARY_300}`
      }}
    >
      <InfoCircleOutlined style={{ color: PRIMARY_600, fontSize: 20, padding: "0px 13px 0px 10px" }} />
      <div style={{ maxWidth: 700, ...customStyle }}>
        <Text style={{ color: PRIMARY_700, fontSize: fontSize || sizes.small }}>{text}</Text>
        {additionalData}
      </div>
    </div>
  );
};
