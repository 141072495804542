import React from "react";
import styled, { isStyledComponent, css } from "styled-components";

/* Some components have "portaled" bits, such as dropdown menus, that aren't
 * children of the React component in the DOM, and thus won't inherit styles
 * applied to the parent component. Antd provides callbacks to generate the
 * dropdown node, so we use that to wrap the dropdown in div with styles
 * applied. We can't just use dropdownStyles because we want to use nested
 * styles. We could use createGlobalStyle and assign it a class name and then
 * use dropdownClassName, but this accomplishes essentially the same thing
 * without having to make up our own class name.
 *
 * If you are not interpolating with functions you can just pass a plain format
 * string literal, for functions you will need to use the `css` helper,
 * re-exported from this module for convenience:
 * https://styled-components.com/docs/api#css
 *
 * Example:
 *
 * const wrapDropdown = makeStyleWrapper(`
 *   color: blue
 * `);
 *
 * const wrapDropdownFromParams = makeStyleWrapper(css`
 *   color: ${props => props.color}
 * `);
 *
 * <Select dropdownRender={wrapDropdown}/>
 * <Select dropdownRender={wrapDropdownFromParams}/>
 * */
export function makeStyleWrapper(styles) {
  const Wrapper = isStyledComponent(styles)
    ? styles
    : styled.div`
        ${styles}
      `;
  return (reactNode) => {
    return <Wrapper>{reactNode}</Wrapper>;
  };
}

export { css };
