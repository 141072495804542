import { Typography, Button, Select, Form } from "antd";

import {
  GRAY_700,
  GRAY_100,
  PRIMARY_50,
  PRIMARY_850,
  PRIMARY_600,
  PRIMARY_800,
  GRAY_500,
  ERROR_700,
  SUCCESS_700,
  SUCCESS_600,
  SUCCESS_50,
  ERROR_50,
  PRIMARY_400,
  GRAY_300,
  GRAY_900,
  WARNING_600,
  WARNING_50,
  BLACK
} from "../../../../constant/colors";
import styled, { css } from "styled-components";
import { font14pxB } from "../../../../component/Typography";
import { fontWeights, sizes } from "../../../../constant/styles";
import { PAID, DENIED, SUBMITTED, NEW_PENDING_PAYMENT } from "../../../../constant/claim";

const { Text } = Typography;

export const DateLabelStyle = styled(Text)`
  ${font14pxB}
  color: ${GRAY_700};
  background: ${GRAY_100};
  border-radius: 16px;
  padding: 2px 8px;
`;

export const OutlinedButton = styled(Button)`
  background: ${PRIMARY_50};
  border: 2px solid ${PRIMARY_850};
  border-radius: 4px;
  color: ${PRIMARY_600};
  font-style: normal;
  font-weight: ${fontWeights.semibold};
  font-size: ${sizes.medium};
  line-height: 5px !important;

  :hover {
    background: ${PRIMARY_50};
    border: 2px solid ${PRIMARY_800};
    border-radius: 4px;
    color: ${PRIMARY_800};
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.medium};
  }

  :active {
    background: ${PRIMARY_50};
    border: 2px solid ${PRIMARY_600};
    border-radius: 4px;
    color: ${PRIMARY_600};
    box-shadow: 0px 0px 0px 4px ${PRIMARY_400} !important;
    padding-top: 3px !important;
    line-height: 20px !important;
  }

  :disabled {
    background: ${PRIMARY_50};
    opacity: 0.3;
    border: 2px solid ${PRIMARY_850};
    border-radius: 4px;
    color: ${PRIMARY_600};
  }
`;

export const CustomSelect = styled(Select)`
  &.ant-select-single {
    font-size: ${sizes.medium};
    line-height: 20px;
    color: ${GRAY_700};

    ${({ theme }) =>
      theme &&
      css`
        color: ${theme === DENIED
          ? ERROR_700
          : theme === PAID
          ? SUCCESS_600
          : theme === SUBMITTED
          ? PRIMARY_600
          : theme === NEW_PENDING_PAYMENT
          ? WARNING_600
          : GRAY_700} !important;
      `}

    .ant-select-selection-item {
      font-weight: ${fontWeights.bold};
      pointer-events: none;
    }
    .ant-select-selector {
      border-radius: 16px;
      border: 0px;
      background: ${GRAY_100};

      ${({ theme }) =>
        theme &&
        css`
          background: ${theme === DENIED
            ? ERROR_50
            : theme === PAID
            ? SUCCESS_50
            : theme === SUBMITTED
            ? PRIMARY_50
            : theme === NEW_PENDING_PAYMENT
            ? WARNING_50
            : GRAY_100};
        `}
    }

    .ant-select-arrow .anticon:not(.ant-select-suffix) {
      pointer-events: none;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: transparent;
    box-shadow: 000 0px transparent;
  }

  .ant-select-item-option-content > span {
    padding: 8px 10px;
  }

  .anticon > svg {
    color: ${GRAY_700};

    ${({ theme }) =>
      theme &&
      css`
        color: ${theme === DENIED
          ? ERROR_700
          : theme === PAID
          ? SUCCESS_700
          : theme === SUBMITTED
          ? PRIMARY_600
          : theme === NEW_PENDING_PAYMENT
          ? WARNING_600
          : GRAY_700};
      `}
  }

  .ant-select-selection-placeholder {
    color: ${GRAY_500};
    font-weight: ${fontWeights.regular};
    font-size: ${sizes.medium};
    line-height: 20px;
  }
`;

export const StyledGreenValue = styled(Text)`
  color: ${(props) => (props.disableStyle ? BLACK : SUCCESS_700)};
  font-weight: ${(props) => (props.disableStyle ? fontWeights.regular : fontWeights.bold)};
  font-size: ${sizes.medium};
  line-height: 20px;
  background: ${(props) => (props.disableStyle ? "transparent" : SUCCESS_50)};
  border-radius: 16px;
  padding: 2px 8px;
  opacity: ${(props) => props.isActive === false && 0.3};
`;

export const StyledRedValue = styled(Text)`
  color: ${(props) => (props.disableStyle ? BLACK : ERROR_700)};
  font-weight: ${(props) => (props.disableStyle ? fontWeights.regular : fontWeights.bold)};
  font-size: ${sizes.medium};
  line-height: 20px;
  background-color: ${(props) => (props.disableStyle ? "transparent" : ERROR_50)};
  border-radius: 16px;
  padding: 2px 8px;
`;

export const StyledBlueValue = styled(Text)`
  color: ${(props) => (props.disableStyle ? BLACK : PRIMARY_600)};
  font-weight: ${(props) => (props.disableStyle ? fontWeights.regular : fontWeights.bold)};
  font-size: ${sizes.medium};
  line-height: 20px;
  background-color: ${(props) => (props.disableStyle ? "transparent" : SUCCESS_50)};
  border-radius: 16px;
  padding: 2px 8px;
  opacity: ${(props) => props.isActive === false && 0.3};
`;

export const StyledSectionTitle = styled(Text)`
  color: ${GRAY_700};
  font-weight: ${fontWeights.semibold};
  font-size: ${sizes.medium};
  line-height: 20px;
`;

export const StyledTitleWithLine = styled(Text)`
  display: flex;
  flex-direction: row;
  color: ${GRAY_500};
  font-weight: ${fontWeights.regular};
  font-size: ${sizes.medium};
  margin-top: 10px;

  :after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid ${GRAY_300};
    margin: auto;
  }
  :after {
    margin-left: 13px;
  }
`;

export const StyledFormItemLable = styled(Form.Item)`
  margin: 0;
  .ant-form-item-label > label {
    color: ${GRAY_700};
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.medium};
    line-height: 20px;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "";
    margin-right: 0px;
  }
`;

export const FormItemHorizontal = styled(Form.Item)`
  margin: 0;
  flex-direction: row !important;

  .ant-form-item-label > label {
    color: ${GRAY_900};
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.medium};
    line-height: 20px;
    margin-right: 10px;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "";
    margin-right: 0px;
  }
`;

export const FontBold = styled(Text)`
  font-weight: ${fontWeights.bold};
`;
