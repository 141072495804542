import React from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { font18pxSB } from "../../Typography";

//TODO: Replace with popconfirm or confirm? Unify with CustomPopupModal? This
//file shouldn't exist, it's just a band-aid for existing duplication
const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 520px;
    height: 280px;

    background: #ffffff !important;
    box-shadow: 0px 19px 76px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    .ant-modal-header {
      background: #ffffff !important;
      border-radius: 12px;
      border: unset;
    }
    .ant-modal-body {
      padding: 0 24px;
      height: 127px;
    }
    .ant-modal-footer {
      padding: 0 24px 16px;
      border-top: unset;
      button {
        height: 44px;
      }
    }
  }
`;

const DialogTitle = styled.p`
  ${font18pxSB}
`;

export default ({ title, icon, children, ...props }) => {
  return (
    <StyledModal destroyOnClose={true} closable={false} title={icon} {...props}>
      <DialogTitle>{title}</DialogTitle>
      {children}
    </StyledModal>
  );
};
