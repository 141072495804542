import React from "react";
import { selectSelectedView, selectFiltersModified } from "../../store/selector";
import { connect } from "react-redux";
import ACTIONS from "../../store/action";
import { withRouter } from "react-router-dom";
import { defaultFilters } from "../../constant/filters";
import { selectedViews } from "../../constant/table";

import "./DashboardSummaryBox.less";
import { createStructuredSelector } from "reselect";

const DashboardSummaryBoxComponent = ({
  boxData: { Icon, title, id: targetView },
  counter,
  selectedView,
  clearSelectedView,
  setSelectedView,
  history,
  clearFiltersInputs,
  setFiltersAct,
  filtersModified,
  clearSavedListRelatedDataAct
}) => {
  const handleViewChange = () => {
    setSelectedView(targetView);
    history.push({ pathname: "/", search: `?selectedView=${targetView}` });

    const shouldRefetchData = selectedView && filtersModified;

    if (shouldRefetchData) {
      clearFiltersInputs();
      if (selectedView !== selectedViews.savedList) {
        // Note: Rest the previous table view data.
        setFiltersAct(defaultFilters, selectedView);
      } else {
        // Note: fetch the data for the target view, when user is currently at the saved-list view page.
        setFiltersAct(defaultFilters, targetView);
      }
    }
  };

  const handleViewToggle = () => {
    clearSelectedView();
    history.push({ pathname: "/", search: "?selectedView=" });
  };

  return (
    <div
      id={targetView}
      className={`dashboard-summary-box
      ${Icon && " icon-summary-box"}
      ${counter != null && " counter-summary-box"}
      ${selectedView === targetView && " selected-summary-box"}`}
      onClick={() => {
        clearSavedListRelatedDataAct();
        selectedView === targetView ? handleViewToggle() : handleViewChange();
      }}
    >
      {counter !== null && <div className="dashboard-summary-box-counter">{counter}</div>}
      {Icon && (
        <div className="dashboard-summary-box-icon">
          <Icon />
        </div>
      )}
      <div className="dashboard-summary-box-title">{title}</div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedView: selectSelectedView,
  filtersModified: selectFiltersModified
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedView: (selectedView) => dispatch(ACTIONS.setSelectedView(selectedView)),
  clearSelectedView: () => dispatch(ACTIONS.clearSelectedView()),
  clearFiltersInputs: () => dispatch(ACTIONS.clearFiltersInputs()),
  setFiltersAct: (filters, tableName) => dispatch(ACTIONS.setFiltersAction(filters, tableName)),
  clearSavedListRelatedDataAct: () => dispatch(ACTIONS.clearSavedListRelatedDataAction())
});

const DashboardSummaryBox = connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardSummaryBoxComponent));

export { DashboardSummaryBox };
