import React from "react";
import { Button, Col, Divider, Popover, Row, Typography } from "antd";
import styled from "styled-components";
import { GRAY_300, TEXT2, GRAY_400 } from "../../../constant/colors";
import { selectPatientDetails } from "../../../store/selector";
import { useSelector } from "react-redux";
import { fontWeights, sizes } from "../../../constant/styles";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
import { QuestionCircleOutlined } from "@ant-design/icons";
import { formatSSN } from "../../../utils/formaters";

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: -2px;
  background: inherit;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  :active,
  :hover,
  :focus {
    background: inherit;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  &::after {
    all: unset;
  }
`;

const StyledTitle = styled(Text)`
  white-space: nowrap;
  font-size: ${sizes.medium};
  color: ${TEXT2};
  font-weight: ${fontWeights.semibold};
  display: block;
  padding-bottom: 5px;
`;

const Item = ({ title, value, withDivider }) => {
  return (
    <>
      <Col style={{ paddingLeft: "17px", wordBreak: "break-all" }} flex="1 1 0">
        <StyledTitle>{title}</StyledTitle>
        <Text>{value || ""}</Text>
      </Col>
      {withDivider && (
        <Col>
          <Divider type="vertical" style={{ height: "100%", flex: 1, borderColor: GRAY_300 }} />
        </Col>
      )}
    </>
  );
};
const PopUpContent = () => {
  const { t } = useTranslation();

  const {
    address: { address, city, state, zipCode },
    preferredName,
    email,
    ssn
  } = useSelector(selectPatientDetails);
  const fullAddress = [address, city, state, zipCode].filter((subAddress) => subAddress).join(", ");

  return (
    <Row
      style={{
        padding: "0px 15px",
        flexWrap: "nowrap"
      }}
    >
      <Item title={t("patient_header.more_info.preferred_name")} value={preferredName || ""} withDivider={true} />
      <Item title={t("patient_header.more_info.email")} value={email} withDivider={true} />
      <Item title={t("patient_header.more_info.address")} value={fullAddress} withDivider={true} />
      <Item title={t("patient_header.more_info.ssn")} value={formatSSN(ssn)} withDivider={false} />
    </Row>
  );
};
const PatientMoreInfo = () => {
  return (
    <Popover
      overlayInnerStyle={{ width: "685px", borderRadius: 8, boxShadow: "rgba(16, 24, 40, 0.15) 1px 5px 8px 1px" }}
      trigger="hover"
      placement="rightTop"
      content={<PopUpContent />}
    >
      <StyledButton id="patient_more_info_question_mark">
        <span style={{ paddingTop: "3px", color: GRAY_400, fontSize: "16px" }}>
          <QuestionCircleOutlined />
        </span>
      </StyledButton>
    </Popover>
  );
};

export { PatientMoreInfo };
