import { UNSPECIFIED_MEMBER_ID, UNSPECIFIED_PAYER_NAME } from "../../../../../constant/coverages";
import { getStateCode } from "../../../../../constant/states";
import { OTHER } from "../../constants";
import { insuranceTypes } from "./formSections/insurance/InsuranceTypeFormItem";
import { getDrugByName, getDrugByCui } from "./formSections/prescription/drugDefinitions";

const DEFAULT_PHONE_TYPE = "mobile";
const TALZENNA_DRUG_NAME = "talzenna";

const getDrug = (prescription) => {
  if (!prescription) {
    return {};
  }

  const { drug, ttyCui, name } = prescription;
  if (drug) {
    return drug;
  }
  if (ttyCui) {
    return getDrugByCui(ttyCui);
  }
  if (name) {
    return getDrugByName(name);
  }
};

const getFormInsuranceType = (insuranceType) => {
  const validInsuranceTypeValues = Object.values(insuranceTypes);
  return insuranceType && !validInsuranceTypeValues.includes(insuranceType) ? insuranceTypes.OTHER : insuranceType;
};

export const getPfizerFreeDrugFormInitialValues = (applicationForm, isRenewedApplication) => {
  if (!applicationForm) return {};

  const patientState =
    applicationForm.formData?.patient?.state === "" ? null : applicationForm.formData?.patient?.state;

  const shippingState =
    applicationForm.formData?.shipping?.state === "" ? null : applicationForm.formData?.shipping?.state;

  const householdIncome = applicationForm.formData?.financial?.householdIncome;
  const insurance = applicationForm.formData?.insurance;
  const diagnosis = applicationForm.formData?.diagnosis || {};
  const primaryInsuranceType = getFormInsuranceType(insurance?.primary?.type);
  const secondaryInsuranceType = getFormInsuranceType(insurance?.secondary?.type);
  const providerId =
    !applicationForm.formData?.provider?.id &&
    (applicationForm.formData?.provider?.firstName || applicationForm.formData?.provider?.lastName)
      ? OTHER
      : applicationForm.formData?.provider?.id;

  const facilityId =
    !applicationForm.formData?.facility?.id && applicationForm.formData?.facility?.name
      ? OTHER
      : applicationForm.formData?.facility?.id;

  let facilityState = applicationForm.formData?.facility?.state || null;
  if (facilityState) facilityState = getStateCode(facilityState);

  const initialValues = {
    ...applicationForm.formData,
    patient: {
      ...applicationForm.formData?.patient,
      state: patientState,
      phoneType: applicationForm.formData?.patient?.phoneType || DEFAULT_PHONE_TYPE,
      caregiver: {
        ...applicationForm.formData?.patient?.caregiver,
        phoneType: applicationForm.formData?.patient?.caregiver?.phoneType || DEFAULT_PHONE_TYPE
      },
      reEnrollment: isRenewedApplication
    },
    prescription: {
      ...applicationForm.formData?.prescription,
      drug: getDrug(applicationForm.formData?.prescription)
    },
    shipping: {
      ...applicationForm.formData?.shipping,
      state: shippingState
    },
    financial: {
      ...applicationForm.formData?.financial,
      householdIncome: isNaN(householdIncome) ? null : householdIncome
    },
    insurance: {
      ...insurance,
      primary: {
        ...insurance?.primary,
        type: primaryInsuranceType,
        member: insurance?.primary?.member?.startsWith(UNSPECIFIED_MEMBER_ID) ? null : insurance?.primary?.member,
        name: insurance?.primary?.name?.startsWith(UNSPECIFIED_PAYER_NAME) ? null : insurance?.primary?.name
      },
      secondary: {
        ...insurance?.secondary,
        type: secondaryInsuranceType,
        member: insurance?.secondary?.member?.startsWith(UNSPECIFIED_MEMBER_ID) ? null : insurance?.secondary?.member,
        name: insurance?.secondary?.name?.startsWith(UNSPECIFIED_PAYER_NAME) ? null : insurance?.secondary?.name
      }
    },
    provider: {
      ...applicationForm.formData?.provider,
      id: providerId,
      lastName: applicationForm.formData?.provider?.lastName || applicationForm.formData?.provider?.firstName
    },
    facility: {
      ...applicationForm.formData?.facility,
      id: facilityId,
      state: facilityState
    }
  };

  if (applicationForm.formData?.prescription?.name?.toLowerCase() === TALZENNA_DRUG_NAME) {
    initialValues.diagnosis = {
      ...initialValues.diagnosis,
      talzennaPositiveHrrTest: diagnosis.talzennaPositiveHrrTest || false
    };
  }
  return initialValues;
};
