import React from "react";
import { useTranslation } from "react-i18next";
import { Space } from "antd";

import { EnrollResultData } from "../../../PatientApplications/enrollTab/submission/EnrollResultData";
import { useSelectedApplication } from "../../../hooks";
import { ReactComponent as AlertIcon } from "../../../../../assets/svg/alert-icon-popup.svg";
import { EnrollGeneralFailure } from "./EnrollGeneralFailure";
import { EnrollGenentechFailure } from "../../Genentech/result/EnrollGenentechFailure";

const invalidFormDetails = "Invalid form details";

export const EnrollFailed = ({ id, error, padding = "0 15vw" }) => {
  const { t } = useTranslation();
  const application = useSelectedApplication();

  return (
    <div id={id}>
      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: padding
        }}
      >
        <EnrollResultData
          icon={<AlertIcon />}
          title={t("patientApplications.enroll.enroll_result.application_failed_for", {
            items: application.drugDisplayName
          })}
          content={
            <Space
              direction="vertical"
              size={20}
              style={{
                display: "flex",
                textAlign: "center"
              }}
            >
              {/* EnrollGenentechFailure use only for 422 error */}
              {error === invalidFormDetails ? <EnrollGenentechFailure /> : <EnrollGeneralFailure error={error} />}
            </Space>
          }
        />
      </Space>
    </div>
  );
};
