import { GRAY_700, GRAY_75, GRAY_90 } from "../../constant/colors";
import { fontWeights, sizes } from "../../constant/styles";

export const commonTableStyle = `.ant-table-empty {
    margin: 77px 0;
  }

  .ant-table-thead {
    height: 97px;
    max-height: 97px;
    background: ${GRAY_75};
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .ant-table-thead > tr > .ant-table-cell {
    color: ${GRAY_700};
    font-weight: ${fontWeights.medium};
    font-size: ${sizes.medium};
    line-height: 20px;
    font-style: normal;
    vertical-align: top;
  }

  .ant-table-thead > tr > .ant-table-cell:not(:first-child) {
    min-width: 148px
  }

  .ant-table-thead > tr > .ant-table-cell:not(:first-child):empty {
    min-width: 50px
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-left: 1px solid ${GRAY_90};
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border-right: 1px solid ${GRAY_90};
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid ${GRAY_90};
    border-top: 1px solid ${GRAY_90};
    padding: 22px 8px !important;
  }

  .ant-table-thead > tr > th > .ant-table-column-sorters {
    justify-content: start;
    align-items: start;
  }


  .ant-table-thead > tr > th > .ant-table-column-sorters .ant-table-column-title {
    flex: initial;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${GRAY_75};
  }

  .ant-table {
    @media (min-width: 1600px) {
      height: 65vh;
    }
    @media (max-width: 1600px) {
      height: 55vh;
    }
    overflow: auto;
  }

  .ant-table::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .ant-table::-webkit-scrollbar:vertical {
    width: 15px;
  }

  .ant-table::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid white;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
