const patientFields = {
  id: ["patient", "id"],
  firstName: ["patient", "firstName"],
  middleName: ["patient", "middleName"],
  lastName: ["patient", "lastName"],
  dob: ["patient", "dob"],
  gender: ["patient", "gender"],
  phoneType: ["patient", "phoneType"],
  phone: ["patient", "phone"],
  email: ["patient", "email"],
  address: ["patient", "address"],
  city: ["patient", "city"],
  state: ["patient", "state"],
  zip: ["patient", "zip"],
  primaryLanguage: ["patient", "primaryLanguage"],
  contactTime: ["patient", "contactTime"],
  caregiverName: ["patient", "caregiver", "name"],
  caregiverRelationship: ["patient", "caregiver", "relationship"],
  caregiverPhone: ["patient", "caregiver", "phone"],
  caregiverPhoneType: ["patient", "caregiver", "phoneType"],
  reEnrollment: ["patient", "reEnrollment"]
};

const prescriptionFields = {
  dosingInstructions: ["prescription", "dosingInstructions"],
  quantity: ["prescription", "quantity"],
  numberOfRefills: ["prescription", "numberOfRefills"],
  concomitant: ["prescription", "concomitant"],
  allergies: ["prescription", "allergies"],
  allergiesDetails: ["prescription", "allergiesDetails"],
  conditions: ["prescription", "conditions"],
  frequency: ["prescription", "frequency"],
  numberOfVials: ["prescription", "numberOfVials"],
  treatmentStartDate: ["prescription", "treatmentStartDate"],
  drug: ["prescription", "drug"],
  name: ["prescription", "drug", "name"],
  ndc: ["prescription", "drug", "ndc"],
  ttyCui: ["prescription", "drug", "ttyCui"],
  category: ["prescription", "drug", "category"],
  dosage: ["prescription", "dosage"],
  vialStrength: ["prescription", "vialStrength"],
  vialSize: ["prescription", "vialSize"],
  preferredSpecialtyPharmacyName: ["prescription", "preferredSpecialtyPharmacyName"],
  preferredSpecialtyPharmacyAddress: ["prescription", "preferredSpecialtyPharmacyAddress"],
  selfDispensingPharmacy: ["prescription", "selfDispensingPharmacy"]
};

const financialFields = {
  householdSize: ["financial", "householdSize"],
  householdIncome: ["financial", "householdIncome"]
};

const diagnosisFields = {
  primary: ["diagnosis", "primary", "icd10"],
  secondary: ["diagnosis", "secondary", "icd10"],
  talzennaPositiveHrrTest: ["diagnosis", "talzennaPositiveHrrTest"]
};

const insuranceFields = {
  primaryType: ["insurance", "primary", "type"],
  secondaryType: ["insurance", "secondary", "type"],
  prescriptionType: ["insurance", "prescription", "type"],
  primaryName: ["insurance", "primary", "name"],
  secondaryName: ["insurance", "secondary", "name"],
  primaryPhone: ["insurance", "primary", "phone"],
  secondaryPhone: ["insurance", "secondary", "phone"],
  primaryGroup: ["insurance", "primary", "group"],
  secondaryGroup: ["insurance", "secondary", "group"],
  primaryMember: ["insurance", "primary", "member"],
  secondaryMember: ["insurance", "secondary", "member"],
  primaryPolicyholderSameAsPatient: ["insurance", "primary", "policyholderSameAsPatient"],
  secondaryPolicyholderSameAsPatient: ["insurance", "secondary", "policyholderSameAsPatient"],
  prescriptionPolicyholderSameAsPatient: ["insurance", "prescriptionPolicyholderSameAsPatient"],
  primaryPolicyholderName: ["insurance", "primary", "policyholderName"],
  secondaryPolicyholderName: ["insurance", "secondary", "policyholderName"],
  prescriptionPolicyholderName: ["insurance", "prescriptionPolicyholderName"],
  primaryPolicyholderRelationship: ["insurance", "primary", "policyholderRelationship"],
  secondaryPolicyholderRelationship: ["insurance", "secondary", "policyholderRelationship"],
  prescriptionPolicyholderRelationship: ["insurance", "prescriptionPolicyholderRelationship"],
  primaryPolicyholderDOB: ["insurance", "primary", "policyholderDOB"],
  secondaryPolicyholderDOB: ["insurance", "secondary", "policyholderDOB"],
  prescriptionPolicyholderDOB: ["insurance", "prescriptionPolicyholderDOB"],
  primaryPfizerMedicationCovered: ["insurance", "primary", "pfizerMedicationCovered"],
  secondaryPfizerMedicationCovered: ["insurance", "secondary", "pfizerMedicationCovered"],
  prescriptionPayerName: ["insurance", "prescriptionPayerName"],
  prescriptionBIN: ["insurance", "prescriptionBIN"],
  prescriptionPhoneNumber: ["insurance", "prescriptionPhoneNumber"],
  prescriptionPCN: ["insurance", "prescriptionPCN"],
  prescriptionGroupId: ["insurance", "prescriptionGroupId"],
  prescriptionMemberId: ["insurance", "prescriptionMemberId"],
  prescriptionCopayAmount: ["insurance", "prescriptionCopayAmount"],
  medicarePartD: ["insurance", "medicarePartD"],
  medicareIdNumber: ["insurance", "medicareIdNumber"],
  partDPlanName: ["insurance", "partDPlanName"],
  partDAddress: ["insurance", "partDAddress"],
  partDCity: ["insurance", "partDCity"],
  partDState: ["insurance", "partDState"],
  partDZip: ["insurance", "partDZip"],
  completedBI: ["insurance", "completedBI"],
  coveredByMedicalOrPrescription: ["insurance", "medicalOrPrescriptionCovered"],
  unaffordableCopay: ["insurance", "unaffordableCopay"]
};

const hcpFields = {
  administeringProviderSameAsHCP: ["administeringProvider", "sameAsHCP"],
  administeringProviderName: ["administeringProvider", "name"],
  administeringProviderSpecialty: ["administeringProvider", "specialty"],
  administeringProviderNpi: ["administeringProvider", "npi"],
  administeringProviderStateLicense: ["administeringProvider", "stateLicense"],
  administeringProviderFacilityName: ["administeringProvider", "facilityName"],
  administeringProviderGroupTaxId: ["administeringProvider", "groupTaxId"],
  administeringProviderDea: ["administeringProvider", "dea"],
  administeringProviderAddress: ["administeringProvider", "address"],
  administeringProviderCity: ["administeringProvider", "city"],
  administeringProviderState: ["administeringProvider", "state"],
  administeringProviderZip: ["administeringProvider", "zip"],
  administeringProviderContactName: ["administeringProvider", "contact", "name"],
  administeringProviderContactPhone: ["administeringProvider", "contact", "phone"],
  administeringProviderContactFax: ["administeringProvider", "contact", "fax"],
  administeringProviderContactEmail: ["administeringProvider", "contact", "email"],
  shippingAddress: ["shipping", "address"],
  shippingCity: ["shipping", "city"],
  shippingState: ["shipping", "state"],
  shippingZip: ["shipping", "zip"],
  shippingName: ["shipping", "name"],
  shippingPhone: ["shipping", "phone"],
  providerId: ["provider", "id"],
  providerFirstName: ["provider", "firstName"],
  providerLastName: ["provider", "lastName"],
  providerDesignation: ["provider", "designation"],
  providerNpi: ["provider", "npi"],
  providerStateLicense: ["provider", "stateLicense"],
  providerDea: ["provider", "dea"],
  facilityId: ["facility", "id"],
  facilityName: ["facility", "name"],
  facilityGroupTaxId: ["facility", "groupTaxId"],
  facilityType: ["facility", "type"],
  facilityAddress: ["facility", "address"],
  facilityCity: ["facility", "city"],
  facilityState: ["facility", "state"],
  facilityZip: ["facility", "zip"],
  facilityContactFullName: ["facility", "contact", "fullName"],
  facilityContactPhone: ["facility", "contact", "phone"],
  facilityContactFax: ["facility", "contact", "fax"],
  facilityContactEmail: ["facility", "contact", "email"],
  shippingType: ["shipping", "type"]
};
export { patientFields, prescriptionFields, financialFields, diagnosisFields, insuranceFields, hcpFields };
