import React from "react";
import { Typography } from "antd";
const { Text } = Typography;
import { withTranslation } from "react-i18next";

const SavedListPageCount = ({ t, total, range }) => {
  return (
    <Text>
      {t("saved_list_pagination.saved_list_page_count", {
        range_start: range[0],
        range_end: range[1] ? range[1] : range[0],
        total
      })}
    </Text>
  );
};

export default withTranslation()(SavedListPageCount);
