import React from "react";
import { Popover, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { selectApplicationAttachmentsData } from "../store/selector";
import { useSelector } from "react-redux";

const { Text } = Typography;

export default function AttachmentPermissionsPopover({ children, canViewOrDownload }) {
  const { t } = useTranslation();

  const applicationAttachments = useSelector(selectApplicationAttachmentsData);
  // Note: the selector is for patient application attachments only, and not for general attachments purpose attachments
  const hasReadFilePermission =
    canViewOrDownload !== undefined ? canViewOrDownload : applicationAttachments?.canViewOrDownload;

  if (hasReadFilePermission) {
    return children;
  }
  return (
    <Popover
      trigger="hover"
      content={
        <Space style={{ padding: "5px 20px" }}>
          <Text>{t("patientRSActions.attachments_disabled")}</Text>
        </Space>
      }
    >
      {children}
    </Popover>
  );
}
