import { Form } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  automatePatientAfterUpdate,
  createDiagnosisEvent,
  addDrugAPI,
  deleteMedicationAPI,
  deletePatientDiagnoses,
  editMedicationAPI,
  getManagePatientDetails as getManagePatientDetailsApi,
  getPatientDiagnoses as getPatientDiagnosesApi,
  getPatientMedicationsForManagePatientDetailsAPI,
  getValidForEbvPayersAPI,
  ignoreDiagnosisPending,
  patchPatientDiagnoses,
  replaceDiagnosisWithPending,
  setPatientDetailsAPI,
  updatePatientMedicationsAPI,
  updateInsuranceAPI,
  updateUninsuredStatusAPI
} from "../../api/api";
import ErrorMessage from "../../component/customComponent/customMessages/ErrorMessage";
import { MANUAL_AUTOMATION_SOURCES } from "../../constant/coverages";
import { Routes } from "../../constant/routes";
import { selectedViews } from "../../constant/table";
import { useFetch } from "../../hooks/fetch";
import { useGetSetting } from "../../hooks/getSetting";
import ACTIONS from "../../store/action";
import { selectPatientDetails, selectPatientJourney, selectSelectedView } from "../../store/selector";
import { formatCoverage } from "../../utils/coverages";
import { utcDateFormatter, dateFormatter, isDateEqual } from "../../utils/date";
import { isBooleanEqual } from "../../utils/boolean";
import { buildPayloadFromDiagnosisTableData } from "../../utils/diagnosis";
import { isRxNormDrugIVAdministered } from "../../utils/drugs";
import { generateHashFromObj, getObjectDiff } from "../../utils/misc";
import { calculateTreatmentEndDate, formatMedication } from "../../utils/treatments";
import SuccessMessage from "../customComponent/customMessages/SuccessMessage";
import AddNewCoveragePopup from "./AddNewCoveragePopup";
import AddNewDiagnosisPopup from "./AddNewDiagnosisPopup";
import { ConflictType } from "./ConflictType";
import { DeleteCoveragePopup } from "./DeleteCoveragePopup";
import { DeleteDiagnosisPopup } from "./DeleteDiagnosisPopup";
import { getParsedInitialFormValues } from "./financialUtils";
import { DateDemographicsKeys } from "../../constant/patient";

const dateFormat = "YYYY-MM-DD";

const formatDateTypeValues = (value) => (Date.parse(value) ? moment(value).format(dateFormat) : value);

const remapPhysician = ({ physician, ...treatment }) => {
  return {
    ...treatment,
    physician
  };
};

const buildConflictProps = ({ value, name, createdAt, id }) => ({
  id,
  date: dateFormatter(createdAt),
  title: "Conflicting data was imported",
  pending: [
    {
      key: name,
      value: DateDemographicsKeys.includes(name) ? formatDateTypeValues(value) : value
    }
  ]
});

const getKeyValueMap = (object) =>
  Object.entries(_.omitBy(object, _.isNil)).reduce((acc, [key, d]) => ({ ...acc, [key]: d.value }), {});

const getKeyConflictMap = (object) =>
  Object.entries(_.omitBy(object, _.isNil)).reduce(
    (acc, [name, d]) => ({
      ...acc,
      [name]: d.pendingDemographic
        ? {
            conflict: true,
            conflictType: ConflictType.demographic,
            name,
            ...buildConflictProps({ name, ...d.pendingDemographic })
          }
        : null
    }),
    {}
  );

const buildConflictFromPendingDiagnosis = (currDiagnosis, pendingDiagnosis) => {
  const pending = [];

  if (
    !_.isNil(pendingDiagnosis.isActive) &&
    (_.isNil(currDiagnosis.isActive) || !isBooleanEqual(currDiagnosis.isActive, pendingDiagnosis.isActive))
  ) {
    pending.push({
      key: "isActive",
      value: pendingDiagnosis.isActive
    });
  }

  if (
    !_.isNil(pendingDiagnosis.activeDate) &&
    (_.isNil(currDiagnosis.activeDate) || !isDateEqual(currDiagnosis.activeDate, pendingDiagnosis.activeDate))
  ) {
    pending.push({
      key: "activeDate",
      value: utcDateFormatter(pendingDiagnosis.activeDate)
    });
  }

  if (
    !_.isNil(pendingDiagnosis.isPrimary) &&
    (_.isNil(currDiagnosis.isPrimary) || !isBooleanEqual(currDiagnosis.isPrimary, pendingDiagnosis.isPrimary))
  ) {
    pending.push({
      key: "isPrimary",
      value: pendingDiagnosis.isPrimary
    });
  }

  return {
    icd: currDiagnosis.icd,
    pending,
    date: moment(currDiagnosis.timestamp).format("D MMM YYYY [at] HH:m"),
    title: "Conflict data was imported"
  };
};

const buildDiagnosisTableData = (patientDiagnosisResult = []) => {
  return patientDiagnosisResult.map(({ diagnosis, activeDate, icd, isActive, isPrimary, pendingDiagnosis }) => ({
    key: icd,
    diagnosisInfo: { name: diagnosis, icd },
    activeDate: utcDateFormatter(activeDate),
    isActive,
    isPrimary,
    conflict:
      (pendingDiagnosis && buildConflictFromPendingDiagnosis({ activeDate, isActive, isPrimary }, pendingDiagnosis)) ||
      null
  }));
};

const useConflictFocusAlert = () => {
  const [conflictAlertList, setConflictAlertList] = useState([]);
  const [conflictAlert, setConflictAlert] = useState(null);
  const [conflictAlertIndex, setConflictAlertIndex] = useState(-1);

  const registerConflictAlert = (popup) => {
    if (!conflictAlertList.some((conflict) => conflict.id === popup.id)) {
      setConflictAlertList((conflictAlertList) => [...conflictAlertList, popup]);
    }
  };

  const refreshAlert = (alert) => {
    setConflictAlert(alert);
    conflictAlertList.forEach(({ hide }) => hide());
    if (conflictAlert) {
      conflictAlert.show();
    }
    setConflictAlertIndex(conflictAlertList.indexOf(alert));
  };

  const focusNextConflictAlert = () => {
    let alert;
    if (!conflictAlert) {
      alert = conflictAlertList[0];
    } else {
      if (conflictAlertIndex < conflictAlertList.length - 1) {
        alert = conflictAlertList[conflictAlertIndex + 1];
      } else {
        alert = conflictAlert;
      }
    }
    refreshAlert(alert);
  };

  const focusPrevConflictAlert = () => {
    let alert;
    if (!conflictAlert) {
      alert = conflictAlertList[0];
    } else {
      if (conflictAlertIndex > 0) {
        alert = conflictAlertList[conflictAlertIndex - 1];
      } else {
        alert = conflictAlert;
      }
    }
    refreshAlert(alert);
  };

  return {
    conflictAlertIndex,
    focusPrevConflictAlert,
    focusNextConflictAlert,
    // focusConflictAlert,
    registerConflictAlert
  };
};

const getModifiedNumberOfRefills = (numberOfRefillsAuthorized, tmRouteType) => {
  let modifiedNumberOfRefills = numberOfRefillsAuthorized;
  if (!isRxNormDrugIVAdministered({ tmRouteType }) && !_.isNil(numberOfRefillsAuthorized)) {
    modifiedNumberOfRefills++;
  }

  return modifiedNumberOfRefills;
};

const calculateTreatments = (patientTreatmentsDataRes) => {
  const { patientPhysicianDrugs, patientUncoveredDrugs, manualMedications, importedTreatments } =
    patientTreatmentsDataRes;
  const calculatedManualData = [];
  const drugPhysicianMap = Object.fromEntries(patientPhysicianDrugs.map((ppd) => [ppd.rxnormDrug, ppd.physician]));
  manualMedications.reduce(
    (
      result,
      {
        dosageValue,
        frequency,
        dosageUnit,
        startDate,
        genericDrugId,
        numOfCycles,
        id,
        numberOfRefillsAuthorized,
        quantityValue,
        calculatedEndDateForIV,
        calculatedDaysBetweenCycle,
        rxnormDrug,
        isHidden
      }
    ) => {
      const { id: rxnormDrugId, tmRouteType } = rxnormDrug;

      const modifiedNumberOfRefills = getModifiedNumberOfRefills(numberOfRefillsAuthorized, tmRouteType);

      const physician = drugPhysicianMap[rxnormDrugId];
      const isUncovered = patientUncoveredDrugs.includes(rxnormDrugId);
      let endDate;
      if (startDate) {
        if (isRxNormDrugIVAdministered({ tmRouteType })) {
          endDate = calculatedEndDateForIV;
        } else {
          const calculatedQuantity = quantityValue / parseInt(frequency || 1);
          endDate = calculateTreatmentEndDate(startDate, calculatedQuantity, modifiedNumberOfRefills);
        }
      }

      result.push({
        dosageValue,
        frequency,
        dosageUnit,
        genericDrugId,
        numOfCycles,
        startDate,
        endDate,
        id,
        isUncovered,
        isHidden,
        physician: physician?.id || "",
        numberOfRefillsAuthorized: modifiedNumberOfRefills,
        quantityValue,
        quantity: calculatedDaysBetweenCycle,
        calculatedDaysBetweenCycle,
        rxnormDrug
      });
      return result;
    },
    calculatedManualData
  );

  const calculatedImportedData = [];
  const flatValues = _.flow([_.values, _.flatten]);
  flatValues(importedTreatments).reduce(
    (
      result,
      {
        dosageValue,
        dosageDisplay,
        frequency,
        dosageUnit,
        startDate,
        genericDrugId,
        numOfCycles,
        id,
        numberOfRefillsAuthorized,
        quantityValue,
        calculatedEndDateForIV,
        calculatedEndDateForOral,
        rxnormDrug,
        isHidden,
        endDate
      }
    ) => {
      const { id: rxnormDrugId, tmRouteType } = rxnormDrug;
      const physician = drugPhysicianMap[rxnormDrugId];
      const isUncovered = patientUncoveredDrugs.includes(rxnormDrugId);

      if (!endDate) {
        endDate = isRxNormDrugIVAdministered({ tmRouteType }) ? calculatedEndDateForIV : calculatedEndDateForOral;
      }

      result.push({
        dosageValue,
        dosageDisplay,
        frequency,
        dosageUnit,
        genericDrugId,
        id,
        numOfCycles,
        startDate,
        endDate,
        isUncovered,
        isHidden,
        physician: physician?.id || "",
        numberOfRefillsAuthorized,
        quantityValue,
        rxnormDrug
      });
      return result;
    },
    calculatedImportedData
  );

  return {
    manualTreatments: calculatedManualData,
    importedTreatments: calculatedImportedData
  };
};

const parseStringToBoolean = (value) => {
  if (!value) return undefined;

  const lowerCaseValue = _.toLower(value);

  if (_.includes(["true", "1"], lowerCaseValue)) return true;
  if (_.includes(["false", "0"], lowerCaseValue)) return false;

  return undefined;
};

const preparePatientDataForConflictsResolution = (patientData) => {
  const formatMap = {
    dob: ({ pendingDemographic, ...data }) =>
      pendingDemographic
        ? {
            pendingDemographic: {
              ...pendingDemographic,
              value: moment(pendingDemographic.value)
            },
            ...data
          }
        : data
  };

  return Object.entries(patientData).reduce((result, [name, data]) => {
    return {
      ...result,
      [name]: formatMap[name] ? formatMap[name](data) : data
    };
  }, {});
};

const setFieldsValue = (form, values) => {
  form.setFields(
    Object.entries(values).map(([name, value]) => ({
      name,
      value,
      touched: false
    }))
  );
};

export const ManagePatientDetailsContext = React.createContext({
  diagnosisTableData: [],
  demographicsFormData: [],
  coveragesData: [],
  initialCoveragesData: null,
  isFetching: false,
  validEbvPayers: [],
  calculateFPL: () => {},
  setCoveragesData: () => {},
  onDiagnosisRemove: () => {},
  onDiagnosisChanged: () => {},
  isCoverageRequestPending: () => {},
  newCoverageRequests: () => {},
  conflictsData: [],
  // Conflict Alert
  registerConflictAlert: () => {},
  focusConflictAlert: () => {},
  focusPrevConflictAlert: () => {},
  focusNextConflictAlert: () => {},
  conflictAlertIndex: -1,
  replaceDiagnosisPendingConflict: () => {},
  ignoreDiagnosisPendingConflict: () => {},
  showAddDiagnosisPopup: () => {},
  showFinanicalWorkSheetPopup: () => {},
  ignoreDemographicsPendingConflict: () => {},
  replaceDemographicsPendingConflict: () => {},
  updateMedications: () => {},
  addNewMedication: () => {},
  deleteTreatments: () => {},
  handleSubmit: () => {},
  getIsFormModified: () => false,
  showAddCoveragePopup: () => {},
  initialDrugEventsData: null,
  isRxNormDrugIVAdministered: () => {},
  lastUpdate: {}
});

export const ManagePatientDetailsContextProvider = ({ children, ...props }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [parsedFplSetting, queryPokitDokByDefault] = useGetSetting(["fpl", "coverage.queryPokitDokByDefault"]);

  const automateRule = queryPokitDokByDefault
    ? MANUAL_AUTOMATION_SOURCES.FORCE_POKITDOK
    : MANUAL_AUTOMATION_SOURCES.FORCE_BENEFIT_INVESTIGATION;

  const [diagnosisTableData, setDiagnosisTableData] = useState([]);
  const [validEbvPayers, setValidEbvPayers] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [demographicsFormData, setDemographicsFormData] = useState({
    values: {},
    conflicts: {}
  });
  const [formWithoutErrors, setFormWithoutErrors] = useState(true);
  const initialDiagnosisData = useRef();
  const initialCoveragesData = useRef();
  const [coveragesData, setCoveragesData] = useState([]);
  const deleteDiagnosisPopup = useRef();
  const addDiagnosisPopup = useRef();
  const addCoveragePopup = useRef();
  const deleteCoveragePopup = useRef();
  const patientDataForConflictsResolution = useRef({});
  const [conflictsData, setConflictsData] = useState([]);
  const [allDrugEvents, setAllDrugEvents] = useState({
    manualTreatments: [],
    importedTreatments: []
  });
  const history = useHistory();

  const initialDrugEventsData = useRef({ manualTreatments: [], importedTreatments: [] });

  const [getPatientDiagnosis, patientDiagnosisResult, isGetPatientDiagnosisLoading] = useFetch(getPatientDiagnosesApi);
  const [getManagePatientDetails, managePatientDetailsRes, isGetManagePatientDetailsLoading] =
    useFetch(getManagePatientDetailsApi);
  const [getPatientTreatments, patientTreatmentsDataRes, isGetPatientTreatmentsLoading] = useFetch(
    getPatientMedicationsForManagePatientDetailsAPI
  );
  const [patchPatientDiagnosesAPI] = useFetch(patchPatientDiagnoses);
  const [deletePatientDiagnosesAPI] = useFetch(deletePatientDiagnoses);
  const [createPatientDiagnosesAPI] = useFetch(createDiagnosisEvent);
  const [updateInsuranceRowsAPI] = useFetch(updateInsuranceAPI);
  const [getValidPayersAPI, validEbvPayersResults] = useFetch(getValidForEbvPayersAPI);

  const [coverageToDelete, setCoverageToDelete] = useState([]);

  const [setPatientDetails] = useFetch(setPatientDetailsAPI);
  const [ignoreDiagnosisPendingAPI] = useFetch(ignoreDiagnosisPending);
  const [replaceDiagnosisWithPendingAPI] = useFetch(replaceDiagnosisWithPending);

  const patientDetails = useSelector(selectPatientDetails);
  const patientJourney = useSelector(selectPatientJourney);

  const dispatch = useDispatch();

  const [lastUpdateDiagnosis, setLastUpdateDiagnosis] = useState(null);
  const [lastUpdateDemographics, setLastUpdateDemographics] = useState(null);
  const [lastUpdateCoverages, setLastUpdateCoverages] = useState(null);
  const [lastUpdateMedications, setLastUpdateMedications] = useState(null);

  const getPatientDetailsAction = (patientId) => dispatch(ACTIONS.getPatientDetailsAction(patientId));
  const getPatientAct = (patientId, journeyId) => dispatch(ACTIONS.getPatientAction(patientId, journeyId));
  const setTableDataAct = (selectedView) => dispatch(ACTIONS.setTableDataAction(1, selectedView));

  const lastUpdate = [lastUpdateDiagnosis, lastUpdateDemographics, lastUpdateCoverages, lastUpdateMedications].reduce(
    (last, cur) => {
      if (!last?.lastUpdateTimestamp || cur?.lastUpdateTimestamp > last.lastUpdateTimestamp) {
        return cur;
      }
      return last;
    }
  );

  useEffect(() => {
    getPatientDiagnosis(patientId, true);
    getManagePatientDetails(patientId);
    getPatientDetailsAction(patientId);
    getPatientTreatments(patientId);
    getValidPayersAPI();
    if (!patientDetails || !patientJourney) {
      getPatientAct(patientId, journeyId);
    }
    return () => dispatch(ACTIONS.clearPatient());
  }, []);

  useEffect(() => {
    if (validEbvPayersResults) {
      setValidEbvPayers(validEbvPayersResults.data);
    }
  }, [validEbvPayersResults]);

  useEffect(() => {
    setIsFetching(isGetPatientDiagnosisLoading || isGetManagePatientDetailsLoading || isGetPatientTreatmentsLoading);
  }, [isGetPatientDiagnosisLoading, isGetManagePatientDetailsLoading, isGetPatientTreatmentsLoading]);

  useEffect(() => {
    initialDiagnosisData.current = buildDiagnosisTableData(_.get(patientDiagnosisResult, "data.patient-diagnoses"));
    setDiagnosisTableData(_.cloneDeep(initialDiagnosisData.current));
    setLastUpdateDiagnosis(patientDiagnosisResult?.data?.meta);
    setConflictsData((conflictState) => [
      ...conflictState,
      ...initialDiagnosisData.current
        .filter((d) => d.conflict)
        .map((d) => ({ ...d, conflict: true, conflictType: ConflictType.diagnosis }))
    ]);
  }, [patientDiagnosisResult]);

  useEffect(() => {
    setConflictsData((conflictState) => [
      ...conflictState,
      ...diagnosisTableData
        .filter((d) => d.conflict)
        .map((d) => ({ ...d, conflict: true, conflictType: ConflictType.diagnosis }))
    ]);
  }, [diagnosisTableData]);

  useEffect(() => {
    if (history?.location?.state?.openCoveragePopup) {
      const emptyData = [];
      showAddCoveragePopup(emptyData);
    }
  }, [history]);

  const { patientId, journeyId } = useParams();

  const conflictAlertValues = useConflictFocusAlert();

  useEffect(() => {
    if (managePatientDetailsRes && managePatientDetailsRes.data) {
      const mappedObject = getKeyValueMap(managePatientDetailsRes.data);
      const conflicts = getKeyConflictMap(managePatientDetailsRes.data);
      patientDataForConflictsResolution.current = preparePatientDataForConflictsResolution(
        managePatientDetailsRes.data
      );

      setLastUpdateDemographics(
        _.mapValues(
          _.pick(managePatientDetailsRes.data, ["lastUpdateTimestamp", "lastUpdateUserId"]),
          (val) => val.value
        )
      );

      setConflictsData((conflictState) => [...conflictState, ...Object.values(conflicts).filter((value) => value)]);
      const {
        dob,
        incomeVerifiedDate,
        agreementSignatureDate,
        patientHeight,
        totalIncome,
        householdSizeAdults,
        householdSizeChildren,
        veteran,
        usCitizen,
        legalResident,
        disabled
      } = mappedObject;

      const dobMoment = moment(dob, "YYYY-MM-DD");
      if (!dobMoment.isValid()) {
        delete mappedObject.dob;
      } else {
        mappedObject.dob = dobMoment;
      }

      const incomeVerifiedDateMoment = moment(incomeVerifiedDate, "YYYY-MM-DD");
      if (!incomeVerifiedDateMoment.isValid()) {
        delete mappedObject.incomeVerifiedDate;
      } else {
        mappedObject.incomeVerifiedDate = incomeVerifiedDateMoment;
      }

      const agreementSignatureDateMoment = moment(agreementSignatureDate, "YYYY-MM-DD");
      if (!agreementSignatureDateMoment.isValid()) {
        delete mappedObject.agreementSignatureDate;
      } else {
        mappedObject.agreementSignatureDate = agreementSignatureDateMoment;
      }

      if (patientHeight) {
        const [patientHeightFeet, patientHeightInch] = patientHeight.split('"');
        mappedObject.patientHeightFeet = patientHeightFeet;
        mappedObject.patientHeightInch = patientHeightInch;
      }

      if (veteran) {
        mappedObject.veteran = parseStringToBoolean(veteran);
      }
      if (usCitizen) {
        mappedObject.usCitizen = parseStringToBoolean(usCitizen);
      }
      if (legalResident) {
        mappedObject.legalResident = parseStringToBoolean(legalResident);
      }
      if (disabled) {
        mappedObject.disabled = parseStringToBoolean(disabled);
      }

      let calculatedFPL = 0;
      if (!_.isNil(totalIncome) && !_.isNil(householdSizeAdults) && !_.isNil(householdSizeChildren)) {
        const householdSize = parseInt(householdSizeAdults ?? 0) + parseInt(householdSizeChildren ?? 0);
        calculatedFPL = calculateFPL(parseInt(totalIncome), householdSize);
      }

      mappedObject.fpl = calculatedFPL;
      const values = {
        ...mappedObject
      };

      setDemographicsFormData({ values, conflicts });
    }
  }, [managePatientDetailsRes]);

  useEffect(() => {
    if (patientTreatmentsDataRes && patientTreatmentsDataRes.data) {
      const { manualTreatments, importedTreatments } = calculateTreatments(patientTreatmentsDataRes.data);

      setLastUpdateMedications(_.pick(patientTreatmentsDataRes.data, ["lastUpdateTimestamp", "lastUpdateUserId"]));
      setAllDrugEvents({ manualTreatments, importedTreatments });
      initialDrugEventsData.current = { manualTreatments, importedTreatments };
    }
  }, [patientTreatmentsDataRes]);

  const findLatestYear = (parsedFplSetting) => {
    const fplYears = Object.keys(parsedFplSetting);
    return Math.max(...fplYears);
  };

  const calculateFPL = (yearlyIncome, incomeHouseholdSize) => {
    if (!parsedFplSetting || !yearlyIncome || !incomeHouseholdSize) {
      return 0;
    }

    let usedFpl;
    const latestYear = findLatestYear(parsedFplSetting);
    usedFpl = parsedFplSetting[latestYear];

    const overEight = Math.max(incomeHouseholdSize - 8, 0);
    const householdSize = Math.min(8, incomeHouseholdSize);

    const calculatedFplValue = parseInt((yearlyIncome / (usedFpl[householdSize] + overEight * usedFpl["+"])) * 100);
    return calculatedFplValue;
  };

  useEffect(() => {
    const fetchInitialCoveragesData = patientDetails?.coverageDetailsFromDatum.map((coverage) => ({
      ...coverage,
      id: uuidv4(),
      activeDate: moment(coverage?.activeDate)
    }));

    initialCoveragesData.current = fetchInitialCoveragesData;
    setCoveragesData(fetchInitialCoveragesData);
    setLastUpdateCoverages(patientDetails?.coverageUpdated);

    const coverages = {
      patientInsuranceRows: initialCoveragesData.current,
      isPatientUninsured: patientDetails?.isPatientUninsured
    };

    setFieldsValue(form, { coverages });

    if (patientDetails?.gender) {
      setFieldsValue(form, { gender: patientDetails.gender });
    }

    if (patientDetails) {
      if (patientDetails.hasInsuranceConflicts) {
        const coverageConflict = {
          conflict: true,
          conflictType: ConflictType.coverage
        };
        setConflictsData((conflictState) => [...conflictState, coverageConflict]);
      } else {
        setConflictsData((conflictState) => {
          return conflictState.filter(({ conflictType }) => {
            return conflictType !== ConflictType.coverage;
          });
        });
      }
    }

    if (patientDetails?.hasInsuranceConflicts) {
      conflictAlertValues.registerConflictAlert({
        id: generateHashFromObj(_.get(patientDetails, "pendingInsuranceRows[0]") || {}),
        show: () => {},
        hide: () => {}
      });
    }

    if (patientDetails) {
      const { preferredLanguage } = patientDetails;
      setFieldsValue(form, { preferredLanguage });
    }
  }, [patientDetails]);

  useEffect(() => {
    const formFields = {
      ..._.omit(demographicsFormData.values, ["deceased"]),
      ...getParsedInitialFormValues(demographicsFormData.values)
    };

    setFieldsValue(form, formFields);
  }, [demographicsFormData]);

  useEffect(() => {
    if (coveragesData && !_.isEqual(coveragesData, initialCoveragesData.current)) {
      const coverages = {
        patientInsuranceRows: coveragesData,
        isPatientUninsured:
          form.getFieldValue(["coverages", "isPatientUninsured"]) ?? patientDetails?.isPatientUninsured
      };
      form.setFieldsValue({ coverages });
    }
  }, [coveragesData]);

  const onCoverageAdded = (values) => {
    setCoveragesData((prev) => [...prev, { ...formatCoverage(values, getCoverageType(), patientId) }]);
  };

  const onDeleteCoverage = (coverageToDelete) => {
    const coveragesOrder = ["primary", "secondary", "tertiary"];
    setCoveragesData((prev) => {
      const coveragesAfterDeletion = prev.filter((coverage) => coverage.id !== coverageToDelete.id);
      return coveragesAfterDeletion.map((coverage, index) => ({ ...coverage, coverageType: coveragesOrder[index] }));
    });
  };

  const isCoverageRequestPending = () =>
    initialCoveragesData.current &&
    (_.differenceWith(initialCoveragesData.current, coveragesData, (a, b) => a.id === b.id).length > 0 ||
      _.differenceWith(coveragesData, initialCoveragesData.current, (a, b) => a.id === b.id).length > 0);

  const newCoverageRequests = ({ coverages: { patientInsuranceRows } }) => [
    () =>
      updateInsuranceRowsAPI(
        patientId,
        initialCoveragesData.current,
        patientInsuranceRows.map((insuranceRow) => _.omit(insuranceRow, "id"))
      )
  ];

  const getCoverageType = () => {
    const insurancesListLength = coveragesData.length;
    switch (insurancesListLength) {
      case 0:
        return "primary";
      case 1:
        return "secondary";
      default:
        return "tertiary";
    }
  };
  const getDiagnosisEffects = useCallback(() => {
    if (!initialDiagnosisData.current) return [];
    const possiblyModifiedDiagnoses = _.intersectionWith(
      diagnosisTableData,
      initialDiagnosisData.current,
      (a, b) => a.diagnosisInfo.icd === b.diagnosisInfo.icd
    );
    const diagnosesChanged = possiblyModifiedDiagnoses.filter((v, i) => !_.isEqual(initialDiagnosisData.current[i], v));
    const updateDiagnosesEffects = diagnosesChanged.map((diagnosisData) => {
      const data = buildPayloadFromDiagnosisTableData(diagnosisData);
      return () => patchPatientDiagnosesAPI(patientId, data.icd, data);
    });

    const addedDiagnoses = _.differenceWith(
      diagnosisTableData,
      initialDiagnosisData.current,
      (a, b) => a.diagnosisInfo.icd === b.diagnosisInfo.icd
    );
    const addDiagnosesEffects = addedDiagnoses.map((diagnosisData) => {
      const data = buildPayloadFromDiagnosisTableData(diagnosisData);
      return () => createPatientDiagnosesAPI(patientId, data.icd, data);
    });

    const removedDiagnoses = _.differenceWith(
      initialDiagnosisData.current,
      diagnosisTableData,
      (a, b) => a.diagnosisInfo.icd === b.diagnosisInfo.icd
    );
    const removeDiagnosesEffects = removedDiagnoses.map((diagnosisData) => {
      const data = buildPayloadFromDiagnosisTableData(diagnosisData);
      return () => deletePatientDiagnosesAPI(patientId, data.icd);
    });

    return [...updateDiagnosesEffects, ...removeDiagnosesEffects, ...addDiagnosesEffects];
  }, [initialDiagnosisData.current, diagnosisTableData]);

  const onDiagnosisChanged = (index, key, value) => {
    if (key === "isPrimary") {
      diagnosisTableData[index].isPrimary = value;
    } else if (key === "isActive") {
      diagnosisTableData[index].isActive = value;
    } else if (key === "date") {
      diagnosisTableData[index].activeDate = value;
    }
    setDiagnosisTableData([...diagnosisTableData]);
  };

  const onDiagnosisRemove = (index) => {
    deleteDiagnosisPopup.current(index, diagnosisTableData[index]);
  };

  const onDiagnosisAdded = (diagnosisData) => {
    if (!isICDExists(diagnosisData.icd)) {
      diagnosisTableData.push(...buildDiagnosisTableData([diagnosisData]));
    } else {
      ErrorMessage("The Patient already has diagnoses with this ICD");
    }
    setDiagnosisTableData([...diagnosisTableData]);
  };

  const ignoreDiagnosisPendingConflict = async (icd) => {
    try {
      const currentDiagnosis = diagnosisTableData.find((d) => d.diagnosisInfo.icd === icd);
      const currentDiagnosisIndex = diagnosisTableData.indexOf(currentDiagnosis);
      delete currentDiagnosis.conflict;
      diagnosisTableData.splice(currentDiagnosisIndex, 1, currentDiagnosis);
      initialDiagnosisData.current = _.cloneDeep(diagnosisTableData);
      setDiagnosisTableData(_.cloneDeep(diagnosisTableData));
      setConflictsData((conflictState) => {
        return conflictState.filter(({ conflictType, key }) => {
          return !(conflictType === "diagnosis" && key === currentDiagnosis.key);
        });
      });
      await ignoreDiagnosisPendingAPI(patientId, icd);
      SuccessMessage(t("successMessages.successfully_conflict_resolution"));
    } catch (error) {
      ErrorMessage(t("errorMessages.failed_conflict_resolution"));
      throw error;
    }
  };

  const replaceDiagnosisPendingConflict = async (icd) => {
    try {
      const currentDiagnosis = diagnosisTableData.find((d) => d.diagnosisInfo.icd === icd);
      const { conflict, ...nextDiagnosis } = currentDiagnosis;
      const replacedDiagnoses = conflict.pending.reduce((accumulateDiagnosis, pending) => {
        const { key, value } = pending;
        return {
          ...accumulateDiagnosis,
          [key]: value
        };
      }, nextDiagnosis);
      const currentDiagnosisIndex = diagnosisTableData.indexOf(currentDiagnosis);
      diagnosisTableData.splice(currentDiagnosisIndex, 1, replacedDiagnoses);
      initialDiagnosisData.current = _.cloneDeep(diagnosisTableData);
      setDiagnosisTableData(_.cloneDeep(diagnosisTableData));
      setConflictsData((conflictState) => {
        return conflictState.filter(({ conflictType, key }) => {
          return !(conflictType === "diagnosis" && key === currentDiagnosis.key);
        });
      });
      await replaceDiagnosisWithPendingAPI(patientId, icd);
      SuccessMessage(t("successMessages.successfully_conflict_resolution"));
    } catch (error) {
      ErrorMessage(t("errorMessages.failed_conflict_resolution"));
      throw error;
    }
  };

  const ignoreDemographicsPendingConflict = async (name) => {
    try {
      setDemographicsFormData((demographicsData) => ({
        ...demographicsData,
        conflicts: _.omit(demographicsData.conflicts, [name])
      }));
      const { demographicDatum } = managePatientDetailsRes.data[name];

      setPatientDetails(patientId, {
        conflictResolution: {
          type: "pending_demographic_ignored",
          demographicData: [demographicDatum]
        }
      });
      setConflictsData((conflictState) => {
        return conflictState.filter(({ conflictType, name: conflictName }) => {
          return !(conflictType === "demographic" && conflictName === name);
        });
      });
      SuccessMessage(t("successMessages.successfully_conflict_resolution"));
    } catch (error) {
      ErrorMessage(t("errorMessages.failed_conflict_resolution"));
      throw error;
    }

    // setConflictAlertList((conflictAlertList) => [...conflictAlertList, popup]);
  };

  const replaceDemographicsPendingConflict = async (name) => {
    try {
      const { pendingDemographic } = patientDataForConflictsResolution.current[name];
      setDemographicsFormData((demographicsData) => ({
        ...demographicsData,
        values: {
          ...demographicsData.values,
          [name]: pendingDemographic.value
        },
        conflicts: _.omit(demographicsData.conflicts, [name])
      }));
      setPatientDetails(patientId, {
        conflictResolution: {
          type: "pending_demographic_accepted",
          demographicData: [pendingDemographic]
        }
      });
      setConflictsData((conflictState) => {
        return conflictState.filter(({ conflictType, name: conflictName }) => {
          return !(conflictType === "demographic" && conflictName === name);
        });
      });
      SuccessMessage(t("successMessages.successfully_conflict_resolution"));
    } catch (error) {
      ErrorMessage(t("errorMessages.failed_conflict_resolution"));
      throw error;
    }
  };

  const onDiagnosisRemoveHandler = (index) => {
    diagnosisTableData.splice(index, 1);
    setDiagnosisTableData([...diagnosisTableData]);
  };

  const showAddDiagnosisPopup = () => {
    addDiagnosisPopup.current();
  };

  const showAddCoveragePopup = (updatedCoverageData) => {
    setCoveragesData(updatedCoverageData);
    addCoveragePopup.current();
  };

  const showDeleteCoveragePopup = (coverage, updatedCoverageData) => {
    setCoveragesData(updatedCoverageData);
    setCoverageToDelete(coverage);
    deleteCoveragePopup.current();
  };

  const isICDExists = (icd) => {
    return diagnosisTableData.find((existedDiagnosis) => existedDiagnosis.diagnosisInfo.icd === icd);
  };

  const updateMedications = (type, attributesToUpdate, medicationData) => {
    setAllDrugEvents((prevDrugEvents) => {
      const updatedDrugs = prevDrugEvents[type].map((drugEvent) => {
        if (isDrugEventMatchedToMedication(drugEvent, medicationData)) {
          return {
            ...drugEvent,
            ...attributesToUpdate
          };
        }
        return drugEvent;
      });
      return { ...prevDrugEvents, [type]: updatedDrugs };
    });
  };

  const addNewMedication = ({ rxnormDrug, medicationData }) => {
    setAllDrugEvents((treatments) =>
      formatMedication({
        treatments,
        rxnormDrug,
        ...medicationData
      })
    );
  };

  const isDrugEventMatchedToMedication = (drugEvent, medication) => {
    return (
      (drugEvent.id && drugEvent.id === medication.id) ||
      (drugEvent.rxnormDrug && drugEvent.rxnormDrug.id === medication.rxnormDrug.id)
    );
  };

  const deleteTreatments = (medication) => {
    setAllDrugEvents((treatments) => {
      return {
        ...treatments,
        manualTreatments: _.filter(
          treatments["manualTreatments"],
          (drugEvent) => !isDrugEventMatchedToMedication(drugEvent, medication)
        )
      };
    });
  };

  const selectedView = useSelector(selectSelectedView) || selectedViews.allPatients;

  const goBackPath = useRef(history.location?.state?.path);

  const goBackToPatient = () =>
    goBackPath.current === Routes.SEARCH_RESULTS
      ? history.goBack()
      : goBackPath.current
      ? history.push(goBackPath.current)
      : history.push(`/patient/${patientId}/journey/${journeyId}`);

  const getPhysicianValue = (initialMedication, physician, rxnormDrug) => {
    //NOTE: rxnormId didnt change, no need to update physician drug rxnormId
    if (initialMedication.rxnormDrug.id === rxnormDrug.id) {
      return physician;
    }

    //NOTE: rxnormId change and a new physician assigned or deleted
    if (physician || physician === "") {
      return physician;
    }

    //NOTE: rxnormId change. take physician assigned to initialMedication
    return initialMedication.physician;
  };

  const isRxnormDrugChanged = (currentMedication, initialManualTreatments) =>
    currentMedication.rxnormDrug.id !==
    initialManualTreatments.find((x) => x.id === currentMedication.id)?.rxnormDrug.id;

  const handleSubmit = async (navigatorFunction = goBackToPatient) => {
    try {
      setIsFetching(true);
      const diagnosesEffects = getDiagnosisEffects();
      const formData = form.getFieldsValue(true);
      const {
        patientHeightInch,
        patientHeightFeet,
        coverages,
        totalIncome,
        totalMonthlyIncome,
        householdSizeAdults,
        householdSizeChildren,
        ...patientFormData
      } = formData || {};

      const requestList = [];
      let treatmentMedicationUpdate = false;
      let diagnosisUpdate = false;
      let insuranceRowsUpdate = false;

      if (getIsFormModified()) {
        if (form.isFieldsTouched()) {
          if (!_.isNil(patientHeightFeet) && !_.isNil(patientHeightInch)) {
            patientFormData.patientHeight = `${patientHeightFeet}"${patientHeightInch}`;
          }

          if (!_.isNil(householdSizeAdults) || !_.isNil(householdSizeChildren)) {
            patientFormData.householdSizeAdults = householdSizeAdults;
            patientFormData.householdSizeChildren = householdSizeChildren;

            // Check if both values are empty
            if (_.isEmpty(householdSizeAdults) && _.isEmpty(householdSizeChildren)) {
              patientFormData.householdSize = "";
            } else {
              // Convert values to integers, treating empty strings as 0
              const adults = parseInt(householdSizeAdults, 10) || 0;
              const children = parseInt(householdSizeChildren, 10) || 0;

              // Calculate household size normally
              patientFormData.householdSize = (adults + children).toString();
            }
          }

          if (patientFormData.firstName) patientFormData.firstName = patientFormData.firstName.trim();
          if (patientFormData.middleName) patientFormData.middleName = patientFormData.middleName.trim();
          if (patientFormData.lastName) patientFormData.lastName = patientFormData.lastName.trim();
          if (patientFormData.socialSecurityNumber)
            patientFormData.socialSecurityNumber = patientFormData.socialSecurityNumber
              .toString()
              .match(/\d/g)
              ?.join("");

          if (!_.isNil(totalIncome)) {
            patientFormData.totalIncome = totalIncome;
            patientFormData.monthlyIncome = totalMonthlyIncome;
          }

          const patientFormDifference = getObjectDiff(patientFormData, demographicsFormData.values);
          if (patientFormDifference.length) {
            if (patientFormData.dob) {
              patientFormData.dob = patientFormData.dob.format(dateFormat);
            }

            if (patientFormData.incomeVerifiedDate) {
              patientFormData.incomeVerifiedDate = patientFormData.incomeVerifiedDate.format(dateFormat);
            }

            if (patientFormData.agreementSignatureDate) {
              patientFormData.agreementSignatureDate = patientFormData.agreementSignatureDate.format(dateFormat);
            }

            const dataToSend = _.pick(patientFormData, patientFormDifference);
            requestList.push(setPatientDetailsAPI(patientId, dataToSend, true));
          }
        }

        const { manualTreatments: initialManualTreatments, importedTreatments: initialImportedTreatments } =
          initialDrugEventsData.current;
        const { manualTreatments, importedTreatments } = allDrugEvents;
        const treatmentPayloadBuilder = (initialTreatments, updatedTreatments, excludeFields = []) =>
          _.compact(
            initialTreatments.map((treatment, i) => {
              const currentTreatment = _.find(updatedTreatments, ({ id }) => id === treatment.id);
              if (!currentTreatment) return;
              const diffList = _.without(
                getObjectDiff(remapPhysician(treatment), remapPhysician(currentTreatment)),
                ...excludeFields
              );
              if (diffList.length > 0) {
                return _.pick(updatedTreatments[i], [...diffList, "id", "genericDrugId", "rxnormDrug"]);
              }
              return null;
            })
          );

        const addedManualTreatments = _.differenceBy(manualTreatments, initialManualTreatments, "id");
        const deletedManualTreatments = _.differenceBy(initialManualTreatments, manualTreatments, "id");

        const manualTreatmentEdited = _.differenceBy(
          manualTreatments,
          _.concat(deletedManualTreatments, addedManualTreatments),
          "id"
        );

        const editedManualTreatments = treatmentPayloadBuilder(initialManualTreatments, manualTreatmentEdited);
        const editedManualTreatmentsIgnoringProps = treatmentPayloadBuilder(
          initialManualTreatments,
          manualTreatmentEdited,
          ["physician", "isHidden", "isUncovered"]
        );
        const editedImportedTreatments = treatmentPayloadBuilder(initialImportedTreatments, importedTreatments);

        const patientHiddenDrugs = [...editedImportedTreatments, ...editedManualTreatments]
          .filter((medication) => _.has(medication, "isHidden"))
          .map(({ genericDrugId, rxnormDrug }) => ({
            genericDrug: genericDrugId,
            rxnormDrug: rxnormDrug.id,
            patient: patientId
          }));

        const uncoveredDrugs = [...editedImportedTreatments, ...editedManualTreatments]
          .filter((medication) => _.has(medication, "isUncovered"))
          .map(({ genericDrugId, rxnormDrug }) => ({
            genericDrug: genericDrugId,
            rxnormDrug: rxnormDrug.id,
            patient: patientId
          }));

        const allEditedMedications = [
          ...editedImportedTreatments,
          ...editedManualTreatments.map((x) => ({ ...x, isManual: true }))
        ];

        const physicianDrugs = allEditedMedications
          .filter((medication) => {
            return (
              _.has(medication, "physician") ||
              (medication.isManual && isRxnormDrugChanged(medication, initialManualTreatments))
            );
          })
          .map(({ id, physician, rxnormDrug, genericDrugId }) => {
            const initialMedication = [...initialManualTreatments, ...initialImportedTreatments].find(
              (x) => x.id === id
            );
            const physicianValue = getPhysicianValue(initialMedication, physician, rxnormDrug);
            return {
              genericDrug: genericDrugId,
              physician: physicianValue || "",
              patient: parseInt(patientId, 10),
              rxnormDrug: rxnormDrug.id,
              prevRxnormDrugId: initialMedication.rxnormDrug.id
            };
          });

        treatmentMedicationUpdate =
          addedManualTreatments.length ||
          deletedManualTreatments.length ||
          editedManualTreatmentsIgnoringProps.length ||
          uncoveredDrugs.length ||
          patientHiddenDrugs.length;

        addedManualTreatments.forEach(({ rxnormDrug, physician, ...medicationData }) => {
          requestList.push(
            addDrugAPI({
              rxnormDrug,
              medicationData: _.omitBy(
                {
                  ...medicationData,
                  physician
                },
                _.isNil
              ),
              patientId
            })
          );
        });

        deletedManualTreatments.forEach(({ id }) => {
          requestList.push(deleteMedicationAPI({ phiMedicationId: id, patientId }));
        });

        editedManualTreatmentsIgnoringProps.forEach((editedMedication) => {
          const initialMedication = initialManualTreatments.find((x) => x.id === editedMedication.id);
          requestList.push(
            editMedicationAPI({
              patientId,
              phiMedicationId: editedMedication.id,
              rxnormDrug: editedMedication.rxnormDrug,
              medicationData: { ...initialMedication, ...editedMedication }
            })
          );
        });

        if (patientHiddenDrugs.length > 0 || physicianDrugs.length > 0 || uncoveredDrugs.length > 0) {
          requestList.push(
            updatePatientMedicationsAPI({
              patientId,
              patientHiddenDrugs: _.uniqBy(patientHiddenDrugs, "rxnormDrug"),
              physicianDrugs: _.uniqWith(physicianDrugs, _.isEqual),
              uncoveredDrugs: _.uniqBy(uncoveredDrugs, "rxnormDrug"),
              skipAutomations: true
            })
          );
        }
        diagnosisUpdate = diagnosesEffects.length;
        requestList.push(...diagnosesEffects.map((generateRequest) => generateRequest()));
      }

      if (coverages) {
        if (!patientDetails?.isPatientUninsured && form.getFieldValue(["coverages", "isPatientUninsured"])) {
          insuranceRowsUpdate = true;
          requestList.push(
            updateUninsuredStatusAPI(patientId, form.getFieldValue(["coverages", "isPatientUninsured"]))
          );
        } else if (
          patientDetails?.isPatientUninsured !== form.getFieldValue(["coverages", "isPatientUninsured"]) ||
          form.isFieldTouched(["coverages"]) ||
          !_.isEqual(coveragesData, initialCoveragesData.current)
        ) {
          insuranceRowsUpdate = true;
          requestList.push(
            ...newCoverageRequests(form.getFieldsValue(["coverages", "patientInsuranceRows"])).map((generateRequest) =>
              generateRequest()
            )
          );
        }
      }

      if (requestList.length) {
        await Promise.all(requestList);
        await automatePatientAfterUpdate({
          patientId,
          treatmentMedicationUpdate,
          diagnosisUpdate,
          insuranceRowsUpdate,
          automateRule
        });
        //reset context values
        setCoveragesData([]);
        setLastUpdateCoverages(null);
        initialCoveragesData.current = null;

        getPatientAct(patientId, journeyId);
        setTableDataAct(selectedView);

        SuccessMessage(t("successMessages.updated_patient_details"));
      }

      if (treatmentMedicationUpdate || diagnosisUpdate) {
        await automatePatientAfterUpdate({
          patientId,
          treatmentMedicationUpdate,
          diagnosisUpdate,
          automateRule
        });
      }

      navigatorFunction();
    } catch (error) {
      ErrorMessage(t("errorMessages.update_patient_details_failed"));
    } finally {
      setIsFetching(false);
    }
  };

  const getIsFormModified = () => {
    const diagnosesEffects = getDiagnosisEffects();
    return (
      form.isFieldsTouched() ||
      diagnosesEffects?.length ||
      !_.isEqual(initialDrugEventsData.current, allDrugEvents) ||
      !_.isEqual(initialCoveragesData.current, coveragesData)
    );
  };

  const checkFormValidation = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      setFormWithoutErrors(error?.errorFields.length === 0);
    }
  };

  return (
    <>
      <ManagePatientDetailsContext.Provider
        {...props}
        value={{
          ...conflictAlertValues,
          demographicsFormData,
          diagnosisTableData,
          coveragesData,
          initialCoveragesData,
          conflictsData,
          allDrugEvents,
          initialDrugEventsData,
          isFetching,
          validEbvPayers,
          formWithoutErrors,
          updateMedications,
          calculateFPL,
          isCoverageRequestPending,
          newCoverageRequests,
          onDiagnosisChanged,
          onDiagnosisRemove,
          replaceDiagnosisPendingConflict,
          ignoreDiagnosisPendingConflict,
          showAddDiagnosisPopup,
          ignoreDemographicsPendingConflict,
          replaceDemographicsPendingConflict,
          showAddCoveragePopup,
          showDeleteCoveragePopup,
          setCoveragesData,
          addNewMedication,
          deleteTreatments,
          handleSubmit,
          getIsFormModified,
          isRxNormDrugIVAdministered,
          lastUpdate
        }}
      >
        <Form
          name="ManagePatientDetails"
          onValuesChange={checkFormValidation}
          form={form}
          onFinishFailed={() => handleSubmit()}
          onFinish={() => handleSubmit()}
          layout="vertical"
        >
          {children}
        </Form>
      </ManagePatientDetailsContext.Provider>
      <AddNewDiagnosisPopup
        isICDExists={isICDExists}
        onDiagnosisAdded={onDiagnosisAdded}
        showPopupRef={addDiagnosisPopup}
      />

      <AddNewCoveragePopup
        validEbvPayers={validEbvPayers}
        showPopupRef={addCoveragePopup}
        onCoverageAdded={onCoverageAdded}
        coverages={coveragesData}
      />
      <DeleteCoveragePopup
        showPopupRef={deleteCoveragePopup}
        onRemove={onDeleteCoverage}
        coverageToDelete={coverageToDelete}
      />
      <DeleteDiagnosisPopup onRemove={onDiagnosisRemoveHandler} showPopupRef={deleteDiagnosisPopup} />
    </>
  );
};

export default ManagePatientDetailsContextProvider;
