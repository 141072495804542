import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { HEADER_BLUE } from "../../constant/colors";
import { formPopupsContentKeys } from "../../constant/formPopupsContentKeys";
import ACTIONS from "../../store/action";

const FormPopupsButtonComponent = ({ setFormPopupsAct }) => {
  const handleClick = () => {
    setFormPopupsAct(true, formPopupsContentKeys.ADD_ASSISTANCE_PROGRAM);
  };

  return (
    <Button
      id="add_patient_assistance_program_btn"
      onClick={handleClick}
      className="dont-print"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: HEADER_BLUE,
        border: "none",
        width: "3rem",
        height: "3rem",
        position: "fixed",
        zIndex: "10",
        right: "70px",
        bottom: "85px"
      }}
      type="primary"
      shape="circle"
      icon={
        <PlusOutlined style={{ fontSize: "1.5rem", display: "flex", alignItems: "center", justifyContent: "center" }} />
      }
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFormPopupsAct: (visible, contentKey) => dispatch(ACTIONS.setFormPopups(visible, contentKey))
});

const FormPopupsButton = connect(null, mapDispatchToProps)(withTranslation()(FormPopupsButtonComponent));

export { FormPopupsButton };
