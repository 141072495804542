import { Select } from "antd";
import styled from "styled-components";

const SingleSelect = styled(Select).attrs(() => ({
  size: "large"
}))`
  .ant-select-selector {
    width: ${(props) => (props.width ? props.width : "472px")} !important;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
`;

export { SingleSelect };
