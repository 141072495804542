import React from "react";

export default function Male(props) {
  return (
    <svg width={38} height={38} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="16" cy="16" r="16" fill="#306393" />
      <g clipPath="url(#clip0_6188_27401)">
        <path
          d="M16.0001 17.7593C18.4458 17.7593 20.4293 15.3505 20.4293 12.3797C20.4293 9.40878 19.7779 7 16.0001 7C12.2222 7 11.5708 9.40878 11.5708 12.3797C11.5708 15.3505 13.5542 17.7593 16.0001 17.7593Z"
          fill="#F5F5F5"
        />
        <path d="M7.63395 25.9731C7.63309 25.7926 7.63309 25.9224 7.63395 25.9731V25.9731Z" fill="#F5F5F5" />
        <path d="M24.3647 26.1152C24.3673 26.0653 24.3656 25.7714 24.3647 26.1152V26.1152Z" fill="#F5F5F5" />
        <path
          d="M24.3557 25.7569C24.2741 20.581 23.5978 19.1063 18.4252 18.1729C18.4252 18.1729 17.6973 19.101 16.0001 19.101C14.303 19.101 13.5751 18.1729 13.5751 18.1729C8.45919 19.0958 7.74155 20.5491 7.64717 25.5893C7.63943 26.0009 7.636 26.0224 7.63428 25.9743C7.63428 26.0637 7.63514 26.2295 7.63514 26.5183C7.63514 26.5183 8.86663 29.001 16.0002 29.001C23.1338 29.001 24.3653 26.5183 24.3653 26.5183V26.1161C24.3644 26.1444 24.361 26.0877 24.3558 25.7569L24.3557 25.7569Z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_6188_27401">
          <rect width="22" height="22" fill="white" transform="translate(5 7)" />
        </clipPath>
      </defs>
    </svg>
  );
}
