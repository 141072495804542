import React, { useEffect } from "react";
import { Checkbox, Col, Divider, Row, Typography, Input, Form } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { GRAY_500, GRAY_700 } from "../../../../../../constant/colors";
import { insuranceFields } from "../fieldNames";
import { DateFormItem, FormDividerText, FormItem, InputPhone } from "../../../components";
import { fontWeights, sizes } from "../../../../../../constant/styles";
import { phoneNumberLengthRegEx } from "../../../../../../utils/formValidation";
import { buildFieldValueMap } from "../../../../../../utils/form";

const { Text } = Typography;

export const insuranceCheckList = Object.freeze({
  PHARMACY_CARD: "patientInsuranceAndPharmacyChecked",
  MEDICARE_PART_A: "insuranceMedicarePartA",
  MEDICARE_PART_B: "insuranceMedicarePartB",
  MEDICARE_PENDEING: "insuranceMedicarePending",
  MEDICAID_EFF: "insuranceMedicaid",
  MEDICAID_PENDEING: "insuranceMedicaidPending",
  CHAMPUS_EFF: "insuranceChampus",
  VA_EFF: "insuranceVA",
  VA_PENDEING: "insuranceVAPending"
});

export const insuranceCheckListDates = Object.freeze({
  MEDICARE_PART_A: [`${insuranceCheckList.MEDICARE_PART_A}Date`],
  MEDICARE_PART_B: [`${insuranceCheckList.MEDICARE_PART_B}Date`],
  MEDICAID_EFF: [`${insuranceCheckList.MEDICAID_EFF}Date`],
  CHAMPUS_EFF: [`${insuranceCheckList.CHAMPUS_EFF}Date`],
  VA_EFF: [`${insuranceCheckList.VA_EFF}Date`]
});

const StyledInsuranceText = styled(Text)`
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.semibold};
  color: ${GRAY_700};
`;

const StyledFormItem = styled(FormItem)`
  margin-bottom: 8px !important;
`;

const StyledDateFormItem = styled(DateFormItem)`
  margin-bottom: 8px !important;
`;

const EaglePemfexyCopayInsurance = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const patientCoverageUninsured = Form.useWatch(insuranceFields.patientCoverageUninsured, form);
  const hasSecondaryInsurance = Form.useWatch(insuranceFields.hasSecondaryInsurance, form);

  const isInsuranceMedicarePartAChecked = Form.useWatch(insuranceFields[insuranceCheckList.MEDICARE_PART_A], form);
  const isInsuranceMedicarePartBChecked = Form.useWatch(insuranceFields[insuranceCheckList.MEDICARE_PART_B], form);
  const isInsuranceMedicaidChecked = Form.useWatch(insuranceFields[insuranceCheckList.MEDICAID_EFF], form);
  const isInsuranceChampusChecked = Form.useWatch(insuranceFields[insuranceCheckList.CHAMPUS_EFF], form);
  const isInsuranceVAChecked = Form.useWatch(insuranceFields[insuranceCheckList.VA_EFF], form);

  useEffect(() => {
    form.validateFields();
  }, [patientCoverageUninsured, hasSecondaryInsurance]);

  useEffect(() => {
    // Note: When user unchecked any of the checkboxes, unset the date picker associated with.

    isInsuranceMedicarePartAChecked === false &&
      form.setFieldsValue(buildFieldValueMap([[insuranceFields[insuranceCheckListDates.MEDICARE_PART_A], ""]]));

    isInsuranceMedicarePartBChecked === false &&
      form.setFieldsValue(buildFieldValueMap([[insuranceFields[insuranceCheckListDates.MEDICARE_PART_B], ""]]));

    isInsuranceMedicaidChecked === false &&
      form.setFieldsValue(buildFieldValueMap([[insuranceFields[insuranceCheckListDates.MEDICAID_EFF], ""]]));

    isInsuranceChampusChecked === false &&
      form.setFieldsValue(buildFieldValueMap([[insuranceFields[insuranceCheckListDates.CHAMPUS_EFF], ""]]));

    isInsuranceVAChecked === false &&
      form.setFieldsValue(buildFieldValueMap([[insuranceFields[insuranceCheckListDates.VA_EFF], ""]]));
  }, [
    isInsuranceMedicarePartAChecked,
    isInsuranceMedicarePartBChecked,
    isInsuranceMedicaidChecked,
    isInsuranceChampusChecked,
    isInsuranceVAChecked
  ]);

  return (
    <>
      <Divider
        id="enrollment-form-insurance-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.eagle.insurance.labels.header")}</FormDividerText>
      </Divider>

      <Row gutter={14}>
        <Col span={24}>
          <Row gutter={14}>
            <Col span={24}>
              <StyledFormItem name={insuranceFields.patientCoverageUninsured} valuePropName="checked">
                <Checkbox> {t("application_form_editor.eagle.insurance.labels.not_have_insurance")}</Checkbox>
              </StyledFormItem>
            </Col>

            {!patientCoverageUninsured && (
              <>
                <Col span={12}>
                  <StyledFormItem name={insuranceFields.patientCoverageCommercial} valuePropName="checked">
                    <Checkbox> {t("application_form_editor.eagle.insurance.labels.commercial_plan")}</Checkbox>
                  </StyledFormItem>
                </Col>

                <Col span={12}>
                  <StyledFormItem name={insuranceFields.flexibleSpendingAmountParticipant} valuePropName="checked">
                    <Checkbox>
                      {t("application_form_editor.eagle.insurance.labels.flexible_spending_account_participant")}
                    </Checkbox>
                  </StyledFormItem>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>

      {!patientCoverageUninsured && (
        <>
          <Row gutter={14}>
            <Col span={8}>
              <FormItem
                name={insuranceFields.primaryInsuranceName}
                label={t("application_form_editor.eagle.insurance.labels.primary_insurance_name")}
                rules={[{ required: true }]}
              >
                <Input placeholder={t("application_form_editor.eagle.insurance.placeholders.primary_insurance_name")} />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                name={insuranceFields.primaryInsurancePhone}
                label={t("application_form_editor.eagle.insurance.labels.primary_insurance_phone")}
                rules={[{ pattern: phoneNumberLengthRegEx, message: "" }, { required: true }]}
              >
                <InputPhone
                  placeholder={t("application_form_editor.eagle.insurance.placeholders.primary_insurance_phone")}
                />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                name={insuranceFields.primaryPolicyNumber}
                label={t("application_form_editor.eagle.insurance.labels.policy_number")}
                rules={[{ required: true }]}
              >
                <Input placeholder={t("application_form_editor.eagle.insurance.placeholders.policy_number")} />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={14}>
            <Col span={8}>
              <FormItem
                name={insuranceFields.primaryGroupNumber}
                label={t("application_form_editor.eagle.insurance.labels.group_number")}
                rules={[{ required: true }]}
              >
                <Input placeholder={t("application_form_editor.eagle.insurance.placeholders.group_number")} />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                name={insuranceFields.primaryPolicyholderName}
                label={t("application_form_editor.eagle.insurance.labels.policyholder_name")}
                rules={[{ required: true }]}
              >
                <Input placeholder={t("application_form_editor.eagle.insurance.placeholders.policyholder_name")} />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                name={insuranceFields.primaryRelationshipToPatient}
                label={t("application_form_editor.eagle.insurance.labels.relationship_to_patient")}
              >
                <Input
                  placeholder={t("application_form_editor.eagle.insurance.placeholders.relationship_to_patient")}
                />
              </FormItem>
            </Col>
          </Row>

          <Col span={24}>
            <StyledFormItem name={insuranceFields.hasSecondaryInsurance} valuePropName="checked">
              <Checkbox> {t("application_form_editor.eagle.insurance.labels.secondary_insurance")}</Checkbox>
            </StyledFormItem>
          </Col>

          {hasSecondaryInsurance && (
            <>
              <Row gutter={14}>
                <Col span={8}>
                  <FormItem
                    name={insuranceFields.secondaryInsuranceName}
                    label={t("application_form_editor.eagle.insurance.labels.secondary_insurance_name")}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={t("application_form_editor.eagle.insurance.placeholders.secondary_insurance_name")}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem
                    name={insuranceFields.secondaryInsurancePhone}
                    label={t("application_form_editor.eagle.insurance.labels.secondary_insurance_phone")}
                    rules={[{ pattern: phoneNumberLengthRegEx, message: "" }, { required: true }]}
                  >
                    <InputPhone
                      placeholder={t("application_form_editor.eagle.insurance.placeholders.secondary_insurance_phone")}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem
                    name={insuranceFields.secondaryPolicyNumber}
                    label={t("application_form_editor.eagle.insurance.labels.policy_number")}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t("application_form_editor.eagle.insurance.placeholders.policy_number")} />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={14}>
                <Col span={8}>
                  <FormItem
                    name={insuranceFields.secondaryGroupNumber}
                    label={t("application_form_editor.eagle.insurance.labels.group_number")}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t("application_form_editor.eagle.insurance.placeholders.group_number")} />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem
                    name={insuranceFields.secondaryPolicyholderName}
                    label={t("application_form_editor.eagle.insurance.labels.policyholder_name")}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t("application_form_editor.eagle.insurance.placeholders.policyholder_name")} />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem
                    name={insuranceFields.secondaryRelationshipToPatient}
                    label={t("application_form_editor.eagle.insurance.labels.relationship_to_patient")}
                  >
                    <Input
                      placeholder={t("application_form_editor.eagle.insurance.placeholders.relationship_to_patient")}
                    />
                  </FormItem>
                </Col>
              </Row>
            </>
          )}
          <Row gutter={14}>
            <StyledInsuranceText style={{ marginBottom: "10px" }}>
              {t(`application_form_editor.eagle.insurance.labels.check_any_that_apply`)}
            </StyledInsuranceText>

            <Col span={24}>
              <Row>
                <StyledFormItem name={insuranceFields[insuranceCheckList.PHARMACY_CARD]} valuePropName="checked">
                  <Checkbox>{t("application_form_editor.eagle.insurance.insurance_pharmacy_card")}</Checkbox>
                </StyledFormItem>
              </Row>

              <Row>
                <StyledFormItem name={insuranceFields[insuranceCheckList.MEDICARE_PART_A]} valuePropName="checked">
                  <Checkbox>{t("application_form_editor.eagle.insurance.medicare_part_a")}</Checkbox>
                </StyledFormItem>

                <StyledDateFormItem
                  name={insuranceFields[insuranceCheckListDates.MEDICARE_PART_A]}
                  disabled={!isInsuranceMedicarePartAChecked}
                />
              </Row>

              <Row>
                <StyledFormItem name={insuranceFields[insuranceCheckList.MEDICARE_PART_B]} valuePropName="checked">
                  <Checkbox>{t("application_form_editor.eagle.insurance.medicare_part_b")}</Checkbox>
                </StyledFormItem>

                <StyledDateFormItem
                  name={insuranceFields[insuranceCheckListDates.MEDICARE_PART_B]}
                  disabled={!isInsuranceMedicarePartBChecked}
                />
              </Row>

              <Row>
                <StyledFormItem name={insuranceFields[insuranceCheckList.MEDICARE_PENDEING]} valuePropName="checked">
                  <Checkbox>{t("application_form_editor.eagle.insurance.medicare_pending")}</Checkbox>
                </StyledFormItem>
              </Row>

              <Row>
                <StyledFormItem name={insuranceFields[insuranceCheckList.MEDICAID_EFF]} valuePropName="checked">
                  <Checkbox>{t("application_form_editor.eagle.insurance.medicaid_eff")}</Checkbox>
                </StyledFormItem>

                <StyledDateFormItem
                  name={insuranceFields[insuranceCheckListDates.MEDICAID_EFF]}
                  disabled={!isInsuranceMedicaidChecked}
                />
              </Row>

              <Row>
                <StyledFormItem name={insuranceFields[insuranceCheckList.MEDICAID_PENDEING]} valuePropName="checked">
                  <Checkbox>{t("application_form_editor.eagle.insurance.medicaid_pending")}</Checkbox>
                </StyledFormItem>
              </Row>

              <Row>
                <StyledFormItem name={insuranceFields[insuranceCheckList.CHAMPUS_EFF]} valuePropName="checked">
                  <Checkbox>{t("application_form_editor.eagle.insurance.champus_eff")}</Checkbox>
                </StyledFormItem>

                <StyledDateFormItem
                  name={insuranceFields[insuranceCheckListDates.CHAMPUS_EFF]}
                  disabled={!isInsuranceChampusChecked}
                />
              </Row>

              <Row>
                <StyledFormItem name={insuranceFields[insuranceCheckList.VA_EFF]} valuePropName="checked">
                  <Checkbox>{t("application_form_editor.eagle.insurance.va_insurance_eff")}</Checkbox>
                </StyledFormItem>

                <StyledDateFormItem
                  name={insuranceFields[insuranceCheckListDates.VA_EFF]}
                  disabled={!isInsuranceVAChecked}
                />
              </Row>

              <Row>
                <StyledFormItem name={insuranceFields[insuranceCheckList.VA_PENDEING]} valuePropName="checked">
                  <Checkbox>{t("application_form_editor.eagle.insurance.va_insurance_pending")}</Checkbox>
                </StyledFormItem>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default EaglePemfexyCopayInsurance;
