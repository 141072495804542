import React from "react";
import { Col, Divider, Row, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

import { GRAY_500 } from "../../../../../../constant/colors";
import { financialFields } from "../fieldNames";
import { FormDividerText, FormItem } from "../../../components";
import { minNumberValidation } from "../../../../../../utils/formValidation";
import { InputDollar } from "../../../components/InputDollar";

export default function Financial() {
  const { t } = useTranslation();

  return (
    <>
      <Divider
        id="enrollment-form-financial-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.pfizer.financial.header")}</FormDividerText>
      </Divider>
      <Row gutter={14}>
        <Col span={12}>
          <FormItem
            rules={[{ required: true, message: "" }, minNumberValidation(1)]}
            labelCol={{ span: 24 }}
            label={t("application_form_editor.pfizer.financial.labels.total_number_of_people")}
            name={financialFields.householdSize}
          >
            <InputNumber
              placeholder={t("application_form_editor.pfizer.financial.placeholders.total_number_of_people")}
              style={{ width: "100%" }}
              min={0}
              controls={false}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            rules={[{ required: true, message: "" }, minNumberValidation(1)]}
            labelCol={{ span: 24 }}
            label={t("application_form_editor.pfizer.financial.labels.total_annual_household_income")}
            name={financialFields.householdIncome}
          >
            <InputDollar
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.pfizer.financial.placeholders.total_annual_household_income")}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
}
