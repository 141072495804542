import React from "react";

import { GRAY_600 } from "../../constant/colors";

const ArrowBack = ({ color = GRAY_600, rotate }) => {
  return (
    <svg
      style={{ transform: rotate ? "rotate(180deg)" : "", display: "flex" }}
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 6.5H0.5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 1.5L0.5 6.5L5.5 11.5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowBack;
