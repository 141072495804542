export const roles = Object.freeze({
  FUND_MANAGER: 15,
  FUND_MANAGER_USER: 14,
  OPERATION_MANGER: 5,
  INSIGHTS: 4,
  FINANCIAL_NAVIGATOR: 2
});

export const eligibleUserRoles = [
  roles.FINANCIAL_NAVIGATOR,
  roles.INSIGHTS,
  roles.FUND_MANAGER,
  roles.FUND_MANAGER_USER
];
