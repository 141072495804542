import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Space, Typography, Modal, Input, Button, Radio } from "antd";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import {
  selectFormPopups,
  selectSelectedView,
  selectFilters,
  selectIsFetching,
  selectFilterList,
  getUserSelectedColumns
} from "../../store/selector";
import { formPopupsContentKeys } from "../../constant/formPopupsContentKeys";
import ACTIONS from "../../store/action";
import { fontWeights, sizes } from "../../constant/styles";
import { ReactComponent as PrivateIcon } from "../../assets/svg/private-icon.svg";
import { DARK_GRAY3, DARK_GRAY2, BLUE_2, BLACK_99 } from "../../constant/colors";
import { ApiNames } from "../../api/api";
import { selectedViews } from "../../constant/table";

const { Text } = Typography;
const { TextArea } = Input;

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    border-top: 0px;
    padding: 0px 24px 24px 24px;
  }
  .ant-modal-body {
    padding: 24px 24px 40px 24px;
  }
  .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0px 19px 76px rgba(0, 0, 0, 0.15);
  }
  .ant-btn {
    border-radius: 4px;
  }
  .ant-btn-default {
    border: 1px solid ${DARK_GRAY2};
  }
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-left: 12px;
  }
  .ant-btn-primary {
    background-color: ${BLUE_2};
    border: 1px solid ${BLUE_2};
  }
  .ant-btn-primary[disabled] {
    background-color: #b2ddff;
    border: 1px solid #b2ddff;
    color: #ffffff;
  }
`;

const StyledRadio = styled(Radio.Group)`
  .ant-radio-inner::after {
    background-color: ${BLUE_2};
  }
`;
const DescriptionText = styled(Text)`
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.regular};
  color: ${DARK_GRAY3};
  display: flex;
`;

const PrivateText = styled(Text)`
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.semibold};
  padding-left: 5px;
`;

const StyledText = styled(Text)`
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.semibold};
`;

const PUBLIC = "public";
const PRIVATE = "private";

const AddFilterListFormComponent = ({
  t,
  setFormPopupsAct,
  formPopups,
  selectedView,
  filters,
  createFilterListAct,
  isFetching,
  filterList,
  updateFilterListAct,
  userSelectedColumns
}) => {
  const [form] = Form.useForm();

  const { visible, contentKey } = formPopups;
  const [listNameField, setListNameField] = useState(null);

  useEffect(() => {
    // Note: (Edit Mode) populate the form data with the saved list
    if (Object.keys(filterList).length > 0) {
      form.setFieldsValue({
        listName: filterList.name,
        listType: filterList.isPrivate === 0 ? PUBLIC : PRIVATE,
        description: filterList.description
      });
      setListNameField(!!form.getFieldValue("listName")?.trim());
    } else {
      cleanForm();
    }
  }, [filterList, selectedView, formPopups]);

  const handleAddButton = () => {
    const filterListValues = {
      name: form.getFieldValue("listName").trim(),
      description: form.getFieldValue("description")?.trim(),
      isPrivate: form.getFieldValue("listType") === PRIVATE ? true : false,
      params: { filters, selectedColumns: userSelectedColumns },
      // Note: in case the save action conducted from the saved list view, need to pass the current saved list view value (patient, visits, etc)
      selectedView: selectedView === selectedViews.savedList ? filterList.selectedView : selectedView
    };
    form.validateFields().then(() => {
      filterList.isEditView
        ? updateFilterListAct(filterListValues, filterList?.id)
        : createFilterListAct(filterListValues);
    });
  };

  const onChangelistName = (value) => {
    setListNameField(value?.trim());
  };

  const handleCancelButton = () => {
    cleanForm();
    setFormPopupsAct(false, formPopupsContentKeys.ADD_NEW_LIST);
  };

  const cleanForm = () => {
    form.resetFields();
    setListNameField(null);
  };

  return (
    <StyledModal
      open={visible && contentKey === formPopupsContentKeys.ADD_NEW_LIST}
      centered
      okText={t("new_list_form.save")}
      cancelText={t("new_list_form.cancel")}
      width="520px"
      maskStyle={{
        backgroundColor: BLACK_99
      }}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleCancelButton} id="filters_saves_cancel">
            {t("new_list_form.cancel")}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            onClick={handleAddButton}
            disabled={!listNameField}
            loading={isFetching}
            id="filters_saves_save"
          >
            {filterList.isEditView ? t("new_list_form.update") : t("new_list_form.save")}
          </Button>
        </div>
      }
      destroyOnClose={true}
      afterClose={cleanForm}
    >
      <Text style={{ fontSize: sizes.large, fontWeight: fontWeights.semibold }}>
        {filterList.isEditView ? t("new_list_form.edit_list_info") : t("new_list_form.save_as_new_list")}
      </Text>
      <Form form={form} initialValues={{ listType: PRIVATE }} style={{ paddingTop: "24px" }}>
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <StyledText>{t("new_list_form.list_name.title")}</StyledText>
          <Form.Item
            name="listName"
            style={{ marginTop: 8, padding: 0 }}
            onChange={(e) => onChangelistName(e.target.value)}
          >
            <Input maxLength={128} style={{ width: "100%" }} placeholder={t("new_list_form.list_name.placeholder")} />
          </Form.Item>
          <StyledText>{t("new_list_form.description.title")}</StyledText>
          <Form.Item name="description" style={{ marginTop: 8, padding: 0 }}>
            <TextArea
              placeholder={t("new_list_form.description.placeholder")}
              autoSize={{ minRows: 5, maxRows: 5 }}
              maxLength={255}
              style={{
                borderRadius: "4px",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                border: `1px solid ${DARK_GRAY2}`,
                padding: "8px 12px"
              }}
            />
          </Form.Item>
          <StyledText>{t("new_list_form.list_type.title")}</StyledText>
          <Form.Item name="listType" style={{ marginTop: 8, padding: 0 }}>
            <StyledRadio>
              <Space direction="vertical">
                <Radio value={PRIVATE} id="filters_saves_private">
                  <div>
                    <PrivateIcon style={{ width: "14px", height: "14px" }} />
                    <PrivateText>{t("new_list_form.list_type.private.title")}</PrivateText>
                    <DescriptionText>{t("new_list_form.list_type.private.description")}</DescriptionText>
                  </div>
                </Radio>
                <Radio value={PUBLIC} id="filters_saves_public">
                  <StyledText>{t("new_list_form.list_type.public.title")}</StyledText>
                  <DescriptionText>{t("new_list_form.list_type.public.description")}</DescriptionText>
                </Radio>
              </Space>
            </StyledRadio>
          </Form.Item>
        </Space>
      </Form>
    </StyledModal>
  );
};

const mapStateToProps = createStructuredSelector({
  formPopups: selectFormPopups,
  selectedView: selectSelectedView,
  filters: selectFilters,
  filterList: selectFilterList,
  isFetching: selectIsFetching([ApiNames.CreateFilterList, ApiNames.UpdateFilterList]),
  userSelectedColumns: getUserSelectedColumns
});

const mapDispatchToProps = (dispatch) => ({
  setFormPopupsAct: (visible, contentKey) => dispatch(ACTIONS.setFormPopups(visible, contentKey)),
  updateFilterListAct: (newFilterListData, filterListId) =>
    dispatch(ACTIONS.updateFilterListAction(newFilterListData, filterListId)),
  createFilterListAct: (filterListData) => dispatch(ACTIONS.createFilterListAction(filterListData))
});

const FilterListForm = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddFilterListFormComponent));

export { FilterListForm };
