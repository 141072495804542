import React from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "antd";

import { ProviderTypes } from "../../../../constants";
import { Physician } from "./physicianAndFacility/Physician";
import { Facility } from "./physicianAndFacility/Facility";
import { FormDividerText } from "../../../../components";
import { GRAY_300 } from "../../../../../../../constant/colors";
import { getFieldsToUpdateOnProviderChange } from "../../../../utils/provider";
import { getStateCode } from "../../../../../../../constant/states";

const STATE = "state";

export const onProviderChange = async (
  providerId,
  formToProviderFieldMap,
  providerType,
  providers,
  form,
  additionalFieldsToUpdate
) => {
  let formSectionName;
  if (providerType === ProviderTypes.Facility) {
    formSectionName = "facility";
  } else {
    formSectionName = "physician";
  }

  let fieldsToUpdate = getFieldsToUpdateOnProviderChange(
    providers,
    providerId,
    formToProviderFieldMap,
    additionalFieldsToUpdate
  );

  fieldsToUpdate[STATE] = fieldsToUpdate[STATE] ? getStateCode(fieldsToUpdate[STATE]) : null;

  form.setFieldsValue({ [formSectionName]: { ...fieldsToUpdate } });
};

export function PhysicianAndFacility() {
  const { t } = useTranslation();

  return (
    <>
      <div id="enrollment-form-physician_and_facility-information" />
      <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_300 }}>
        <FormDividerText>
          {t("application_form_editor.genentech.copay.physician_and_facility.doctor_header")}
        </FormDividerText>
      </Divider>
      <Physician />
      <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_300 }}>
        <FormDividerText>
          {t("application_form_editor.genentech.copay.physician_and_facility.facility_header")}
        </FormDividerText>
      </Divider>
      <Facility />
    </>
  );
}
