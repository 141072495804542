import React, { useEffect } from "react";
import { Col, Form, Input, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";

import { DobFormItem, FormItem } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";

export const PolicyholderDetails = ({
  policyholderSameAsPatientField,
  policyholderNameField,
  policyholderRelationshipField,
  policyholderDOBField
}) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const policyholderSameAsPatient = Form.useWatch(policyholderSameAsPatientField, form);

  useEffect(() => {
    form.validateFields();
  }, [policyholderSameAsPatient]);

  return (
    <>
      <Row gutter={14}>
        <Col span={24}>
          <FormItem
            rules={[{ required: true }]}
            style={{ paddingTop: 10 }}
            name={policyholderSameAsPatientField}
            label={t("application_form_editor.pfizer.insurance.labels.policyholder_same_as_patient")}
          >
            <Radio.Group>
              <Radio value={true}>{t("application_form_editor.pfizer.insurance.yes")}</Radio>
              <Radio value={false}>{t("application_form_editor.pfizer.insurance.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      {policyholderSameAsPatient === false && (
        <Row gutter={14}>
          <Col span={12}>
            <FormItem
              name={policyholderNameField}
              label={t("application_form_editor.pfizer.insurance.labels.policyholder_full_name")}
              rules={[{ required: true }, onlyWhitespacesRule]}
            >
              <Input placeholder={t("application_form_editor.pfizer.insurance.placeholders.policyholder_full_name")} />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem
              rules={[{ required: true }, onlyWhitespacesRule]}
              label={t("application_form_editor.pfizer.insurance.labels.policyholder_relationship")}
              name={policyholderRelationshipField}
            >
              <Input
                placeholder={t("application_form_editor.pfizer.insurance.placeholders.policyholder_relationship")}
              />
            </FormItem>
          </Col>
          <Col span={6}>
            <DobFormItem
              name={policyholderDOBField}
              label={t("application_form_editor.pfizer.insurance.labels.policyholder_dob")}
              placeholder={t("application_form_editor.pfizer.insurance.placeholders.policyholder_dob")}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
