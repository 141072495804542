import React from "react";
import { Col, Input, Row } from "antd";
import { useTranslation } from "react-i18next";

import { prescriptionFields } from "../../fieldNames";
import { DateFormItem, FormItem } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";
import { PatientInformation } from "./PatientInformation";

export default function IvForm() {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={14}>
        <Col span={24}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={prescriptionFields.dosingInstructions}
            label={t("application_form_editor.pfizer.prescription.labels.directions")}
          >
            <Input placeholder={t("application_form_editor.pfizer.prescription.placeholders.directions")} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            label={t("application_form_editor.pfizer.prescription.labels.number_of_vials")}
            name={prescriptionFields.numberOfVials}
          >
            <Input
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.pfizer.prescription.placeholders.number_of_vials")}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            label={t("application_form_editor.pfizer.prescription.labels.refills")}
            name={prescriptionFields.numberOfRefills}
          >
            <Input
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.pfizer.prescription.placeholders.refills")}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            label={t("application_form_editor.pfizer.prescription.labels.frequency")}
            name={prescriptionFields.frequency}
          >
            <Input
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.pfizer.prescription.placeholders.frequency")}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <DateFormItem
            required
            name={prescriptionFields.treatmentStartDate}
            label={t("application_form_editor.pfizer.prescription.labels.treatment_start_date")}
          />
        </Col>
      </Row>
      <PatientInformation />
    </>
  );
}
