import React, { Component } from "react";
import OktaSignIn from "@okta/okta-signin-widget";

import { withTranslation } from "react-i18next";

import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import { GREEN } from "../constant/colors";
import { oktaConfig } from "../constant/okta";

import "./OktaSignInWidget.css";
class OktaSignInWidget extends Component {
  renderOktaSelfWidget() {
    const { requestContext, baseUrl } = oktaConfig;

    const {
      oktaAuth,
      idpConfig: { IDPEnabled }
    } = this.props;
    this.widget = new OktaSignIn({
      features: {
        rememberMe: false,
        idpDiscovery: IDPEnabled,
        selfServiceUnlock: true
      },
      ...(IDPEnabled && {
        idpDiscovery: {
          requestContext
        }
      }),
      i18n: {
        en: {
          "primaryauth.title": " ",
          "primaryauth.submit": "Login"
        }
      },
      colors: {
        brand: GREEN
      },
      helpLinks: {
        custom: [
          {
            text: this.props.t("login_email_support"),
            href: this.props.t("email_to_support")
          }
        ]
      },
      authClient: oktaAuth,
      baseUrl
    });

    const el = this.node;
    const { onSuccess, onError } = this.props;
    this.widget.renderEl({ el }, onSuccess, onError);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idpConfig.IDPEnabled !== this.props.idpConfig.IDPEnabled) {
      this.widget.remove();
      this.renderOktaSelfWidget();
    }
  }

  componentDidMount() {
    this.renderOktaSelfWidget();
  }

  componentWillUnmount() {
    this.widget.remove();
  }

  render() {
    return <div ref={(node) => (this.node = node)} />;
  }
}

export default withTranslation()(OktaSignInWidget);
