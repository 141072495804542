import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import TableFilters from "../TableFilters";
import { AllPatientsTable } from "./AllPatientsTable";
import { VisitScheduleTable } from "./VisitScheduleTable";
import { HighPrioritiesTable } from "./HighPrioritiesTable";
import { SavedListTable } from "./SavedListTable";
import { AllApplicationsTable } from "./AllApplicationsTable";
import { selectSelectedView } from "../../store/selector";
import { selectedViews } from "../../constant/table";
import { PendingRenewalTable } from "./PendingRenewalTable";
import { FilterListForm } from "../../component/FormPopups/FilterListForm";
import { AllClaimsTable } from "./AllClaimsTable";

const TableSectionComponent = ({ selectedView }) => {
  const [filtersVisible, setFiltersVisible] = useState(false);
  useEffect(() => {
    setFiltersVisible(false);
  }, [selectedView]);

  const showFilters = selectedView !== selectedViews.savedList;
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: filtersVisible ? "row" : "column",
        justifyContent: "flex-start",
        gap: "10px"
      }}
    >
      {showFilters && <TableFilters filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible} />}
      {selectedView === selectedViews.allPatients && (
        <AllPatientsTable isSearch={false} isFilterOpen={filtersVisible} />
      )}
      {selectedView === selectedViews.visitSchedule && <VisitScheduleTable isFilterOpen={filtersVisible} />}
      {selectedView === selectedViews.pendingRenewal && <PendingRenewalTable isFilterOpen={filtersVisible} />}
      {selectedView === selectedViews.highPriority && <HighPrioritiesTable isFilterOpen={filtersVisible} />}
      {selectedView === selectedViews.savedList && <SavedListTable />}
      {selectedView === selectedViews.allApplications && <AllApplicationsTable isFilterOpen={filtersVisible} />}
      {selectedView === selectedViews.allClaims && <AllClaimsTable isFilterOpen={filtersVisible} />}
      <FilterListForm />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedView: selectSelectedView
});

const TableSection = connect(mapStateToProps)(TableSectionComponent);

export { TableSection };
