import React, { useRef, useState } from "react";

import IconPopup from "./common/IconPopup";
import { ReactComponent as PopupIconError } from "../../assets/svg/delete-new-icon.svg";
import { ReactComponent as PopupIconWarning } from "../../assets/svg/PopupIconWarning.svg";
import { OutlinedButton, ErrorButton, PrimaryButton } from "../customComponent/Button";

const getProps = (action, targetName) => {
  const defaults = {
    icon: <PopupIconWarning />,
    button: PrimaryButton,
    buttonLabel: "Ok",
    text: null,
    title: null
  };
  switch (action) {
    case "hide":
      return {
        ...defaults,
        title: "Hide Treatment",
        text: "Hiding a drug will have an impact on all occurrences of that drug."
      };
    case "cover":
      return {
        ...defaults,
        title: "Set a treatment as uncovered",
        text: "Setting a drug as uncovered by the payer will have an impact on all occurrences of that drug."
      };
    case "delete":
      return {
        ...defaults,
        buttonLabel: "Yes, Delete",
        icon: <PopupIconError />,
        button: ErrorButton,
        title: "Delete Treatment",
        text: `Are you sure you want to delete ‘${targetName}’?`
      };
    default:
      return defaults;
  }
};

const TreatmentChangeStatusPopup = ({ showPopupRef }) => {
  const [popupShown, setPopupShown] = useState(false);
  const onSubmit = useRef();
  const requiredAction = useRef();
  const drugNameRef = useRef();
  const showPopup = (action, drug, onSubmitTreatmentAction) => {
    requiredAction.current = action;
    drugNameRef.current = drug.rxnormDrug.tmDisplayName;
    onSubmit.current = onSubmitTreatmentAction;
    setPopupShown(true);
  };
  const onCancelHandler = () => {
    setPopupShown(false);
  };
  const onActionHandler = () => {
    if (onSubmit.current) onSubmit.current();
    setPopupShown(false);
  };
  showPopupRef.current = showPopup;

  const {
    button: ActionButton,
    buttonLabel: actionLabel,
    text,
    ...popupProps
  } = getProps(requiredAction.current, drugNameRef.current);

  return (
    <IconPopup
      open={popupShown}
      onOk={onActionHandler}
      onCancel={onCancelHandler}
      footer={[
        <OutlinedButton key="back" onClick={onCancelHandler}>
          Back
        </OutlinedButton>,
        <ActionButton key="delete" onClick={onActionHandler}>
          {actionLabel}
        </ActionButton>
      ]}
      {...popupProps}
    >
      <p>{text}</p>
    </IconPopup>
  );
};

export { TreatmentChangeStatusPopup };
