import React from "react";
import { useTranslation } from "react-i18next";
import { Space, Typography, Row, Col, Image } from "antd";
import { PhoneOutlined } from "@ant-design/icons";

import { BLUE_DARK } from "../../constant/colors";
import { sizes } from "../../constant/styles";
import { getClickableLink } from "../../utils/formaters";

const { Text } = Typography;

const PatientReportContact = ({ assistanceProgram }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col span={24} style={{ paddingRight: "16px", maxHeight: "70px" }}>
        <Space size={16}>
          {assistanceProgram && assistanceProgram.url && (
            <Space direction="horizontal" style={{ alignItems: "center" }}>
              <Image
                src={`${process.env.PUBLIC_URL}/images/assistance-program-website.png`}
                preview={false}
                width={15}
                height={12}
              />
              <a
                style={{ color: BLUE_DARK, fontSize: sizes.medium }}
                href={getClickableLink(assistanceProgram.url)}
                target="_blank"
                rel="noreferrer"
              >
                {t("patientPotentialSavings.application_link")}
              </a>
            </Space>
          )}
          {assistanceProgram && assistanceProgram.website && (
            <Space direction="horizontal" align="start">
              <Image
                src={`${process.env.PUBLIC_URL}/images/assistance-program-website.png`}
                preview={false}
                width={15}
                height={12}
              />
              <a
                style={{ color: BLUE_DARK, fontSize: sizes.medium }}
                href={getClickableLink(assistanceProgram.website)}
                target="_blank"
                rel="noreferrer"
              >
                {t("patientPotentialSavings.program_website")}
              </a>
            </Space>
          )}
          {assistanceProgram && assistanceProgram.pharmacyWebsite && (
            <Space direction="horizontal" align="start">
              <Image
                src={`${process.env.PUBLIC_URL}/images/assistance-program-website.png`}
                preview={false}
                width={15}
                height={12}
              />
              <a
                style={{ color: BLUE_DARK, fontSize: sizes.medium }}
                href={getClickableLink(assistanceProgram.pharmacyWebsite)}
                target="_blank"
                rel="noreferrer"
              >
                {t("patientPotentialSavings.pharmacy_website")}
              </a>
            </Space>
          )}
          {assistanceProgram && assistanceProgram.phone1 && assistanceProgram.phone1.length && (
            <Space direction="horizontal" style={{ display: "flex", alignItems: "center" }}>
              <PhoneOutlined style={{ color: BLUE_DARK }} />
              <Text style={{ color: BLUE_DARK, fontSize: sizes.medium }}>{assistanceProgram.phone1}</Text>
            </Space>
          )}
          {assistanceProgram && assistanceProgram.phone2 && assistanceProgram.phone2.length && (
            <Space direction="horizontal" style={{ display: "flex", alignItems: "center" }}>
              <PhoneOutlined style={{ color: BLUE_DARK }} />
              <Text style={{ color: BLUE_DARK, fontSize: sizes.medium }}>{assistanceProgram.phone2}</Text>
            </Space>
          )}
          {assistanceProgram && assistanceProgram.address && assistanceProgram.address.length && (
            <Space direction="horizontal" align="start">
              <Image
                src={`${process.env.PUBLIC_URL}/images/assistance-program-address.png`}
                preview={false}
                width={10}
                height={13}
              />
              <Text style={{ color: BLUE_DARK, fontSize: sizes.medium }}>{assistanceProgram.address}</Text>
            </Space>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default PatientReportContact;
