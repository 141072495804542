import { ivAdminTypes, TM_IV_ROUTE_TYPES, BRANDED_TERM_TYPES } from "../constant/drugs";

// [DRUGS-TODO-YUVAL]: Remove once all RxnormDrug references have been switched to using tmRouteType:
export const isIvType = (adminType) => {
  return adminType && ivAdminTypes.includes(adminType.toLowerCase());
};

export const formatRxnormDrugLabelForSearch = (pinName, bnName) => {
  return bnName ? `${pinName} (${bnName})` : pinName;
};

export const isRxNormDrugIVAdministered = ({ tmRouteType }) => TM_IV_ROUTE_TYPES.includes(tmRouteType);
export const isRxNormDrugBranded = ({ tty }) => Object.values(BRANDED_TERM_TYPES).includes(tty);

export const formatPapRxnormDrugName = (rxnormDrug) =>
  rxnormDrug?.bnName || rxnormDrug?.pinName.split("|").join(" + ") || "";

export const algorithmOneDrugsSorting = (drugs) =>
  drugs.sort((a, b) => {
    if (a.pinName === b.pinName) {
      return (a.bnName || "").localeCompare(b.bnName || "", { numeric: true });
    } else {
      return a.pinName.localeCompare(b.pinName, { numeric: true });
    }
  });

export const getAPDrugDisplayName = (rxnormDrug) => {
  const { bnName, pinName, dfName } = rxnormDrug;

  let displayName = pinName.split("|").join(" + ");

  if (bnName) {
    displayName = `${bnName} (${displayName})`;
  }

  if (dfName) {
    displayName = `${displayName} ${dfName}`;
  }

  return displayName;
};
