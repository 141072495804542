import React from "react";
import { Popover, Space, Typography } from "antd";

import { fontWeights, sizes } from "../../../../../constant/styles";
import { GRAY_700 } from "../../../../../constant/colors";

const { Text } = Typography;

const PermissionPopover = ({ hasPermission, children, disabledMessage }) => {
  if (hasPermission) {
    return children;
  }

  return (
    <Popover
      trigger="hover"
      overlayInnerStyle={{ borderRadius: "8px" }}
      content={
        <Space style={{ width: 350, padding: "5px 20px", display: hasPermission ? `none` : `block` }}>
          <Text style={{ fontWeight: fontWeights.semibold, fontSize: sizes.small, color: GRAY_700 }}>
            {disabledMessage}
          </Text>
        </Space>
      }
    >
      {children}
    </Popover>
  );
};

export default PermissionPopover;
