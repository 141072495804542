import { INTERNAL_PROGRAM, prioritiesMap } from "../constant/assistanceProgram";

export const formatAPType = (t, { priority, internal }) => {
  if (internal) {
    return t(`patientPotentialSavings.${prioritiesMap[INTERNAL_PROGRAM].text}`);
  } else {
    return prioritiesMap[priority] ? t(`patientPotentialSavings.${prioritiesMap[priority].text}`) : "";
  }
};

export const getApplicationDisplayName = (application) => {
  const res = application.associatedDrugs.length
    ? `${application.potentialSaving.name} (${application.associatedDrugs.join(", ")})`
    : application.displayName;
  return res;
};
