import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Select, Space, Typography } from "antd";

import { updatePatientFacility } from "../../../api/api";
import { selectPatientDetails, selectProvidersFacility } from "../../../store/selector";
import ErrorMessage from "../../customComponent/customMessages/ErrorMessage";
import SuccessMessage from "../../customComponent/customMessages/SuccessMessage";
import ACTIONS from "../../../store/action";
import { fontWeights, sizes } from "../../../constant/styles";
import { GRAY7, TEXT4 } from "../../../constant/colors";
import { SingleSelect } from "../../customComponent/CustomSelect";
import { filterSort } from "../../../utils/sort";

const { Option } = Select;
const { Text } = Typography;

const secondaryTextStyle = { fontSize: sizes.small, color: TEXT4, fontWeight: fontWeights.semibold };

const PatientFacility = ({ selectStyle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [hover, setHover] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [patientFacility, setPatientFacility] = useState(null);
  const { patientId } = useParams();

  const facilities = useSelector(selectProvidersFacility);
  const patientDetails = useSelector(selectPatientDetails);

  useEffect(() => {
    if (patientDetails && facilities && facilities.length) {
      const facility = facilities.find((facility) => facility.name === patientDetails.facility);
      if (facility) {
        setPatientFacility(facility.id);
      }
    }
  }, [facilities, patientDetails]);

  const updateFacilityHandler = async (value) => {
    try {
      setIsFetching(true);
      const updateAssigneeRes = await updatePatientFacility(patientId, value);
      if (updateAssigneeRes && updateAssigneeRes.data && updateAssigneeRes.data.success) {
        SuccessMessage(t("successMessages.successfully_updated_facility"));
        const facility = facilities.find((facility) => facility.id === value);
        dispatch(ACTIONS.updatePatientDetailsAction({ ...patientDetails, facility: facility.name }));
      } else {
        ErrorMessage(t("errorMessages.failed_to_update_facility"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.failed_to_update_facility"));
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Space direction="vertical" size={0} style={{ width: "100%" }}>
      <Text style={{ ...secondaryTextStyle, paddingLeft: 12 }}>{t("patientTopSection.facility")}</Text>
      <SingleSelect
        filterSort={filterSort}
        useCommonStyle={false}
        id="patient_header_facility"
        showArrow={hover || isFetching}
        allowClear={false}
        loading={isFetching}
        disabled={isFetching}
        dropdownMatchSelectWidth={false}
        onChange={updateFacilityHandler}
        placeholder={<Text style={{ color: "#98A2B3", fontWeight: 400 }}>{t("no_facility")}</Text>}
        value={patientFacility}
        style={{ ...selectStyle, backgroundColor: hover || isFetching ? GRAY7 : "transparent" }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {facilities &&
          facilities.map((facility, index) => (
            <Option key={index} value={facility.id} localSortKey={facility.name}>
              {facility.name}
            </Option>
          ))}
      </SingleSelect>
    </Space>
  );
};

export { PatientFacility };
