import React from "react";

const CoveragesIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 15V1" stroke="#98A2B3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M2.34375 8.5H14.6558"
      stroke="#98A2B3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 1C7.1 2.4 4.767 3.8 1.5 4.267C1.5 4.267 1.5 12.2 8.5 15C15.5 12.2 15.5 4.267 15.5 4.267C12.233 3.8 9.9 2.4 8.5 1Z"
      stroke="#98A2B3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { CoveragesIcon };
