import React from "react";

const PersonalIcon = ({ style = {} }) => {
  return (
    <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1042_85589)">
        <path
          style={style}
          d="M8 6.5C9.65685 6.5 11 5.15685 11 3.5C11 1.84315 9.65685 0.5 8 0.5C6.34315 0.5 5 1.84315 5 3.5C5 5.15685 6.34315 6.5 8 6.5Z"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          style={style}
          d="M8 9C6.27609 9 4.62279 9.68482 3.40381 10.9038C2.18482 12.1228 1.5 13.7761 1.5 15.5H14.5C14.5 13.7761 13.8152 12.1228 12.5962 10.9038C11.3772 9.68482 9.72391 9 8 9V9Z"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1042_85589">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default PersonalIcon;
