import React, { useRef, useState } from "react";

import { ReactComponent as DeleteIcon } from "../../assets/svg/delete-new-icon.svg";
import IconPopup from "./common/IconPopup";
import { OutlinedButton, ErrorButton } from "../customComponent/Button";

const DeleteDiagnosisPopup = ({ showPopupRef, onRemove = () => {} }) => {
  const diagnosisName = useRef("");
  const diagnosisIndex = useRef(-1);
  const [popupShown, setPopupShown] = useState(false);
  const showPopup = (index, record) => {
    const { diagnosisInfo } = record;
    diagnosisIndex.current = index;
    diagnosisName.current = diagnosisInfo.name;
    setPopupShown(true);
  };
  const onCancelHandler = () => {
    setPopupShown(false);
  };
  const onRemoveHandler = () => {
    onRemove(diagnosisIndex.current);
    setPopupShown(false);
  };
  showPopupRef.current = showPopup;
  return (
    <IconPopup
      icon={<DeleteIcon />}
      open={popupShown}
      title="Delete Diagnosis"
      onOk={onRemoveHandler}
      onCancel={onCancelHandler}
      footer={[
        <OutlinedButton key="back" onClick={onCancelHandler} id="delete_diagnosis_back">
          Back
        </OutlinedButton>,
        <ErrorButton key="delete" onClick={onRemoveHandler} id="delete_diagnosis_delete">
          Yes, Delete
        </ErrorButton>
      ]}
    >
      <p>Are you sure you want to delete ‘{diagnosisName.current}’?</p>
    </IconPopup>
  );
};

export { DeleteDiagnosisPopup };
