import React, { useContext, useEffect, useState } from "react";
import { Typography, Divider, Col, Row, Space, Switch, Form, Menu, Tooltip, Alert } from "antd";
import _ from "lodash";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { EllipsisOutlined, InfoCircleOutlined } from "@ant-design/icons";

import InsuranceCard from "./common/InsuranceCard";
import { ManagePatientDetailsContext } from "./context";
import SubSectionHeader from "./SubSectionHeader";
import { DropdownMenu } from "../DropdownMenu";
import EmptyListMessage from "../sharedComponents/EmptyListMessage";
import { NoCoveragesYetIcon } from "../../assets/svg/NoCoveragesYetIcon";
import { OutlinedButton, PrimaryButton, SecondaryButton } from "../../component/customComponent/Button";
import { ReactComponent as WarningMark } from "../../assets/svg/warning-mark.svg";
import { ReactComponent as TrashBinIcon } from "../../assets/svg/TrashBin.svg";
import { ReactComponent as CoverageConflictIndicatorIcon } from "../../assets/svg/coverageConflictIndicator.svg";
import { ReactComponent as SwitchInsuranceIcon } from "../../assets/svg/SwitchInsuranceIcon.svg";
import { useGetSetting } from "../../hooks/getSetting";
import { selectPatientDetails } from "../../store/selector";
import { PopupStyledModal } from "../customComponentNewDesign/CustomPopupModal";
import { ReactComponent as WarningPopupIcon } from "../../assets/svg/warning-mark.svg";
import { PRIMARY_700, PRIMARY_50, ERROR_600, ERROR_25 } from "../../constant/colors";
import { font18pxSB } from "../Typography";

const EllipsisWrapper = styled.div`
  cursor: pointer;
  :hover {
    background-color: #eff8ff;
    color: #1570ef;
    border-radius: 50%;
  }
`;

const { Text } = Typography;

const DialogTitle = styled.p`
  ${font18pxSB}
`;

const FormItemSwitch = styled(Form.Item)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0;
  flex-direction: row-reverse !important;
  align-items: center;
  justify-content: center;
  .ant-form-item-label {
    padding-bottom: 0;
  }
`;

const StyledSwitch = styled(Switch)`
  margin-right: 8px;
  &.ant-switch {
    background: #f2f4f7 !important;
  }

  &.ant-switch-checked {
    background: #1570ef !important;
  }
`;

const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    color: yellow !important;
    background-color: green;
    width: 800px;
  }
`;

export const isDuplicatedCoverages = (coverages) => {
  const memberIds = coverages ? coverages.map(({ memberId }) => memberId) : [];

  return coverages.some(({ memberId, payerName }, index) => {
    const firstInstanceIndex = memberIds.indexOf(memberId);
    return firstInstanceIndex !== index && payerName === coverages[firstInstanceIndex].payerName;
  });
};

const Coverages = () => {
  const form = Form.useFormInstance();
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const selectedPatientDetails = useSelector(selectPatientDetails);
  const patientDetails = {
    patientId: selectedPatientDetails?.id,
    journeyId: selectedPatientDetails?.journey,
    isPatientUninsured: selectedPatientDetails?.isPatientUninsured,
    hasInsuranceConflicts: selectedPatientDetails?.hasInsuranceConflicts
  };
  const [uninsuredValue, setUninsuredValue] = useState(patientDetails.isPatientUninsured);

  useEffect(() => {
    if (patientDetails.isPatientUninsured) {
      setUninsuredValue(patientDetails.isPatientUninsured);
    }
  }, [patientDetails.isPatientUninsured]);

  const history = useHistory();
  const {
    validEbvPayers,
    showDeleteCoveragePopup,
    coveragesData,
    showAddCoveragePopup,
    handleSubmit,
    getIsFormModified,
    setCoveragesData
  } = useContext(ManagePatientDetailsContext);
  const handleSubmitForm = (navigatorFunction) => handleSubmit(navigatorFunction);
  const addCoverageButtonFunction = () => {
    showAddCoveragePopup(form.getFieldValue(["coverages", "patientInsuranceRows"]));
  };

  const [isInsuranceEncounterLevel, skipBenefitInvestigation] = useGetSetting([
    "isInsuranceEncounterLevel",
    "skipBenefitInvestigation"
  ]);

  const switchPairs = [
    [
      { type: "secondary", index: 1, id: "switch_pri_with_sec" },
      ...(coveragesData?.length > 2 ? [{ type: "tertiary", index: 2, id: "switch_pri_with_ter" }] : [])
    ],
    [
      { type: "primary", index: 0, id: "switch_sec_with_pri" },
      ...(coveragesData?.length > 2 ? [{ type: "tertiary", index: 2, id: "switch_sec_with_ter" }] : [])
    ],
    [
      { type: "primary", index: 0, id: "switch_tri_with_pri" },
      { type: "secondary", index: 1, id: "switch_tri_with_sec" }
    ]
  ];

  const switchType = (currentType, destinationType) => {
    const updatedCoverage = coveragesData.slice();
    const destinationInsuranceRow = updatedCoverage[destinationType];
    const currentTypeValue = updatedCoverage[currentType].coverageType;
    updatedCoverage[destinationType] = {
      ...updatedCoverage[currentType],
      coverageType: destinationInsuranceRow.coverageType
    };
    updatedCoverage[currentType] = { ...destinationInsuranceRow, coverageType: currentTypeValue };
    setCoveragesData(updatedCoverage);
  };

  const isUnknownCoverageExists = coveragesData?.some(({ isUnknown }) => isUnknown);
  const isCoverageUnknown = (coverage) => coverage.isUnknown || coverage.isActive === false;

  return (
    <>
      <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <Space style={{ display: "flex", alignItems: "end" }}>
          <Text id="manage-patient-details-coverages" style={{ fontSize: "24px" }}>
            {t("managePatientDetails.coverage")}
          </Text>
          {patientDetails.hasInsuranceConflicts ? (
            <CoverageConflictIndicatorIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                const isFormModified = getIsFormModified();
                if (isFormModified) {
                  setModalVisible(true);
                } else {
                  history.push(
                    `/patient/${patientDetails.patientId}/journey/${patientDetails.journeyId}/coverages/conflicts`
                  );
                }
              }}
            />
          ) : null}
        </Space>
        <Space>
          <FormItemSwitch name={["coverages", "isPatientUninsured"]} valuePropName="checked">
            <StyledSwitch
              id="ManagePatientDetails_coverages_isPatientUninsured"
              onChange={(value) => {
                if (!value) {
                  setCoveragesData([]);
                }
                setUninsuredValue(value);
              }}
              disabled={
                coveragesData && coveragesData.length > 0 && !form.getFieldValue(["coverages", "isPatientUninsured"])
              }
            />
          </FormItemSwitch>
          {t("managePatientDetails.patient_is_uninsured")}
        </Space>
      </Space>
      <Divider />
      {isUnknownCoverageExists && !skipBenefitInvestigation && (
        <Alert
          style={{ backgroundColor: PRIMARY_50, borderRadius: 8, marginBottom: 20 }}
          message={<Text style={{ color: PRIMARY_700 }}>{t("coverages.changing_coverage_details_message")}</Text>}
          type="info"
          showIcon
          icon={<InfoCircleOutlined style={{ marginBottom: 20 }} />}
        />
      )}
      {!form.getFieldValue(["coverages", "isPatientUninsured"]) &&
        coveragesData &&
        isDuplicatedCoverages(coveragesData) && (
          <Alert
            style={{ backgroundColor: ERROR_25, borderRadius: 8, marginBottom: 20 }}
            message={
              <Text style={{ color: ERROR_600 }}>
                {t("patientClassicCoverageDetailsOverview.coverage_with_the_same_payer_name_and_member_ID")}
              </Text>
            }
            type="error"
            showIcon
            icon={<InfoCircleOutlined />}
          />
        )}
      {!form.getFieldValue(["coverages", "isPatientUninsured"]) &&
        coveragesData &&
        _.orderBy(coveragesData, "coverageType")?.map((coverage, index) => {
          const subMenuOptions = switchPairs[index]?.map((switchName, switchIndex) => (
            <Menu.Item
              id={switchName.id}
              key={`${index}${switchIndex}`}
              onClick={() => {
                switchType(index, switchName.index);
              }}
            >
              {_.startCase(switchName.type)}
            </Menu.Item>
          ));

          const optionsMenu = [
            {
              key: "delete",
              text: t("patientClassicCoverageDetailsOverview.delete"),
              icon: <TrashBinIcon />,
              id: "coverage_delete"
            }
          ];

          if (coveragesData.length > 1 && !isInsuranceEncounterLevel)
            optionsMenu.unshift({
              key: "switch",
              text: t("patientClassicCoverageDetailsOverview.switch_with"),
              icon: <SwitchInsuranceIcon />,
              subMenu: true,
              subMenuTitle: "Switch with",
              subMenuItems: subMenuOptions
            });
          return (
            <Col key={index} id={"manage-patient-details-coverages-" + coverage.coverageType}>
              <Row style={{ justifyContent: "space-between" }}>
                <Space style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                  <SubSectionHeader title={_.startCase(coverage.coverageType)} />
                  {!skipBenefitInvestigation && isCoverageUnknown(coverage) && (
                    <StyledTooltip title={t("new_patient.unknown_coverage_message")}>
                      <WarningMark />
                    </StyledTooltip>
                  )}
                </Space>
                <DropdownMenu
                  menuWidth="162px"
                  placement="bottomRight"
                  onClick={(key) => {
                    if (key === "delete") {
                      showDeleteCoveragePopup(coverage, form.getFieldValue(["coverages", "patientInsuranceRows"]));
                    }
                  }}
                  menu={optionsMenu}
                >
                  <EllipsisWrapper>
                    <EllipsisOutlined style={{ fontSize: "18px" }} />
                  </EllipsisWrapper>
                </DropdownMenu>
              </Row>
              <Row>
                <InsuranceCard
                  validEbvPayers={validEbvPayers}
                  index={index}
                  form={form}
                  coveragesData={coveragesData}
                  toolTipTitle={form.getFieldValue(["coverages", "patientInsuranceRows", index, "planName"])}
                  customPayer={coverage.isUnknown}
                  t={t}
                />
              </Row>
            </Col>
          );
        })}
      {!form.getFieldValue(["coverages", "isPatientUninsured"]) &&
        coveragesData &&
        coveragesData.length > 0 &&
        coveragesData.length < 3 && (
          <SecondaryButton onClick={addCoverageButtonFunction} id="add_coverage">
            + {t("patientClassicCoverageDetailsOverview.add_coverage")}
          </SecondaryButton>
        )}
      {((coveragesData && coveragesData.length == 0) || form.getFieldValue(["coverages", "isPatientUninsured"])) && (
        <EmptyListMessage
          subTitleText={[t("patientCoverageDetailsTabs.coverages_can_always_be_added")]}
          title={t("patientCoverageDetails.no_coverage_yet")}
          icon={NoCoveragesYetIcon}
          buttonTitle={t("patientClassicCoverageDetailsOverview.add_coverage")}
          buttonDisabled={uninsuredValue}
          buttonAction={addCoverageButtonFunction}
          id="add_coverage_when_no_coverages"
        />
      )}
      <PopupStyledModal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 25,
              height: 25,
              backgroundColor: "#F9F5E7",
              borderRadius: "50%"
            }}
          >
            <WarningPopupIcon />
          </div>
        }
        open={modalVisible}
        footer={[
          <OutlinedButton key="back" onClick={() => setModalVisible(false)} id="discard_changes_cancel">
            {t("back")}
          </OutlinedButton>,
          <PrimaryButton
            key="ok"
            onClick={() => {
              handleSubmitForm(() =>
                history.push(
                  `/patient/${patientDetails.patientId}/journey/${patientDetails.journeyId}/coverages/conflicts`
                )
              );
            }}
            id="discard_changes_ok"
          >
            {t("coverages.save_changes")}
          </PrimaryButton>
        ]}
      >
        <DialogTitle>{t("save_changes")}</DialogTitle>
        {t("coverages.redirect_message")}
      </PopupStyledModal>
    </>
  );
};

export default Coverages;
