import { applyMiddleware, createStore, compose } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import originalReducer from "./reducer";
import crossCheckReducer from "./lib/crossCheckReducer";
import slicedReducer from "./slicedReducer";
import { rootSaga } from "./saga/index";

const sagaMiddleware = createSagaMiddleware();

// We don't have access to site name at this point, so we are forced to use href
// in all other parts of the app we want to use site license in the store
const isDevEnv = function () {
  const devEnvs = ["localhost", "dev", "mars", "orion", "tde", "tae", ".pre."];
  return devEnvs.some((match) => window.location.href.toLowerCase().includes(match));
};

export default function configureStore(initialState) {
  const middlewares = [sagaMiddleware];
  const isDev = isDevEnv();
  const composeEnhancers = isDev ? composeWithDevTools({}) : compose;
  if (isDev) {
    middlewares.push(logger);
  }

  const checkOpts = { useOriginal: true };
  if (process.env.REACT_APP_REDUCER_CHECK_CHANCE) {
    checkOpts.checkChance = Number(process.env.REACT_APP_REDUCER_CHECK_CHANCE);
  }
  const reducer = crossCheckReducer(originalReducer, slicedReducer, checkOpts);
  const store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(rootSaga);

  return store;
}
