import { put, takeLatest, call } from "redux-saga/effects";

import ACTIONS from "../action";

import { getApplicationFormSignatures, ApiNames } from "../../api/api";
import { callApi } from "./networkActions";

function* fetchApplicationFormSignatures({ applicationFormId }) {
  try {
    const applicationFormSignaturesRes = yield call(getApplicationFormSignatures, applicationFormId);
    const applicationFormSignatures = applicationFormSignaturesRes?.data;

    yield put(ACTIONS.updateEnrollmentStore("applicationFormSignatures", applicationFormSignatures));
  } catch (error) {
    // Handle error if needed
  }
}

function* fetchPapPdfMetadata({ potentialSavingId, applicationId }) {
  const pdfMetadataResult = yield callApi(ApiNames.FetchPapPDFMetaData, potentialSavingId, applicationId);

  if (pdfMetadataResult) {
    yield put(ACTIONS.updateEnrollmentStore("pdfMetaData", pdfMetadataResult));
  } else {
    yield put(ACTIONS.updateEnrollmentStore("pdfMetaData", {}));
  }
}

export default function* applicationFormSaga() {
  yield takeLatest(ACTIONS.Types.FETCH_APPLICATION_FORM_SIGNATURES, fetchApplicationFormSignatures);
  yield takeLatest(ACTIONS.Types.FETCH_PAP_PDF_METADATA, fetchPapPdfMetadata);
}
