import React, { useContext, useEffect, useState } from "react";
import {
  ERROR_50,
  ERROR_100,
  ERROR_600,
  GRAY_500,
  GRAY_100,
  GRAY_50,
  SUCCESS_50,
  SUCCESS_600,
  GRAY_700
} from "../../constant/colors";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import styled, { createGlobalStyle } from "styled-components";

import { OutlinedButton, PrimaryButton } from "../customComponent/Button";
import { font14pxR } from "../Typography";
import { ManagePatientDetailsContext } from "./context";
import { generateHashFromObj } from "../../utils/misc";
import { utcDateFormatter } from "../../utils/date";

const GlobalStyle = createGlobalStyle`
.conflict-popover .ant-popover-inner {
  width: 316px;
  border-radius: 8px;
  .ant-popover-title {
    padding-bottom: 0;
    border: none;
  }
  .ant-popover-inner-content {
    justify-content: center;
    display: flex;
    flex: 1;
    width: 100%;
    padding: 0;
    padding-bottom: 12px;
  }
}`;

const Date = styled.div`
  font-size: 10px;
  color: ${GRAY_500};
`;

const Title = styled.div`
  ${font14pxR}
`;

const ConflictValuesWrapper = styled.div`
  display: flex;
  margin: 8px 0px 14px 8px;
  align-items: center;
`;

const IsActiveConflictValue = styled.div`
  background-color: ${(props) => (props.value ? SUCCESS_50 : GRAY_100)};
  color: ${(props) => (props.value ? SUCCESS_600 : GRAY_700)};
  border-radius: 16px;
  width: fit-content;
  padding: 2px 12px;
  margin-right: 8px;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const ContentButtonWrapper = styled.div`
  display: flex;
  > button {
    display: flex;
    flex: 1;
    margin: 0px 8px;
    justify-content: center;
  }
`;

const Header = styled.div`
  background: ${GRAY_50};
  border-radius: 8px;
  padding: 0;
  margin: 4px;
  display: flex;
  border: none;
  align-items: center;
`;

const IconWrapper = styled.div`
  background: ${(props) => props.backgroundColor};
  border: 4px solid ${(props) => props.borderColor};
  border-radius: 28px;
  width: 32px;
  height: 32px;
  text-align: center;
  margin: 11px 8px;
  .anticon {
    vertical-align: middle;
    color: ${(props) => props.color};
  }
`;

const ConflictHeader = ({ conflict }) => {
  return (
    <Header>
      <IconWrapper backgroundColor={ERROR_100} borderColor={ERROR_50} color={ERROR_600}>
        <ExclamationCircleOutlined />
      </IconWrapper>
      <div>
        <Date>{conflict.date}</Date>
        <Title>{conflict.title}</Title>
      </div>
    </Header>
  );
};

const getComponentByConflictKey = (pending) => {
  return pending.map(({ key, value }) => {
    switch (key) {
      case "isActive":
        return (
          <IsActiveConflictValue key={key} value={value}>
            {value === true ? "Active" : "Inactive"}
          </IsActiveConflictValue>
        );
      case "activeDate": {
        const formatedDate = utcDateFormatter(value);
        return <span key={key}>{formatedDate}</span>;
      }
      case "isPrimary":
        return <div key={key}>{value}</div>;
      default:
        return <div key={key}>{value}</div>;
    }
  });
};

const ResolveConflictDialog = ({ conflict, onReplace, onIgnore }) => {
  const componentByConflict = getComponentByConflictKey(conflict.pending);

  return (
    <ContentWrapper>
      <ConflictValuesWrapper>{componentByConflict}</ConflictValuesWrapper>
      <ContentButtonWrapper>
        <PrimaryButton id="conflict_medical_replace" onClick={onReplace}>
          Replace
        </PrimaryButton>
        <OutlinedButton id="conflict_medical_ignore" onClick={onIgnore}>
          Ignore
        </OutlinedButton>
      </ContentButtonWrapper>
    </ContentWrapper>
  );
};

export const ConflictPopover = ({
  children,
  conflict,
  placement = "bottomLeft",
  onClickReplace = () => {},
  onClickIgnore = () => {}
}) => {
  const { registerConflictAlert } = useContext(ManagePatientDetailsContext);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    registerConflictAlert({
      id: generateHashFromObj(conflict),
      show: () => {} /*setVisible(true)*/,
      hide: () => {} /*setVisible(false)*/
    });
  }, []);
  return (
    <>
      <GlobalStyle />
      <Popover
        overlayClassName="conflict-popover"
        open={visible}
        placement={placement}
        title={() => <ConflictHeader conflict={conflict} />}
        content={() => (
          <ResolveConflictDialog conflict={conflict} onReplace={onClickReplace} onIgnore={onClickIgnore} />
        )}
        destroyTooltipOnHide={true}
        trigger="hover"
        onOpenChange={setVisible}
      >
        {children}
      </Popover>
    </>
  );
};
