import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "antd";
import styled from "styled-components";

import { EnrollResultData } from "./EnrollResultData";
import { ReactComponent as AlertIcon } from "../../../../../assets/svg/alert-icon-popup.svg";
import { GRAY_500 } from "../../../../../constant/colors";
import { SupportLink } from "../utils/components";

const { Text } = Typography;

const TextStyled = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${GRAY_500};
`;

export const DeniedResult = ({ title, denialReason }) => {
  const { t } = useTranslation();

  const resultData = (
    <>
      <Trans
        i18nKey="patientApplications.enroll.enroll_result.application_denied_subtitle"
        components={{
          bold: <strong />,
          span: <TextStyled />,
          denialReason: denialReason || t("patientApplications.enroll.enroll_result.unknown_denial_reason")
        }}
      />
      <SupportLink />
    </>
  );

  return <EnrollResultData icon={<AlertIcon />} title={title} content={resultData} />;
};
