import React from "react";
import { Menu, Typography, Space, Dropdown, Button } from "antd";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { selectUser } from "../../store/selector";

import { Routes } from "../../constant/routes";
import SuccessMessage from "../../component/customComponent/customMessages/SuccessMessage";
import ACTIONS from "../../store/action";
import { GRAY7, DARK_BLUE, LIGHT_GRAY2, LIGHT_BLUE_2 } from "../../constant/colors";
import { ReactComponent as ActionEditIcon } from "../../assets/svg/actions-edit.svg";
import { ReactComponent as ActionUrlIcon } from "../../assets/svg/actions-url.svg";
import { ReactComponent as ActionDeleteIcon } from "../../assets/svg/actions-delete.svg";
import { formPopupsContentKeys } from "../../constant/formPopupsContentKeys";
import { ThreeDots } from "../customComponent/ThreeDots";
import { ActionsMenuStyle } from "./SavedListActionsMenuStyle";

const { Text } = Typography;

const StyledMenu = styled(Menu)`
  ${ActionsMenuStyle}
`;

const StyledButton = styled(Button)`
  border-radius: 15px;
  padding: 5px;
  :hover {
    background: ${GRAY7};
    span {
      background-color: ${LIGHT_GRAY2};
    }
  }
  :active,
  :focus {
    background: ${LIGHT_BLUE_2};
    span {
      background-color: ${DARK_BLUE};
    }
  }
`;

const ActionsMenuComponent = ({
  t,
  user,
  source,
  record,
  deleteAction,
  openEditSavedListPopup,
  populateSavedListPopup
}) => {
  const isOwner = user?.id === record?.user;
  const actionsMenuItems = [
    {
      id: `${source}_options_edit`,
      key: "edit",
      title: t("savedList.actions_menu.edit"),
      Icon: ActionEditIcon,
      show: isOwner && record.isValid,
      actions: () => {
        populateSavedListPopup(record);
        openEditSavedListPopup();
      }
    },
    {
      key: "copy",
      title: t("savedList.actions_menu.copy"),
      Icon: ActionUrlIcon,
      show: record.isValid,
      actions: () => {
        const { id } = record;
        const url = `${window.location.origin}${Routes.FILTER_LIST}/${id}`;
        navigator.clipboard.writeText(url);
        SuccessMessage(t("savedList.copy_action"));
      }
    },
    {
      id: `${source}_options_delete`,
      key: "delete",
      title: t("savedList.actions_menu.delete"),
      Icon: ActionDeleteIcon,
      show: isOwner,
      actions: () => {
        deleteAction(record);
      }
    }
  ];

  const itemsToShow = actionsMenuItems.filter((item) => item.show);
  const overlayItems = (
    <StyledMenu>
      {itemsToShow.map(({ id, key, actions, title, Icon }, index) => (
        <Menu.Item
          id={id}
          key={key}
          onClick={() => actions(record)}
          style={index == 2 ? { borderTop: "1px solid " + GRAY7 } : {}}
        >
          <Space>
            <Text>
              {Icon && (
                <span style={{ marginRight: 9, verticalAlign: "sub" }}>
                  <Icon />
                </span>
              )}
              {title}
            </Text>
          </Space>
        </Menu.Item>
      ))}
    </StyledMenu>
  );

  const dropDown = Boolean(isOwner || record.isValid) && (
    <Dropdown overlay={overlayItems} placement="bottomRight" trigger={["click"]}>
      <StyledButton type="text">
        <ThreeDots />
      </StyledButton>
    </Dropdown>
  );
  return dropDown;
};

const mapStateToProps = createStructuredSelector({
  user: selectUser
});
const mapDispatchToProps = (dispatch) => ({
  openEditSavedListPopup: () => dispatch(ACTIONS.setFormPopups(true, formPopupsContentKeys.ADD_NEW_LIST)),
  populateSavedListPopup: (filterListData) => dispatch(ACTIONS.setFilterList({ ...filterListData, isEditView: true }))
});
const SavedListActionsMenu = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ActionsMenuComponent);

export { SavedListActionsMenu };
