import React, { useEffect, useState } from "react";
import { Col, Divider, Input, Select, Row, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { phoneNumberLengthRegEx } from "../../../../../../../../utils/formValidation";
import { npiInputRule, onlyWhitespacesRule, zipInputRules } from "../../../../../utils";
import { physicianAndFacilityFields } from "../../fieldNames";
import { FormItem, InputPhone } from "../../../../../components";
import { PhysicianSelect } from "../../../../../../../Select";
import { buildFieldValueMap } from "../../../../../../../../utils/form";
import { selectProvidersDoctors } from "../../../../../../../../store/selector";
import { sizes } from "../../../../../../../../constant/styles";
import { SingleSelect } from "../../../../../../../customComponent/CustomSelect";
import { States as STATES } from "../../../../../../../../constant/states";
import { onProviderChange } from "../PhysicianAndFacility";
import { OTHER, ProviderTypes } from "../../../../../constants";
import { getPhysicianFieldsToUpdate } from "../../../../../utils/provider";

const { Option } = Select;

const formToPhysicianFieldMap = [
  [physicianAndFacilityFields.physicianPracticeName, "facility"],
  [physicianAndFacilityFields.physicianAddress, "address"],
  [physicianAndFacilityFields.physicianCity, "city"],
  [physicianAndFacilityFields.physicianState, "state"],
  [physicianAndFacilityFields.physicianZip, "zipCode"],
  [physicianAndFacilityFields.physicianPhone, "phone"],
  [physicianAndFacilityFields.physicianNpi, "npi"]
];

export function Physician() {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const physicians = useSelector(selectProvidersDoctors);

  const [isPhysicianSelectOpen, setIsPhysicianSelectOpen] = useState(false);

  const physician = Form.useWatch(physicianAndFacilityFields.physicianId, form);

  useEffect(() => {
    form.validateFields();
  }, [physician]);

  const handlePhysicianChange = (physician) => {
    onProviderChange(
      physician,
      formToPhysicianFieldMap,
      ProviderTypes.Physician,
      physicians,
      form,
      getPhysicianFieldsToUpdate(
        form,
        physicians,
        physicianAndFacilityFields.physicianId,
        physicianAndFacilityFields.physicianFirstName,
        physicianAndFacilityFields.physicianLastName
      )
    );
  };

  return (
    <>
      <Row gutter={14}>
        <Col span={8}>
          <FormItem
            name={physicianAndFacilityFields.physicianId}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_full_name")}
            rules={[{ required: true }]}
          >
            <PhysicianSelect
              onDropdownVisibleChange={(visible) => setIsPhysicianSelectOpen(visible)}
              open={isPhysicianSelectOpen}
              id="EE_Genentech_copay_physician_select"
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_full_name"
              )}
              onChange={(physician) => handlePhysicianChange(physician)}
              onClear={() => handlePhysicianChange(null)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Button
                    id="EE_genentech_copay_other_physician_id"
                    type="text"
                    style={{ width: "100%", textAlign: "start", fontSize: sizes.medium, background: "transparent" }}
                    onClick={() => {
                      form.setFieldsValue(buildFieldValueMap([[physicianAndFacilityFields.physicianId, OTHER]]));
                      setIsPhysicianSelectOpen(false);
                      handlePhysicianChange(null);
                    }}
                  >
                    {t("application_form_editor.genentech.copay.physician_and_facility.other")}
                  </Button>
                </>
              )}
            />
          </FormItem>
        </Col>
        <Col span={physician !== OTHER ? 0 : 8}>
          <FormItem
            hidden={physician !== OTHER}
            name={physicianAndFacilityFields.physicianFirstName}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_first_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_first_name"
              )}
            />
          </FormItem>
        </Col>
        <Col span={physician !== OTHER ? 0 : 8}>
          <FormItem
            hidden={physician !== OTHER}
            name={physicianAndFacilityFields.physicianLastName}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_last_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_last_name"
              )}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem
            rules={[onlyWhitespacesRule]}
            name={physicianAndFacilityFields.physicianPracticeName}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_practice_name")}
          >
            <Input
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_practice_name"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={physicianAndFacilityFields.physicianAddress}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_address")}
          >
            <Input
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_address"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={physicianAndFacilityFields.physicianCity}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_city")}
          >
            <Input
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_city"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[{ required: true }]}
            name={physicianAndFacilityFields.physicianState}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_state")}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_state"
              )}
            >
              {Object.values(STATES).map((state, index) => (
                <Option key={index} value={state.code}>
                  {state.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name={physicianAndFacilityFields.physicianZip}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_zip")}
            rules={[{ required: true }, ...zipInputRules]}
          >
            <Input
              maxLength={5}
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_zip"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
            name={physicianAndFacilityFields.physicianPhone}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_phone")}
          >
            <InputPhone
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_phone"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[onlyWhitespacesRule, npiInputRule]}
            name={physicianAndFacilityFields.physicianNpi}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.physician_npi")}
          >
            <Input
              style={{ width: "100%" }}
              maxLength={10}
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.physician_npi"
              )}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
}
