import React from "react";
import { Radio as AntRadio } from "antd";
import "./Group.less";

export const RadioGroup = ({ options, id, t, ...props }) => (
  <AntRadio.Group id={id} className="tailormed-radio" {...props}>
    {options &&
      options.map((option, index) => (
        <AntRadio.Button key={index} value={option.key} id={option.id}>
          {option.value(t)}
        </AntRadio.Button>
      ))}
  </AntRadio.Group>
);
