export const drugTypes = Object.freeze({
  ORAL: "oral",
  IV: "iv"
});

export const drugDictionary = {
  1307625: {
    name: "bosulif",
    tmDisplayName: "bosulif (Bosutinib)",
    category: drugTypes.ORAL,
    ndc: "00069013501"
  },
  2049112: {
    name: "braftovi",
    tmDisplayName: "Braftovi (Encorafenib)",
    category: drugTypes.ORAL,
    ndc: "70255002501"
  },
  2105851: {
    name: "daurismo",
    tmDisplayName: "Daurismo (Glasdegib)",
    category: drugTypes.ORAL,
    ndc: "00069153130"
  },
  1601381: {
    name: "ibrance",
    tmDisplayName: "Ibrance (Palbociclib)",
    category: drugTypes.ORAL,
    ndc: "00069018721"
  },
  1243005: {
    name: "inlyta",
    tmDisplayName: "Inlyta (Axitinib)",
    category: drugTypes.ORAL,
    ndc: "00069014501"
  },
  2103170: {
    name: "lorbrena",
    tmDisplayName: "Lorbrena (Lorlatinib)",
    category: drugTypes.ORAL,
    ndc: "00069022701"
  },
  2049128: {
    name: "mektovi",
    tmDisplayName: "Mektovi (Binimetinib)",
    category: drugTypes.ORAL,
    ndc: "70255001002"
  },
  2099947: {
    name: "talzenna",
    tmDisplayName: "Talzenna (Talazoparib)",
    category: drugTypes.ORAL,
    ndc: "00069119530"
  },
  2058916: {
    name: "vizimpro",
    tmDisplayName: "Vizimpro (Dacomitinib)",
    category: drugTypes.ORAL,
    ndc: "00069019730"
  },
  1148499: {
    name: "xalkori",
    tmDisplayName: "Xalkori (Crizotinib)",
    category: drugTypes.ORAL,
    ndc: "00069814020"
  },
  1942955: {
    name: "besponsa",
    tmDisplayName: "Besponsa (Inotuzumab Ozogamicin)",
    category: drugTypes.IV,
    ndc: "00008010001"
  },
  261585: {
    name: "mylotarg",
    tmDisplayName: "Mylotarg (Gemtuzumab Ozogamicin)",
    category: drugTypes.IV,
    ndc: "00008451001"
  }
};

export const getDrugByName = (searchName) => {
  const [rxcui, drug] =
    Object.entries(drugDictionary).find(([, { name }]) => name?.toLowerCase() === searchName?.toLowerCase()) || [];
  return rxcui
    ? {
        ttyCui: rxcui,
        ...drug
      }
    : {};
};

export const getDrugByCui = (cui) => {
  if (!drugDictionary[cui]) return {};

  return {
    ttyCui: cui,
    ...drugDictionary[cui]
  };
};
