import React from "react";
import { EyeOutlined } from "@ant-design/icons";

import { Button } from "antd";

const ViewApplicationFormActionButton = ({ onClick, type = "text", text }) => {
  return (
    <Button
      id="EE_view_application_form"
      type={type}
      icon={<EyeOutlined style={{ fontSize: "20px" }} />}
      onClick={onClick}
      style={{ ...(text && { padding: "4px 8px" }) }}
    >
      {text}
    </Button>
  );
};
export default ViewApplicationFormActionButton;
