import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { selectApplicationForm, selectExpressEnrollmentConfiguration } from "../../../../../../../store/selector";
import { prescriptionFields } from "../fieldNames";
import { updateFormFields } from "./Practice";
import { capitalizeEachFirstLetter } from "../../../../../../../utils/string";
import { ZERO_REFILLS_OPTION, prepareDefaultPrescriptionData, valuesToExclude } from "../initialValues";
import { ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME } from "../../../../constants";

const prescriptionMapping = {
  product: prescriptionFields.product,
  dosageAmount: prescriptionFields.dosage,
  dosageUnit: prescriptionFields.dosageUnit,
  dispenseQuantity: prescriptionFields.dispenseQuantity,
  dispenseUnit: prescriptionFields.dispenseUnit,
  frequency: prescriptionFields.frequency,
  numberOfRefills: prescriptionFields.refills,
  option: prescriptionFields.prescriptionOption,
  sig: prescriptionFields.sig,
  expectedSupplyDurationValue: prescriptionFields.supplyDurationValue,
  expectedSupplyDurationUnit: prescriptionFields.supplyDuration,
  type: prescriptionFields.prescriptionType
};

const questionnaireShipmentOptionsQuestion = [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "shipment-options"];
const productNameField = prescriptionFields.product[1];
const numberOfRefillsField = prescriptionFields.refills[1];
const supplyDurationValueField = prescriptionFields.supplyDurationValue[1];

const shouldDisableField = (fieldVal, fieldName, excludedInputs = []) => {
  return !valuesToExclude.includes(fieldVal) && !excludedInputs.includes(fieldName) && !Array.isArray(fieldVal);
};
const findAndUpdatePrescription = (prescriptionData, applicationForm, selectedPrescriptionType, extOption) => {
  // Find matching prescription based on type and option

  const option = extOption ? extOption : applicationForm?.formData?.prescription?.option;
  let prescription =
    prescriptionData.find(
      (data) => (!selectedPrescriptionType || data.type === selectedPrescriptionType) && data.option === option
    ) || prescriptionData[0];

  // Determine if we should check the type based on existing prescription type data and a selected type
  const shouldCheckType = !!applicationForm?.formData?.prescription?.type && !!selectedPrescriptionType;

  // Check if the types match or if we shouldn't check the type
  const isTypeMatch = shouldCheckType
    ? applicationForm?.formData?.prescription?.type === selectedPrescriptionType
    : true;

  // If application form has matching option and types match, update prescription with application form data
  if (applicationForm?.formData?.prescription?.option === option && isTypeMatch) {
    prescription = { ...prescription, ...applicationForm?.formData?.prescription };
  }

  return prescription;
};

export default function GenentechFreeDrugPrescription() {
  const form = Form.useFormInstance();
  const prescriptionFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.GENENTECH_PAP)()
    .prescription;

  const expressEnrollmentConfiguration = useSelector(selectExpressEnrollmentConfiguration);
  const applicationForm = useSelector(selectApplicationForm);

  // this will include the drug name from the dynamic questionnaire section
  const questionnaireOption = Form.useWatch("questionnaireOption", form);

  const [drugName, setDrugName] = useState(questionnaireOption);
  const [prescriptionOption, setPrescriptionOption] = useState({});
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [selectedPrescriptionType, setSelectedPrescriptionType] = useState("");
  const [filteredPrescriptionOptions, setFilteredPrescriptionOptions] = useState([]);

  const shipmentOption = Form.useWatch(questionnaireShipmentOptionsQuestion, form);
  const isReplacement = shipmentOption === "Replacement";

  const prescriptionTypeItems = Array.from(new Set(prescriptionData.map((item) => item.type))).map((type) => ({
    value: type,
    displayValue: type
  }));

  useEffect(() => {
    if (expressEnrollmentConfiguration?.expressEnrollmentQuestionnaire?.length !== 1 && !questionnaireOption) return;

    const drugConfig = questionnaireOption
      ? expressEnrollmentConfiguration.expressEnrollmentQuestionnaire.find(
          (questionnaire) => questionnaireOption === questionnaire.drugName
        )
      : expressEnrollmentConfiguration?.expressEnrollmentQuestionnaire?.[0];

    const { prescriptionData, drugName } = drugConfig;
    // for saved prescription values - get data from the formData
    const prescriptionOptionInitialData = findAndUpdatePrescription(
      prescriptionData,
      applicationForm,
      selectedPrescriptionType
    );

    setDrugName(drugName);
    form.setFieldValue(prescriptionFields.product, capitalizeEachFirstLetter(drugName));
    setPrescriptionOption(prescriptionOptionInitialData);
    setPrescriptionData(prescriptionData);
    form.validateFields();
  }, [questionnaireOption, expressEnrollmentConfiguration?.expressEnrollmentQuestionnaire]);

  useEffect(() => {
    let prescriptionType = undefined;
    if (selectedPrescriptionType || prescriptionData[0]?.type) {
      prescriptionType = selectedPrescriptionType || prescriptionData[0]?.type;
      const initialPrescriptionOption = prescriptionData.find((data) => data.type === prescriptionType);

      setSelectedPrescriptionType(prescriptionType);
      setPrescriptionOption(initialPrescriptionOption);
    }
    const filteredOptions = prescriptionData
      .filter((data) => data.type === prescriptionType)
      .map(({ option }) => ({ value: option, displayValue: option }));

    setFilteredPrescriptionOptions(filteredOptions);
  }, [prescriptionData, selectedPrescriptionType]);

  const onChangePrescriptionOption = (option) => {
    const prescription = findAndUpdatePrescription(prescriptionData, applicationForm, selectedPrescriptionType, option);
    setPrescriptionOption(prescription);
    updatePrescriptionFormFields(prescription);
  };

  const updatePrescriptionFormFields = (prescription) => {
    // in case the number of refills is 0, include it in the prescription data
    const excludedInputs = prescription.numberOfRefills !== ZERO_REFILLS_OPTION ? [numberOfRefillsField] : [];

    const preparedDefaultPrescriptionData = prepareDefaultPrescriptionData(
      prescription,
      valuesToExclude,
      excludedInputs
    );

    updateFormFields(
      {
        ...preparedDefaultPrescriptionData,
        [productNameField]: capitalizeEachFirstLetter(drugName)
      },
      prescriptionMapping,
      form
    );
    form.validateFields();
  };

  const setPrescriptionType = (type) => {
    if (!type) return;
    const prescription = prescriptionData.find((data) => data.type === type);
    if (prescription) {
      setPrescriptionOption(prescription);
      setSelectedPrescriptionType(type);
    }
  };

  // determine if the field is a select field or an input field
  const renderField = ({ fieldName, minNumber, maxNumber, maxLength }) => {
    const data = prescriptionData.find(
      (data) =>
        data.option === prescriptionOption.option &&
        (!selectedPrescriptionType || data.type === selectedPrescriptionType)
    );

    const isArray = Array.isArray(data[fieldName]);
    const optionItems = isArray ? data[fieldName].map((value) => ({ value, displayValue: value })) : [];
    const disabled = shouldDisableField(data[fieldName], fieldName, [supplyDurationValueField]);

    return prescriptionFormComponents[fieldName]({
      required: true,
      inputProps: { disabled: disabled, controls: false, maxLength: maxLength },
      minNumber: minNumber,
      maxNumber: maxNumber,
      isArray: isArray,
      optionItems: optionItems
    });
  };

  return (
    <SectionPart
      sectionId="prescription"
      headerTranslationKey="application_form_editor.genentech.free_drug.prescription.labels.header"
    >
      {prescriptionFormComponents.informationSection({ isReplacement: isReplacement })}
      {!isReplacement ? (
        <>
          <ConfigurableRow config={ROW_OPTIONS.EQUAL} filterFalsy>
            {[
              prescriptionFormComponents.product({
                required: false,
                inputProps: { readOnly: true, disabled: true }
              }),
              prescriptionOption?.type &&
                prescriptionFormComponents.prescriptionType({
                  required: true,
                  optionItems: prescriptionTypeItems,
                  value: selectedPrescriptionType,
                  onChange: setPrescriptionType
                }),
              prescriptionFormComponents.prescriptionOption({
                disabled: !drugName,
                required: drugName,
                optionItems: filteredPrescriptionOptions,
                onChange: onChangePrescriptionOption
              })
            ]}
          </ConfigurableRow>
          <ConfigurableRow config={ROW_OPTIONS.PROPORTION_0_5_0_5_ALL_1} filterFalsy>
            {[
              prescriptionOption?.dosageAmount != null &&
                renderField({
                  fieldName: prescriptionFields.dosage[1],
                  minNumber: 1,
                  maxNumber: prescriptionOption.dosageAmount,
                  maxLength: 5
                }),
              prescriptionOption?.dosageUnit &&
                renderField({
                  fieldName: prescriptionFields.dosageUnit[1]
                }),
              prescriptionOption?.expectedSupplyDurationUnit != null &&
                renderField({
                  fieldName: prescriptionFields.supplyDuration[1]
                }),
              prescriptionOption?.expectedSupplyDurationValue != null &&
                renderField({
                  fieldName: prescriptionFields.supplyDurationValue[1],
                  minNumber: 0,
                  maxNumber: prescriptionOption.expectedSupplyDurationValue
                }),
              prescriptionOption?.frequency &&
                renderField({
                  fieldName: prescriptionFields.frequency[1],
                  maxLength: 50
                })
            ]}
          </ConfigurableRow>
          <ConfigurableRow config={ROW_OPTIONS.EQUAL} filterFalsy>
            {[
              prescriptionOption?.sig &&
                prescriptionFormComponents.sig({
                  required: true,
                  inputProps: { disabled: shouldDisableField(prescriptionOption.sig) }
                }),
              prescriptionOption?.dispenseQuantity != null &&
                renderField({
                  fieldName: prescriptionFields.dispenseQuantity[1],
                  minNumber: 1,
                  maxNumber: prescriptionOption.dispenseQuantity,
                  maxLength: 5
                }),
              prescriptionOption?.dispenseUnit &&
                renderField({
                  fieldName: prescriptionFields.dispenseUnit[1]
                }),
              prescriptionOption?.numberOfRefills != null &&
                prescriptionFormComponents.refills({
                  required: true,
                  minNumber: 0,
                  inputProps: { controls: false, disabled: prescriptionOption.numberOfRefills === ZERO_REFILLS_OPTION },
                  maxNumber: prescriptionOption.numberOfRefills
                })
            ]}
          </ConfigurableRow>
        </>
      ) : (
        <>
          {prescriptionFormComponents.product({
            formItemProps: { hidden: true }
          })}
        </>
      )}
    </SectionPart>
  );
}
