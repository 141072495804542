import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Popover, Space, Typography } from "antd";
import { PhysicianSelect } from "../../Select";
import { fontWeights } from "../../../constant/styles";
import { updatePatientPhysician } from "../../../api/api";
import { selectPatientDetails, selectProvidersDoctors } from "../../../store/selector";
import ErrorMessage from "../../customComponent/customMessages/ErrorMessage";
import SuccessMessage from "../../customComponent/customMessages/SuccessMessage";

import ACTIONS from "../../../store/action";

import { buildOneLineAddress } from "../../../utils/parse";
import CustomInfoCircleOutlined from "../../customComponent/CustomInfoCircleOutlined";
import { GRAY_600 } from "../../../constant/colors";

const { Text } = Typography;

const PatientPhysician = () => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { patientId } = useParams();
  const patientDetails = useSelector(selectPatientDetails);
  const physicians = useSelector(selectProvidersDoctors);
  const dispatch = useDispatch();

  const patientPhysician = physicians?.find((p) => p.id === patientDetails?.physician?.id) || patientDetails?.physician;

  const updatePhysicianHandler = async (value) => {
    try {
      setIsFetching(true);
      const updateAssigneeRes = await updatePatientPhysician(patientId, value);
      if (updateAssigneeRes && updateAssigneeRes.data && updateAssigneeRes.data.success) {
        const physician = physicians.find((physician) => physician.id === value);
        dispatch(
          ACTIONS.updatePatientDetailsAction({
            ...patientDetails,
            physician: physician || null
          })
        );
        SuccessMessage(t("successMessages.successfully_updated_physician"));
      } else {
        ErrorMessage(t("errorMessages.failed_to_update_physician"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.failed_to_update_physician"));
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Space direction="vertical" size={0}>
      <label htmlFor="patient_header_physician" style={{ color: GRAY_600 }}>
        {t("patientTopSection.physician")}
        {patientPhysician && (
          <Popover
            content={
              <Space size={0} style={{ padding: "0 12px", minWidth: "200px" }} direction="vertical">
                <Text>
                  {t("form.physician.popover.npi")}:{" "}
                  <Text style={{ fontWeight: fontWeights.semibold }}>{patientPhysician.npi}</Text>
                </Text>
                <Text>
                  {t("form.physician.popover.dea")}:{" "}
                  <Text style={{ fontWeight: fontWeights.semibold }}>{patientPhysician.dea}</Text>
                </Text>
                <Text>
                  {t("form.physician.popover.stateLicense")}:{" "}
                  <Text style={{ fontWeight: fontWeights.semibold }}>{patientPhysician.stateLicense}</Text>
                </Text>
                <Text>
                  {t("form.physician.popover.phone")}:{" "}
                  <Text style={{ fontWeight: fontWeights.semibold }}>{patientPhysician.phone}</Text>
                </Text>
                <Text>
                  {t("form.physician.popover.fax")}:{" "}
                  <Text style={{ fontWeight: fontWeights.semibold }}>{patientPhysician.fax}</Text>
                </Text>
                <Text>
                  {t("form.physician.popover.address")}:{" "}
                  <Text style={{ fontWeight: fontWeights.semibold }}>{buildOneLineAddress(patientPhysician)}</Text>
                </Text>
              </Space>
            }
            trigger="hover"
            placement="top"
          >
            <span>
              <CustomInfoCircleOutlined inputStyles={{ marginLeft: "8px", cursor: "pointer" }} />
            </span>
          </Popover>
        )}
      </label>
      <PhysicianSelect
        id="patient_header_physician"
        onChange={updatePhysicianHandler}
        value={patientPhysician?.id}
        bordered={false}
        loading={isFetching}
        showArrow={hover || isFetching}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    </Space>
  );
};

export { PatientPhysician };
