import React, { useEffect } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import download from "downloadjs";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import { ErrorMessage } from "@tailormed/common-client/component/ErrorMessage";

import { useFetch } from "../../../hooks/fetch";
import { fillPdfApi } from "../../../api/api";
import { selectApplicationAttachmentsData } from "../../../store/selector";
import AttachmentPermissionsPopover from "../../AttachmentPermissionsPopover";
import { PRIMARY_650, WHITE } from "../../../constant/colors";

export const DownloadPdfButton = () => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const [fillPdf, fillPdfResult, isFillingPdf, fillPdfError] = useFetch(fillPdfApi);

  useEffect(() => {
    if (!fillPdfResult) return;
    const content = "application/pdf";
    download(fillPdfResult?.data, "application", content);
  }, [fillPdfResult]);

  useEffect(() => {
    if (!fillPdfError) return;
    ErrorMessage(t("errorMessages.error_while_downloading_form"));
  }, [fillPdfError]);

  const applicationAttachments = useSelector(selectApplicationAttachmentsData);
  const hasReadFilePermission = applicationAttachments?.canViewOrDownload;

  const handleDownload = () => fillPdf({ applicationId });

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <AttachmentPermissionsPopover>
        <Button
          loading={isFillingPdf}
          disabled={!hasReadFilePermission}
          buttonId="approved-application-result-modal"
          type="text"
          icon={<DownloadOutlined />}
          onClick={handleDownload}
          style={{ backgroundColor: PRIMARY_650, color: WHITE, marginBottom: 20 }}
        >
          {t("patientApplications.enroll.enroll_result.download_saving_card")}
        </Button>
      </AttachmentPermissionsPopover>
    </div>
  );
};
