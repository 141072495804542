import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withTranslation } from "react-i18next";
import {
  CloseOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  StarFilled,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { Checkbox, Collapse, InputNumber, Select, Space, Spin, Tooltip, Typography, Popover, Form } from "antd";
import { debounce } from "lodash";
import moment from "moment-timezone";
import styled from "styled-components";
import {
  ALL_TIMES,
  defaultFilters,
  tableFilterValues,
  assistanceProgramStatus,
  NEXT_3_DAYS
} from "../constant/filters";
import { isEmpty } from "../utils/string";
import { filterSort, stringSort } from "../utils/sort";
import {
  selectAssigneesAndUserGroups,
  selectFilters,
  selectFiltersInputs,
  selectFiltersModified,
  selectProvidersFacility,
  selectSelectedView,
  selectVisitTypes,
  selectUser,
  selectProvidersDoctors,
  selectAPSponsors
} from "../store/selector";
import { MultiSelect } from "./customComponent/CustomSelect";
import { DateRangeFilter } from "./filter/DateRangeFilter";
import ACTIONS from "../store/action";
import {
  getImportedStatusesAPI,
  searchDiagnosisIcdsAPI,
  searchDiagnosisNamesAPI,
  searchRxnormDrugsAPI
} from "../api/api";
import ClearIcon from "../assets/svg/ClearIcon";
import { ReactComponent as FilterByIcon } from "../assets/svg/filter-by-icon.svg";
import { NEW_BLUE, LIGHT_BLUE, DARK_GRAY2, GREEN_1, GREEN, WHITE, GRAY5, GRAY_400 } from "../constant/colors";
import { selectedViews } from "../constant/table";
import ErrorMessage from "./customComponent/customMessages/ErrorMessage";
import { useGetSetting } from "../hooks/getSetting";
import SaveAsNewList from "../component/SaveAsNewList";
import { formatAssignee } from "../utils/formaters";
import { MultiSelectDrugSearch } from "@tailormed/common-client/component/MultiSelectDrugSearch";
import { ClaimFiltersPanel } from "./FilterPanels/ClaimFiltersPanel";

const { Text, Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const StyledSpace = styled(Space)`
  ${(props) =>
    props.filtersVisible &&
    `@media (min-width: 1600px) {
  height: 70vh;
}
@media (max-width: 1600px) {
  height: 62vh;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 4px solid white;
  background-color: rgba(0, 0, 0, .2);
}`}

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    position: relative;
    left: 218px;
    transform: translateY(40%);
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 4px;
  }
`;

const BaseToolTip = (props) => {
  return (
    <Tooltip
      placement="topRight"
      title={<Text>{props.title}</Text>}
      id={props.toolTipId ? props.toolTipId : null}
      color={"white"}
    >
      <QuestionCircleOutlined
        id={props.iconId ? props.iconId : null}
        style={{
          height: "100%",
          color: GRAY_400,
          position: "relative",
          left: props.marginLeft ? props.marginLeft : null,
          cursor: "pointer",
          ...props.iconStyles
        }}
      />
    </Tooltip>
  );
};

const TableFiltersComponent = ({
  assignees,
  t,
  user,
  filtersVisible,
  setFiltersVisible,
  selectedView,
  filters,
  setFiltersAct,
  filtersModified,
  setFiltersInputs,
  filtersInputs,
  clearFiltersInputs,
  facilities,
  visitTypes,
  doctors,
  showSaveNewListBtn = true,
  apSponsors
}) => {
  const [diagnosisSearchResults, setDiagnosisSearchResults] = useState([]);
  const [diagnosisIcdsSearchResults, setDiagnosisIcdsSearchResults] = useState([]);
  const [importedStatuses, setImportedStatuses] = useState([]);
  const [isFetchingDiagnosis, setIsFetchingDiagnosis] = useState(false);
  const [isFetchingDiagnosisIcds, setIsFetchingDiagnosisIcds] = useState(false);
  const [physicianSearchInput, setPhysicianSearchInput] = useState("");
  const [facilitySearchInput, setFacilitySearchInput] = useState("");
  const [assigneesSearchInput, setAssigneesSearchInput] = useState("");
  const [isAssigneeSelectOpen, setIsAssigneeSelectOpen] = useState(false);
  const [isAssigneeSelectHover, setIsAssigneeSelectHover] = useState(false);
  const validAssignees =
    filtersInputs?.assignees?.length &&
    filtersInputs.assignees
      .map((assignee) =>
        assignees.find((x) => {
          // Note: assignees are stored as array of options(value, text),
          // but When user change the selected assignees from the dropdown, it will be replaced by list of ids (option.value)
          return typeof assignee !== "object" ? x.id === assignee : x.id === assignee.value;
        })
      )
      .filter((assignee) => assignee)
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  const assigneesTextToDisplay =
    (validAssignees &&
      validAssignees.length > 0 &&
      validAssignees
        .map(({ firstName, lastName, name, type }) => formatAssignee({ firstName, lastName, name, type }))
        .join(" | ")) ||
    "";

  const [form] = Form.useForm();
  const shouldHideSaveListBtn = !showSaveNewListBtn;
  const dateFilterName =
    selectedView === selectedViews.allApplications
      ? "validThroughDateRangeAllApplicationFilters"
      : "validThroughDateRangeFilters";

  const [visitScheduleEnabled, showPhysician, statuses, claimsEnabled, withSignatureSubscription] = useGetSetting([
    "automation.visit-schedule.fe-display",
    "doctorProviderMode",
    "statuses",
    "fe.show_claims",
    "subscription.signature"
  ]);
  const applicationTypeFilterOptions =
    selectedView === selectedViews.allClaims
      ? tableFilterValues.claimPotentialSavingsTypes
      : tableFilterValues.potentialSavingsTypes;
  useEffect(() => {
    getImportedStatuses();
  }, []);

  const getImportedStatuses = async () => {
    try {
      const importedStatusesRes = await getImportedStatusesAPI();
      if (importedStatusesRes?.data) {
        setImportedStatuses(importedStatusesRes.data);
      } else {
        ErrorMessage(t("errorMessages.failed_to_get_imported_statuses"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.failed_to_get_imported_statuses"));
    }
  };

  const { dates, multiples, numbers } = filters;

  const {
    patientCreationDateRange,
    visitScheduleDateRange,
    followupDate,
    coverageChangeDate,
    medicationCreatedDate = defaultFilters.dates.medicationCreatedDate,
    activeDate,
    validThroughDateRange,
    approvedDateRange = defaultFilters.dates.approvedDateRange
  } = dates;

  useEffect(() => {
    // Note: refill the numbers controls once updated from the store.
    if (selectedView != selectedViews.savedList) {
      form.setFieldsValue({
        remainingOOP: numbers.remainingOOP,
        applicationFundBalance: numbers.applicationFundBalance
      });
    }
  }, [numbers, selectedView]);

  const clearFilters = () => {
    setDiagnosisSearchResults([]);
    setDiagnosisIcdsSearchResults([]);
    setFiltersAct(defaultFilters);
    clearFiltersInputs();
  };

  const onDiagnosisSearch = async (text) => {
    if (isEmpty(text)) return;

    try {
      setIsFetchingDiagnosis(true);
      const result = await searchDiagnosisNamesAPI(text);
      const { data } = result;
      setDiagnosisSearchResults(data.data);
    } catch (error) {
      ErrorMessage(`${t("errorMessages.diagnosis_names")} ${error}`);
    } finally {
      setIsFetchingDiagnosis(false);
    }
  };

  const onDiagnosisIcdsSearch = async (text) => {
    if (isEmpty(text)) return;

    try {
      setIsFetchingDiagnosisIcds(true);
      const result = await searchDiagnosisIcdsAPI(text);
      const { data } = result;
      setDiagnosisIcdsSearchResults(data.data);
    } catch (error) {
      ErrorMessage(`${t("errorMessages.diagnosis_names")} ${error}`);
    } finally {
      setIsFetchingDiagnosisIcds(false);
    }
  };

  const handleInputMultiSelect = (values, options, filterName) => {
    const filterValues = {};

    setFiltersInputs(filterName, values);
    options.map((option, index) => {
      filterValues[index] = { value: option.value, text: option.children };
    });

    setFiltersAct({
      ...filters,
      multiples: { ...multiples, [filterName]: filterValues }
    });
  };

  const handleInputSingleSelect = (value, options, filterName) => {
    setFiltersAct({
      ...filters,
      [filterName]: value
    });
  };

  const handleSelectedDrugsChange = (selectedDrugs) => {
    setFiltersInputs("rxnormDrugs", selectedDrugs);

    setFiltersAct({
      ...filters,
      multiples: { ...multiples, rxnormDrugs: selectedDrugs }
    });
  };

  const searchRxnormDrugs = async (searchTerm) => {
    const response = await searchRxnormDrugsAPI({ text: searchTerm });
    return response?.data?.rxnormDrugs || [];
  };

  const handleCheckUnassigned = (value) => {
    setFiltersInputs("isUnassigned", value);
    setFiltersInputs("assignees", []);
    setFiltersAct({
      ...filters,
      isUnassigned: value,
      multiples: { ...multiples, ["assignees"]: {} }
    });
  };

  const handleInputDate = (fromDate, toDate, filterName, value) => {
    setFiltersAct({
      ...filters,
      dates: { ...dates, [filterName]: { fromDate, toDate, value } }
    });
  };

  const resetDatesToDefault = (dateKey, value) => {
    handleInputDate(null, null, dateKey, value);
    setFiltersInputs(dateKey, null);
  };

  const handleInputNumber = (value, filterName) => {
    setFiltersAct({
      ...filters,
      numbers: { ...numbers, [filterName]: value }
    });
  };

  const handleCheckbox = (e, filterName) => {
    setFiltersAct({ ...filters, [filterName]: e.target.checked });
    window.scroll(0, 0);
  };

  const content = (
    <div
      style={{
        width: "290px",
        margin: "5px 20px",
        fontWeight: "400",
        whiteSpace: "pre-wrap",
        color: "rgba(0,0,0,.87)"
      }}
    >
      <span>
        {t("physician_popover.part_one")}
        <span style={{ textDecoration: "underline", margin: "5px" }}>{t("physician_popover.assigned")}</span>
        {t("physician_popover.part_two")}
        <span style={{ fontStyle: "italic", margin: "5px" }}>&quot;{t("physician_popover.visit_schedule")}&quot;</span>
        {t("physician_popover.part_three")}
      </span>
    </div>
  );

  const isPatientFiltersActive = () => {
    const starredPatients = filters.starredPatients;
    const expressEnrollEligible = filters.expressEnrollEligible;
    const inActiveTreatment = filters.inActiveTreatment;
    const hideDeceasedPatients = filters.hideDeceasedPatients;
    const ageRanges = filtersInputs.ageRanges;
    const { value: PatientDateValue } = filters.dates.patientCreationDateRange;
    const facilities = filtersInputs.facilities;
    const doctors = filtersInputs.doctors;
    const statuses = filtersInputs.statuses;
    const assignees = filtersInputs.assignees;
    const isUnassigned = filtersInputs.isUnassigned;
    const { value: followupDateValue } = filters.dates.followupDate;

    return (
      !!starredPatients ||
      !!expressEnrollEligible ||
      !!inActiveTreatment ||
      !!hideDeceasedPatients ||
      PatientDateValue !== ALL_TIMES ||
      followupDateValue !== ALL_TIMES ||
      !!statuses.length ||
      !!ageRanges.length ||
      !!assignees.length ||
      !!facilities.length ||
      !!doctors.length ||
      !!isUnassigned
    );
  };

  const isVisitScheduleActive = () => {
    const { value: visitDateValue } = filters.dates.visitScheduleDateRange;
    const visitTypes = filtersInputs.visitTypes;
    const groupPatientVisits = filters.groupPatientVisits;
    const { visitFacilities = [] } = filtersInputs;

    if (visitFacilities.length || visitDateValue !== NEXT_3_DAYS || visitTypes || groupPatientVisits) return true;
    return false;
  };

  const isHighPriorityActive = () => {
    const topOppAPStatuses = filtersInputs.topOppAPStatuses;
    const potentialSavingsTypes = filtersInputs.potentialSavingsTypes;
    return Boolean(potentialSavingsTypes.length || topOppAPStatuses.length);
  };

  const isInsuranceActive = () => {
    const planMarketTypes = filtersInputs.planMarketTypes;
    const { value: coverageDateValue } = filters.dates.coverageChangeDate;
    const insuranceCombination = filters.insuranceCombination;

    if (planMarketTypes.length || coverageDateValue !== ALL_TIMES || insuranceCombination) {
      return true;
    } else {
      return false;
    }
  };

  const isDrugFiltersActive = () => {
    const drugs = filters.multiples.rxnormDrugs;
    const drugRoutes = filtersInputs.drugRoutes;
    const medicationCreatedDateValue = filters.dates?.medicationCreatedDate?.value;
    return (drugs && Object.keys(drugs).length !== 0) || medicationCreatedDateValue !== ALL_TIMES || drugRoutes.length;
  };

  const isDiagnosisFiltersActive = () => {
    const diagnosisCodeIDs = filtersInputs.diagnosisCodeIDs;
    const diagnosisTypes = filtersInputs.diagnosisTypes;
    const { value: activeDateValue } = filters.dates.activeDate;
    const diagnosisNames = filtersInputs.diagnosisNames;

    return (
      (diagnosisNames && diagnosisNames.length !== 0) ||
      diagnosisCodeIDs.length !== 0 ||
      activeDateValue !== ALL_TIMES ||
      diagnosisTypes?.length
    );
  };

  const isApplicationsFiltersActive = () => {
    const applicationSignatureStatuses = filtersInputs.applicationSignatureStatuses;
    const applicationsTypes = filtersInputs.potentialSavingsTypes;
    const applicationsDrugName = filtersInputs.rxnormDrugs;
    const applicationsDrugRoutes = filtersInputs.potentialSavingsDrugRoutes;
    const applicationsAPStatuses = filtersInputs.potentialSavingsAplicationStatuses;
    const applicationsAPSponsor = filtersInputs.potentialSavingsAPSponsor;
    const validThroughDateRangeValue = filters.dates?.validThroughDateRange?.value;
    const { applicationFundBalance } = numbers;
    const approvedDateRangeValue = filters.dates?.approvedDateRange?.value;
    const claimExists = filters.claimExists;

    if (
      applicationSignatureStatuses?.length ||
      applicationsTypes?.length ||
      applicationsDrugName?.length ||
      applicationsDrugRoutes?.length ||
      applicationsAPStatuses?.length ||
      applicationsAPSponsor?.length ||
      validThroughDateRangeValue !== ALL_TIMES ||
      (applicationFundBalance && applicationFundBalance !== "0") ||
      approvedDateRangeValue !== ALL_TIMES ||
      claimExists !== null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isAllApplicationsActive = () => [selectedViews.allApplications, selectedViews.allClaims].includes(selectedView);

  const ApplicationPanel = (
    <Panel
      header={
        <Title level={5} style={{ color: NEW_BLUE }}>
          {t("tableFilters.applications")}
          {isApplicationsFiltersActive() ? (
            <div
              style={{
                position: "absolute",
                background: GREEN,
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                left: "240px",
                top: "24px"
              }}
            />
          ) : (
            <></>
          )}
        </Title>
      }
    >
      <Space style={{ width: "100%" }} direction="vertical">
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.type")}
          </Text>
          <MultiSelect
            id="potential_savings_filter_type"
            onChange={(values, options) => {
              handleInputMultiSelect(values, options, "potentialSavingsTypes");
            }}
            value={filtersInputs.potentialSavingsTypes}
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={filtersInputs.potentialSavingsTypes.length > 1 ? 8 : 16}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder={t("all")}
            bordered={false}
          >
            {applicationTypeFilterOptions.map((option) => {
              const id = `potential_savings_filter_type_${option.translationText}`;
              return (
                <Option id={id} key={option.key} value={option.value}>
                  {t(option.translationText)}
                </Option>
              );
            })}
          </MultiSelect>
        </Space>
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.application_drug_name")}
          </Text>
          <MultiSelectDrugSearch
            id="drug_filter_name"
            searchRxnormDrugs={searchRxnormDrugs}
            mode="multiple"
            placeholder={t("all")}
            onChangeSelectedDrugs={(selectedDrugs) => handleSelectedDrugsChange(selectedDrugs)}
            onClear={() => handleSelectedDrugsChange([])}
            value={filtersInputs.rxnormDrugs}
            displayNameMaxChars={25}
            bordered={false}
            style={{
              width: "100%",
              border: `0px`,
              borderBottom: `1px solid ${GRAY5}`,
              padding: 0
            }}
          />
        </Space>
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.drug_route")}
          </Text>
          <MultiSelect
            id="potential_savings_filter_drug_route"
            onChange={(values, options) => {
              handleInputMultiSelect(values, options, "potentialSavingsDrugRoutes");
            }}
            value={filtersInputs.potentialSavingsDrugRoutes}
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={filtersInputs.potentialSavingsDrugRoutes.length > 1 ? 8 : 16}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder={t("all")}
            bordered={false}
          >
            {tableFilterValues.drugRoute.map((option) => (
              <Option key={option.key} value={option.value}>
                {t(option.translationText)}
              </Option>
            ))}
          </MultiSelect>
        </Space>

        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.application_status")}
          </Text>
          <MultiSelect
            id="potential_savings_filter_application_status"
            onChange={(values, options) => {
              handleInputMultiSelect(values, options, "potentialSavingsAplicationStatuses");
            }}
            value={filtersInputs.potentialSavingsAplicationStatuses}
            mode="multiple"
            maxTagCount={1}
            autoClearSearchValue={false}
            maxTagTextLength={filtersInputs.potentialSavingsAplicationStatuses ? 9 : 18}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder={t("all")}
            bordered={false}
            filterSort={filterSort}
          >
            {isAllApplicationsActive()
              ? tableFilterValues.allApplicationsAPStatuses.map((option, index) => (
                  <Option key={index} value={option.value} localSortKey={option.localSortKey.toString()}>
                    {t(option.translationText)}
                  </Option>
                ))
              : tableFilterValues.pendingRenewalAPStatuses.map((option, index) => (
                  <Option key={index} value={option.value} localSortKey={option.localSortKey.toString()}>
                    {t(option.translationText)}
                  </Option>
                ))}
          </MultiSelect>
        </Space>
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.ap_sponsor")}
          </Text>
          <MultiSelect
            id="potential_savings_filter_ap_sponsor"
            onChange={(values, options) => {
              handleInputMultiSelect(values, options, "potentialSavingsAPSponsor");
            }}
            value={filtersInputs.potentialSavingsAPSponsor}
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={filtersInputs.potentialSavingsAPSponsor ? 8 : 16}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder={t("all")}
            bordered={false}
          >
            {apSponsors?.map((option, index) => (
              <Option key={index} value={option}>
                {option}
              </Option>
            ))}
          </MultiSelect>
        </Space>
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.approved_date")}
          </Text>
          <DateRangeFilter
            id="approved_date_input"
            handleInput={(value = ALL_TIMES) => setFiltersInputs("approvedDateRange", value)}
            onSelect={(fromDate, toDate, value = null) => handleInputDate(fromDate, toDate, "approvedDateRange", value)}
            defaultValue={ALL_TIMES}
            placeHolder={t(ALL_TIMES)}
            dateFilters="approvedDateFilters"
            timeRange={approvedDateRange}
            allowClear={true}
            onClear={() => {
              resetDatesToDefault("approvedDateRange", ALL_TIMES);
            }}
          />
        </Space>
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.valid_through_date")}
          </Text>
          <DateRangeFilter
            id="valid_through_date_input"
            handleInput={(value = ALL_TIMES) => setFiltersInputs("validThroughDateRange", value)}
            onSelect={(fromDate, toDate, value = null) =>
              handleInputDate(fromDate, toDate, "validThroughDateRange", value)
            }
            defaultValue={ALL_TIMES}
            placeHolder={t(ALL_TIMES)}
            dateFilters={dateFilterName}
            timeRange={validThroughDateRange}
            allowClear={true}
            onClear={() => {
              resetDatesToDefault("validThroughDateRange", ALL_TIMES);
            }}
          />
        </Space>

        {isAllApplicationsActive() && withSignatureSubscription && (
          <Space size={0} style={{ width: "100%" }} direction="vertical">
            <Text strong style={{ color: LIGHT_BLUE }}>
              {t("tableFilters.signature_status")}
            </Text>
            <MultiSelect
              id="application_signature_statuses_filter"
              onChange={(values, options) => handleInputMultiSelect(values, options, "applicationSignatureStatuses")}
              value={filtersInputs.applicationSignatureStatuses}
              mode="multiple"
              maxTagCount={1}
              maxTagTextLength={filtersInputs.applicationSignatureStatuses.length > 1 ? 8 : 16}
              allowClear={true}
              style={{ width: "100%" }}
              placeholder={t("all")}
              bordered={false}
            >
              {tableFilterValues.applicationSignatureStatuses.map((option) => (
                <Option key={option.key} value={option.value}>
                  {t(option.translationText)}
                </Option>
              ))}
            </MultiSelect>
          </Space>
        )}
        {claimsEnabled && selectedView !== selectedViews.allClaims && (
          <Space size={0} style={{ width: "100%" }} direction="vertical">
            <div>
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.claim_exists.text")}
              </Text>
              <BaseToolTip
                title={t("tableFilters.claim_exists.pop_up_content")}
                toolTipId="claims_exist_filter_popup"
                iconId="claims_exist_filter_popup_icon"
                iconStyles={{ left: "10px" }}
              />
            </div>
            <Select
              id="filter_by_claim_exists"
              onSelect={(values, option) => {
                handleInputSingleSelect(values, option, "claimExists");
              }}
              onClear={() => {
                handleInputSingleSelect(defaultFilters.claimExists, null, "claimExists");
              }}
              value={filters.claimExists}
              allowClear={true}
              style={{ width: "100%", borderBottom: `1px solid ${GRAY5}` }}
              placeholder={t("all")}
              bordered={false}
            >
              {tableFilterValues.claimExists.map((option) => {
                const id = `filter_by_claim_exists_${option.translationText}`;
                return (
                  <Option id={id} key={option.key} value={option.value}>
                    {t(option.translationText)}
                  </Option>
                );
              })}
            </Select>
          </Space>
        )}
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.max_available_funds")}
          </Text>
          <Form form={form}>
            <Form.Item
              name="applicationFundBalance"
              rules={[{ required: false }]}
              initialValue={numbers.applicationFundBalance ? numbers.applicationFundBalance : 0}
            >
              <InputNumber
                id="application_fund_balance"
                onChange={debounce(
                  (value) => handleInputNumber(value && value != "0" ? value : 0, "applicationFundBalance"),
                  500
                )}
                controls={false}
                addonAfter="$"
                defaultValue="0"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Form>
        </Space>
      </Space>
    </Panel>
  );

  const isTopOppFiltersActive = () => selectedView === selectedViews.highPriority;

  const disabledDate = (current) => current && current < moment().subtract(1, "days");

  return (
    <div>
      <div
        style={{
          position: "absolute",
          left: filtersVisible ? "300px" : "135px",
          top: filtersVisible ? "165px" : "170px",
          zIndex: "1",
          display: shouldHideSaveListBtn ? "none" : undefined
        }}
      >
        <SaveAsNewList id="filters_saves_as_new_list" disabled={!filtersModified} />
      </div>

      <StyledSpace
        size={0}
        direction={filtersVisible ? "vertical" : "horizontal"}
        style={{
          backgroundColor: filtersVisible ? "#F9F9F9" : "white",
          padding: filtersVisible ? "17px 0" : undefined,
          minWidth: filtersVisible ? "275px" : "110px",
          position: !filtersVisible ? "absolute" : undefined,
          borderRight: !filtersVisible && showSaveNewListBtn ? `1px solid ${DARK_GRAY2}` : undefined,
          top: !filtersVisible ? "173px" : undefined,
          zIndex: !filtersVisible ? "1" : undefined,
          overflow: "auto"
        }}
        filtersVisible={filtersVisible}
      >
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            padding: filtersVisible ? "0 17px" : undefined,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          }}
          onClick={() => setFiltersVisible(!filtersVisible)}
        >
          <div
            id="filter_by_btn"
            style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          >
            <FilterByIcon />
            {filtersModified ? (
              <div
                style={{
                  background: GREEN,
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  marginTop: "-13px",
                  marginLeft: "-2px"
                }}
              />
            ) : (
              <></>
            )}
            <Text
              style={{
                color: NEW_BLUE,
                paddingLeft: 6,
                paddingTop: 2,
                fontWeight: 600,
                textTransform: "uppercase"
              }}
            >
              {t("filter_by")}
            </Text>
          </div>
          {filtersVisible ? <CloseOutlined style={{ color: NEW_BLUE }} /> : <></>}
        </div>
        <div
          onClick={() => filtersModified && clearFilters()}
          style={{
            display: !filtersVisible ? "none" : "flex",
            justifyContent: "flex-end",
            padding: "8px 16px 0px 0px",
            opacity: !filtersModified ? "0.5" : undefined,
            cursor: !filtersModified ? "not-allowed" : "pointer"
          }}
        >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ClearIcon />
            <Text strong level={5} style={{ color: NEW_BLUE, paddingLeft: 4 }}>
              {t("clear_all")}
            </Text>
          </div>
        </div>
        <Collapse
          ghost
          expandIconPosition="end"
          style={{ display: !filtersVisible ? "none" : undefined, padding: "0px 10px 10px 10px" }}
        >
          {isTopOppFiltersActive() && (
            <Panel
              header={
                <Title level={5} style={{ color: NEW_BLUE }}>
                  {t("tableFilters.high_priority")}
                  {isHighPriorityActive() && (
                    <div
                      style={{
                        position: "absolute",
                        background: GREEN,
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        left: "240px",
                        top: "24px"
                      }}
                    />
                  )}
                </Title>
              }
            >
              <Space size={16} direction="vertical" style={{ width: "100%" }}>
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.assistance_program_status")}
                  </Text>
                  <MultiSelect
                    id="high_priority_ap_status"
                    onChange={(values, options) => handleInputMultiSelect(values, options, "topOppAPStatuses")}
                    value={filtersInputs.topOppAPStatuses}
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={filtersInputs.topOppAPStatuses.length > 1 ? 8 : 16}
                    allowClear={true}
                    style={{ width: "100%" }}
                    placeholder={t("all")}
                    bordered={false}
                  >
                    {assistanceProgramStatus.map((option) => (
                      <Option key={option.key} value={option.value}>
                        {t(option.translationText)}
                      </Option>
                    ))}
                  </MultiSelect>
                </Space>
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.type")}
                  </Text>
                  <MultiSelect
                    id="potential_savings_filter_type"
                    onChange={(values, options) => {
                      handleInputMultiSelect(values, options, "potentialSavingsTypes");
                    }}
                    value={filtersInputs.potentialSavingsTypes}
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={filtersInputs.potentialSavingsTypes.length > 1 ? 8 : 16}
                    allowClear={true}
                    style={{ width: "100%" }}
                    placeholder={t("all")}
                    bordered={false}
                  >
                    {tableFilterValues.potentialSavingsTypes.map((option) => {
                      const id = `potential_savings_filter_type_${option.translationText}`;
                      return (
                        <Option id={id} key={option.key} value={option.value}>
                          {t(option.translationText)}
                        </Option>
                      );
                    })}
                  </MultiSelect>
                </Space>
              </Space>
            </Panel>
          )}
          <ClaimFiltersPanel
            handleInputMultiSelect={handleInputMultiSelect}
            handleInputDate={handleInputDate}
            resetDatesToDefault={resetDatesToDefault}
            filtersVisible={filtersVisible}
          />
          {isAllApplicationsActive() && ApplicationPanel}
          {selectedView === selectedViews.pendingRenewal && ApplicationPanel}
          <Panel
            header={
              <Title level={5} style={{ color: NEW_BLUE }}>
                {t("tableFilters.patient")}
                {isPatientFiltersActive() && (
                  <div
                    style={{
                      position: "absolute",
                      background: GREEN,
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      left: "240px",
                      top: "24px"
                    }}
                  />
                )}
              </Title>
            }
          >
            <Space size={16} direction="vertical" style={{ width: "100%" }}>
              <Space id="filter_by_starred_patients">
                <Checkbox
                  style={{ marginLeft: "0px" }}
                  checked={filters.starredPatients}
                  onChange={(e) => handleCheckbox(e, "starredPatients")}
                >
                  <StarFilled
                    style={{
                      color: GREEN_1
                    }}
                  />
                  <Text strong style={{ color: LIGHT_BLUE, paddingLeft: 4 }}>
                    {t("starred")}
                  </Text>
                </Checkbox>
              </Space>

              <Space size={0} align="baseline">
                <Space id="filter_by_deceased_patients">
                  <Checkbox
                    style={{ marginLeft: "0px" }}
                    checked={filters.hideDeceasedPatients}
                    onChange={(e) => handleCheckbox(e, "hideDeceasedPatients")}
                  >
                    <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                      <Text strong style={{ color: LIGHT_BLUE }}>
                        {t("hide_deceased_patients")}
                      </Text>
                    </div>
                  </Checkbox>
                </Space>
              </Space>

              <Space size={0} align="baseline">
                <Space id="filter_by_active_treatments">
                  <Checkbox
                    style={{ marginLeft: "0px" }}
                    checked={filters.inActiveTreatment}
                    onChange={(e) => handleCheckbox(e, "inActiveTreatment")}
                  >
                    <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                      <Text strong style={{ color: LIGHT_BLUE }}>
                        {t("active_treatment")}
                      </Text>
                    </div>
                  </Checkbox>
                </Space>

                <BaseToolTip title={t("active_treatment_tooltip")} />
              </Space>

              {[selectedViews.allPatients, selectedViews.visitSchedule, selectedViews.highPriority].includes(
                selectedView
              ) && (
                <Space size={0} align="baseline">
                  <Space id="filter_eligibile_to_express_enroll">
                    <Checkbox
                      style={{ marginLeft: "0px" }}
                      checked={filters.expressEnrollEligible}
                      onChange={(e) => handleCheckbox(e, "expressEnrollEligible")}
                    >
                      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                        <Text strong style={{ color: LIGHT_BLUE }}>
                          {t("express_enroll_eligibile")}
                        </Text>
                      </div>
                    </Checkbox>
                  </Space>

                  <BaseToolTip title={t("express_enroll_eligibile_tooltip")} />
                </Space>
              )}
              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Text strong style={{ color: LIGHT_BLUE }}>
                  {t("tableFilters.age")}
                </Text>
                <MultiSelect
                  id="patient_age_selector"
                  onChange={(values, options) => handleInputMultiSelect(values, options, "ageRanges")}
                  value={filtersInputs.ageRanges}
                  mode="multiple"
                  maxTagCount="responsive"
                  allowClear={true}
                  style={{ width: "100%" }}
                  placeholder={t("all")}
                  bordered={false}
                >
                  {tableFilterValues.patientAge.map((option) => (
                    <Option id={`patient_filter_by_ages_${option.id}`} key={option.key} value={option.value}>
                      {t(option.translationText)}
                    </Option>
                  ))}
                </MultiSelect>
              </Space>

              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Text strong style={{ color: LIGHT_BLUE }}>
                  {t("tableFilters.created_date")}
                </Text>
                <DateRangeFilter
                  id="patient_filter_created_date"
                  handleInput={(value = ALL_TIMES) => setFiltersInputs("patientCreationDateRange", value)}
                  onSelect={(fromDate, toDate, value = null) =>
                    handleInputDate(fromDate, toDate, "patientCreationDateRange", value)
                  }
                  defaultValue={ALL_TIMES}
                  placeHolder={t(ALL_TIMES)}
                  dateFilters="pastFilters"
                  timeRange={patientCreationDateRange}
                  allowClear={true}
                  onClear={() => {
                    resetDatesToDefault("patientCreationDateRange", ALL_TIMES);
                  }}
                />
              </Space>

              <Space style={{ width: "100%" }} direction="vertical">
                <Text strong style={{ color: LIGHT_BLUE }}>
                  {t("tableFilters.patientFacility")}
                </Text>
                <MultiSelect
                  id="filter_patient_facility"
                  dropdownMatchSelectWidth={false}
                  onChange={(values, options) => handleInputMultiSelect(values, options, "facilities")}
                  value={filtersInputs.facilities}
                  autoClearSearchValue={false}
                  showSearch
                  mode="multiple"
                  maxTagCount={1}
                  maxTagTextLength={filtersInputs.facilities.length > 1 ? 8 : 16}
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={`${t("all")}`}
                  filterOption={(input, option) => {
                    setFacilitySearchInput(input.toLowerCase());
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  filterSort={filterSort}
                  bordered={false}
                  clearAll={true}
                  selectAll={true}
                  onSelectAll={() => {
                    const filteredFacilities = facilities.filter((d) =>
                      d.name?.toLowerCase().includes(facilitySearchInput)
                    );
                    const values = filteredFacilities.map(({ id }) => id);
                    const options = facilities.map(({ id, name }, index) => ({
                      value: id,
                      key: index,
                      children: name
                    }));
                    handleInputMultiSelect(values, options, "facilities");
                  }}
                  onClearAll={() => {
                    handleInputMultiSelect([], [], "facilities");
                    setFacilitySearchInput("");
                  }}
                >
                  {facilities &&
                    !!facilities.length &&
                    _.uniqBy(facilities, (facility) => facility.name?.toLowerCase().trim()).map((facility, index) => (
                      <Option key={index} value={facility.id} localSortKey={facility.name}>
                        {facility.name}
                      </Option>
                    ))}
                </MultiSelect>
              </Space>

              {showPhysician && (
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <div style={{ display: "flex" }}>
                    <Text strong style={{ color: LIGHT_BLUE }}>
                      {t("tableFilters.physician")}
                    </Text>
                    <div style={{ cursor: "pointer", width: "14px", marginLeft: "10px" }}>
                      <Popover content={content}>
                        <ExclamationCircleOutlined style={{ fontSize: "14px", color: GRAY_400 }} />
                      </Popover>
                    </div>
                  </div>

                  <MultiSelect
                    id="filter_patient_physician"
                    onChange={(values, options) => handleInputMultiSelect(values, options, "doctors")}
                    onSelectAll={() => {
                      const filteredDoctors = doctors.filter((d) =>
                        d.name?.toLowerCase().includes(physicianSearchInput)
                      );
                      const values = filteredDoctors.map(({ id }) => id);
                      const options = doctors.map(({ id, name }, index) => ({
                        value: id,
                        key: index,
                        children: name
                      }));
                      handleInputMultiSelect(values, options, "doctors");
                    }}
                    clearAll={true}
                    onClearAll={() => {
                      handleInputMultiSelect([], [], "doctors");
                      setPhysicianSearchInput("");
                    }}
                    value={filtersInputs.doctors}
                    autoClearSearchValue={false}
                    showSearch={true}
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={filtersInputs.doctors.length > 1 ? 8 : 16}
                    allowClear={true}
                    selectAll={true}
                    style={{ width: "100%" }}
                    placeholder={`${t("all")}`}
                    filterOption={(input, option) => {
                      setPhysicianSearchInput(input.toLowerCase());
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    filterSort={filterSort}
                    bordered={false}
                  >
                    {doctors &&
                      !!doctors.length &&
                      _.uniqBy(doctors, (doctor) => doctor.name?.toLowerCase().trim()).map((doctor, index) => (
                        <Option key={index} value={doctor.id} localSortKey={doctor.name}>
                          {doctor.name}
                        </Option>
                      ))}
                  </MultiSelect>
                </Space>
              )}
              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Text strong style={{ color: LIGHT_BLUE }}>
                  {t("tableFilters.status")}
                </Text>
                <MultiSelect
                  id="filter_patient_status"
                  onChange={(values, options) => handleInputMultiSelect(values, options, "statuses")}
                  value={filtersInputs.statuses}
                  autoClearSearchValue={false}
                  mode="multiple"
                  maxTagCount={1}
                  maxTagTextLength={filtersInputs.statuses.length > 1 ? 8 : 16}
                  allowClear={true}
                  style={{ width: "100%" }}
                  placeholder={t("all")}
                  bordered={false}
                >
                  {statuses?.length &&
                    statuses
                      .filter((status) => !status.roleRequirement || status.roleRequirement == user?.role)
                      .map((status) => (
                        <Option key={status.value} value={status.value}>
                          {status.text}
                        </Option>
                      ))}
                </MultiSelect>
              </Space>
              {!!importedStatuses.length && (
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.import_status")}
                  </Text>
                  <MultiSelect
                    id="patient_filter_imported_statuses"
                    onChange={(values, options) => handleInputMultiSelect(values, options, "importedStatuses")}
                    value={filtersInputs.importedStatuses}
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={filtersInputs.importedStatuses?.length > 1 ? 8 : 16}
                    allowClear={true}
                    style={{ width: "100%" }}
                    placeholder={t("all")}
                    bordered={false}
                  >
                    {importedStatuses.map((importedStatus, index) => {
                      return (
                        <Option key={index} value={importedStatus.value}>
                          {importedStatus.text}
                        </Option>
                      );
                    })}
                  </MultiSelect>
                </Space>
              )}
              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Text strong style={{ color: LIGHT_BLUE }}>
                  {t("tableFilters.assignee")}
                </Text>
                <Tooltip
                  open={assigneesTextToDisplay.length && !isAssigneeSelectOpen && isAssigneeSelectHover}
                  title={<Text>{assigneesTextToDisplay}</Text>}
                  color={WHITE}
                >
                  <MultiSelect
                    id="filter_patient_assignee"
                    onChange={(values, options) => handleInputMultiSelect(values, options, "assignees")}
                    disabled={filtersInputs.isUnassigned}
                    value={!validAssignees ? [] : validAssignees.map(({ id }) => id)}
                    autoClearSearchValue={false}
                    showSearch
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={filtersInputs.assignees.length > 1 ? 8 : 16}
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={filtersInputs.isUnassigned ? t("unassigned") : `${t("all")}`}
                    filterOption={(input, option) => {
                      setAssigneesSearchInput(input.toLowerCase());
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    filterSort={(a, b) => {
                      const aIsSelected = filtersInputs.assignees.indexOf(a.value) > -1;
                      const bIsSelected = filtersInputs.assignees.indexOf(b.value) > -1;
                      if ((aIsSelected && bIsSelected) || (!aIsSelected && !bIsSelected)) {
                        return a.children.localeCompare(b.children);
                      }
                      if (aIsSelected && !bIsSelected) {
                        return -1;
                      }
                      if (!aIsSelected && bIsSelected) {
                        return 1;
                      }
                    }}
                    bordered={false}
                    onSelectAll={() => {
                      const filteredAssignees = assignees.filter((d) =>
                        d.displayName?.toLowerCase().includes(assigneesSearchInput)
                      );
                      const values = filteredAssignees.map(({ id }) => id);
                      const options = assignees.map(({ id, displayName, name }, index) => ({
                        value: id,
                        key: index,
                        children: displayName || name
                      }));

                      handleInputMultiSelect(values, options, "assignees");
                    }}
                    onClearAll={() => {
                      handleInputMultiSelect([], [], "assignees");
                      setAssigneesSearchInput("");
                    }}
                    onDropdownVisibleChange={(open) => setIsAssigneeSelectOpen(open)}
                    onMouseEnter={() => setIsAssigneeSelectHover(true)}
                    onMouseLeave={() => setIsAssigneeSelectHover(false)}
                    selectAll
                    clearAll
                  >
                    {assignees.map(({ id, firstName, lastName, name, type }) => (
                      <Option key={id} value={id}>
                        {formatAssignee({ firstName, lastName, name, type })}
                      </Option>
                    ))}
                  </MultiSelect>
                </Tooltip>
                <Space id="patient_filter_unassignee">
                  <Checkbox
                    style={{ paddingTop: 4 }}
                    checked={filtersInputs.isUnassigned}
                    onChange={(e) => handleCheckUnassigned(e.target.checked)}
                  >
                    <Text style={{ color: LIGHT_BLUE }}>{t("unassigned")}</Text>
                  </Checkbox>
                </Space>
              </Space>
              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Text strong style={{ color: LIGHT_BLUE }}>
                  {t("tableFilters.follow_up_date")}
                </Text>
                <DateRangeFilter
                  id="patient_filter_follow_up_date"
                  handleInput={(value = ALL_TIMES) => setFiltersInputs("followupDate", value)}
                  onSelect={(fromDate, toDate, value = null) =>
                    handleInputDate(fromDate, toDate, "followupDate", value)
                  }
                  allowClear={true}
                  onClear={() => {
                    resetDatesToDefault("followupDate", ALL_TIMES);
                  }}
                  defaultValue={ALL_TIMES}
                  placeHolder={t(ALL_TIMES)}
                  dateFilters="followUpFilters"
                  timeRange={followupDate}
                />
              </Space>
            </Space>
          </Panel>
          {visitScheduleEnabled && selectedView === selectedViews.visitSchedule && (
            <Panel
              header={
                <Title level={5} style={{ color: NEW_BLUE }}>
                  {t("tableFilters.visit_schedule")}
                  {isVisitScheduleActive() ? (
                    <div
                      style={{
                        position: "absolute",
                        background: GREEN,
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        left: "240px",
                        top: "24px"
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Title>
              }
            >
              <Space size={16} direction="vertical" style={{ width: "100%" }}>
                <Space align="baseline">
                  <Space id="visit_schedule_group_patients">
                    <Checkbox
                      style={{ marginLeft: "0px" }}
                      checked={filters.groupPatientVisits}
                      onChange={(e) => handleCheckbox(e, "groupPatientVisits")}
                    >
                      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                        <Text strong style={{ color: LIGHT_BLUE }}>
                          {t("group_patients_visits")}
                        </Text>
                      </div>
                    </Checkbox>
                  </Space>

                  <BaseToolTip title={t("group_patients_visits_tooltip")} />
                </Space>

                <Space style={{ width: "100%", marginTop: "15px" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.visitFacility")}
                  </Text>
                  <MultiSelect
                    id="visit_schedule_facility"
                    dropdownMatchSelectWidth={false}
                    onChange={(values, options) => handleInputMultiSelect(values, options, "visitFacilities")}
                    value={filtersInputs.visitFacilities}
                    showSearch
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={filtersInputs?.visitFacilities?.length > 1 ? 8 : 16}
                    allowClear
                    placeholder={`${t("all")}`}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={filterSort}
                    bordered={false}
                    clearAll={true}
                    selectAll={true}
                    onSelectAll={() => {
                      const values = facilities.map(({ id }) => id);
                      const options = facilities.map(({ id, name }, index) => ({
                        value: id,
                        key: index,
                        children: name
                      }));
                      handleInputMultiSelect(values, options, "visitFacilities");
                    }}
                    onClearAll={() => handleInputMultiSelect([], [], "visitFacilities")}
                  >
                    {facilities &&
                      !!facilities.length &&
                      facilities.map((facility, index) => (
                        <Option key={index} value={facility.id} localSortKey={facility.name}>
                          {facility.name}
                        </Option>
                      ))}
                  </MultiSelect>
                </Space>
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.visit_date")}
                  </Text>
                  <DateRangeFilter
                    id="visit_schedule_date"
                    handleInput={(value = NEXT_3_DAYS) => setFiltersInputs("visitScheduleDateRange", value)}
                    onSelect={(fromDate, toDate, value = null) => {
                      handleInputDate(fromDate, toDate, "visitScheduleDateRange", value);
                    }}
                    allowClear={true}
                    onClear={() => {
                      resetDatesToDefault("visitScheduleDateRange", NEXT_3_DAYS);
                    }}
                    defaultValue={NEXT_3_DAYS}
                    placeHolder={t(NEXT_3_DAYS)}
                    dateFilters="visitScheduleDateRangeFilters"
                    timeRange={visitScheduleDateRange}
                    disabledDate={disabledDate}
                  />
                </Space>
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.visit_type")}
                  </Text>
                  <MultiSelect
                    id="visit_schedule_type"
                    onChange={(values, options) => handleInputMultiSelect(values, options, "visitTypes")}
                    value={filtersInputs.visitTypes}
                    autoClearSearchValue={false}
                    showSearch={true}
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={filtersInputs.visitTypes ? 8 : 16}
                    allowClear={true}
                    style={{ width: "100%" }}
                    placeholder={`${t("all")}`}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    bordered={false}
                  >
                    {visitTypes &&
                      !!visitTypes.length &&
                      visitTypes.map((visitType, index) => (
                        <Option key={index} value={visitType.adminType}>
                          {visitType.adminType}
                        </Option>
                      ))}
                  </MultiSelect>
                </Space>
              </Space>
            </Panel>
          )}
          <Panel
            header={
              <Title level={5} style={{ color: NEW_BLUE }}>
                {t("tableFilters.insurance")}
                {isInsuranceActive() && (
                  <div
                    style={{
                      position: "absolute",
                      background: GREEN,
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      left: "240px",
                      top: "24px"
                    }}
                  />
                )}
              </Title>
            }
          >
            <Space style={{ width: "100%" }} direction="vertical">
              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Text strong style={{ color: LIGHT_BLUE }}>
                  {t("tableFilters.plan_market")}
                </Text>
                <MultiSelect
                  id="insurance_filter_type"
                  onChange={(values, options) => handleInputMultiSelect(values, options, "planMarketTypes")}
                  value={filtersInputs.planMarketTypes}
                  mode="multiple"
                  allowClear={true}
                  style={{ width: "100%" }}
                  placeholder={t("all")}
                  bordered={false}
                  maxTagCount={1}
                  maxTagTextLength={filtersInputs.planMarketTypes.length > 1 ? 8 : 16}
                >
                  {tableFilterValues.planMarketTypes.map((option) => {
                    const id = `insurance_filter_type_${option.translationText}`;
                    return (
                      <Option id={id} key={option.key} value={option.value}>
                        {t(option.translationText)}
                      </Option>
                    );
                  })}
                </MultiSelect>
              </Space>
              <Space align="baseline">
                <Space id="insurance_filter_selected_insurances_only">
                  <Checkbox
                    style={{ marginLeft: "0px" }}
                    checked={filters.insuranceCombination}
                    onChange={(e) => handleCheckbox(e, "insuranceCombination")}
                  >
                    <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                      <Text strong style={{ color: LIGHT_BLUE, whiteSpace: "nowrap", fontSize: "13px" }}>
                        {t("only_selected_insurances")}
                      </Text>
                    </div>
                  </Checkbox>
                </Space>

                <BaseToolTip title={t("only_selected_insurances_tooltip")} />
              </Space>
              {!isAllApplicationsActive() && (
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.created_date")}
                  </Text>
                  <DateRangeFilter
                    id="insurance_filter_created_date"
                    handleInput={(value = ALL_TIMES) => setFiltersInputs("coverageChangeDate", value)}
                    onSelect={(fromDate, toDate, value = null) =>
                      handleInputDate(fromDate, toDate, "coverageChangeDate", value)
                    }
                    allowClear={true}
                    onClear={() => {
                      resetDatesToDefault("coverageChangeDate", ALL_TIMES);
                    }}
                    defaultValue={ALL_TIMES}
                    placeHolder={t(ALL_TIMES)}
                    dateFilters="pastFilters"
                    timeRange={coverageChangeDate}
                  />
                </Space>
              )}
              {!isAllApplicationsActive() && (
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.min_remaining_oop")}
                  </Text>
                  <Form form={form}>
                    <Form.Item
                      initialValue={numbers.remainingOOP ? numbers.remainingOOP : 0}
                      name="remainingOOP"
                      rules={[{ required: false }]}
                    >
                      <InputNumber
                        id="insurance_filter_min_remaining_oop"
                        value={numbers.remainingOOP}
                        onChange={debounce(
                          (value) => handleInputNumber(value && value != "0" ? value : 0, "remainingOOP"),
                          500
                        )}
                        controls={false}
                        addonafter="$"
                        defaultValue="0"
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Form>
                </Space>
              )}
            </Space>
          </Panel>
          {![selectedViews.allApplications, selectedViews.pendingRenewal, selectedViews.allClaims].includes(
            selectedView
          ) && (
            <Panel
              header={
                <Title level={5} style={{ color: NEW_BLUE }}>
                  {t("tableFilters.drug")}
                  {isDrugFiltersActive() ? (
                    <div
                      style={{
                        position: "absolute",
                        background: GREEN,
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        left: "240px",
                        top: "24px"
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Title>
              }
            >
              <Space style={{ width: "100%" }} direction="vertical">
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.name")}
                  </Text>
                  <MultiSelectDrugSearch
                    id="drug_filter_name"
                    searchRxnormDrugs={searchRxnormDrugs}
                    mode="multiple"
                    placeholder={t("all")}
                    onChangeSelectedDrugs={(selectedDrugs) => handleSelectedDrugsChange(selectedDrugs)}
                    onClear={() => handleSelectedDrugsChange([])}
                    value={filtersInputs.rxnormDrugs}
                    bordered={false}
                    displayNameMaxChars={25}
                    style={{
                      width: "100%",
                      border: `0px`,
                      borderBottom: `1px solid ${GRAY5}`,
                      padding: 0
                    }}
                  />
                </Space>
                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.route")}
                  </Text>
                  <MultiSelect
                    id="drug_filter_route"
                    onChange={(values, options) => handleInputMultiSelect(values, options, "drugRoutes")}
                    value={filtersInputs.drugRoutes}
                    mode="multiple"
                    allowClear={true}
                    style={{ width: "100%" }}
                    placeholder={t("all")}
                    bordered={false}
                    maxTagCount={1}
                    maxTagTextLength={filtersInputs.drugRoutes.length > 1 ? 8 : 16}
                  >
                    {tableFilterValues.drugRoute.map((option) => {
                      const id = `drug_filter_route_${option.translationText}`;
                      return (
                        <Option id={id} key={option.key} value={option.value}>
                          {t(option.translationText)}
                        </Option>
                      );
                    })}
                  </MultiSelect>
                </Space>

                <Space size={0} style={{ width: "100%" }} direction="vertical">
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.started_date")}
                  </Text>
                  <DateRangeFilter
                    id="drug_filter_created_date"
                    handleInput={(value = ALL_TIMES) => setFiltersInputs("medicationCreatedDate", value)}
                    onSelect={(fromDate, toDate, value = null) =>
                      handleInputDate(fromDate, toDate, "medicationCreatedDate", value)
                    }
                    allowClear={true}
                    onClear={() => {
                      resetDatesToDefault("medicationCreatedDate", ALL_TIMES);
                    }}
                    defaultValue={ALL_TIMES}
                    placeHolder={t(ALL_TIMES)}
                    dateFilters="medicationCreatedDateFilters"
                    timeRange={medicationCreatedDate}
                    withRangePicker={true}
                  />
                </Space>
              </Space>
            </Panel>
          )}
          <Panel
            header={
              <Title level={5} style={{ color: NEW_BLUE }} id="diagnosis_filter">
                {t("tableFilters.diagnosis")}
                {isDiagnosisFiltersActive() ? (
                  <div
                    style={{
                      position: "absolute",
                      background: GREEN,
                      width: "8px",
                      height: "8px",
                      borderRadius: "50%",
                      left: "240px",
                      top: "24px"
                    }}
                  />
                ) : (
                  <></>
                )}
              </Title>
            }
          >
            <Space style={{ width: "100%" }} direction="vertical">
              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Space
                  direction="horizontal"
                  style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
                >
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.name")}
                  </Text>
                  {!!diagnosisSearchResults.length && (
                    <Text style={{ color: LIGHT_BLUE }}>{diagnosisSearchResults.length} Results</Text>
                  )}
                </Space>

                <MultiSelect
                  onChange={(values, options) => handleInputMultiSelect(values, options, "diagnosisNames")}
                  value={filtersInputs.diagnosisNames}
                  id="diagnosis_filter_name"
                  placeholder={t("all")}
                  allowClear={true}
                  showSearch={true}
                  loading={isFetchingDiagnosis}
                  onSearch={debounce(onDiagnosisSearch, 500)}
                  filterOption={false}
                  filterSort={(a, b) => stringSort(a.value, b.value)}
                  suffixIcon={
                    isFetchingDiagnosis ? <Spin size={"small"} indicator={<LoadingOutlined spin />} /> : <></>
                  }
                  notFoundContent={t("not_found")}
                  maxTagCount="responsive"
                  clearAll={diagnosisSearchResults.length ? true : false}
                  selectAll={diagnosisSearchResults.length ? true : false}
                  onSelectAll={() => {
                    const values = diagnosisSearchResults.map(({ value }) => value);
                    const options = diagnosisSearchResults.map(({ value, text }, index) => ({
                      value: value,
                      key: index,
                      children: text
                    }));
                    handleInputMultiSelect(values, options, "diagnosisNames");
                  }}
                  onClearAll={() => {
                    handleInputMultiSelect([], [], "diagnosisNames");
                    setDiagnosisSearchResults([]);
                  }}
                  onDropdownVisibleChange={(visible) => !visible && setDiagnosisSearchResults([])}
                >
                  {diagnosisSearchResults.map((d, index) => (
                    <Option key={index} value={d.value}>
                      <Text style={{ whiteSpace: "pre-line" }}>{d.text}</Text>
                    </Option>
                  ))}
                </MultiSelect>
              </Space>

              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Space
                  direction="horizontal"
                  style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
                >
                  <Text strong style={{ color: LIGHT_BLUE }}>
                    {t("tableFilters.code")}
                  </Text>
                  {!!diagnosisIcdsSearchResults.length && (
                    <Text style={{ color: LIGHT_BLUE }}>{diagnosisIcdsSearchResults.length} Results </Text>
                  )}
                </Space>
                <MultiSelect
                  id="diagnosis_filter_icd_code"
                  onChange={(values, options) => handleInputMultiSelect(values, options, "diagnosisCodeIDs")}
                  value={filtersInputs.diagnosisCodeIDs}
                  placeholder={t("all")}
                  allowClear={true}
                  showSearch={true}
                  loading={isFetchingDiagnosisIcds}
                  onSearch={debounce(onDiagnosisIcdsSearch, 500)}
                  filterOption={false}
                  filterSort={(a, b) => stringSort(a.value, b.value)}
                  suffixIcon={
                    isFetchingDiagnosisIcds ? <Spin size={"small"} indicator={<LoadingOutlined spin />} /> : <></>
                  }
                  notFoundContent={t("not_found")}
                  maxTagCount="responsive"
                  clearAll={diagnosisIcdsSearchResults.length ? true : false}
                  selectAll={diagnosisIcdsSearchResults.length ? true : false}
                  onSelectAll={() => {
                    const values = diagnosisIcdsSearchResults.map(({ value }) => value);
                    const options = diagnosisIcdsSearchResults.map(({ value, text }, index) => ({
                      value: value,
                      key: index,
                      children: text
                    }));
                    handleInputMultiSelect(values, options, "diagnosisCodeIDs");
                  }}
                  onClearAll={() => {
                    handleInputMultiSelect([], [], "diagnosisCodeIDs");
                    setDiagnosisIcdsSearchResults([]);
                  }}
                  onDropdownVisibleChange={() => setDiagnosisIcdsSearchResults([])}
                >
                  {diagnosisIcdsSearchResults.map((d, index) => (
                    <Option key={index} value={d.value}>
                      <Text style={{ whiteSpace: "pre-line" }}>{d.text}</Text>
                    </Option>
                  ))}
                </MultiSelect>
              </Space>
              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Text strong style={{ color: LIGHT_BLUE }}>
                  {t("tableFilters.diagnosis_type")}
                </Text>
                <MultiSelect
                  id="diagnosis_filter_type"
                  onChange={(values, options) => handleInputMultiSelect(values, options, "diagnosisTypes")}
                  value={filtersInputs.diagnosisTypes}
                  mode="multiple"
                  allowClear={true}
                  style={{ width: "100%" }}
                  placeholder={t("all")}
                  bordered={false}
                  maxTagCount={1}
                  maxTagTextLength={filtersInputs.diagnosisTypes?.length > 1 ? 8 : 16}
                >
                  {tableFilterValues.diagnosisType.map((option) => {
                    const id = `diagnosis_filter_type_${option.translationText}`;
                    return (
                      <Option id={id} key={option.key} value={option.value}>
                        {t(option.translationText)}
                      </Option>
                    );
                  })}
                </MultiSelect>
              </Space>
              <Space size={0} style={{ width: "100%" }} direction="vertical">
                <Text strong style={{ color: LIGHT_BLUE }}>
                  {t("tableFilters.diagnosis_date")}
                </Text>
                <DateRangeFilter
                  id="diagnosis_filter_date"
                  handleInput={(value = ALL_TIMES) => setFiltersInputs("activeDate", value)}
                  onSelect={(fromDate, toDate, value = null) => handleInputDate(fromDate, toDate, "activeDate", value)}
                  allowClear={true}
                  onClear={() => {
                    resetDatesToDefault("activeDate", ALL_TIMES);
                  }}
                  defaultValue={ALL_TIMES}
                  placeHolder={t(ALL_TIMES)}
                  dateFilters="pastFilters"
                  timeRange={activeDate}
                />
              </Space>
            </Space>
          </Panel>
        </Collapse>
      </StyledSpace>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  assignees: selectAssigneesAndUserGroups,
  user: selectUser,
  selectedView: selectSelectedView,
  filtersModified: selectFiltersModified,
  filters: selectFilters,
  filtersInputs: selectFiltersInputs,
  facilities: selectProvidersFacility,
  visitTypes: selectVisitTypes,
  doctors: selectProvidersDoctors,
  apSponsors: selectAPSponsors
});

const mapDispatchToProps = (dispatch) => ({
  setFiltersAct: (filters) => dispatch(ACTIONS.setFiltersAction(filters)),
  setFiltersInputs: (filterName, value) => dispatch(ACTIONS.setFiltersInputs(filterName, value)),
  clearFiltersInputs: () => dispatch(ACTIONS.clearFiltersInputs())
});

const TableFilters = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TableFiltersComponent));

export default TableFilters;
