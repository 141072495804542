import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Form, Row, Col, Input, Select, InputNumber, Typography, Alert, Spin } from "antd";
import { UserOutlined, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import { searchRxnormDrugsAPI } from "../../../../api/api";
import { selectUnits } from "../../../..//store/selector";
import { MultiSelectDrugSearch } from "@tailormed/common-client/component/MultiSelectDrugSearch";
import { CustomForm } from "../../../customComponentNewDesign/CustomForm";
import DateFormItem from "../utils/components/DateFormItem";
import { isRxNormDrugIVAdministered, isRxNormDrugBranded } from "../../../../utils/drugs";
import { PhysicianSelect } from "../../../../component/Select/";
import styled from "styled-components";
import {
  ERROR_300,
  ERROR_600,
  GRAY_700,
  PRIMARY_600,
  LIGHT_BLUE_5,
  BLUE_3,
  ERROR_50
} from "../../../../constant/colors";
import { medicationFieldNames, ivMedicationFields, nonIvMedicationFields } from "./fieldNames";
import { sizes } from "../../../../constant/styles";
import { BRANDED_TERM_TYPES, NOT_BRANDED_TERM_TYPES } from "../../../../constant/drugs";
import { numOfCyclesOrRefillsValidator, MAX_NUM_OF_CYCLES } from "../../../../utils/formValidation";

const { Text } = Typography;

const StyledMedicationForm = styled(CustomForm).attrs((props) => ({
  validateMessages: {
    required: ""
  },
  ...props
}))`
  .ant-picker {
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
    color: ${GRAY_700};
  }

  .ant-input,
  .ant-select-selection-item {
    color: ${GRAY_700};
  }

  && .ant-form-item.ant-form-item-has-error {
    .ant-picker,
    .ant-radio-inner,
    .ant-input,
    .ant-select.ant-select-status-error .ant-select-selector,
    .ant-input-number {
      border-color: ${ERROR_300} !important;
    }

    .ant-select.ant-select-status-error .ant-select-selector {
      border-color: ${ERROR_300} !important;
      background: ${ERROR_50} !important;
    }
  }

  .ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
    border-color: ${ERROR_300};
  }

  .ant-tooltip {
    color: ${GRAY_700} !important;
    background-color: ${PRIMARY_600} !important;
  }

  .ant-divider-inner-text {
    padding-left: 0;
  }

  .ant-form-item-explain-error {
    color: ${ERROR_600};
    font-size: ${sizes.small};
  }

  .ant-form-item-label > label {
    color: ${GRAY_700};
    font-weight: 600;
  }

  .ant-radio-wrapper {
    color: ${GRAY_700};
    font-weight: 600;
  }

  .ant-checkbox-wrapper {
    color: ${GRAY_700};
    font-weight: 600;
  }

  > .ant-row {
    align-items: flex-end;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${PRIMARY_600} !important;
    border-color: ${PRIMARY_600} !important;
  }
`;

const MedicationForm = ({
  handleSubmitEvent = null,
  handleSelectDrugEvent = null,
  drugToEdit = null,
  editedDrugOptions = [],
  isFetchingOptions,
  form,
  showFormItems = true
}) => {
  const { t } = useTranslation();
  const [selectedDrugOptions, setSelectedDrugOptions] = useState([]);
  const [doseFormsOptions, setDoseFormsOptions] = useState([]);
  const [strengthsOptions, setStrengthsOptions] = useState([]);
  const [selectedDoseForm, setSelectedDoseForm] = useState(drugToEdit ? drugToEdit.rxnormDrug.dfName : null);
  const [selectedStrength, setSelectedStrength] = useState(drugToEdit ? drugToEdit.rxnormDrug.strength : null);
  const [isFetching, setIsFetching] = useState(isFetchingOptions);

  const selectedPhysician = Form.useWatch(medicationFieldNames.physician, form);

  const matchedRxnormDrug = useRef(null);
  const isIvMedication = useRef(true);
  const isBrandedMedication = useRef(null);

  const dosageUnits = useSelector(selectUnits);

  const brandedTermTypesValues = Object.values(BRANDED_TERM_TYPES);

  useEffect(() => {
    if (editedDrugOptions.length) {
      setSelectedDrugOptions(editedDrugOptions);
      setIsFetching(false);
    }
  }, [editedDrugOptions]);

  useEffect(() => {
    if (drugToEdit) {
      const {
        dosageUnit,
        dosageValue,
        frequency,
        numOfCycles,
        numberOfRefillsAuthorized,
        quantityValue,
        route,
        startDate,
        physician,
        calculatedDaysBetweenCycle,
        quantity,
        rxnormDrug
      } = drugToEdit;

      form.setFieldsValue({
        numOfCycles,
        dosageUnit,
        dosageValue,
        frequency,
        numberOfRefillsAuthorized,
        quantityValue: isRxNormDrugIVAdministered({ tmRouteType: rxnormDrug.tmRouteType })
          ? quantityValue
          : quantityValue / parseInt(frequency || 1),
        quantity: quantity || calculatedDaysBetweenCycle,
        route,
        startDate,
        physician,
        rxnormDrug,
        strength: rxnormDrug.strength,
        dfName: rxnormDrug.dfName
      });

      matchedRxnormDrug.current = rxnormDrug;
      const { tmRouteType, tty } = matchedRxnormDrug.current;
      isIvMedication.current = isRxNormDrugIVAdministered({ tmRouteType });
      isBrandedMedication.current = isRxNormDrugBranded({ tty });
    }
  }, [drugToEdit]);

  const pickDefaultDrug = () =>
    selectedDrugOptions.find((drug) => {
      return matchedRxnormDrug.current.bnName
        ? drug.tty === BRANDED_TERM_TYPES.BN
        : drug.tty === NOT_BRANDED_TERM_TYPES.PIN;
    });

  const migrateMedicationFormByMedicationIsIVAdministered = (isNewSelectionIsIVAdministered) => {
    const newCyclesFieldName = isNewSelectionIsIVAdministered
      ? ivMedicationFields.numOfCycles
      : nonIvMedicationFields.numberOfRefillsAuthorized;

    const newQuantityFieldName = isNewSelectionIsIVAdministered
      ? ivMedicationFields.quantity
      : nonIvMedicationFields.quantityValue;

    const oldCyclesFieldName = isNewSelectionIsIVAdministered
      ? nonIvMedicationFields.numberOfRefillsAuthorized
      : ivMedicationFields.numOfCycles;

    const oldQuantityFieldName = isNewSelectionIsIVAdministered
      ? nonIvMedicationFields.quantityValue
      : ivMedicationFields.quantity;

    const cyclesValue = form.getFieldValue(oldCyclesFieldName);
    const quantityValue = form.getFieldValue(oldQuantityFieldName);

    form.setFieldsValue({
      [newCyclesFieldName]: cyclesValue,
      [oldCyclesFieldName]: null,
      [newQuantityFieldName]: quantityValue,
      [oldQuantityFieldName]: null
    });
  };

  const handleDeterminativeFieldChange = () => {
    if (!matchedRxnormDrug.current) {
      return;
    }

    let selectedDrugOption;
    const pickedTty = matchedRxnormDrug.current.tty;
    if (brandedTermTypesValues.includes(pickedTty)) {
      if (selectedDoseForm && selectedStrength) {
        selectedDrugOption = selectedDrugOptions.find(
          (drug) =>
            drug.tty === BRANDED_TERM_TYPES.SBD &&
            drug.dfName === selectedDoseForm &&
            drug.strength === selectedStrength
        );
      } else if (selectedDoseForm && !selectedStrength) {
        selectedDrugOption = selectedDrugOptions.find(
          (drug) => drug.tty === BRANDED_TERM_TYPES.SBDF && drug.dfName === selectedDoseForm
        );
      } else if (selectedStrength && !selectedDoseForm) {
        selectedDrugOption = selectedDrugOptions.find(
          (drug) => drug.tty === BRANDED_TERM_TYPES.SBDC && drug.strength === selectedStrength
        );
      }
    } else {
      if (selectedDoseForm && selectedStrength) {
        selectedDrugOption = selectedDrugOptions.find(
          (drug) =>
            drug.tty === NOT_BRANDED_TERM_TYPES.SCD &&
            drug.dfName === selectedDoseForm &&
            drug.strength === selectedStrength
        );
      } else if (selectedDoseForm && !selectedStrength) {
        selectedDrugOption = selectedDrugOptions.find(
          (drug) => drug.tty === NOT_BRANDED_TERM_TYPES.SCDF && drug.dfName === selectedDoseForm
        );
      } else if (selectedStrength && !selectedDoseForm) {
        selectedDrugOption = selectedDrugOptions.find(
          (drug) => drug.tty === NOT_BRANDED_TERM_TYPES.SCDC && drug.strength === selectedStrength
        );
      }
    }

    if (selectedDrugOption) {
      const { tmRouteType: newSelectionTmRouteType } = selectedDrugOption;
      const isNewSelectionIsIVAdministered = isRxNormDrugIVAdministered({ tmRouteType: newSelectionTmRouteType });

      if (isNewSelectionIsIVAdministered !== isIvMedication.current) {
        migrateMedicationFormByMedicationIsIVAdministered(isNewSelectionIsIVAdministered);
      }
    }

    matchedRxnormDrug.current = selectedDrugOption || pickDefaultDrug();
    form.setFieldsValue({ rxnormDrug: matchedRxnormDrug.current });
    const { tmRouteType, tty } = matchedRxnormDrug.current;
    isIvMedication.current = isRxNormDrugIVAdministered({ tmRouteType });
    isBrandedMedication.current = isRxNormDrugBranded({ tty });
    if (handleSelectDrugEvent && typeof handleSelectDrugEvent === "function") {
      handleSelectDrugEvent(matchedRxnormDrug.current);
    }
  };

  const filterSelectedDrugOptions = (drugOptions) => {
    if (matchedRxnormDrug.current.tty === BRANDED_TERM_TYPES.BN) {
      return drugOptions.filter(({ bnName }) => matchedRxnormDrug.current.bnName === bnName);
    } else {
      return drugOptions.filter(({ pinName }) => matchedRxnormDrug.current.pinName === pinName);
    }
  };

  useEffect(() => {
    const _filterByTermType = (tty, isBranded) => {
      return isBranded ? brandedTermTypesValues.includes(tty) : !brandedTermTypesValues.includes(tty);
    };

    const _getUniqueValues = (options, property) => {
      return _.uniq(options.filter((drug) => drug[property]).map((drug) => drug[property]));
    };

    const filteredOptions = selectedDrugOptions.filter(({ tty }) =>
      _filterByTermType(tty, isBrandedMedication.current)
    );

    setDoseFormsOptions(_getUniqueValues(filteredOptions, "dfName"));
    setStrengthsOptions(_getUniqueValues(filteredOptions, "strength"));
  }, [selectedDrugOptions, isBrandedMedication.current]);

  useEffect(() => {
    if (selectedDrugOptions.length) {
      handleDeterminativeFieldChange();
    }

    if (selectedDoseForm) {
      setStrengthsOptions(
        _.uniq(
          selectedDrugOptions
            .filter((drug) => drug.strength && drug.dfName === selectedDoseForm)
            .map(({ strength }) => strength)
        )
      );
    } else {
      setStrengthsOptions(_.uniq(selectedDrugOptions.filter((drug) => drug.strength).map(({ strength }) => strength)));
    }
  }, [selectedDoseForm]);

  useEffect(() => {
    if (selectedDrugOptions.length) {
      handleDeterminativeFieldChange();
    }

    if (selectedStrength) {
      setDoseFormsOptions(
        _.uniq(
          selectedDrugOptions
            .filter((drug) => drug.strength && drug.strength === selectedStrength)
            .map(({ dfName }) => dfName)
        )
      );
    } else {
      setDoseFormsOptions(_.uniq(selectedDrugOptions.filter((drug) => drug.dfName).map(({ dfName }) => dfName)));
    }
  }, [selectedStrength]);

  const handleSelectDrugName = (selectedDrug, drugOptions = []) => {
    const currentSelection = selectedDrug.length ? selectedDrug[0] : null;
    matchedRxnormDrug.current = currentSelection;
    form.setFieldsValue({ rxnormDrug: currentSelection });
    if (currentSelection) {
      const { tmRouteType, tty } = matchedRxnormDrug.current;
      isIvMedication.current = isRxNormDrugIVAdministered({ tmRouteType });
      isBrandedMedication.current = isRxNormDrugBranded({ tty });
      const filteredDrugOptions = filterSelectedDrugOptions(drugOptions);
      setSelectedDrugOptions(filteredDrugOptions);
    } else {
      isIvMedication.current = true;
      form.setFieldsValue({ [medicationFieldNames.doseForm]: null });
      form.setFieldsValue({ [medicationFieldNames.strength]: null });
      setSelectedDoseForm(null);
      setSelectedStrength(null);
      setSelectedDrugOptions(drugOptions);
    }

    if (handleSelectDrugEvent && typeof handleSelectDrugEvent === "function") {
      handleSelectDrugEvent(currentSelection);
    }
  };

  const isPhysicianEdited = () => {
    const physicianValue = drugToEdit?.physician;
    return physicianValue && physicianValue !== selectedPhysician;
  };

  const validateDrugNameIsSelected = async () => {
    try {
      await form.validateFields([medicationFieldNames.rxnormDrug]);
    } catch (errorsInfo) {
      //NOTE: Intentionally empty catch block
    }
  };

  const onSubmit = ({ physician, ...values }) => {
    const medicationData = {
      ...values,
      physician: physician || ""
    };

    delete medicationData.rxnormDrug;
    delete medicationData.dfName;
    delete medicationData.strength;

    handleSubmitEvent({ rxnormDrug: matchedRxnormDrug.current, medicationData });
  };

  const searchRxnormDrugs = async (searchTerm) => {
    const response = await searchRxnormDrugsAPI({ text: searchTerm });
    return response?.data?.rxnormDrugs || [];
  };

  const getNumberOfCyclesFieldName = () =>
    isIvMedication.current ? ivMedicationFields.numOfCycles : nonIvMedicationFields.numberOfRefillsAuthorized;

  return (
    <StyledMedicationForm id="medication_editor" preserve={false} form={form} onFinish={onSubmit}>
      {isPhysicianEdited() && (
        <Alert
          style={{ backgroundColor: LIGHT_BLUE_5, borderRadius: 8, marginBottom: 20 }}
          message={
            <Text style={{ color: BLUE_3 }}>
              {t("managePatientDetails.medications_section.medication_modal.medication_form.physician.edit_physician")}
            </Text>
          }
          type="info"
          showIcon
          icon={<InfoCircleOutlined style={{ marginBottom: 20 }} />}
        />
      )}
      <Row>
        <Col span={24}>
          <Form.Item
            rules={[{ required: true }]}
            name={medicationFieldNames.rxnormDrug}
            label={t("managePatientDetails.medications_section.medication_modal.medication_form.drug_name.label")}
          >
            <MultiSelectDrugSearch
              searchRxnormDrugs={searchRxnormDrugs}
              placeholder={t(
                "managePatientDetails.medications_section.medication_modal.medication_form.drug_name.search_placeholder"
              )}
              onChangeSelectedDrugs={(selectedDrug, drugOptions) => handleSelectDrugName(selectedDrug, drugOptions)}
              onClear={() => handleSelectDrugName([])}
              disabled={!!drugToEdit}
            />
          </Form.Item>
        </Col>
      </Row>
      {showFormItems && (
        <>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name={medicationFieldNames.doseForm}
                label={t("managePatientDetails.medications_section.medication_modal.medication_form.dose_form.label")}
              >
                <Select
                  allowClear
                  placeholder={t(
                    "managePatientDetails.medications_section.medication_modal.medication_form.dose_form.placeholder"
                  )}
                  options={doseFormsOptions.map((df, index) => ({
                    label: df,
                    value: df,
                    key: index
                  }))}
                  onFocus={validateDrugNameIsSelected}
                  onChange={(selectedDoseForm) => setSelectedDoseForm(selectedDoseForm)}
                  notFoundContent={
                    isFetching ? <Spin size={"small"} indicator={<LoadingOutlined spin />} /> : t("not_found")
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={medicationFieldNames.strength}
                label={t("managePatientDetails.medications_section.medication_modal.medication_form.strength.label")}
              >
                <Select
                  allowClear
                  placeholder={t(
                    "managePatientDetails.medications_section.medication_modal.medication_form.strength.placeholder"
                  )}
                  options={strengthsOptions.map((st, index) => ({
                    label: st,
                    value: st,
                    key: index
                  }))}
                  onFocus={validateDrugNameIsSelected}
                  onChange={(selectedStrength) => setSelectedStrength(selectedStrength)}
                  notFoundContent={
                    isFetching ? <Spin size={"small"} indicator={<LoadingOutlined spin />} /> : t("not_found")
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name={medicationFieldNames.dosageValue}
                label={t("managePatientDetails.medications_section.medication_modal.medication_form.dosage.label")}
                rules={[
                  {
                    pattern: /^\d+(?:\.\d+)?(?:-\d+(?:\.\d+)?)*$/,
                    message: t(
                      "managePatientDetails.medications_section.medication_modal.medication_form.dosage.validation_error"
                    )
                  }
                ]}
              >
                <Input
                  onFocus={validateDrugNameIsSelected}
                  placeholder={t(
                    "managePatientDetails.medications_section.medication_modal.medication_form.dosage.placeholder"
                  )}
                  style={{ borderRadius: "4px" }}
                  addonAfter={
                    <Form.Item name={medicationFieldNames.dosageUnit} noStyle={true}>
                      <Select
                        options={dosageUnits.map((unit, index) => ({
                          label: unit,
                          value: unit,
                          key: index
                        }))}
                        style={{ width: "70px" }}
                      />
                    </Form.Item>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={getNumberOfCyclesFieldName()}
                rules={[{ message: `Maximum ${MAX_NUM_OF_CYCLES}`, validator: numOfCyclesOrRefillsValidator }]}
                label={t(
                  "managePatientDetails.medications_section.medication_modal.medication_form.num_of_cycles.label"
                )}
              >
                <InputNumber
                  controls={false}
                  min={0}
                  addonBefore={t(
                    "managePatientDetails.medications_section.medication_modal.medication_form.num_of_cycles.addon_before"
                  )}
                  placeholder={t(
                    "managePatientDetails.medications_section.medication_modal.medication_form.num_of_cycles.placeholder"
                  )}
                  onFocus={validateDrugNameIsSelected}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                name={isIvMedication.current ? ivMedicationFields.quantity : nonIvMedicationFields.quantityValue}
                label={t("managePatientDetails.medications_section.medication_modal.medication_form.days_supply.label")}
                dependencies={[ivMedicationFields.numOfCycles, nonIvMedicationFields.numberOfRefillsAuthorized]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const numOfCyclesFieldName = getNumberOfCyclesFieldName();
                      const numOfCycles = getFieldValue(numOfCyclesFieldName);
                      if (!numOfCycles || value > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t(
                            "managePatientDetails.medications_section.medication_modal.medication_form.days_supply.required_message"
                          )
                        )
                      );
                    }
                  })
                ]}
              >
                <InputNumber
                  controls={false}
                  min={0}
                  placeholder={t(
                    "managePatientDetails.medications_section.medication_modal.medication_form.num_of_cycles.placeholder"
                  )}
                  onFocus={validateDrugNameIsSelected}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={nonIvMedicationFields.frequency}
                label={t(
                  "managePatientDetails.medications_section.medication_modal.medication_form.units_per_day.label"
                )}
              >
                <InputNumber
                  disabled={matchedRxnormDrug.current && isIvMedication.current}
                  controls={false}
                  min={0}
                  placeholder={t(
                    "managePatientDetails.medications_section.medication_modal.medication_form.units_per_day.placeholder"
                  )}
                  onFocus={validateDrugNameIsSelected}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <DateFormItem
                label={t("managePatientDetails.medications_section.medication_modal.medication_form.start_date.label")}
                name={medicationFieldNames.startDate}
                onFocus={validateDrugNameIsSelected}
                dependencies={[ivMedicationFields.numOfCycles, nonIvMedicationFields.numberOfRefillsAuthorized]}
                rules={[
                  {
                    validator(_, value) {
                      const numOfCyclesFieldName = getNumberOfCyclesFieldName();
                      const numOfCycles = form.getFieldValue(numOfCyclesFieldName);
                      if (!numOfCycles || value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t(
                            "managePatientDetails.medications_section.medication_modal.medication_form.start_date.required_message"
                          )
                        )
                      );
                    }
                  }
                ]}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name={medicationFieldNames.physician}
                label={t("managePatientDetails.medications_section.medication_modal.medication_form.physician.label")}
              >
                <PhysicianSelect
                  placeholder={
                    <Text>
                      <UserOutlined />
                      {t(
                        "managePatientDetails.medications_section.medication_modal.medication_form.physician.choose_physician"
                      )}
                    </Text>
                  }
                  allowClear={false}
                  virtual={false}
                  onFocus={validateDrugNameIsSelected}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </StyledMedicationForm>
  );
};

export default MedicationForm;
