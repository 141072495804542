import React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

export const PatientConfirmationAlertContext = React.createContext({
  showCancelConfirm: () => {}
});

export const PatientConfirmationAlertContextProvider = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const showCancelConfirm = (dest) => {
    confirm({
      centered: true,
      title: t("new_patient.cancel_modal_title"),
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ margin: "12px 0 24px -38px" }}>{t("new_patient.cancel_modal_message")}</p>,
      okText: t("new_patient.leave_anyway"),
      onOk() {
        history.push(dest);
      },
      cancelText: t("new_patient.stay_in_form")
    });
  };
  return <PatientConfirmationAlertContext.Provider {...props} value={{ showCancelConfirm }} />;
};
