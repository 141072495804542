import { noop } from "lodash";
import React, { useState } from "react";

import { CREATE_NEW_PATIENT_STEPS } from "../../constant/patient";
import DemographicStep from "./DemographicStep";
import FindAssistanceProgramsStep from "./FindAssistanceProgramsStep";
import WhatNextStep from "./WhatNextStep";

const NewPatientForm = ({ closePopover = noop, setDiscardChangesPopupVisibility = noop, defineDiscardFunc = noop }) => {
  const [step, setStep] = useState(CREATE_NEW_PATIENT_STEPS.demographics);
  const [patientData, setPatientData] = useState();

  const changeStep = (stepName) => {
    setStep(stepName);
  };

  const updatePatientData = (patient) => {
    setPatientData(patient);
  };

  const renderStep = (step) => {
    switch (step) {
      case CREATE_NEW_PATIENT_STEPS.demographics:
        return (
          <DemographicStep
            changeStep={changeStep}
            closePopover={closePopover}
            setDiscardChangesPopupVisibility={setDiscardChangesPopupVisibility}
            updatePatientData={updatePatientData}
          />
        );
      case CREATE_NEW_PATIENT_STEPS.whatNext:
        return <WhatNextStep changeStep={changeStep} closePopover={closePopover} patient={patientData} />;
      case CREATE_NEW_PATIENT_STEPS.findAssistancePrograms:
        return (
          <FindAssistanceProgramsStep
            changeStep={changeStep}
            patient={patientData}
            closePopover={closePopover}
            setDiscardChangesPopupVisibility={setDiscardChangesPopupVisibility}
            defineDiscardFunc={defineDiscardFunc}
          />
        );
    }
  };

  return renderStep(step);
};

export default NewPatientForm;
