import { ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME } from "../../../constants";

const patientFields = {
  id: ["patient", "id"],
  gender: ["patient", "gender"],
  alternativeContact: ["patient", "hasRelatedPerson"],
  relationToPatient: ["patient", "relatedPerson", "relationship"],
  relatedFirstName: ["patient", "relatedPerson", "firstName"],
  relatedLastName: ["patient", "relatedPerson", "lastName"],
  relatedPhoneNumber: ["patient", "relatedPerson", "phone"],
  relatedPhoneNumberType: ["patient", "relatedPerson", "phoneType"],
  relatedAdditionalPhoneNumber: ["patient", "relatedPerson", "additionalPhone"],
  relatedAdditionalPhoneNumberType: ["patient", "relatedPerson", "additionalPhoneType"],
  relatedDoB: ["patient", "relatedPerson", "dob"],
  relatedSignedPersontAuth: ["patient", "relatedPerson", "signedPersonAuthorization"],
  primaryLanguage: ["patient", "primaryLanguage"]
};

const insuranceFields = {
  primarySubscriberId: ["insurance", "primary", "subscriberId"],
  primaryGroupId: ["insurance", "primary", "groupId"],
  primaryRelationToPatient: ["insurance", "primary", "relationToPatient"],
  primarySubscriberFirstName: ["insurance", "primary", "subscriberFirstName"],
  primarySubscriberLastName: ["insurance", "primary", "subscriberLastName"],
  primarySubscriberDOB: ["insurance", "primary", "subscriberDob"],
  primaryPayerPhone: ["insurance", "primary", "payerPhone"],
  primaryPayerPhoneType: ["insurance", "primary", "payerPhoneType"],
  secondarySubscriberId: ["insurance", "secondary", "subscriberId"],
  secondaryGroupId: ["insurance", "secondary", "groupId"],
  secondaryRelationToPatient: ["insurance", "secondary", "relationToPatient"],
  secondarySubscriberFirstName: ["insurance", "secondary", "subscriberFirstName"],
  secondarySubscriberLastName: ["insurance", "secondary", "subscriberLastName"],
  secondarySubscriberDOB: ["insurance", "secondary", "subscriberDob"],
  secondaryPayerPhone: ["insurance", "secondary", "payerPhone"],
  secondaryPayerPhoneType: ["insurance", "secondary", "payerPhoneType"]
};

const questionnaireTreatmentSiteFields = {
  facilityId: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "id"],
  treatmentSiteName: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "name"],
  treatmentAddress: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "address"],
  treatmentSiteCity: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "city"],
  treatmentSiteZip: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "zip"],
  treatmentSiteState: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "state"],
  treatmentSiteNPI: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "npi"],
  treatmentSiteTaxId: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "taxId"],
  treatmentSiteContactName: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "contactName"],
  treatmentSiteContactPhone: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "contactPhone"],
  treatmentSiteContactFax: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "contactFax"],
  treatmentSiteContactEmail: [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "siteOfTreatmentOrganization", "contactEmail"]
};

const prescriptionFields = {
  product: ["prescription", "product"],
  prescriptionType: ["prescription", "type"],
  prescriptionOption: ["prescription", "option"],
  dosage: ["prescription", "dosageAmount"],
  dosageUnit: ["prescription", "dosageUnit"],
  supplyDuration: ["prescription", "expectedSupplyDurationUnit"],
  supplyDurationValue: ["prescription", "expectedSupplyDurationValue"],
  frequency: ["prescription", "frequency"],
  sig: ["prescription", "sig"],
  dispenseQuantity: ["prescription", "dispenseQuantity"],
  dispenseUnit: ["prescription", "dispenseUnit"],
  refills: ["prescription", "numberOfRefills"]
};

const medicalFields = {
  0: ["medical", "primaryICD"],
  1: ["medical", "secondaryICD"],
  2: ["medical", "tertiaryICD"],
  3: ["medical", "quaternaryICD"]
};

const patientConsentFields = {
  patientResourcesConsent: ["consent", "patientResourcesConsent"],
  TCPAConsent: ["consent", "TCPAConsent"],
  personalInfoConsent: ["consent", "personalInfoConsent"],
  personalInfoConsentSignerRelationship: ["consent", "personalInfoConsentSignerRelationship"],
  personalInfoConsentSignerFirstName: ["consent", "personalInfoConsentSignerFirstName"],
  personalInfoConsentSignerLastName: ["consent", "personalInfoConsentSignerLastName"],
  personalInfoConsentSignerEmail: ["consent", "personalInfoConsentSignerEmail"]
};

const practiceFields = {
  facilityId: ["practice", "facilityId"],
  facilityName: ["practice", "name"],
  address: ["practice", "street"],
  city: ["practice", "city"],
  state: ["practice", "state"],
  zip: ["practice", "zip"],
  facilityNpi: ["practice", "npi"],
  facilityTaxId: ["practice", "groupTaxId"],
  contactFirstName: ["practice", "contactFirstName"],
  contactLastName: ["practice", "contactLastName"],
  contactPhone: ["practice", "contactPhone"],
  contactFax: ["practice", "contactFax"]
};

const submitAttestationFields = {
  hipaaAuthConsent: ["submitAttestation", "hipaaAuthConsent"]
};

export {
  patientFields,
  insuranceFields,
  prescriptionFields,
  medicalFields,
  patientConsentFields,
  practiceFields,
  submitAttestationFields,
  questionnaireTreatmentSiteFields
};
