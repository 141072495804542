import { actions as CommonActions } from "@tailormed/common-client/store/action";

export const trackPendoEvent = (dispatch, eventName, eventData) => {
  const { sendPendoEvent } = CommonActions;
  try {
    dispatch(sendPendoEvent(eventName, eventData));
  } catch (errorText) {
    console.error("Error dispatching Pendo event(error from pendoUtils):", errorText);
  }
};

export default { trackPendoEvent };
