import React from "react";
import { Form } from "antd";

const FormItem = ({ children, ...otherProps }) => (
  <Form.Item validateFirst={true} {...otherProps}>
    {children}
  </Form.Item>
);

export default FormItem;
