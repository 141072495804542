import { GRAY9, DARK_GRAY2, GRAY8, WHITE, GRAY10 } from "../../constant/colors";

export const PaginationStyle = `
.ant-pagination{
    li{
      margin: 0;
    }
    .ant-pagination-total-text{
      flex: auto;
    }
    .ant-pagination-prev{
      .ant-btn{
        border-right: 0;
        border-radius: 0;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 40px;
        line-height: 32px;
        :hover{
          border: 1px solid ${DARK_GRAY2};
          border-right: 0;
          background: ${GRAY9};        
        }
        svg{
          vertical-align: sub;
          width: 12px;     
        }
      }
    }
    .ant-pagination-next{
      .ant-btn{
        border-radius: 0;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        height: 40px;
        line-height: 32px;
        :hover{
          color: ${GRAY8};
          border: 1px solid ${DARK_GRAY2};
          background: ${GRAY9};        
        }
        svg{
          vertical-align: sub;
          margin-left: 5px;
          width: 12px;
        }
      }      
    }
    .ant-pagination-item{
      border-radius: 0;
      border-right:0;
      height: 40px;
      width: 40px;
      line-height: 40px;
      border-color: ${DARK_GRAY2};
      a{
        color: ${GRAY8};
      }
    }
    .ant-pagination-jump-next, .ant-pagination-jump-prev{
      border: 1px solid ${DARK_GRAY2};
      height: 40px;
      line-height: 40px;
      border-right: 0;
      width: 40px;
      border-radius: 0;
    }
    .ant-pagination-disabled{
      .ant-btn{
        color: ${GRAY10};
        background: ${WHITE};
        :hover{
          background: ${WHITE};
        }
        svg{
          path{
            stroke: ${GRAY10};
          }
        }
      }
    }
    .ant-pagination-disabled:hover{
      .ant-btn{
        background: ${WHITE};
        color: ${GRAY10};
        svg{
          path{
            stroke: ${GRAY10};
          }
        }
      }
    }
    .ant-pagination-item:hover{
      background: ${GRAY9};
      a{
        color: ${GRAY8};
      }
    }
    .ant-pagination-item-active{
      background: ${GRAY9};
      a{
        color: ${GRAY8};
      }
    }
  }
  `;
