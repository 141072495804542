export const optionsSearch = (input, option) =>
  (option.props.children &&
    typeof option.props.children.toLowerCase === "function" &&
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
  (option.props.value &&
    typeof option.props.value.toLowerCase === "function" &&
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0);

export const buildQueryParamsSearchStrings = (filters) => {
  let queryParts = [];

  const encodeURIQueryParam = (key, value, condition) =>
    `${encodeURIComponent(key)}${condition}${encodeURIComponent(value)}`;

  Object.keys(filters).forEach((key) => {
    const filterValue = filters[key];

    if (Array.isArray(filterValue)) {
      filterValue.forEach((item) => {
        // Check if the item is an object
        const isObject = typeof item === "object" && !Array.isArray(item) && item !== null;
        if (isObject) {
          // Stringify and encode each object in the array
          queryParts.push(encodeURIQueryParam(key, JSON.stringify(item), "[]="));
        } else {
          // Handle non-object items in the array
          queryParts.push(encodeURIQueryParam(key, item, "[]="));
        }
      });
    } else if (filterValue !== null && typeof filterValue === "object") {
      // Stringify and encode objects
      queryParts.push(encodeURIQueryParam(key, JSON.stringify(filterValue), "="));
    } else {
      // Handle non-object, non-array values
      queryParts.push(encodeURIQueryParam(key, filterValue, "="));
    }
  });

  queryParts = queryParts.length > 0 ? queryParts.join("&") : "";
  return queryParts;
};
