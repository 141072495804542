import React, { useState, useCallback, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";
import { selectIDPConfig } from "../store/selector";

import { Redirect } from "react-router-dom";
import OktaSignInWidget from "../component/OktaSignInWidget";
import { withOktaAuth } from "@okta/okta-react";
import { Row, Col, Typography } from "antd";
import { withTranslation } from "react-i18next";
import { cancelSleepCheck } from "@tailormed/common-client/services/auth";

import { Routes } from "../constant/routes";
import { GREEN } from "../constant/colors";
import LoginLogoIcon from "../assets/svg/LoginLogoIcon";
import ErrorMessage from "../component/customComponent/customMessages/ErrorMessage";
import { getLoginImagesAPI, initializePendoAnonymous } from "../api/api";
import backgroundLogo from "../assets/client_logo.png";
import "./Login.css";

const { Title } = Typography;

const LoginComponent = ({ authState, oktaAuth, t, idpConfig }) => {
  const [images, setImages] = useState({});
  const [isDefaultBackground, setIsDefaultBackground] = useState(false);

  const getLoginImage = useCallback(async () => {
    try {
      const result = await getLoginImagesAPI();
      if (result.data.success) {
        setImages(result.data.images);
        if (!result.data.images.clientLoginBackground) {
          setIsDefaultBackground(true);
        }
      } else {
        setIsDefaultBackground(true);
      }
    } catch (error) {
      setIsDefaultBackground(true);
    }
  }, []);

  useEffect(() => {
    getLoginImage();
  }, [getLoginImage]);
  const initializePendoForAnonymous = useCallback(async () => {
    try {
      const response = await initializePendoAnonymous();

      const { jwt, signingKey } = response.data;

      if (jwt && signingKey) {
        window.pendo.initialize({
          jwt,
          signingKeyName: signingKey
        });
      } else {
        console.error(
          "Failed to initialize Pendo. jwt or signingKey was not provided in the initializePendoForAnonymous response."
        );
      }
    } catch (error) {
      const errors = [error].concat(error.response?.data?.errors || []).map((error) => error.message);
      console.error(`Failed to initialize Pendo.`, errors);
    }
  }, []);

  useEffect(() => {
    // Clear any lingering local storage items
    // related to the handleReturnFromSleep check.
    // Leftover items can lead to unexpected logouts.
    cancelSleepCheck();
    if (window.location.hostname !== "localhost") {
      initializePendoForAnonymous();
    }
  }, []);

  const onSuccess = (res) => {
    if (res.status === "SUCCESS") {
      return oktaAuth.signInWithRedirect();
    }
  };

  const onError = (err) => {
    ErrorMessage(`Error logging in ${err}`);
  };

  return authState?.isAuthenticated ? (
    <Redirect to={{ pathname: Routes.ROOT }} />
  ) : (
    <Row>
      <Col span={6} style={{ display: "flex", flexDirection: "column", padding: 28 }}>
        <div style={{ display: "flex", flexDirection: "row", paddingLeft: 30 }}>
          <LoginLogoIcon />
        </div>
        <Title style={{ color: GREEN, paddingTop: 120, paddingLeft: 30, fontSize: "3.5em" }}>{t("welcome")}</Title>
        {!!idpConfig && (
          <OktaSignInWidget oktaAuth={oktaAuth} idpConfig={idpConfig} onSuccess={onSuccess} onError={onError} />
        )}
      </Col>
      {isDefaultBackground ? (
        <Col span={18} style={{ backgroundColor: GREEN }}>
          <div
            style={{
              display: "flex",
              backgroundImage: `url("https://private-cdn.tailormed.co/frontend/v1.107.3-375087851/images/frontend_client_login_bg.jpg")`,
              justifyContent: "center",
              alignItems: "center",
              backgroundPosition: "center",
              height: "100vh",
              paddingTop: 50
            }}
          />
          {images.clientLogo ? (
            <img src={images.clientLogo} alt="" style={{ position: "absolute", top: "50px", right: "50px" }} />
          ) : (
            <img src={backgroundLogo} alt="TailorMed" style={{ position: "absolute", top: "50px", right: "50px" }} />
          )}
        </Col>
      ) : (
        <Col
          span={18}
          style={{
            backgroundImage: `url(${images.clientLoginBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100vh"
          }}
        >
          <div style={{ backgroundColor: GREEN, height: "100vh", width: "6px" }}></div>
          <img src={images.clientLogo} alt="" style={{ position: "absolute", top: "50px", right: "50px" }} />
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = createStructuredSelector({
  idpConfig: selectIDPConfig
});

const Login = compose(withOktaAuth, withTranslation(), connect(mapStateToProps, null))(LoginComponent);

export { Login };
