import React, { useMemo } from "react";
import _ from "lodash";
import { FilterSelect } from "./Filter";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectProvidersDoctors } from "../../store/selector";
import { makeStyleWrapper } from "../../utils/styles";
import { GRAY_600 } from "../../constant/colors";
import { fontWeights } from "../../constant/styles";
import styled from "styled-components";
import "../../locales/i18n";

import { Space, Typography } from "antd";
import { buildOneLineAddress } from "../../utils/parse";
import { filterSort } from "../../utils/sort";

const { Text } = Typography;
const { Option } = FilterSelect;

const optionWrapper = makeStyleWrapper(`
  font-weight: normal;
  .title {
    font-weight: ${fontWeights.semibold};
  }
  .subtext {
    color: ${GRAY_600};
  }
`);

// Hide the address in selection box
const StyledSelect = styled(FilterSelect)`
  .subtext {
    display: none;
  }
`;

export function PhysicianSelect({ placeholder, value, ...props }) {
  const { t } = useTranslation();
  const physicians = useSelector(selectProvidersDoctors);
  const mergedPlaceholder = placeholder || t("no_physician");

  const uniquePhysicians = _.uniqBy(physicians, (physician) =>
    [
      physician.name,
      physician.address,
      physician.suiteNumber,
      physician.city,
      physician.state,
      physician.zipCode,
      physician.npi
    ].join("-")
  );
  const options = useMemo(
    () =>
      uniquePhysicians?.map((provider) => (
        <Option
          key={provider.id}
          label={provider.name}
          value={provider.id}
          title={provider.name}
          localSortKey={provider?.name?.trim() || ""}
        >
          <Space direction="vertical" size={0}>
            <Text className="title">{provider.name}</Text>
            <Text className="subtext">
              {provider.npi ? `NPI: ${provider.npi}, ` : ""}
              {buildOneLineAddress(provider)}
            </Text>
          </Space>
        </Option>
      )),
    [physicians]
  );

  return (
    <StyledSelect
      filterSort={filterSort}
      filterOption
      optionFilterProp="label"
      dropdownMatchSelectWidth={false}
      // 400px is a balance between leaving extra space, and accomodating long addresses
      dropdownFallbackWidth={400}
      dropdownRender={optionWrapper}
      // Do a loading state until we have the physicians list, otherwise
      // it renders as just an id until then. Blech!
      placeholder={physicians ? mergedPlaceholder : t("loading")}
      value={physicians ? value : null}
      {...props}
    >
      {options}
    </StyledSelect>
  );
}
