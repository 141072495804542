import React, { useEffect } from "react";
import { Col, Divider, Form, Select, Row, Input, InputNumber, Alert, Typography } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";

import OralForm from "./Oral";
import IvForm from "./Iv";
import { GRAY_500, GRAY_900, LIGHT_BLUE_5, PRIMARY_700 } from "../../../../../../../constant/colors";
import { prescriptionFields } from "../../fieldNames";
import { fontWeights, sizes } from "../../../../../../../constant/styles";
import { getDrugByCui, drugTypes } from "./drugDefinitions";
import { selectAssistanceProgramDrugs } from "../../../../../../../store/selector";

import { FormDividerText, FormItem } from "../../../../components";
import { ObjectSelect } from "../../../../../../Select";

import { InfoCircleOutlined } from "@ant-design/icons";
import { InfoNotice } from "../../../../components/InfoNotice";
import { ESignatureToolTip } from "../../../../../PatientApplications/PatientApplicationAttachments";

const { Text } = Typography;
export const PrescriptionNotice = ({ customStyle = {}, tooltipPlacement = "bottom", fontSize }) => {
  const { t } = useTranslation();

  return (
    <InfoNotice
      text={t("application_form_editor.pfizer.prescription.information_notice")}
      fontSize={fontSize || sizes.small}
      customStyle={{ maxWidth: 700, ...customStyle }}
      additionalData={
        <ESignatureToolTip placement={tooltipPlacement}>
          <Text
            style={{
              color: PRIMARY_700,
              fontWeight: fontWeights.bold,
              paddingLeft: 2,
              fontSize: fontSize || sizes.small
            }}
          >
            {t("application_form_editor.pfizer.prescription.hover_for_details")}
          </Text>
        </ESignatureToolTip>
      }
    />
  );
};
export const PrescriptionNoticeWithoutTooltip = ({ translationText, customStyle = {}, divFontSize }) => {
  const defaultStyle = {
    backgroundColor: LIGHT_BLUE_5,
    borderRadius: 8,
    marginBottom: 20,
    marginTop: 16
  };

  return (
    <Alert
      style={{ ...defaultStyle, ...customStyle }}
      message={
        <Trans
          i18nKey={translationText}
          components={{
            bold: <strong />,
            br: <br />,
            span: <span style={{ color: PRIMARY_700, fontSize: sizes.medium }} />,
            div: <div style={{ color: PRIMARY_700, fontSize: divFontSize ? divFontSize : sizes.medium }} />
          }}
        />
      }
      type="info"
      showIcon
      icon={<InfoCircleOutlined style={{ alignSelf: "flex-start", paddingTop: 8 }} />}
    />
  );
};
const categoryFieldMap = {
  [drugTypes.ORAL]: OralForm,
  [drugTypes.IV]: IvForm
};

function DrugAmountField({ drugInfo }) {
  const { t } = useTranslation();

  switch (drugInfo?.category) {
    case drugTypes.ORAL:
      return (
        <FormItem
          rules={[{ required: true }]}
          label={t("application_form_editor.pfizer.prescription.labels.tablet_strength")}
          name={prescriptionFields.dosage}
        >
          <InputNumber
            placeholder={t("application_form_editor.pfizer.prescription.placeholders.tablet_strength")}
            style={{ width: "100%" }}
            controls={false}
            addonAfter={
              <div style={{ color: GRAY_900, fontWeight: fontWeights.regular, fontSize: sizes.medium_large }}>
                {t("application_form_editor.pfizer.prescription.mg")}
              </div>
            }
          />
        </FormItem>
      );
    case drugTypes.BIOSIMILAR:
      return (
        <FormItem
          rules={[{ required: true }]}
          label={t("application_form_editor.pfizer.prescription.labels.vial_strength")}
          name={prescriptionFields.vialStrength}
        >
          <Select
            options={drugInfo?.dosages?.map((dose, index) => ({
              label: `${dose.toLocaleString()} ${drugInfo?.unit}`,
              value: dose,
              key: index
            }))}
            placeholder={t("application_form_editor.pfizer.prescription.placeholders.vial_strength")}
          />
        </FormItem>
      );
    case drugTypes.IV:
    default:
      return (
        <FormItem
          rules={[{ required: true }]}
          label={t("application_form_editor.pfizer.prescription.labels.vial_size")}
          name={prescriptionFields.vialSize}
        >
          <Input placeholder={t("application_form_editor.pfizer.prescription.placeholders.vial_size")} />
        </FormItem>
      );
  }
}

export default function Prescription() {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const drug = Form.useWatch(prescriptionFields.drug, form);

  const rxnormDrugs = useSelector(selectAssistanceProgramDrugs);

  const drugOptions =
    _.compact(
      rxnormDrugs?.map((rxnormDrug) => {
        const drugByCui = getDrugByCui(rxnormDrug.ttyCui);
        if (_.isEmpty(drugByCui)) return;
        return {
          ...rxnormDrug,
          ...drugByCui
        };
      })
    ) || [];

  // Runs a validation for the sections that opens after changing one of the dependencies value
  useEffect(() => {
    form.validateFields();
  }, [drug]);

  const CategorySpecificFields = categoryFieldMap[drug?.category] || IvForm;

  return (
    <>
      <Divider
        id="enrollment-form-prescription-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.pfizer.prescription.labels.header")}</FormDividerText>
      </Divider>
      <PrescriptionNotice fontSize={sizes.medium} />
      <Row gutter={14}>
        <Col span={18}>
          <Form.Item
            rules={[{ required: true }]}
            label={t("application_form_editor.pfizer.prescription.labels.drug_name")}
            name={prescriptionFields.drug}
          >
            <ObjectSelect
              options={drugOptions}
              placeholder={t("application_form_editor.pfizer.prescription.placeholders.drug_name")}
              fieldNames={{
                label: "tmDisplayName",
                value: "ttyCui"
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <DrugAmountField drugInfo={drug} />
        </Col>
      </Row>
      <CategorySpecificFields />
    </>
  );
}
