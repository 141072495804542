import React from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";

import { ERROR_600 } from "../../../../constant/colors";

const { Text } = Typography;

const IneligiblePatientWarning = ({ ineligibleAnswerError }) => {
  const { t } = useTranslation();

  if (!ineligibleAnswerError) return <></>;

  return (
    <Space>
      <ExclamationCircleOutlined style={{ color: ERROR_600 }} />
      <Text style={{ color: ERROR_600 }}>{t(ineligibleAnswerError)}</Text>
    </Space>
  );
};

export default IneligiblePatientWarning;
