import { GRAY_600, PRIMARY_600, SUCCESS_600, WARNING_600, ERROR_600 } from "./colors";
import { fontWeights, sizes } from "./styles";

export const statuses = Object.freeze([
  {
    value: "pending",
    label: "Pending",
    style: {
      color: GRAY_600,
      fontWeight: fontWeights.bold,
      fontSize: sizes.small,
      marginTop: "0px",
      maxHeight: "60px",
      alignItems: "center"
    }
  },
  {
    value: "submitted",
    label: "Submitted",
    style: {
      color: PRIMARY_600,
      fontWeight: fontWeights.bold,
      fontSize: sizes.small,
      marginTop: "6px",
      alignItems: "center"
    }
  },
  {
    value: "pending-payment",
    label: "Pending Payment",
    style: {
      color: WARNING_600,
      fontWeight: fontWeights.bold,
      fontSize: sizes.small,
      marginTop: "6px",
      alignItems: "center"
    }
  },
  {
    value: "paid",
    label: "Paid",
    style: {
      color: SUCCESS_600,
      fontWeight: fontWeights.bold,
      fontSize: sizes.small,
      marginTop: "6px",
      alignItems: "center"
    }
  },
  {
    value: "denied",
    label: "Denied",
    style: {
      color: ERROR_600,
      fontWeight: fontWeights.bold,
      fontSize: sizes.small,
      marginTop: "6px",
      alignItems: "center"
    }
  },
  {
    value: "oop-max-met",
    label: "OOP Max Met",
    style: {
      color: GRAY_600,
      fontWeight: fontWeights.bold,
      fontSize: sizes.small,
      marginTop: "6px",
      alignItems: "center"
    }
  }
]);

export const payables = Object.freeze([
  { id: "claimـpayable_to_provider", value: "provider", label: "Provider" },
  { id: "claimـpayable_to_pharmacy", value: "pharmacy", label: "Pharmacy" },
  { id: "claimـpayable_to_insurance", value: "insurance", label: "Insurance" },
  { id: "claimـpayable_to_patient_guardian", value: "patient_guardian", label: "Patient/Guardian" }
]);

export const servicesTypes = Object.freeze({
  medication: { id: "claim_services_type_medication", value: "medication", label: "Medication" },
  service: { id: "claim_services_type_service", value: "service", label: "Service" },
  nonMedical: { id: "claim_services_type_non_medical", value: "non-medical", label: "Non-Medical" }
});

export const paymentTypes = Object.freeze([
  { id: "claim_payment_type_etf", value: "eft", label: "Electronic Funds Transfer (EFT)" },
  { id: "claim_payment_type_check", value: "check", label: "Check" },
  {
    id: "claim_payment_type_pharmacy_card_or_credit_card",
    value: "pharmacy_card_or_credit_card",
    label: "Virtual Pharmacy Card / Credit Card"
  }
]);

export const calimFilter = Object.freeze([
  { value: "", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "submitted", label: "Submitted" },
  { value: "pending-payment", label: "Pending Payment" },
  { value: "paid", label: "Paid" },
  { value: "denied", label: "Denied" },
  { value: "oop-max-met", label: "OOP Max Met" },
  { value: "deleted", label: "Deleted" }
]);

export const claimStatusObject = Object.freeze({
  pending: {
    key: "pending",
    value: "pending"
  },
  submitted: { key: "submitted", value: "submitted" },
  paid: { key: "paid", value: "paid" },
  denied: { key: "denied", value: "denied" },
  deleted: {
    key: "deleted",
    value: "deleted"
  },
  new_pending_payment: {
    key: "pending payment",
    value: "pending-payment"
  },
  oop: {
    key: "OOP Max Met",
    value: "oop-max-met"
  }
});

export const PENDING = claimStatusObject["pending"].value;
export const SUBMITTED = claimStatusObject["submitted"].value;
export const PAID = claimStatusObject["paid"].value;
export const DENIED = claimStatusObject["denied"].value;
export const NEW_PENDING_PAYMENT = claimStatusObject["new_pending_payment"].value;
export const OOP = claimStatusObject["oop"].value;
export const MEDICATION = servicesTypes["medication"].value;
export const PROVIDER = "provider";

export const notRequiredRules = [
  {
    required: false,
    message: ""
  }
];

export const requiredRules = [
  {
    required: true,
    message: ""
  }
];

export const duplicateCheckboxOptions = Object.freeze({
  notes: { label: "Notes", value: "Notes" },
  attachments: { label: "Attachments", value: "Attachments" }
});

export const ATTACHMENT_DUPLICATION_OPTION = duplicateCheckboxOptions.attachments.value;
export const NOTES_DUPLICATION_OPTION = duplicateCheckboxOptions.notes.value;
