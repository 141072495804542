import React from "react";
import { message, Space, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { ReactComponent as SuccessIcon } from "../../../assets/svg/success-msg-icon.svg";
import { BLACK, BLUE_3 } from "../../../constant/colors";
import "./CustomMessage.css";
import { fontWeights, sizes } from "../../../constant/styles";
import { history } from "../../../router/history";

const SuccessMessage = (msg, { closeButtonId, title, redirectTo, linkId } = {}) => {
  return (
    <>
      {message.success({
        key: "successKey",
        content: (
          <Space style={{ color: BLACK, width: "100%" }}>
            <SuccessIcon />
            <Space direction="vertical" size={0}>
              {msg}
              {redirectTo && (
                <Button
                  type="link"
                  id={linkId}
                  style={{
                    color: BLUE_3,
                    fontSize: sizes.medium,
                    fontWeight: fontWeights.medium,
                    float: "left",
                    padding: "0px"
                  }}
                  onClick={() => history.push(redirectTo)}
                >
                  {title}
                </Button>
              )}
            </Space>
            <CloseOutlined
              id={closeButtonId}
              style={{ color: BLACK, paddingLeft: "50px" }}
              onClick={() => message.destroy("errorKey")}
            />
          </Space>
        ),
        className: "success-message",
        onClick: () => message.destroy("successKey")
      })}
    </>
  );
};

export default SuccessMessage;
