import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Divider, Form, Row, Select, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { debounce } from "lodash";

import { SingleSelect } from "../../../../../customComponentNewDesign/CustomSelect";
import { searchIcdToDiagnoses } from "../../../../../../api/api";
import { useFetch } from "../../../../../../hooks/fetch";
import { diagnosisFields, prescriptionFields } from "../fieldNames";
import { fontWeights, sizes } from "../../../../../../constant/styles";
import { isEmpty } from "../../../../../../utils/string";
import { GRAY_500, PRIMARY_600 } from "../../../../../../constant/colors";

import { ReactComponent as BlueTrashIcon } from "../../../../../../assets/svg/BlueTrashIcon.svg";
import { FormDividerText, FormItem } from "../../../components";

const { Option } = SingleSelect;
const { Text } = Typography;

const StyledFormItem = styled(FormItem)`
  label {
    width: ${(props) => props.width};
  }
`;

const DiagnosisDetails = ({ type, isRequired, fieldName, onDelete }) => {
  const [searchDiagnosis, searchDiagnosisResult, searchInProgress, , resetSearchDiagnosis] =
    useFetch(searchIcdToDiagnoses);
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const handleDiagnosisSearch = (searchValue) => {
    if (isEmpty(searchValue) || searchValue.trim().length < 2) return;
    resetSearchDiagnosis();
    searchDiagnosis(searchValue);
  };

  useEffect(() => {
    const fieldValue = form?.getFieldValue(fieldName);
    handleDiagnosisSearch(fieldValue);
  }, []);
  const diagnosisSearchResults = searchDiagnosisResult ? searchDiagnosisResult.data?.ICDtoDiagnosises || [] : null;

  return (
    <>
      <Row gutter={14} wrap={false}>
        <Col span={24}>
          <StyledFormItem
            width={isRequired ? null : "100%"}
            label={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "baseline"
                }}
              >
                {t(`application_form_editor.pfizer.diagnosis.labels.${type}_diagnosis`)}
                {!isRequired && (
                  <Button
                    type="text"
                    icon={<BlueTrashIcon />}
                    onClick={() => {
                      form.setFields([{ name: fieldName, value: null }]);
                      onDelete();
                    }}
                    style={{ height: 0 }}
                  />
                )}
              </div>
            }
            name={fieldName}
            rules={[{ required: isRequired }]}
          >
            <Select
              showSearch
              allowClear
              loading={searchInProgress}
              onSearch={debounce(handleDiagnosisSearch, 300)}
              filterOption={false}
              notFoundContent={diagnosisSearchResults ? t("add_new_diagnosis.no_results_found") : t("searching")}
              placeholder={t("application_form_editor.pfizer.diagnosis.placeholders.diagnosis")}
            >
              {diagnosisSearchResults?.map(({ diagnosis, code, tmDiagnosis }, index) => (
                <Option key={index} value={code}>
                  {`${code} - ${diagnosis} (${tmDiagnosis})`}
                </Option>
              ))}
            </Select>
          </StyledFormItem>
        </Col>
      </Row>
    </>
  );
};

export default function Diagnosis() {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [isSecondaryDiagnosisVisible, setIsSecondaryDiagnosisVisible] = useState(false);

  const drug = Form.useWatch(prescriptionFields.drug, form);

  useEffect(() => {
    if (form?.getFieldValue(diagnosisFields.secondary)) {
      setIsSecondaryDiagnosisVisible(true);
    }
  }, []);

  useEffect(() => {
    form.validateFields();
  }, [drug]);

  return (
    <>
      <Divider
        id="enrollment-form-diagnosis-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.pfizer.diagnosis.labels.header")}</FormDividerText>
      </Divider>
      <div style={{ padding: "10px 0px" }}>
        <DiagnosisDetails type="primary" fieldName={diagnosisFields.primary} isRequired={true} />
        {!isSecondaryDiagnosisVisible ? (
          <Button
            type="text"
            style={{ color: PRIMARY_600, fontSize: sizes.medium, padding: 0 }}
            onClick={() => setIsSecondaryDiagnosisVisible(!isSecondaryDiagnosisVisible)}
          >
            <PlusOutlined />
            <Text style={{ fontWeight: fontWeights.semibold, color: PRIMARY_600 }}>
              {t("application_form_editor.pfizer.diagnosis.add_second_label")}
            </Text>
          </Button>
        ) : (
          <DiagnosisDetails
            type="secondary"
            fieldName={diagnosisFields.secondary}
            isRequired={false}
            onDelete={() => setIsSecondaryDiagnosisVisible(false)}
          />
        )}
      </div>
    </>
  );
}
