import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button, Typography, Input, Space, Form, Modal, Card } from "antd";
import { withTranslation } from "react-i18next";

import { selectShowSupport, selectUserId } from "../store/selector";
import ACTIONS from "../store/action";
import { BLUE_DARK, WHITE } from "../constant/colors";
import { sendSupportTicketAPI } from "../api/api";
import ErrorMessage from "./customComponent/customMessages/ErrorMessage";
import SuccessMessage from "./customComponent/customMessages/SuccessMessage";
import { ReactComponent as WarningIcon } from "../assets/svg/warning-icon-popup.svg";

const { TextArea } = Input;
const { Title, Text } = Typography;

const ReachableContext = React.createContext();

const ContactSupportComponent = ({ t, showSupport, setShowSupport, closeModal }) => {
  const [subject, setSubject] = useState("");
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [isFetching, setIsFetching] = useState(false);

  const onSend = async (values) => {
    const descriptionWithAP =
      showSupport.assistanceProgram && showSupport.assistanceProgram.name
        ? `Program name: ${showSupport.assistanceProgram.name} - ${values.description}`
        : values.description;

    let patientId = null;
    const hrefSplited = location.href.split("/");
    if (hrefSplited.length && hrefSplited[3] == "patient") {
      patientId = hrefSplited[4];
    }

    setIsFetching(true);
    try {
      const response = await sendSupportTicketAPI(values.subject, descriptionWithAP, patientId);
      if (response && response.data && response.data.savedTicketIdSF) {
        SuccessMessage(t("contact_support.ticket_send_successfully"));
        form.resetFields();
        setShowSupport(false);
      } else {
        ErrorMessage(t("contact_support.failed_to_send_ticket"));
      }
    } catch (error) {
      ErrorMessage(t("contact_support.failed_to_send_ticket"));
    } finally {
      setIsFetching(false);
    }
  };

  const onCancel = () => {
    const formFieldsValue = form.getFieldsValue(true);
    const hasChanges = Object.values(formFieldsValue).filter((value) => value !== "").length;
    if (hasChanges) {
      modal.confirm({
        centered: true,
        okText: t("yes_sure"),
        cancelText: t("back"),
        icon: <WarningIcon />,
        title: t("form_popups.confirmed_close_title"),
        content: (
          <>
            {t("form_popups.confirmed_close_content")}
            {t("are_you_sure_you_want_to_proceed")}
          </>
        ),
        onOk: () => {
          form.resetFields();
          setShowSupport(false);
          closeModal();
        }
      });
    } else {
      setShowSupport(false);
      closeModal();
    }
  };

  return (
    <ReachableContext.Provider value={null}>
      <Modal bodyStyle={{ padding: "0px" }} open={showSupport.visible} footer={false} onCancel={onCancel}>
        <Card
          headStyle={{
            background: BLUE_DARK,
            display: "flex",
            justifyContent: "center"
          }}
          bordered={false}
          title={
            <Title
              style={{
                fontSize: 20,
                color: WHITE
              }}
            >
              {showSupport.assistanceProgram
                ? t("support.title", { name: showSupport.assistanceProgram.name })
                : t("contact_support.title")}
            </Title>
          }
        >
          <Form form={form} name="contactSupport" onFinish={(values) => onSend(values)}>
            <Text strong style={{ fontSize: 16 }}>
              {t("support.please_provide_relevant_details_below")}
            </Text>
            <Form.Item
              name="subject"
              rules={[
                { required: true, message: t("support.subject_required") },
                { max: 49, message: "Subject should be less than 50 character" }
              ]}
              style={{ marginBottom: 0, marginTop: "30px" }}
            >
              <Input
                size="large"
                maxLength={50}
                placeholder={t("support.subject")}
                bordered={false}
                style={{ borderBottom: "1px solid #9B9B9B" }}
                onChange={(value) => setSubject(value.target.value)}
                suffix={<Text style={{ color: "#9B9B9B" }}>{`${subject.length}/50`}</Text>}
              />
            </Form.Item>
            <Text style={{ paddingTop: 4, color: "#2B56A3" }}>{t("support.disclaimer")}</Text>
            <Form.Item
              name="description"
              rules={[
                { required: true, message: t("support.description_required") },
                { max: 249, message: "Description should be less than 250 character" }
              ]}
              style={{ paddingTop: 30 }}
            >
              <TextArea placeholder={t("support.description")} rows={6} showCount={true} maxLength={250} />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: 16 }}>
              <Space>
                <Button type="text" onClick={onCancel}>
                  <Text style={{ color: "#2B56A3" }}>{t("cancel")}</Text>
                </Button>
                <Button htmlType="submit" loading={isFetching} type="primary">
                  {t("send")}
                </Button>
              </Space>
            </div>
          </Form>
        </Card>
      </Modal>
      {contextHolder}
    </ReachableContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(ACTIONS.setShowAssistanceProgramModal(false)),
  setShowSupport: (visible) => dispatch(ACTIONS.setShowSupport(visible))
});

const mapStateToProps = createStructuredSelector({
  userId: selectUserId,
  showSupport: selectShowSupport
});

const ContactSupport = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactSupportComponent));

export { ContactSupport };
