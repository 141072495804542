import React from "react";
import { Col, Divider, Input, Radio, Row, Form, Checkbox, Select, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { patientFields } from "../fieldNames";
import { phoneNumberLengthRegEx } from "../../../../../../utils/formValidation";
import { GRAY_500, GRAY_700 } from "../../../../../../constant/colors";
import { DobFormItem, FormDividerText, FormItem, InputPhoneWithPhoneTypeAddon, RadioButton } from "../../../components";
import { emailInputRules, onlyWhitespacesRule, zipInputRules } from "../../../utils";
import { SingleSelect } from "../../../../../customComponent/CustomSelect";
import { States as STATES } from "../../../../../../constant/states";
import { fontWeights, sizes } from "../../../../../../constant/styles";

const { Option } = Select;
const { Text } = Typography;

const EaglePemfexyPatient = () => {
  const { t } = useTranslation();

  return (
    <>
      <div id="enrollment-form-patient-information-anchor" />
      <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_500 }}>
        <FormDividerText>{t("application_form_editor.eagle.patient.labels.header")}</FormDividerText>
      </Divider>

      <Form.Item name={patientFields.id} hidden />

      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            name={patientFields.firstName}
            label={t("application_form_editor.eagle.patient.labels.first_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.eagle.patient.placeholders.first_name")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            name={patientFields.middleName}
            label={t("application_form_editor.eagle.patient.labels.middle_initial")}
          >
            <Input placeholder={t("application_form_editor.eagle.patient.placeholders.middle_initial")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={patientFields.lastName}
            label={t("application_form_editor.eagle.patient.labels.last_name")}
          >
            <Input placeholder={t("application_form_editor.eagle.patient.placeholders.last_name")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <DobFormItem
            required
            name={patientFields.dob}
            label={t("application_form_editor.eagle.patient.labels.dob")}
          />
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <FormItem name={patientFields.gender} label={t("application_form_editor.eagle.patient.labels.gender")}>
            <Radio.Group style={{ width: "100%" }}>
              <RadioButton style={{ width: "157px" }} value="female">
                {t("application_form_editor.eagle.patient.female")}
              </RadioButton>
              <RadioButton style={{ width: "157px" }} value="male">
                {t("application_form_editor.eagle.patient.male")}
              </RadioButton>
              <RadioButton style={{ width: "157px" }} value="other">
                {t("application_form_editor.eagle.patient.other")}
              </RadioButton>
            </Radio.Group>
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            rules={emailInputRules}
            name={patientFields.email}
            label={t("application_form_editor.eagle.patient.labels.email")}
          >
            <Input
              addonBefore={<MailOutlined />}
              placeholder={t("application_form_editor.eagle.patient.placeholders.email")}
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={patientFields.address}
            label={t("application_form_editor.eagle.patient.labels.address")}
          >
            <Input placeholder={t("application_form_editor.eagle.patient.placeholders.address")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            name={patientFields.city}
            label={t("application_form_editor.eagle.patient.labels.city")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.eagle.patient.placeholders.city")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            name={patientFields.state}
            label={t("application_form_editor.eagle.patient.labels.state")}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              placeholder={t("application_form_editor.eagle.patient.placeholders.state")}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {Object.values(STATES).map((state, index) => (
                <Option key={index} value={state.code}>
                  {state.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            rules={[{ required: true }, ...zipInputRules]}
            name={patientFields.zip}
            label={t("application_form_editor.eagle.patient.labels.zip_code")}
          >
            <Input
              maxLength={5}
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.eagle.patient.placeholders.zip_code")}
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
            name={patientFields.homePhone}
            label={t("application_form_editor.eagle.patient.labels.primary_phone_number")}
          >
            <InputPhoneWithPhoneTypeAddon
              placeholder={t("application_form_editor.eagle.patient.placeholders.primary_phone_number")}
              typeName={patientFields.homePhoneType}
            />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            rules={[{ pattern: phoneNumberLengthRegEx, message: "" }]}
            name={patientFields.mobilePhone}
            label={t("application_form_editor.eagle.patient.labels.secondary_phone_number")}
          >
            <InputPhoneWithPhoneTypeAddon
              placeholder={t("application_form_editor.eagle.patient.placeholders.secondary_phone_number")}
              typeName={patientFields.mobilePhoneType}
            />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem name={patientFields.additionalInformation} valuePropName="checked">
            <Checkbox>
              <Text style={{ fontSize: sizes.medium, fontWeight: fontWeights.semibold, color: GRAY_700 }}>
                {t("application_form_editor.eagle.patient.placeholders.contact_patient_for_additional_information")}
              </Text>
            </Checkbox>
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={6}>
          <FormItem name={patientFields.usCitizen} label={t("application_form_editor.eagle.patient.labels.us_citizen")}>
            <Radio.Group>
              <Radio value={true}>{t("application_form_editor.eagle.patient.yes")}</Radio>
              <Radio value={false}>{t("application_form_editor.eagle.patient.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            name={patientFields.caregiverName}
            label={t("application_form_editor.eagle.patient.labels.alternate_contact_name")}
          >
            <Input placeholder={t("application_form_editor.eagle.patient.placeholders.alternate_contact_name")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            name={patientFields.caregiverRelationship}
            label={t("application_form_editor.eagle.patient.labels.relationship")}
          >
            <Input placeholder={t("application_form_editor.eagle.patient.placeholders.relationship")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            rules={[{ pattern: phoneNumberLengthRegEx, message: "" }]}
            name={patientFields.caregiverPhone}
            label={t("application_form_editor.eagle.patient.labels.phone_number")}
          >
            <InputPhoneWithPhoneTypeAddon
              placeholder={t("application_form_editor.eagle.patient.placeholders.phone_number")}
              typeName={patientFields.caregiverPhoneType}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default EaglePemfexyPatient;
