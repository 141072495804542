import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";

import { UNSPECIFIED_MEMBER_ID, UNSPECIFIED_PAYER_NAME } from "../constant/coverages";

export const formatCoverage = (coverage, coverageType, patientId) => {
  let coverageTypeIndex;

  switch (coverageType) {
    case "primary":
      coverageTypeIndex = 0;
      break;
    case "secondary":
      coverageTypeIndex = 1;
      break;
    case "tertiary":
      coverageTypeIndex = 2;
      break;
  }

  const generateDefaultMemberID = (coverageTypeIndex, patientId) => {
    return UNSPECIFIED_MEMBER_ID.concat(`_${coverageTypeIndex}_${patientId}`);
  };

  return {
    coverageType,
    policyType: coverage.planMarket,
    ...coverage,
    id: uuidv4(),
    activeDate: moment(coverage.activeDate),
    memberId: coverage.memberId || generateDefaultMemberID(coverageTypeIndex, patientId),
    payerName: coverage.payerName ? coverage.payerName : UNSPECIFIED_PAYER_NAME
  };
};
