import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { Space, Table, Typography } from "antd";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment-timezone";
import { withRouter } from "react-router";

import { selectTableColumns, selectIsFetching, selectPendingRenewalData } from "../../store/selector";
import { ApiNames } from "../../api/api";
import { ReactComponent as NoPatientsFoundIcon } from "../../assets/svg/NoPatientsFoundIcon.svg";
import ACTION from "../../store/action";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { capitalizeFirstLetter } from "../../utils/string";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import { ColumnSelector } from "../ColumnSelector";
import {
  PatientNameColumn,
  StarColumn,
  AssociatedDrugColumnComponent,
  withSorting,
  AssigneeColumn
} from "./CommonColumns";
import PatientPageCount from "./PatientPageCount";
import { TabsMap } from "../../screen/patient/Patient";
import { commonTableStyle } from "./TableStyle";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { dateFormatter, DATE_FORMAT } from "../../utils/date";
import { formatPhoneNumber, formatSSN } from "../../utils/formaters";

const CentralizedText = styled.div`
  text-align: center;
`;
const StyledTable = styled(Table)`
  ${commonTableStyle}
`;
const { Text } = Typography;

const PendingRenewalTableComponent = ({
  tableColumns,
  isFetching,
  setTableDataAct,
  history,
  tableData,
  t,
  isFilterOpen,
  updateSavedFilterViewParameters
}) => {
  const onClickTable = () => ({
    onCell: (patient) => ({
      onClick: () => {
        const { patientId, journeyId } = patient;
        history.push(TabsMap.applications.path(patientId, journeyId));
      }
    })
  });

  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;

  const fetchData = (pagination, filters, sorter) => {
    if ((!tableData?.sorter?.columnKey && sorter.columnKey) || sorter.columnKey !== tableData?.sorter?.columnKey) {
      // Note: if user try sorting another column. fetch the first page data.
      pagination.current = 1;
    }

    setTableDataAct(pagination.current, sorter);

    if (updateSavedFilterViewParameters) {
      // Note: We need to maintain the last visited paging & sorting for each saved filter view.
      updateSavedFilterViewParameters(pagination.current, sorter);
    }

    window.scrollTo(0, 0);
  };

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.pendingRenewal]?.star?.dataIndex,
      key: tableColumns[selectedViews.pendingRenewal]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.name_id, {
      showSorterTooltip: {
        title: t("columns.sortById")
      },
      minWidth: 300,
      render: (text, record) => <PatientNameColumn record={record} />,
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.phone_number,
      render: (text, record) => formatPhoneNumber(record.phone_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.dob,
      // render: (text, record) => moment(record.dob).format("MM/DD/YYYY"),
      render: (text, record) => {
        if (!record.dob || record.dob === t("invalid_date")) {
          return "";
        } else {
          const dob = moment(record.dob);
          if (dob.isValid()) {
            return dob.format(DATE_FORMAT);
          } else {
            return "";
          }
        }
      },
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.ssn,
      render: (text, record) => formatSSN(record.social_security_number),
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.assistanceProgram, {
      render: (text, record) => (record.assistanceProgramName ? record.assistanceProgramName : null),
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.apType, {
      render: (text, record) => (record.apType ? record.apType : null),
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.providedBy, {
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.associatedDrug,
      render: (text, record) => <AssociatedDrugColumnComponent record={record} />,
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.assignee,
      render: (text, record) => <AssigneeColumn record={record} />,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.facility, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.physician, {
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.employer,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.creationDate, {
      render: (text, record) => moment(record.creationDate).format("MMM D, YYYY"),
      ...onClickTable()
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.patientStatus, {
      render: (_, record) => <PatientStatusCell value={record.status} />,
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.primaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.primary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.secondaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.secondary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.pendingRenewal]?.tertiaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.tertiary),
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.applicationStatus, {
      width: 150,
      render: (text, record) => (
        <CentralizedText>
          {record.applicationStatus ? capitalizeFirstLetter(record.applicationStatus) : null}
        </CentralizedText>
      ),
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.fundBalance, {
      render: (text, record) =>
        !isNaN(parseFloat(record.fundBalance)) && record.apType.trim().toLowerCase() !== "free drug"
          ? `$${record.fundBalance}`
          : null,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.validThrough, {
      width: 130,
      render: (text, record) => {
        if (record.effectiveIndefinitely) {
          return t("patientApplications.indefinitely");
        } else {
          return (
            moment(record.approvalValidThrough).isValid() && (
              <CentralizedText>{dateFormatter(record.approvalValidThrough)}</CentralizedText>
            )
          );
        }
      },
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.approvedDate, {
      render: (text, record) =>
        record.approvedDate && <CentralizedText>{dateFormatter(record.approvedDate)}</CentralizedText>,
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.pendingRenewal]?.agreementSignatureDate,
      render: (text, record) =>
        record.agreement_signature_date && (
          <CentralizedText>{dateFormatter(record.agreement_signature_date)}</CentralizedText>
        ),
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.followupDate, {
      render: (text, record) =>
        record.followupDate && <CentralizedText>{dateFormatter(record.followupDate)}</CentralizedText>
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.pendingRenewal]?.latestClaimDate, {
      render: (text, record) =>
        record.latestClaimDate && <CentralizedText>{dateFormatter(record.latestClaimDate)}</CentralizedText>
    })
  ];
  return (
    <StyledTable
      size="small"
      rowClassName="cursor-pointer"
      style={{ width: isFilterOpen ? "77%" : "100%" }}
      loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
      columns={columns.filter((col) => tableColumns[selectedViews.pendingRenewal]?.[col.key]?.display)}
      locale={{
        emptyText: tableData && tableData.patients && tableData.patients.length == 0 && (
          <Space direction={"vertical"} size={10}>
            <NoPatientsFoundIcon />
            <Text style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.5)", fontSize: "16px" }}>
              {t("no_patients_found")}
            </Text>
          </Space>
        )
      }}
      onChange={fetchData}
      pagination={{
        size: "small",
        showSizeChanger: false,
        current: tableData && tableData.selectedPage,
        defaultPageSize: PAGE_SIZE,
        pageSize: PAGE_SIZE,
        position: ["topRight"],
        total: totalRecordsCount,
        showTotal: (total, range) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ColumnSelector />
            <PatientPageCount total={total} range={range} isAccurateCount={tableData.isAccurateCount} />
          </div>
        )
      }}
      dataSource={
        tableData && tableData.patients
          ? tableData.patients
              .map((patient, index) => {
                return {
                  ...patient,
                  key: index
                };
              })
              .sort((a, b) => b.isActive - a.isActive)
          : null
      }
    />
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  tableData: selectPendingRenewalData,
  isFetching: selectIsFetching([ApiNames.PendingRenewalPhiSearch, ApiNames.PendingRenewalSearch])
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.pendingRenewal, sorter))
});

const PendingRenewalTable = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PendingRenewalTableComponent);

export { PendingRenewalTable };
