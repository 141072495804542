import { SidebarIcon } from "../../components";

const getGenentechCopaySidebarItems = (t, expressEnrollmentConfiguration) => {
  const sidebarItems = [
    {
      title: t("application_form_editor.genentech.copay.eligibility_questionnaire.sidebar"),
      href: "#enrollment-form-eligibility_questionnaire",
      key: "eligibilityQuestionnaire",
      icon: SidebarIcon,
      validationFields: ["eligibilityQuestionnaire"]
    },
    {
      title: t("application_form_editor.genentech.copay.patient.sidebar"),
      href: "#enrollment-form-patient-information",
      key: "patient",
      icon: SidebarIcon,
      validationFields: ["patient"]
    },
    {
      title: t("application_form_editor.genentech.copay.insurance.sidebar"),
      href: "#enrollment-form-insurance-information",
      key: "insurance",
      icon: SidebarIcon,
      validationFields: ["insurance"]
    },
    {
      title: t("application_form_editor.genentech.copay.physician_and_facility.sidebar"),
      href: "#enrollment-form-physician_and_facility-information",
      key: "physicianAndFacility",
      icon: SidebarIcon,
      validationFields: ["physician", "facility"]
    }
  ];

  if (expressEnrollmentConfiguration?.hasAdminOption) {
    return [
      {
        title: t("application_form_editor.genentech.copay.support_options.sidebar"),
        href: "#enrollment-form-support-options",
        key: "supportOptions",
        icon: SidebarIcon,
        validationFields: ["assistanceType"]
      },
      ...sidebarItems
    ];
  }
  return sidebarItems;
};

export default getGenentechCopaySidebarItems;
