import React from "react";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { novartisSectionsController } from "../../novartisSectionsController";

export default function NovartisCopayEligibilityAndAttestation() {
  const eligibilityAndAttestationFormComponents = formSectionsController().getSectionFieldsByVendor(
    vendors.NOVARTIS_COPAY
  )().eligibilityAndAttestation;
  const controller = novartisSectionsController();
  const currentDrug = controller.associatedDrug;
  const drugList = controller.drugsList;
  const getFieldForHipaa = (currentDrug) => {
    return drugList.COSENTYX === currentDrug
      ? eligibilityAndAttestationFormComponents.HIPAA_Cosentyx({ required: true })
      : eligibilityAndAttestationFormComponents.HIPAA_Auth({ required: true });
  };
  return (
    <SectionPart
      sectionId="eligibilityAndAttestation"
      headerTranslationKey="application_form_editor.novartis.copay.eligibilityAndAttestation.labels.header"
    >
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>{[getFieldForHipaa(currentDrug)]}</ConfigurableRow>
    </SectionPart>
  );
}
