import React from "react";
import { Spin } from "antd";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useLocation } from "react-router";

import { selectIsFetching } from "../store/selector";
import { ApiNames } from "../api/api";

const style = {
  position: "absolute",
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  backdropFilter: "blur(5px)",
  zIndex: 9999
};

const AppLoaderComponent = ({ isFetching }) => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <>
      {isFetching && !pathname.includes("patient") && (
        <div style={style}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching([ApiNames.LoggedUser, ApiNames.Settings, ApiNames.CountByStatus])
});

const AppLoader = connect(mapStateToProps, null)(AppLoaderComponent);

export { AppLoader };
