import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import DiscardChangesPopup from "../component/Patient/PatientCoverageDetails/DiscardChangesPopup";
import { Routes } from "../constant/routes";

export function RouterPrompt(props) {
  const { t } = useTranslation();
  const { shouldTriggerPopup, popupDescription } = props;

  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    const blocker = history.block((prompt) => {
      if (prompt.hash?.length == 0) {
        setCurrentPath(prompt.pathname);
        if (shouldTriggerPopup) {
          setShowPrompt(true);
          return false;
        }
        return true;
      }
    });
    return () => {
      blocker();
    };
  }, [history, shouldTriggerPopup]);

  const handleOK = useCallback(async () => {
    history.block(() => {});
    currentPath === Routes.SEARCH_RESULTS ? history.goBack() : history.push(currentPath);
  }, [currentPath]);

  const handleCancel = useCallback(async () => {
    setShowPrompt(false);
  }, [currentPath]);

  return showPrompt ? (
    <DiscardChangesPopup
      visible={showPrompt}
      onClickDiscard={handleOK}
      onClickCancel={handleCancel}
      popupTitle={t("managePatientDetails.cancel_modal_title")}
      popupDescription={popupDescription}
    />
  ) : null;
}
