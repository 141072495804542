const MAX_NUMBER = 100000000;
const MIN_NUMBER = 0;
export const MAX_NUM_OF_CYCLES = 730;

export const numberValidation = (max = MAX_NUMBER) => [
  {
    type: "number",
    min: 1,
    message: "Must be a positive number",
    transform: (value) => (!value ? null : Number(value) ? Number(value) : 0)
  },
  {
    type: "number",
    max,
    message: `Should be less than ${max}`,
    transform: (value) => (!value ? null : Number(value) ? Number(value) : 0)
  }
];

export const numberInputValidator = (errorMessage = "") => ({
  validator: (_, value) => {
    if (!value || /^\d+$/.test(value)) {
      // This regex checks for a non-negative integer
      return Promise.resolve();
    }
    return Promise.reject(new Error(errorMessage));
  }
});

export const minNumberValidation = (minNumber) => () => ({
  validator(_, value) {
    if (value < minNumber) {
      return Promise.reject();
    }
    return Promise.resolve();
  }
});

export const rangeValidationRule = (min = MIN_NUMBER, max = MAX_NUMBER) => [
  {
    type: "number",
    min,
    message: `Must be ${min} or above`,
    transform: (value) => (!value ? null : Number(value) ? Number(value) : 0)
  },
  {
    type: "number",
    max,
    message: `Must be ${max} or below`,
    transform: (value) => (!value ? null : Number(value) ? Number(value) : 0)
  }
];

export const lengthValidation = (len = 49) => ({
  max: len,
  message: `Should be less than ${len} characters`
});

export const digitsOnlyRule = (t) => ({
  pattern: /^\d+$/,
  message: t("new_patient.only_digits_rule")
});

export const ssnRule = (t) => ({
  pattern: /^\d{3}-?\d{2}-?\d{4}$/,
  message: t("new_patient.ssn_rule")
});

export function isUrl(_string) {
  const matchpattern =
    /(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
  return matchpattern.test(_string);
}

export const isEmail = (_string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(_string);
};

export const phoneNumberLengthRegEx = /^\d{10}$/;

export const numOfCyclesOrRefillsValidator = (_, numOfCycles) => {
  if (numOfCycles > MAX_NUM_OF_CYCLES) {
    return Promise.reject();
  } else {
    return Promise.resolve();
  }
};

export const getValidationErrors = async (form, fieldsToValidate) => {
  try {
    if (fieldsToValidate) {
      await form.validateFields(fieldsToValidate);
    } else {
      await form.validateFields();
    }
  } catch (errorsInfo) {
    if (errorsInfo.errorFields?.length) return errorsInfo.errorFields;
  }
  return null;
};

export const validateEmail = (_, value) => {
  if (!value || isEmail(value)) {
    return Promise.resolve();
  }
  return Promise.reject();
};
