import React, { useState } from "react";
import { Select, DatePicker } from "antd";
import { withTranslation } from "react-i18next";
import { CalendarOutlined } from "@ant-design/icons";

import { SingleSelect } from "../customComponent/CustomSelect";
import { dateFilterTypes, ALL_TIMES } from "../../constant/filters";
import { DATE_FORMAT, setDateRange } from "../../utils/date";
import moment from "moment-timezone";

const { RangePicker } = DatePicker;
const { Option } = Select;

const DateRangeFilterComponent = ({
  t,
  timeRange,
  disabled,
  onSelect,
  dateFilters,
  handleInput,
  id,
  disabledDate,
  allowClear,
  onClear,
  defaultValue,
  placeHolder,
  withRangePicker = true
}) => {
  const fromDate = timeRange.fromDate ? moment(timeRange.fromDate, DATE_FORMAT) : null;
  const toDate = timeRange.toDate ? moment(timeRange.toDate, DATE_FORMAT) : null;
  const selectedValue = timeRange.value;
  const isRangeSelected = fromDate && toDate && !selectedValue;
  const [isRangeOpen, setIsRangeOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [open, setOpen] = useState(false);

  const setRangePicker = (values) => {
    setOpen(false);

    const formattedDates = setDateRange(values[0], values[1]);
    onSelect(...formattedDates);

    // Note: reset the date value for the filter input.
    handleInput();
  };

  return (
    <div id={id ? id : undefined}>
      <SingleSelect
        showSearch={false}
        placeholder={placeHolder}
        disabled={disabled}
        open={open}
        onClick={() => {
          if (isFocus && !open) setOpen(true);
        }}
        onFocus={() => {
          setIsFocus(true);
          setOpen(true);
        }}
        onBlur={() => {
          setIsFocus(false);
          if (!isRangeOpen) setOpen(false);
        }}
        onSelect={(value) => {
          setOpen(false);

          if (value === selectedValue) {
            return;
          } else {
            handleInput(value);

            if (value === defaultValue) {
              onClear();
            } else {
              onSelect(null, null, value);
            }
          }
        }}
        suffixIcon={<CalendarOutlined />}
        value={
          //: value of null will make the placeHolder take effect.
          isRangeSelected
            ? `${fromDate.format("MM-DD-YYYY")} - ${toDate.format("MM-DD-YYYY")}`
            : selectedValue === defaultValue
            ? null
            : selectedValue
        }
        dropdownRender={(menu) => (
          <div>
            {menu}
            {withRangePicker && (
              <RangePicker
                value={isRangeSelected ? [fromDate, toDate] : null}
                disabledDate={disabledDate}
                suffixIcon={null}
                clearIcon={null}
                separator={null}
                bordered={false}
                allowEmpty={[false, false]}
                onChange={setRangePicker}
                onOpenChange={(open) => setIsRangeOpen(open)}
              />
            )}
          </div>
        )}
        allowClear={allowClear}
        onClear={onClear}
      >
        <Option value={ALL_TIMES} id={"all_times"}>
          {t("all_times")}
        </Option>
        {dateFilterTypes[dateFilters].map((filterType) => (
          <Option value={filterType.value} key={filterType.value} id={filterType.id || undefined}>
            {t(filterType.value)}
          </Option>
        ))}
      </SingleSelect>
    </div>
  );
};

const DateRangeFilter = withTranslation()(DateRangeFilterComponent);

export { DateRangeFilter };
