export const programTypes = [
  "Copay Card",
  "Free Drug",
  "Copay Foundation",
  "Living and Transport expenses",
  "Government Plans",
  "Internal"
];

export const adminType = {
  IV: "iv",
  ORAL: "oral",
  OTHER: "other"
};

export const lookbackPeriodUnits = Object.freeze({
  months: "months",
  days: "days"
});
