import _ from "lodash";
import { buildFieldValueMap } from "../../../../utils/form";
import { getPurePhoneNumber } from "../../../../utils/misc";
import { getNamesFromFullName } from "./parsers";

const PHONE = "phone";
const FAX = "fax";

const getPhysicianFieldsToUpdate = (
  form,
  physicians,
  physicianIdField,
  physicianFirstNameField,
  physicianLastNameField
) => {
  const physicianId = form.getFieldValue(physicianIdField);
  const physician = physicians?.filter(({ id }) => id === physicianId)[0];
  let physicianFirstName = undefined;
  let physicianLastName = undefined;

  const physicianFullName = physician?.name;

  if (physicianFullName) {
    const physicianFullNames = getNamesFromFullName(physicianFullName);
    physicianFirstName = physicianFullNames.firstName;
    physicianLastName = physicianFullNames.lastName;
  }
  const firstNameFieldValueMap = buildFieldValueMap([[physicianFirstNameField, physicianFirstName]]);

  //When having full name as one string, use the same string for first and last name
  let lastNameFieldValueMap;
  if (!physicianLastName) {
    lastNameFieldValueMap = buildFieldValueMap([[physicianLastNameField, physicianFirstName]]);
  } else {
    lastNameFieldValueMap = buildFieldValueMap([[physicianLastNameField, physicianLastName]]);
  }

  const firstNameToUpdate = Object.values(firstNameFieldValueMap)[0];
  const lastNameToUpdate = Object.values(lastNameFieldValueMap)[0];
  return { ...firstNameToUpdate, ...lastNameToUpdate };
};

const getFieldsToUpdateOnProviderChange = (providers, providerId, formToProviderFieldMap, additionalFieldsToUpdate) => {
  const provider = providers?.filter(({ id }) => id === providerId)[0];
  let fieldsToUpdate = {};
  formToProviderFieldMap.map((field) => {
    let fieldValueMap = {};
    const formFieldName = field[0];
    const providerFieldName = field[1];
    if (provider) {
      let providerFieldValue = provider[providerFieldName];
      if (providerFieldValue && (providerFieldName === PHONE || providerFieldName === FAX)) {
        providerFieldValue = getPurePhoneNumber(providerFieldValue);
      }
      fieldValueMap = buildFieldValueMap([[formFieldName, providerFieldValue?.toString().trim()]]);
    } else {
      fieldValueMap = buildFieldValueMap([[formFieldName, undefined]]);
    }
    const objToUpdate = Object.values(fieldValueMap)[0];
    fieldsToUpdate = _.merge(fieldsToUpdate, objToUpdate);
  });

  if (additionalFieldsToUpdate) {
    fieldsToUpdate = { ...fieldsToUpdate, ...additionalFieldsToUpdate };
  }

  return fieldsToUpdate;
};

export { getPhysicianFieldsToUpdate, getFieldsToUpdateOnProviderChange };
