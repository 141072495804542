import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Popover, Spin, Tree, Typography } from "antd";
import { createStructuredSelector } from "reselect";

import { selectHighPrioritiesData, selectIsFetching, selectPatientDetails } from "../../../store/selector";
import { fontWeights, sizes } from "../../../constant/styles";
import { FADE_GREEN, GREEN_DARK } from "../../../constant/colors";
import { ReactComponent as FlagGreen } from "../../../assets/svg/flag-green.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import Modal from "antd/lib/modal/Modal";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import SuccessMessage from "../../customComponent/customMessages/SuccessMessage";
import ErrorMessage from "../../customComponent/customMessages/ErrorMessage";
import ACTIONS from "../../../store/action";
import { ApiNames, rejectHighPriorityAPI, rejectSingleHighPriorityPapAPI } from "../../../api/api";
import { rejectReasons } from "../../../constant/patient";
import { useSelectedPotentialSaving } from "../hooks";
import action from "../../../store/action";
import { selectedViews } from "../../../constant/table";

const { Text } = Typography;

const ExcludeHighPriorityComponant = ({
  t,
  papId,
  patientDetails,
  isFetchingPatientJourney,
  updatePatientDetailsAct,
  getPatientJourneyAct,
  getSummaryCountersAct,
  highPrioritiesTableData,
  refetchHighPriority
}) => {
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const selectedPotentialSaving = useSelectedPotentialSaving();

  const { patientId, journeyId } = useParams();

  const rejectHighPriority = async () => {
    try {
      setIsFetching(true);
      const result = await rejectHighPriorityAPI(reason, patientId);

      if (result && result.data) {
        SuccessMessage("Reject high priority successfully");
        updatePatientDetailsAct({ ...patientDetails, isOpportunity: false });
        setShowModal(false);
        refetchHighPriority(highPrioritiesTableData.sorter);
        getSummaryCountersAct();
      } else {
        ErrorMessage("Failed to reject high priority");
      }
    } catch {
      ErrorMessage("Failed to reject high priority");
    } finally {
      setIsFetching(false);
    }
  };

  const rejectSingleHighPriorityPap = async () => {
    try {
      setIsFetching(true);
      const result = await rejectSingleHighPriorityPapAPI(reason, papId);
      if (result) {
        SuccessMessage("Reject high priority successfully");
        getPatientJourneyAct(journeyId);
        setShowModal(false);
        refetchHighPriority(highPrioritiesTableData.sorter);
        getSummaryCountersAct();
      } else {
        ErrorMessage("Failed to reject high priority");
      }
    } catch {
      ErrorMessage("Failed to reject high priority");
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <Popover
        content={
          <Text style={{ padding: 16 }}>
            {t(`patientTopSection.${papId ? "exclude_high_priority" : "exclude_patient_from_high_priority"}`)}
          </Text>
        }
      >
        <Button
          type="text"
          style={{ padding: 0 }}
          onClick={() => setShowModal(!showModal)}
          disabled={isFetchingPatientJourney && papId && selectedPotentialSaving?.id === papId}
        >
          <div
            style={{
              height: 22,
              width: 122,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: FADE_GREEN,
              gap: 5,
              padding: "4px 8px",
              borderRadius: 4
            }}
          >
            <FlagGreen />
            <Text
              style={{
                fontSize: sizes.xsmall,
                color: GREEN_DARK,
                fontWeight: fontWeights.bold,
                textTransform: "uppercase"
              }}
            >
              {t("patientTopSection.high_priority")}
            </Text>
            {isFetchingPatientJourney && papId && selectedPotentialSaving?.id === papId ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 12 }} spin />} />
            ) : (
              <Close />
            )}
          </div>
        </Button>
      </Popover>
      <Modal
        title="Reject Reason"
        open={showModal}
        okButtonProps={{ disabled: !reason || isFetching, loading: isFetching }}
        onOk={() => {
          if (papId) {
            rejectSingleHighPriorityPap();
          } else {
            rejectHighPriority();
          }
        }}
        onCancel={() => setShowModal(false)}
      >
        <Text style={{ fontSize: sizes.medium, fontWeight: fontWeights.semibold }}>
          Please select a reject reason from the list below:
        </Text>
        <Tree
          switcherIcon={<DownOutlined />}
          defaultExpandAll={true}
          onSelect={(_, e) => setReason(e.node.title)}
          treeData={rejectReasons(t)}
        />
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPatientJourneyAct: (journeyId) => dispatch(ACTIONS.getPatientJourneyAction(journeyId)),
  updatePatientDetailsAct: (patientDetails) => dispatch(ACTIONS.updatePatientDetailsAction(patientDetails)),
  getSummaryCountersAct: () => dispatch(ACTIONS.getSummaryCounters()),
  refetchHighPriority: (sorter) => dispatch(action.setTableDataAction(1, selectedViews.highPriority, sorter))
});

const mapStateToProps = createStructuredSelector({
  isFetchingPatientJourney: selectIsFetching([ApiNames.PatientJourney, ApiNames.PatientJourneyNonPhi]),
  patientDetails: selectPatientDetails,
  highPrioritiesTableData: selectHighPrioritiesData
});

const ExcludeHighPriority = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ExcludeHighPriorityComponant);

export { ExcludeHighPriority };
