import React from "react";
import { Radio } from "antd";
import { useTranslation } from "react-i18next";

import FormItem from "../../../../../../FormItem";

const insuranceTypes = Object.freeze({
  COMMERCIAL: "commercial",
  MEDICARE: "medicare",
  MEDICAID: "medicaid",
  OTHER: "other",
  NONE: "none"
});

const InsuranceTypeFormItem = ({ type, name }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      rules={[{ required: true }]}
      name={name}
      label={t(`application_form_editor.pfizer.insurance.labels.${type}_insurance_type`)}
    >
      <Radio.Group>
        <Radio value={insuranceTypes.COMMERCIAL}>{t("application_form_editor.pfizer.insurance.commercial")}</Radio>
        <Radio value={insuranceTypes.MEDICARE}>{t("application_form_editor.pfizer.insurance.medicare")}</Radio>
        <Radio value={insuranceTypes.MEDICAID}>{t("application_form_editor.pfizer.insurance.medicaid")}</Radio>
        <Radio value={insuranceTypes.OTHER}>{t("application_form_editor.pfizer.insurance.other")}</Radio>
        <Radio value={insuranceTypes.NONE}>{t("application_form_editor.pfizer.insurance.none")}</Radio>
      </Radio.Group>
    </FormItem>
  );
};

export { insuranceTypes, InsuranceTypeFormItem };
