import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Button } from "antd";
import styled from "styled-components";

import { fontWeights, sizes } from "../constant/styles";
import ACTIONS from "../store/action";
import { BLUE_2, LIGHT_BLUE_2, LIGHT_BLUE_4 } from "../constant/colors";
import { formPopupsContentKeys } from "../constant/formPopupsContentKeys";

const StyledButton = styled(Button)`
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.semibold};
  background: ${LIGHT_BLUE_2} !important;
  color: ${BLUE_2};
  border: 1px solid ${LIGHT_BLUE_2} !important;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;

  :active,
  :hover,
  :focus {
    color: ${BLUE_2};
  }
  :disabled {
    color: ${LIGHT_BLUE_4};
    :hover {
      color: ${LIGHT_BLUE_4};
    }
  }
`;

const SaveAsNewListComponent = ({ t, id, setFormPopupsAct, disabled }) => {
  const handleClickButton = () => {
    setFormPopupsAct(true, formPopupsContentKeys.ADD_NEW_LIST);
  };

  return (
    <StyledButton type="primary" onClick={handleClickButton} disabled={disabled} id={id}>
      {t("save_as_new_list")}
    </StyledButton>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFormPopupsAct: (visible, contentKey) => dispatch(ACTIONS.setFormPopups(visible, contentKey))
});

const SaveAsNewList = compose(withTranslation(), connect(null, mapDispatchToProps))(SaveAsNewListComponent);

export default SaveAsNewList;
