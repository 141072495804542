import React, { useEffect } from "react";
import { Col, Divider, Form, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { GRAY_500 } from "../../../../../../../constant/colors";
import { InsuranceDetails } from "./InsuranceDetails";
import { insuranceFields } from "../../fieldNames";
import { FormDividerText, FormItem } from "../../../../components";
import { tristate } from "../../../../../../../constant/tristate";
import { PrescriptionInsuranceDetails } from "./PrescriptionInsuranceDetails";

import { InputDollar } from "../../../../components/InputDollar";
import { MedicarePartDDetails } from "./MedicarePartDDetails";
import { selectApplicationForm } from "../../../../../../../store/selector";
import IneligiblePatientWarning from "../../../../components/IneligiblePatientWarning";
import { drugTypes } from "../prescription/drugDefinitions";

export const PRIMARY_INSURANCE = "primary";
export const SECONDARY_INSURANCE = "secondary";

export default function PfizerFreeDrugInsurance() {
  const { t } = useTranslation();
  const applicationForm = useSelector(selectApplicationForm);
  const form = Form.useFormInstance();
  const medicarePartD = Form.useWatch(insuranceFields.medicarePartD, form);
  const coveredByMedicalOrPrescription = Form.useWatch(insuranceFields.coveredByMedicalOrPrescription, form);
  const completedBI = Form.useWatch(insuranceFields.completedBI, form);

  const isInjectable = applicationForm?.tmRouteType.toLowerCase() === drugTypes.IV;

  useEffect(() => {
    form.validateFields();
  }, [medicarePartD, coveredByMedicalOrPrescription]);

  return (
    <>
      <Divider
        id="enrollment-form-insurance-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.pfizer.insurance.labels.header")}</FormDividerText>
      </Divider>
      <Row gutter={14}>
        <Col span={16}>
          <FormItem
            rules={[{ required: true }]}
            name={insuranceFields.coveredByMedicalOrPrescription}
            label={t("application_form_editor.pfizer.insurance.labels.covered_by_insurance")}
          >
            <Radio.Group>
              <Radio value={tristate.YES}>{t("application_form_editor.pfizer.insurance.yes")}</Radio>
              <Radio value={tristate.NO}>{t("application_form_editor.pfizer.insurance.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      {coveredByMedicalOrPrescription === tristate.YES && (
        <Row>
          <FormItem
            rules={[{ required: true }]}
            name={insuranceFields.prescriptionCopayAmount}
            labelCol={{ span: 24 }}
            label={t("application_form_editor.pfizer.insurance.labels.copay_amount")}
          >
            <InputDollar
              style={{ width: "75%" }}
              placeholder={t("application_form_editor.pfizer.insurance.placeholders.copay_amount")}
            />
          </FormItem>
        </Row>
      )}
      <Row gutter={14}>
        <Col span={24}>
          <FormItem
            rules={[
              { required: true },
              {
                validator: async function () {
                  if (completedBI === false) {
                    return Promise.reject();
                  }
                },
                message: ""
              }
            ]}
            name={insuranceFields.completedBI}
            label={t("application_form_editor.pfizer.insurance.labels.completed_bi")}
            style={{ ...(completedBI === false && { marginBottom: 0 }) }}
          >
            <Radio.Group>
              <Radio value={true}>{t("application_form_editor.pfizer.insurance.yes")}</Radio>
              <Radio value={false}>{t("application_form_editor.pfizer.insurance.no")}</Radio>
            </Radio.Group>
          </FormItem>
          {completedBI === false && (
            <IneligiblePatientWarning
              ineligibleAnswerError={"application_form_editor.pfizer.hcp.ineligible_answer_error"}
            />
          )}
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={24}>
          <FormItem
            rules={[{ required: true }]}
            name={insuranceFields.unaffordableCopay}
            label={t("application_form_editor.pfizer.insurance.labels.unaffordable_copay")}
          >
            <Radio.Group>
              <Radio value={true}>{t("application_form_editor.pfizer.insurance.yes")}</Radio>
              <Radio value={false}>{t("application_form_editor.pfizer.insurance.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      <InsuranceDetails type={PRIMARY_INSURANCE} />
      <InsuranceDetails type={SECONDARY_INSURANCE} />
      <PrescriptionInsuranceDetails />
      {!isInjectable && (
        <Row gutter={14}>
          <Col span={24}>
            <FormItem
              rules={[{ required: true }]}
              label={t("application_form_editor.pfizer.insurance.labels.medicare_part_d")}
              name={insuranceFields.medicarePartD}
            >
              <Radio.Group>
                <Radio value={true}>{t("application_form_editor.pfizer.insurance.yes")}</Radio>
                <Radio value={false}>{t("application_form_editor.pfizer.insurance.no")}</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
      )}
      {medicarePartD && <MedicarePartDDetails />}
    </>
  );
}
