import React from "react";
import { useTranslation } from "react-i18next";

import { Space, Typography } from "antd";
import { fontWeights, sizes } from "../../constant/styles";
import { GRAY8, TEXT1 } from "../../constant/colors";

const { Text } = Typography;

const PatientReportEligibility = ({ requiredCoverageTypes, assistanceProgram }) => {
  const { t } = useTranslation();
  return (
    <Space size={24} style={{ width: "100%" }}>
      {assistanceProgram.maxFpl && (
        <Space size={24} style={{ width: "100%" }}>
          <Space size={4}>
            <Text style={{ fontWeight: fontWeights.semibold, color: GRAY8 }}>
              {t("patientPotentialSavings.insurance_criterias")}
            </Text>
            <Text>
              {requiredCoverageTypes.length ? requiredCoverageTypes.map(({ title }) => title).join(" / ") : "--"}
            </Text>
          </Space>
          <Space size={4}>
            <Text style={{ fontWeight: fontWeights.semibold, color: GRAY8 }}>
              {t("patientPotentialSavings.income_required")}
            </Text>
            <Text>{assistanceProgram.maxFpl > 0 ? `${assistanceProgram.maxFpl}%` : "--"}</Text>
          </Space>
        </Space>
      )}
      <Space direction="vertical" size={4}>
        <Text style={{ fontWeight: fontWeights.semibold, color: GRAY8 }}>
          {t("patientPotentialSavings.program_eligibilitys")}
        </Text>
        {assistanceProgram.eligibilityRequirements && assistanceProgram.eligibilityRequirements.length ? (
          <ul style={{ listStyle: "inside", padding: "0" }}>
            {assistanceProgram.eligibilityRequirements.map((requirement, index) => (
              <li key={index} style={{ fontSize: sizes.medium, fontWeight: fontWeights.regular, color: TEXT1 }}>
                {requirement.name}
              </li>
            ))}
          </ul>
        ) : (
          "--"
        )}
      </Space>
    </Space>
  );
};

export default PatientReportEligibility;
