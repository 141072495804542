import React from "react";
import { Form, Input } from "antd";

import { onlyWhitespacesRule } from "../../utils";

export const InputItem = ({ name, label, rules, inputPlaceholder, inputProps = {}, formItemProps = {}, style }) => {
  const rulesArr = rules || [];

  const requiredRuleExists = rulesArr.find((rule) => rule.required);
  if (requiredRuleExists) {
    rulesArr.push(onlyWhitespacesRule);
  }

  return (
    <Form.Item name={name} label={label} rules={rulesArr} style={style} {...formItemProps}>
      <Input {...inputProps} placeholder={inputPlaceholder} />
    </Form.Item>
  );
};
