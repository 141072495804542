import React from "react";

import { Form, Checkbox } from "antd";

export const CheckboxItem = ({ rules, name, label, ...props }) => {
  return (
    <Form.Item rules={rules} name={name} valuePropName="checked" {...props}>
      <Checkbox>{label}</Checkbox>
    </Form.Item>
  );
};
