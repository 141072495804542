const patientFields = {
  id: ["patient", "id"],
  firstName: ["patient", "firstName"],
  lastName: ["patient", "lastName"],
  dob: ["patient", "dob"],
  gender: ["patient", "gender"],
  address: ["patient", "address"],
  city: ["patient", "city"],
  state: ["patient", "state"],
  zip: ["patient", "zip"],
  email: ["patient", "email"],
  phone: ["patient", "phone"],
  phoneType: ["patient", "phoneType"]
};

const practiceFields = {
  facilityNpi: ["facility", "npi"]
};

export { patientFields, practiceFields };
