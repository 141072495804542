import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";

import SectionPart from "../../../../components/SectionPart";
import { ConfigurableRow } from "../../../../ConfigurableRow";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { selectApplicationForm } from "../../../../../../../store/selector";
import { medicalFields } from "../fieldNames";

const indexToKey = {
  0: medicalFields[0][1],
  1: medicalFields[1][1],
  2: medicalFields[2][1],
  3: medicalFields[3][1]
};
const medicalMainField = medicalFields[0][0];

const MAX_DIAGNOSIS_COUNT = 4;

export default function GenentechFreeDrugMedical() {
  const applicationForm = useSelector(selectApplicationForm);
  const medicalFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.GENENTECH_PAP)().medical;
  const medicalSavedCount = applicationForm?.formData?.medical && Object.keys(applicationForm.formData.medical).length;
  const [diagnosisCount, setDiagnosisCount] = useState(medicalSavedCount || 1);
  const form = Form.useFormInstance();

  const addDiagnosis = () => {
    if (diagnosisCount < MAX_DIAGNOSIS_COUNT) {
      setDiagnosisCount(diagnosisCount + 1);
    }
  };

  const removeDiagnosis = () => {
    if (diagnosisCount > 1) {
      setDiagnosisCount(diagnosisCount - 1);
    }
  };
  const getFieldValueBasedOnIndex = (index) => {
    return form.getFieldValue([medicalMainField, indexToKey[index]]);
  };

  return (
    <SectionPart
      sectionId="medical"
      headerTranslationKey="application_form_editor.genentech.free_drug.medical.labels.header"
    >
      {[...Array(diagnosisCount)].map((_, index) => (
        <ConfigurableRow key={index}>
          {[
            medicalFormComponents.diagnosisIcd({
              onDelete: removeDiagnosis,
              index,
              allowClear: diagnosisCount - 1 === index,
              allowDelete: diagnosisCount - 1 === index
            })
          ]}
        </ConfigurableRow>
      ))}
      {diagnosisCount < MAX_DIAGNOSIS_COUNT &&
        medicalFormComponents.addDiagnosis({
          onClick: addDiagnosis,
          disabled: !getFieldValueBasedOnIndex(diagnosisCount - 1)
        })}
    </SectionPart>
  );
}
