import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Input, Select, Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { GRAY_500 } from "../../../../../../constant/colors";
import { practiceFields } from "../fieldNames";
import { FormDividerText, FormItem, InputPhone } from "../../../components";
import { SingleSelect } from "../../../../../customComponent/CustomSelect";
import { getStateCode, States } from "../../../../../../constant/states";
import { emailInputRules, onlyWhitespacesRule, zipInputRules } from "../../../utils";
import { phoneNumberLengthRegEx } from "../../../../../../utils/formValidation";
import { selectProvidersDoctors, selectProvidersFacility } from "../../../../../../store/selector";
import { OTHER, ProviderTypes } from "../../../constants";
import { getFieldsToUpdateOnProviderChange } from "../../../utils/provider";
import { sizes } from "../../../../../../constant/styles";
import { buildFieldValueMap } from "../../../../../../utils/form";

const { Option } = Select;

const STATE = "state";

const facilitySectionName = "facility";
const providerSectionName = "provider";

const formToFacilityFieldMap = [
  [practiceFields.facilityName, "fullName"],
  [practiceFields.address, "address"],
  [practiceFields.city, "city"],
  [practiceFields.state, "state"],
  [practiceFields.zip, "zipCode"],
  [practiceFields.facilityPhone, "phone"],
  [practiceFields.facilityFax, "fax"]
];

const Practice = () => {
  const { t } = useTranslation();
  const facilities = useSelector(selectProvidersFacility);
  const physicians = useSelector(selectProvidersDoctors);

  const form = Form.useFormInstance();
  const facility = Form.useWatch(practiceFields.facilityId, form);

  const [isFacilitySelectOpen, setIsFacilitySelectOpen] = useState(false);

  useEffect(() => {
    form.validateFields();
  }, [facility]);

  const onProviderChange = (providerId, formFieldMap, providerType, additionalFieldsToUpdate) => {
    let providers;
    let formSectionName;
    if (providerType === ProviderTypes.Facility) {
      providers = facilities;
      formSectionName = facilitySectionName;
    } else {
      providers = physicians;
      formSectionName = providerSectionName;
    }

    let fieldsToUpdate = getFieldsToUpdateOnProviderChange(
      providers,
      providerId,
      formFieldMap,
      additionalFieldsToUpdate
    );

    if (fieldsToUpdate[STATE]) fieldsToUpdate[STATE] = getStateCode(fieldsToUpdate[STATE]);

    form.setFieldsValue({ [formSectionName]: { ...fieldsToUpdate } });
  };

  const onFacilityChange = (facility) => onProviderChange(facility, formToFacilityFieldMap, ProviderTypes.Facility);

  const currentFacilityColSpan = facility === OTHER ? 8 : 0;
  const anotherFacilityColSpan = facility === OTHER ? 8 : 12;

  return (
    <>
      <Divider
        id="enrollment-form-practice-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.eagle.practice.labels.header")}</FormDividerText>
      </Divider>

      <Row gutter={14}>
        <Col span={anotherFacilityColSpan}>
          <FormItem
            rules={[{ required: true }]}
            name={practiceFields.facilityId}
            label={t("application_form_editor.eagle.practice.labels.practice_name")}
          >
            <SingleSelect
              useCommonStyle={false}
              listHeight={100}
              onDropdownVisibleChange={setIsFacilitySelectOpen}
              bordered={true}
              allowClear={true}
              placeholder={t("application_form_editor.eagle.practice.placeholders.practice_name")}
              getPopupContainer={(trigger) => trigger.parentNode}
              open={isFacilitySelectOpen}
              onChange={(facility) => onFacilityChange(facility)}
              onClear={() => onFacilityChange(null)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Button
                    id="EE_eagle_copay_other_facility_id"
                    type="text"
                    style={{ width: "100%", textAlign: "start", fontSize: sizes.medium, background: "transparent" }}
                    onClick={() => {
                      form.setFieldsValue(buildFieldValueMap([[practiceFields.facilityId, OTHER]]));
                      setIsFacilitySelectOpen(false);
                      onFacilityChange(null);
                    }}
                  >
                    {t("application_form_editor.eagle.practice.other")}
                  </Button>
                </>
              )}
            >
              {facilities?.map((p, index) => (
                <Option key={index} value={p.id}>
                  {p.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
        <Col span={currentFacilityColSpan}>
          <FormItem
            hidden={facility !== OTHER}
            name={practiceFields.facilityName}
            label={t("application_form_editor.eagle.practice.labels.practice_name")}
            rules={[{ required: facility === OTHER }]}
          >
            <Input placeholder={t("application_form_editor.eagle.practice.placeholders.practice_name")} />
          </FormItem>
        </Col>

        <Col span={anotherFacilityColSpan}>
          <FormItem
            name={practiceFields.facilityContact}
            label={t("application_form_editor.eagle.practice.labels.practice_contact")}
            rules={[{ required: true }]}
          >
            <Input placeholder={t("application_form_editor.eagle.practice.placeholders.practice_contact")} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={practiceFields.address}
            label={t("application_form_editor.eagle.practice.labels.address")}
          >
            <Input placeholder={t("application_form_editor.eagle.practice.placeholders.address")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            name={practiceFields.city}
            label={t("application_form_editor.eagle.practice.labels.city")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.eagle.practice.placeholders.city")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            name={practiceFields.state}
            label={t("application_form_editor.eagle.practice.labels.state")}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              placeholder={t("application_form_editor.eagle.practice.placeholders.state")}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {Object.values(States).map((state, index) => (
                <Option key={index} value={state.code}>
                  {state.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            rules={[{ required: true }, ...zipInputRules]}
            name={practiceFields.zip}
            label={t("application_form_editor.eagle.practice.labels.zip_code")}
          >
            <Input
              maxLength={5}
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.eagle.practice.placeholders.zip_code")}
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
            name={practiceFields.facilityPhone}
            label={t("application_form_editor.eagle.practice.labels.practice_phone")}
          >
            <InputPhone placeholder={t("application_form_editor.eagle.practice.placeholders.practice_phone")} />
          </FormItem>
        </Col>

        <Col span={6}>
          <FormItem
            rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
            name={practiceFields.facilityFax}
            label={t("application_form_editor.eagle.practice.labels.practice_fax")}
          >
            <InputPhone placeholder={t("application_form_editor.eagle.practice.placeholders.practice_fax")} />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            rules={emailInputRules}
            name={practiceFields.email}
            label={t("application_form_editor.eagle.practice.labels.email")}
          >
            <Input placeholder={t("application_form_editor.eagle.practice.placeholders.email")} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default Practice;
