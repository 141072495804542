import { WHITE, DARK_GRAY2, DARK_GRAY3, RED_DARK } from "../../constant/colors";

export const ModalStyle = `
.ant-modal-content{
    border-radius: 12px;
    .ant-btn-default{
        background-color: ${WHITE};
        border: 1px solid ${DARK_GRAY2};
        box-shadow: 0px 1px 2px 0px #1018280d;
    }
    .ant-btn-primary{
        background-color: ${RED_DARK};
        border: 1px solid ${RED_DARK};
        box-shadow: 0px 1px 2px 0px #1018280d;
    }
    .title {
        font-weight: 600;
        font-size: 18px;
    }
    .content {
        color: ${DARK_GRAY3};
    }
    .ant-btn {
        padding: 10px 16px 10px 16px;
        height: auto;
    }      
}
.ant-modal-footer{
    border: 0;
}
.ant-modal-mask {
    background: #00000099;
  }
`;
