import { takeLatest, select, fork, put, call } from "redux-saga/effects";
import ErrorMessage from "../../component/customComponent/customMessages/ErrorMessage";

import ACTION from "../action";
import { ApiNames } from "../../api/api";
import { selectUser, selectIDPConfig, selectOktaClientId } from "../selector";
import { callApi } from "./networkActions";
import { getAuthInstance, getOktaClientId, logout } from "@tailormed/common-client/services/auth";
import { oktaConfig } from "../../constant/okta";

function* getIDPConfig() {
  const idpConfigRes = yield call(callApi, ApiNames.getIDPConfig);
  if (idpConfigRes) {
    yield put(ACTION.setIDPConfig(idpConfigRes.config));
  } else {
    // Note: fallback to disableing the IDP.
    yield put(ACTION.setIDPConfig({ IDPEnabled: false }));
  }
}

function* getOktaConfig() {
  const oktaClientId = yield getOktaClientId();
  if (!oktaClientId) {
    console.log("Missing Okta Client ID");
    ErrorMessage("Server Error, Please contact support.");
  }
  yield put(ACTION.setOktaConfig(oktaClientId));
}

function* handleIDPFlow() {
  const idpConfig = yield select(selectIDPConfig);
  const user = yield select(selectUser);
  const oktaClientId = yield select(selectOktaClientId);
  const oktaAuth = getAuthInstance(oktaConfig.redirectUri, oktaClientId);

  if (idpConfig && idpConfig.IDPEnabled) {
    try {
      const session = yield call(oktaAuth.session.exists);
      if (session) {
        const response = yield call(oktaAuth.token.getWithoutPrompt);
        oktaAuth.tokenManager.setTokens(response.tokens);
      } else {
        if (user) {
          // Note: in case the session was killed from our Okta, need to logged the user out.
          yield call(logout, user, idpConfig, oktaConfig.loginURI);
        }
      }
    } catch (error) {
      console.error("failure in IDP flow", error);
    }
  }
}

function* getUser() {
  const loggedUserRes = yield callApi(ApiNames.LoggedUser);
  if (loggedUserRes && loggedUserRes.data) {
    const { data } = loggedUserRes;
    const { phiAllowed, siteLicense, users } = data;
    yield put(ACTION.setIsPhiAllowed(phiAllowed));
    yield put(ACTION.setEnvironment(siteLicense.siteName));
    yield put(ACTION.setTier(siteLicense.tier));
    yield put(ACTION.setUser(users[0]));
  } else {
    ErrorMessage(`User is not allowed`);
    const idpConfig = yield select(selectIDPConfig);
    const user = yield select(selectUser);
    yield call(logout, user, idpConfig, oktaConfig.loginURI);
  }
}

function* watchIDPActions() {
  yield takeLatest([ACTION.Types.SET_IDP_CONFIG, ACTION.Types.SET_USER], handleIDPFlow);
  yield fork(getIDPConfig);
}

function* watchOktaActions() {
  yield fork(getOktaConfig);
}

export { watchOktaActions, watchIDPActions, getUser };
