export const filtersTypes = { all: "all", private: "private", starred: "starred" };
export const filtersTypesOptions = [
  {
    index: 1,
    key: filtersTypes.all,
    id: "saved_lists_all_lists_view",
    value: (t) => t("savedList.types_options.all")
  },
  {
    index: 2,
    key: filtersTypes.private,
    id: "saved_lists__private_lists_view",
    value: (t) => t("savedList.types_options.private")
  },
  {
    index: 3,
    key: filtersTypes.starred,
    id: "saved_lists_favorite_lists_view",
    value: (t) => t("savedList.types_options.favorite")
  }
];
