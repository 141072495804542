import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_400 } from "../../../constant/colors";

const Label = styled.label`
  color: ${GRAY_400};

  &.label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 2px;
    top: 4px;
    transition: 0.2s ease all;
  }

  &.label-float {
    top: 0;
    font-size: 10px !important;
  }
`;

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value } = props;

  const labelClass =
    focus || (value && value.length !== 0) || value === false || value === 0 ? "label label-float" : "label";

  return (
    <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)} {...props}>
      {children}
      <Label className={labelClass} style={{ color: "#979797" }}>
        {label}
      </Label>
    </div>
  );
};

export default FloatLabel;
