import React from "react";

export default function Female(props) {
  return (
    <svg width={38} height={38} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.5 0C7.664 0 0.5 7.164 0.5 16C0.5 24.836 7.664 32 16.5 32C25.336 32 32.5 24.836 32.5 16C32.5 7.164 25.336 0 16.5 0ZM16.5 29.3333C11.6173 29.3333 8.5 26.6667 8.5 26.6667C8.5 22.5 13.964 23.188 14.8333 20.8347V20C11.6853 20 9.90533 18.4293 9.90533 18.4293C11.5507 17.06 9.272 7.536 14.8747 7.536C14.8747 7.536 15.5227 6.66667 16.5 6.66667C23.348 6.66667 21.1987 17.008 22.9707 18.4613C22.9707 18.4613 21.3307 20 18.1667 20V20.8347C19.036 23.188 24.5 22.5 24.5 26.6667C24.5 26.6667 21.3827 29.3333 16.5 29.3333Z"
        fill="#93307D"
      />
    </svg>
  );
}
