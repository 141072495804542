import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Select, Space, Tooltip, Typography } from "antd";

import { selectAssigneesAndUserGroups, selectPatientDetails } from "../../../store/selector";
import ErrorMessage from "../../customComponent/customMessages/ErrorMessage";
import SuccessMessage from "../../customComponent/customMessages/SuccessMessage";
import { updatePatientAssigneesAPI } from "../../../api/api";
import ACTIONS from "../../../store/action";
import { GRAY_400, TEXT4, WHITE } from "../../../constant/colors";
import { formatAssignee } from "../../../utils/formaters";
import { fontWeights, sizes } from "../../../constant/styles";

const { Option } = Select;
const { Text } = Typography;

const PatientAssignees = () => {
  const [isSelectHover, setIsSelectHover] = useState(false);
  const filterInput = useRef("");
  const [isFetching, setIsFetching] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [patientAssignees, setPatientAssignees] = useState([]);
  const { patientId } = useParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const patientDetails = useSelector(selectPatientDetails);
  const assignees = useSelector(selectAssigneesAndUserGroups);

  useEffect(() => {
    if (!assignees || !Object.keys(assignees).length || !patientDetails?.assignees?.length) return;

    const patientAssignees = patientDetails.assignees
      .map(({ assignee }) => {
        const chosenAssignee = Object.values(assignees).find(({ id }) => id && id.toString() === assignee.toString());
        if (chosenAssignee) return chosenAssignee;
      })
      .sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
      });

    setPatientAssignees(patientAssignees.filter((item) => item));
  }, [patientDetails, assignees]);

  const updateAssigneeHandler = async (values) => {
    try {
      setIsFetching(true);
      const newAssignees = values.map((value) => {
        const selectedAssignee = assignees.find(({ id }) => id && id.toString() === value.toString());
        if (selectedAssignee) {
          selectedAssignee.assignee = value;
          return selectedAssignee;
        }
      });

      const updateAssigneeRes = await updatePatientAssigneesAPI(patientId, newAssignees);

      if (updateAssigneeRes?.data?.success) {
        setPatientAssignees(newAssignees);
        SuccessMessage(t("successMessages.updated_assignees_successfully"));
        dispatch(ACTIONS.updatePatientDetailsAction({ ...patientDetails, assignees: newAssignees }));
      } else {
        ErrorMessage(t("errorMessages.failed_to_update_assignees"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.failed_to_update_assignees"));
    } finally {
      setIsFetching(false);
    }
  };

  const updateFilterInput = (inputValue) => (filterInput.current = inputValue);

  const assigneesIds = patientAssignees?.map(({ id }) => id) || [];
  const assigneesTextToDisplay = patientAssignees?.map((assignee) => formatAssignee(assignee)).join(" | ") || "";

  const compareAssignees = (a, b) => {
    const aIsSelected = assigneesIds.indexOf(a.value) > -1;
    const bIsSelected = assigneesIds.indexOf(b.value) > -1;
    if ((aIsSelected && bIsSelected) || (!aIsSelected && !bIsSelected)) {
      return a.children.localeCompare(b.children);
    }
    if (aIsSelected && !bIsSelected) {
      return -1;
    }
    if (!aIsSelected && bIsSelected) {
      return 1;
    }
  };

  return (
    <Space direction="vertical" size={0} style={{ width: "100%" }}>
      <Text style={{ fontSize: sizes.small, color: TEXT4, fontWeight: fontWeights.semibold, paddingLeft: 12 }}>
        {t("patientTopSection.assignees")}
      </Text>
      <Tooltip
        open={assigneesTextToDisplay.length && !isSelectOpen && isSelectHover}
        title={<Text>{assigneesTextToDisplay}</Text>}
        color={WHITE}
      >
        <div id="patient_header_assignees">
          <Select
            mode="multiple"
            showArrow={isSelectHover || isFetching}
            allowClear={false}
            loading={isFetching}
            bordered={false}
            maxTagCount={1}
            filterOption={(inputValue, option) => {
              updateFilterInput(inputValue?.toLowerCase());
              return option.displayName?.toLowerCase()?.includes(inputValue?.toLowerCase());
            }}
            onDropdownVisibleChange={(open) => setIsSelectOpen(open)}
            filterSort={compareAssignees}
            maxTagTextLength={patientAssignees.length > 1 ? 8 : 16}
            onChange={(value) => updateAssigneeHandler(value)}
            placeholder={<Text style={{ color: GRAY_400, fontWeight: 400 }}>{t("no_assignees")}</Text>}
            value={patientAssignees.map(({ id }) => id)}
            dropdownMatchSelectWidth={false}
            onMouseEnter={() => setIsSelectHover(true)}
            onMouseLeave={() => setIsSelectHover(false)}
          >
            {Object.values(assignees).map((assignee, index) => (
              <Option key={index} value={assignee.id} displayName={formatAssignee(assignee)} disabled={isFetching}>
                {formatAssignee(assignee)}
              </Option>
            ))}
          </Select>
        </div>
      </Tooltip>
    </Space>
  );
};

export { PatientAssignees };
