import React from "react";
import { SEC_FONT_GREY } from "../../constant/colors";
import { translatePlanMarket, PLAN_MARKETS } from "../../constant/planMarkets";
import { UNSPECIFIED_PAYER_NAME } from "../../constant/coverages";
import { useGetSetting } from "../../hooks/getSetting";
import { Typography } from "antd";
const { Text } = Typography;

export const PatientStatusCell = ({ value }) => {
  const [statuses] = useGetSetting(["statuses"]);
  const status = statuses.find((status) => status.value === value);
  const textualValue = status ? status.text : "";

  return <Text>{textualValue} </Text>;
};

export const patientInsuranceCell = (insuranceType) => (_, record) => {
  const planMarket = record[`${insuranceType}InsurancePlanMarket`] || "";
  const payer = planMarket === PLAN_MARKETS.Uninsured ? "" : record[`${insuranceType}InsurancePayerName`] || "";

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="primary-insurance-payer">{payer === UNSPECIFIED_PAYER_NAME ? "--" : payer}</div>
      <div style={{ color: SEC_FONT_GREY }}>{planMarket && translatePlanMarket(planMarket)}</div>
    </div>
  );
};
