import React from "react";
import { Button, Typography } from "antd";
import styled from "styled-components";
import {
  PRIMARY_BLUE,
  LIGHT_BLUE_2,
  PRIMARY_600,
  PRIMARY_800,
  PRIMARY_400,
  GREEN_DARK3,
  WHITE
} from "../../constant/colors";
const { Text } = Typography;

const OutlinedButton = styled(Button)`
  border-radius: 4px;
`;

const AntdPrimaryButton = (props) => <Button type="primary" {...props} />;
const AntdTextButton = (props) => (
  <Button className="icon-button" type="text" {...props}>
    <Text className="text">{props.text}</Text>
  </Button>
);

const PrimaryButton = styled(AntdPrimaryButton)`
  color: ${WHITE} !important;
  border-radius: 4px;
  background: ${PRIMARY_600};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid ${GREEN_DARK3};

  :disabled,
  :hover:disabled {
    background: ${PRIMARY_600};
    opacity: 0.3;
    border: 1px solid ${PRIMARY_600};
  }

  :hover {
    background: ${PRIMARY_800};
    border: 1px solid ${PRIMARY_600};
  }

  :focus {
    background: ${PRIMARY_600};
    border: 1px solid ${PRIMARY_400};
  }
`;

const SecondaryButton = styled(AntdPrimaryButton)`
  border-radius: 4px;
  background-color: ${(props) => (props.disabled ? "#EFF8FF" : "#1570EF")};
  border: none;
`;

const ErrorButton = styled(AntdPrimaryButton)`
  border-radius: 4px;
  border: 1px solid #d92d20;
  background-color: #d92d20;
  color: #ffffff;
  :hover {
    background-color: #c02317;
  }
`;
const IconButton = styled(AntdTextButton)`
  font-weight: 600;
  padding: 8px, 16px, 8px, 16px;
  color: ${PRIMARY_BLUE};
  :hover {
    border-radius: 4px;
    color: ${PRIMARY_BLUE};
    background-color: ${LIGHT_BLUE_2};
  }
  .text {
    color: ${PRIMARY_BLUE};
    font-weight: 600;
    padding-left: 4px;
  }
`;

export { OutlinedButton, PrimaryButton, SecondaryButton, ErrorButton, IconButton };
