import { Button, Space, Table, Typography, Image, Spin, Select, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { debounce } from "lodash";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useParams, withRouter } from "react-router";
import { ArrowLeftOutlined, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useTranslation, withTranslation } from "react-i18next";
import { displayedMRN } from "@tailormed/common-client/util/mrn";

import {
  ApiNames,
  getNotificationDataAPI,
  getTopOpportunityPatientsAPI,
  searchDiagnosisIcdsAPI,
  searchDiagnosisNamesAPI
} from "./../api/api";
import { Routes } from "../constant/routes";
import { isEmpty } from "../utils/string";
import { dateFormatter, DATE_FORMAT } from "../utils/date";
import { PAGE_SIZE, selectedViews } from "../constant/table";
import { AssigneeColumn, EditColumn, StarColumn } from "./TableSection/CommonColumns";
import { BLACK, GRAY, GRAY_400, GRAY_700, SEC_FONT_GREY } from "../constant/colors";
import { ShorterTextWithTooltip } from "./ShorterText";
import ErrorMessage from "./customComponent/customMessages/ErrorMessage";

import { ReactComponent as NoPatientsFoundIcon } from "../assets/svg/NoPatientsFoundIcon.svg";
import {
  selectAssignees,
  selectProvidersDoctors,
  selectProvidersFacility,
  selectTableColumns,
  selectRelevantPatientData,
  selectUser,
  selectedViewTableData,
  selectIsFetching
} from "../store/selector";
import styled from "styled-components";
import { patientInsuranceCell, PatientStatusCell } from "./TableCells";
import { ColumnSelector } from "../component/ColumnSelector";
import ACTIONS from "../store/action";
import { SUPPORTED_COVERAGES } from "../constant/patient";
import PatientPageCount from "../component/TableSection/PatientPageCount";
import { commonTableStyle } from "../component/TableSection/TableStyle";
import moment from "moment-timezone";
import { formatAssignee, formatPhoneNumber, formatSSN } from "../utils/formaters";
import { DeceasedLable } from "./DeceasedLable";
import { useGetSetting } from "../hooks/getSetting";
import { MultiSelect } from "./customComponent/CustomSelect";
import { fontWeights } from "../constant/styles";
import { desiredOrderOfStatuses, SORT_DIRECTIONS_DEFAULT } from "../constant/sort";
import { getSortingChanges, sortListByOrder } from "../utils/sort";
import { TitledTooltip } from "./TitledTooltip";
import { defaultRelevantPatientsFilters } from "../constant/filters";

const { Text } = Typography;
const { Option } = Select;
const OOP_MET_STATUS = "oop-met";
const RELEVANT_PATIENTS_TABLE_CONFIG = "RelevantPatientsTableConfig";

const CentralizedText = styled.div`
  text-align: center;
`;

const StyledTable = styled(Table)`
  ${commonTableStyle}
  .ant-table {
    @media (min-width: 1600px) {
      height: 70vh;
    }
    @media (max-width: 1600px) {
      height: 60vh;
    }
  }
`;

const TopOpportunitiesComponent = ({
  t,
  history,
  assignees,
  facilities,
  doctors,
  setSelectedView,
  tableColumns,
  setTableDataAct,
  tableData,
  clearSelectedView
}) => {
  const user = useSelector(selectUser);
  const isFetchingProviders = useSelector(selectIsFetching([ApiNames.Providers]));
  const isFetchingAssignees = useSelector(selectIsFetching([ApiNames.Assignees]));

  const saveRelevantPatientsTableConfigToLocalStorage = (filters) => {
    localStorage.setItem(RELEVANT_PATIENTS_TABLE_CONFIG, JSON.stringify(filters));
  };

  const getRelevantPatientsTableConfigFromLocalStorage = () => {
    const relevantPatientsTableConfig = localStorage.getItem(RELEVANT_PATIENTS_TABLE_CONFIG);

    const defaultRelevantPatientsTableConfig = {
      id: 0,
      filters: defaultRelevantPatientsFilters,
      sorter: {},
      currentPage: 1
    };

    if (!relevantPatientsTableConfig) {
      localStorage.setItem(RELEVANT_PATIENTS_TABLE_CONFIG, JSON.stringify(defaultRelevantPatientsTableConfig));
      return defaultRelevantPatientsTableConfig;
    }

    return JSON.parse(relevantPatientsTableConfig);
  };

  const relevantPatientsTableConfig = getRelevantPatientsTableConfigFromLocalStorage();
  const relevantPatientsSorter = relevantPatientsTableConfig?.sorter || {};
  const relevantPatientsFilters = relevantPatientsTableConfig?.filters || {};
  const relevantPatientsCurrentPage = relevantPatientsTableConfig?.currentPage || {};
  const relevantPatientsId = relevantPatientsTableConfig?.id && parseInt(relevantPatientsTableConfig.id);

  const [isFetching, setIsFetching] = useState([]);
  const [isFetchingDiagnosisIcds, setIsFetchingDiagnosisIcds] = useState(false);
  const [isFetchingDiagnosis, setIsFetchingDiagnosis] = useState(false);
  const [diagnosisSearchResults, setDiagnosisSearchResults] = useState([]);
  const [hoveringLineIndex, setHoveringLineIndex] = useState(null);

  const [diagnosisIcdsSearchResults, setDiagnosisIcdsSearchResults] = useState([]);
  const { notificationId } = useParams();
  const [notification, setNotification] = useState(null);
  const [filteredStatuses, setFilteredStatuses] = useState([]);
  const [statusSearchInput, setStatusSearchInput] = useState("");

  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;
  const isAssigneesEmpty = assignees.length === 0;

  const [statuses] = useGetSetting(["statuses"]);

  useEffect(() => {
    if (!statuses.length) {
      setFilteredStatuses([]);
      return;
    }
    const orderedStatuses = sortListByOrder(statuses, desiredOrderOfStatuses, "value");
    if (JSON.stringify(filteredStatuses) !== JSON.stringify(orderedStatuses)) {
      setFilteredStatuses(orderedStatuses);
    }
  }, [filteredStatuses]);

  const onDiagnosisIcdsSearch = async (text) => {
    if (isEmpty(text)) return;
    try {
      setIsFetchingDiagnosisIcds(true);
      const result = await searchDiagnosisIcdsAPI(text);
      if (result && result.data && result.data.data) {
        const { data } = result.data;
        setDiagnosisIcdsSearchResults(data);
      } else {
        ErrorMessage(t("errorMessages.dignosis_icds"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.dignosis_icds"));
    } finally {
      setIsFetchingDiagnosisIcds(false);
    }
  };

  const onDiagnosisSearch = async (text) => {
    if (isEmpty(text)) return;
    try {
      setIsFetchingDiagnosis(true);
      const result = await searchDiagnosisNamesAPI(text);
      if (result && result.data && result.data.data) {
        const { data } = result;
        setDiagnosisSearchResults(data.data);
      } else {
        ErrorMessage(t("errorMessages.diagnosis_names"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.diagnosis_names"));
    } finally {
      setIsFetchingDiagnosis(false);
    }
  };

  useEffect(() => {
    setSelectedView(selectedViews.topOpportunity);
  }, []);

  useEffect(() => {
    if (
      tableData?.patients?.length === 0 &&
      tableData?.totalPatients % PAGE_SIZE === 0 &&
      relevantPatientsCurrentPage > 1
    ) {
      fetchTopOpportunityPatients(null, null, relevantPatientsSorter, { current: relevantPatientsCurrentPage - 1 });
    }
  }, [tableData]);

  useEffect(() => {
    const fetchData = async () => {
      const notificationDate = await fetchNotificationData();

      if (notificationDate) {
        const assistanceProgramId = notificationDate.assistanceProgram.id;
        //Note: Reset the page number to 1 when navigating to a different relevant patient
        const shouldResetPage = relevantPatientsId !== 0 && relevantPatientsId !== parseInt(notificationId);

        const newFilters = {
          ...relevantPatientsFilters,
          assistancePrograms: [{ value: assistanceProgramId }]
        };

        const currentPage = shouldResetPage ? 1 : relevantPatientsCurrentPage;

        saveRelevantPatientsTableConfigToLocalStorage({
          ...relevantPatientsTableConfig,
          currentPage,
          filters: newFilters
        });

        await fetchTopOpportunityPatients(assistanceProgramId, null, relevantPatientsSorter, {
          current: currentPage
        });
      }
    };

    fetchData();
  }, [notificationId]);

  const fetchNotificationData = async () => {
    let notificationData = null;

    if (history.location && history.location.state && history.location.state.notification) {
      notificationData = history.location.state.notification;
    } else {
      try {
        setIsFetching(true);
        const notificationDataRes = await getNotificationDataAPI(notificationId);
        if (notificationDataRes && notificationDataRes.data) {
          notificationData = notificationDataRes.data;
        } else {
          ErrorMessage(t("top_opportunities.failed_to_get_notification_data"));
        }
      } catch (error) {
        ErrorMessage(t("top_opportunities.failed_to_get_notification_data"));
      } finally {
        setIsFetching(false);
      }
    }

    if (notificationData) {
      setNotification(notificationData);
    }

    return notificationData;
  };

  const fetchData = (pagination, filters, sorter) => {
    const { current: nextPage } = pagination;

    if (nextPage === relevantPatientsCurrentPage) {
      const { columnIsChanged, sortDirectionIsChanged, nextColumnName, nextSortBy } = getSortingChanges(
        relevantPatientsSorter,
        sorter
      );

      if (columnIsChanged || sortDirectionIsChanged) {
        saveRelevantPatientsTableConfigToLocalStorage({
          ...relevantPatientsTableConfig,
          sorter: { ...sorter, columnKey: nextColumnName, order: nextSortBy },
          currentPage: relevantPatientsCurrentPage === 1 ? pagination.current : 1
        });
        fetchTopOpportunityPatients(
          null,
          null,
          sorter,
          relevantPatientsCurrentPage === 1 ? pagination : { current: 1 }
        );
      }
    } else {
      fetchTopOpportunityPatients(null, null, sorter, pagination);
    }
    window.scrollTo(0, 0);
  };

  const fetchTopOpportunityPatients = async (
    assistanceProgramId,
    filters,
    sorter = relevantPatientsSorter,
    pagination
  ) => {
    const finalSorter = sorter.column ? sorter : {};
    const parsedFilters = filters && _.omitBy(filters, _.isNull);
    const filtersForAPIRequest = parsedFilters
      ? parsedFilters
      : {
          ..._.omitBy(relevantPatientsFilters, _.isNull),
          assistancePrograms: [
            { value: assistanceProgramId ? assistanceProgramId : notification?.assistanceProgram.id }
          ]
        };
    setIsFetching(true);
    try {
      const parsedFilters = {
        multiples: filtersForAPIRequest
      };

      const topOpportunityPatientsRes = await getTopOpportunityPatientsAPI(
        parsedFilters,
        finalSorter,
        pagination?.current
      );
      if (topOpportunityPatientsRes && topOpportunityPatientsRes.data && topOpportunityPatientsRes.data.patients) {
        setTableDataAct(topOpportunityPatientsRes.data, finalSorter);
      } else {
        ErrorMessage(t("errorMessages.top_opportunity_patients"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.top_opportunity_patients"));
    } finally {
      setIsFetching(false);
    }
  };

  const onClickTable = () => ({
    onCell: (patient) => ({
      onClick: () => {
        const { patientId, journeyId } = patient;
        history.push(Routes.ROOT + `patient/${patientId}/journey/${journeyId}`);
      }
    })
  });

  const handleFetchFilter = (filters, filterName) => {
    const currentFilter = relevantPatientsTableConfig.filters[filterName];
    const newFilter = filters[filterName];

    if (JSON.stringify(currentFilter) !== JSON.stringify(newFilter)) {
      fetchTopOpportunityPatients(null, filters);
      saveRelevantPatientsTableConfigToLocalStorage({
        ...relevantPatientsTableConfig,
        filters: {
          ...relevantPatientsTableConfig.filters,
          [filterName]: newFilter
        },
        currentPage: 1
      });
    }
  };

  const handleSelectStatuses = (values, options) => {
    let filters;

    const newStatusesValues = options.map((option) => ({
      value: option.value,
      children: option.children
    }));

    if (options.length > 0)
      filters = {
        ...relevantPatientsFilters,
        statuses: newStatusesValues
      };
    else {
      filters = { ...relevantPatientsFilters, statuses: [] };
    }

    handleFetchFilter(filters, "statuses");
  };

  const withSorting = (sortingEnabled, tableColumn, rest, sortDirection) => {
    const [threshold] = useGetSetting(["dashboard.sorting-threshold"]);
    const { t } = useTranslation();
    const selectedViewData = useSelector(selectedViewTableData);
    const { sorter } = selectedViewData;
    const sortingTooltipTitle = threshold && t("sortInfo", { threshold: threshold / 1000 });

    return {
      sorter: sortingEnabled,
      sortDirections: sortDirection ? sortDirection : SORT_DIRECTIONS_DEFAULT,
      ...tableColumn,
      title: () => (
        <div style={{ display: "flex", gap: "5px" }} id={`columnـ${tableColumn.dataIndex}`}>
          <Text style={{ color: GRAY_700 }}>{tableColumn.title}</Text>
          <div>
            {!sortingEnabled && (
              <TitledTooltip text={sortingTooltipTitle}>
                <ExclamationCircleOutlined style={{ fontSize: "12px", alignSelf: "center", color: GRAY_400 }} />
              </TitledTooltip>
            )}
          </div>
        </div>
      ),
      ...rest,
      sortOrder: sorter?.column && sorter?.column?.key === tableColumn.key && sorter?.order
    };
  };

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.topOpportunity]?.star?.dataIndex,
      key: tableColumns[selectedViews.topOpportunity]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.name_id, {
      showSorterTooltip: {
        title: t("columns.sortById")
      },
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Text style={{ fontWeight: "600" }}>{record.name ? record.name : "Patient"}</Text>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text>{t("top_opportunities.tm_patient", { patientID: record.patientId })}</Text>
            <Text style={{ color: SEC_FONT_GREY }}>
              {t("top_opportunities.record_mrn", { mrnRecord: displayedMRN(record.mrn) })}
            </Text>
          </div>
        </div>
      ),
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => (record.isDeceased ? <DeceasedLable /> : ""),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.topOpportunity]?.phone_number,
      render: (text, record) => formatPhoneNumber(record.phone_number || record.home_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.dob,
      render: (text, record) => {
        if (!record.dob || record.dob === t("invalid_date")) {
          return "";
        } else {
          const dob = moment(record.dob);
          if (dob.isValid()) {
            return dob.format(DATE_FORMAT);
          } else {
            return "";
          }
        }
      },
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.ssn,
      render: (text, record) => formatSSN(record.social_security_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.primaryDiagnosis,
      dataIndex: "diagnosises",
      render: (text, record) => (record.diagnosises[0] ? record.diagnosises[0] : null),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.assignee,
      render: (text, record) => <AssigneeColumn record={record} />,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.facility, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.physician, {
      ...onClickTable()
    }),

    {
      ...tableColumns[selectedViews.topOpportunity]?.primaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.primary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.secondaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.secondary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.topOpportunity]?.tertiaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.tertiary),
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.applicationStatus, {
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.topOpportunity]?.agreementSignatureDate,
      render: (text, record) =>
        record.agreement_signature_date && (
          <CentralizedText>{dateFormatter(record.agreement_signature_date)}</CentralizedText>
        )
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.patientStatus, {
      render: (_, record) => <PatientStatusCell value={record.status} />,
      ...onClickTable()
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.followupDate, {
      render: (text, record) =>
        record.followupDate && <CentralizedText>{dateFormatter(record.followupDate)}</CentralizedText>
    }),
    {
      dataIndex: tableColumns[selectedViews.topOpportunity]?.edit?.dataIndex,
      render: (_, record, rowIndex) => <EditColumn isHovering={hoveringLineIndex === rowIndex} record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.topOpportunity]?.creationDate, {
      render: (text, record) => moment(record.creationDate).format("MMM D, YYYY"),
      ...onClickTable()
    })
  ];

  return (
    <div>
      <div style={{ backgroundColor: "#f5f5f5", paddingTop: 16 }}>
        <div
          style={{
            width: "100%",
            maxWidth: "1184px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Space style={{ width: "100%" }}>
            <div>
              <Button
                style={{
                  backgroundColor: "#f5f5f5",
                  border: "none",
                  paddingLeft: 0,
                  paddingBottom: 10,
                  color: GRAY
                }}
                onClick={() => {
                  clearSelectedView();
                  history.push(Routes.ROOT);
                }}
              >
                <ArrowLeftOutlined />
                {t("top_opportunities.back")}
              </Button>
              <Text
                style={{
                  display: "block",
                  paddingBottom: 10,
                  fontSize: 20,
                  fontWeight: "bold"
                }}
              >
                {notification &&
                  t("top_opportunities.relevant_patients_for_assistance_program_name", {
                    assistanceProgramName: notification.assistanceProgram.name
                  })}
              </Text>
              <Text style={{ display: "block", color: GRAY, paddingBottom: 15 }}>
                {notification &&
                  t("top_opportunities.program_status_time", {
                    status: notification.assistanceProgram.status,
                    time: dateFormatter(notification.createdAt)
                  })}
              </Text>
            </div>
          </Space>
          <Space>
            <Image
              preview={false}
              style={{ minWidth: 100, maxWidth: 300 }}
              src={
                notification && notification.assistanceProgram && notification.assistanceProgram.logo ? (
                  notification.assistanceProgram.logo
                ) : (
                  <Skeleton.Image />
                )
              }
            />
          </Space>
        </div>
      </div>
      <div style={{ width: "100%", margin: "10px auto", maxWidth: "1160px" }}>
        <Space>
          <Text style={{ alignSelf: "center" }}>{t("top_opportunities.show_patients_from")}:</Text>
          <MultiSelect
            id="filter_all_statuses"
            placeholder={<Text>{t("top_opportunities.filter_strip.all_statuses")}</Text>}
            onChange={(values, options) => handleSelectStatuses(values, options)}
            value={relevantPatientsFilters?.statuses}
            autoClearSearchValue={false}
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={relevantPatientsFilters?.statuses?.length > 1 ? 1 : 10}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ minWidth: "190px" }}
            bordered={true}
            style={{ fontWeight: fontWeights.bold, width: 150, borderBottom: "0px" }}
            clearAll={true}
            selectAll={true}
            onClearAll={() => {
              handleSelectStatuses([], []);
              setStatusSearchInput("");
            }}
            filterOption={(input, option) => {
              setStatusSearchInput(input.toLowerCase());
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onSelectAll={() => {
              const newFilteredStatuses = filteredStatuses.filter(
                (d) =>
                  d.text?.toLowerCase().includes(statusSearchInput) &&
                  d.value.toLowerCase() != OOP_MET_STATUS.toLowerCase()
              );
              const values = newFilteredStatuses.map(({ value }) => value);
              const options = newFilteredStatuses.map(({ value, text }, index) => ({
                value: value,
                key: index,
                children: text
              }));

              handleSelectStatuses(values, options);
            }}
          >
            {filteredStatuses?.length &&
              filteredStatuses
                .filter(
                  (status) =>
                    (!status.roleRequirement || status.roleRequirement == user?.role) &&
                    status.value.toLowerCase() != OOP_MET_STATUS.toLowerCase()
                )
                .map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.text}
                  </Option>
                ))}
          </MultiSelect>

          <Select
            placeholder={<Text style={{ divor: BLACK }}>{t("top_opportunities.filter_strip.all_assignees")}</Text>}
            value={!isAssigneesEmpty && relevantPatientsFilters?.assignees}
            onSelect={(value) => {
              const chosenAssignee = _.find(assignees, ({ id }) => id == value);
              let filters;
              if (chosenAssignee) {
                filters = {
                  ...relevantPatientsFilters,
                  assignees: [{ value: chosenAssignee.id, text: `${formatAssignee(chosenAssignee)}` }]
                };
              } else {
                filters = { ...relevantPatientsFilters, assignees: null };
              }
              handleFetchFilter(filters, "assignees");
            }}
            dropdownMatchSelectWidth={false}
            style={{ fontWeight: "bold", width: 150 }}
            loading={isFetchingAssignees}
            suffixIcon={isFetchingAssignees ? <Spin size={"small"} indicator={<LoadingOutlined spin />} /> : <></>}
          >
            <Option>{t("top_opportunities.filter_strip.all_assignees")}</Option>
            {Object.values(assignees)
              .sort((a, b) => formatAssignee(a).localeCompare(formatAssignee(b)))
              .map((assignee) => (
                <Option key={assignee.id} value={assignee.id}>
                  <Text>{formatAssignee(assignee)}</Text>
                </Option>
              ))}
          </Select>
          <Select
            placeholder={<Text style={{ color: BLACK }}>{t("top_opportunities.filter_strip.all_facilities")}</Text>}
            value={facilities?.length > 0 && relevantPatientsFilters?.facilities}
            onSelect={(facilityId) => {
              const chosenFacility = _.find(facilities, ({ id }) => id == facilityId);
              let filters;
              if (chosenFacility) {
                filters = {
                  ...relevantPatientsFilters,
                  facilities: [{ value: chosenFacility.id, text: chosenFacility.name }]
                };
              } else {
                filters = { ...relevantPatientsFilters, facilities: null };
              }

              handleFetchFilter(filters, "facilities");
            }}
            dropdownMatchSelectWidth={false}
            style={{ fontWeight: "bold", width: 150 }}
            loading={isFetchingProviders}
            suffixIcon={isFetchingProviders ? <Spin size={"small"} indicator={<LoadingOutlined spin />} /> : <></>}
          >
            <Option>{t("top_opportunities.filter_strip.all_facilities")}</Option>
            {facilities &&
              facilities.map((facility) => (
                <Option key={facility.id} value={facility.id}>
                  {facility.name}
                </Option>
              ))}
          </Select>
          <Select
            placeholder={<Text style={{ color: BLACK }}>{t("top_opportunities.filter_strip.all_physicians")}</Text>}
            style={{ fontWeight: "bold", width: 150 }}
            value={doctors?.length > 0 && relevantPatientsFilters?.doctors}
            onSelect={(doctorId) => {
              const chosenDoctor = _.find(doctors, ({ id }) => id == doctorId);
              let filters;
              if (chosenDoctor) {
                filters = {
                  ...relevantPatientsFilters,
                  doctors: [{ value: chosenDoctor.id, text: chosenDoctor.name }]
                };
              } else {
                filters = { ...relevantPatientsFilters, doctors: null };
              }
              handleFetchFilter(filters, "doctors");
            }}
            dropdownMatchSelectWidth={false}
            loading={isFetchingProviders}
            suffixIcon={isFetchingProviders ? <Spin size={"small"} indicator={<LoadingOutlined spin />} /> : <></>}
          >
            <Option>{t("top_opportunities.filter_strip.all_physicians")}</Option>
            {doctors &&
              doctors.map((doctor) => (
                <Option key={doctor.id} value={doctor.id}>
                  {doctor.name}
                </Option>
              ))}
          </Select>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            defaultValue={relevantPatientsFilters?.diagnosisNames}
            value={relevantPatientsFilters?.diagnosisNames}
            allowClear
            onBlur={() => setDiagnosisSearchResults([])}
            onChange={(diagnosisName) => {
              let filters;
              if (diagnosisName) {
                filters = {
                  ...relevantPatientsFilters,
                  diagnosisNames: [{ value: diagnosisName, text: diagnosisName }]
                };
              } else {
                filters = { ...relevantPatientsFilters, diagnosisNames: null };
              }
              handleFetchFilter(filters, "diagnosisNames");
            }}
            onSearch={debounce(onDiagnosisSearch, 500)}
            filterOption={false}
            style={{ fontWeight: "bold", width: 150 }}
            loading={isFetchingDiagnosis}
            notFoundContent={isFetchingDiagnosis ? <Spin size={"small"} /> : t("not_found")}
          >
            <Option value={null}>{t("top_opportunities.filter_strip.all_diagnoses")}</Option>
            {diagnosisSearchResults.map((d, index) => (
              <Option style={{ width: 400, display: "flex", flexDirection: "column" }} key={index} value={d.text}>
                <ShorterTextWithTooltip strong={true} text={`${d.text}`} maxLength={50} />
              </Option>
            ))}
          </Select>
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            defaultValue={relevantPatientsFilters?.diagnosisCodeIDs}
            value={relevantPatientsFilters?.diagnosisCodeIDs}
            allowClear
            onBlur={() => setDiagnosisIcdsSearchResults([])}
            onChange={(diagnosisCodeID) => {
              let filters;
              if (diagnosisCodeID) {
                filters = {
                  ...relevantPatientsFilters,
                  diagnosisCodeIDs: [{ value: diagnosisCodeID, text: diagnosisCodeID }]
                };
              } else {
                filters = { ...relevantPatientsFilters, diagnosisCodeIDs: null };
              }

              handleFetchFilter(filters, "diagnosisCodeIDs");
            }}
            onSearch={debounce(onDiagnosisIcdsSearch, 500)}
            filterOption={false}
            style={{ fontWeight: "bold", width: 185 }}
            loading={isFetchingDiagnosisIcds}
            notFoundContent={isFetchingDiagnosisIcds ? <Spin size={"small"} /> : t("not_found")}
          >
            <Option value={null}>{t("top_opportunities.filter_strip.all_diagnoses_codes")}</Option>
            {diagnosisIcdsSearchResults &&
              diagnosisIcdsSearchResults.map((d, index) => (
                <Option key={index} value={d.text}>
                  {d.text}
                </Option>
              ))}
          </Select>
          <div style={{ position: "relative", top: 6 }}>
            <ColumnSelector />
          </div>
        </Space>
      </div>
      <StyledTable
        size="small"
        loading={isFetching}
        style={{
          width: "100%",
          maxWidth: "1184px",
          margin: "0 auto"
        }}
        onRow={(_, rowIndex) => {
          return {
            onMouseEnter: () => setHoveringLineIndex(rowIndex),
            onMouseLeave: () => setHoveringLineIndex(null)
          };
        }}
        columns={columns.filter((col) => tableColumns[selectedViews.topOpportunity]?.[col.key]?.display)}
        locale={{
          emptyText: tableData && tableData.patients && tableData.patients.length == 0 && (
            <Space direction={"vertical"} size={10}>
              <NoPatientsFoundIcon />
              <Text style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.5)", fontSize: "16px" }}>
                {t("top_opportunities.no_patients_found")}
              </Text>
            </Space>
          )
        }}
        onChange={fetchData}
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: relevantPatientsCurrentPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottomRight"],
          total: totalRecordsCount,
          onChange: (pagination) =>
            saveRelevantPatientsTableConfigToLocalStorage({
              ...relevantPatientsTableConfig,
              currentPage: pagination,
              id: notificationId
            }),
          showTotal: (total, range) => (
            <>
              <div style={{ display: "flex", flexDirection: "div" }}>
                <PatientPageCount total={total} range={range} isAccurateCount={tableData.isAccurateCount} />
              </div>
            </>
          )
        }}
        dataSource={
          tableData && tableData.patients
            ? tableData.patients
                .filter(({ status }) => status != OOP_MET_STATUS)
                .map((patient, index) => {
                  return {
                    ...patient,
                    key: index
                  };
                })
                .sort((a, b) => b.isActive - a.isActive)
            : null
        }
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  assignees: selectAssignees,
  facilities: selectProvidersFacility,
  doctors: selectProvidersDoctors,
  tableColumns: selectTableColumns,
  tableData: selectRelevantPatientData
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedView: (selectedView) => dispatch(ACTIONS.setSelectedView(selectedView)),
  setTableDataAct: (tableData, sorter, selectedPage) =>
    dispatch(ACTIONS.setTablesData(tableData, selectedPage, selectedViews.topOpportunity, sorter)),
  clearSelectedView: () => dispatch(ACTIONS.clearSelectedView())
});

const TopOpportunities = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TopOpportunitiesComponent);

export { TopOpportunities };
