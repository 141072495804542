const zipInputRules = [
  {
    pattern: /^\d{5}$/,
    message: ""
  }
];

const onlyWhitespacesRule = {
  whitespace: true,
  message: ""
};

const emailInputRules = [
  onlyWhitespacesRule,
  {
    type: "email",
    message: ""
  }
];

const npiInputRule = {
  pattern: /^\d{10}$/,
  message: ""
};

export { zipInputRules, npiInputRule, emailInputRules, onlyWhitespacesRule };
