/**
 * This file combines state from the common-client
 * reducer, and provides structure for adding state
 * slices.
 *
 * No state should be added directly to this file!
 *
 * New state should be added to reducer.js as usual.
 */
import reduceReducers from "reduce-reducers";
import hybridReducer from "./lib/hybridReducer";

import commonReducer from "@tailormed/common-client/store/reducer";
import * as fallbackReducer from "./futureReducer";
import logoutReducer from "./logoutReducer";

export default logoutReducer(
  hybridReducer(
    // Future state slices will go here
    {},
    // Fallback reducer for existing unsliced state
    reduceReducers(
      {
        ...commonReducer.defaultState,
        ...fallbackReducer.initialState
      },
      commonReducer.reducer,
      fallbackReducer.reducer
    )
  )
);
