import React from "react";

export default function ClearIcon(props) {
  return (
    <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.14286 0.85C5.32347 0.85 2.21713 3.90687 2.13729 7.70714H1.20711C0.62802 7.70714 0.33801 8.40728 0.747487 8.81676L2.84132 10.9106C2.91477 11.0393 3.09209 11.0647 3.19866 10.9584L5.34506 8.81733C5.75532 8.40809 5.46549 7.70714 4.88601 7.70714H3.96165C4.04084 4.91097 6.3273 2.67381 9.14286 2.67381C12.0086 2.67381 14.3262 4.99141 14.3262 7.85714C14.3262 10.7229 12.0086 13.0405 9.14286 13.0405C7.98154 13.0405 6.90848 12.6542 6.04637 12.0047C5.65973 11.7134 5.09188 11.696 4.73096 12.0569C4.37778 12.4101 4.36424 13.0026 4.7717 13.3292C5.96944 14.2892 7.4852 14.8643 9.14286 14.8643C13.0124 14.8643 16.15 11.7267 16.15 7.85714C16.15 3.98763 13.0124 0.85 9.14286 0.85Z"
        fill="#2B56A3"
        stroke="#525055"
        strokeWidth="0.3"
      />
    </svg>
  );
}
