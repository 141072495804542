import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as DeleteIcon } from "../../assets/svg/delete-new-icon.svg";
import IconPopup from "./common/IconPopup";
import { OutlinedButton, ErrorButton } from "../customComponent/Button";

const DeleteCoveragePopup = ({ showPopupRef, coverageToDelete, onRemove = () => {} }) => {
  const [popupShown, setPopupShown] = useState(false);
  const { t } = useTranslation();
  const showPopup = () => {
    setPopupShown(true);
  };
  const onCancelHandler = () => setPopupShown(false);
  const onRemoveHandler = () => {
    onRemove(coverageToDelete);
    setPopupShown(false);
  };
  showPopupRef.current = showPopup;
  return (
    <IconPopup
      open={popupShown}
      icon={<DeleteIcon />}
      title={t("patientClassicCoverageDetailsOverview.delete_coverage")}
      onOk={onRemoveHandler}
      onCancel={onCancelHandler}
      footer={[
        <OutlinedButton key="back" onClick={onCancelHandler} id="delete_coverage_back">
          {t("back")}
        </OutlinedButton>,
        <ErrorButton key="delete" onClick={onRemoveHandler} id="delete_coverage_delete">
          {t("patientClassicCoverageDetailsOverview.yes_delete")}
        </ErrorButton>
      ]}
    >
      <p>
        {t("patientClassicCoverageDetailsOverview.delete_with_type_confirmation", {
          coverageType: coverageToDelete.coverageType
        })}
      </p>
    </IconPopup>
  );
};

export { DeleteCoveragePopup };
