import { SingleSelect } from "../../../../customComponent/CustomSelect";
import React from "react";
import { Select } from "antd";

const { Option } = Select;
import { Form } from "antd";
import { useSelector } from "react-redux";
import { selectProvidersFacility } from "../../../../../store/selector";

export const FacilitiesItem = ({
  name,
  label,
  required,
  onDropdownVisibleChange,
  isOpen,
  onChange,
  onClear,
  dropdownRender,
  placeholder
}) => {
  const facilities = useSelector(selectProvidersFacility);

  const onChangeFacility = (facilityId) => {
    const facility = facilities.find((obj) => obj.id === facilityId) || {};
    onChange(facility);
  };
  return (
    <Form.Item name={name} label={label} rules={[{ required: required }]}>
      <SingleSelect
        useCommonStyle={false}
        listHeight={100}
        onDropdownVisibleChange={onDropdownVisibleChange}
        bordered={true}
        allowClear={true}
        placeholder={placeholder}
        open={isOpen}
        onChange={onChangeFacility}
        onClear={onClear}
        dropdownRender={dropdownRender}
      >
        {facilities?.map((p, index) => (
          <Option key={index} value={p.id}>
            {p.name}
          </Option>
        ))}
      </SingleSelect>
    </Form.Item>
  );
};
