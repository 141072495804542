import React, { Suspense, lazy } from "react";
import { Spin } from "antd";

const DefaultFallback = (
  <div>
    <Spin />
  </div>
);

const lazyLoadComponent = (importFunc, fallback = DefaultFallback) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default lazyLoadComponent;
