import React from "react";
import { Checkbox, Col, Divider, Input, InputNumber, Row } from "antd";
import { useTranslation } from "react-i18next";

import { GRAY_500, GRAY_900 } from "../../../../../../../constant/colors";
import { fontWeights, sizes } from "../../../../../../../constant/styles";
import { prescriptionFields } from "../../fieldNames";
import { FormDividerText, FormItem } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";
import { PatientInformation } from "./PatientInformation";

export default function OralForm() {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={14} wrap={false}>
        <Col span={12}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={prescriptionFields.dosingInstructions}
            label={t("application_form_editor.pfizer.prescription.labels.dosing_instructions")}
          >
            <Input placeholder={t("application_form_editor.pfizer.prescription.placeholders.dosing_instructions")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            label={t("application_form_editor.pfizer.prescription.labels.quantity")}
            name={prescriptionFields.quantity}
          >
            <InputNumber
              placeholder={t("application_form_editor.pfizer.prescription.placeholders.quantity")}
              controls={false}
              addonAfter={
                <div style={{ color: GRAY_900, fontWeight: fontWeights.regular, fontSize: sizes.medium_large }}>
                  {t("application_form_editor.pfizer.prescription.labels.day_supply")}
                </div>
              }
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            style={{ width: "100%" }}
            rules={[{ required: true }]}
            name={prescriptionFields.numberOfRefills}
            label={t("application_form_editor.pfizer.prescription.labels.refills")}
          >
            <Input
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.pfizer.prescription.placeholders.refills")}
            />
          </FormItem>
        </Col>
      </Row>
      <PatientInformation />
      <Divider
        id="enrollment-form-prescription-preferred-specialty-pharmacy"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>
          {t("application_form_editor.pfizer.prescription.labels.preferred_specialty_pharmacy_header")}
        </FormDividerText>
      </Divider>
      <Row gutter={14} wrap={false}>
        <Col span={12}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={prescriptionFields.preferredSpecialtyPharmacyName}
            label={t("application_form_editor.pfizer.prescription.labels.preferred_specialty_pharmacy_name")}
          >
            <Input
              placeholder={t(
                "application_form_editor.pfizer.prescription.placeholders.preferred_specialty_pharmacy_name"
              )}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={prescriptionFields.preferredSpecialtyPharmacyAddress}
            label={t("application_form_editor.pfizer.prescription.labels.Preferred_specialty_pharmacy_address")}
          >
            <Input
              placeholder={t(
                "application_form_editor.pfizer.prescription.placeholders.Preferred_specialty_pharmacy_address"
              )}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14} wrap={false}>
        <Col span={8}>
          <FormItem name={prescriptionFields.selfDispensingPharmacy} valuePropName="checked">
            <Checkbox>{t("application_form_editor.pfizer.prescription.labels.self_dispensing_pharmacy")}</Checkbox>
          </FormItem>
        </Col>
      </Row>
    </>
  );
}
