import { css } from "styled-components";
import { fontWeights, sizes } from "../constant/styles";

export const font14pxR = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
`;

export const font14pxSB = css`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
`;

export const font12pxB = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
`;

export const font12pxSB = css`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
`;

export const font12pxR = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
`;

export const font16pxSB = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
`;

export const font18pxSB = css`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
`;

export const font14pxB = css`
  font-weight: ${fontWeights.bold};
  font-size: ${sizes.medium};
  line-height: 20px;
`;

export const font20pxB = css`
  font-weight: ${fontWeights.bold};
  font-size: ${sizes.medium_xlarge};
  line-height: 27px;
`;
