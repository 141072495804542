import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { Space, Table, Typography } from "antd";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment-timezone";
import { withRouter } from "react-router";

import { selectTableColumns, selectIsFetching, selectAllApplicationsData } from "../../store/selector";
import { ApiNames } from "../../api/api";
import { ReactComponent as NoPatientsFoundIcon } from "../../assets/svg/NoPatientsFoundIcon.svg";
import ACTION from "../../store/action";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { capitalizeFirstLetter } from "../../utils/string";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import { ColumnSelector } from "../ColumnSelector";
import {
  AssigneeColumn,
  AssociatedDrugColumnComponent as AssociatedDrugColumn,
  PatientNameColumn,
  StarColumn,
  withSorting
} from "./CommonColumns";
import PatientPageCount from "./PatientPageCount";
import { commonTableStyle } from "./TableStyle";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { dateFormatter, DATE_FORMAT } from "../../utils/date";
import { fontWeights, sizes } from "../../constant/styles";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { formatPhoneNumber, formatSSN, formatToDecimal } from "../../utils/formaters";
import { isNull } from "lodash";
import { DeceasedLable } from "../DeceasedLable";
import { ESignatureStatusBadge } from "../Patient/PatientApplications/enrollTab/utils/components";

const CentralizedText = styled.div`
  text-align: center;
`;
const StyledTable = styled(Table)`
  ${commonTableStyle}
`;
const { Text } = Typography;

const AllApplicationsTableComponent = ({
  tableColumns,
  isFetching,
  setTableDataAct,
  history,
  tableData,
  t,
  isFilterOpen,
  updateSavedFilterViewParameters
}) => {
  const onClickTable = () => ({
    onCell: (patient) => ({
      onClick: () => {
        const { patientId, journeyId, papId, applicationId } = patient;
        history.push(`/patient/${patientId}/journey/${journeyId}/applications/${papId}/${applicationId}`);
      }
    })
  });

  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;

  const fetchData = (pagination, filters, sorter) => {
    if ((!tableData?.sorter?.columnKey && sorter.columnKey) || sorter.columnKey !== tableData?.sorter?.columnKey) {
      // Note: if user try sorting another column. fetch the first page data.
      pagination.current = 1;
    }

    setTableDataAct(pagination.current, sorter);
    if (updateSavedFilterViewParameters) {
      // Note: We need to maintain the last visited paging & sorting for each saved filter view.
      updateSavedFilterViewParameters(pagination.current, sorter);
    }
    window.scrollTo(0, 0);
  };

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.allApplications]?.star?.dataIndex,
      key: tableColumns[selectedViews.allApplications]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.name_id, {
      showSorterTooltip: {
        title: t("columns.sortById")
      },
      render: (text, record) => <PatientNameColumn record={record} />,
      minWidth: 300,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => (record.isDeceased ? <DeceasedLable /> : ""),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.phone_number,
      render: (text, record) => formatPhoneNumber(record.phone_number || record.home_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allApplications]?.dob,
      render: (text, record) => {
        if (!record.dob || record.dob === t("invalid_date")) {
          return "";
        } else {
          const dob = moment(record.dob);
          if (dob.isValid()) {
            return dob.format(DATE_FORMAT);
          } else {
            return "";
          }
        }
      },
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allApplications]?.ssn,
      render: (text, record) => formatSSN(record.social_security_number),
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.assistanceProgram, {
      render: (text, record) => (record.assistanceProgramName ? record.assistanceProgramName : null),
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.providedBy, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.apType, {
      render: (text, record) => (record.apType ? record.apType : null),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.associatedDrug,
      render: (text, record) => <AssociatedDrugColumn record={record} />,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.applicationStatus, {
      width: 150,
      render: (text, record) => (
        <CentralizedText>
          {record.applicationStatus ? capitalizeFirstLetter(record.applicationStatus) : null}
        </CentralizedText>
      ),
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.fundBalance, {
      render: (text, record) => (!isNull(record.fundBalance) ? `$${formatToDecimal(record.fundBalance) || 0} ` : null),
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.validThrough, {
      width: 130,
      render: (text, record) => {
        if (record.effectiveIndefinitely) {
          return t("patientApplications.indefinitely");
        } else {
          return (
            moment(record.approvalValidThrough).isValid() && (
              <CentralizedText>{dateFormatter(record.approvalValidThrough)}</CentralizedText>
            )
          );
        }
      },
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.approvedAmount, {
      render: (text, record) => (record.approvedAmount ? `$${formatToDecimal(record.approvedAmount)}` : ""),
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.approvedDate, {
      render: (text, record) =>
        record.approvedDate && <CentralizedText>{dateFormatter(record.approvedDate)}</CentralizedText>,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.member_id, {
      render: (text, record) => (record.memberId ? record.memberId : null),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.assignee,
      render: (text, record) => <AssigneeColumn record={record} />,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.facility, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.physician, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.patientStatus, {
      render: (_, record) => <PatientStatusCell value={record.status} />,
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.agreementSignatureDate,
      render: (text, record) =>
        moment(record.agreement_signature_date).isValid() && (
          <CentralizedText>{dateFormatter(record.agreement_signature_date)}</CentralizedText>
        ),
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.followupDate, {
      render: (text, record) =>
        record.followupDate && <CentralizedText>{dateFormatter(record.followupDate)}</CentralizedText>
    }),
    {
      ...tableColumns[selectedViews.allApplications]?.primaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.primary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allApplications]?.secondaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.secondary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allApplications]?.tertiaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.tertiary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allApplications]?.applicationSignatureStatus,
      render: (text, record) =>
        record.applicationSignatureStatus && (
          <CentralizedText>
            <ESignatureStatusBadge status={record.applicationSignatureStatus} />
          </CentralizedText>
        ),
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allApplications]?.latestClaimDate, {
      render: (text, record) =>
        record.latestClaimDate && <CentralizedText>{dateFormatter(record.latestClaimDate)}</CentralizedText>,
      ...onClickTable()
    })
  ];
  return (
    <StyledTable
      size="small"
      rowClassName="cursor-pointer"
      style={{ width: isFilterOpen ? "77%" : "100%" }}
      loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
      columns={columns.filter((col) => tableColumns[selectedViews.allApplications]?.[col.key]?.display)}
      locale={{
        emptyText: tableData && tableData.patients && tableData.patients.length == 0 && (
          <Space direction={"vertical"} size={10}>
            <NoPatientsFoundIcon />
            <Text
              style={{ fontWeight: fontWeights.semibold, color: "rgba(0, 0, 0, 0.5)", fontSize: sizes.medium_large }}
            >
              {t("no_patients_found")}
            </Text>
          </Space>
        )
      }}
      onChange={fetchData}
      pagination={{
        size: "small",
        showSizeChanger: false,
        current: tableData && tableData.selectedPage,
        defaultPageSize: PAGE_SIZE,
        pageSize: PAGE_SIZE,
        position: ["topRight"],
        total: totalRecordsCount,
        showTotal: (total, range) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ColumnSelector />
            <PatientPageCount
              total={total}
              range={range}
              isAccurateCount={tableData.isAccurateCount}
              isApplication={true}
            />
          </div>
        )
      }}
      dataSource={
        tableData && tableData.patients
          ? tableData.patients
              .map((patient, index) => {
                return {
                  ...patient,
                  key: index
                };
              })
              .sort((a, b) => b.isActive - a.isActive)
          : null
      }
    />
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  tableData: selectAllApplicationsData,
  isFetching: selectIsFetching([ApiNames.AllApplicationsPhiSearch, ApiNames.AllApplicationsSearch])
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.allApplications, sorter))
});

const AllApplicationsTable = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AllApplicationsTableComponent);

export { AllApplicationsTable };
