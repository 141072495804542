import { trackPendoEvent } from "./pendoUtils";

export const pendoEEUtils = (dispatch, application, eventName, eventTaken) => {
  try {
    trackPendoEvent(dispatch, eventName, {
      programName: application?.displayName,
      providerName: application?.potentialSaving?.APProvider,
      priority: application?.potentialSaving?.priority,
      tmPatientId: application?.potentialSaving?.patient,
      assosiatedDrug: application?.drugDisplayName,
      actionTaken: eventTaken
    });
  } catch (errorText) {
    console.error("Error dispatching EE Pendo event:", errorText);
  }
};

export default { pendoEEUtils };
