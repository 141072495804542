import React, { useContext } from "react";
import { Space as AntdSpace, Tooltip, Typography, DatePicker, Button } from "antd";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { CloudDownloadOutlined, EllipsisOutlined } from "@ant-design/icons";
import moment from "moment-timezone";

import { DropdownBadge } from "../DropdownBadge";
import { font14pxR, font14pxSB } from "../Typography";
import { SUCCESS_600, SUCCESS_100, GRAY_200, GRAY_700 } from "../../constant/colors";

import { ReactComponent as PrimaryDiagnosisIcon } from "../../assets/svg/PrimaryDiagnosis.svg";
import { ReactComponent as SetAsPrimaryDiagnosisIcon } from "../../assets/svg/setAsPrimaryDiagnosisIcon.svg";

import { ReactComponent as PrimaryDiagnosisDisabledIcon } from "../../assets/svg/PrimaryDiagnosisDisabled.svg";
import { ReactComponent as TrashBinIcon } from "../../assets/svg/TrashBin.svg";
import { TitledTooltip } from "../TitledTooltip";
import { DropdownMenu } from "../DropdownMenu";
import { ConflictPopover } from "./ConflictPopover";
import { ManagePatientDetailsContext } from "./context";
import { ManagePatientRoundCornersTable } from "./ManagePatientRoundCornersTable";
import { DATE_FORMAT } from "../../utils/date";

const PAGE_SIZE = 7;
const { Text } = Typography;

const DiagnosisName = styled.span`
  ${font14pxR}
  color: #101828;
`;
const DiagnosisICD = styled.span`
  ${font14pxR}
  color: #667085;
`;

const Space = styled(AntdSpace)`
  line-height: 0;
  cursor: default;
  height: 34px;
`;

const DateInputSpace = styled(Space)`
  padding: 0;
  margin-left: -10px;
`;

const EllipsisWrapper = styled.div`
  cursor: pointer;
  :hover {
    background-color: #eff8ff;
    color: #1570ef;
    border-radius: 50%;
  }
`;

const Table = styled(ManagePatientRoundCornersTable)`
  .ant-table-tbody > tr > td:first-child {
    padding: 8px;
  }
  .ant-table-thead > tr > th:not(:first-child):not(:nth-child(2)) {
    padding: 13px 32px;
  }
  .ant-table-tbody > tr > td:not(:first-child):not(:nth-child(2)):not(:nth-last-child(1)):not(:nth-last-child(2)),
  .ant-table tfoot > tr > th:not(:first-child):not(:nth-child(2)):not(:nth-last-child(1)):not(:nth-last-child(2)),
  .ant-table-tbody
    > tr.ant-table-row
    > td:not(:first-child):not(:nth-child(2)):not(:nth-last-child(1)):not(:nth-last-child(2)) {
    padding: 16px 32px;
  }
  .ant-table-tbody > tr > td:nth-last-child(2) {
    padding: 0;
  }
  .ant-table-tbody > tr > td:nth-last-child(1) {
    padding: 0;
  }
  .ant-table-thead > tr > th:nth-child(2) {
    padding: 16px 32px 16px 0px;
  }
  .ant-table-tbody > tr > td:nth-child(2),
  .ant-table tfoot > tr > th:nth-child(2),
  .ant-table-tbody > tr.ant-table-row > td:nth-child(2) {
    padding: 16px 32px 16px 0px;
  }
`;

const flex = css`
  flex: 1;
  display: flex;
  align-items: center;
`;

const TableTitle = styled.div`
  ${font14pxSB}
  ${flex}
  justify-content: space-between;
  color: #667085;
  padding-bottom: 8px;
  span {
    display: flex;
    align-items: center;
    .anticon-question-circle {
      margin-left: 8px;
    }
  }
  .ant-btn-link {
    padding-right: 0;
  }
`;

export const DiagnosisTable = () => {
  const { t } = useTranslation();
  const {
    showAddDiagnosisPopup,
    replaceDiagnosisPendingConflict,
    ignoreDiagnosisPendingConflict,
    diagnosisTableData: data,
    onDiagnosisRemove = () => {},
    onDiagnosisChanged = () => {}
  } = useContext(ManagePatientDetailsContext);

  const columns = [
    {
      ellipsis: true,
      textWrap: "word-break",
      title: "",
      width: 34,
      dataIndex: "conflict",
      key: "conflict",
      render: (conflict, record) =>
        !!conflict && (
          <ConflictPopover
            onClickReplace={() => replaceDiagnosisPendingConflict(record.diagnosisInfo.icd)}
            onClickIgnore={() => ignoreDiagnosisPendingConflict(record.diagnosisInfo.icd)}
            conflict={conflict}
          >
            <CloudDownloadOutlined />
          </ConflictPopover>
        )
    },
    {
      ellipsis: true,
      textWrap: "word-break",
      title: "Diagnosis Name",
      dataIndex: "diagnosisInfo",
      key: "diagnosisInfo",
      render: (data) => (
        <Space size={0} direction="vertical">
          <Tooltip placement="top" title={data.name}>
            <DiagnosisName>{data.name}</DiagnosisName>
          </Tooltip>
          <DiagnosisICD>{data.icd}</DiagnosisICD>
        </Space>
      )
    },
    {
      title: "Diagnosis Date",
      dataIndex: "activeDate",
      key: "activeDate",
      render: (date, record) => {
        return (
          <DateInputSpace align="center">
            <DatePicker
              format={DATE_FORMAT}
              onChange={(newDate) => {
                record.activeDate = newDate;
                const recordIndex = data.indexOf(data.find((v) => v.diagnosisInfo.icd === record.diagnosisInfo.icd));
                onDiagnosisChanged(recordIndex, "date", newDate && newDate.format(DATE_FORMAT));
              }}
              value={date && moment(date, DATE_FORMAT)}
            />
          </DateInputSpace>
        );
      }
    },
    {
      title: <Text id="status_column_order">Status</Text>,
      dataIndex: "isActive",
      key: "isActive",
      name: "isActive",
      sorter: (a, b) => b.isActive - a.isActive,
      showSorterTooltip: false,
      render: (isActive, record) => {
        const options = [
          {
            label: "Active",
            value: "active",
            backgroundColor: SUCCESS_100,
            color: SUCCESS_600,
            id: "diagnosis_status_active"
          },
          {
            label: "Inactive",
            value: "inactive",
            backgroundColor: GRAY_200,
            color: GRAY_700,
            id: "diagnosis_status_inactive"
          }
        ];

        const sortedOptions = isActive ? options : options.reverse();

        return (
          <DropdownBadge
            onChange={(v) => {
              const recordIndex = data.indexOf(data.find((v) => v.diagnosisInfo.icd === record.diagnosisInfo.icd));
              onDiagnosisChanged(recordIndex, "isActive", v === "active");
              record.isActive = v === "active";
            }}
            defaultValue={sortedOptions[0]?.value}
            options={sortedOptions}
          />
        );
      }
    },
    {
      title: <Text id="primary_column_order"></Text>,
      dataIndex: "isPrimary",
      key: "isPrimary",
      align: "center",
      width: 60,
      sorter: (a, b) => b.isPrimary - a.isPrimary,
      showSorterTooltip: false,
      defaultSortOrder: "ascend",
      render: (isPrimary) => {
        return (
          !!isPrimary && (
            <TitledTooltip title={t("new_patient.primary_diagnosis_camelCase")}>
              <PrimaryDiagnosisIcon />
            </TitledTooltip>
          )
        );
      }
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 40,
      render: (props, record) => {
        return (
          <Space size="middle">
            <DropdownMenu
              onClick={(key) => {
                const recordIndex = data.indexOf(data.find((v) => v.diagnosisInfo.icd === record.diagnosisInfo.icd));
                if (key === "remove_primary" || key === "set_primary") {
                  onDiagnosisChanged(recordIndex, "isPrimary", key === "set_primary");
                } else if (key === "delete") {
                  onDiagnosisRemove(recordIndex);
                }
              }}
              menu={[
                {
                  key: record?.isPrimary ? "remove_primary" : "set_primary",
                  text: record?.isPrimary ? "Remove Primary" : "Set As Primary",
                  icon: record?.isPrimary ? <PrimaryDiagnosisDisabledIcon /> : <SetAsPrimaryDiagnosisIcon />,
                  id: "remove_primary"
                },
                {
                  key: "delete",
                  text: "Delete",
                  icon: <TrashBinIcon />,
                  id: "delete_primary"
                }
              ]}
            >
              <EllipsisWrapper>
                <EllipsisOutlined style={{ fontSize: "18px" }} />
              </EllipsisWrapper>
            </DropdownMenu>
          </Space>
        );
      }
    }
  ];

  return (
    <>
      <TableTitle>
        <span>{t("managePatientDetails.diagnosis")}</span>
        <Button id="manage-patient-details-add-diagnosis-button" type="link" onClick={() => showAddDiagnosisPopup()}>
          {t("managePatientDetails.add_diagnosis")}
        </Button>
      </TableTitle>
      <Table
        id="manage-patient-details-diagnosis"
        rowKey="key"
        rowClassName={(record) => (record.conflict ? "conflicted" : "")}
        bordered={false}
        pagination={
          data.length > PAGE_SIZE
            ? {
                pageSize: PAGE_SIZE,
                position: ["bottomRight"]
              }
            : false
        }
        columns={columns}
        dataSource={data}
      />
    </>
  );
};
