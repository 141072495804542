import React from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { GRAY_500 } from "../../../../constant/colors";
import { FormDividerText } from "../components";

const SectionPart = ({ sectionId, headerTranslationKey, children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider id={`enrollment-form-${sectionId}`} orientation="left" orientationMargin="0" style={{ color: GRAY_500 }}>
        <FormDividerText>{t(headerTranslationKey)}</FormDividerText>
      </Divider>
      {children}
    </>
  );
};

export default SectionPart;
