export const Languages = Object.freeze({
  EN: { name: "English", code: "en" },
  AR: { name: "Arabic", code: "ar" },
  FR: { name: "French", code: "fr" },
  DE: { name: "German", code: "de" },
  JA: { name: "Japanese", code: "ja" },
  KO: { name: "Korean", code: "ko" },
  ZH: { name: "Mandarin", code: "zh" },
  RU: { name: "Russian", code: "ru" },
  ES: { name: "Spanish", code: "es" },
  YUE: { name: "Cantonese", code: "yue" },
  TL: { name: "Tagalog", code: "tl" },
  VI: { name: "Vietnamese", code: "vi" },
  HI: { name: "Hindi", code: "hi" },
  HT: { name: "Haitian Creole", code: "ht" },
  IT: { name: "Italian", code: "it" },
  FA: { name: "Farsi", code: "fa" },
  PT: { name: "Portuguese", code: "pt" },
  PL: { name: "Polish", code: "pl" },
  YI: { name: "Yiddish", code: "yi" },
  HMN: { name: "Hmong", code: "hmn" }
});

export const ALL_LANGUAGES = Object.freeze([
  { id: "preferred_languageـoption_english", value: "english", label: "English" },
  { id: "preferred_languageـoption_chinese", value: "chinese", label: "Chinese" },
  { id: "preferred_languageـoption_hindi", value: "Hindi", label: "Hindi" },
  { id: "preferred_languageـoption_spanish", value: "spanish", label: "Spanish" },
  { id: "preferred_languageـoption_arabic", value: "arabic", label: "Arabic" },
  { id: "preferred_language_option_abkhazian", value: "abkhazian", label: "Abkhazian" },
  { id: "preferred_language_option_afar", value: "afar", label: "Afar" },
  { id: "preferred_language_option_afrikaans", value: "afrikaans", label: "Afrikaans" },
  { id: "preferred_language_option_akan", value: "akan", label: "Akan" },
  { id: "preferred_language_option_albanian", value: "albanian", label: "Albanian" },
  { id: "preferred_language_option_amharic", value: "amharic", label: "Amharic" },
  { id: "preferred_language_option_aragonese", value: "aragonese", label: "Aragonese" },
  { id: "preferred_language_option_armenian", value: "armenian", label: "Armenian" },
  { id: "preferred_language_option_assamese", value: "assamese", label: "Assamese" },
  { id: "preferred_language_option_avaric", value: "avaric", label: "Avaric" },
  { id: "preferred_language_option_avestan", value: "avestan", label: "Avestan" },
  { id: "preferred_language_option_aymara", value: "aymara", label: "Aymara" },
  { id: "preferred_language_option_azerbaijani", value: "azerbaijani", label: "Azerbaijani" },
  { id: "preferred_language_option_bambara", value: "bambara", label: "Bambara" },
  { id: "preferred_language_option_bashkir", value: "bashkir", label: "Bashkir" },
  { id: "preferred_language_option_basque", value: "basque", label: "Basque" },
  { id: "preferred_language_option_belarusian", value: "belarusian", label: "Belarusian" },
  { id: "preferred_languageـoption_bengali", value: "bengali", label: "Bengali" },
  { id: "preferred_language_option_bislama", value: "bislama", label: "Bislama" },
  { id: "preferred_language_option_bosnian", value: "bosnian", label: "Bosnian" },
  { id: "preferred_language_option_breton", value: "breton", label: "Breton" },
  { id: "preferred_language_option_bulgarian", value: "bulgarian", label: "Bulgarian" },
  { id: "preferred_language_option_burmese", value: "burmese", label: "Burmese" },
  { id: "preferred_language_option_catalan", value: "catalan", label: "Catalan" },
  { id: "preferred_language_option_chamorro", value: "chamorro", label: "Chamorro" },
  { id: "preferred_language_option_chechen", value: "chechen", label: "Chechen" },
  {
    id: "preferred_language_option_chichewa_chewa_nyanja",
    value: "chichewa, chewa, nyanja",
    label: "Chichewa Chewa Nyanja"
  },
  {
    id: "preferred_language_option_church_slavonic_old_slavonic_old_church_slavonic",
    value: "church slavonic old slavonic old church slavonic",
    label: "Church Slavonic, Old Slavonic, Old Church Slavonic"
  },
  { id: "preferred_language_option_chuvash", value: "chuvash", label: "Chuvash" },
  { id: "preferred_language_option_cornish", value: "cornish", label: "Cornish" },
  { id: "preferred_language_option_corsican", value: "corsican", label: "Corsican" },
  { id: "preferred_language_option_cree", value: "cree", label: "Cree" },
  { id: "preferred_language_option_croatian", value: "croatian", label: "Croatian" },
  { id: "preferred_language_option_czech", value: "czech", label: "Czech" },
  { id: "preferred_language_option_danish", value: "danish", label: "Danish" },
  {
    id: "preferred_language_option_divehi_dhivehi_maldivian",
    value: "divehi dhivehi maldivian",
    label: "Divehi, Dhivehi, Maldivian"
  },
  { id: "preferred_language_option_dutch", value: "dutch flemish", label: "Dutch, Flemish" },
  { id: "preferred_language_option_dzongkha", value: "dzongkha", label: "Dzongkha" },
  { id: "preferred_language_option_esperanto", value: "esperanto", label: "Esperanto" },
  { id: "preferred_language_option_estonian", value: "estonian", label: "Estonian" },
  { id: "preferred_language_option_ewe", value: "ewe", label: "Ewe" },
  { id: "preferred_language_option_faroese", value: "faroese", label: "Faroese" },
  { id: "preferred_language_option_fijian", value: "fijian", label: "Fijian" },
  { id: "preferred_language_option_finnish", value: "finnish", label: "Finnish" },
  { id: "preferred_languageـoption_french", value: "french", label: "French" },
  { id: "preferred_languageـoption_western_frisian", value: "western frisian", label: "Western Frisian" },
  {
    id: "preferred_language_option_fulah",
    value: "fulah",
    label: "Fulah"
  },
  {
    id: "preferred_language_option_galician_scottish_gaelic",
    value: "galician scottish gaelic",
    label: "Gaelic, Scottish Gaelic"
  },
  { id: "preferred_language_option_galician", value: "galician", label: "Galician" },
  { id: "preferred_language_option_ganda", value: "ganda", label: "Ganda" },
  { id: "preferred_language_option_georgian", value: "georgian", label: "Georgian" },
  { id: "preferred_language_option_german", value: "german", label: "German" },
  { id: "preferred_language_option_greek", value: "greek", label: "Greek" },
  { id: "preferred_language_option_greenlandic", value: "greenlandic", label: "Kalaallisut, Greenlandic" },
  { id: "preferred_language_option_guarani", value: "guarani", label: "Guarani" },
  { id: "preferred_language_option_gujarati", value: "gujarati", label: "Gujarati" },
  { id: "preferred_language_option_haitian_Creole", value: "haitian_Creole", label: "Haitian, Creole" },
  { id: "preferred_language_option_hausa", value: "hausa", label: "Hausa" },
  { id: "preferred_language_option_hebrew", value: "hebrew", label: "Hebrew" },
  { id: "preferred_language_option_herero", value: "herero", label: "Herero" },
  { id: "preferred_language_option_hiri_Motu", value: "hiri_Motu", label: "Hiri_Motu" },
  { id: "preferred_language_option_hungarian", value: "hungarian", label: "Hungarian" },
  { id: "preferred_language_option_icelandic", value: "icelandic", label: "Icelandic" },
  { id: "preferred_language_option_ido", value: "ido", label: "Ido" },
  { id: "preferred_language_option_igbo", value: "igbo", label: "Igbo" },
  { id: "preferred_language_option_indonesian", value: "indonesian", label: "Indonesian" },
  { id: "preferred_language_option_interlingua", value: "interlingua", label: "Interlingua" },
  { id: "preferred_language_option_interlingue", value: "interlingue", label: "Interlingue" },
  { id: "preferred_language_option_inuktitut", value: "inuktitut", label: "Inuktitut" },
  { id: "preferred_language_option_inupiak", value: "inupiak", label: "Inupiak" },
  { id: "preferred_language_option_irish", value: "irish", label: "Irish" },
  { id: "preferred_language_option_italian", value: "italian", label: "Italian" },
  { id: "preferred_language_option_japanese", value: "japanese", label: "Japanese" },
  { id: "preferred_language_option_javanese", value: "javanese", label: "Javanese" },
  { id: "preferred_language_option_kannada", value: "kannada", label: "Kannada" },
  { id: "preferred_language_option_kanuri", value: "kanuri", label: "Kanuri" },
  { id: "preferred_language_option_kashmiri", value: "kashmiri", label: "Kashmiri" },
  { id: "preferred_language_option_kazakh", value: "kazakh", label: "Kazakh" },
  { id: "preferred_language_option_khmer", value: "khmer", label: "Central Khmer" },
  { id: "preferred_language_option_kikuyu", value: "kikuyu", label: "Kikuyu, Gikuyu" },
  {
    id: "preferred_language_option_kinyarwanda",
    value: "kinyarwanda",
    label: "Kinyarwanda"
  },
  { id: "preferred_language_option_kirundi", value: "kirundi", label: "Kirundi" },
  { id: "preferred_language_option_komi", value: "komi", label: "Komi" },
  { id: "preferred_language_option_kongo", value: "kongo", label: "Kongo" },
  { id: "preferred_language_option_korean", value: "korean", label: "Korean" },
  { id: "preferred_language_option_kuanyama_kwanyama", value: "kuanyama kwanyama", label: "Kuanyama, Kwanyama" },
  { id: "preferred_language_option_kurdish", value: "kurdish", label: "Kurdish" },
  { id: "preferred_language_option_lao", value: "lao", label: "Lao" },
  { id: "preferred_language_option_latin", value: "latin", label: "Latin" },
  { id: "preferred_language_option_latvian", value: "latvian", label: "Latvian" },
  {
    id: "preferred_language_option_limburgish_limburger)",
    value: "limburgish limburger",
    label: "Limburgan, Limburger, Limburgish"
  },
  { id: "preferred_language_option_lingala", value: "lingala", label: "Lingala" },
  { id: "preferred_language_option_lithuanian", value: "lithuanian", label: "Lithuanian" },
  { id: "preferred_language_option_luba_katanga", value: "luba-katanga", label: "Luba-Katanga" },
  { id: "preferred_language_option_luxembourgish", value: "luxembourgish", label: "Luxembourgish" },
  { id: "preferred_language_option_macedonian", value: "macedonian", label: "Macedonian" },
  { id: "preferred_language_option_malagasy", value: "malagasy", label: "Malagasy" },
  { id: "preferred_language_option_malay", value: "malay", label: "Malay" },
  { id: "preferred_language_option_malayalam", value: "malayalam", label: "Malayalam" },
  { id: "preferred_language_option_maltese", value: "maltese", label: "Maltese" },
  { id: "preferred_language_option_manx", value: "manx", label: "Manx" },
  { id: "preferred_language_option_maori", value: "maori", label: "Maori" },
  { id: "preferred_language_option_marathi", value: "marathi", label: "Marathi" },
  { id: "preferred_language_option_marshallese", value: "marshallese", label: "Marshallese" },
  { id: "preferred_language_option_mongolian", value: "mongolian", label: "Mongolian" },
  { id: "preferred_language_option_nauru", value: "nauru", label: "Nauru" },
  { id: "preferred_language_option_navajo", value: "navajo", label: "Navajo, Navaho" },
  { id: "preferred_language_option_north_ndebele", value: "north ndebele", label: "North Ndebele" },
  { id: "preferred_language_option_south_ndebele", value: "south ndebele", label: "South Ndebele" },
  { id: "preferred_language_option_ndonga", value: "ndonga", label: "Ndonga" },
  { id: "preferred_language_option_nepali", value: "nepali", label: "Nepali" },
  { id: "preferred_language_option_norwegian", value: "norwegian", label: "Norwegian" },
  { id: "preferred_language_option_norwegian_bokmål", value: "norwegian bokmål", label: "Norwegian bokmål" },
  { id: "preferred_language_option_norwegian_nynorsk", value: "norwegian nynorsk", label: "Norwegian nynorsk" },
  { id: "preferred_language_option_nuosu", value: "nuosu", label: "Sichuan Yi, Nuosu" },
  { id: "preferred_language_option_occitan", value: "occitan", label: "Occitan" },
  { id: "preferred_language_option_ojibwe", value: "ojibwe", label: "Ojibwe" },
  { id: "preferred_language_option_oriya", value: "oriya", label: "Oriya" },
  { id: "preferred_language_option_oromo", value: "oromo", label: "Oromo" },
  { id: "preferred_language_option_ossetian", value: "ossetian", label: "Ossetian, Ossetic" },
  { id: "preferred_language_option_pāli", value: "pāli", label: "Pāli" },
  { id: "preferred_language_option_pashto_pushto", value: "pashto pushto", label: "Pashto Pushto" },
  { id: "preferred_language_option_persian_farsi", value: "persian farsi", label: "Persian" },
  { id: "preferred_language_option_polish", value: "polish", label: "Polish" },
  { id: "preferred_languageـoption_portuguese", value: "portuguese", label: "Portuguese" },
  { id: "preferred_language_option_punjabi", value: "punjabi", label: "Punjabi, Panjabi" },
  { id: "preferred_language_option_quechua", value: "quechua", label: "Quechua" },
  { id: "preferred_language_option_romanian", value: "romanian", label: "Romanian" },
  { id: "preferred_language_option_romansh", value: "romansh", label: "Romansh" },
  { id: "preferred_language_option_rundi", value: "Rundi", label: "Rundi" },
  { id: "preferred_languageـoption_russian", value: "russian", label: "Russian" },
  { id: "preferred_language_option_sami", value: "sami", label: "Northern Sami" },
  { id: "preferred_language_option_samoan", value: "samoan", label: "Samoan" },
  { id: "preferred_language_option_sango", value: "sango", label: "Sango" },
  { id: "preferred_language_option_sanskrit", value: "sanskrit", label: "Sanskrit" },
  { id: "preferred_language_option_sardinian", value: "sardinian", label: "Sardinian" },
  { id: "preferred_language_option_serbian", value: "serbian", label: "Serbian" },
  { id: "preferred_language_option_shona", value: "shona", label: "Shona" },
  { id: "preferred_language_option_sindhi", value: "Sindhi", label: "sindhi" },
  { id: "preferred_language_option_sindhi", value: "sindhi", label: "Sinhala, Sinhalese" },
  { id: "preferred_language_option_slovak", value: "slovak", label: "Slovak" },
  { id: "preferred_language_option_slovenian", value: "slovenian", label: "Slovenian" },
  { id: "preferred_language_option_somali", value: "somali", label: "Somali" },
  { id: "preferred_language_option_somali", value: "southern sotho", label: "Southern Sotho" },
  { id: "preferred_language_option_sundanese", value: "sundanese", label: "Sundanese" },
  { id: "preferred_language_option_swahili", value: "swahili", label: "Swahili" },
  { id: "preferred_language_option_swati", value: "swati", label: "Swati" },
  { id: "preferred_language_option_swedish", value: "swedish", label: "Swedish" },
  { id: "preferred_language_option_tagalog", value: "tagalog", label: "Tagalog" },
  { id: "preferred_language_option_tahitian", value: "tahitian", label: "Tahitian" },
  { id: "preferred_language_option_tajik", value: "tajik", label: "Tajik" },
  { id: "preferred_language_option_tamil", value: "tamil", label: "Tamil" },
  { id: "preferred_language_option_tatar", value: "tatar", label: "Tatar" },
  { id: "preferred_language_option_telugu", value: "telugu", label: "Telugu" },
  { id: "preferred_language_option_thai", value: "thai", label: "Thai" },
  { id: "preferred_language_option_tibetan", value: "tibetan", label: "Tibetan" },
  { id: "preferred_language_option_tigrinya", value: "tigrinya", label: "Tigrinya" },
  { id: "preferred_language_option_tonga", value: "tonga", label: "Tonga" },
  { id: "preferred_language_option_tsonga", value: "tsonga", label: "Tsonga" },
  { id: "preferred_language_option_tswana", value: "tswana", label: "Tswana" },
  { id: "preferred_language_option_turkish", value: "turkish", label: "Turkish" },
  { id: "preferred_language_option_turkmen", value: "turkmen", label: "Turkmen" },
  { id: "preferred_language_option_twi", value: "twi", label: "Twi" },
  { id: "preferred_language_option_uyghur", value: "uyghur", label: "Uyghur" },
  { id: "preferred_language_option_ukrainian", value: "ukrainian", label: "Ukrainian" },
  { id: "preferred_languageـoption_urdu", value: "urdu", label: "Urdu" },
  { id: "preferred_language_option_uzbek", value: "uzbek", label: "Uzbek" },
  { id: "preferred_language_option_venda", value: "venda", label: "Venda" },
  { id: "preferred_language_option_vietnamese", value: "vietnamese", label: "Vietnamese" },
  { id: "preferred_language_option_volapük", value: "volapük", label: "Volapük" },
  { id: "preferred_language_option_wallon", value: "wallon", label: "Wallon" },
  { id: "preferred_language_option_welsh", value: "welsh", label: "Welsh" },
  { id: "preferred_language_option_wolof", value: "wolof", label: "Wolof" },
  { id: "preferred_language_option_xhosa", value: "xhosa", label: "Xhosa" },
  { id: "preferred_language_option_yiddish", value: "yiddish", label: "Yiddish" },
  { id: "preferred_language_option_yoruba", value: "yoruba", label: "Yoruba" },
  { id: "preferred_language_option_zhuang_chuang", value: "zhuang chuang", label: "Zhuang Chuang" },
  { id: "preferred_language_option_zulu", value: "zulu", label: "Zulu" }
]);
