import _ from "lodash";
import moment from "moment-timezone";
import { isRxNormDrugIVAdministered } from "./drugs";

export const calculateTreatmentEndDate = (startDate, frequency, numOfCycles) => {
  return moment(startDate)
    .add(frequency * numOfCycles, "d")
    .format("YYYY-MM-DD");
};

export const calculateQuantityValueForOralDrug = (quantityValue, frequency) => {
  return (quantityValue || 1) * parseInt(frequency || 1);
};

export const formatMedication = ({
  treatments,
  startDate,
  quantity,
  numOfCycles,
  frequency,
  physician,
  quantityValue,
  numberOfRefillsAuthorized,
  rxnormDrug,
  dosageValue,
  dosageDisplay,
  dosageUnit,
  isStartDateRequired = true
}) => {
  const { tmRouteType } = rxnormDrug;

  dosageDisplay = dosageDisplay ?? dosageValue;
  dosageValue = dosageDisplay?.toString().split("-")[0];

  let appliedStartDate = null;
  if (startDate) {
    appliedStartDate = startDate;
  } else if (isStartDateRequired) {
    appliedStartDate = moment();
  }
  appliedStartDate = appliedStartDate ? moment(appliedStartDate).format("YYYY-MM-DD") : null;
  if (isRxNormDrugIVAdministered({ tmRouteType })) {
    const endDate = appliedStartDate ? calculateTreatmentEndDate(appliedStartDate, quantity, numOfCycles - 1) : null;
    return {
      ...treatments,
      manualTreatments: [
        ..._.get(treatments, "manualTreatments", []),
        {
          rxnormDrug,
          frequency,
          numOfCycles,
          startDate: appliedStartDate,
          physician,
          endDate,
          quantityValue,
          quantity,
          dosageValue,
          dosageDisplay,
          dosageUnit
        }
      ]
    };
  } else {
    const quantity = calculateQuantityValueForOralDrug(quantityValue, frequency);
    const endDate = appliedStartDate
      ? calculateTreatmentEndDate(appliedStartDate, quantityValue, numberOfRefillsAuthorized)
      : null;
    return {
      ...treatments,
      manualTreatments: [
        ..._.get(treatments, "manualTreatments", []),
        {
          rxnormDrug,
          frequency,
          numberOfRefillsAuthorized,
          startDate: appliedStartDate,
          physician,
          endDate,
          quantityValue: quantity,
          dosageValue,
          dosageDisplay,
          dosageUnit
        }
      ]
    };
  }
};
