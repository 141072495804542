import { Space, Typography } from "antd";
import React from "react";
import styled from "styled-components";

import { GRAY_500 } from "../../../../../constant/colors";
import { font20pxB } from "../../../../Typography";

const { Text } = Typography;

const TitleStyled = styled(Text)`
  ${font20pxB}
  color: ${GRAY_500};
  display: flex;
  text-align: center;
  white-space: pre-line;
`;

export const EnrollResultData = ({ icon, title, content, description, titleStyle = {} }) => (
  <Space direction="vertical" align="center">
    {icon}
    <TitleStyled style={{ ...titleStyle }}>{title}</TitleStyled>
    {description}
    {content}
  </Space>
);
