import React from "react";
import { useSelector } from "react-redux";
import { Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { GRAY_50, GRAY_500 } from "../../../../../constant/colors";
import { useSelectedApplication } from "../../../hooks";
import {
  selectApplicationForm,
  selectPatientDetails,
  selectSelectedAssistanceProgramById
} from "../../../../../store/selector";
import { font14pxB, font14pxSB } from "../../../../Typography";
import { getExpressEnrollmentMapping } from "../../../ApplicationFormEditor/configurations";

const { Text } = Typography;

const CardStyled = styled(Card)`
  .ant-card-body {
    padding: 10px 10px 10px 15px;
  }
  border-radius: 8px;
  background: ${GRAY_50};
  width: 528px;
`;

const FieldNameStyledDiv = styled(Text)`
  ${font14pxSB}
  color: ${GRAY_500};
`;

const FieldValueStyledText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${GRAY_500};
`;

const BoldTextStyled = styled(Text)`
  ${font14pxB}
  color: ${GRAY_500};
  display: flex;
  margin-top: 15px;
`;

export const EnrollResultCard = ({ enrollData }) => {
  const { t } = useTranslation();
  const application = useSelectedApplication();
  const patientDetails = useSelector(selectPatientDetails);
  const applicationForm = useSelector(selectApplicationForm);
  const selectedAssistanceProgramById = useSelector(selectSelectedAssistanceProgramById);

  const expressEnrollMapping = getExpressEnrollmentMapping();
  const { getEnrollResultData = () => ({}), getAdditionalResultCardInformation } = expressEnrollMapping || {};
  const enrollResultData = enrollData || getEnrollResultData({ application, patientDetails, applicationForm, t });

  const getDetailsToDisplayOnCard = () => {
    const detailsToDisplay = Object.entries(enrollResultData).map(([field, value]) => {
      return (
        <div key={field}>
          <FieldNameStyledDiv>{t(`patientApplications.enroll.enroll_result.${field}`)}</FieldNameStyledDiv>
          <FieldValueStyledText>{value}</FieldValueStyledText>
        </div>
      );
    });
    return detailsToDisplay;
  };

  const detailsToDisplay = getDetailsToDisplayOnCard() || null;
  const additionalResultCardInformation = !enrollData && getAdditionalResultCardInformation;
  const phone = selectedAssistanceProgramById?.phone1;

  return (
    <CardStyled>
      {detailsToDisplay}
      <BoldTextStyled style={{ display: "flex", marginTop: "20px" }}>
        {t("patientApplications.enroll.enroll_result.details_saved")}
      </BoldTextStyled>
      {additionalResultCardInformation && (
        <FieldValueStyledText style={{ display: "flex", marginTop: "10px" }}>
          {t(additionalResultCardInformation, {
            phone: phone
          })}
        </FieldValueStyledText>
      )}
    </CardStyled>
  );
};

export default EnrollResultCard;
