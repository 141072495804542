export const FormFields = {
  //Personal
  deceased: "deceased",
  firstName: "firstName",
  lastName: "lastName",
  middleName: "middleName",
  prefferedName: "prefferedName",
  homeNumber: "homeNumber",
  gender: "gender",
  phoneNumber: "phoneNumber",
  email: "email",
  dob: "dob",
  mrn: "mrn",
  socialSecurityNumber: "socialSecurityNumber",
  veteran: "veteran",
  legalResident: "legalResident",
  disabled: "disabled",
  usCitizen: "usCitizen",
  address: "address",
  city: "city",
  stateCode: "stateCode",
  zipCode: "zipCode",
  preferredLanguage: "preferredLanguage",
  fullNameCaregiver: "fullNameCaregiver",
  relationToPatientCaregiver: "relationToPatientCaregiver",
  phoneCaregiver: "phoneCaregiver",
  emailCaregiver: "emailCaregiver",
  alternateContactOption: "alternateContactOption",

  //Personal
  //Medical
  patientAllergies: "patientAllergies",
  patientHeight: "patientHeight",
  patientWeight: "patientWeight",
  patientHeightInch: "patientHeightInch",
  patientHeightFeet: "patientHeightFeet",
  //Medical
  //Financial
  incomeSalary: "incomeSalary",
  incomeSocialSecurity: "incomeSocialSecurity",
  incomeDisability: "incomeDisability",
  incomeUnemployment: "incomeUnemployment",
  incomePension: "incomePension",
  incomeStocksBonds: "incomeStocksBonds",
  incomeDividends: "incomeDividends",
  incomeChildSupport: "incomeChildSupport",
  incomeFoodStamps: "incomeFoodStamps",
  incomeOther: "incomeOther",
  incomeVerifiedDate: "incomeVerifiedDate",
  filesTaxes: "filesTaxes",
  agreementSignatureDate: "agreementSignatureDate",
  proofOfIncome: "proofOfIncome",
  householdSizeAdults: "householdSizeAdults",
  householdSizeChildren: "householdSizeChildren",
  householdSize: "householdSize",
  maritalStatus: "maritalStatus",
  totalIncome: "totalIncome",
  totalMonthlyIncome: "totalMonthlyIncome"
  //Financial
};
