import _ from "lodash";

export const INTERNAL_PROGRAM = "internal";

export const APStatus = Object.freeze({
  OPEN: "open",
  CLOSED: "closed",
  WAITLIST: "waitlist",
  WAITLIST_ONLY: "waitlist only"
});

export const priorities = Object.freeze({
  COPAY_CARD: {
    value: 1,
    text: "copay_card",
    forView: true
  },
  FREE_DRUGS: {
    value: 2,
    text: "free_drug",
    forView: true
  },
  COPAY: {
    value: 3,
    text: "copay",
    forView: true
  },
  LIVING_EXPENSES: {
    value: 4,
    text: "living_expenses",
    forView: true
  },
  GOVERNMENT_PLANS: {
    value: 5,
    text: "government_plans",
    forView: true
  },
  INTERNAL: {
    value: INTERNAL_PROGRAM,
    text: "internal",
    forView: true
  },
  ARCHIVED: {
    value: 10,
    text: "archived",
    forView: false
  }
});

export const prioritiesForView = _.pickBy(priorities, (p) => p.forView);

export const prioritiesMap = _.keyBy(Object.values(prioritiesForView), "value");
