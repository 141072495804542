import React, { useEffect, useState } from "react";
import { Col, Divider, Input, Row, Checkbox, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

import { GRAY_300 } from "../../../../../../../constant/colors";
import { onlyWhitespacesRule } from "../../../../utils";
import { insuranceFields } from "../fieldNames";
import { DateFormItem, FormDividerText, FormItem } from "../../../../components";
import { insuranceMedicalName, insuranceMedicalPlanType } from "./insuranceConfigurations";
import { buildFieldValueMap } from "../../../../../../../utils/form";
import { sizes } from "../../../../../../../constant/styles";
import { SingleSelect } from "../../../../../../customComponent/CustomSelect";
import { OTHER } from "../../../../constants";

export default function GenentechCopayInsurance() {
  const { t } = useTranslation();

  const [isInsuranceMedicalNameSelectOpen, setIsInsuranceMedicalNameSelectOpen] = useState();
  const [isInsuranceMedicalPlanTypeOpen, setIsInsuranceMedicalPlanTypeOpen] = useState();

  const form = Form.useFormInstance();
  const insuranceWithoutBinAndPcn = Form.useWatch(insuranceFields.insuranceWithoutBinAndPcn, form);
  const insuranceWithoutPharmacyInsuranceCard = Form.useWatch(
    insuranceFields.insuranceWithoutPharmacyInsuranceCard,
    form
  );
  const insuranceMedicalSelectNameItem = Form.useWatch(insuranceFields.insuranceMedicalSelectName, form);
  const insuranceMedicalSelectPlanTypeItem = Form.useWatch(insuranceFields.insuranceMedicalSelectPlanType, form);

  useEffect(() => {
    form.validateFields();
  }, [insuranceMedicalSelectNameItem, insuranceMedicalSelectPlanTypeItem, insuranceWithoutPharmacyInsuranceCard]);

  return (
    <>
      <div id="enrollment-form-insurance-information" />
      <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_300 }}>
        <FormDividerText>{t("application_form_editor.genentech.copay.insurance.medical_header")}</FormDividerText>
      </Divider>
      <Row gutter={14}>
        <Col span={8}>
          <FormItem
            rules={[{ required: true }]}
            name={insuranceFields.insuranceMedicalSelectName}
            label={t("application_form_editor.genentech.copay.insurance.labels.insurance_medical_name")}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              onDropdownVisibleChange={(visible) => setIsInsuranceMedicalNameSelectOpen(visible)}
              open={isInsuranceMedicalNameSelectOpen}
              options={Object.values(insuranceMedicalName).map((name) => ({ label: name, value: name }))}
              placeholder={t(
                "application_form_editor.genentech.copay.insurance.placeholders.insurance_medical_select_name"
              )}
              onChange={(insuranceMedicalName) =>
                form.setFieldsValue(buildFieldValueMap([[insuranceFields.insuranceMedicalName, insuranceMedicalName]]))
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Button
                    id="EE_genentech_copay_other_insurance_medical_name"
                    type="text"
                    style={{ width: "100%", textAlign: "start", fontSize: sizes.medium, background: "transparent" }}
                    onClick={() => {
                      form.setFieldsValue(buildFieldValueMap([[insuranceFields.insuranceMedicalSelectName, OTHER]]));
                      form.setFieldsValue(buildFieldValueMap([[insuranceFields.insuranceMedicalName, undefined]]));
                      setIsInsuranceMedicalNameSelectOpen(false);
                    }}
                  >
                    {t("application_form_editor.genentech.copay.insurance.other")}
                  </Button>
                </>
              )}
            />
          </FormItem>
        </Col>
        <Col span={insuranceMedicalSelectNameItem !== OTHER ? 0 : 8}>
          <FormItem
            hidden={insuranceMedicalSelectNameItem !== OTHER}
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={insuranceFields.insuranceMedicalName}
            label={t("application_form_editor.genentech.copay.insurance.labels.insurance_medical_name")}
          >
            <Input
              placeholder={t("application_form_editor.genentech.copay.insurance.placeholders.insurance_medical_name")}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem
            rules={[{ required: true }]}
            name={insuranceFields.insuranceMedicalSelectPlanType}
            label={t("application_form_editor.genentech.copay.insurance.labels.insurance_medical_plan_type")}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              onDropdownVisibleChange={(visible) => setIsInsuranceMedicalPlanTypeOpen(visible)}
              open={isInsuranceMedicalPlanTypeOpen}
              options={Object.values(insuranceMedicalPlanType).map((type) => ({ label: type, value: type }))}
              placeholder={t(
                "application_form_editor.genentech.copay.insurance.placeholders.insurance_medical_plan_type"
              )}
              onChange={(insuranceMedicalPlanType) =>
                form.setFieldsValue(
                  buildFieldValueMap([[insuranceFields.insuranceMedicalPlanType, insuranceMedicalPlanType]])
                )
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Button
                    id="EE_genentech_copay_other_insurance_medical_plan_type"
                    type="text"
                    style={{ width: "100%", textAlign: "start", fontSize: sizes.medium, background: "transparent" }}
                    onClick={() => {
                      form.setFieldsValue(
                        buildFieldValueMap([[insuranceFields.insuranceMedicalSelectPlanType, OTHER]])
                      );
                      form.setFieldsValue(buildFieldValueMap([[insuranceFields.insuranceMedicalPlanType, undefined]]));
                      setIsInsuranceMedicalPlanTypeOpen(false);
                    }}
                  >
                    {t("application_form_editor.genentech.copay.insurance.other")}
                  </Button>
                </>
              )}
            />
          </FormItem>
        </Col>
        <Col span={insuranceMedicalSelectPlanTypeItem !== OTHER ? 0 : 8}>
          <FormItem
            hidden={insuranceMedicalSelectPlanTypeItem !== OTHER}
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={insuranceFields.insuranceMedicalPlanType}
            label={t("application_form_editor.genentech.copay.insurance.labels.insurance_medical_plan_type")}
          >
            <Input
              placeholder={t(
                "application_form_editor.genentech.copay.insurance.placeholders.insurance_medical_other_plan_type"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name={insuranceFields.insuranceMedicalGroupId}
            label={t("application_form_editor.genentech.copay.insurance.labels.insurance_medical_group_id")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input
              maxLength={20}
              placeholder={t(
                "application_form_editor.genentech.copay.insurance.placeholders.insurance_medical_group_id"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name={insuranceFields.insuranceMedicalMemberId}
            label={t("application_form_editor.genentech.copay.insurance.labels.insurance_medical_Member_id")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input
              maxLength={20}
              placeholder={t(
                "application_form_editor.genentech.copay.insurance.placeholders.insurance_medical_Member_id"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <DateFormItem
            required
            name={insuranceFields.insuranceMedicalInsuranceEffectiveDate}
            disabledDate={(date) => moment(date).isAfter(moment())}
            label={t(
              "application_form_editor.genentech.copay.insurance.labels.insurance_medical_insurance_effective_date"
            )}
          />
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={8}>
          <FormItem name={insuranceFields.insuranceWithoutBinAndPcn} valuePropName="checked">
            <Checkbox>
              {t("application_form_editor.genentech.copay.insurance.labels.insurance_medical_without_bin_and_pcn")}
            </Checkbox>
          </FormItem>
        </Col>
      </Row>
      {!insuranceWithoutBinAndPcn && (
        <Row gutter={14}>
          <Col span={8}>
            <FormItem
              name={insuranceFields.insuranceMedicalRxBin}
              label={t("application_form_editor.genentech.copay.insurance.labels.insurance_medical_rx_bin")}
              rules={[onlyWhitespacesRule]}
            >
              <Input
                maxLength={6}
                placeholder={t(
                  "application_form_editor.genentech.copay.insurance.placeholders.insurance_medical_rx_bin"
                )}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name={insuranceFields.insuranceMedicalRxPcn}
              label={t("application_form_editor.genentech.copay.insurance.labels.insurance_medical_rx_pcn")}
              rules={[onlyWhitespacesRule]}
            >
              <Input
                maxLength={10}
                placeholder={t(
                  "application_form_editor.genentech.copay.insurance.placeholders.insurance_medical_rx_pcn"
                )}
              />
            </FormItem>
          </Col>
        </Row>
      )}
      <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_300 }}>
        <FormDividerText>{t("application_form_editor.genentech.copay.insurance.pharmacy_header")}</FormDividerText>
      </Divider>
      <Row gutter={14}>
        <Col span={8}>
          <FormItem name={insuranceFields.insuranceWithoutPharmacyInsuranceCard} valuePropName="checked">
            <Checkbox>
              {t("application_form_editor.genentech.copay.insurance.labels.insurance_without_pharmacy_insurance_card")}
            </Checkbox>
          </FormItem>
        </Col>
      </Row>
      {!insuranceWithoutPharmacyInsuranceCard && (
        <>
          <Row gutter={14}>
            <Col span={8}>
              <FormItem
                rules={[{ required: true }, onlyWhitespacesRule]}
                name={insuranceFields.insurancePharmacyName}
                label={t("application_form_editor.genentech.copay.insurance.labels.insurance_pharmacy_name")}
              >
                <Input
                  placeholder={t(
                    "application_form_editor.genentech.copay.insurance.placeholders.insurance_pharmacy_name"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                rules={[{ required: true }, onlyWhitespacesRule]}
                name={insuranceFields.insurancePharmacyMemberId}
                label={t("application_form_editor.genentech.copay.insurance.labels.insurance_pharmacy_member_id")}
              >
                <Input
                  maxLength={20}
                  placeholder={t(
                    "application_form_editor.genentech.copay.insurance.placeholders.insurance_pharmacy_member_id"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                rules={[{ required: true }, onlyWhitespacesRule]}
                name={insuranceFields.insurancePharmacyGroupId}
                label={t("application_form_editor.genentech.copay.insurance.labels.insurance_pharmacy_group_id")}
              >
                <Input
                  maxLength={20}
                  placeholder={t(
                    "application_form_editor.genentech.copay.insurance.placeholders.insurance_pharmacy_group_id"
                  )}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={8}>
              <FormItem
                rules={[onlyWhitespacesRule]}
                name={insuranceFields.insurancePharmacyRxBin}
                label={t("application_form_editor.genentech.copay.insurance.labels.insurance_pharmacy_rx_bin")}
              >
                <Input
                  maxLength={6}
                  placeholder={t(
                    "application_form_editor.genentech.copay.insurance.placeholders.insurance_pharmacy_rx_bin"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                rules={[onlyWhitespacesRule]}
                name={insuranceFields.insurancePharmacyRxPcn}
                label={t("application_form_editor.genentech.copay.insurance.labels.insurance_pharmacy_rx_pcn")}
              >
                <Input
                  maxLength={10}
                  placeholder={t(
                    "application_form_editor.genentech.copay.insurance.placeholders.insurance_pharmacy_rx_pcn"
                  )}
                />
              </FormItem>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
