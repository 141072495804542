import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { Table, Typography } from "antd";
import { withTranslation } from "react-i18next";
import moment from "moment-timezone";
import { withRouter } from "react-router";
import "./cursor.less";
import styled from "styled-components";

import {
  selectTableColumns,
  selectIsFetching,
  selectAllPatientsData,
  selectSearchedPatients
} from "../../store/selector";
import { ApiNames } from "../../api/api";
import { ReactComponent as NoPatientsFoundIcon } from "../../assets/svg/NoPatientsFoundIcon.svg";
import ACTION from "../../store/action";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import { ColumnSelector } from "../ColumnSelector";
import { EditColumn, PatientNameColumn, StarColumn, withSorting, AssigneeColumn } from "./CommonColumns";
import PatientPageCount from "./PatientPageCount";
import { TabsMap } from "../../screen/patient/Patient";
import { commonTableStyle } from "./TableStyle";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { dateFormatter, DATE_FORMAT } from "../../utils/date";
import { formatPhoneNumber, formatSSN } from "../../utils/formaters";
import { DeceasedLable } from "../DeceasedLable";

const CentralizedText = styled.div`
  text-align: center;
`;

const { Text } = Typography;

const StyledTable = styled(Table)`
  ${commonTableStyle}
  .ant-table-thead > tr > th:nth-last-of-type(-n + 2)::before {
    display: none;
  }
`;

const AllPatientsTableComponent = ({
  fetchOrders,
  isSearch,
  tableColumns,
  isFetching,
  setTableDataAct,
  history,
  tableData,
  tableDataWithSearch,
  isFetchingWithSearch,
  t,
  isFilterOpen,
  updateSavedFilterViewParameters = undefined
}) => {
  const [hoveringLineIndex, setHoveringLineIndex] = useState(null);

  const fetchData = (pagination, filters, sorter) => {
    const targetData = isSearch ? tableDataWithSearch : tableData;

    if ((!tableData?.sorter?.columnKey && sorter.columnKey) || sorter.columnKey !== targetData?.sorter?.columnKey) {
      // Note: if user try sorting another column. fetch the first page data.
      pagination.current = 1;
    }

    if (isSearch) {
      fetchOrders(pagination.current, sorter);
    } else {
      setTableDataAct(pagination.current, sorter);
    }

    if (updateSavedFilterViewParameters) {
      // Note: We need to maintain the last visited paging & sorting for each saved filter view.
      updateSavedFilterViewParameters(pagination.current, sorter);
    }

    window.scrollTo(0, 0);
  };

  const onClickTable = () => ({
    onCell: (patient) => ({
      onClick: () => {
        const { patientId, journeyId } = patient;
        history.push(TabsMap.overview.path(patientId, journeyId));
      }
    })
  });

  const {
    patients: searchedPatients = [],
    totalPatients,
    selectedPage: searchResultCurrentPage
  } = tableDataWithSearch || {};

  const currentPage = isSearch ? searchResultCurrentPage : tableData && tableData.selectedPage;
  const totalRecordsCount = isSearch ? totalPatients : tableData?.totalPatients;
  const sortingEnabled = isSearch ? tableDataWithSearch?.sortingEnabled : tableData?.sortingEnabled;

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.allPatients]?.star?.dataIndex,
      key: tableColumns[selectedViews.allPatients]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.name_id, {
      showSorterTooltip: {
        title: t("columns.sortById")
      },
      render: (text, record) => <PatientNameColumn record={record} />,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => (record.isDeceased ? <DeceasedLable /> : ""),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allPatients]?.phone_number,
      render: (text, record) => formatPhoneNumber(record.phone_number || record.home_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.dob,
      render: (text, record) => {
        if (!record.dob || record.dob === t("invalid_date")) {
          return "";
        } else {
          const dob = moment(record.dob);
          if (dob.isValid()) {
            return dob.format(DATE_FORMAT);
          } else {
            return "";
          }
        }
      },
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.ssn,
      render: (text, record) => formatSSN(record.social_security_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.primaryDiagnosis,
      render: (text, record) => (record.diagnosises[0] ? record.diagnosises[0] : null),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.assignee,
      width: 230,
      render: (text, record) => <AssigneeColumn record={record} />,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.facility, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.physician, {
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allPatients]?.employer,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.creationDate, {
      render: (text, record) => moment(record.creationDate).format("MMM D, YYYY"),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allPatients]?.journeyEvent,
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <div>
            <img src={`/images/events/${record.journeyUpcomingEventIcon}`} alt="" />
          </div>
          <div>
            <div style={{ textTransform: "capitalize" }}>{record.journeyUpcomingEventChapter}</div>
            <div style={{ color: "#9b9b9b" }}>{record.journeyUpcomingEvent}</div>
          </div>
        </div>
      ),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.primaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.primary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.secondaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.secondary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.tertiaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.tertiary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.agreementSignatureDate,
      render: (text, record) =>
        record.agreement_signature_date && (
          <CentralizedText>{dateFormatter(record.agreement_signature_date)}</CentralizedText>
        )
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.patientStatus, {
      render: (_, record) => <PatientStatusCell value={record.status} />,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.followupDate, {
      render: (text, record) =>
        record.followupDate && <CentralizedText>{dateFormatter(record.followupDate)}</CentralizedText>
    }),
    {
      dataIndex: tableColumns[selectedViews.allPatients]?.edit?.dataIndex,
      key: tableColumns[selectedViews.allPatients]?.edit?.key,
      width: 35,
      render: (_, record, rowIndex) => <EditColumn isHovering={hoveringLineIndex === rowIndex} record={record} />
    }
  ];

  return (
    <StyledTable
      size="small"
      rowClassName="cursor-pointer"
      style={{ width: isFilterOpen ? "77%" : "100%" }}
      loading={{ indicator: <TailormedSpinner />, spinning: (isSearch && isFetchingWithSearch) || isFetching }}
      columns={columns.filter((col) => tableColumns[selectedViews.allPatients]?.[col.key]?.display)}
      locale={{
        emptyText: (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <NoPatientsFoundIcon />
            <Text style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.5)", fontSize: "16px" }}>
              {t("no_patients_found")}
            </Text>
          </div>
        )
      }}
      onRow={(_, rowIndex) => {
        return {
          onMouseEnter: () => setHoveringLineIndex(rowIndex),
          onMouseLeave: () => setHoveringLineIndex(null)
        };
      }}
      pagination={{
        size: "small",
        showSizeChanger: false,
        current: currentPage,
        defaultPageSize: PAGE_SIZE,
        pageSize: PAGE_SIZE,
        position: ["topRight"],
        total: totalRecordsCount,
        showTotal: (total, range) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ColumnSelector isSearchView={isSearch} />
            <PatientPageCount
              total={total}
              range={range}
              isAccurateCount={isSearch ? totalPatients : tableData.isAccurateCount}
            />
          </div>
        )
      }}
      onChange={fetchData}
      dataSource={
        !isSearch
          ? tableData && tableData.patients
            ? tableData.patients
                .map((patient, index) => ({ ...patient, key: index }))
                .sort((a, b) => b.isActive - a.isActive)
            : null
          : searchedPatients.length
          ? searchedPatients
              .map((patient, index) => ({ ...patient, key: index }))
              .sort((a, b) => b.isActive - a.isActive)
          : null
      }
    />
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  tableData: selectAllPatientsData,
  tableDataWithSearch: selectSearchedPatients,
  isFetching: selectIsFetching([ApiNames.AllPatientsPhiSearch, ApiNames.AllPatientsSearch]),
  isFetchingWithSearch: selectIsFetching([ApiNames.PhiSearchFreeText, ApiNames.SearchFreeText])
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.allPatients, sorter))
});

const AllPatientsTable = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AllPatientsTableComponent);

export { AllPatientsTable };
