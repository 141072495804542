const _ = require("lodash");

export function buildFieldValueMap(valueList) {
  const values = {};
  for (const [namePath, value] of valueList) {
    _.set(values, namePath, value);
  }
  return values;
}

export * as validation from "./validation";
