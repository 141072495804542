import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { PrimaryButton } from "../customComponent/Button";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    background: #ffffff !important;
    color: black;
    width: 520px;
  }
  .ant-modal-title {
    margin-top: 8px;
    color: black !important;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-header {
    border-radius: 8px;
    background: #ffffff !important;
    border: #ffffff;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    border: 0px;
    .ant-btn {
      border-radius: 8px;
    }
  }
`;
const CustomPopupModal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(() => {
    props.modalToggle.current = showModal;
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <StyledModal
        destroyOnClose={true}
        title={props.modalTitle}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancelButton" onClick={handleCancel}>
            {props.cancelText ? props.cancelText : null}
          </Button>,
          <PrimaryButton form="addDiagnosisForm" key="submit" htmlType="submit">
            {props.okText ? props.okText : null}
          </PrimaryButton>
        ]}
      >
        {props.children}
      </StyledModal>
    </>
  );
};

export default CustomPopupModal;
export { StyledModal as PopupStyledModal };
