import React, { useState, useEffect } from "react";
import { Button, List, Popover, Space, Typography, Image, Select, Badge, Skeleton } from "antd";
import { withTranslation } from "react-i18next";
import { ReactComponent as BellFilled } from "../assets/svg/bell.svg";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import moment from "moment-timezone";
import _ from "lodash";

import { BLUE, BLUE_DARK, SEC_FONT_GREY } from "./../constant/colors";
import { getNotificationsAPI, markNotificationAPI } from "./../api/api";
import ErrorMessage from "./customComponent/customMessages/ErrorMessage";
import { Routes } from "../constant/routes";
import { capitalizeFirstLetter } from "../utils/string";
import { isUrl } from "../utils/formValidation";
import { dateFormatter } from "../utils/date";
import { CircleIconButton } from "./UI/CircleIconButton";
import { Tooltip } from "./UI/Tooltip";

const { Option } = Select;
const { Text } = Typography;

const DisplayFilterMap = Object.freeze({
  all: {
    key: "all",
    text: (t) => t("notifications.display_filter.all_programs"),
    filter: (list) => list,
    sort: (list) => list
  },
  relevant: {
    key: "relevant",
    text: (t) => t("notifications.display_filter.open_with_relevant_patients"),
    filter: (list) => list.filter((notification) => notification.hasTopOppsPatients && notification.value !== "closed")
  },
  unread: {
    key: "unread",
    text: (t) => t("notifications.display_filter.unread_notifications"),
    filter: (list) => list.filter((notification) => !notification.read && notification.value !== "closed")
  },
  recentlyOpened: {
    key: "recentlyOpened",
    text: (t) => t("notifications.display_filter.recently_opened_programs"),
    filter: (list) =>
      list.filter(
        (notification) =>
          notification.value === "open" && moment(notification.createdAt).isAfter(moment().subtract(7, "d"))
      )
  },
  closed: {
    key: "closed",
    text: (t) => t("notifications.display_filter.closed_programs"),
    filter: (list) => list.filter((notification) => notification.value === "closed")
  }
});

const OrderSortMap = Object.freeze({
  newToOld: {
    key: "newToOld",
    text: (t) => t("notifications.order_sort.date_new_to_old"),
    sort: (newList) => newList
  },
  oldToNew: {
    key: "oldToNew",
    text: (t) => t("notifications.order_sort.date_old_to_new"),
    sort: (newList) => _.sortBy(newList, "createdAt")
  },
  status: {
    key: "status",
    text: (t) => t("notifications.order_sort.status_open_to_close"),
    sort: (newList) => _.sortBy(newList, "value").reverse()
  },
  unread: {
    key: "unread",
    text: (t) => t("notifications.order_sort.unread_first"),
    sort: (newList) => _.sortBy(newList, "read")
  }
});

const NotificationsComponent = ({ t, history }) => {
  const [visible, setVisible] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(DisplayFilterMap.all.key);
  const [notifications, setNotifications] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    try {
      setIsFetching(true);
      const res = await getNotificationsAPI();
      if (res && res.data && res.data.notifications) {
        const { notifications } = res.data;
        notifications.map((notification) =>
          notification.value === "closed" ? Object.assign(notification, { read: true }) : notification
        );
        setNotifications(notifications);
      } else {
        ErrorMessage(t("notifications.get_notifications_failed"));
      }
    } catch (error) {
      ErrorMessage(t("notifications.get_notifications_failed"));
    } finally {
      setIsFetching(false);
    }
  };

  const handleMarkNotification = async (notificationID, val) => {
    try {
      setIsFetching(true);
      const resultRes = await markNotificationAPI(notificationID, val);
      if (resultRes && resultRes.data && resultRes.data.success) {
        getNotifications();
      } else {
        ErrorMessage(t("notifications.marking_notifications_failed"));
        setIsFetching(false);
      }
    } catch (error) {
      ErrorMessage(t("notifications.marking_notifications_failed"));
      setIsFetching(false);
    }
  };

  const getUnReadNotificationsAmount = () =>
    notifications.filter((notification) => !notification.read && notification.value !== "closed").length;

  const notificationValueMessage = (notification) => {
    if (notification.value == "closed") {
      return <Text style={{ color: SEC_FONT_GREY, fontSize: 12 }}>{t("notifications.enrollment_closed")}</Text>;
    } else if (notification.hasTopOppsPatients) {
      return (
        <Button
          style={{ color: BLUE, border: "none", padding: 0 }}
          onClick={() => {
            setVisible(false);
            history.push(Routes.ROOT + `top-opportunities/${notification.id}`, { notification });
          }}
        >
          {t("notifications.view_relevant_patients")}
        </Button>
      );
    } else if (notification.value == "waitlist only") {
      return (
        <Text style={{ color: SEC_FONT_GREY, fontSize: 12 }}>
          {t("notifications.no_relevant_patients_waitlist_only")}
        </Text>
      );
    } else {
      return <Text style={{ color: SEC_FONT_GREY, fontSize: 12 }}>{t("notifications.no_relevant_patients")}</Text>;
    }
  };

  return (
    <Popover
      placement="bottomLeft"
      trigger="click"
      open={visible}
      onOpenChange={(visible) => setVisible(visible)}
      title={
        <div style={{ display: "flex", justifyContent: "start", padding: 4 }}>
          <Space direction={"vertical"} size={0}>
            <Text style={{ paddingLeft: 11 }}>{t("notifications.title.display")}</Text>
            <Select
              onSelect={(value) => setDisplayFilter(value)}
              dropdownMatchSelectWidth={false}
              style={{ color: BLUE }}
              placeholder={<Text style={{ color: BLUE }}> {DisplayFilterMap.all.text(t)}</Text>}
              bordered={false}
            >
              {Object.values(DisplayFilterMap).map((displayFilter) => (
                <Option key={displayFilter.key} value={displayFilter.key}>
                  {displayFilter.text(t)}
                </Option>
              ))}
            </Select>
          </Space>
          <Space direction={"vertical"} size={0}>
            <Text style={{ paddingLeft: 41 }}>{t("notifications.title.order_by")}</Text>
            <Select
              placeholder={<Text style={{ color: BLUE }}> {OrderSortMap.newToOld.text(t)}</Text>}
              onSelect={(selectedOrder) => setNotifications(OrderSortMap[selectedOrder].sort(notifications))}
              dropdownMatchSelectWidth={false}
              style={{ paddingLeft: 30, color: BLUE }}
              bordered={false}
            >
              {Object.values(OrderSortMap).map((orderFilter) => (
                <Option key={orderFilter.key} value={orderFilter.key}>
                  {orderFilter.text(t)}
                </Option>
              ))}
            </Select>
          </Space>
        </div>
      }
      content={
        <div style={{ display: "flex", paddingLeft: 16, flexDirection: "column" }}>
          <div style={{ color: BLUE_DARK }}>
            {t("notifications.results_unread", {
              results: DisplayFilterMap[displayFilter].filter(notifications).length,
              unread: getUnReadNotificationsAmount()
            })}
          </div>
          <List
            style={{ width: "450px", height: "330px", overflowY: "auto" }}
            loading={isFetching}
            dataSource={DisplayFilterMap[displayFilter].filter(notifications)}
            renderItem={(notification) => (
              <List.Item
                id={`notification-${notification.id}`}
                style={{ padding: 0 }}
                extra={
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      {notification.assistanceProgram && (
                        <div style={{ padding: 16 }}>
                          {isUrl(notification.assistanceProgram.logo) ? (
                            <Image
                              preview={false}
                              src={notification.assistanceProgram.logo}
                              style={{ height: 45, width: 100, alignSelf: "center" }}
                            />
                          ) : (
                            <Skeleton.Image style={{ height: 45, width: 100 }} />
                          )}
                        </div>
                      )}
                      {notification.value !== "closed" && (
                        <Button
                          style={{ color: BLUE, border: "none", justifyContent: "center" }}
                          onClick={() => handleMarkNotification(notification.id, !notification.read)}
                        >
                          {t("notifications.mark_as_read", { read: notification.read ? "Unread" : "Read" })}
                        </Button>
                      )}
                    </div>
                  </div>
                }
              >
                <List.Item.Meta
                  title={
                    <Text style={{ color: SEC_FONT_GREY, fontSize: 12 }}>
                      {t("notifications.program_value_and_moment", {
                        value: capitalizeFirstLetter(notification.assistanceProgram.status),
                        moment: dateFormatter(notification.createdAt)
                      })}
                    </Text>
                  }
                  description={
                    <Space direction={"vertical"} style={{ maxWidth: 250 }}>
                      <Text style={{ maxLength: 10 }}>{notification.assistanceProgram.name}</Text>
                      <Space>{notificationValueMessage(notification)}</Space>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      }
    >
      <Badge offset={[-7, 6]} size="small" count={getUnReadNotificationsAmount()}>
        <Tooltip title={t("notifications.tooltip")}>
          <CircleIconButton id="main_header_notifications" onClick={() => setVisible(!visible)} icon={<BellFilled />} />
        </Tooltip>
      </Badge>
    </Popover>
  );
};

const Notifications = compose(withTranslation(), withRouter)(NotificationsComponent);
export { Notifications };
