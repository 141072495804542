import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";

import {
  ERROR_300,
  ERROR_50,
  GRAY_200,
  GRAY_50,
  PRIMARY_100,
  PRIMARY_200,
  SUCCESS_500
} from "../../../../constant/colors";
import styled from "styled-components";

const CircleIcon = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isActive", "isCompleted"].includes(prop)
})`
  width: 16px;
  height: 16px;
  background: ${(props) => (props.isActive ? PRIMARY_100 : GRAY_50)};
  border: 1px solid ${(props) => (props.isActive ? PRIMARY_200 : GRAY_200)};
  border-radius: 50%;
`;

const SidebarIcon = function ({ isActive, isCompleted }) {
  if (isCompleted) {
    return <CheckCircleFilled style={{ color: SUCCESS_500, fontSize: "16px", display: "flex" }} />;
  }
  return (
    <CircleIcon
      style={{ borderColor: ERROR_300, background: ERROR_50 }}
      className="sidebar-circle"
      isActive={isActive}
    />
  );
};

export default SidebarIcon;
