import React from "react";
import { Layout as AntLayout } from "antd";
import styled from "styled-components";
import { GREEN } from "../constant/colors";
import { TailormedLogoIcon } from "../assets/svg/TailormedLogoIcon";
import { TailormedVisualIcon } from "../assets/svg/TailormedVisualIcon";

const { Header: AntHeader } = AntLayout;

const Header = styled(AntHeader)`
  padding: 0;
  background-color: ${GREEN};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: ${(props) => props.theme.dimensions.width};
`;

const Substrate = styled.div`
  background-color: ${GREEN};
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TailormedLogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  padding-left: 0px;
`;

const TailormedHeaderText = null;

const AppLayout = ({ children }) => {
  return (
    <Substrate>
      <Header>{children}</Header>
    </Substrate>
  );
};

const ErrorPageLayout = ({ children }) => <div>{children}</div>;

export {
  ErrorPageLayout,
  Header,
  Substrate,
  LogoContainer,
  AppLayout,
  TailormedLogoIcon,
  TailormedHeaderText,
  TailormedVisualIcon,
  TailormedLogoContainer
};
