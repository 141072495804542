import React from "react";
import { Space, Row, Col } from "antd";
import { PrimaryButton } from "../customComponent/Button";
import styled from "styled-components";

const TitleRow = styled(Row)`
  font-size: 18px;
  font-weight: 600;
  font-family: "Open Sans";
  font-style: normal;
  margin-top: 16px;
`;

const SubTitleRow = styled(Row)`
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans";
  font-style: normal;
  color: #667085;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const MessageSpace = styled(Space)`
  display: flex;
  justify-content: center;
  aligni-items: center;
  background: #ffffff;
  padding: 24px 32px 32px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
`;

const EmptyListMessage = ({
  icon,
  id = "",
  title = "",
  buttonDisabled = false,
  subTitleText = [],
  buttonTitle = "",
  buttonAction = () => {}
}) => {
  const Icon = icon;
  return (
    <MessageSpace>
      <Col>
        <Row justify="center">{Icon && <Icon />}</Row>
        <TitleRow justify="center">{title}</TitleRow>
        <SubTitleRow>
          <Col>
            {subTitleText.map((subRow, index) => (
              <Row justify="center" key={index}>
                {subRow}
              </Row>
            ))}
          </Col>
        </SubTitleRow>
        <Row justify="center">
          <PrimaryButton disabled={buttonDisabled} onClick={buttonAction} id={id}>
            {buttonTitle}
          </PrimaryButton>
        </Row>
      </Col>
    </MessageSpace>
  );
};

export default EmptyListMessage;
