import React, { useEffect, useState } from "react";
import { Col, Divider, Row, Input, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { GRAY_500 } from "../../../../../../constant/colors";
import { providerFields } from "../fieldNames";
import { FormDividerText, FormItem } from "../../../components";
import { npiInputRule, onlyWhitespacesRule } from "../../../utils";
import { OTHER, ProviderTypes } from "../../../constants";
import { PhysicianSelect } from "../../../../../Select";
import { sizes } from "../../../../../../constant/styles";
import { buildFieldValueMap } from "../../../../../../utils/form";
import { selectProvidersDoctors, selectProvidersFacility } from "../../../../../../store/selector";
import { getFieldsToUpdateOnProviderChange, getPhysicianFieldsToUpdate } from "../../../utils/provider";

const facilitySectionName = "facility";
const providerSectionName = "provider";

const formToProviderFieldMap = [
  [providerFields.providerNpi, "npi"],
  [providerFields.taxId, "taxId"]
];

const EaglePemfexyCopayProvider = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const physician = Form.useWatch(providerFields.providerId, form);

  const physicians = useSelector(selectProvidersDoctors);
  const facilities = useSelector(selectProvidersFacility);

  const [isPhysicianSelectOpen, setIsPhysicianSelectOpen] = useState(false);

  useEffect(() => {
    form.validateFields();
  }, [physician]);

  const currentPhysicianColSpan = physician === OTHER ? 6 : 0;
  const anotherPhysicianColSpan = physician === OTHER ? 6 : 12;

  const onProviderChange = async (providerId, formFieldMap, providerType, additionalFieldsToUpdate) => {
    let providers;
    let formSectionName;
    if (providerType === ProviderTypes.Facility) {
      providers = facilities;
      formSectionName = facilitySectionName;
    } else {
      providers = physicians;
      formSectionName = providerSectionName;
    }

    let fieldsToUpdate = getFieldsToUpdateOnProviderChange(
      providers,
      providerId,
      formFieldMap,
      additionalFieldsToUpdate
    );

    form.setFieldsValue({ [formSectionName]: { ...fieldsToUpdate } });
  };

  const onPhysicianChange = (physician) =>
    onProviderChange(
      physician,
      formToProviderFieldMap,
      ProviderTypes.Physician,
      getPhysicianFieldsToUpdate(
        form,
        physicians,
        providerFields.providerId,
        providerFields.providerFirstName,
        providerFields.providerLastName
      )
    );

  return (
    <>
      <Divider
        id="enrollment-form-provider-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.eagle.provider.labels.header")}</FormDividerText>
      </Divider>

      <Row gutter={14}>
        <Col span={anotherPhysicianColSpan}>
          <FormItem
            name={providerFields.providerId}
            label={t("application_form_editor.eagle.provider.labels.provider_full_name")}
            rules={[{ required: true }]}
          >
            <PhysicianSelect
              onDropdownVisibleChange={setIsPhysicianSelectOpen}
              open={isPhysicianSelectOpen}
              id="EE_eagle_copay_physician_select"
              placeholder={t("application_form_editor.eagle.provider.placeholders.provider_full_name")}
              onChange={(physician) => onPhysicianChange(physician)}
              onClear={() => onPhysicianChange(null)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Button
                    id="EE_eagle_copay_other_provider_id"
                    type="text"
                    style={{ width: "100%", textAlign: "start", fontSize: sizes.medium, background: "transparent" }}
                    onClick={() => {
                      form.setFieldsValue(buildFieldValueMap([[providerFields.providerId, OTHER]]));
                      setIsPhysicianSelectOpen(false);
                      onPhysicianChange(null);
                    }}
                  >
                    {t("application_form_editor.eagle.provider.other")}
                  </Button>
                </>
              )}
            />
          </FormItem>
        </Col>

        <Col span={currentPhysicianColSpan}>
          <FormItem
            name={providerFields.providerFirstName}
            label={t("application_form_editor.eagle.provider.labels.provider_first_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.eagle.provider.placeholders.provider_first_name")} />
          </FormItem>
        </Col>

        <Col span={currentPhysicianColSpan}>
          <FormItem
            name={providerFields.providerLastName}
            label={t("application_form_editor.eagle.provider.labels.provider_last_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.eagle.provider.placeholders.provider_last_name")} />
          </FormItem>
        </Col>

        <Col span={anotherPhysicianColSpan}>
          <FormItem
            name={providerFields.taxId}
            label={t("application_form_editor.eagle.provider.labels.tax_id")}
            rules={[{ required: true }]}
          >
            <Input placeholder={t("application_form_editor.eagle.provider.placeholders.tax_id")} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <FormItem
            name={providerFields.groupNpi}
            label={t("application_form_editor.eagle.provider.labels.group_npi")}
            rules={[onlyWhitespacesRule, npiInputRule, { required: true }]}
          >
            <Input maxLength={10} placeholder={t("application_form_editor.eagle.provider.placeholders.group_npi")} />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem
            name={providerFields.providerNpi}
            label={t("application_form_editor.eagle.provider.labels.provider_npi")}
            rules={[onlyWhitespacesRule, npiInputRule, { required: true }]}
          >
            <Input maxLength={10} placeholder={t("application_form_editor.eagle.provider.placeholders.provider_npi")} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default EaglePemfexyCopayProvider;
