import { getTrimmedFields } from "../../../../utils/formaters";

const applyTrimFields = (form, fieldsToExclude = []) => {
  const formFieldValues = form.getFieldsValue();

  fieldsToExclude.forEach((field) => delete formFieldValues[field]);

  const trimmedFields = getTrimmedFields(formFieldValues);
  form.setFieldsValue(trimmedFields);
};

export { applyTrimFields };
