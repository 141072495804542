const DEFAULT_PHONE_TYPE = "mobile";

export const getNovartisCopayFormInitialValues = (applicationForm) => {
  const formData = applicationForm?.formData ?? {};

  const patientState = formData?.patient?.state === "" ? null : formData?.patient?.state;

  const initialValues = {
    ...formData,
    patient: {
      ...formData?.patient,
      firstName: formData?.patient?.firstName?.substring?.(0, 49),
      lastName: formData?.patient?.lastName?.substring?.(0, 49),
      address: formData?.patient?.address?.substring?.(0, 49),
      city: formData?.patient?.city?.substring?.(0, 29),
      state: patientState,
      phoneType: formData?.patient?.phoneType || DEFAULT_PHONE_TYPE,
      caregiver: {
        ...formData?.patient?.caregiver,
        phoneType: formData?.patient?.caregiver?.phoneType || DEFAULT_PHONE_TYPE
      }
    }
  };

  return initialValues;
};

// [ ] 1. Condition Section
// [ ] primaryCondition > First Radio option to match first diagnosis the patient has. If difficult - raise a flag to @Mordechay Shahr
