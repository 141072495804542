import { select, fork, put, spawn, all, call, take } from "redux-saga/effects";

import ACTION from "../action";
import { ApiNames } from "../../api/api";
import { selectSettings } from "../selector";
import { callApi } from "./networkActions";
import { actions, setTableDataAction, setSummaryCounters } from "./sagaActions";
import { getUser, watchIDPActions, watchOktaActions } from "./authActions";
import ErrorMessage from "../../component/customComponent/customMessages/ErrorMessage";
import { selectedViews } from "../../constant/table";
import { parseValues } from "../../hooks/getSetting";
import { waitForLogin } from "@tailormed/common-client/store/saga/authSagas";
import initializeUserMetrics from "@tailormed/common-client/store/saga/services/userMetrics";
import { getAuthInstance } from "@tailormed/common-client/services/auth";
import { oktaConfig } from "../../constant/okta";

function* getUsers() {
  const result = yield callApi(ApiNames.Users);
  if (result && result.users) {
    const { users } = result;
    const usersMap = users.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});
    yield put(ACTION.setUsers(usersMap));
  } else {
    ErrorMessage(`Failed to get users`);
  }
}

function* getAssignees() {
  const result = yield callApi(ApiNames.Assignees);
  if (result) {
    const { users } = result;
    const assignees = users.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});
    yield put(ACTION.setAssignees(assignees));
  } else {
    ErrorMessage(`Failed to get assignees`);
  }
}

function* getUserGroups() {
  const result = yield callApi(ApiNames.UserGroups);
  if (result) {
    const userGroups = result["user-groups"].reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});
    yield put(ACTION.setUserGroups(userGroups));
  } else {
    ErrorMessage(`Failed to get user groups`);
  }
}

function* getSettings() {
  try {
    const settingsRes = yield callApi(ApiNames.Settings);
    if (settingsRes) {
      yield put(ACTION.setSettings(settingsRes.settings));
    }
  } catch (error) {
    ErrorMessage(`Failed to get settings: ${error}`);
  }
}

function* getUnits() {
  try {
    const getUnitsRes = yield callApi(ApiNames.GetUnits);
    if (getUnitsRes) {
      yield put(ACTION.setUnits(getUnitsRes));
    }
  } catch (error) {
    ErrorMessage(`Failed to get units. ${error}`);
  }
}

function* getVisitTypes() {
  try {
    const getVisitTypesRes = yield callApi(ApiNames.GetVisitTypes);
    if (getVisitTypesRes) {
      yield put(ACTION.setVisitTypes(getVisitTypesRes));
    }
  } catch (error) {
    ErrorMessage(`Failed to get units. ${error}`);
  }
}

function* getProviders() {
  const providerTypes = ["facility", "doctor"];
  for (let type of providerTypes) {
    const result = yield callApi(ApiNames.Providers, type);
    if (result) {
      yield put(ACTION.provider(type, result.providers));
    } else {
      ErrorMessage(`Failed to get providers data`);
    }
  }
}

function* getAPSponsors() {
  const result = yield callApi(ApiNames.getAPSponsors);
  if (result) {
    yield put(ACTION.APSponsors(result.APSponsors));
  } else {
    ErrorMessage(`Failed to get APSponsors data`);
  }
}

function* getPatients() {
  yield spawn(setTableDataAction, { page: 1, selectedView: selectedViews.allPatients });

  const settings = yield select(selectSettings);

  if (Object.keys(settings).length === 0) {
    yield take(ACTION.Types.SET_SETTINGS);
  }

  const displayVisitSchedule = parseValues(settings["automation.visit-schedule.fe-display"]);
  yield displayVisitSchedule && spawn(setTableDataAction, { page: 1, selectedView: selectedViews.visitSchedule });

  const displayPendingRenewal = parseValues(settings["automation.pending-renewal.fe-display"]);
  yield displayPendingRenewal && spawn(setTableDataAction, { page: 1, selectedView: selectedViews.pendingRenewal });

  const displayOppsFe = parseValues(settings["automation.opportunities.fe-display"]);
  yield displayOppsFe && spawn(setTableDataAction, { page: 1, selectedView: selectedViews.highPriority });

  const displayClaimsFe = parseValues(settings["fe.show_claims"]);
  yield displayClaimsFe && spawn(setTableDataAction, { page: 1, selectedView: selectedViews.allClaims });

  yield spawn(setTableDataAction, { page: 1, selectedView: selectedViews.allApplications });
}

function* startup() {
  const oktaClientId = sessionStorage.getItem("oktaClientId");
  const oktaAuth = getAuthInstance(oktaConfig.redirectUri, oktaClientId);
  const token = oktaAuth.getAccessToken();
  yield all([call(getUser), call(getSettings)]);
  yield spawn(initializeUserMetrics, { analytics: true, rum: true, token });
  yield spawn(getUsers);
  yield spawn(getAssignees);
  yield spawn(getUserGroups);
  yield spawn(setSummaryCounters);
  yield spawn(getUnits);
  yield spawn(getVisitTypes);
  yield spawn(getProviders);
  yield spawn(getPatients);
  yield spawn(getAPSponsors);
}

function* rootSaga() {
  yield fork(watchOktaActions);
  //the next line preventing watchIDPActions from running until the okta config is set
  yield take(ACTION.Types.SET_OKTA_CONFIG);
  yield fork(watchIDPActions);
  yield waitForLogin();
  yield fork(startup);
  yield spawn(actions);
}

export { rootSaga };
