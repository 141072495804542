import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Select, Space, Typography } from "antd";

import { optionsSearch } from "../../utils/search";
import { GRAY5, GRAY_PLACEHOLDER } from "../../constant/colors";
import FloatLabel from "./FloatLabel";

const commonStyle = {
  width: "100%",
  border: `0px`,
  borderBottom: `1px solid ${GRAY5}`,
  padding: 0
};

const { Text } = Typography;

const MultiSelect = ({
  children,
  maxTagCount = 1,
  selectAll = false,
  clearAll = false,
  useCommonStyle = true,
  onClearAll,
  onSelectAll,
  style = {},
  id,
  bordered = false,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div id={id}>
      <Select
        {...props}
        mode="multiple"
        bordered={bordered}
        showArrow={true}
        allowClear={true}
        onClear={onClearAll}
        maxTagCount={maxTagCount}
        style={{
          ...(useCommonStyle && commonStyle),
          ...style,
          padding: props.label ? "10px 0 0 0" : ""
        }}
        dropdownRender={(menu) => (
          <div>
            {selectAll && clearAll && (
              <>
                <div style={{ display: "flex", flexWrap: "nowrap", padding: 8, gap: 6 }}>
                  <Button block onClick={onSelectAll}>
                    {t("select_all")}
                  </Button>
                  <Button onClick={onClearAll}>{t("clear")}</Button>
                </div>

                <Divider style={{ margin: "4px 0" }} />
              </>
            )}
            {menu}
          </div>
        )}
      >
        {children}
      </Select>
    </div>
  );
};

const SingleSelect = ({
  children,
  filterOption = optionsSearch,
  required = false,
  showSearch = true,
  bordered = false,
  useCommonStyle = true,
  style,
  ...props
}) => {
  return (
    <FloatLabel
      label={
        <Space size={4}>
          {required && <Text style={{ color: GRAY_PLACEHOLDER }}>*</Text>}
          <Text style={{ color: GRAY_PLACEHOLDER }}>{props.label}</Text>
        </Space>
      }
      name={props.label}
      value={props.value}
    >
      <Select
        bordered={bordered}
        filterOption={filterOption}
        showSearch={showSearch}
        style={{
          ...(useCommonStyle && commonStyle),
          padding: props.label ? "6px 0 0 0" : "",
          ...style
        }}
        placeholder={props.placeholder && (required ? `* ${props.placeholder}` : props.placeholder)}
        {...props}
      >
        {children}
      </Select>
    </FloatLabel>
  );
};

export { SingleSelect, MultiSelect };
