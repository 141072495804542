import React from "react";
import { Button, message, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "./CustomMessage.css";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/error-msg-icon.svg";
import { BLACK } from "../../../constant/colors";

const ErrorMessage = (msg, style = {}, { navigateTo, linkId, title, closeButtonId, styleOfLink } = {}) => {
  return (
    <>
      {message.error({
        key: "errorKey",
        content: (
          <Space style={{ color: BLACK, width: "100%", display: "flex", justifyContent: "space-between", ...style }}>
            {msg}
            {title && (
              <Button type="link" id={linkId} style={styleOfLink} onClick={navigateTo}>
                {title}
              </Button>
            )}
            <CloseOutlined
              style={{ color: BLACK, paddingLeft: "50px" }}
              id={closeButtonId}
              onClick={() => message.destroy("errorKey")}
            />
          </Space>
        ),
        className: "error-message",
        icon: <ErrorIcon />,
        onClick: () => message.destroy("errorKey")
      })}
    </>
  );
};

export default ErrorMessage;
