import { diffJson } from "diff";
import _ from "lodash";
import { sendRumEvent } from "@tailormed/common-client/store/saga/services/userMetrics";

/**
 * @typedef TimingResult
 * @property {number} time - The time it took to run the function
 * @property {any} result - The result of the function
 */

/**
 * Runs the given function and arguments, returning the result of the function
 * and the time it took to run it
 *
 * @param {Function} func - The function to run
 * @param {array} ...args - Arguments to pass to the function
 * @return {TimingResult}
 */
function time(func, ...args) {
  const start = performance.now();
  const result = func(...args);
  const end = performance.now();
  return {
    time: end - start,
    result
  };
}

/**
 * Creates a reducer that takes two child reducers and calculates the new state
 * with both of them, logging an error if they yield different results.
 *
 * Intended to be used when refactoring reducers, to check the original reducer
 * against the refactored one.
 *
 * By default the result from the new reducer is returned as the actual new
 * state. This is configurable with `opts.useOriginal`.
 *
 * To use the original reducer instead, set `useOriginal` to true.
 *
 * To use the original reducer for part of the state, and the new reducer for
 * the rest, pass an array of top-level keys in the state as `useOriginal`. The
 * two results will be merged together, with the specified keys from
 * `originalReducer` overwriting the state generated by `newReducer`. This is
 * mainly useful when progressively splitting out new state - you can set the
 * new state to use the original reducer, monitor error logs, and then remove
 * the list to use the new reducer once the state is verified to be the same.
 *
 * @param {Reducer} originalReducer - The original reducer, to use as a comparison baseline
 * @param {Reducer} [newReducer] - The new reducer, to check against the baseline. If not provided, this reducer is a no-op and simply returns originalReducer.
 * @param {object} opts
 * @param {number} [opts.checkChance] - If provided, sets the proportion of the time that the diff will be run. Use if performance is a concern. Defaults to 1 (always run)
 * @param {boolean|Array} [opts.useOriginal] - If set to true, use the original reducer. Set to an array to partially use the original reducer (see above)
 * @returns {(state, action) => State} The resulting reducer
 */
export default function crossCheckReducer(
  originalReducer,
  newReducer,
  { checkChance = 1, useOriginal = false, logger = console, debounceMs = 500 } = {}
) {
  if (!newReducer) {
    return originalReducer;
  }

  const compareReducers = _.debounce(async (before, after) => {
    try {
      if (Math.random() >= checkChance) {
        return;
      }
      const compare = time(() => {
        const diff = diffJson(before.result, after.result);
        const changes = diff.filter((change) => change.added || change.removed);
        if (changes.length) {
          const changedKeys = _.uniq(
            _.flatten(changes.map((change) => [...change.value.matchAll(/"(\w+)"/g)].map((match) => match[1])))
          );
          logger.error("Reducer regression", diff, changedKeys);
          sendRumEvent({ eventName: "reducer.regression", payload: { keys: changedKeys } });
        }
      });
      // Just a check to make sure events are working properly
      sendRumEvent({
        eventName: "reducer.check",
        payload: {
          before: before.time,
          after: after.time,
          compare: compare.time
        }
      });
      logger.debug(`Reducer timing: ${before.time} -> ${after.time} Compare: ${compare.time}`);
    } catch (e) {
      logger.error(`Failed to compare reducers`, e);
    }
  }, debounceMs);

  return (state, action) => {
    const before = time(originalReducer, state, action);
    const after = time(newReducer, state, action);

    compareReducers(before, after);

    if (Array.isArray(useOriginal)) {
      return {
        ...after.result,
        ..._.pick(before.result, useOriginal)
      };
    } else {
      return useOriginal ? before.result : after.result;
    }
  };
}
