import React, { useEffect, useState } from "react";
import { Col, Divider, Form, Input, Radio, Select, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { GRAY_500 } from "../../../../../../../constant/colors";
import { AdministeringProviderForm } from "./AdministeringProvider";
import { OtherFacilityAddressForm } from "./OtherFacilityAddress";
import { phoneNumberLengthRegEx } from "../../../../../../../utils/formValidation";
import { hcpFields, prescriptionFields } from "../../fieldNames";
import { FormDividerText, FormItem, InputPhone } from "../../../../components";
import { emailInputRules, onlyWhitespacesRule, zipInputRules, npiInputRule } from "../../../../utils";
import { SingleSelect } from "../../../../../../customComponent/CustomSelect";
import { getStateCode, States as STATES } from "../../../../../../../constant/states";
import { PhysicianSelect } from "../../../../../../Select";
import { sizes } from "../../../../../../../constant/styles";
import { OTHER, ProviderTypes, STATE } from "../../../../constants";
import { buildFieldValueMap } from "../../../../../../../utils/form";
import { selectProvidersDoctors, selectProvidersFacility } from "../../../../../../../store/selector";
import { getFieldsToUpdateOnProviderChange, getPhysicianFieldsToUpdate } from "../../../../utils/provider";
import { drugTypes } from "../prescription/drugDefinitions";

const locationDictionary = [
  {
    label: "Provider's Office",
    value: "providersOffice"
  },
  {
    label: "Hospital Outpatient",
    value: "hospitalOutpatient"
  },
  {
    label: "Hospital Inpatient",
    value: "hospitalInpatient"
  },
  {
    label: "Other",
    value: "other"
  },
  {
    label: "N/A",
    value: "n/a"
  }
];

const { Option } = Select;

const formToProviderFieldMap = [
  [hcpFields.providerNpi, "npi"],
  [hcpFields.providerStateLicense, "stateLicense"],
  [hcpFields.providerDea, "dea"]
];

const formToFacilityFieldMap = [
  [hcpFields.facilityName, "name"],
  [hcpFields.facilityGroupTaxId, "taxId"],
  [hcpFields.facilityAddress, "address"],
  [hcpFields.facilityCity, "city"],
  [hcpFields.facilityState, "state"],
  [hcpFields.facilityZip, "zipCode"],
  [hcpFields.facilityContactPhone, "phone"],
  [hcpFields.facilityContactFax, "fax"],
  [hcpFields.facilityContactEmail, "email"]
];

const facilitySectionName = "facility";
const providerSectionName = "provider";

export default function HCP() {
  const [isPhysicianSelectOpen, setIsPhysicianSelectOpen] = useState(false);
  const [isFacilitySelectOpen, setIsFacilitySelectOpen] = useState(false);

  const physicians = useSelector(selectProvidersDoctors);
  const facilities = useSelector(selectProvidersFacility);

  const form = Form.useFormInstance();
  const shippingType = Form.useWatch(hcpFields.shippingType, form);
  const physician = Form.useWatch(hcpFields.providerId, form);
  const facility = Form.useWatch(hcpFields.facilityId, form);

  const drug = Form.useWatch(prescriptionFields.drug, form);
  const isOralDrug = drug?.category === drugTypes.ORAL;

  const { t } = useTranslation();

  useEffect(() => {
    form.validateFields();
  }, [shippingType, physician, facility, drug]);

  const onPhysicianChange = (physician) =>
    onProviderChange(
      physician,
      formToProviderFieldMap,
      ProviderTypes.Physician,
      getPhysicianFieldsToUpdate(
        form,
        physicians,
        hcpFields.providerId,
        hcpFields.providerFirstName,
        hcpFields.providerLastName
      )
    );

  const onFacilityChange = (facility) => onProviderChange(facility, formToFacilityFieldMap, ProviderTypes.Facility);

  const onProviderChange = async (providerId, formFieldMap, providerType, additionalFieldsToUpdate) => {
    let providers;
    let formSectionName;
    if (providerType === ProviderTypes.Facility) {
      providers = facilities;
      formSectionName = facilitySectionName;
    } else {
      providers = physicians;
      formSectionName = providerSectionName;
    }

    let fieldsToUpdate = getFieldsToUpdateOnProviderChange(
      providers,
      providerId,
      formFieldMap,
      additionalFieldsToUpdate
    );

    if (fieldsToUpdate[STATE]) fieldsToUpdate[STATE] = getStateCode(fieldsToUpdate[STATE]);

    form.setFieldsValue({ [formSectionName]: { ...fieldsToUpdate } });
  };

  const physicianColSpan = physician === OTHER ? 8 : 24;
  const taxIdAndTypeColSpan = facility === OTHER ? 12 : 6;
  const facilityColSpan = facility === OTHER ? 12 : 0;

  return (
    <>
      <Divider
        id="enrollment-form-hcp-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.pfizer.hcp.labels.header")}</FormDividerText>
      </Divider>
      <Row gutter={14}>
        <Col span={physicianColSpan}>
          <FormItem
            name={hcpFields.providerId}
            label={t("application_form_editor.pfizer.hcp.labels.provider_full_name")}
            rules={[{ required: true }]}
          >
            <PhysicianSelect
              onDropdownVisibleChange={setIsPhysicianSelectOpen}
              open={isPhysicianSelectOpen}
              id="EE_Pfizer_copay_physician_select"
              placeholder={t("application_form_editor.pfizer.hcp.placeholders.provider_full_name")}
              onChange={(physician) => onPhysicianChange(physician)}
              onClear={() => onPhysicianChange(null)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Button
                    id="EE_genentech_copay_other_provider_id"
                    type="text"
                    style={{ width: "100%", textAlign: "start", fontSize: sizes.medium, background: "transparent" }}
                    onClick={() => {
                      form.setFieldsValue(buildFieldValueMap([[hcpFields.providerId, OTHER]]));
                      setIsPhysicianSelectOpen(false);
                      onPhysicianChange(null);
                    }}
                  >
                    {t("application_form_editor.pfizer.hcp.other")}
                  </Button>
                </>
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            hidden={physician !== OTHER}
            name={hcpFields.providerFirstName}
            label={t("application_form_editor.pfizer.hcp.labels.provider_first_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.provider_first_name")} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            hidden={physician !== OTHER}
            name={hcpFields.providerLastName}
            label={t("application_form_editor.pfizer.hcp.labels.provider_last_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.provider_last_name")} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            name={hcpFields.providerDesignation}
            label={t("application_form_editor.pfizer.hcp.labels.provider_designation")}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.provider_designation")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.providerNpi}
            label={t("application_form_editor.pfizer.hcp.labels.provider_npi")}
            rules={[{ required: true }, npiInputRule, onlyWhitespacesRule]}
          >
            <Input maxLength={10} placeholder={t("application_form_editor.pfizer.hcp.placeholders.provider_npi")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.providerStateLicense}
            label={t("application_form_editor.pfizer.hcp.labels.provider_state_license")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.provider_state_license")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem name={hcpFields.providerDea} label={t("application_form_editor.pfizer.hcp.labels.provider_dea")}>
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.provider_dea")} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12}>
          <FormItem
            name={hcpFields.facilityId}
            label={t("application_form_editor.pfizer.hcp.labels.facility_name")}
            rules={[{ required: true }]}
          >
            <SingleSelect
              useCommonStyle={false}
              listHeight={100}
              onDropdownVisibleChange={setIsFacilitySelectOpen}
              bordered={true}
              allowClear={true}
              placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_id")}
              open={isFacilitySelectOpen}
              onChange={(facility) => onFacilityChange(facility)}
              onClear={() => onFacilityChange(null)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Button
                    id="EE_genentech_copay_other_facility_id"
                    type="text"
                    style={{ width: "100%", textAlign: "start", fontSize: sizes.medium, background: "transparent" }}
                    onClick={() => {
                      form.setFieldsValue(buildFieldValueMap([[hcpFields.facilityId, OTHER]]));
                      setIsFacilitySelectOpen(false);
                      onFacilityChange(null);
                    }}
                  >
                    {t("application_form_editor.genentech.copay.physician_and_facility.other")}
                  </Button>
                </>
              )}
            >
              {facilities?.map((p, index) => (
                <Option key={index} value={p.id}>
                  {p.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
        <Col span={facilityColSpan}>
          <FormItem
            hidden={facility !== OTHER}
            name={hcpFields.facilityName}
            label={t("application_form_editor.pfizer.hcp.labels.facility_name")}
            rules={[{ required: true }]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_name")} />
          </FormItem>
        </Col>
        <Col span={taxIdAndTypeColSpan}>
          <FormItem
            name={hcpFields.facilityGroupTaxId}
            label={t("application_form_editor.pfizer.hcp.labels.facility_group_tax_id")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_group_tax_id")} />
          </FormItem>
        </Col>
        <Col span={taxIdAndTypeColSpan}>
          <FormItem
            name={hcpFields.facilityType}
            label={t("application_form_editor.pfizer.hcp.labels.facility_type")}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_type")}
              options={locationDictionary.map((locationDef, index) => {
                return { label: locationDef.label, value: locationDef.value, key: index };
              })}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.facilityAddress}
            label={t("application_form_editor.pfizer.hcp.labels.facility_street_address")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_street_address")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.facilityCity}
            label={t("application_form_editor.pfizer.hcp.labels.facility_city")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_city")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.facilityState}
            label={t("application_form_editor.pfizer.hcp.labels.facility_state")}
            rules={[{ required: true }]}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_state")}
            >
              {Object.values(STATES).map((state, index) => (
                <Option key={index} value={state.code}>
                  {state.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, ...zipInputRules]}
            name={hcpFields.facilityZip}
            label={t("application_form_editor.pfizer.hcp.labels.facility_zip_code")}
          >
            <Input maxLength={5} placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_zip_code")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.facilityContactFullName}
            label={t("application_form_editor.pfizer.hcp.labels.facility_contact_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_contact_name")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.facilityContactPhone}
            label={t("application_form_editor.pfizer.hcp.labels.facility_contact_phone")}
            rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
          >
            <InputPhone placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_contact_phone")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.facilityContactFax}
            label={t("application_form_editor.pfizer.hcp.labels.facility_contact_fax")}
            rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
          >
            <InputPhone placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_contact_fax")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.facilityContactEmail}
            label={t("application_form_editor.pfizer.hcp.labels.facility_contact_email")}
            rules={[{ required: true }, ...emailInputRules]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.facility_contact_email")} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={24}>
          <FormItem
            rules={[{ required: true }]}
            name={hcpFields.shippingType}
            label={t("application_form_editor.pfizer.hcp.labels.ship_to")}
          >
            <Radio.Group>
              <Radio value="patientAddress">{t("application_form_editor.pfizer.hcp.labels.patient_address")}</Radio>
              <Radio value="hcp">{t("application_form_editor.pfizer.hcp.labels.hcp_site_of_care")}</Radio>
              {!isOralDrug && (
                <Radio value="administeringProvider">
                  {t("application_form_editor.pfizer.hcp.labels.administering_provider_address")}
                </Radio>
              )}
              <Radio value="other">{t("application_form_editor.pfizer.hcp.labels.other_address")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      {shippingType === "other" && <OtherFacilityAddressForm />}
      <AdministeringProviderForm />
    </>
  );
}
