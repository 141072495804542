import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Form, Input } from "antd";
import { useSelector } from "react-redux";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { selectProvidersDoctors } from "../../../../../../../store/selector";
import { practiceFields as prescriberFields } from "../../../../components/common/fieldNames";
import { sizes } from "../../../../../../../constant/styles";
import { OTHER } from "../../../../constants";
import { practiceFields } from "../fieldNames";
import { getStateCode } from "../../../../../../../constant/states";
import { buildFieldValueMap } from "../../../../../../../utils/form";

const facilitiesMapping = {
  name: practiceFields.facilityName,
  npi: practiceFields.facilityNpi,
  address: practiceFields.address,
  city: practiceFields.city,
  state: practiceFields.state,
  zipCode: practiceFields.zip,
  taxId: practiceFields.facilityTaxId
};

const prescriberMapping = {
  firstName: prescriberFields.prescriberFirstName,
  lastName: prescriberFields.prescriberLastName,
  npi: prescriberFields.prescriberNpi,
  taxId: prescriberFields.groupTaxId
};
export const updateFormFields = (entity, mappings, form) => {
  const updates = Object.entries(mappings).reduce((acc, [entityKey, fieldPath]) => {
    // Handle special case for the state field
    const value = entityKey === "state" ? getStateCode(entity[entityKey]) : entity[entityKey];

    // Assuming fieldPath is an array, directly use it for constructing the nested structure
    fieldPath.reduce((current, part, index) => {
      if (index === fieldPath.length - 1) {
        current[part] = value;
        return;
      }
      current[part] = current[part] || {};
      return current[part];
    }, acc);

    return acc;
  }, {});

  form.setFieldsValue(updates);
};

export default function GenentechFreeDrugPractice() {
  const practiceFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.GENENTECH_PAP)().practice;
  const [isPhysicianSelectOpen, setIsPhysicianSelectOpen] = useState(false);
  const [isFacilitySelectOpen, setIsFacilitySelectOpen] = useState(false);
  const { t } = useTranslation();

  const physicians = useSelector(selectProvidersDoctors);

  const parsedPhysicians = physicians.map(({ name, ...props }) => ({
    ...props,
    firstName: name.split(" ")[0],
    lastName: name.split(" ")[1]
  }));

  const form = Form.useFormInstance();
  const physician = Form.useWatch(prescriberFields.id, form);
  const facility = Form.useWatch(practiceFields.facilityId, form);

  useEffect(() => {
    form.validateFields();
  }, [physician, facility]);

  const onOtherClick = () => {
    const newValues = buildFieldValueMap([
      [prescriberFields.id, OTHER],
      [prescriberFields.prescriberFirstName, ""],
      [(prescriberFields.prescriberLastName, "")]
    ]);
    form.setFieldsValue(newValues);

    setIsPhysicianSelectOpen(false);
  };

  const dropdownRender = (menu) => (
    <>
      {menu}
      <Divider style={{ margin: "8px 0" }} />
      <Button
        id="EE_genentech_free_drug_other_provider_id"
        type="text"
        style={{ width: "100%", textAlign: "start", fontSize: sizes.medium, background: "transparent" }}
        onClick={onOtherClick}
      >
        {t("application_form_editor.genentech.free_drug.practice.other")}
      </Button>
    </>
  );

  const onPrescriberChange = (physicianId) => {
    const physician = parsedPhysicians.find((obj) => obj.id === physicianId) || {};
    updateFormFields(physician, prescriberMapping, form);
  };

  const onFacilityChange = (facility) => updateFormFields(facility, facilitiesMapping, form);

  const prescriberSelect = practiceFormComponents.prescriberSelect({
    name: prescriberFields.id,
    onChange: onPrescriberChange,
    onDropdownVisibleChange: setIsPhysicianSelectOpen,
    isOpen: isPhysicianSelectOpen,
    required: true,
    dropdownRender
  });

  return (
    <SectionPart
      sectionId="practice"
      headerTranslationKey="application_form_editor.genentech.free_drug.practice.labels.header"
    >
      <Form.Item name={prescriberFields.prescriberFirstName} style={{ display: "none" }}>
        <Input type={"hidden"} />
      </Form.Item>
      <Form.Item name={prescriberFields.prescriberLastName} style={{ display: "none" }}>
        <Input type={"hidden"} />
      </Form.Item>
      <Form.Item name={practiceFields.facilityName} style={{ display: "none" }}>
        <Input type={"hidden"} />
      </Form.Item>
      {physician !== OTHER ? (
        <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
          {[
            prescriberSelect,
            practiceFormComponents.prescriberNpi({ required: true }),
            practiceFormComponents.groupTaxId({
              required: false
            })
          ]}
        </ConfigurableRow>
      ) : (
        <>
          <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
            {[
              prescriberSelect,
              practiceFormComponents.prescriberFirstName({ required: true }),
              practiceFormComponents.prescriberLastName({ required: true })
            ]}
          </ConfigurableRow>
          <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_2}>
            {[
              practiceFormComponents.prescriberNpi({ required: true }),
              practiceFormComponents.groupTaxId({
                required: false
              })
            ]}
          </ConfigurableRow>
        </>
      )}
      {practiceFormComponents.practiceDivider()}
      <ConfigurableRow config={ROW_OPTIONS.EQUAL} filterFalsy>
        {[
          practiceFormComponents.facilitySelect({
            required: true,
            isOpen: isFacilitySelectOpen,
            onDropdownVisibleChange: setIsFacilitySelectOpen,
            onChange: onFacilityChange
          }),
          practiceFormComponents.address({ required: true }),
          practiceFormComponents.city({ required: true })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          practiceFormComponents.state({ required: true }),
          practiceFormComponents.zip({ required: true }),
          practiceFormComponents.facilityNpi({}),
          practiceFormComponents.facilityTaxId({ required: false })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          practiceFormComponents.contactFirstName({ required: true }),
          practiceFormComponents.contactLastName({ required: true }),
          practiceFormComponents.contactPhone({ required: true }),
          practiceFormComponents.contactFax({ required: true })
        ]}
      </ConfigurableRow>
    </SectionPart>
  );
}
