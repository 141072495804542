import { SidebarIcon } from "../../../components";

const getPfizerCopayIVSidebarItems = (t) => [
  {
    title: t("application_form_editor.pfizer.copay.iv.patient.sidebar"),
    href: "#enrollment-form-copay-iv-patient-information-anchor",
    key: "patient",
    validationFields: ["patient"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.pfizer.copay.iv.practice.sidebar"),
    href: "#enrollment-form-copay-iv-practice-information-anchor",
    key: "practice",
    validationFields: ["facility"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.pfizer.copay.iv.consent.sidebar"),
    href: "#enrollment-form-eligibility_questionnaire",
    key: "consent",
    validationFields: ["eligibilityQuestionnaire"],
    icon: SidebarIcon
  }
];

export default getPfizerCopayIVSidebarItems;
