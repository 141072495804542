import React from "react";

export default function ActiveStarIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7933 5.30735L11.947 5.55563L12.2282 5.63459L17.2439 7.04326L13.958 11.2968L13.7886 11.5161L13.8024 11.7928L14.0739 17.2191L9.27995 15.289L8.99982 15.1762L8.71969 15.289L3.9265 17.2191L4.19792 11.7928L4.21176 11.5161L4.04238 11.2968L0.756323 7.0431L5.77151 5.63459L6.05265 5.55563L6.20638 5.30735L8.99984 0.795831L11.7933 5.30735Z"
        stroke="#2B56A3"
        strokeWidth="1.5"
      />
    </svg>
  );
}
