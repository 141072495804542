const States = Object.freeze({
  ALABAMA: { name: "ALABAMA", code: "AL" },
  ALASKA: { name: "ALASKA", code: "AK" },
  ARIZONA: { name: "ARIZONA", code: "AZ" },
  ARKANSAS: { name: "ARKANSAS", code: "AR" },
  CALIFORNIA: { name: "CALIFORNIA", code: "CA" },
  COLORADO: { name: "COLORADO", code: "CO" },
  CONNECTICUT: { name: "CONNECTICUT", code: "CT" },
  DELAWARE: { name: "DELAWARE", code: "DE" },
  FLORIDA: { name: "FLORIDA", code: "FL" },
  GEORGIA: { name: "GEORGIA", code: "GA" },
  GUAM: { name: "GUAM", code: "GU" },
  HAWAII: { name: "HAWAII", code: "HI" },
  IDAHO: { name: "IDAHO", code: "ID" },
  ILLINOIS: { name: "ILLINOIS", code: "IL" },
  INDIANA: { name: "INDIANA", code: "IN" },
  IOWA: { name: "IOWA", code: "IA" },
  KANSAS: { name: "KANSAS", code: "KS" },
  KENTUCKY: { name: "KENTUCKY", code: "KY" },
  LOUISIANA: { name: "LOUISIANA", code: "LA" },
  MAINE: { name: "MAINE", code: "ME" },
  MARYLAND: { name: "MARYLAND", code: "MD" },
  MASSACHUSETTS: { name: "MASSACHUSETTS", code: "MA" },
  MICHIGAN: { name: "MICHIGAN", code: "MI" },
  MINNESOTA: { name: "MINNESOTA", code: "MN" },
  MISSISSIPPI: { name: "MISSISSIPPI", code: "MS" },
  MISSOURI: { name: "MISSOURI", code: "MO" },
  MONTANA: { name: "MONTANA", code: "MT" },
  NEBRASKA: { name: "NEBRASKA", code: "NE" },
  NEVADA: { name: "NEVADA", code: "NV" },
  NEW_HAMPSHIRE: { name: "NEW HAMPSHIRE", code: "NH" },
  NEW_JERSEY: { name: "NEW JERSEY", code: "NJ" },
  NEW_MEXICO: { name: "NEW MEXICO", code: "NM" },
  NEW_YORK: { name: "NEW YORK", code: "NY" },
  NORTH_CAROLINA: { name: "NORTH CAROLINA", code: "NC" },
  NORTH_DAKOTA: { name: "NORTH DAKOTA", code: "ND" },
  OHIO: { name: "OHIO", code: "OH" },
  OKLAHOMA: { name: "OKLAHOMA", code: "OK" },
  OREGON: { name: "OREGON", code: "OR" },
  PENNSYLVANIA: { name: "PENNSYLVANIA", code: "PA" },
  PUERTO_RICO: { name: "PUERTO RICO", code: "PR" },
  RHODE_ISLAND: { name: "RHODE ISLAND", code: "RI" },
  SOUTH_CAROLINA: { name: "SOUTH CAROLINA", code: "SC" },
  SOUTH_DAKOTA: { name: "SOUTH DAKOTA", code: "SD" },
  TENNESSEE: { name: "TENNESSEE", code: "TN" },
  TEXAS: { name: "TEXAS", code: "TX" },
  UTAH: { name: "UTAH", code: "UT" },
  VERMONT: { name: "VERMONT", code: "VT" },
  VIRGINIA: { name: "VIRGINIA", code: "VA" },
  VIRGIN_ISLANDS: { name: "VIRGIN ISLANDS", code: "VI" },
  WASHINGTON: { name: "WASHINGTON", code: "WA" },
  WASHINGTON_DC: { name: "WASHINGTON D.C.", code: "DC" },
  WEST_VIRGINIA: { name: "WEST VIRGINIA", code: "WV" },
  WISCONSIN: { name: "WISCONSIN", code: "WI" },
  WYOMING: { name: "WYOMING", code: "WY" }
});

const getStateCodeByName = (stateName) => {
  if (stateName) {
    const formattedStateName = stateName.toUpperCase();
    for (const state of Object.values(States)) {
      if (state.name === formattedStateName) {
        return state.code;
      }
    }
  }
  return null;
};

const checkIfCodeExists = (stateCode) => {
  if (stateCode) {
    const formattedStateCode = stateCode.toUpperCase();
    for (const state of Object.values(States)) {
      if (state.code === formattedStateCode) {
        return true;
      }
    }
  }
  return false;
};

const getStateCode = (state) => {
  let stateCode = getStateCodeByName(state);
  if (stateCode) return stateCode;
  else if (checkIfCodeExists(state)) return state.toUpperCase();
  return null;
};

export { States, getStateCode };
