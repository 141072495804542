const formSections = {
  PATIENT_SECTION: "patient",
  PATIENT_CONSENT_SECTION: "patientConsent",
  INSURANCE_SECTION: "insurance",
  MEDICAL_SECTION: "medical",
  PRESCRIPTION_SECTION: "prescription",
  PRACTICE_SECTION: "practice",
  SUBMIT_ATTESTATION_SECTION: "submitAttestation",
  TREATMENT_SITE_SECTION: "treatmentSite"
};

export { formSections };
