import React, { useEffect, useState } from "react";
import { Col, Divider, Input, Select, Row, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { phoneNumberLengthRegEx } from "../../../../../../../../utils/formValidation";
import { npiInputRule, onlyWhitespacesRule, zipInputRules } from "../../../../../utils";
import { physicianAndFacilityFields } from "../../fieldNames";
import { FormItem, InputPhone } from "../../../../../components";
import { buildFieldValueMap } from "../../../../../../../../utils/form";
import { selectProvidersFacility } from "../../../../../../../../store/selector";
import { sizes } from "../../../../../../../../constant/styles";
import { SingleSelect } from "../../../../../../../customComponent/CustomSelect";
import { States as STATES } from "../../../../../../../../constant/states";
import { onProviderChange } from "../PhysicianAndFacility";
import { OTHER, ProviderTypes } from "../../../../../constants";
import { InfoNotice } from "../../../../../components/InfoNotice";

const { Option } = Select;

const formToFacilityFieldMap = [
  [physicianAndFacilityFields.facilityName, "name"],
  [physicianAndFacilityFields.facilityAddress, "address"],
  [physicianAndFacilityFields.facilityCity, "city"],
  [physicianAndFacilityFields.facilityState, "state"],
  [physicianAndFacilityFields.facilityZip, "zipCode"],
  [physicianAndFacilityFields.facilityPhone, "phone"],
  [physicianAndFacilityFields.facilityNpi, "npi"]
];

export function Facility() {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const facilities = useSelector(selectProvidersFacility);

  const [isFacilitySelectOpen, setIsFacilitySelectOpen] = useState(false);

  const facility = Form.useWatch(physicianAndFacilityFields.facilityId, form);

  const handleFacilityChange = (facility) => {
    onProviderChange(facility, formToFacilityFieldMap, ProviderTypes.Facility, facilities, form);
  };

  useEffect(() => {
    form.validateFields();
  }, [facility]);

  return (
    <>
      <Row gutter={14}>
        <Col span={8}>
          <FormItem
            name={physicianAndFacilityFields.facilityId}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.facility_name")}
          >
            <SingleSelect
              useCommonStyle={false}
              listHeight={100}
              onDropdownVisibleChange={(visible) => setIsFacilitySelectOpen(visible)}
              bordered={true}
              allowClear={true}
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.facility_select_name"
              )}
              open={isFacilitySelectOpen}
              onChange={(facility) => handleFacilityChange(facility)}
              onClear={() => handleFacilityChange(null)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Button
                    id="EE_genentech_copay_other_facility_id"
                    type="text"
                    style={{ width: "100%", textAlign: "start", fontSize: sizes.medium, background: "transparent" }}
                    onClick={() => {
                      form.setFieldsValue(buildFieldValueMap([[physicianAndFacilityFields.facilityId, OTHER]]));
                      setIsFacilitySelectOpen(false);
                      handleFacilityChange(null);
                    }}
                  >
                    {t("application_form_editor.genentech.copay.physician_and_facility.other")}
                  </Button>
                </>
              )}
            >
              {facilities?.map((p, index) => (
                <Option key={index} value={p.id}>
                  {p.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
        <Col span={facility !== OTHER ? 0 : 8}>
          <FormItem
            hidden={facility !== OTHER}
            name={physicianAndFacilityFields.facilityName}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.facility_name")}
            rules={[onlyWhitespacesRule]}
          >
            <Input
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.facility_name"
              )}
            />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem
            rules={[onlyWhitespacesRule]}
            name={physicianAndFacilityFields.facilityAddress}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.facility_address")}
          >
            <Input
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.facility_address"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[onlyWhitespacesRule]}
            name={physicianAndFacilityFields.facilityCity}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.facility_city")}
          >
            <Input
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.facility_city"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name={physicianAndFacilityFields.facilityState}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.facility_state")}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.facility_state"
              )}
            >
              {Object.values(STATES).map((state, index) => (
                <Option key={index} value={state.code}>
                  {state.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name={physicianAndFacilityFields.facilityZip}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.facility_zip")}
            rules={[...zipInputRules, onlyWhitespacesRule]}
          >
            <Input
              maxLength={5}
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.facility_zip"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[{ pattern: phoneNumberLengthRegEx, message: "" }]}
            name={physicianAndFacilityFields.facilityPhone}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.facility_phone")}
          >
            <InputPhone
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.facility_phone"
              )}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule, npiInputRule]}
            name={physicianAndFacilityFields.facilityNpi}
            label={t("application_form_editor.genentech.copay.physician_and_facility.labels.facility_npi")}
          >
            <Input
              maxLength={10}
              placeholder={t(
                "application_form_editor.genentech.copay.physician_and_facility.placeholders.facility_npi"
              )}
            />
          </FormItem>
        </Col>
        <Col span={16}>
          <FormItem>
            <InfoNotice text={t("application_form_editor.common.practice_npi_notification")} fontSize={sizes.medium} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
}
