import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "antd";
const { Text } = Typography;

import { fontWeights, sizes } from "../constant/styles";
import { ERROR_50, ERROR_600 } from "../constant/colors";

export const DeceasedLable = () => {
  const { t } = useTranslation();

  return (
    <Text
      style={{
        width: "105px",
        display: "flex",
        justifyContent: "center",
        background: ERROR_50,
        color: ERROR_600,
        fontWeight: fontWeights.bold,
        fontSize: sizes.medium,
        borderRadius: "16px"
      }}
    >
      {t("patientTopSection.deceased")}
    </Text>
  );
};
