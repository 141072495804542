import moment from "moment-timezone";
import { DENIED, NEW_PENDING_PAYMENT, PAID, PENDING, SUBMITTED } from "../constant/claim";

export const findLatestClaimDate = (claims) => {
  const today = moment().startOf("day");
  const activeClaims = claims.filter((claim) => claim.isActive);

  const futureClaimDates = activeClaims
    .filter((claim) => moment(claim.claimDate).startOf("day").isSameOrAfter(today))
    .sort((a, b) => moment(a.claimDate).startOf("day").diff(moment(b.claimDate).startOf("day")));

  const pastClaimDates = activeClaims
    .filter((claim) => moment(claim.claimDate).startOf("day").isBefore(today))
    .sort((a, b) => moment(b.claimDate).startOf("day").diff(moment(a.claimDate).startOf("day")));

  return futureClaimDates.length > 0
    ? futureClaimDates[0].claimDate
    : pastClaimDates.length > 0
    ? pastClaimDates[0].claimDate
    : null;
};

export const calculateTotalPaidAmount = (claims) => {
  return claims
    .filter((claim) => [PAID, DENIED].includes(claim.status) && claim.isActive)
    .reduce((accumulator, claim) => accumulator + (claim.paidAmount || 0), 0);
};

export const calculateTotalPendingAmount = (claims) => {
  return claims
    .filter((claim) => [PENDING, SUBMITTED, NEW_PENDING_PAYMENT].includes(claim.status) && claim.isActive)
    .reduce((accumulator, claim) => accumulator + (claim.totalClaimsAmount || 0), 0);
};
