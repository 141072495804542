import { ASCEND } from "../constant/sort";
import { formatClaimServiceDates, getSplitDates } from "./date";

export const nullSort = (a, b, sortDirection, sortFunc) => {
  if (!a && !b) return 0;
  else if (!a) return sortDirection === ASCEND ? 1 : -1;
  else if (!b) return sortDirection === ASCEND ? -1 : 1;
  return sortFunc();
};

export const dateSort = (a, b, sortDirection) => {
  return nullSort(a, b, sortDirection, () => {
    return Date.parse(a) < Date.parse(b) ? -1 : 1;
  });
};

export const stringSort = (a, b, sortDirection) => {
  return nullSort(a, b, sortDirection, () => {
    a = typeof a === "string" ? a.toLowerCase() : a.toString();
    b = typeof b === "string" ? b.toLowerCase() : b.toString();

    return a.localeCompare(b);
  });
};

export const sortByDueDate = (inputArr) => {
  return inputArr.sort((A, B) => {
    const dateA = new Date(A.dueDate);
    const dateB = new Date(B.dueDate);

    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
  });
};

export const sortServiceDateFormatted = (a, b) => {
  const aServiceDates = formatClaimServiceDates(a.claimLineItems);
  const bServiceDates = formatClaimServiceDates(b.claimLineItems);

  const [aStartDate, aEndDate] = getSplitDates(aServiceDates);
  const [bStartDate, bEndDate] = getSplitDates(bServiceDates);

  if (!aStartDate || !bStartDate) {
    if (!aStartDate && !bStartDate) {
      return 0;
    } else if (!aStartDate) {
      return -1;
    } else {
      return 1;
    }
  }

  if (aStartDate.getTime() === bStartDate.getTime()) {
    return aEndDate - bEndDate;
  } else {
    return aStartDate - bStartDate;
  }
};

export const filterSort = (A, B, orderBy = "localSortKey") => {
  return A[orderBy].localeCompare(B[orderBy], "en", { sensitivity: "case", numeric: true });
};

export const sortListByOrder = (list, order, key) => {
  if (!list.length) return [];

  const sortedList = [];

  order.forEach((orderedItem) => {
    const foundItem = list.find((item) => item[key] === orderedItem[key]);
    if (foundItem) {
      sortedList.push(foundItem);
    }
  });

  list.forEach((item) => {
    if (!order.some((orderedItem) => orderedItem[key] === item[key])) {
      sortedList.push(item);
    }
  });

  return sortedList;
};

export const getSortingChanges = (sorting, sorter) => {
  let { order: nextSortBy, columnKey: nextColumnName } = sorter;
  const columnIsChanged = nextColumnName !== sorter.columnName;

  const prevSortBy = sorting.order;
  const sortDirectionIsChanged = prevSortBy !== nextSortBy;

  return { columnIsChanged, sortDirectionIsChanged, nextColumnName, nextSortBy };
};
