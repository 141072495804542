import { getPurePhoneNumber } from "../../../../../../../utils/misc";

export const getPfizerCopayIvFormInitialValues = (applicationForm) => {
  const DEFAULT_PHONE_TYPE = "mobile";

  const patientState =
    applicationForm?.formData?.patient?.state === "" ? null : applicationForm?.formData?.patient?.state;

  const initialValues = {
    ...applicationForm?.formData,
    patient: {
      ...applicationForm?.formData?.patient,
      state: patientState,
      phoneType: applicationForm?.formData?.patient?.phoneType || DEFAULT_PHONE_TYPE,
      phone: applicationForm?.formData?.patient?.phone
        ? getPurePhoneNumber(applicationForm?.formData?.patient?.phone)
        : null
    },
    facility: {
      ...applicationForm?.formData?.facility
    }
  };

  return initialValues;
};
