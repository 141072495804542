import { ExclamationCircleOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Affix, Typography, Button as AntButton } from "antd";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ManagePatientDetailsContext } from "./context";
import { font14pxSB } from "../Typography";
import { ConflictType } from "./ConflictType";
import { ERROR_25, ERROR_300, ERROR_600, GRAY_500, GRAY_900 } from "../../constant/colors";
import { transparentize } from "polished";

const Text = Typography.Text;

const Button = styled(AntButton)`
  padding: 0;
  width: 1em;
  height: 1em;
  cursor: pointer;
`;

const ConflictNavigatorWrapper = styled.div`
  background: ${transparentize(0.2, ERROR_25)};
  backdrop-filter: blur(7px);
  border: 1px solid ${ERROR_300};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 16px;
  display: flex;
`;

const flex = css`
  flex: 1;
  display: flex;
  align-items: center;
`;

const LeftAlignFlex = styled.div`
  ${flex}
  text-align: left;
  svg {
    color: ${ERROR_600};
  }
`;

const RightAlignFlex = styled.div`
  ${flex}
  text-align: right;
  justify-content: flex-end;
`;

const ConflictNavigatorTitle = styled(Text)`
  ${font14pxSB}
  color: ${ERROR_600};
  margin-left: 14px;
`;
const ConflictSourceType = styled(Text)`
  ${font14pxSB}
  color: ${({ conflictAlertIndex }) => (conflictAlertIndex !== -1 ? GRAY_500 : "transparent")};
  margin-right: 8px;
`;
const ConflictPager = styled(Text)`
  ${font14pxSB}
  color: ${({ conflictAlertIndex }) => (conflictAlertIndex !== -1 ? GRAY_900 : "transparent")};
  margin-right: 24px;
`;

const ConflictPagerButtonBack = styled.div`
  ${flex}
  color: ${({ conflictAlertIndex }) => (conflictAlertIndex !== -1 ? "black" : "transparent")};
  flex: 0;
  margin-right: 18px;
`;
const ConflictPagerButtonForward = styled.div`
  ${flex}
  color: ${({ conflictAlertIndex, numOfConflict }) =>
    conflictAlertIndex !== numOfConflict - 1 ? "black" : "transparent"};
  flex: 0;
`;

const AffixPaddingContainer = styled.div`
  padding-top: 16px;
`;

const ConflictSourceTypeTitleMap = {
  [ConflictType.diagnosis]: "Diagnosis",
  [ConflictType.demographic]: "Demographic",
  [ConflictType.coverage]: "Coverage"
};

const sectionsAnchorMap = {
  [ConflictType.demographic]: "#manage-patient-details-personal",
  [ConflictType.coverage]: "#manage-patient-details-coverages",
  [ConflictType.diagnosis]: "#manage-patient-details-medical"
};

const handleScrollTo = (currentConflictType) => {
  const anchorValue = sectionsAnchorMap[currentConflictType];
  if (anchorValue && document.getElementById(anchorValue.substring(1))) {
    const elementOffset = document.getElementById(anchorValue.substring(1)).offsetTop - 65;
    window.scrollTo({ top: elementOffset, behavior: "smooth" });
  }
};

const prepareConflictsList = (rawConflictsList) => {
  const conflictsBySectionOrderMap = {
    [ConflictType.demographic]: [],
    [ConflictType.coverage]: [],
    [ConflictType.diagnosis]: []
  };

  for (const conflict of rawConflictsList) {
    const _value = JSON.stringify(conflict);
    const sectionConflicts = conflictsBySectionOrderMap[conflict.conflictType];
    const isExist = sectionConflicts.findIndex((obj) => {
      return JSON.stringify(obj) === _value;
    });
    if (isExist === -1) {
      conflictsBySectionOrderMap[conflict.conflictType].push(conflict);
    }
  }
  return Object.values(conflictsBySectionOrderMap).flat();
};

const ConflictNavigator = () => {
  const { conflictsData, focusNextConflictAlert, focusPrevConflictAlert, conflictAlertIndex } =
    useContext(ManagePatientDetailsContext);
  const { t } = useTranslation();
  const [conflictsList, setConflictsList] = useState([]);

  useEffect(() => {
    if (conflictsData.length === 0) {
      setConflictsList([]);
    } else {
      const conflictsBySectionOrder = prepareConflictsList(conflictsData);
      setConflictsList(conflictsBySectionOrder);
    }
  }, [conflictsData]);

  const currentConflictType = conflictsList[conflictAlertIndex]?.conflictType;
  const conflictSourceTypeTitle = ConflictSourceTypeTitleMap[currentConflictType] || "";

  const onClickBack = () => {
    focusPrevConflictAlert();
  };
  const onClickNext = () => {
    focusNextConflictAlert();
  };

  if (!conflictsData?.length) {
    return null;
  }

  return (
    <Affix offsetTop={130}>
      <AffixPaddingContainer>
        <ConflictNavigatorWrapper>
          <LeftAlignFlex>
            <ExclamationCircleOutlined />
            <ConflictNavigatorTitle>
              {conflictsList.length} {t("managePatientDetails.conflicts_detected")}
            </ConflictNavigatorTitle>
          </LeftAlignFlex>
          <RightAlignFlex>
            <ConflictSourceType conflictAlertIndex={conflictAlertIndex}>{conflictSourceTypeTitle}</ConflictSourceType>
            <ConflictPager conflictAlertIndex={conflictAlertIndex}>
              {conflictAlertIndex + 1}/{conflictsList.length}
            </ConflictPager>
            <Button type="text" onClick={() => handleScrollTo(conflictsList[conflictAlertIndex - 1]?.conflictType)}>
              <ConflictPagerButtonBack conflictAlertIndex={conflictAlertIndex} onClick={onClickBack}>
                <LeftOutlined />
              </ConflictPagerButtonBack>
            </Button>
            <Button
              type="text"
              onClick={() => {
                handleScrollTo(conflictsList[conflictAlertIndex + 1]?.conflictType);
              }}
            >
              <ConflictPagerButtonForward
                conflictAlertIndex={conflictAlertIndex}
                numOfConflict={conflictsList.length}
                onClick={onClickNext}
              >
                <RightOutlined />
              </ConflictPagerButtonForward>
            </Button>
          </RightAlignFlex>
        </ConflictNavigatorWrapper>
      </AffixPaddingContainer>
    </Affix>
  );
};

export { ConflictNavigator };
