import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { CloseButton, Gray500Divider } from "../utils/components";
import { selectApplicationForm } from "../../../../../store/selector";
import { fontWeights, sizes } from "../../../../../constant/styles";
import { AttachmentModal } from "../../../../modals/AttachmentModal";

export const SubmittedFormModal = ({ visible, onCancel }) => {
  const { t } = useTranslation();
  const applicationForm = useSelector(selectApplicationForm);

  const applicationFormAttachmentId = applicationForm?.applicationFormAttachmentId;

  return (
    <AttachmentModal
      visible={visible}
      title={t("patientApplications.enroll.application_form_submission.view_submitted_modal.title")}
      description={t("patientApplications.enroll.application_form_submission.view_submitted_modal.description")}
      applicationFormFileName={applicationForm?.attachmentName}
      applicationFormAttachmentId={applicationFormAttachmentId}
    >
      <Gray500Divider style={{ fontSize: sizes.small, fontWeight: fontWeights.semibold }} />
      <div style={{ marginTop: 40, display: "flex", justifyContent: "right" }}>
        <CloseButton onClick={onCancel} id={"EE_submitted_form_modal_close"} />
      </div>
    </AttachmentModal>
  );
};
