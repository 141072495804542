import styled from "styled-components";
import { DatePicker } from "antd";

const CustomDatePicker = styled(DatePicker).attrs((props) => ({
  size: "large",
  ...props
}))`
  width: ${(props) => (props.width ? props.width : "228")}px;
`;

export { CustomDatePicker };
