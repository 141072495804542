import { ApplicationFormSignatureStatuses } from "./ESign";

export const ESIGNATURE_ENROLLMENT_STATES = Object.freeze({
  EMPTY: "EMPTY", // User didn't use the open editor modal and generate a pdf.
  DRAFT: "draft", // User generate the program and didn't yet upload for ESign / Skip Esign upload.
  FILLED: "filled", // User upload the program for ESIGN.
  SIGNED: "signed" // User click skip and upload a signed file.
});

export const ESIGNATURE_ENROLLMENT_STEPS = Object.freeze({
  FIRST_STEP: 0,
  SECOND_STEP: 1,
  THIRD_STEP: 2,
  FORTH_STEP: 3,
  FIFTH_STEP: 4
});

export const ENROLLMENT_SIGNATURES_STATUSES = Object.freeze({
  PENDING: "pending",
  SIGNED: "signed"
});

const APPLICATION_FORM_SIGNATURE_STATUS_FILTER = Object.freeze({
  SIGNED: ApplicationFormSignatureStatuses.SIGNED,
  PENDING: ApplicationFormSignatureStatuses.PENDING,
  EXPIRED: ApplicationFormSignatureStatuses.EXPIRED,
  NOT_STARTED: ApplicationFormSignatureStatuses.NOT_STARTED
});

export const APPLICATION_FORM_SIGNATURE_STATUS_FILTER_OPTIONS = Object.freeze([
  {
    key: "1",
    value: APPLICATION_FORM_SIGNATURE_STATUS_FILTER.SIGNED,
    translationText: "Signed",
    id: "signed"
  },
  {
    key: "2",
    value: APPLICATION_FORM_SIGNATURE_STATUS_FILTER.PENDING,
    translationText: "Pending Signature",
    id: "pending"
  },
  {
    key: "3",
    value: APPLICATION_FORM_SIGNATURE_STATUS_FILTER.EXPIRED,
    translationText: "Link Expired",
    id: "expired"
  },
  {
    key: "4",
    value: APPLICATION_FORM_SIGNATURE_STATUS_FILTER.NOT_STARTED,
    translationText: "Not Started",
    id: "not_started"
  }
]);
