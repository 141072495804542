import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";

import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { questionnaireTreatmentSiteFields } from "../fieldNames";
import { updateFormFields } from "./Practice";

const siteOfTreatmentMapping = {
  name: questionnaireTreatmentSiteFields.treatmentSiteName,
  address: questionnaireTreatmentSiteFields.treatmentAddress,
  city: questionnaireTreatmentSiteFields.treatmentSiteCity,
  state: questionnaireTreatmentSiteFields.treatmentSiteState,
  zipCode: questionnaireTreatmentSiteFields.treatmentSiteZip,
  phone: questionnaireTreatmentSiteFields.treatmentSiteContactPhone,
  npi: questionnaireTreatmentSiteFields.treatmentSiteNPI,
  taxId: questionnaireTreatmentSiteFields.treatmentSiteTaxId,
  fax: questionnaireTreatmentSiteFields.treatmentSiteContactFax,
  email: questionnaireTreatmentSiteFields.treatmentSiteContactEmail
};

export const SiteOfTreatment = () => {
  const siteOfTreatmentFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.GENENTECH_PAP)()
    .siteOfTreatment;
  const [isFacilitySelectOpen, setIsFacilitySelectOpen] = useState(false);
  const form = Form.useFormInstance();
  const facility = Form.useWatch(questionnaireTreatmentSiteFields.facilityId, form);

  useEffect(() => {
    form.validateFields();
  }, [facility]);

  const onFacilityChange = (facility) => updateFormFields(facility, siteOfTreatmentMapping, form);

  return (
    <>
      <Form.Item name={questionnaireTreatmentSiteFields.treatmentSiteName} style={{ display: "none" }}>
        <Input type={"hidden"} />
      </Form.Item>
      {siteOfTreatmentFormComponents.siteOfTreatment()}
      <ConfigurableRow config={ROW_OPTIONS.EQUAL} filterFalsy>
        {[
          siteOfTreatmentFormComponents.practiceSelect({
            required: true,
            isOpen: isFacilitySelectOpen,
            onDropdownVisibleChange: setIsFacilitySelectOpen,
            onChange: onFacilityChange
          }),
          siteOfTreatmentFormComponents.practiceAddress({ required: true }),
          siteOfTreatmentFormComponents.practiceCity({ required: true })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          siteOfTreatmentFormComponents.practiceState({ required: true }),
          siteOfTreatmentFormComponents.practiceZip({ required: true }),
          siteOfTreatmentFormComponents.practiceNPI({}),
          siteOfTreatmentFormComponents.practiceTaxId({})
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          siteOfTreatmentFormComponents.practiceContactName({ required: true }),
          siteOfTreatmentFormComponents.practiceContactPhone({ required: true }),
          siteOfTreatmentFormComponents.practiceContactFax({ required: true }),
          siteOfTreatmentFormComponents.practiceContactEmail({ required: false })
        ]}
      </ConfigurableRow>
    </>
  );
};
