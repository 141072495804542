import { searchIcdToDiagnoses } from "../api/api";
import { useFetch } from "./fetch";
import { useCallback, useState } from "react";

export const useDiagnosisSearch = (searchFunction = searchIcdToDiagnoses, initialSearchValue = "") => {
  const [searchDiagnosis, searchDiagnosisResult, searchInProgress, , resetSearchDiagnosis] = useFetch(searchFunction);
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const handleDiagnosisSearch = useCallback(
    (value) => {
      setSearchValue(value);
      if (!value || value.trim().length < 2) {
        resetSearchDiagnosis();
        return;
      }
      searchDiagnosis(value);
    },
    [searchDiagnosis, resetSearchDiagnosis]
  );

  return { handleDiagnosisSearch, searchDiagnosisResult, searchInProgress, resetSearchDiagnosis, searchValue };
};
