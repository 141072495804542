import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  selectPotentialSavings,
  selectCurrentApplication,
  selectPatientApplications,
  isExpressEnrollFormOpen
} from "../../store/selector";
import { useMemo } from "react";

const useSelectedPotentialSaving = () => {
  const potentialSavings = useSelector(selectPotentialSavings);
  const { potentialSavingId } = useParams();

  if (!potentialSavings.length) {
    return {};
  }
  const selectedPotentialSaving = potentialSavings.find((item) => item.id === parseInt(potentialSavingId, 10));

  return selectedPotentialSaving;
};

const useCurrentApplication = () => {
  // get application when url param applicationId from patientApplications
  const { applicationId } = useParams();

  const { activeApplications, inActiveApplications } = useSelector(selectPatientApplications);
  return useMemo(() => {
    return (
      [...activeApplications, ...inActiveApplications].find((item) => item.id === parseInt(applicationId, 10)) || {}
    );
  }, [activeApplications, inActiveApplications, applicationId]);
};

const useSelectedApplication = () => {
  // get application from store
  return useSelector(selectCurrentApplication);
};

const useSelectedExpressEnrollTemplateName = () => {
  const application = useSelectedApplication();
  const expressEnrollTemplateName = application?.ApplicationFormConfigurationTemplateName;

  return expressEnrollTemplateName;
};

const useSelectedIsExpressEnrollFormOpen = () => {
  return useSelector(isExpressEnrollFormOpen);
};
export {
  useSelectedPotentialSaving,
  useSelectedApplication,
  useSelectedExpressEnrollTemplateName,
  useCurrentApplication,
  useSelectedIsExpressEnrollFormOpen
};
