import React from "react";
import { Form, Radio } from "antd";

export const RadioItem = ({ name, label, rules, radioOptions, formItemProps = {}, groupStyle = {} }) => {
  return (
    <Form.Item name={name} rules={rules} label={label} {...formItemProps}>
      <Radio.Group style={groupStyle}>
        {radioOptions.map((radioOption, index) => {
          return (
            <Radio key={radioOption.value + "_" + index} value={radioOption.value}>
              {radioOption.displayValue}
            </Radio>
          );
        })}
      </Radio.Group>
    </Form.Item>
  );
};
