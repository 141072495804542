import ACTIONS from "./action";

/**
 * In the event of logout, run the child reducer with no state so that
 * it resets to its initial state
 *
 * Otherwise just passes through to the child reducer
 */
export default function (reducer) {
  return function (state, action) {
    if (action.type === ACTIONS.Types.LOGOUT) {
      return reducer(undefined, action);
    } else {
      return reducer(state, action);
    }
  };
}
