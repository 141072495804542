import React from "react";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { novartisSectionsController } from "../../novartisSectionsController";

export default function NovartisCopaySupportOptions() {
  const supportOptionsFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.NOVARTIS_COPAY)()
    .supportOptions;
  const controller = novartisSectionsController();
  const mekinistOptions = [
    { value: "Copay support for Tafinlar AND Mekinist", displayValue: "Copay support for Tafinlar AND Mekinist" },
    { value: "Copay support for Mekinist only", displayValue: "Copay support for Mekinist only" }
  ];
  const tafinlarOptions = [
    { value: "Copay support for Tafinlar AND Mekinist", displayValue: "Copay support for Tafinlar AND Mekinist" },
    { value: "Copay support for Tafinlar only", displayValue: "Copay support for Tafinlar only" }
  ];
  const radioOptions =
    controller.associatedDrug === controller.drugsList.MEKINIST
      ? mekinistOptions
      : controller.associatedDrug === controller.drugsList.TAFINLAR
      ? tafinlarOptions
      : [];

  return (
    <SectionPart
      sectionId="supportOptions"
      headerTranslationKey="application_form_editor.novartis.copay.supportOptions.labels.header"
    >
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[supportOptionsFormComponents.supportOption({ required: true, radioOptions: radioOptions })]}
      </ConfigurableRow>
    </SectionPart>
  );
}
