import React from "react";
import { Col, Divider, Row, Input } from "antd";
import { useTranslation } from "react-i18next";

import { FormDividerText, FormItem } from "../../../../../components";
import { npiInputRule, onlyWhitespacesRule } from "../../../../../utils";
import { practiceFields } from "../fieldNames";
import { GRAY_500 } from "../../../../../../../../constant/colors";
import { sizes } from "../../../../../../../../constant/styles";
import { InfoNotice } from "../../../../../components/InfoNotice";

export const PfizerCopayIvPractice = () => {
  const { t } = useTranslation();

  return (
    <>
      <Divider
        id="enrollment-form-copay-iv-practice-information-anchor"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.pfizer.copay.iv.practice.labels.header")}</FormDividerText>
      </Divider>
      <Row gutter={24}>
        <Col span={8}>
          <FormItem
            rules={[{ required: true }, npiInputRule, onlyWhitespacesRule]}
            name={practiceFields.facilityNpi}
            label={t("application_form_editor.pfizer.copay.iv.practice.labels.npi")}
          >
            <Input maxLength={10} placeholder={t("application_form_editor.pfizer.copay.iv.practice.placeholder.npi")} />
          </FormItem>
        </Col>
        <Col span={16}>
          <FormItem>
            <InfoNotice text={t("application_form_editor.common.practice_npi_notification")} fontSize={sizes.medium} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};
