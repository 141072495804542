import { Typography } from "antd";
import styled from "styled-components";

import { fontWeights, sizes } from "../../../constant/styles";
import { PRIMARY_600 } from "../../../constant/colors";

const { Text } = Typography;

export const MoreDetailsTextStyle = styled(Text)`
  color: ${PRIMARY_600};
  cursor: pointer;
  font-weight: ${fontWeights.semibold};
  font-size: ${sizes.small};
`;
