import React from "react";
import { Form, DatePicker } from "antd";
import { dateParser } from "../../utils/parsers";
import { DATE_FORMAT, DATE_DATA_FORMAT } from "../../../../../utils/date";

export default ({ required = false, placeholder = DATE_FORMAT, defaultDate = null, ...props }) => (
  <Form.Item
    rules={[{ type: "date" }, { required }]}
    getValueProps={dateParser}
    getValueFromEvent={(momentValue) => momentValue?.format(DATE_DATA_FORMAT)}
    {...props}
  >
    <DatePicker format={[DATE_FORMAT, DATE_DATA_FORMAT]} placeholder={placeholder} defaultPickerValue={defaultDate} />
  </Form.Item>
);
