export const EEsignatureStatuses = Object.freeze({
  UNSIGNED: "unsigned",
  PENDING: "pending",
  SIGNED: "signed",
  MANUALLY_UNSIGNED: "manuallyUnsigned",
  SUBMISSION_IN_PROGRESS: "submissionInProgress",
  SUBMITTED: "submittedToProgram",
  SUBMIT_FAILED: "submitFailed",
  SENT: "sent",
  COMPLETED: "completed",
  DRAFT: "draft"
});
