import React, { useEffect } from "react";
import { Form } from "antd";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { patientFields } from "../fieldNames";

export default function GenentechFreeDrugPatient() {
  const patientFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.GENENTECH_PAP)().patient;

  const form = Form.useFormInstance();
  const alternativeContact = Form.useWatch(patientFields.alternativeContact, form);
  const patientGender = Form.useWatch(patientFields.gender, form);

  useEffect(() => {
    form.validateFields();
  }, [alternativeContact]);

  return (
    <SectionPart
      sectionId="patient"
      headerTranslationKey="application_form_editor.genentech.free_drug.patient.labels.header"
    >
      <>{patientFormComponents.id()}</>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_2}>
        {[
          patientFormComponents.firstName({ required: true }),
          patientFormComponents.lastName({ required: true }),
          patientFormComponents.gender({
            required: true,
            showError: !patientGender
          })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_3}>
        {[patientFormComponents.dob({ required: true }), patientFormComponents.address({ required: true })]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_2_2}>
        {[
          patientFormComponents.city({ required: true }),
          patientFormComponents.state({ required: true }),
          patientFormComponents.zip_code({ required: true })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          patientFormComponents.phone_number({ required: true }),
          patientFormComponents.additional_phone_number({ required: false }),
          patientFormComponents.preferred_language({ required: true }),
          patientFormComponents.email({ required: false })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_BLANK}>
        {[patientFormComponents.alternative_contact({ required: true })]}
      </ConfigurableRow>
      {alternativeContact && (
        <>
          {patientFormComponents.patient_divider()}
          <ConfigurableRow>{[patientFormComponents.relationship_to_patient({ required: true })]}</ConfigurableRow>
          <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
            {[
              patientFormComponents.related_firstName({ required: true }),
              patientFormComponents.related_lastName({ required: true }),
              patientFormComponents.related_phone_number({ required: true }),
              patientFormComponents.related_additional_phone_number({ required: false })
            ]}
          </ConfigurableRow>
          <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_BLANK}>
            {[patientFormComponents.related_dob({ required: true })]}
          </ConfigurableRow>
          <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_BLANK}>
            {[patientFormComponents.signed_patient_authorization({ required: false })]}
          </ConfigurableRow>
        </>
      )}
    </SectionPart>
  );
}
