import React, { useState, useEffect, memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useParams, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router";
import moment from "moment-timezone";
import styled from "styled-components";
import { Select, DatePicker, Button, List, Popover, Space, Checkbox, Typography, Row, Col, Badge } from "antd";
import { DeleteFilled, EditFilled, LinkOutlined, CheckSquareOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { ReactComponent as TasksFilled } from "../../assets/svg/tasks.svg";
import { selectAssignees, selectPatientDetails, selectUser } from "../../store/selector";
import { TasksEdit } from "./TasksEdit";
import { ALL, DATE_RANGE, WITHIN_A_MONTH, WITHIN_A_WEEK, DONE, UNASSIGNED } from "../../constant/filters";
import { DATE_FORMAT, dateFormatter, setDateRange } from "../../utils/date";
import { BLUE_DARK, PRIMARY_600 } from "../../constant/colors";
import {
  createPatientsTaskAPI,
  deletePatientsTaskAPI,
  editPatientsTaskAPI,
  getFinancialNavigationPotentialSavingPhis,
  getPatientsTasksAPI
} from "../../api/api";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";
import { useFetch } from "../../hooks/fetch";
import { CircleIconButton } from "../UI/CircleIconButton";

import { Tooltip } from "../UI/Tooltip";
import { dateSort } from "../../utils/sort";

import "./Tasks.css";
import { optionsSearch } from "../../utils/search";
import { formatAssignee } from "../../utils/formaters";
import ExportTasksData from "./ExportTasksData";
import { eligibleUserRoles } from "../../constant/roles";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const STATUS_NOT_DONE = "";
const taskPopoverSize = {
  Width: {
    patientView: 450,
    mainView: 550
  },
  Height: {
    patientView: 330,
    mainView: 450
  }
};

const PATIENTS_ON_PAGE = 10;
const STATUS_CHECKED = "checked";
const FILTERS_TASKS = "filterTasks";

const MenuButton = styled(CircleIconButton)`
  padding-top: 4px;
`;

const StyledSelect = styled(Select)`
  .anticon > svg {
    color: rgb(82, 80, 85);
  }
`;

export const getPopoverWidth = (isPatientView) =>
  isPatientView ? taskPopoverSize.Width.patientView : taskPopoverSize.Width.mainView;

export const getPopoverHeight = (isPatientView) =>
  isPatientView ? taskPopoverSize.Height.patientView : taskPopoverSize.Height.mainView;

const PotentialSavingLink = ({ patientId, journeyId, linkedPotentialSaving, potentialSavingName }) => {
  if (!patientId || !journeyId || !linkedPotentialSaving) return null;
  return (
    <Link to={`/patient/${patientId}/journey/${journeyId}/optimizations/${linkedPotentialSaving}`}>
      <LinkOutlined style={{ paddingRight: 8 }} />
      {potentialSavingName}
    </Link>
  );
};

const TaskPotentialSavingLink = ({
  taskJourneyPatientId,
  taskJourneyId,
  potentialSavingName,
  linkedPotentialSaving
}) => {
  const { t } = useTranslation();
  const { patientId, journeyId } = useParams();
  const psName = potentialSavingName || t("patientsTasks.view_program");
  return (
    <Switch>
      <Route
        path={"/patient/:patientId/journey"}
        component={() => (
          <PotentialSavingLink
            patientId={patientId}
            journeyId={journeyId}
            potentialSavingName={psName}
            linkedPotentialSaving={linkedPotentialSaving}
          />
        )}
      />
      <Route
        path={"*"}
        render={() => (
          <PotentialSavingLink
            potentialSavingName={psName}
            patientId={taskJourneyPatientId}
            journeyId={taskJourneyId}
            linkedPotentialSaving={linkedPotentialSaving}
          />
        )}
      />
    </Switch>
  );
};

export const Tasks = ({ isPatientView }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const saveFiltersToLocalStorage = (filters) => {
    localStorage.setItem(FILTERS_TASKS, JSON.stringify(filters));
  };

  const getFiltersFromLocalStorage = () => {
    const filters = localStorage.getItem(FILTERS_TASKS);
    return filters ? JSON.parse(filters) : {};
  };

  const filters = getFiltersFromLocalStorage();
  const { start, end } = filters?.dueDate || {};

  const user = useSelector(selectUser);
  const assignees = useSelector(selectAssignees);
  const patientDetails = useSelector(selectPatientDetails);

  const [page, setPage] = useState(1);
  const [potentialSavingMap, setPotentialSavingMap] = useState({});
  const [tasks, setTasks] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState(filters.assignees);
  const [selectedStatus, setSelectedStatus] = useState(filters.status || STATUS_NOT_DONE);
  const [selectedPeriod, setSelectedPeriod] = useState(filters.period || ALL);
  const [isRangePickerOpen, setIsRangePickerOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEligibleUser, setIsEligibleUser] = useState(false);
  const [editTaskData, setEditTaskData] = useState(null);
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);

  const [editTask, editPatientsTaskResult, editPatientsTaskIsFetching, editPatientsTaskError] =
    useFetch(editPatientsTaskAPI);
  const [getTasksAPI, getTasksResult, getTasksIsFetching, getTasksError] = useFetch(getPatientsTasksAPI);
  const [deleteTask, deleteTaskResult, deleteTaskIsFetching, deleteTaskError] = useFetch(deletePatientsTaskAPI);
  const [createTask, createTaskResult, createTaskIsFetching, createTaskError] = useFetch(createPatientsTaskAPI);
  const [fetchPotentialSaving, potentialSavingResult, potentialSavingIsFetching, potentialSavingError] = useFetch(
    getFinancialNavigationPotentialSavingPhis
  );

  const { patientId } = useParams();

  useEffect(() => {
    if (getTasksResult?.data?.todos) {
      const tasksSorted = getTasksResult.data.todos.sort((a, b) => dateSort(a.dueDate, b.dueDate, "descend"));
      setIsEligibleUser(eligibleUserRoles.includes(user.role));
      setTasks(tasksSorted);
    }
  }, [getTasksResult]);

  useEffect(() => {
    if (assignees.length === 0 || !user) return;

    const currentAssigneeUser = assignees[user?.id]?.id;
    const initialAssignee = filters.assignees || currentAssigneeUser || ALL;

    setSelectedAssignees(initialAssignee);
  }, [assignees, user]);

  useEffect(() => {
    const potentialSavings = potentialSavingResult?.data?.["financial-navigation-potential-saving-phi"];
    if (potentialSavings) {
      setPotentialSavingMap({
        ...potentialSavings.reduce((acc, { id, ...result }) => {
          return {
            ...acc,
            [id]: result
          };
        }, {}),
        ...potentialSavingMap
      });
    }
  }, [potentialSavingResult]);

  useEffect(() => {
    saveFiltersToLocalStorage({
      ...filters,
      assignees: selectedAssignees,
      status: selectedStatus,
      period: selectedPeriod,
      dueDate: selectedPeriod != DATE_RANGE ? { start: null, end: null } : filters.dueDate
    });
    setIsEditMode(false);
    getTasks();
  }, [editPatientsTaskResult, createTaskResult, selectedAssignees, selectedStatus, selectedPeriod, deleteTaskResult]);

  useEffect(async () => {
    const fetchingTasks = tasks.slice(PATIENTS_ON_PAGE * (page - 1), PATIENTS_ON_PAGE * page);
    const fetchLinkedPotentialSaving = fetchingTasks
      .filter((p) => p.linkedPotentialSaving)
      .map((p) => p.linkedPotentialSaving);
    const fetchingList = [];

    if (fetchLinkedPotentialSaving.length) {
      fetchingList.push(fetchPotentialSaving(fetchLinkedPotentialSaving));
    }
    await Promise.all(fetchingList);
  }, [page, tasks]);

  const hasError = editPatientsTaskError || getTasksError || deleteTaskError || createTaskError || potentialSavingError;

  if (hasError) {
    ErrorMessage(hasError);
  }

  const isFetching =
    editPatientsTaskIsFetching ||
    getTasksIsFetching ||
    deleteTaskIsFetching ||
    createTaskIsFetching ||
    potentialSavingIsFetching;

  const getTasks = async (start = null, end = null) => {
    const filterData = {
      assignee: selectedAssignees === UNASSIGNED ? "" : selectedAssignees,
      status: selectedStatus,
      dueDate: start ? { start, end } : selectedPeriod
    };
    const addPatientData = patientId ? false : true;
    getTasksAPI(filterData, addPatientData, patientId);
  };

  const selectPeriodHandler = (value) => {
    setSelectedPeriod(value);
    setIsRangePickerOpen(value === DATE_RANGE);
  };

  const setRangePicker = (values) => {
    setIsRangePickerOpen(false);
    const formattedDates = setDateRange(moment(values[0]), moment(values[1]));
    const dateFrom = formattedDates[0];
    const dateTo = formattedDates[1];
    getTasks(dateFrom, dateTo);

    saveFiltersToLocalStorage({ ...filters, dueDate: { start: dateFrom, end: dateTo } });
  };

  const changeStatusHandler = (task) => {
    if (task.status === STATUS_CHECKED) {
      task.status = null;
    } else if (task.status === null) {
      task.status = STATUS_CHECKED;
    }
    editTask(task);
  };

  const editingTaskHandler = useCallback((task) => {
    setIsEditMode(true);
    setEditTaskData(task);
  }, []);

  const onLoadMore = () => setPage(page + 1);

  const getAssigneeLabel = () => {
    switch (selectedAssignees) {
      case ALL:
        return t("patientsTasks.all_assignees");
      case UNASSIGNED:
        return t("patientsTasks.unassigned");
      default:
        return selectedAssignees;
    }
  };

  const loadMore = !isFetching ? (
    <div style={{ textAlign: "center", marginTop: 12, height: 32, lineHeight: "32px" }}>
      {page < Math.ceil(tasks.length / PATIENTS_ON_PAGE) && (
        <Button onClick={onLoadMore}>{t("patientsTasks.load_more")}</Button>
      )}
    </div>
  ) : null;

  const popoverTitle = (
    <div style={{ backgroundColor: BLUE_DARK, padding: 16 }}>
      <Text style={{ color: "#fff" }}>
        {patientId ? `${t("patientsTasks.tasks_for")} ${patientDetails?.name || ""}` : t("patientsTasks.title")}
      </Text>
    </div>
  );

  const renderItem = (task) => {
    const { text, dueDate, patient, mrn } = task;
    const taskAssignee = Object.values(assignees).find((assignee) => assignee.id === task.assignee);
    const patientFullName = task.patientFullName || "Patient";

    return (
      <List.Item id={`task-${task.id}`}>
        <List.Item.Meta
          avatar={
            <Checkbox
              style={{ paddingTop: task.journey ? 4 : 0 }}
              checked={task.status === STATUS_CHECKED}
              onClick={() => changeStatusHandler(task)}
            />
          }
          title={
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                {task.journey && (
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => history.push(`/patient/${patient}/journey/${task.journey.id}`)}
                  >
                    {patientFullName}
                  </Button>
                )}
                {!isPatientView && (patient || mrn) && (
                  <>
                    <div style={{ paddingLeft: 5 }}>{"("}</div>
                    {patient && (
                      <Text style={{ paddingRight: 5 }}>
                        {t("patientsTasks.tm", { id: patient, comma: mrn ? ", " : "" })}
                      </Text>
                    )}
                    {mrn && <Text>{t("patientsTasks.mrn", { mrn })}</Text>}
                    <div>{")"}</div>
                  </>
                )}
              </div>
              <div>
                <Text>{text}</Text>
              </div>
              <TaskPotentialSavingLink
                taskJourneyPatientId={task?.journey?.patient}
                taskJourneyId={task?.journey?.id}
                linkedPotentialSaving={task.linkedPotentialSaving}
                potentialSavingName={potentialSavingMap[task.linkedPotentialSaving]?.name}
              />
            </>
          }
          description={
            <Row>
              {dueDate && (
                <Col span={5}>
                  <Text style={{ color: moment().isBefore(moment(dueDate)) ? "black" : "red" }}>
                    {dateFormatter(dueDate)}
                  </Text>
                </Col>
              )}
              <Col span={dueDate ? 13 : 18}>{taskAssignee && `${formatAssignee(taskAssignee)}`}</Col>
              <Col span={6}>
                <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                  <EditFilled onClick={() => editingTaskHandler(task)} />
                  <DeleteFilled onClick={() => deleteTask(task)} />
                </Space>
              </Col>
            </Row>
          }
        />
      </List.Item>
    );
  };

  const tasksList = (
    <Space direction={"vertical"}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "4px 16px" }}>
        <Button
          id="add_task_button"
          style={{ padding: 0 }}
          onClick={() => {
            setIsEditMode(true);
            setEditTaskData(null);
          }}
          type="text"
        >
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <PlusCircleOutlined style={{ color: PRIMARY_600 }} />
            <Text style={{ color: PRIMARY_600 }}>{t("patientsTasks.add_task")}</Text>
          </div>
        </Button>
        {!isPatientView && (
          <ExportTasksData
            data={
              isEligibleUser
                ? tasks
                : tasks.map((task) => {
                    return { ...task, mrn: "", text: "", patientFullName: "" };
                  })
            }
            isPatientView={isPatientView}
          />
        )}
      </div>
      <Space>
        <StyledSelect
          value={getAssigneeLabel()}
          dropdownMatchSelectWidth={false}
          onSelect={setSelectedAssignees}
          style={{ fontWeight: 700, minWidth: "140px" }}
          bordered={false}
          showSearch={true}
          open={isAssigneeOpen}
          onDropdownVisibleChange={(visible) => setIsAssigneeOpen(visible)}
          filterOption={optionsSearch}
          dropdownRender={(menu) => (
            <>
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8, gap: 6 }}>
                <Button
                  type={selectedAssignees === ALL ? "primary" : "default"}
                  ghost={selectedAssignees === ALL ? true : false}
                  onClick={() => (setSelectedAssignees(ALL), setIsAssigneeOpen(false))}
                >
                  {t("patientsTasks.all_assignees")}
                </Button>
                <Button
                  type={selectedAssignees === UNASSIGNED ? "primary" : "default"}
                  ghost={selectedAssignees === UNASSIGNED ? true : false}
                  onClick={() => {
                    setSelectedAssignees(UNASSIGNED);
                    setIsAssigneeOpen(false);
                  }}
                >
                  {t("patientsTasks.unassigned")}
                </Button>
              </div>
              {menu}
            </>
          )}
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {Object.values(assignees).map((assignee) => (
            <Option key={assignee.id} value={assignee.id}>
              {formatAssignee(assignee)}
            </Option>
          ))}
        </StyledSelect>
        <StyledSelect
          value={selectedStatus}
          dropdownMatchSelectWidth={false}
          onSelect={setSelectedStatus}
          style={{ fontWeight: 700 }}
          bordered={false}
        >
          <Option value={ALL}>{t("patientsTasks.show_all")}</Option>
          <Option value={STATUS_NOT_DONE}>{t("patientsTasks.not_done")}</Option>
          <Option value={DONE}>{t("patientsTasks.done")}</Option>
        </StyledSelect>
        <StyledSelect
          value={selectedPeriod}
          dropdownMatchSelectWidth={false}
          onSelect={selectPeriodHandler}
          style={{ fontWeight: 700 }}
          bordered={false}
        >
          <Option value={ALL}>{t("patientsTasks.all_times")}</Option>
          <Option value={WITHIN_A_WEEK}>{t("patientsTasks.within_a_week")}</Option>
          <Option value={WITHIN_A_MONTH}>{t("patientsTasks.within_a_month")}</Option>
          <Option value={DATE_RANGE}>{t("patientsTasks.date_range")}</Option>
        </StyledSelect>
        {isRangePickerOpen && (
          <RangePicker
            bordered={false}
            style={{
              width: "0%",
              height: "0%",
              ...(isRangePickerOpen ? { position: "absolute", right: 0 } : { display: "none" })
            }}
            open={isRangePickerOpen}
            onOpenChange={() => setIsRangePickerOpen(false)}
            onChange={setRangePicker}
            defaultValue={[start && moment(start, DATE_FORMAT), end && moment(end, DATE_FORMAT)]}
          />
        )}
      </Space>
      <List
        style={{
          padding: "0px 16px",
          width: getPopoverWidth(isPatientView),
          height: getPopoverHeight(isPatientView),
          overflowY: "scroll"
        }}
        loadMore={loadMore}
        loading={isFetching}
        dataSource={tasks.slice(0, PATIENTS_ON_PAGE * page)}
        renderItem={renderItem}
      />
    </Space>
  );

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      style={{ padding: 0 }}
      title={popoverTitle}
      content={
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!isEditMode && tasksList}
          {isEditMode && (
            <TasksEdit
              isFetching={isFetching}
              editTaskData={editTaskData}
              setIsEditMode={setIsEditMode}
              editTask={editTask}
              createTask={createTask}
              isPatientView={isPatientView}
            />
          )}
        </div>
      }
    >
      {!patientId ? (
        <Badge offset={[-5, 5]} size="small" overflowCount={999} count={tasks.length}>
          <Tooltip title={t("patientsTasks.tasks")}>
            <MenuButton id="main_view_tasks_button" icon={<TasksFilled />} />
          </Tooltip>
        </Badge>
      ) : (
        <div
          id="patient_tasks"
          style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
        >
          <CheckSquareOutlined style={{ fontSize: 14, paddingRight: 3 }} />
          <Text style={{ fontSize: 14 }}>
            {`${t("patientRSActions.tasks")}${tasks.length ? ` (${tasks.length})` : ""}`}
          </Text>
        </div>
      )}
    </Popover>
  );
};

export default memo(Tasks);
