import React from "react";
import styled from "styled-components";
import { Radio } from "antd";
const CustomRadioGroup = styled((props) => <Radio.Group {...props} />)`
  display: flex;
  align-items: center;
  .ant-radio-button-wrapper {
    height: 40px;
    display: flex;
    width: 118px;
    align-items: center;
    justify-content: center;
    span {
      color: #98a2b3;
    }
  }

  .ant-radio-button-wrapper:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0px 4px 4px 0px;
  }

  .ant-radio-button-wrapper-checked {
    border: 1px solid #1570ef;
    span {
      color: #1570ef;
    }
  }
`;

export { CustomRadioGroup };
