import React, { useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Typography } from "antd";
import { useLocation } from "react-router";
import styled from "styled-components";

import { selectSelectedView, selectUser } from "../store/selector";
import { DashboardSummary } from "../component/DashboardSummary";
import { TableSection } from "../../src/component/TableSection/TableSection";
import { timeOfDay } from "../utils/date";
import financialBackground from "../assets/financial-background.png";
import AddNewPatientButton from "../component/addNewPatient/AddNewPatientButton";
import ACTIONS from "../store/action";
import { selectedViews } from "../constant/table";

const { Text, Title } = Typography;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DashboardScreen = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.dimensions.width};
  margin: 0 auto;
  padding: 40px 0px 0px 0px;
  position: relative;
`;

const DashboardComponent = ({ user, t, selectedView, setSelectedView }) => {
  let query = useQuery();

  useEffect(() => {
    if (!selectedView) {
      const currSelectedView = query.get("selectedView");
      if (currSelectedView && Object.values(selectedViews).includes(currSelectedView)) {
        setSelectedView(currSelectedView);
      }
    }
  }, []);

  return (
    <DashboardScreen direction={"vertical"}>
      <DashboardSummary />
      {!selectedView ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Title strong>{t("main_title", { timeOfDay: timeOfDay(), firstName: user ? user.firstName : "" })}</Title>
          <Text style={{ fontSize: "20px" }}>{t("main_subtitle")}</Text>
          <img
            style={{ maxWidth: "100%", padding: "25px 0", alignSelf: "center" }}
            className="dashboard-background"
            src={financialBackground}
            alt=""
          />
        </div>
      ) : (
        <TableSection />
      )}
      <AddNewPatientButton />
    </DashboardScreen>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  selectedView: selectSelectedView
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedView: (selectedView) => dispatch(ACTIONS.setSelectedView(selectedView))
});

const Dashboard = compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(DashboardComponent);

export default Dashboard;
