import { useState } from "react";

const defaultState = {
  result: null,
  isFetching: false,
  error: null
};

function useFetch(fetchFunc) {
  const [status, setStatus] = useState(defaultState);

  const invoke = async (...data) => {
    setStatus({
      error: null,
      result: null,
      isFetching: true
    });
    try {
      const result = await fetchFunc(...data);
      setStatus({
        result,
        isFetching: false,
        error: null
      });
    } catch (error) {
      setStatus({
        result: null,
        isFetching: false,
        error: error.message
      });
    }
  };

  const reset = () => setStatus(defaultState);

  return [invoke, status.result, status.isFetching, status.error, reset];
}

export { useFetch };
