import { insuranceCheckList, insuranceCheckListDates } from "./formSections/Insurance";

const patientFields = {
  id: ["patient", "id"],
  firstName: ["patient", "firstName"],
  middleName: ["patient", "middleName"],
  lastName: ["patient", "lastName"],
  dob: ["patient", "dob"],
  gender: ["patient", "gender"],
  homePhone: ["patient", "homePhone"],
  homePhoneType: ["patient", "homePhoneType"],
  mobilePhone: ["patient", "phone"],
  mobilePhoneType: ["patient", "phoneType"],
  email: ["patient", "email"],
  address: ["patient", "address"],
  city: ["patient", "city"],
  state: ["patient", "state"],
  zip: ["patient", "zip"],
  additionalInformation: ["patient", "caregiver", "caregiver"],
  usCitizen: ["patient", "isUsCitizen"],
  caregiverName: ["patient", "caregiver", "name"],
  caregiverRelationship: ["patient", "caregiver", "relationship"],
  caregiverPhone: ["patient", "caregiver", "phone"],
  caregiverPhoneType: ["patient", "caregiver", "phoneType"]
};

const insuranceFields = {
  hasSecondaryInsurance: ["insurance", "secondary", "policyholderSameAsPatient"],
  patientCoverageUninsured: ["insurance", "patientCoverageUninsured"],
  patientCoverageCommercial: ["insurance", "patientCoverageCommercial"],
  flexibleSpendingAmountParticipant: ["insurance", "flexibleSpendingAmountParticipant"],

  primaryInsuranceName: ["insurance", "primary", "name"],
  primaryInsurancePhone: ["insurance", "primary", "phone"],

  secondaryInsuranceName: ["insurance", "secondary", "name"],
  secondaryInsurancePhone: ["insurance", "secondary", "phone"],

  primaryGroupNumber: ["insurance", "primary", "group"],
  secondaryGroupNumber: ["insurance", "secondary", "group"],

  primaryPolicyholderName: ["insurance", "primary", "policyholderName"],
  secondaryPolicyholderName: ["insurance", "secondary", "policyholderName"],

  primaryPolicyNumber: ["insurance", "primary", "member"],
  secondaryPolicyNumber: ["insurance", "secondary", "member"],

  primaryRelationshipToPatient: ["insurance", "primary", "policyholderRelationship"],
  secondaryRelationshipToPatient: ["insurance", "secondary", "policyholderRelationship"],

  [insuranceCheckList.PHARMACY_CARD]: ["insurance", insuranceCheckList.PHARMACY_CARD],
  [insuranceCheckList.MEDICARE_PART_A]: ["insurance", insuranceCheckList.MEDICARE_PART_A],
  [insuranceCheckListDates.MEDICARE_PART_A]: ["insurance", [insuranceCheckListDates.MEDICARE_PART_A]],
  [insuranceCheckList.MEDICARE_PART_B]: ["insurance", insuranceCheckList.MEDICARE_PART_B],
  [insuranceCheckListDates.MEDICARE_PART_B]: ["insurance", [insuranceCheckListDates.MEDICARE_PART_B]],
  [insuranceCheckList.MEDICARE_PENDEING]: ["insurance", insuranceCheckList.MEDICARE_PENDEING],
  [insuranceCheckList.MEDICAID_EFF]: ["insurance", insuranceCheckList.MEDICAID_EFF],
  [insuranceCheckListDates.MEDICAID_EFF]: ["insurance", [insuranceCheckListDates.MEDICAID_EFF]],
  [insuranceCheckList.MEDICAID_PENDEING]: ["insurance", insuranceCheckList.MEDICAID_PENDEING],
  [insuranceCheckList.CHAMPUS_EFF]: ["insurance", insuranceCheckList.CHAMPUS_EFF],
  [insuranceCheckListDates.CHAMPUS_EFF]: ["insurance", [insuranceCheckListDates.CHAMPUS_EFF]],
  [insuranceCheckList.VA_EFF]: ["insurance", insuranceCheckList.VA_EFF],
  [insuranceCheckListDates.VA_EFF]: ["insurance", [insuranceCheckListDates.VA_EFF]],
  [insuranceCheckList.VA_PENDEING]: ["insurance", insuranceCheckList.VA_PENDEING]
};

const providerFields = {
  taxId: ["provider", "taxId"],
  groupNpi: ["provider", "groupNpi"],
  providerNpi: ["provider", "npi"],
  providerId: ["provider", "id"],
  providerFirstName: ["provider", "firstName"],
  providerLastName: ["provider", "lastName"]
};

const practiceFields = {
  facilityId: ["facility", "id"],
  facilityName: ["facility", "contact", "fullName"],
  facilityContact: ["facility", "contactName"],
  address: ["facility", "address"],
  city: ["facility", "city"],
  state: ["facility", "state"],
  zip: ["facility", "zip"],
  facilityPhone: ["facility", "contact", "phone"],
  facilityFax: ["facility", "contact", "fax"],
  email: ["facility", "contact", "email"]
};

const diagnosisFields = {
  primary: ["diagnosis", "primary", "icd10"],
  secondary: ["diagnosis", "secondary", "icd10"]
};

const clinicalFields = {
  productNdc: ["clinical", "productNdc"],
  treatmentFdaApproved: ["clinical", "IsFDAapproved"],
  previousTherapies: ["clinical", "previousTherapies"],
  concurrentTherapies: ["clinical", "concurrentTherapies"],
  siteOfCare: ["clinical", "siteOfCare"]
};

export { patientFields, providerFields, practiceFields, clinicalFields, diagnosisFields, insuranceFields };
