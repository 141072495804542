import { useEffect } from "react";
import { useFetch } from "../hooks/fetch";
import { postPortalDataThroughChromeExtension } from "../api/api";

const ChromeExtensionListener = () => {
  const [postChromeExtensionData] = useFetch(postPortalDataThroughChromeExtension);

  useEffect(() => {
    const messageHandler = (event) => {
      // The chrome extension sends the event from the context of the window
      if (event.source !== window) return;

      if (event.data?.message && event.data?.message === "send_data_to_server") {
        // send the data to your server
        postChromeExtensionData(event.data);
      }
    };

    // Add listener with handler
    window.addEventListener("message", messageHandler);

    // Cleanup when the component unmounts
    return () => {
      // Remove listener with handler
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  return null;
};

export { ChromeExtensionListener };
