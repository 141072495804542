const physicianAndFacilityFields = {
  physicianId: ["physician", "id"],
  physicianFirstName: ["physician", "firstName"],
  physicianLastName: ["physician", "lastName"],
  physicianPracticeName: ["physician", "practiceName"],
  physicianAddress: ["physician", "address"],
  physicianCity: ["physician", "city"],
  physicianState: ["physician", "state"],
  physicianZip: ["physician", "zip"],
  physicianPhone: ["physician", "phone"],
  physicianNpi: ["physician", "npi"],
  facilityNpi: ["facility", "npi"],
  facilityId: ["facility", "id"],
  facilityName: ["facility", "name"],
  facilityType: ["facility", "type"],
  facilityAddress: ["facility", "address"],
  facilityCity: ["facility", "city"],
  facilityState: ["facility", "state"],
  facilityZip: ["facility", "zip"],
  facilityPhone: ["facility", "phone"]
};

const patientFields = {
  id: ["patient", "id"],
  firstName: ["patient", "firstName"],
  lastName: ["patient", "lastName"],
  dob: ["patient", "dob"],
  gender: ["patient", "gender"],
  phoneType: ["patient", "phoneType"],
  phone: ["patient", "phone"],
  email: ["patient", "email"],
  address: ["patient", "address"],
  city: ["patient", "city"],
  state: ["patient", "state"],
  zip: ["patient", "zip"]
};

const insuranceFields = {
  insuranceMedicalGroupId: ["insurance", "medical", "groupId"],
  insuranceMedicalMemberId: ["insurance", "medical", "memberId"],
  insuranceMedicalSelectName: ["insurance", "medical", "selectName"],
  insuranceMedicalName: ["insurance", "medical", "name"],
  insuranceMedicalSelectPlanType: ["insurance", "medical", "selectPlanType"],
  insuranceMedicalPlanType: ["insurance", "medical", "planType"],
  insuranceMedicalInsuranceEffectiveDate: ["insurance", "medical", "insuranceEffectiveDate"],
  insuranceWithoutBinAndPcn: ["insurance", "medical", "insuranceWithoutBinAndPcn"],
  insuranceMedicalRxBin: ["insurance", "medical", "rxBin"],
  insuranceMedicalRxPcn: ["insurance", "medical", "rxPcn"],
  insuranceWithoutPharmacyInsuranceCard: ["insurance", "pharmacy", "insuranceWithoutPharmacyInsuranceCard"],
  insurancePharmacyGroupId: ["insurance", "pharmacy", "groupId"],
  insurancePharmacyMemberId: ["insurance", "pharmacy", "memberId"],
  insurancePharmacyName: ["insurance", "pharmacy", "name"],
  insurancePharmacyRxBin: ["insurance", "pharmacy", "rxBin"],
  insurancePharmacyRxPcn: ["insurance", "pharmacy", "rxPcn"]
};

export { patientFields, insuranceFields, physicianAndFacilityFields };
