import React, { useState } from "react";
import { Button, Card, Divider, Skeleton, Space, Tag, Tooltip, Typography } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CheckOutlined, FilePdfFilled } from "@ant-design/icons";

import {
  GRAY_600,
  GRAY_700,
  PRIMARY_400,
  PRIMARY_600,
  PRIMARY_650,
  PRIMARY_800,
  WHITE,
  SUCCESS_50,
  ERROR_50,
  SUCCESS_700,
  ERROR_700,
  GRAY_100,
  GRAY_500,
  GRAY_900,
  GRAY_300,
  BRAND_BLUE,
  WARNING_50,
  WARNING_700,
  WARNING_600,
  PRIMARY_50,
  PRIMARY_700
} from "../../../../../constant/colors";
import { fontWeights, sizes } from "../../../../../constant/styles";
import { ExpressEnrollmentButton as ExpressEnrollmentButtonStyles } from "../../../../sharedComponents/ExpressEnrollment";
import { EEsignatureStatuses } from "../../../../../constant/EEsignatureStatuses";
import {
  selectApplicationAttachmentsData,
  selectApplicationForm,
  selectIsPhiAllowed
} from "../../../../../store/selector";
import AttachmentPermissionsPopover from "../../../../AttachmentPermissionsPopover";
import { ShorterText, ShorterTextWithTooltip } from "../../../../ShorterText";
import ElectricityIcon from "../../../../../assets/svg/electricity";
import { ReactComponent as HazardTriangle } from "../../../../../assets/svg/hazardTriangle.svg";
import { getExpressEnrollmentMapping } from "../../../ApplicationFormEditor/configurations";
import { useSelectedIsExpressEnrollFormOpen } from "../../../hooks";
import { ApplicationFormSignatureStatuses } from "../../../../../constant/ESign";
import PermissionPopover from "../EsignatureEnrollment/PermissionPopover";
import { dateTimeFormatter } from "../../../../../utils/date";
import ViewApplicationFormActionButton from "../applicationForm/ViewApplicationFormButton";

const { Text } = Typography;

export const ExpressEnrollButton = styled(Button)`
  ${ExpressEnrollmentButtonStyles}
  border: 1px solid ${PRIMARY_650};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  background: ${PRIMARY_600};
  color: ${WHITE};
  font-style: normal;
  font-weight: ${fontWeights.semibold};
  font-size: ${sizes.medium};

  &:hover {
    background: ${PRIMARY_800};
    border: 1px solid ${PRIMARY_600};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    color: ${WHITE};
  }

  :active {
    background: ${PRIMARY_600};
    border: 4px solid ${PRIMARY_400};
    border-radius: 4px;
    color: ${WHITE};
    padding-top: 1px;
  }

  :disabled {
    background: ${PRIMARY_600};
    opacity: 0.3;
    border: 1px solid ${PRIMARY_600};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    color: ${WHITE};
  }
`;

export const TextStyled = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: ${GRAY_500};
`;

const ExpressEnrollSubmitButton = (props) => {
  const { t } = useTranslation();
  const expressEnrollMapping = getExpressEnrollmentMapping();

  return (
    <NoAccessTooltip>
      <ExpressEnrollButton type="primary" style={{ height: 36, width: 182 }} {...props}>
        <Space style={{ lineHeight: 0 }}>
          <ElectricityIcon color={WHITE} />
          {expressEnrollMapping.submissionStep.getSubmitButtonText(t)}
        </Space>
      </ExpressEnrollButton>
    </NoAccessTooltip>
  );
};

const BottomDescription = () => {
  const { t } = useTranslation();
  return (
    <Space style={{ marginTop: 10 }}>
      <HazardTriangle />
      <Text style={{ fontSize: sizes.medium, fontWeight: fontWeights.regular }}>
        {t("patientApplications.enroll.electronic_signatures.email_to_recipient")}
      </Text>
    </Space>
  );
};

const linkTextStyle = {
  color: GRAY_700,
  marginLeft: "0.25em",
  marginRight: "0.5em",
  fontWeight: fontWeights.semibold,
  textDecoration: "underline"
};

const FileLink = ({ fileName, href, maxLength, isAutofillEnrollment, ...props }) => {
  const { t } = useTranslation();
  const isExpressEnrollForm = useSelectedIsExpressEnrollFormOpen();

  const applicationAttachments = useSelector(selectApplicationAttachmentsData);
  const hasReadFilePermission = applicationAttachments?.canViewOrDownload;
  const updateLinkTextStyle = isExpressEnrollForm ? { ...linkTextStyle, fontWeight: fontWeights.bold } : linkTextStyle;

  return (
    <Space style={{ height: "100%" }} justify="center">
      <FilePdfFilled style={{ fontSize: sizes.medium_large, color: GRAY_600 }} />
      {isAutofillEnrollment ? (
        <PermissionPopover
          disabledMessage={t("patientApplications.enroll.digital_signature.not_allowed_message", {
            actionName: props.actionName
          })}
          hasPermission={props.isEligibleUser}
        >
          <a
            href={props.isEligibleUser && href}
            target="_blank"
            rel="noreferrer"
            style={!props.isEligibleUser ? { cursor: "not-allowed" } : {}}
            {...props}
          >
            {props.isEligibleUser && (
              <ShorterTextWithTooltip text={fileName} maxLength={maxLength} textStyle={updateLinkTextStyle} />
            )}
            {!props.isEligibleUser && (
              <ShorterText text={fileName} maxLength={maxLength} textStyle={updateLinkTextStyle} />
            )}
          </a>
        </PermissionPopover>
      ) : (
        <AttachmentPermissionsPopover>
          <a
            href={hasReadFilePermission && href}
            target="_blank"
            rel="noreferrer"
            style={!hasReadFilePermission ? { cursor: "not-allowed" } : {}}
            {...props}
          >
            {hasReadFilePermission && (
              <ShorterTextWithTooltip text={fileName} maxLength={maxLength} textStyle={updateLinkTextStyle} />
            )}
            {!hasReadFilePermission && (
              <ShorterText text={fileName} maxLength={maxLength} textStyle={updateLinkTextStyle} />
            )}
          </a>
        </AttachmentPermissionsPopover>
      )}
    </Space>
  );
};

const SignatureStatusBadge = styled.div`
  align-self: center;
  padding: 2px 8px;
  border-radius: 16px;
  font-size: ${sizes.small};
  font-weight: ${fontWeights.semibold};
  ${({ status }) => {
    switch (status) {
      case EEsignatureStatuses.SUBMITTED:
      case EEsignatureStatuses.SUBMISSION_IN_PROGRESS:
      case EEsignatureStatuses.SIGNED:
        return `background: ${SUCCESS_50}; color: ${SUCCESS_700}`;
      case EEsignatureStatuses.PENDING:
        return `background: ${WARNING_50}; color: ${WARNING_600}`;
      case EEsignatureStatuses.DRAFT:
        return `background: ${ERROR_50}; color: ${ERROR_700}`;
      case EEsignatureStatuses.UNSIGNED:
      default:
        return `background: ${GRAY_100}; color: ${GRAY_600}`;
    }
  }}
`;

const _getEsignStatusBadgeStyle = (status) => {
  switch (status) {
    case ApplicationFormSignatureStatuses.SIGNED:
      return { backgroundColor: SUCCESS_50, textColor: SUCCESS_700 };
    case ApplicationFormSignatureStatuses.PENDING:
      return { backgroundColor: WARNING_50, textColor: WARNING_700 };
    case ApplicationFormSignatureStatuses.EXPIRED:
      return { backgroundColor: ERROR_50, textColor: ERROR_700 };
    case ApplicationFormSignatureStatuses.NOT_STARTED:
      return { backgroundColor: PRIMARY_50, textColor: PRIMARY_700 };
    default:
      return { backgroundColor: GRAY_100, textColor: GRAY_600 };
  }
};

const ESignatureStatusBadge = ({ status, ...props }) => {
  const { t } = useTranslation();
  const { backgroundColor, textColor } = _getEsignStatusBadgeStyle(status);

  return (
    <Tag
      {...props}
      bordered={false}
      color={backgroundColor}
      style={{
        color: textColor,
        fontSize: sizes.small,
        fontWeight: fontWeights.semibold,
        borderRadius: "16px",
        padding: "2px 10px"
      }}
    >
      {t(`patientApplications.enroll.electronic_signatures.signature_step.status.${status}`)}
    </Tag>
  );
};

const SignatureStatus = ({ status }) => {
  const { t } = useTranslation();
  return (
    <SignatureStatusBadge status={status}>
      {t(`patientApplications.enroll.electronic_signatures.status.${status}`)}
    </SignatureStatusBadge>
  );
};

const Gray500Divider = styled(Divider).attrs((style, ...props) => ({
  orientation: "left",
  orientationMargin: 0,
  plain: true,
  style: { margin: "16px 0px", ...style },
  ...props
}))`
  &&.ant-divider {
    color: ${GRAY_500};
  }
`;

const NoAccessTooltip = ({ children }) => {
  const { t } = useTranslation();
  const isPhiAllowed = useSelector(selectIsPhiAllowed);

  return (
    <Tooltip
      overlayInnerStyle={{ padding: "10px 10px", width: "320px" }}
      overlayStyle={{ maxWidth: "450px" }}
      color={WHITE}
      placement="left"
      title={!isPhiAllowed ? <Text>{t("patientApplications.enroll.enrollment_form.no_access_tooltip")}</Text> : ""}
    >
      {children}
    </Tooltip>
  );
};

const IncompleteStep = ({ title, description, actionsComponent, customStyle }) => (
  <div style={{ display: "flex", justifyContent: "space-between", ...customStyle }}>
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", paddingTop: 4 }}>
      <Text style={{ color: GRAY_900, fontWeight: fontWeights.bold }}>{title}</Text>
      <Text style={{ color: GRAY_500 }}>{description}</Text>
    </div>
    <div style={{ alignSelf: "center" }}>{actionsComponent}</div>
  </div>
);

const SignatureStepDescription = ({ title, description }) => (
  <div style={{ marginBottom: 10, display: "flex", justifyContent: "space-between" }}>
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", paddingTop: 4 }}>
      <Text style={{ color: GRAY_900, fontWeight: fontWeights.bold }}>{title}</Text>
      <Text style={{ color: GRAY_500 }}>{description}</Text>
    </div>
  </div>
);

const DividedIncompleteStep = ({ title, description, actionsComponent }) => (
  <>
    <IncompleteStep title={title} description={description} actionsComponent={actionsComponent} />
    <Divider type="horizontal" style={{ borderColor: GRAY_300, margin: "24px 0px" }} />
  </>
);

const CompletedStep = ({ title, bottomDescription: BottomDescription, children }) => (
  <div style={{ padding: "0px 0px 8px 0px" }}>
    {title && (
      <div style={{ marginBottom: 16, paddingTop: 4 }}>
        <Text style={{ color: GRAY_900, fontWeight: fontWeights.bold }}>{title}</Text>
      </div>
    )}
    <Card size="small">
      <Space style={{ width: "100%", justifyContent: "space-between" }}>{children}</Space>
    </Card>
    {BottomDescription}
  </div>
);

const EnrollmentCompletedStep = ({ title, children, description, lastChanged }) => (
  <div style={{ padding: "0px 0px 12px 0px" }}>
    <div style={{ marginBottom: 12, paddingTop: 4 }}>
      <Text style={{ fontWeight: fontWeights.bold }}>{title}</Text>
    </div>
    {typeof description === "string" ? (
      <Text style={{ fontWeight: 600, marginRight: 8 }}>{description}</Text>
    ) : (
      description
    )}
    {children}
    {lastChanged && <LastChanged lastChanged={lastChanged} />}
  </div>
);

const LastChanged = ({ lastChanged }) => {
  const { t } = useTranslation();
  return (
    <div>
      <CheckOutlined style={{ color: PRIMARY_650, marginRight: 4 }} />
      <Text style={{ color: GRAY_700, fontSize: 12 }}>
        {`${t("patientApplications.last_changed_on")} ${dateTimeFormatter(lastChanged)}`}
      </Text>
    </div>
  );
};

const CompletedSubmitStep = ({ title, description, showViewDetails = true }) => {
  const [isSubmittedFormModalOpen, setIsSubmittedFormModalOpen] = useState(false);
  const expressEnrollMapping = getExpressEnrollmentMapping();

  const { viewDetailsButtonId, getViewDetailsModal } = expressEnrollMapping || {};

  return (
    <>
      <div style={{ paddingTop: 4 }}>
        <Text style={{ color: GRAY_900, fontWeight: fontWeights.bold }}>{title}</Text>
      </div>
      <Space align={"baseline"} size={0}>
        <Text style={{ color: GRAY_500 }}>{description}</Text>
        {showViewDetails && (
          <ViewApplicationFormActionButton id={viewDetailsButtonId} onClick={() => setIsSubmittedFormModalOpen(true)} />
        )}
      </Space>
      {getViewDetailsModal?.(() => setIsSubmittedFormModalOpen(false), isSubmittedFormModalOpen)}
    </>
  );
};

const ApplicationTabSkeleton = () => <Skeleton active paragraph={{ rows: 3 }} />;

const CloseButton = ({ id, onClick }) => {
  const { t } = useTranslation();
  return (
    <Button style={{ height: "36px", width: "72px" }} onClick={onClick} id={id}>
      <Text style={{ fontWeight: fontWeights.semibold, fontSize: sizes.medium }}>
        {t("patientApplications.enroll.electronic_signatures.close")}
      </Text>
    </Button>
  );
};

const PrepareApplicationFormButton = ({ onClick, continueText, isSavingOrExecuting, icon }) => {
  const applicationForm = useSelector(selectApplicationForm);
  const hasApplicationForm = !!applicationForm?.id;
  const { t } = useTranslation();
  const isPhiAllowed = useSelector(selectIsPhiAllowed);

  const { prepareButtonId, resubmitButtonId } = getExpressEnrollmentMapping() || {};

  return (
    <NoAccessTooltip>
      <ExpressEnrollButton
        id={
          applicationForm?.status === EEsignatureStatuses.SUBMIT_FAILED && resubmitButtonId
            ? resubmitButtonId
            : prepareButtonId
        }
        style={{ height: 36, minWidth: 182, alignSelf: "center" }}
        type="primary"
        onClick={onClick}
        loading={isSavingOrExecuting}
        disabled={!isPhiAllowed}
      >
        <Space style={{ lineHeight: 0 }}>
          {icon}
          {!isSavingOrExecuting && (
            <Space>
              {!hasApplicationForm ? t("patientApplications.enroll.enrollment_form.open_editor_button") : continueText}
            </Space>
          )}
        </Space>
      </ExpressEnrollButton>
    </NoAccessTooltip>
  );
};

const SupportEmail = () => {
  const { t } = useTranslation();
  return (
    <a
      style={{ fontSize: "16px", fontWeight: "500", color: BRAND_BLUE, textDecoration: "underline" }}
      href={t("email_to_support")}
      rel="noreferrer"
    >
      {t("support_email")}
    </a>
  );
};

const SupportLink = ({ style }) => {
  const { t } = useTranslation();

  return (
    <div style={{ ...style, display: "inlineBlock" }}>
      <TextStyled>{t("patientApplications.enroll.enroll_result.contact_support")}</TextStyled>
      <SupportEmail />
    </div>
  );
};

export {
  CloseButton,
  ExpressEnrollSubmitButton,
  FileLink,
  SignatureStatus,
  BottomDescription,
  Gray500Divider,
  NoAccessTooltip,
  DividedIncompleteStep,
  IncompleteStep,
  CompletedStep,
  CompletedSubmitStep,
  ApplicationTabSkeleton,
  PrepareApplicationFormButton,
  SupportEmail,
  SupportLink,
  EnrollmentCompletedStep,
  ESignatureStatusBadge,
  SignatureStepDescription
};
