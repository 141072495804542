import { Button, Divider, Form, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import _, { noop } from "lodash";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  automatePatientAfterUpdate,
  createDiagnosisEvent,
  addDrugAPI,
  searchIcdToDiagnoses,
  updateInsuranceAPI,
  updateUninsuredStatusAPI,
  searchRxnormDrugsAPI
} from "../../api/api";
import { WHITE, ERROR_300, ERROR_600, GRAY_700, PRIMARY_600, ERROR_50 } from "../../constant/colors";
import { PLAN_MARKETS_OPTIONS } from "../../constant/planMarkets";
import { useFetch } from "../../hooks/fetch";
import { useGetSetting } from "../../hooks/getSetting";
import { formatCoverage } from "../../utils/coverages";
import { buildPayloadFromDiagnosisTableData } from "../../utils/diagnosis";
import { isEmpty } from "../../utils/string";
import { formatMedication } from "../../utils/treatments";
import { PrimaryButton } from "../customComponent/Button";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";
import { CREATE_NEW_PATIENT_STEPS, SUPPORTED_COVERAGES } from "../../constant/patient";
import SuccessMessage from "../customComponent/customMessages/SuccessMessage";
import { MultiSelect } from "../customComponent/CustomSelect";
import PatientDetailsSummary from "./PatientDetailsSummary";
import ArrowBack from "../../assets/svg/ArrowBack";
import { MultiSelectDrugSearch } from "@tailormed/common-client/component/MultiSelectDrugSearch";
import { CustomForm } from "../../component/customComponentNewDesign/CustomForm";
import { sizes } from "../../constant/styles";

const StyledForm = styled(CustomForm).attrs((props) => ({
  validateMessages: {
    required: ""
  },
  ...props
}))`
  padding: 0 1.5em 0 1.5em;
  .ant-picker {
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
    color: ${GRAY_700};
  }

  .ant-input,
  .ant-select-selection-item {
    color: ${GRAY_700};
  }

  && .ant-form-item.ant-form-item-has-error {
    .ant-picker,
    .ant-radio-inner,
    .ant-input,
    .ant-select.ant-select-status-error .ant-select-selector,
    .ant-input-number {
      border-color: ${ERROR_300} !important;
    }

    .ant-select.ant-select-status-error .ant-select-selector {
      border-color: ${ERROR_300} !important;
      background: ${ERROR_50} !important;
    }
  }

  .ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
    border-color: ${ERROR_300};
  }

  .ant-tooltip {
    color: ${GRAY_700} !important;
    background-color: ${PRIMARY_600} !important;
  }

  .ant-divider-inner-text {
    padding-left: 0;
  }

  .ant-form-item-explain-error {
    color: ${ERROR_600};
    font-size: ${sizes.small};
  }

  .ant-form-item-label > label {
    color: ${GRAY_700};
    font-weight: 600;
  }

  .ant-radio-wrapper {
    color: ${GRAY_700};
    font-weight: 600;
  }

  .ant-checkbox-wrapper {
    color: ${GRAY_700};
    font-weight: 600;
  }

  > .ant-row {
    align-items: flex-end;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${PRIMARY_600} !important;
    border-color: ${PRIMARY_600} !important;
  }
`;

const { Text } = Typography;
const { Option } = Select;
const MAX_TAG_AMOUNT = 9999;

const FindAssistanceProgramsStep = ({
  patient,
  closePopover = noop,
  setDiscardChangesPopupVisibility = noop,
  changeStep = noop,
  defineDiscardFunc = noop
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();

  const [isUpdatingPatient, setIsUpdatingPatient] = useState(false);
  const [isPatientUninsured, setIsPatientUninsured] = useState(false);
  const [planMarketOptions, setPlanMarketOptions] = useState([]);
  const [selectedDrugs, setSelectedDrugs] = useState([]);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);
  const [searchDiagnosis, searchDiagnosisResult, searchInProgress] = useFetch(searchIcdToDiagnoses);
  const [isStartDateRequired] = useGetSetting(["automation.medication-creation.isStartDateRequired"]);
  const UNINSURED_VALUE = "uninsured";

  useEffect(() => {
    const options = [];

    PLAN_MARKETS_OPTIONS?.forEach((planMarket) =>
      options.push({
        value: planMarket.value,
        key: planMarket.key,
        label: _.startCase(planMarket.translationText)
      })
    );

    setPlanMarketOptions(options);
  }, []);

  const handleDiagnosisSearch = (searchValue) => {
    if (!searchValue && !isEmpty(searchValue)) return;
    searchDiagnosis(searchValue);
  };

  const newCoverageRequests = (coverages) => [
    () =>
      updateInsuranceAPI(
        patient?.id,
        [],
        coverages.map((insuranceRow) => _.omit(insuranceRow, "id"))
      )
  ];

  const handleSubmit = async () => {
    try {
      setIsUpdatingPatient(true);
      const formData = form.getFieldsValue(true);
      const { planMarkets } = formData || {};
      const requestList = [];
      const coverageTypes = [SUPPORTED_COVERAGES.primary, SUPPORTED_COVERAGES.secondary, SUPPORTED_COVERAGES.tertiary];

      const coverages = planMarkets.map((planMarket, index) =>
        formatCoverage({ planMarket }, coverageTypes[index], patient?.id)
      );

      selectedDrugs?.forEach(({ rxnormDrug, ...medicationData }) => {
        requestList.push(
          addDrugAPI({
            rxnormDrug,
            medicationData: _.omitBy(
              {
                ...medicationData
              },
              _.isNil
            ),
            patientId: patient?.id
          })
        );
      });

      const addDiagnosesEffects = selectedDiagnoses.map((diagnosisData) => {
        const data = buildPayloadFromDiagnosisTableData(diagnosisData);
        return () => createDiagnosisEvent(patient?.id, data.icd, data);
      });
      requestList.push(...addDiagnosesEffects.map((generateRequest) => generateRequest()));

      if (isPatientUninsured) {
        requestList.push(updateUninsuredStatusAPI(patient?.id, true));
      } else {
        requestList.push(...newCoverageRequests(coverages).map((generateRequest) => generateRequest()));
      }

      if (requestList.length) {
        await Promise.all(requestList);
        await automatePatientAfterUpdate({
          patientId: patient?.id,
          treatmentMedicationUpdate: true,
          diagnosisUpdate: true,
          insuranceRowsUpdate: true
        });

        SuccessMessage(t("successMessages.updated_patient_details"));
        history.push(`/patient/${patient?.id}/journey/${patient?.journeyId}/optimizations`);
      }
    } catch (error) {
      setIsUpdatingPatient(false);
      ErrorMessage(t("errorMessages.update_patient_details_failed"));
    }
  };

  const handleSelectedDrugs = (selectedDrugs) => {
    form.setFieldsValue({ drugs: selectedDrugs });
    setSelectedDrugs(
      selectedDrugs.map((drug) =>
        _.get(
          formatMedication({
            rxnormDrug: drug,
            isStartDateRequired
          }),
          "manualTreatments[0]",
          {}
        )
      )
    );
  };

  const onDiagnosesSelect = (diagnoses) => {
    const diagnosesData = diagnoses?.map(({ key }) => {
      const diagnosisData = selectedDiagnoses.find(({ icd }) => icd === key);
      if (diagnosisData) return diagnosisData;

      const diagnosisDataSearchResults = searchDiagnosisResult.data.ICDtoDiagnosises.find(({ code }) => code === key);
      return {
        isPrimary: true,
        activeDate: moment(),
        isActive: true,
        icd: key,
        diagnosisInfo: { name: diagnosisDataSearchResults.diagnosis, icd: key }
      };
    });

    setSelectedDiagnoses(diagnosesData);
  };

  const searchRxnormDrugs = async (searchTerm) => {
    const response = await searchRxnormDrugsAPI({ text: searchTerm });
    return response?.data?.rxnormDrugs || [];
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <CloseOutlined
          style={{ padding: "18.5px 18.5px 0px 0px" }}
          id="close_create_new_patient_popover"
          onClick={() => (form.isFieldsTouched() ? setDiscardChangesPopupVisibility(true) : closePopover())}
        />
      </div>
      <div style={{ padding: "0px 24px" }}>
        <PatientDetailsSummary patient={patient} />
      </div>
      <StyledForm
        onFinish={handleSubmit}
        form={form}
        labelAlign="left"
        width={"100%"}
        style={{ padding: "14px 24px 12px 24px", overflow: "hidden" }}
      >
        <Divider style={{ margin: "2px 0px 16px 0px" }} />
        <Space direction="vertical" style={{ marginBottom: 16 }} size={2}>
          <Space>
            <Button
              id="back_to_whats_next_step_button"
              style={{ padding: 0 }}
              type="text"
              onClick={() => {
                if (form.isFieldsTouched()) {
                  defineDiscardFunc(() => {
                    changeStep(CREATE_NEW_PATIENT_STEPS.whatNext);
                    setDiscardChangesPopupVisibility(false);
                  });
                  setDiscardChangesPopupVisibility(true);
                } else {
                  changeStep(CREATE_NEW_PATIENT_STEPS.whatNext);
                }
              }}
            >
              <ArrowBack />
            </Button>
            <Text style={{ fontSize: 18, fontWeight: 600 }}>{t("new_patient.find_ap_title")}</Text>
          </Space>
          <Text style={{ fontSize: 16 }}>{t("new_patient.find_ap_sub_title")}</Text>
        </Space>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text style={{ fontWeight: 600 }}>{t("new_patient.form_fields.plan_markets")}</Text>
          <Form.Item name={"planMarkets"} rules={[{ required: true }]} style={{ marginBottom: 16 }}>
            <MultiSelect
              id={"plan_markets"}
              labelInValue={false}
              maxTagCount={3}
              useCommonStyle={false}
              style={{ border: "1px solid #d0d5dd", borderRadius: 4 }}
              placeholder={t("new_patient.place_holders.plan_markets")}
              size={"large"}
              options={planMarketOptions}
              onSelect={(value) => {
                if (value === UNINSURED_VALUE) {
                  form.setFieldsValue({ planMarkets: [UNINSURED_VALUE] });
                  setIsPatientUninsured(true);
                  const planMarkets = planMarketOptions.map((planMarket) => ({
                    ...planMarket,
                    disabled: planMarket.value === UNINSURED_VALUE ? false : true,
                    selected: planMarket.value === UNINSURED_VALUE ? true : undefined
                  }));
                  setPlanMarketOptions(planMarkets);
                }
              }}
              onDeselect={(value) => {
                if (value === UNINSURED_VALUE) {
                  setIsPatientUninsured(false);
                  const planMarkets = planMarketOptions.map((planMarket) => ({
                    ...planMarket,
                    disabled: false
                  }));
                  setPlanMarketOptions(planMarkets);
                }
              }}
              onChange={(selectedPlanMarkets) => {
                setPlanMarketOptions(
                  planMarketOptions.map((planMarket) => {
                    const isPlanMarketSelected = selectedPlanMarkets.find((selectedPlanMarket) => {
                      return selectedPlanMarket === planMarket.value;
                    });

                    return {
                      ...planMarket,
                      disabled: selectedPlanMarkets?.length < 3 ? false : isPlanMarketSelected ? false : true
                    };
                  })
                );
              }}
            />
          </Form.Item>
        </Space>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text style={{ fontWeight: 600 }}>{t("new_patient.form_fields.drugs")}</Text>
          <Form.Item name={"drugs"} rules={[{ required: true }]} style={{ marginBottom: 16 }}>
            <MultiSelectDrugSearch
              id="drugs"
              searchRxnormDrugs={searchRxnormDrugs}
              mode="multiple"
              placeholder={t("new_patient.place_holders.drugs")}
              onChangeSelectedDrugs={(selectedDrugs) => handleSelectedDrugs(selectedDrugs)}
              onClear={() => handleSelectedDrugs([])}
              size={"large"}
            />
          </Form.Item>
        </Space>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text style={{ fontWeight: 600 }}>{t("new_patient.form_fields.diagnoses")}</Text>
          <Form.Item name={"diagnoses"} rules={[{ required: true }]} style={{ marginBottom: 16 }}>
            <MultiSelect
              id={"diagnoses"}
              maxTagCount={MAX_TAG_AMOUNT}
              size={"large"}
              useCommonStyle={false}
              style={{
                border: "1px solid #d0d5dd",
                borderRadius: 4
              }}
              labelInValue
              showArrow={true}
              showSearch={true}
              allowClear={true}
              loading={searchInProgress}
              onSearch={handleDiagnosisSearch}
              filterOption={false}
              notFoundContent={searchDiagnosisResult ? "No Results Found" : null}
              placeholder={t("new_patient.place_holders.diagnoses")}
              onChange={(value) => {
                onDiagnosesSelect(value);
              }}
            >
              {searchDiagnosisResult?.data?.ICDtoDiagnosises?.map(({ diagnosis, code, tmDiagnosis }) => (
                <Option key={code}>{`${code} - ${diagnosis} (${tmDiagnosis})`}</Option>
              ))}
            </MultiSelect>
          </Form.Item>
        </Space>
        <Space size={16} style={{ float: "right", marginBottom: 12, marginTop: 15 }}>
          <PrimaryButton
            id="find_ap_new_patient_submit_button"
            style={{ fontSize: 16, width: isUpdatingPatient ? 300 : 274, height: 44 }}
            htmlType="submit"
            loading={isUpdatingPatient}
          >
            <Text style={{ color: WHITE, fontSize: 16, fontWeight: 600 }}>{t("new_patient.save_and_find_ap")}</Text>
          </PrimaryButton>
        </Space>
      </StyledForm>
    </>
  );
};

export default FindAssistanceProgramsStep;
