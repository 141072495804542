import React from "react";
import { InputNumber, Form } from "antd";

export const InputNumberItem = ({ name, label, rules, inputPlaceholder, inputProps, style }) => {
  return (
    <Form.Item name={name} label={label} rules={rules} style={style}>
      <InputNumber {...inputProps} placeholder={inputPlaceholder} />
    </Form.Item>
  );
};
