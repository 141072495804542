const patientFields = {
  id: ["patient", "id"],
  firstName: ["patient", "firstName"],
  lastName: ["patient", "lastName"],
  dob: ["patient", "dob"],
  gender: ["patient", "gender"],
  phoneType: ["patient", "phoneType"],
  phone: ["patient", "phone"],
  email: ["patient", "email"],
  address: ["patient", "address"],
  city: ["patient", "city"],
  state: ["patient", "state"],
  zip: ["patient", "zip"],
  additionalPhone: ["patient", "additionalPhone"],
  additionalPhoneType: ["patient", "additionalPhoneType"]
};

const insuranceFields = {
  isInsured: ["insurance", "isInsured"],
  primaryPayerName: ["insurance", "primary", "payerName"],
  secondaryPayerName: ["insurance", "secondary", "payerName"]
};

const medicalFields = {};

const patientConsentFields = {};

const practiceFields = {
  id: ["practice", "id"],
  prescriberFirstName: ["practice", "prescriberFirstName"],
  prescriberLastName: ["practice", "prescriberLastName"],
  prescriberNpi: ["practice", "prescriberNpi"],
  groupTaxId: ["practice", "prescriberGroupTaxId"]
};

const submitAttestationFields = {};

export { patientFields, insuranceFields, medicalFields, patientConsentFields, practiceFields, submitAttestationFields };
