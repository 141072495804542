export const ColumnsKeys = Object.freeze({
  tables: {
    "all-patients": "all-patients",
    "visit-schedule": "visit-schedule",
    "pending-renewal": "pending-renewal",
    "high-priority": "high-priority",
    "saved-list": "saved-list",
    "all-applications": "all-applications",
    "all-claims": "all-claims"
  }
});
