import { Space, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { SecondaryButton } from "../customComponent/Button";
const { Text } = Typography;

const StyledSpace = styled(Space)`
  display: flex;
  justify-content: space-between;
`;

const SubTitle = styled(Text)`
  color: #667085;
`;
const SubSectionHeader = ({ buttonAction, buttonTitle, title, spaceStyle, ...props }) => {
  return (
    <StyledSpace style={spaceStyle}>
      <SubTitle> {title}</SubTitle>
      {buttonAction && (
        <SecondaryButton onClick={buttonAction} {...props}>
          {buttonTitle}
        </SecondaryButton>
      )}
    </StyledSpace>
  );
};

export default SubSectionHeader;
