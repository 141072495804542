import _ from "lodash";

// Without a reducer function memoize looks at the object reference, which
// doesn't change as a result of property changes
export const buildPayloadFromDiagnosisTableData = _.memoize((diagnosisTableData) => {
  const { diagnosisInfo, activeDate, isActive, isPrimary } = diagnosisTableData;
  return {
    icd: diagnosisInfo.icd,
    activeDate,
    isActive,
    isPrimary
  };
}, JSON.stringify);
