import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Divider, Form, Radio, Row, Select, Typography, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { debounce } from "lodash";

import { SingleSelect } from "../../../../../customComponentNewDesign/CustomSelect";
import { searchIcdToDiagnoses } from "../../../../../../api/api";
import { useFetch } from "../../../../../../hooks/fetch";
import { clinicalFields, diagnosisFields } from "../fieldNames";
import { fontWeights, sizes } from "../../../../../../constant/styles";
import { isEmpty } from "../../../../../../utils/string";
import { GRAY_500, PRIMARY_600 } from "../../../../../../constant/colors";
import { ReactComponent as BlueTrashIcon } from "../../../../../../assets/svg/BlueTrashIcon.svg";
import { FormDividerText, FormItem } from "../../../components";
import { PEMFEXY_DEFAULT_NDC } from "../../../constants";

const { Option } = SingleSelect;
const { Text } = Typography;

const StyledFormItem = styled(FormItem)`
  label {
    width: ${(props) => props.width};
  }
`;

export const siteOfCareOptions = Object.freeze({
  PHYSICIANS_OFFICE: "physiciansSiteOfCare",
  HOSPITAL_OUTPATIENT: "hospitalSiteOfCare",
  OTHER: "otherSiteOfCare"
});

const DiagnosisDetails = ({ type, isRequired, fieldName, onDelete }) => {
  const [searchDiagnosis, searchDiagnosisResult, searchInProgress, , resetSearchDiagnosis] =
    useFetch(searchIcdToDiagnoses);
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const handleDiagnosisSearch = (searchValue) => {
    if (isEmpty(searchValue) || searchValue.trim().length < 2) return;
    resetSearchDiagnosis();
    searchDiagnosis(searchValue);
  };

  useEffect(() => {
    const fieldValue = form?.getFieldValue(fieldName);
    handleDiagnosisSearch(fieldValue);
  }, []);

  const diagnosisSearchResults = searchDiagnosisResult ? searchDiagnosisResult.data?.ICDtoDiagnosises || [] : null;

  return (
    <>
      <Row gutter={14}>
        <Col span={24}>
          <StyledFormItem
            width={isRequired ? null : "100%"}
            label={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "baseline"
                }}
              >
                {t(`application_form_editor.eagle.clinical.labels.${type}_diagnosis`)}
                {!isRequired && (
                  <Button
                    type="text"
                    icon={<BlueTrashIcon />}
                    onClick={() => {
                      form.setFields([{ name: fieldName, value: null }]);
                      onDelete();
                    }}
                    style={{ height: 0 }}
                  />
                )}
              </div>
            }
            name={fieldName}
            rules={[{ required: isRequired }]}
          >
            <Select
              showSearch
              allowClear
              loading={searchInProgress}
              onSearch={debounce(handleDiagnosisSearch, 300)}
              filterOption={false}
              notFoundContent={diagnosisSearchResults ? t("add_new_diagnosis.no_results_found") : t("searching")}
              placeholder={t("application_form_editor.eagle.clinical.placeholders.diagnosis")}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {diagnosisSearchResults?.map(({ diagnosis, code, tmDiagnosis }, index) => (
                <Option key={index} value={code}>
                  {`${code} - ${diagnosis} (${tmDiagnosis})`}
                </Option>
              ))}
            </Select>
          </StyledFormItem>
        </Col>
      </Row>
    </>
  );
};

const Clinical = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const [isSecondaryDiagnosisVisible, setIsSecondaryDiagnosisVisible] = useState(false);

  useEffect(() => {
    if (form?.getFieldValue(diagnosisFields.secondary)) {
      setIsSecondaryDiagnosisVisible(true);
    }
  }, []);

  return (
    <>
      <Divider
        id="enrollment-form-clinical-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.eagle.clinical.labels.header")}</FormDividerText>
      </Divider>
      <div style={{ paddingTop: 10 }}>
        <DiagnosisDetails type="primary" fieldName={diagnosisFields.primary} isRequired={true} />
        {!isSecondaryDiagnosisVisible ? (
          <Button
            type="text"
            style={{ color: PRIMARY_600, fontSize: sizes.medium, padding: 0 }}
            onClick={() => setIsSecondaryDiagnosisVisible(!isSecondaryDiagnosisVisible)}
          >
            <PlusOutlined />
            <Text style={{ fontWeight: fontWeights.semibold, color: PRIMARY_600 }}>
              {t("application_form_editor.eagle.clinical.add_second_label")}
            </Text>
          </Button>
        ) : (
          <DiagnosisDetails
            type="secondary"
            fieldName={diagnosisFields.secondary}
            isRequired={false}
            onDelete={() => setIsSecondaryDiagnosisVisible(false)}
          />
        )}
      </div>

      <Row gutter={14} style={{ marginTop: "24px" }}>
        <Col span={8}>
          <FormItem
            name={clinicalFields.productNdc}
            label={t("application_form_editor.eagle.clinical.labels.product_ndc")}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={t("application_form_editor.eagle.clinical.placeholders.product_ndc")}
              defaultValue={PEMFEXY_DEFAULT_NDC}
            />
          </FormItem>
        </Col>

        <Col span={16}>
          <FormItem
            name={clinicalFields.treatmentFdaApproved}
            rules={[{ required: true }]}
            label={t("application_form_editor.eagle.clinical.labels.treatment_fda")}
          >
            <Radio.Group>
              <Radio value={true}>{t("application_form_editor.eagle.clinical.yes")}</Radio>
              <Radio value={false}>{t("application_form_editor.eagle.clinical.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={8}>
          <FormItem
            name={clinicalFields.previousTherapies}
            label={t("application_form_editor.eagle.clinical.labels.previous_therapies")}
          >
            <Input placeholder={t("application_form_editor.eagle.clinical.placeholders.previous_therapies")} />
          </FormItem>
        </Col>

        <Col span={16}>
          <FormItem
            name={clinicalFields.concurrentTherapies}
            label={t("application_form_editor.eagle.clinical.labels.concurrent_therapies")}
          >
            <Input placeholder={t("application_form_editor.eagle.clinical.placeholders.concurrent_therapies")} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={24}>
          <FormItem
            name={clinicalFields.siteOfCare}
            rules={[{ required: true }]}
            label={t("application_form_editor.eagle.clinical.labels.site_of_care")}
          >
            <Radio.Group>
              <Radio value={siteOfCareOptions.PHYSICIANS_OFFICE}>
                {t("application_form_editor.eagle.clinical.physicians_office")}
              </Radio>
              <Radio value={siteOfCareOptions.HOSPITAL_OUTPATIENT}>
                {t("application_form_editor.eagle.clinical.hospital_outpatient")}
              </Radio>
              <Radio value={siteOfCareOptions.OTHER}>{t("application_form_editor.eagle.clinical.other")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default Clinical;
