import React from "react";

export default function NoSearchResultIcon(props) {
  return (
    <svg width={73} height={47} viewBox="0 0 52 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.4805 15.7529C23.8577 15.7529 25.7623 13.8339 25.7623 11.4568C25.7623 9.07957 23.8577 7.16064 21.4805 7.16064C19.1034 7.16064 17.1844 9.07957 17.1844 11.4568C17.1844 13.8339 19.1034 15.7529 21.4805 15.7529ZM10.0243 15.7529C12.4014 15.7529 14.306 13.8339 14.306 11.4568C14.306 9.07957 12.4014 7.16064 10.0243 7.16064C7.64707 7.16064 5.72814 9.07957 5.72814 11.4568C5.72814 13.8339 7.64707 15.7529 10.0243 15.7529ZM10.0243 18.6169C6.68761 18.6169 0 20.2924 0 23.6291V27.2092H20.0485V23.6291C20.0485 20.2924 13.3609 18.6169 10.0243 18.6169ZM21.4805 18.6169C21.0652 18.6169 20.5927 18.6456 20.0915 18.6885C21.7526 19.8915 22.9126 21.5097 22.9126 23.6291V27.2092H31.5048V23.6291C31.5048 20.2924 24.8172 18.6169 21.4805 18.6169Z"
        fill="#C4C4C4"
      />
      <path
        d="M36.2264 7.67861L39.883 11.3351M39.883 7.67861L36.2264 11.3351"
        stroke="#C4C4C4"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9409 15.3942C44.0686 15.2663 44.2202 15.1649 44.3872 15.0958C44.5541 15.0266 44.733 14.991 44.9137 14.991C45.0943 14.991 45.2733 15.0266 45.4402 15.0958C45.6071 15.1649 45.7587 15.2663 45.8864 15.3942L51.1797 20.687C51.4377 20.9448 51.5827 21.2945 51.5828 21.6592C51.583 22.0239 51.4382 22.3737 51.1804 22.6317C50.9226 22.8896 50.5729 23.0346 50.2082 23.0347C49.8434 23.0349 49.4936 22.8901 49.2356 22.6323L43.9423 17.3395C43.8145 17.2118 43.7131 17.0602 43.6439 16.8933C43.5747 16.7264 43.5391 16.5475 43.5391 16.3668C43.5391 16.1862 43.5747 16.0073 43.6439 15.8404C43.7131 15.6735 43.8145 15.5218 43.9423 15.3942H43.9409Z"
        fill="#C4C4C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0532 17.5501C39.1095 17.5501 40.1555 17.342 41.1314 16.9378C42.1073 16.5335 42.994 15.941 43.741 15.1941C44.4879 14.4472 45.0804 13.5604 45.4846 12.5845C45.8889 11.6086 46.0969 10.5626 46.0969 9.50628C46.0969 8.44996 45.8889 7.40398 45.4846 6.42806C45.0804 5.45215 44.4879 4.56541 43.741 3.81847C42.994 3.07154 42.1073 2.47904 41.1314 2.0748C40.1555 1.67056 39.1095 1.4625 38.0532 1.4625C35.9198 1.4625 33.8738 2.30997 32.3653 3.81847C30.8568 5.32697 30.0094 7.37294 30.0094 9.50628C30.0094 11.6396 30.8568 13.6856 32.3653 15.1941C33.8738 16.7026 35.9198 17.5501 38.0532 17.5501ZM47.5594 9.50628C47.5594 12.0275 46.5579 14.4455 44.7751 16.2282C42.9923 18.011 40.5744 19.0126 38.0532 19.0126C35.5319 19.0126 33.114 18.011 31.3312 16.2282C29.5484 14.4455 28.5469 12.0275 28.5469 9.50628C28.5469 6.98506 29.5484 4.5671 31.3312 2.78433C33.114 1.00155 35.5319 0 38.0532 0C40.5744 0 42.9923 1.00155 44.7751 2.78433C46.5579 4.5671 47.5594 6.98506 47.5594 9.50628Z"
        fill="#C4C4C4"
      />
    </svg>
  );
}
