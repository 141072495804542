import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { DatePicker, Space, Spin, Typography } from "antd";
import moment from "moment-timezone";

import { selectPatientDetails } from "../../../store/selector";
import { updateFollowupDateAPI } from "../../../api/api";
import ErrorMessage from "../../customComponent/customMessages/ErrorMessage";
import SuccessMessage from "../../customComponent/customMessages/SuccessMessage";
import ACTIONS from "../../../store/action";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;

const PatientFollowUpDateComponant = ({
  updatePatientDetailsAct,
  t,
  patientId,
  patientDetails,
  inputStyle,
  secondaryTextStyle
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const { followupDate } = patientDetails || {};

  const updateFollowupDate = async (followupDate) => {
    try {
      setIsFetching(true);
      const result = await updateFollowupDateAPI(patientId, followupDate);
      if (result && result.data && result.data.success) {
        SuccessMessage("Updated patient folow-up date successfully");
        updatePatientDetailsAct({ ...patientDetails, followupDate });
      } else {
        ErrorMessage("Failed to update patient folow-up date");
      }
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Space direction="vertical" size={4} style={{ width: "100%" }}>
      <Text style={secondaryTextStyle}>{t("patientTopSection.follow_up_date")}</Text>
      <DatePicker
        id="patient_header_follow_up_date"
        placeholder={t("select_date")}
        format={"MM-DD-YYYY"}
        disabled={isFetching}
        value={followupDate && moment(followupDate)}
        allowClear={true}
        bordered={false}
        style={inputStyle}
        suffixIcon={isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} /> : null}
        onChange={(value) => {
          const followupDate = value ? moment(value) : value;
          updateFollowupDate(followupDate);
        }}
      />
    </Space>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updatePatientDetailsAct: (patientDetails) => dispatch(ACTIONS.updatePatientDetailsAction(patientDetails))
});

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

const PatientFollowUpDate = compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PatientFollowUpDateComponant);

export { PatientFollowUpDate };
