import React from "react";
import { css } from "styled-components";
import {
  SUCCESS_50,
  GREEN_DARK3,
  STRONG_GREEN,
  DISABLED_GREEN,
  PRIMARY_400,
  PRIMARY_650,
  PRIMARY_600,
  PRIMARY_800,
  WHITE,
  LIGHT_GREEN
} from "../../constant/colors";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Space, Typography } from "antd";
const { Text } = Typography;
import ElectricityIcon from "../../assets/svg/electricity";
import { fontWeights, sizes } from "../../constant/styles";

export const ExpressEnrolmentBrandContainer = css`
  background-color: ${SUCCESS_50};
  border-color: ${LIGHT_GREEN};
  border-radius: 8px;
`;

export const ExpressEnrolmentBrandContainerWithBorder = css`
  ${ExpressEnrolmentBrandContainer}
  border-style: solid;
  border-width: 1px;
  padding: 16px;
`;

export const ExpressEnrollmentBrandText = css`
  color: ${GREEN_DARK3} !important;
`;

export const ExpressEnrollmentLightText = css`
  color: ${STRONG_GREEN} !important;
`;

export const ExpressEnrollmentButton = css`
  background-color: ${GREEN_DARK3} !important;
  border: 1px solid ${GREEN_DARK3} !important;
`;

export const ExpressEnrollmentButtonDisabled = css`
  background-color: ${DISABLED_GREEN} !important;
  border: 1px solid ${DISABLED_GREEN} !important;
`;

export const ExpressEnrollButton = styled(Button)`
  ${ExpressEnrollmentButton}
  border: 1px solid ${PRIMARY_650};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  background: ${PRIMARY_600};
  color: ${WHITE};
  font-style: normal;
  font-weight: ${fontWeights.semibold};
  font-size: ${sizes.medium};

  &:hover {
    background: ${PRIMARY_800};
    border: 1px solid ${PRIMARY_600};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    color: ${WHITE};
  }

  :active {
    background: ${PRIMARY_600};
    border: 4px solid ${PRIMARY_400};
    border-radius: 4px;
    color: ${WHITE};
    padding-top: 1px;
  }

  :disabled {
    background: ${PRIMARY_600};
    opacity: 0.3;
    border: 1px solid ${PRIMARY_600};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    color: ${WHITE};
  }
`;

const ExpressEnrollmentBrandTextComponent = styled(Text)`
  font-weight: ${fontWeights.semibold};
  ${ExpressEnrollmentBrandText}
`;

export const APStyle = Object.freeze({
  REGULAR: "REGULAR",
  NOVARTIS_FULL_SIZE: "NOVARTIS_FULL_SIZE",
  NOVARTIS_SIDE_NAV: "NOVARTIS_SIDE_NAV"
});

export const SupportsExpressEnrollmentText = ({ style, id, apStyle = APStyle.REGULAR }) => {
  const { t } = useTranslation();

  // Adjusting isNovartis flag
  const isNovartisFullSize = apStyle === APStyle.NOVARTIS_FULL_SIZE;
  const isNovartisSideBar = apStyle === APStyle.NOVARTIS_SIDE_NAV;
  const isNovartis = isNovartisFullSize || isNovartisSideBar;

  const baseText = t("express_enroll.supports_express_enroll");

  // Function to get the smaller font size for 'small' and 'medium'
  const getSmallerFontSize = (fontSize) => {
    if (fontSize === sizes.small) return sizes.xsmall;
    return fontSize;
  };

  // Calculate the smaller font size based on the passed style
  const smallerFontSize = style?.fontSize ? getSmallerFontSize(style.fontSize) : sizes.small;

  // Set additionalText and style based on apStyle
  let additionalText = "";
  let additionalTextStyle = {};
  if (isNovartisFullSize) {
    additionalText = ` (${t("express_enroll.supports_express_enroll_novartis")})`;
    additionalTextStyle = { fontSize: style.fontSize };
  } else if (isNovartisSideBar) {
    additionalText = ` ${t("express_enroll.supports_express_enroll_novartis")}`;
    additionalTextStyle = { fontSize: smallerFontSize, display: "flex", marginBottom: 10 };
  }

  return (
    <Space direction="horizontal" size={5} style={{ alignItems: "flex-start" }}>
      <ElectricityIcon color={GREEN_DARK3} />
      <ExpressEnrollmentBrandTextComponent style={{ ...style }} id={id}>
        {baseText}
        {isNovartis && <span style={additionalTextStyle}>{additionalText}</span>}
      </ExpressEnrollmentBrandTextComponent>
    </Space>
  );
};
