import React, { useState, useEffect } from "react";
import { Col, Form, Input, Radio, Row, Select } from "antd";
import { useTranslation } from "react-i18next";

import { phoneNumberLengthRegEx } from "../../../../../../../utils/formValidation";
import { hcpFields, prescriptionFields } from "../../fieldNames";
import { getDrugByName, drugTypes } from "../prescription/drugDefinitions";
import { FormItem, InputPhone } from "../../../../components";
import { emailInputRules, onlyWhitespacesRule, zipInputRules, npiInputRule } from "../../../../utils";
import { SingleSelect } from "../../../../../../customComponent/CustomSelect";
import { States as STATES } from "../../../../../../../constant/states";

const { Option } = Select;

export const AdministeringProviderForm = () => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();
  const administeringProviderSameAsHCP = Form.useWatch(hcpFields.administeringProviderSameAsHCP, form);
  const drugName = Form.useWatch(prescriptionFields.name, form);
  const [showSiteOfCareQuestion, setShowSiteOfCareQuestion] = useState(false);

  useEffect(() => {
    form.validateFields();
  }, [administeringProviderSameAsHCP, showSiteOfCareQuestion]);

  useEffect(() => {
    const drug = getDrugByName(drugName);
    setShowSiteOfCareQuestion(drug?.category !== drugTypes.ORAL);
  }, [drugName]);

  return (
    <>
      {showSiteOfCareQuestion && (
        <Row gutter={14}>
          <Col span={24}>
            <FormItem
              rules={[{ required: true }]}
              name={hcpFields.administeringProviderSameAsHCP}
              label={t("application_form_editor.pfizer.hcp.labels.provider_same_as_site_of_care")}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        </Row>
      )}
      {administeringProviderSameAsHCP === false && (
        <>
          <Row gutter={14}>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderName}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_name")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.administering_provider_name")} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderSpecialty}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_specialty")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  placeholder={t("application_form_editor.pfizer.hcp.placeholders.administering_provider_specialty")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderNpi}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_npi")}
                rules={[{ required: true }, npiInputRule, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={10}
                  placeholder={t("application_form_editor.pfizer.hcp.placeholders.administering_provider_npi")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderStateLicense}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_state_license")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_state_license"
                  )}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={12}>
              <FormItem
                name={hcpFields.administeringProviderFacilityName}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_name")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_name"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderGroupTaxId}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_group_tax_id")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_group_tax_id"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderDea}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_dea")}
              >
                <Input
                  placeholder={t("application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_dea")}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderAddress}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_street_address")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_street_address"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderCity}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_city")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_city"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderState}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_state")}
                rules={[{ required: true }]}
              >
                <SingleSelect
                  useCommonStyle={false}
                  bordered={true}
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_state"
                  )}
                >
                  {Object.values(STATES).map((state, index) => (
                    <Option key={index} value={state.code}>
                      {state.name}
                    </Option>
                  ))}
                </SingleSelect>
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                rules={[{ required: true }, ...zipInputRules, onlyWhitespacesRule]}
                name={hcpFields.administeringProviderZip}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_zip")}
              >
                <Input
                  maxLength={5}
                  placeholder={t("application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_zip")}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={14}>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderContactName}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_contact_name")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_contact_name"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderContactPhone}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_contact_phone")}
                rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
              >
                <InputPhone
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_contact_phone"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderContactFax}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_contact_fax")}
                rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
              >
                <InputPhone
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_contact_fax"
                  )}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={hcpFields.administeringProviderContactEmail}
                label={t("application_form_editor.pfizer.hcp.labels.administering_provider_facility_contact_email")}
                rules={[{ required: true }, ...emailInputRules]}
              >
                <Input
                  placeholder={t(
                    "application_form_editor.pfizer.hcp.placeholders.administering_provider_facility_contact_email"
                  )}
                />
              </FormItem>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
