import React from "react";
import { Form } from "antd";

import { formSectionsController, vendors } from "../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../ConfigurableRow";
import { novartisSectionsController } from "./novartisSectionsController";
import { patientFields } from "../../components/common/fieldNames";
import { patientFields as caregiverFields } from "./form/fieldNames";
import { novartisEmailRules } from "./form/formSections/Patient";
import { calculateAge } from "../../utils";

export const cosentyxController = () => {
  const patientFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.NOVARTIS_COPAY)().patient;
  const novartisController = novartisSectionsController();
  const form = Form.useFormInstance();
  const patientDob = Form.useWatch(patientFields.dob, form);
  React.useEffect(() => {
    form.validateFields();
  }, [patientDob]);
  const careGiverGender = Form.useWatch(caregiverFields.caregiverGender, form);

  const getCommonCosentyxRows = () => (
    <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
      {[patientFormComponents.biologicalMedication({ required: true })]}
    </ConfigurableRow>
  );
  const getCaregiverCosentyxRows = () => (
    <>
      {patientFormComponents.caregiverDivider()}
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          patientFormComponents.caregiverFirstName({ required: true }),
          patientFormComponents.caregiverLastName({ required: true }),
          patientFormComponents.caregiverDob({ required: true })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_2_5_1_5}>
        {[
          patientFormComponents.caregiverGender({
            required: true,
            minWidth: 115,
            minWidthForAdditionalOptions: 208,
            showError: !careGiverGender
          })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          patientFormComponents.caregiverAddress({ required: true }),
          patientFormComponents.caregiverCity({ required: true }),
          patientFormComponents.caregiverState({ required: true })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          patientFormComponents.caregiverZipCode({ required: true }),
          patientFormComponents.caregiverEmail({ required: true, additionalRules: novartisEmailRules }),
          patientFormComponents.caregiverPhoneNumber({ required: true })
        ]}
      </ConfigurableRow>
    </>
  );

  return {
    getCommonCosentyxRows: getCommonCosentyxRows,
    getBelow18CosentyxRows: getCaregiverCosentyxRows,
    isCosentyx: novartisController.associatedDrug === novartisController.drugsList.COSENTYX,
    isPatientBelow18: calculateAge(patientDob) < 18
  };
};
