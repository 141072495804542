import React from "react";
import { Col, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";

import { insuranceFields } from "../../fieldNames";
import { FormItem } from "../../../../components";
import { onlyWhitespacesRule, zipInputRules } from "../../../../utils";
import { States } from "../../../../../../../constant/states";
import { SingleSelect } from "../../../../../../customComponent/CustomSelect";

const { Option } = Select;

export const MedicarePartDDetails = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={14}>
        <Col span={12}>
          <FormItem
            name={insuranceFields.partDPlanName}
            label={t("application_form_editor.pfizer.insurance.labels.part_d_plan_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.insurance.placeholders.part_d_plan_name")} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name={insuranceFields.partDAddress}
            label={t("application_form_editor.pfizer.insurance.labels.part_d_address")}
            rules={[onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.insurance.placeholders.part_d_address")} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={8}>
          <FormItem
            name={insuranceFields.partDCity}
            label={t("application_form_editor.pfizer.insurance.labels.city")}
            rules={[onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.insurance.placeholders.city")} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name={insuranceFields.partDState}
            label={t("application_form_editor.pfizer.insurance.labels.state")}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              placeholder={t("application_form_editor.pfizer.insurance.placeholders.state")}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {Object.values(States).map((state, index) => (
                <Option key={index} value={state.code}>
                  {state.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[...zipInputRules, onlyWhitespacesRule]}
            name={insuranceFields.partDZip}
            label={t("application_form_editor.pfizer.insurance.labels.zip_code")}
          >
            <Input
              maxLength={5}
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.pfizer.insurance.placeholders.zip_code")}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};
