import React, { useState } from "react";
import styled from "styled-components";
import { Modal, Typography } from "antd";
import { TEXT1, WARNING_RED } from "../constant/colors";
import { history } from "../router/history";
import { sizes } from "../constant/styles";
import { withTranslation } from "react-i18next";

const { Text } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    background: ${WARNING_RED} !important;
  }
  .ant-modal-footer {
    .ant-btn-primary {
      background: ${WARNING_RED};
      border-color: ${WARNING_RED};
    }
  }
`;

const ErrorFallback = ({ t }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  return (
    <StyledModal
      closable={false}
      mask={false}
      cancelButtonProps={{ style: { display: "none" } }}
      title={t("error_fallback.title")}
      open={isModalVisible}
      onOk={() => {
        setIsModalVisible(false);
        history.goBack();
        window.setTimeout(() => {
          window.location.reload();
        }, 500);
      }}
      okText={t("error_fallback.ok_text")}
      onCacel={false}
    >
      <div
        style={{
          flexFlow: "column nowrap",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Text strong style={{ fontSize: sizes.medium_large, color: TEXT1 }}>
          {t("error_fallback.p1")}
        </Text>
        <Text style={{ fontSize: sizes.small, color: TEXT1 }}>{t("error_fallback.p2")}</Text>
      </div>
    </StyledModal>
  );
};

export default withTranslation()(ErrorFallback);
