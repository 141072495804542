import React, { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";

import { insuranceFields } from "../../fieldNames";
import { FormItem, InputPhone } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";
import { InsuranceTypeFormItem, insuranceTypes } from "./InsuranceTypeFormItem";
import { phoneNumberLengthRegEx } from "../../../../../../../utils/formValidation";
import { PolicyholderDetails } from "./PolicyholderDetails";

const PRESCRIPTION = "prescription";

export const PrescriptionInsuranceDetails = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const insuranceType = Form.useWatch(insuranceFields.prescriptionType, form);

  useEffect(() => {
    form.validateFields();
  }, [insuranceType]);

  return (
    <>
      <Row gutter={14}>
        <Col span={24}>
          <InsuranceTypeFormItem type={PRESCRIPTION} name={insuranceFields.prescriptionType} />
        </Col>
      </Row>
      {Object.values(insuranceTypes).includes(insuranceType) && insuranceType !== insuranceTypes.NONE && (
        <>
          <Row gutter={14}>
            <Col span={12}>
              <FormItem
                name={insuranceFields.prescriptionPayerName}
                label={t("application_form_editor.pfizer.insurance.labels.prescription_payer_name")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_payer_name")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={insuranceFields.prescriptionGroupId}
                label={t("application_form_editor.pfizer.insurance.labels.prescription_group_id")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={20}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_group_id")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={insuranceFields.prescriptionMemberId}
                label={t("application_form_editor.pfizer.insurance.labels.prescription_member_id")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={20}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_member_id")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label={t("application_form_editor.pfizer.insurance.labels.prescription_pcn")}
                rules={[{ required: true }, onlyWhitespacesRule]}
                name={insuranceFields.prescriptionPCN}
              >
                <Input
                  maxLength={10}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_pcn")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label={t("application_form_editor.pfizer.insurance.labels.prescription_bin")}
                name={insuranceFields.prescriptionBIN}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={6}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_bin")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                rules={[{ pattern: phoneNumberLengthRegEx, message: "" }, { required: true }]}
                name={insuranceFields.prescriptionPhoneNumber}
                label={t("application_form_editor.pfizer.insurance.labels.phone_number")}
              >
                <InputPhone placeholder={t("application_form_editor.pfizer.insurance.placeholders.phone_number")} />
              </FormItem>
            </Col>
          </Row>
          <PolicyholderDetails
            policyholderSameAsPatientField={insuranceFields.prescriptionPolicyholderSameAsPatient}
            policyholderNameField={insuranceFields.prescriptionPolicyholderName}
            policyholderRelationshipField={insuranceFields.prescriptionPolicyholderRelationship}
            policyholderDOBField={insuranceFields.prescriptionPolicyholderDOB}
          />
        </>
      )}
    </>
  );
};
