export const PAGE_SIZE = 25;
export const SAVED_LIST_PAGE_SIZE = 10;
export const maxNumberOfRecords = 10000;
export const selectedViewTypes = {
  "all-patients": { title: "AllPatients", description: "All Patients" },
  "visit-schedule": { title: "VisitSchedule", description: "Visit Schedule" },
  "pending-renewal": { title: "PendingRenewal", description: "Pending Renewal" },
  "high-priority": { title: "HighPriorities", description: "High Priority" },
  "saved-list": { title: "SavedList", description: "Saved List" },
  "all-applications": { title: "AllApplications", description: "All Applications" },
  "all-claims": { title: "AllClaims", description: "All Claims" }
};

export const selectedViews = {
  allPatients: "all-patients",
  visitSchedule: "visit-schedule",
  pendingRenewal: "pending-renewal",
  highPriority: "high-priority",
  topOpportunity: "top-opportunity",
  savedList: "saved-list",
  allApplications: "all-applications",
  calims: "claims",
  allClaims: "all-claims"
};
