import React from "react";
import { Tooltip } from "antd";
import { BLACK } from "../constant/colors";
import styled from "styled-components";
import { font12pxR, font12pxSB } from "./Typography";

const DarkTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    background: ${BLACK};
    border-radius: 8px;
    padding: 12px;
  }
`;

const TooltipTitle = styled.div`
  ${font12pxSB}
`;

const TooltipText = styled.div`
  ${font12pxR}
`;

export const TitledTooltip = ({ children, title, text, visible }) => (
  <DarkTooltip
    open={visible}
    placement="top"
    title={() => (
      <>
        {title && <TooltipTitle>{title}</TooltipTitle>}
        {text && <TooltipText>{text}</TooltipText>}
      </>
    )}
  >
    {children}
  </DarkTooltip>
);
