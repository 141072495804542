import React from "react";
import { InputNumber } from "antd";

import { GRAY_900 } from "../../../../constant/colors";
import { fontWeights, sizes } from "../../../../constant/styles";

export const InputDollar = (props) => (
  <InputNumber
    addonBefore={
      <div style={{ color: GRAY_900, fontWeight: fontWeights.regular, fontSize: sizes.medium_large }}>{"$"}</div>
    }
    formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
    min={0}
    max={100000000}
    controls={false}
    {...props}
  />
);
