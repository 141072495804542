import { AttachmentIcon } from "../../../../assets/svg/AttachmentIcon";
import { SidebarIcon } from "../components";

const getEgalePemfexySidebarItems = (t) => [
  {
    title: t("application_form_editor.eagle.patient.labels.sidebar"),
    href: "#enrollment-form-patient-information-anchor",
    key: "patient",
    validationFields: ["patient"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.eagle.insurance.labels.sidebar"),
    href: "#enrollment-form-insurance-information",
    key: "insurance",
    validationFields: ["insurance"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.eagle.provider.labels.sidebar"),
    href: "#enrollment-form-provider-information",
    key: "provider",
    validationFields: ["provider"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.eagle.practice.labels.sidebar"),
    href: "#enrollment-form-practice-information",
    key: "practice",
    validationFields: ["facility"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.eagle.clinical.labels.sidebar"),
    href: "#enrollment-form-clinical-information",
    key: "clinical",
    validationFields: ["clinical", "diagnosis"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.eagle.attachments.labels.header"),
    href: "#enrollment-form-attachments",
    key: "attachments",
    validationFields: [],
    icon: AttachmentIcon
  }
];

export default getEgalePemfexySidebarItems;
