import React from "react";
import { Select } from "antd";

import { SingleSelect } from "../../../../customComponent/CustomSelect";
import { Form } from "antd";
const { Option } = Select;

export const SelectItem = ({ name, label, rules, optionItems, placeholder, style, ...props }) => {
  return (
    <Form.Item name={name} label={label} rules={rules} style={style}>
      <SingleSelect placeholder={placeholder} {...props}>
        {optionItems?.map((optionItem, index) => {
          return (
            <Option key={optionItem.value + "_" + index} value={optionItem.value}>
              {optionItem.displayValue}
            </Option>
          );
        })}
      </SingleSelect>
    </Form.Item>
  );
};
