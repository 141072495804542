import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { Popover, Space, Typography } from "antd";

import { selectPatientDetails } from "../../../store/selector";
import { fontWeights, sizes } from "../../../constant/styles";
import { TEXT1 } from "../../../constant/colors";
import { MoreDetailsTextStyle } from "./styles";

const { Text } = Typography;
const primaryTextStyle = { fontSize: sizes.medium, color: TEXT1 };

const MAX_LENGTH = 2;

const PatientDiagnosesComponant = ({ patientDetails }) => {
  const { primaryDiagnosesForDisplay } = patientDetails || {};
  return (
    <>
      <Space size={2} direction="vertical">
        {primaryDiagnosesForDisplay.slice(0, MAX_LENGTH).map((item, index) => (
          <Text
            key={index}
            style={{
              ...primaryTextStyle,
              lineHeight: "21px",
              fontWeight: fontWeights.regular
            }}
          >
            {item}
          </Text>
        ))}
        {primaryDiagnosesForDisplay.length > MAX_LENGTH && (
          <Popover
            trigger={"click"}
            content={
              <Space size={2} direction="vertical" style={{ padding: "0px 12px" }}>
                {primaryDiagnosesForDisplay.map((item, index) => (
                  <Text
                    key={index}
                    style={{
                      ...primaryTextStyle,
                      lineHeight: "21px",
                      fontWeight: fontWeights.regular
                    }}
                  >
                    {item}
                  </Text>
                ))}
              </Space>
            }
          >
            <MoreDetailsTextStyle id="more_diagnoses">{`+${
              primaryDiagnosesForDisplay.length - MAX_LENGTH
            } more diagnoses`}</MoreDetailsTextStyle>
          </Popover>
        )}
      </Space>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

const PatientDiagnoses = compose(withTranslation(), connect(mapStateToProps))(PatientDiagnosesComponant);

export { PatientDiagnoses };
