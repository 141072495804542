import { LIGHT_BLUE_2, BLACK, LIGHT_BLUE_3, GRAY7 } from "../../constant/colors";

export const ActionsMenuStyle = `
    border-radius: 5px;
    padding: 0;
    width: 180px;
    .ant-dropdown-menu-item {
        padding: 8px 12px 8px 12px;
    }
    .ant-dropdown-menu-item:last-child {
        border-top: 1px solid ${GRAY7};
    }
    .ant-dropdown-menu-item:hover{
        background-color: ${LIGHT_BLUE_2};  
        
    }
    .ant-dropdown-menu-item:nth-child(1):hover svg rect{
            fill:${LIGHT_BLUE_2}
    }
    .ant-dropdown-menu-item {
        color: ${BLACK} ;
        font-size: 14px;
        font-weight: 400;

    }
    .ant-dropdown-menu-item {
        user-select: none;
    }
    .ant-dropdown-menu-item:hover span{
        color: ${LIGHT_BLUE_3};

    }
`;
