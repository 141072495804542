import React from "react";
import "./TailormedSpinner.less";

const TailormedSpinner = () => (
  <div className="dashboard-loader-container">
    <div className="dashboard-loader">
      <div className="dna">
        <section className="column">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </section>
        <section className="column">
          <span className="dash"></span>
          <span className="dash"></span>
          <span className="dash"></span>
          <span className="dash"></span>
          <span className="dash"></span>
          <span className="dash"></span>
          <span className="dash"></span>
          <span className="dash"></span>
        </section>
        <section className="column">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </section>
      </div>
    </div>
  </div>
);

export { TailormedSpinner };
