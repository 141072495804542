// Pollyfills for IE and edge
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "fast-text-encoding";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import "antd/dist/antd.less";
import moment from "moment-timezone";

import configureStore from "./store/store";
import { MainRouter } from "./router/MainRouter";
import { ThemeProvider } from "./component/UI/ThemeProvider";
import "./locales/i18n";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./component/ErrorFallback";

require("typeface-open-sans");

// All dates will be handled as UTC and not by the local tz
moment.tz.setDefault("Etc/UTC");

const reduxStore = configureStore(window.REDUX_INITIAL_DATA);

ReactDOM.render(
  <ThemeProvider>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ReduxProvider store={reduxStore}>
        <MainRouter />
      </ReduxProvider>
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById("root")
);
