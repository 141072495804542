import { SidebarIcon } from "../../../components";

const getPfizerCopayOralSidebarItems = (t) => [
  {
    title: t("application_form_editor.genentech.copay.eligibility_questionnaire.sidebar"),
    href: "#enrollment-form-eligibility_questionnaire",
    key: "eligibilityQuestionnaire",
    icon: SidebarIcon,
    validationFields: ["eligibilityQuestionnaire"]
  }
];

export default getPfizerCopayOralSidebarItems;
