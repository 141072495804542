import React from "react";
import { useTranslation } from "react-i18next";

import { Space, Typography } from "antd";
import { sizes, fontWeights } from "../../constant/styles";
import { GRAY8, TEXT1 } from "../../constant/colors";
import { lookbackPeriodUnits } from "../../constant/potentialSavings";

const { Text } = Typography;

const PatientReportBenefits = ({ assistanceProgram }) => {
  const { t } = useTranslation();
  const lookbackRelevanceTime = assistanceProgram.lookbackRelevanceTime;
  const lookbackRelevanceUnits = assistanceProgram.lookbackRelevanceUnits;

  const getLookbackPeriodText = (t) => {
    if (!lookbackRelevanceTime) {
      return `${t("patientPotentialSavings.no_lookback_period")}`;
    }

    return `${
      lookbackRelevanceUnits === lookbackPeriodUnits.months
        ? t("patientApplications.x_months_lookback", {
            count: lookbackRelevanceTime
          })
        : t("patientApplications.x_days_lookback", {
            count: lookbackRelevanceTime
          })
    }`;
  };

  return (
    <>
      <Space size={24} style={{ width: "100%" }}>
        <Space size={4}>
          <Text style={{ fontWeight: fontWeights.semibold, color: GRAY8 }}>
            {t("patientPotentialSavings.enrollment_expiration_date")}
          </Text>
          <Text>
            {assistanceProgram.applicationRenewalTimeType === 2 && (
              <Text>
                {t("assistance_programs.modal.application_renewal_time_type.time_to_renewal", {
                  applicationRenewalTimeInMonths: assistanceProgram.applicationRenewalTimeInMonths
                })}
              </Text>
            )}
            {assistanceProgram.applicationRenewalTimeType === 1 && (
              <Text>{t("assistance_programs.modal.application_renewal_time_type.never_expires")}</Text>
            )}
            {assistanceProgram.applicationRenewalTimeType === 3 && (
              <Text>{t("assistance_programs.modal.application_renewal_time_type.expired_end_of_the_year")}</Text>
            )}
          </Text>
        </Space>
        {assistanceProgram.offerExpiration && (
          <Space size={4}>
            <Text style={{ fontWeight: fontWeights.semibold, color: GRAY8 }}>
              {t("patientPotentialSavings.offer_expiration_date")}
            </Text>
            <Text>{assistanceProgram.offerExpiration ? assistanceProgram.offerExpiration : "--"}</Text>
          </Space>
        )}
        {assistanceProgram.maxNumberOfUses && (
          <Space size={4}>
            <Text style={{ fontWeight: fontWeights.semibold, color: GRAY8 }}>
              {t("patientPotentialSavings.maximum_number_of_uses")}
            </Text>
            <Text>{assistanceProgram.maxNumberOfUses ? assistanceProgram.maxNumberOfUses : "--"}</Text>
          </Space>
        )}
        {((lookbackRelevanceTime || lookbackRelevanceTime === 0) && (
          <Space size={4}>
            <Text style={{ fontWeight: fontWeights.semibold, color: GRAY8 }}>
              {t("patientPotentialSavings.lookback_period")}
            </Text>
            <Text>{getLookbackPeriodText(t)}</Text>
          </Space>
        )) ||
          null}
        {assistanceProgram.benefits && (
          <Space direction="vertical" size={4}>
            <Text style={{ fontWeight: fontWeights.semibold, color: GRAY8 }}>
              {t("patientPotentialSavings.program_details")}
            </Text>
            <ul style={{ listStyle: "inside", padding: "0" }}>
              {assistanceProgram.benefits &&
                assistanceProgram.benefits.map((benefit, index) => (
                  <li key={index} style={{ fontSize: sizes.medium, fontWeight: fontWeights.regular, color: TEXT1 }}>
                    {benefit.type}
                  </li>
                ))}
            </ul>
          </Space>
        )}
      </Space>
    </>
  );
};

export default PatientReportBenefits;
