import React from "react";
import { Typography } from "antd";
const { Text } = Typography;
import { useTranslation } from "react-i18next";

const AllClaimsPageCount = ({ range, total }) => {
  const { t } = useTranslation();
  return (
    <>
      <Text>
        {t("all_claims_pagination.all_claims_page_count", {
          range_start: range[0],
          range_end: range[1] ? range[1] : range[0],
          total: total,
          title: t("all_claims_pagination.title")
        })}
      </Text>
    </>
  );
};

export default AllClaimsPageCount;
