import styled from "styled-components";
import { Form } from "antd";

const CustomForm = styled(Form).attrs((props) => ({
  size: "large",
  layout: "vertical",
  ...props
}))`
  &.ant-form-vertical {
    &.ant-form-large .ant-form-item-label > label {
      height: auto;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
      &::before {
        display: none;
      }
      &::after {
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        content: "*";
      }
    }
  }
`;

export { CustomForm };
