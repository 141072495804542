import React from "react";

import { sizes } from "../../../constant/styles";
import { ExpressEnrollButton } from "../../sharedComponents/ExpressEnrollment";

const CompleteFormButton = ({ onClick, isLoading, buttonText, ...props }) => {
  return (
    <ExpressEnrollButton
      size="large"
      onClick={onClick}
      type="primary"
      style={{ fontSize: sizes.medium_large }}
      loading={isLoading}
      {...props}
    >
      {buttonText}
    </ExpressEnrollButton>
  );
};

export { CompleteFormButton };
