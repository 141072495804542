import React, { useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";

import ACTIONS from "../../../../../store/action";
import { MB } from "../../../../Uploader";
import {
  getApplicationFormByApplicationIdApi,
  signPdfManuallyApi,
  uploadApplicationFormAttachmentApi
} from "../../../../../api/api";
import SuccessMessage from "../../../../customComponent/customMessages/SuccessMessage";
import ErrorMessage from "../../../../customComponent/customMessages/ErrorMessage";
import { useSelectedApplication } from "../../../hooks";
import { getExpressEnrollmentMapping } from "../../../ApplicationFormEditor/configurations";
import { selectApplicationForm } from "../../../../../store/selector";
import { ExpressEnrollButton, NoAccessTooltip } from "./components";
import { pendoEEUtils } from "../../../../../utils/pendo/pendoEEUtils";

const physician = "physician";

const ApplicationFormUploader = ({ disabled, uploadApi }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const applicationForm = useSelector(selectApplicationForm);
  const application = useSelectedApplication();

  const {
    signatureStep: {
      uploadButtonText,
      fileTypesAccepted,
      pendoUploadSignAttachment,
      pendoUploadSignAttachmentActionTaken
    }
  } = getExpressEnrollmentMapping();

  const MAX_FILE_SIZE = 11 * MB;

  const trackPendoEventAfterUpload = (dispatch, application) =>
    pendoEEUtils(dispatch, application, pendoUploadSignAttachment, pendoUploadSignAttachmentActionTaken);

  const handleUploadResult = async (attachmentsUploadRes) => {
    const isSuccessful = !!attachmentsUploadRes?.data?.status;
    if (isSuccessful) {
      const applicationFormGetResponse = await getApplicationFormByApplicationIdApi(applicationForm.application);
      dispatch(ACTIONS.setApplicationForm(applicationFormGetResponse.data.applicationForm));
      trackPendoEventAfterUpload(dispatch, application);
      SuccessMessage(t("patientApplications.attachments.success.upload"));
    } else if (attachmentsUploadRes?.data?.errors[0]?.message) {
      const errorMessage = attachmentsUploadRes.data.errors[0].message;
      ErrorMessage(t("patientApplications.attachments.error.upload_server", { errorMessage }));
    } else {
      ErrorMessage(t("patientApplications.enroll.electronic_signatures.errors.upload_error"));
    }
  };

  const handleUploadAttachment = async (file) => {
    try {
      const fileName = applicationForm?.attachmentName.replace(".pdf", " - signed.pdf");
      const fileAttachmentName = fileName.replace("/", ".");
      setIsUploading(true);
      const attachmentsUploadRes = await uploadApi(file, fileAttachmentName);
      await handleUploadResult(attachmentsUploadRes);
    } catch (error) {
      ErrorMessage(t("patientApplications.enroll.electronic_signatures.errors.upload_error"));
    } finally {
      setIsUploading(false);
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    showUploadList: false,
    accept: fileTypesAccepted,
    beforeUpload: (_, fileList) => {
      fileList.map((file) => {
        if (file.size > MAX_FILE_SIZE) {
          const errorMessage = t("patientApplications.attachments.exceeds_file_size", { maxSize: MAX_FILE_SIZE / MB });
          ErrorMessage(errorMessage, { width: 500, textAlign: "left" });
          throw new Error(errorMessage);
        }
      });
    }
  };

  const UploadButtonText = ({ t }) => (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <CloudUploadOutlined style={{ fontSize: 20, paddingRight: 5 }} />
      {uploadButtonText(t)}
    </div>
  );

  return (
    <Upload customRequest={({ file }) => handleUploadAttachment(file)} disabled={disabled} {...uploadProps}>
      <NoAccessTooltip>
        <ExpressEnrollButton
          id="EE_manual_upload_button"
          style={{ height: 36, width: 182, alignSelf: "center" }}
          type="primary"
          disabled={disabled}
          loading={isUploading}
        >
          {!isUploading && <UploadButtonText t={t} />}
        </ExpressEnrollButton>
      </NoAccessTooltip>
    </Upload>
  );
};

const SignedFormUploader = ({ disabled }) => {
  const { patientId, applicationId } = useParams();
  const uploadForm = useCallback((file, fileAttachmentName) =>
    uploadApplicationFormAttachmentApi(file, patientId, applicationId, fileAttachmentName)
  );

  return <ApplicationFormUploader disabled={disabled} uploadApi={uploadForm} />;
};

const SignatureUploader = ({ disabled }) => {
  const { patientId } = useParams();

  const applicationForm = useSelector(selectApplicationForm);
  const uploadSignature = useCallback((file) =>
    signPdfManuallyApi({
      file,
      patientId,
      applicationFormId: applicationForm?.id,
      signerType: physician
    })
  );

  return <ApplicationFormUploader disabled={disabled} uploadApi={uploadSignature} />;
};

export { SignedFormUploader, SignatureUploader };
