import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { selectSettings } from "../store/selector";

export const parseValues = (setting) => {
  if (!setting) {
    return "";
  }
  const valueParser = {
    bool: (value) => value === "true",
    string: (value) => value,
    date: (date) => moment(date),
    json: (value) => JSON.parse(value),
    number: (float) => parseFloat(float)
  };
  return valueParser[setting.type](setting.value);
};

const useGetSetting = (settingList) => {
  const allSettings = useSelector(selectSettings);
  return settingList.map((key) => parseValues(allSettings[key]));
};

export { useGetSetting };
