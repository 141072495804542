import React from "react";
import { useTranslation } from "react-i18next";
import { Radio } from "antd";

import FormItem from "../../FormItem";
import { RadioButton } from "./components";

export const GenderFormItem = ({
  label,
  radioStyle,
  formItemName,
  showError,
  width,
  thirdOption,
  showThirdOption = true,
  additionalOptions,
  widthForAdditionalOptions,
  minWidth,
  minWidthForAdditionalOptions,
  required = true
}) => {
  const { t } = useTranslation();
  return (
    <FormItem rules={[{ required: required }]} name={formItemName} label={label}>
      <Radio.Group style={radioStyle}>
        <RadioButton showError={showError} value="female" style={{ width: width || 115, minWidth: minWidth }}>
          {t("application_form_editor.genentech.copay.patient.female")}
        </RadioButton>
        <RadioButton showError={showError} value="male" style={{ width: width || 115, minWidth: minWidth }}>
          {t("application_form_editor.genentech.copay.patient.male")}
        </RadioButton>
        {showThirdOption && (
          <RadioButton
            showError={showError}
            value="other"
            style={{ width: widthForAdditionalOptions || width || null }}
          >
            {thirdOption || t("application_form_editor.genentech.copay.patient.prefer_no_answer")}
          </RadioButton>
        )}
        {!!additionalOptions &&
          additionalOptions.map((option, index) => (
            <RadioButton
              key={index}
              showError={showError}
              value={option.value}
              style={{
                width: widthForAdditionalOptions || width || null,
                minWidth: minWidthForAdditionalOptions
              }}
            >
              {option.label}
            </RadioButton>
          ))}
      </Radio.Group>
    </FormItem>
  );
};
