import React from "react";

const FinancialIcon = ({ style = {} }) => {
  return (
    <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_722_28084)">
        <path
          style={style}
          d="M5.5 3.5V0.5H10.5V3.5"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          style={style}
          d="M1.5 3.5H14.5C14.7652 3.5 15.0196 3.60536 15.2071 3.79289C15.3946 3.98043 15.5 4.23478 15.5 4.5V9.5H0.5V4.5C0.5 4.23478 0.605357 3.98043 0.792893 3.79289C0.98043 3.60536 1.23478 3.5 1.5 3.5V3.5Z"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          style={style}
          d="M14.5 11.5V13.5C14.5 13.7652 14.3946 14.0196 14.2071 14.2071C14.0196 14.3946 13.7652 14.5 13.5 14.5H2.5C2.23478 14.5 1.98043 14.3946 1.79289 14.2071C1.60536 14.0196 1.5 13.7652 1.5 13.5V11.5"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_722_28084">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FinancialIcon;
