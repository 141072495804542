import { formSections } from "./sections";

export const novartisCopayi18nRoutes = {
  [formSections.PATIENT_SECTION]: {
    gender_header: "application_form_editor.novartis.copay.patient.labels.gender.header",
    gender_option_3: "application_form_editor.novartis.copay.patient.labels.gender.option_3",
    gender_option_4: "application_form_editor.novartis.copay.patient.labels.gender.option_4",
    biologicalMedication: "application_form_editor.novartis.copay.patient.labels.biological_medication",
    caregiverDivider: "application_form_editor.novartis.copay.patient.labels.caregiver_divider"
  },
  [formSections.CONDITION_SECTION]: {
    condition: "application_form_editor.novartis.copay.condition.labels.condition"
  },
  [formSections.ELIGIBILITYANDATTESTATION_SECTION]: {
    hipaaAuthConsentText:
      "application_form_editor.novartis.copay.eligibilityAndAttestation.labels.hipaa_auth_consent_text",
    cosentyxConsent: "application_form_editor.novartis.copay.eligibilityAndAttestation.labels.cosentyx_consent",
    hipaaAuthConsentError:
      "application_form_editor.novartis.copay.eligibilityAndAttestation.labels.hipaa_auth_consent_error"
  },
  [formSections.SUPPORTOPTIONS_SECTION]: {
    supportOption: "application_form_editor.novartis.copay.supportOptions.labels.support_option"
  }
};
