import { CloseOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import styled from "styled-components";
import { ReactComponent as IconAddPatient } from "../../assets/svg/icon-add-patient.svg";
import { ReactComponent as IconArrowDown } from "../../assets/svg/icon-arrow-down.svg";
import { GRAY_300 } from "../../constant/colors";
import { PrimaryButton } from "../customComponent/Button";
import DiscardChangesPopup from "../Patient/PatientCoverageDetails/DiscardChangesPopup";
import NewPatientForm from "./NewPatientForm";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }
`;

const AddNewPatientButton = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [discardChangesPopupVisible, setDiscardChangesPopupVisible] = useState(false);
  const [destroyPopoverOnClose, setDestroyPopoverOnClose] = useState(false);
  const [discardChangesFunc, setDiscardChangesFunc] = useState();

  useEffect(() => {
    if (!discardChangesPopupVisible) {
      setDiscardChangesFunc(() => discardChanges);
    }
  }, [discardChangesPopupVisible]);

  const discardChanges = () => {
    setDiscardChangesPopupVisible(false);
    closePopover();
  };

  const defineDiscardFunc = (func) => {
    setDiscardChangesFunc(() => func);
  };

  const onClickCancel = () => {
    setDiscardChangesPopupVisible(false);
  };

  const setDiscardChangesPopupVisibility = (visibility) => {
    setDiscardChangesPopupVisible(visibility);
  };

  const closePopover = () => {
    setDestroyPopoverOnClose(true);
    setOpen(false);
  };

  return (
    <>
      <StyledModal
        id="create_new_patient_popover"
        open={open}
        destroyOnClose={destroyPopoverOnClose}
        mask={false}
        zIndex={900}
        style={{ position: "absolute", right: 50, bottom: 90, top: "auto" }}
        bodyStyle={{ borderRadius: 12, border: `1px solid ${GRAY_300}`, padding: 0 }}
        width={833}
        footer={null}
        closable={false}
        closeIcon={<CloseOutlined id="close_create_new_patient_popover" />}
      >
        <NewPatientForm
          closePopover={closePopover}
          setDiscardChangesPopupVisibility={setDiscardChangesPopupVisibility}
          defineDiscardFunc={defineDiscardFunc}
        />
      </StyledModal>
      <Tooltip
        placement="left"
        title={open ? "" : t("new_patient.create_patient_tooltip")}
        overlayStyle={{ position: "fixed" }}
      >
        <PrimaryButton
          id="add_new_patient_button"
          shape="circle"
          type="primary"
          style={{
            position: "fixed",
            right: 40,
            bottom: 40,
            zIndex: 1001,
            width: "3.5rem",
            height: "3.5rem",
            borderRadius: 50,
            display: !open ? "" : "none"
          }}
          onClick={() => {
            setDestroyPopoverOnClose(false);
            setOpen(true);
          }}
          icon={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconAddPatient />
            </div>
          }
        />
      </Tooltip>
      <Tooltip placement="left" title={open ? t("new_patient.minimize") : ""}>
        <PrimaryButton
          id="minimize_new_patient_button"
          shape="circle"
          type="primary"
          style={{
            position: "fixed",
            right: 40,
            bottom: 40,
            zIndex: 1001,
            width: "3.5rem",
            height: "3.5rem",
            borderRadius: 50,
            display: open ? "" : "none"
          }}
          onClick={() => {
            setDestroyPopoverOnClose(false);
            setOpen(false);
          }}
          icon={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <IconArrowDown />
            </div>
          }
        />
      </Tooltip>
      <DiscardChangesPopup
        visible={discardChangesPopupVisible}
        onClickDiscard={() => discardChangesFunc()}
        onClickCancel={onClickCancel}
        popupTitle={t("managePatientDetails.cancel_modal_title")}
      />
    </>
  );
};

export default withRouter(AddNewPatientButton);
