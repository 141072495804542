export const OTHER = "Other";
export const STATE = "state";
export const EVRYSDI = "Evrysdi";
export const ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME = "eligibilityQuestionnaire";

export const ProviderTypes = Object.freeze({ Physician: "Physician", Facility: "Facility" });
export const PhoneNumberType = Object.freeze({ HOME: "home", MOBILE: "mobile" });

export const PEMFEXY_DEFAULT_NDC = "42367-0531-33";
export const HEADER_SUBMIT_BUTTON_TYPES = Object.freeze({
  COMPLETE_FORM: "Complete Form",
  SUBMIT_FORM: "Submit Form"
});
