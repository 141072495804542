import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Affix, Anchor, Form } from "antd";
import styled from "styled-components";
import _ from "lodash";

import PersonalIcon from "../../assets/svg/PersonalIcon";
import FinancialIcon from "../../assets/svg/FinancialIcon";
import MedicalIcon from "../../assets/svg/MedicalIcon";
import TreatmentIcon from "../../assets/svg/TreatmentIcon";
import { CoveragesIcon } from "../../assets/svg/CoveragesIcon";
import { font14pxR, font14pxSB } from "../Typography";
import { ManagePatientDetailsContext } from "./context";

const { Link } = Anchor;

const StyledSideBarCollapse = styled.div`
  margin: 16px;
  background: white;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
`;

const StyledAnchor = styled(Anchor)`
  ${font14pxR}
  .ant-anchor {
    padding: 8px 0;
  }
  .ant-anchor-ink {
    display: none;
  }
  .ant-anchor-link {
    height: 44px;
    padding: 8px;
  }
  .ant-anchor-link .ant-anchor-link {
    margin-left: 18px;
    padding: 0 0 0 13px;
    display: flex;
    align-items: center;
    border-left: 1px solid #f2f4f7;
  }
  .ant-anchor-link .ant-anchor-link.ant-anchor-link.ant-anchor-link-active {
    color: #1570ef;
    border-left: 3px solid #1570ef;
  }
  > div > .ant-anchor-link {
    transition: height 0.25s ease-in;
    overflow: hidden;
    padding: 0;
    .arrow {
      position: absolute;
      top: 19px;
      right: 20px;
      border: solid black;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
    }
    > .ant-anchor-link-title-active {
      .arrow {
        transform: rotate(-135deg);
      }
      background: #eff8ff;
      color: #1570ef;
      ${font14pxSB}
    }
    > .ant-anchor-link-title {
      display: flex;
      align-items: center;
      height: 44px;
      border-radius: 8px;
      margin: 0 8px;
    }
  }
  .section-selected {
    height: fit-content;
  }
`;

const RootWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: #1570ef;
    margin: 0 13px;
  }
`;

const ManagePatientDetailsTabs = () => {
  const { t } = useTranslation();
  const [currentAnchor, setCurrentAnchor] = useState();
  const [currentSection, setCurrentSection] = useState();
  const form = Form.useFormInstance();
  const { coveragesData } = useContext(ManagePatientDetailsContext);

  const coverageTypes = !form.getFieldValue(["coverages", "isPatientUninsured"])
    ? coveragesData?.map((coverage) => coverage.coverageType)
    : null;

  const sections = [
    {
      key: "PERSONAL",
      icon: PersonalIcon,
      value: "#manage-patient-details-personal",
      anchors: [
        { key: "patientName", title: "Patient Name", value: "#manage-patient-details-personal" },
        { key: "contactDetails", title: "Contact Details", value: "#manage-patient-contact-details" },
        { key: "generalPersonal", title: "General", value: "#manage-patient-details-general" }
      ]
    },
    {
      key: "FINANCIAL",
      icon: FinancialIcon,
      value: "#manage-patient-details-financial",
      anchors: [
        { key: "generalFinancial", title: "General", value: "#manage-patient-details-financial-general" },
        { key: "income", title: "Income", value: "#manage-patient-details-financial-income" }
      ]
    },
    {
      key: "COVERAGE",
      icon: CoveragesIcon,
      value: "#manage-patient-details-coverages",
      anchors: coverageTypes?.map((coverageType) => {
        return {
          key: coverageType,
          title: _.startCase(coverageType),
          value: `#manage-patient-details-coverages-${coverageType}`
        };
      })
    },
    {
      key: "MEDICAL",
      icon: MedicalIcon,
      value: "#manage-patient-details-diagnosis",
      anchors: [
        { key: "diagnosis", title: "Diagnosis", value: "#manage-patient-details-diagnosis" },
        {
          key: "medicalInformation",
          title: "Medical Information",
          value: "#manage-patient-details-medical-information"
        }
      ]
    },
    { key: "MEDICATIONS", icon: TreatmentIcon, value: "#manage-patient-details-medications", anchors: [] }
  ];
  useEffect(() => {
    const selectedSection = sections.find(({ anchors }) => {
      return anchors?.find(({ value }) => value.indexOf(currentAnchor) > -1);
    });
    setCurrentSection(selectedSection);
  }, [currentAnchor]);

  return (
    <Affix offsetTop={130}>
      <StyledSideBarCollapse>
        <StyledAnchor
          onChange={(value) => setCurrentAnchor(value)}
          offsetTop={196}
          showInkInFixed={false}
          affix={false}
        >
          {sections &&
            sections.length > 0 &&
            sections?.map((section) => {
              const SectionIcon = section.icon;
              return (
                <Link
                  key={section.key}
                  href={section.value}
                  className={currentSection?.key === section.key ? "section-selected" : ""}
                  title={
                    <RootWrapper>
                      <SectionIcon />
                      {t("managePatientDetails." + section.key.toLocaleLowerCase())}
                      <div className="arrow" />
                    </RootWrapper>
                  }
                >
                  {section.anchors?.length > 0 &&
                    section.anchors?.map((anchor) => (
                      <Link href={anchor.value} key={anchor.key} title={anchor.title} />
                    ))}
                </Link>
              );
            })}
        </StyledAnchor>
      </StyledSideBarCollapse>
    </Affix>
  );
};

export default ManagePatientDetailsTabs;
