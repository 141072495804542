import React from "react";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow } from "../../../../ConfigurableRow";

export default function GenentechFreeDrugSubmitAttestation() {
  const submitAttestationFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.GENENTECH_PAP)()
    .submitAttestation;

  return (
    <SectionPart
      sectionId="submitAttestation"
      headerTranslationKey="application_form_editor.genentech.free_drug.submit_attestation.labels.header"
    >
      <ConfigurableRow>{[submitAttestationFormComponents.hipaaAuthConsentText()]}</ConfigurableRow>
      <ConfigurableRow>{[submitAttestationFormComponents.hipaaAuthConsent({ required: true })]}</ConfigurableRow>
    </SectionPart>
  );
}
