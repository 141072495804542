import { AttachmentIcon } from "../../../../assets/svg/AttachmentIcon";
import { SidebarIcon } from "../components";

const getPfizerFreeDrugSidebarItems = (t) => [
  {
    title: t("application_form_editor.pfizer.patient.labels.sidebar"),
    href: "#enrollment-form-patient-information-anchor",
    key: "patient",
    validationFields: ["patient"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.pfizer.prescription.labels.sidebar"),
    href: "#enrollment-form-prescription-information",
    key: "prescription",
    validationFields: ["prescription"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.pfizer.financial.labels.header"),
    href: "#enrollment-form-financial-information",
    key: "financial",
    validationFields: ["financial"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.pfizer.diagnosis.labels.sidebar"),
    href: "#enrollment-form-diagnosis-information",
    key: "diagnosis",
    validationFields: ["diagnosis"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.pfizer.insurance.labels.sidebar"),
    href: "#enrollment-form-insurance-information",
    key: "insurance",
    validationFields: ["insurance"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.pfizer.hcp.labels.sidebar"),
    href: "#enrollment-form-hcp-information",
    key: "hcp",
    validationFields: ["administeringProvider", "shipping", "provider", "facility"],
    icon: SidebarIcon
  },
  {
    title: t("application_form_editor.pfizer.attachments.labels.header"),
    href: "#enrollment-form-attachments",
    key: "attachments",
    validationFields: [],
    icon: AttachmentIcon
  }
];

export default getPfizerFreeDrugSidebarItems;
