import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Col, Row, Space, Typography } from "antd";

import { PopupStyledModal } from "../customComponentNewDesign/CustomPopupModal";
import { OutlinedButton, PrimaryButton } from "../customComponent/Button";
import { SingleSelect } from "../customComponentNewDesign/CustomSelect";
import { CustomInput } from "../customComponentNewDesign/CustomInput";
import { CustomDatePicker } from "../customComponentNewDesign/customDatePicker";
import { CustomForm as Form } from "../customComponentNewDesign/CustomForm";
import { DATE_FORMAT } from "../../utils/date";
import { ERROR_25, ERROR_700 } from "../../constant/colors";
import { PLAN_MARKETS_OPTIONS } from "../../constant/planMarkets";
import { ReactComponent as AlertCircle } from "../../assets/svg/alert-circle.svg";
import { ReactComponent as WarningMark } from "../../assets/svg/warning-mark.svg";
import CustomSelectAutocomplete from "../customComponentNewDesign/CustomSelectAutocomplete";

const { Option } = SingleSelect;
const { Text } = Typography;

const MEMBER_ID = "memberId";
const PAYER_NAME = "payerName";

const ManullyPayerAlert = styled(Space)`
  color: #667085;
  align-items: flex-start;
  padding-bottom: 24px;
`;
const ManullyPayerAlertText = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

const AddNewCoveragePopup = ({ showPopupRef, onCoverageAdded, coverages, validEbvPayers }) => {
  const [popupShown, setPopupShown] = useState(false);
  const [isConflict, setIsConflict] = useState(false);
  const [isUnknown, setIsUnknown] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const showPopup = () => setPopupShown(true);

  const handleCreateNewCoverageEvent = (values) => {
    const { payerName } = values;
    if (isUnknown) {
      onCoverageAdded({
        ...values,
        customPayer: payerName,
        isUnknown: isUnknown
      });
    } else {
      onCoverageAdded({ ...values });
    }
    setPopupShown(false);
    form.resetFields();
    setIsUnknown(false);
  };

  const onClose = () => {
    setIsUnknown(false);
    setIsConflict(false);
    setPopupShown(false);
    form.resetFields();
  };

  showPopupRef.current = showPopup;

  return (
    <PopupStyledModal
      open={popupShown}
      title={t("patientClassicCoverageDetailsOverview.add_new_coverage")}
      onCancel={() => onClose()}
      footer={[
        <OutlinedButton key="cancelButton" onClick={() => onClose()}>
          {t("managePatientDetails.cancel")}
        </OutlinedButton>,
        <PrimaryButton form="addCoverageForm" key="submit" htmlType="submit" disabled={isConflict}>
          {t("patientClassicCoverageDetailsOverview.add_coverage")}
        </PrimaryButton>
      ]}
    >
      <Form
        form={form}
        id="addCoverageForm"
        onFinish={(values) => {
          handleCreateNewCoverageEvent(values);
        }}
        onFieldsChange={(changedFields, allFields) => {
          const memberIdField = allFields.filter(({ name }) => name[0] === MEMBER_ID)[0];
          const payerField = allFields.filter(({ name }) => name[0] === PAYER_NAME)[0];
          const { name } = changedFields[0];
          const changedField = name[0];
          if (changedField === MEMBER_ID || changedField === PAYER_NAME) {
            const memberIds = coverages ? coverages.map(({ memberId }) => memberId) : [];
            const payers = coverages ? coverages.map(({ payerName }) => payerName?.toLowerCase()) : [];

            const hasMemberIdsConflict = memberIds.includes(memberIdField.value);

            const hasPayerConflict = payers.includes(payerField?.value?.toLowerCase());

            setIsConflict(hasMemberIdsConflict && hasPayerConflict);
          }
        }}
      >
        {isConflict && (
          <Row
            style={{
              backgroundColor: ERROR_25,
              height: "72px",
              padding: "16px",
              gap: "12px",
              border: "1px solid #FDA29B",
              borderRadius: "8px",
              marginBottom: "16px",
              marginTop: "24px"
            }}
          >
            <div style={{ width: "16.67px", height: "16.67px" }}>
              <AlertCircle style={{ width: "100%", height: "100%" }} />
            </div>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                color: ERROR_700,
                width: "360px",
                height: "40px"
              }}
            >
              {t("patientClassicCoverageDetailsOverview.coverage_with_the_same_payer_name_and_member_ID")}
            </Text>
          </Row>
        )}
        <Row>
          <Form.Item
            label={t("patientClassicCoverageDetailsOverview.plan_market")}
            name="planMarket"
            rules={[{ required: true, message: t("patientClassicCoverageDetailsOverview.choose_plan_market") }]}
          >
            <SingleSelect
              required={true}
              showArrow={true}
              showSearch={true}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder={t("patientClassicCoverageDetailsOverview.choose_plan_market")}
            >
              {PLAN_MARKETS_OPTIONS?.map((planMarket) => (
                <Option key={planMarket.key} value={planMarket.value} id={`pop_up_${planMarket.id}`}>
                  {planMarket.translationText}
                </Option>
              ))}
            </SingleSelect>
          </Form.Item>
        </Row>
        <Row style={{ justifyContent: "space-between" }}>
          <Col>
            <Form.Item label={t("patientClassicCoverageDetailsOverview.group_id")} name="group">
              <CustomInput width="228px" placeholder="Ex. 435323"></CustomInput>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t("patientClassicCoverageDetailsOverview.member_id")} name="memberId">
              <CustomInput width="228px" placeholder="Ex. 8PW5RY9"></CustomInput>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item label={t("patientClassicCoverageDetailsOverview.payer")} name="payerName">
            <CustomSelectAutocomplete
              placeholder={t("patientClassicCoverageDetailsOverview.choose_payer")}
              setIsCustomPayer={setIsUnknown}
            >
              {validEbvPayers.map((payer, index) => (
                <Option key={index} value={payer.name}>
                  {payer.name}
                </Option>
              ))}
            </CustomSelectAutocomplete>
          </Form.Item>
        </Row>
        <Row>
          {isUnknown && (
            <ManullyPayerAlert>
              <WarningMark />
              <ManullyPayerAlertText>{t("new_patient.other_payer_selected_message")}</ManullyPayerAlertText>
            </ManullyPayerAlert>
          )}
        </Row>
        <Row style={{ justifyContent: "space-between" }}>
          <Col>
            <Form.Item label={t("patientCoverageDetails.plan_name")} name="planName">
              <CustomInput width="228px" placeholder="Enter plan name"></CustomInput>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={t("new_patient.effective_date")} name="activeDate">
              <CustomDatePicker placeholder={DATE_FORMAT} format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </PopupStyledModal>
  );
};

export default AddNewCoveragePopup;
