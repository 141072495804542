import moment from "moment";

const dateParser = (rawDate) => ({
  value: rawDate ? moment(rawDate) : rawDate
});

const calculateAge = (dob) => moment().diff(moment(dob, "YYYY-MM-DD"), "years");

const getNamesFromFullName = (fullName) => {
  if (!fullName) {
    return {};
  }
  let firstName;
  let middleName;
  let lastName;
  if (fullName.includes(",")) {
    // Case where the name is in the format of "lastName, firstName"
    const nameParts = fullName.split(",");

    lastName = nameParts[0].trim();
    firstName = nameParts[1].trim();
  } else {
    const nameParts = fullName.split(" ");
    // Case where the name is in the format of "firstName"
    if (nameParts?.length === 1) {
      firstName = nameParts[0];
    } else if (nameParts?.length === 2) {
      // Case where the name is in the format of "firstName lastName"
      firstName = nameParts[0];
      lastName = nameParts[1];
    } else if (nameParts?.length === 3) {
      // Case where the name is in the format of "firstName middleName lastName"
      firstName = nameParts[0];
      middleName = nameParts[1];
      lastName = nameParts[2];
    }
  }

  return { firstName, middleName, lastName };
};

export { dateParser, getNamesFromFullName, calculateAge };
