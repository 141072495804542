import { formSections } from "./sections";

export const genentechPAPi18nRoutes = {
  [formSections.PATIENT_SECTION]: {
    firstName: "application_form_editor.genentech.free_drug.patient.labels.first_name",
    firstNamePlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.first_name",
    lastName: "application_form_editor.genentech.free_drug.patient.labels.last_name",
    lastNamePlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.last_name",
    dob: "application_form_editor.genentech.free_drug.patient.labels.dob",
    gender: "application_form_editor.genentech.free_drug.patient.labels.gender",
    address: "application_form_editor.genentech.free_drug.patient.labels.street_address",
    addressPlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.street_address",
    city: "application_form_editor.genentech.free_drug.patient.labels.city",
    cityPlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.city",
    zipCode: "application_form_editor.genentech.free_drug.patient.labels.zip_code",
    zipCodePlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.zip_code",
    state: "application_form_editor.genentech.free_drug.patient.labels.state",
    statePlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.state",
    phoneNumber: "application_form_editor.genentech.free_drug.patient.labels.phone_number",
    phoneNumberPlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.phone_number",
    additionalPhoneNumber: "application_form_editor.genentech.free_drug.patient.labels.additional_phone_number",
    additionalPhoneNumberPlaceholder:
      "application_form_editor.genentech.free_drug.patient.placeholders.additional_phone_number",
    email: "application_form_editor.genentech.free_drug.patient.labels.email",
    emailPlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.email",
    preferredLanguage: "application_form_editor.genentech.free_drug.patient.labels.preferred_language",
    preferredLanguagePlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.preferred_language",
    alternativeContact: "application_form_editor.genentech.free_drug.patient.labels.alternative_contact",
    divider: "application_form_editor.genentech.free_drug.patient.labels.alternative_patient_info",
    relationToPatient: "application_form_editor.genentech.free_drug.patient.labels.relation_to_patient",
    relationToPatientPlaceholder: "application_form_editor.genentech.free_drug.patient.placeholders.relation_to_patient"
  },
  [formSections.INSURANCE_SECTION]: {
    primarySubscriberId: "application_form_editor.genentech.free_drug.insurance.labels.primary_subscriber_id",
    secondarySubscriberId: "application_form_editor.genentech.free_drug.insurance.labels.secondary_subscriber_id",
    subscriberIdPlaceholder: "application_form_editor.genentech.free_drug.insurance.placeholders.subscriber_id",
    groupId: "application_form_editor.genentech.free_drug.insurance.labels.group_number",
    groupIdPlaceholder: "application_form_editor.genentech.free_drug.insurance.placeholders.group_number",
    policyholderSameAsPatient: "application_form_editor.genentech.free_drug.insurance.labels.relation_to_patient",
    policyholderSameAsPatientPlaceholder:
      "application_form_editor.genentech.free_drug.insurance.placeholders.relation_to_patient",
    subscriberFirstName: "application_form_editor.genentech.free_drug.insurance.labels.subscriber_first_name",
    subscriberFirstNamePlaceholder:
      "application_form_editor.genentech.free_drug.insurance.placeholders.subscriber_first_name",
    subscriberLastName: "application_form_editor.genentech.free_drug.insurance.labels.subscriber_last_name",
    subscriberLastNamePlaceholder:
      "application_form_editor.genentech.free_drug.insurance.placeholders.subscriber_last_name",
    subscriberDOB: "application_form_editor.genentech.free_drug.insurance.labels.subscriber_date_of_birth",
    payerPhone: "application_form_editor.genentech.free_drug.insurance.labels.payer_phone",
    payerPhonePlaceholder: "application_form_editor.genentech.free_drug.insurance.placeholders.payer_phone",
    isPrescriptionPlan: "application_form_editor.genentech.free_drug.insurance.labels.prescription_plan"
  },
  [formSections.PRACTICE_SECTION]: {
    facilityName: "application_form_editor.genentech.free_drug.practice.labels.facility_name",
    facilityNamePlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.facility_name",
    facilityInputPlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.facility_input",
    address: "application_form_editor.genentech.free_drug.practice.labels.address",
    addressPlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.address",
    city: "application_form_editor.genentech.free_drug.practice.labels.city",
    cityPlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.city",
    state: "application_form_editor.genentech.free_drug.practice.labels.state",
    statePlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.state",
    zip: "application_form_editor.genentech.free_drug.practice.labels.zip",
    zipPlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.zip",
    facilityNpi: "application_form_editor.genentech.free_drug.practice.labels.facility_npi",
    facilityNpiPlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.facility_npi",
    facilityTaxId: "application_form_editor.genentech.free_drug.practice.labels.facility_tax_id",
    facilityTaxIdPlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.facility_tax_id",
    contactFirstName: "application_form_editor.genentech.free_drug.practice.labels.contact_first_name",
    contactFirstNamePlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.contact_first_name",
    contactLastName: "application_form_editor.genentech.free_drug.practice.labels.contact_last_name",
    contactLastNamePlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.contact_last_name",
    contactPhone: "application_form_editor.genentech.free_drug.practice.labels.contact_phone",
    contactPhonePlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.contact_phone",
    contactFax: "application_form_editor.genentech.free_drug.practice.labels.contact_fax",
    contactFaxPlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.contact_fax",
    practiceDivider: "application_form_editor.genentech.free_drug.practice.labels.practice_divider"
  },
  [formSections.PRESCRIPTION_SECTION]: {
    product: "application_form_editor.genentech.free_drug.prescription.labels.product",
    prescriptionType: "application_form_editor.genentech.free_drug.prescription.labels.prescription_type",
    prescriptionOption: "application_form_editor.genentech.free_drug.prescription.labels.prescription_option",
    dosage: "application_form_editor.genentech.free_drug.prescription.labels.dosage",
    dosageUnit: "application_form_editor.genentech.free_drug.prescription.labels.dosage_unit",
    dispenseQuantity: "application_form_editor.genentech.free_drug.prescription.labels.dispense_quantity",
    dispenseUnit: "application_form_editor.genentech.free_drug.prescription.labels.dispense_unit",
    frequencyOfAdministrationTiming:
      "application_form_editor.genentech.free_drug.prescription.labels.frequency_of_administration_timing",
    frequency: "application_form_editor.genentech.free_drug.prescription.labels.frequency",
    refills: "application_form_editor.genentech.free_drug.prescription.labels.refills",
    supply_duration: "application_form_editor.genentech.free_drug.prescription.labels.supply_duration",
    supply_duration_value: "application_form_editor.genentech.free_drug.prescription.labels.supply_duration_value",
    sig: "application_form_editor.genentech.free_drug.prescription.labels.sig",
    prescription_type_placeholder:
      "application_form_editor.genentech.free_drug.prescription.placeholders.prescription_type",
    prescription_option_placeholder:
      "application_form_editor.genentech.free_drug.prescription.placeholders.prescription_option",
    information_notice: "application_form_editor.genentech.free_drug.prescription.labels.information_notice",
    information_replacement_shipment:
      "application_form_editor.genentech.free_drug.prescription.labels.information_replacement_shipment"
  },
  [formSections.SUBMIT_ATTESTATION_SECTION]: {
    hipaaAuthConsentText:
      "application_form_editor.genentech.free_drug.submit_attestation.labels.hipaa_auth_consent_text",
    hipaaAuthConsent: "application_form_editor.genentech.free_drug.submit_attestation.labels.hipaa_auth_consent",
    hipaaAuthConsentError: "application_form_editor.genentech.free_drug.submit_attestation.errors.hipaa_auth_consent"
  },
  [formSections.PATIENT_CONSENT_SECTION]: {
    patientResourcesConsentText:
      "application_form_editor.genentech.free_drug.patient_consent.labels.patient_resources_consent_text",
    patientResourcesConsentTextEvrysdi:
      "application_form_editor.genentech.free_drug.patient_consent.labels.patient_resources_consent_text_evrysdi",
    patientResourcesConsentTextBelow:
      "application_form_editor.genentech.free_drug.patient_consent.labels.patient_resources_consent_text_below",
    patientResourcesConsent:
      "application_form_editor.genentech.free_drug.patient_consent.labels.patient_resources_consent",
    patientResourcesConsentEvrysdi:
      "application_form_editor.genentech.free_drug.patient_consent.labels.patient_resources_consent_evrysdi",
    TCPAConsentDivider: "application_form_editor.genentech.free_drug.patient_consent.labels.tcpa_consent_divider",
    TCPAConsent: "application_form_editor.genentech.free_drug.patient_consent.labels.tcpa_consent",
    authorizationRequiredDivider:
      "application_form_editor.genentech.free_drug.patient_consent.labels.authorization_required_divider",
    authorizationRequiredText:
      "application_form_editor.genentech.free_drug.patient_consent.labels.authorization_required_text",
    personalInfoConsent: "application_form_editor.genentech.free_drug.patient_consent.labels.personal_info_consent",
    personalInfoConsentError:
      "application_form_editor.genentech.free_drug.patient_consent.errors.personal_info_consent",
    personalInfoConsentSignerRelationship:
      "application_form_editor.genentech.free_drug.patient_consent.labels.signer_relationship",
    personalInfoConsentSignerRelationshipPlaceholder:
      "application_form_editor.genentech.free_drug.patient_consent.placeholders.signer_relationship",
    personalInfoConsentSignerFirstName:
      "application_form_editor.genentech.free_drug.patient_consent.labels.signer_first_name",
    personalInfoConsentSignerLastName:
      "application_form_editor.genentech.free_drug.patient_consent.labels.signer_last_name",
    personalInfoConsentSignerEmail: "application_form_editor.genentech.free_drug.patient_consent.labels.signer_email"
  },
  [formSections.MEDICAL_SECTION]: {
    0: "application_form_editor.common.medical.labels.primary_diagnosis",
    1: "application_form_editor.common.medical.labels.secondary_diagnosis",
    2: "application_form_editor.common.medical.labels.tertiary_diagnosis",
    3: "application_form_editor.common.medical.labels.quaternary_diagnosis",
    diagnosisPlaceholder: "application_form_editor.common.medical.placeholders.diagnosis",
    addDiagnosis: "application_form_editor.common.medical.labels.add_diagnosis"
  },
  [formSections.TREATMENT_SITE_SECTION]: {
    treatmentSiteName: "application_form_editor.genentech.free_drug.practice.labels.treatment_site_name",
    treatmentSiteNamePlaceholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_name",
    treatmentAddress: "application_form_editor.genentech.free_drug.practice.labels.treatment_site_address",
    treatmentAddressPlaceholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_address",
    treatmentSiteCity: "application_form_editor.genentech.free_drug.practice.labels.treatment_site_city",
    treatmentSiteCityPlaceholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_city",
    treatmentSiteState: "application_form_editor.genentech.free_drug.practice.labels.treatment_site_state",
    treatmentSiteStatePlaceholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_state",
    treatmentSiteNPI: "application_form_editor.genentech.free_drug.practice.labels.treatment_site_npi",
    treatmentSiteNPIPlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_npi",
    treatmentSiteTaxId: "application_form_editor.genentech.free_drug.practice.labels.treatment_site_tax_id",
    treatmentSiteTaxIdPlaceholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_tax_id",
    treatmentSiteContactName: "application_form_editor.genentech.free_drug.practice.labels.treatment_site_contact_name",
    treatmentSiteContactNamePlaceholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_contact_name",
    treatmentSiteContactPhone:
      "application_form_editor.genentech.free_drug.practice.labels.treatment_site_contact_phone",
    treatmentSiteContactPhonePlaceholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_contact_phone",
    treatmentSiteContactFax: "application_form_editor.genentech.free_drug.practice.labels.treatment_site_contact_fax",
    treatmentSiteContactFaxPlaceholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_contact_fax",
    treatmentSiteContactEmail:
      "application_form_editor.genentech.free_drug.practice.labels.treatment_site_contact_email",
    treatmentSiteContactEmailPlaceholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_contact_email",
    treatmentSiteZip: "application_form_editor.genentech.free_drug.practice.labels.treatment_site_zip",
    treatmentSiteZipPlaceholder: "application_form_editor.genentech.free_drug.practice.placeholders.treatment_site_zip",
    treatment_site_place_of_service: "application_form_editor.genentech.free_drug.practice.labels.place_of_service",
    treatment_site_place_of_service_placeholder:
      "application_form_editor.genentech.free_drug.practice.placeholders.place_of_service",
    divider: "application_form_editor.genentech.free_drug.practice.labels.practice_info_divider"
  }
};
