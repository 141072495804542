import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox, Divider, Form, Row, Typography } from "antd";
import styled from "styled-components";

import { FormDividerText } from "./styledComponents";
import { GRAY_300, GRAY_400, GRAY_700 } from "../../../../constant/colors";
import { selectCurrentApplication, selectExpressEnrollmentConfiguration } from "../../../../store/selector";
import { titleCase } from "../../../../utils/string";
import { fontWeights } from "../../../../constant/styles";

const { Text } = Typography;

const assistanceTypeFormItem = "assistanceType";
export const adminAssistanceType = "assistance-type-admin";
export const drugAssistanceType = "assistance-type-drug";

const SupportText = styled(Text)`
  color: ${GRAY_700};
  font-weight: ${fontWeights.semibold};
`;

const FormItem = styled(Form.Item)`
  .ant-form-item-explain {
    min-height: 0px !important;
    height: 0;
  }
  margin-bottom: 0px;
`;

export const SupportOptions = () => {
  const { t } = useTranslation();
  const expressEnrollmentConfiguration = useSelector(selectExpressEnrollmentConfiguration);
  const application = useSelector(selectCurrentApplication);
  const drug = titleCase(application.drugDisplayName);

  return expressEnrollmentConfiguration?.hasAdminOption ? (
    <div id="enrollment-form-support-options">
      <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_300 }}>
        <FormDividerText>{t("application_form_editor.genentech.copay.support_options.header")}</FormDividerText>
      </Divider>
      <SupportText>{t("application_form_editor.genentech.copay.support_options.title")}</SupportText>
      <FormItem rules={[{ required: true }]} name={assistanceTypeFormItem}>
        <Checkbox.Group>
          <Row>
            <Checkbox value={drugAssistanceType} disabled>
              <SupportText style={{ color: GRAY_400 }}>
                {t("application_form_editor.genentech.copay.support_options.checkbox_drug", { drug })}
              </SupportText>
            </Checkbox>
          </Row>
          <Row>
            <Checkbox value={adminAssistanceType}>
              <SupportText>
                {t("application_form_editor.genentech.copay.support_options.checkbox_admin", { drug })}
              </SupportText>
            </Checkbox>
          </Row>
        </Checkbox.Group>
      </FormItem>
    </div>
  ) : null;
};
