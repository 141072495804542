export function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeEachFirstLetter(str) {
  if (!str) return "";
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
}

export function escapeRegExp(text) {
  return text?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function numberWithCommas(x) {
  return !x || isNaN(x) ? "" : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function shorterString(string, maxLength) {
  return string?.length > maxLength ? `${string.substring(0, maxLength)}...` : string;
}

export const isEmpty = (str) => !str || !str.trim();

export const titleCase = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const checkAndStringify = (input) => {
  if (input) {
    return input.toString();
  }
  if (!input) {
    return (input = "");
  }
};
