export const AlternateContactOptions = Object.freeze({
  OK_TO_SPEAK_TO: {
    id: "alternate_contact_ok_to_speak_to",
    value: "ok_to_speak_to",
    label: (t) => t("managePatientDetails.ok_to_speak_to"),
    labelPatientTopSection: (t) => t("patientTopSection.ok_to_speak_to")
  },
  CONTACT_INSTEAD_OF_PATIENT: {
    id: "alternate_contact_contact_instead_of_patient",
    value: "contact_instead_of_patient",
    label: (t) => t("managePatientDetails.contact_instead_of_patient"),
    labelPatientTopSection: (t) => t("patientTopSection.contact_instead_of_patient")
  }
});
