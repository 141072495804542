import React from "react";
import { Row, Col } from "antd";

const ROW_OPTIONS = {
  EQUAL: "equal",
  PROPORTION_0_5_0_5_ALL_1: "0.5:0.5:1",
  PROPORTION_1_1_2: "1:1:2",
  PROPORTION_1_2_2: "1:2:2",
  PROPORTION_1_2_0: "1:2:0",
  PROPORTION_2_1_1_1: "2:1:1:1",
  PROPORTION_2_1_1: "2:1:1",
  PROPORTION_1_3: "1:3",
  PROPORTION_3_1: "3:1",
  PROPORTION_2_5_1_5: "2.5:1.5",
  PROPORTION_1_BLANK: "1_BLANK",
  PROPORTION_ALL_1: "All_1"
};

const getSpan = (config, children) => {
  const childCount = React.Children.count(children);
  switch (config) {
    case ROW_OPTIONS.EQUAL:
      return Array(childCount).fill(24 / childCount);
    case ROW_OPTIONS.PROPORTION_0_5_0_5_ALL_1:
      return [3, 3, ...Array(childCount - 2).fill(18 / (childCount - 2))];
    case ROW_OPTIONS.PROPORTION_1_1_2:
      return [6, 6, 12];
    case ROW_OPTIONS.PROPORTION_1_2_2:
      return [6, 9, 9];
    case ROW_OPTIONS.PROPORTION_1_2_0:
      return [6, 12, 0];
    case ROW_OPTIONS.PROPORTION_2_1_1_1:
      return [12, 4, 4, 4];
    case ROW_OPTIONS.PROPORTION_1_3:
      return [6, 18];
    case ROW_OPTIONS.PROPORTION_3_1:
      return [18, 6];
    case ROW_OPTIONS.PROPORTION_2_5_1_5:
      return [15, 9];
    case ROW_OPTIONS.PROPORTION_2_1_1:
      return [12, 6, 6];
    case ROW_OPTIONS.PROPORTION_1_BLANK:
      return [6];
    case ROW_OPTIONS.PROPORTION_ALL_1:
      return Array(childCount).fill(6);
    default:
      return [24];
  }
};
const ConfigurableRow = ({ config, children, wrap = false, filterFalsy = false }) => {
  if (filterFalsy) {
    children = children.filter(Boolean);
  }

  // Fallback to EQUAL if condition for the layout isn't met
  if (config === ROW_OPTIONS.PROPORTION_0_5_0_5_ALL_1 && React.Children.count(children) !== 5) {
    config = ROW_OPTIONS.EQUAL;
  }
  const span = getSpan(config, children);

  return (
    <Row gutter={14} wrap={wrap}>
      {React.Children.map(children, (child, index) => (
        <Col index={index} span={span[index]} key={index}>
          {child}
        </Col>
      ))}
    </Row>
  );
};

export { ConfigurableRow, ROW_OPTIONS };
