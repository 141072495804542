import React, { useState } from "react";
import { Button, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import ErrorMessage from "../../customComponent/customMessages/ErrorMessage";
import SuccessMessage from "../../customComponent/customMessages/SuccessMessage";
import { fontWeights, sizes } from "../../../constant/styles";
import ACTIONS from "../../../store/action";
import {
  generateDraftApplicationFormPDFApi,
  getApplicationFormByApplicationIdApi,
  deleteApplicationFormPDFApi
} from "../../../api/api";
import { GRAY_500 } from "../../../constant/colors";
import { CloseButton } from "../PatientApplications/enrollTab/utils/components";
import { useSelectedApplication } from "../hooks";
import { pendoEEUtils } from "../../../utils/pendo/pendoEEUtils";
import { HEADER_SUBMIT_BUTTON_TYPES } from "./constants";

const { Text } = Typography;

const pendoSaveDraftEventName = "Save Draft";
const pendoSaveDraftEventActionTaken = "Save Draft Button for application form clicked";

export const Header = ({
  form,
  isFormValid,
  onClose,
  saveButton: SaveButton,
  applicationFormHeaderSubmission: ApplicationFormHeaderSubmission,
  headerSubmitButtonType,
  shouldSaveAppear,
  shouldCloseButtonAppear,
  updateApplicationForm,
  isSubmitting,
  onSubmit
}) => {
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);

  const applicationFormHeaderSubmissionButtonText =
    headerSubmitButtonType === HEADER_SUBMIT_BUTTON_TYPES.COMPLETE_FORM
      ? t("application_form_editor.eagle.complete_button")
      : t("application_form_editor.genentech.copay.submit_button");

  return (
    <Space style={{ width: "100%", justifyContent: "space-between" }}>
      <Space size={0} direction="vertical">
        <Text style={{ fontSize: sizes.medium_large, fontWeight: fontWeights.semibold }}>
          {t("application_form_editor.pfizer.header")}
        </Text>
        <Text style={{ color: GRAY_500 }}>{t("application_form_editor.pfizer.description")}</Text>
      </Space>
      {shouldCloseButtonAppear && <CloseButton onClick={onClose} />}
      {!shouldCloseButtonAppear && (
        <Space>
          {shouldSaveAppear() && (
            <SaveButton
              setIsLoading={setIsSaving}
              loading={isSaving}
              form={form}
              onClose={onClose}
              updateApplicationForm={updateApplicationForm}
            />
          )}
          <ApplicationFormHeaderSubmission
            buttonText={applicationFormHeaderSubmissionButtonText}
            isLoading={isSubmitting}
            onClick={onSubmit}
            disabled={isFormValid || isSaving}
          />
        </Space>
      )}
    </Space>
  );
};

export const SaveButton = ({
  form,
  setIsLoading,
  onSave,
  updateApplicationForm,
  onClose,
  showPdfOnSave = true,
  ...props
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const application = useSelectedApplication();

  const onSaveDraft = async () => {
    setIsLoading(true);
    try {
      onSave(form);
      const updatedApplicationForm = await updateApplicationForm();

      if (updatedApplicationForm?.data?.applicationForm?.supportPDF) {
        const applicationForm = updatedApplicationForm.data.applicationForm;
        if (showPdfOnSave) {
          await generateDraftApplicationFormPDFApi(applicationForm.id);
        } else if (applicationForm.applicationFormAttachmentId) {
          await deleteApplicationFormPDFApi(applicationForm.id, applicationForm.applicationFormAttachmentId);
        }
        const applicationFormWithAttachment = await getApplicationFormByApplicationIdApi(
          updatedApplicationForm.data.applicationForm.application
        );

        dispatch(ACTIONS.setApplicationForm(applicationFormWithAttachment.data.applicationForm));
      } else {
        dispatch(ACTIONS.setApplicationForm(updatedApplicationForm?.data.applicationForm));
      }
      pendoEEUtils(dispatch, application, pendoSaveDraftEventName, pendoSaveDraftEventActionTaken);
      SuccessMessage(t("patientApplications.enroll.enrollment_form.save_draft_success_message"));
      form.resetFields();
      onClose();
    } catch (err) {
      ErrorMessage(t("patientApplications.enroll.enrollment_form.save_error_message"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      id="EE_save_pdf"
      size={"large"}
      onClick={onSaveDraft}
      style={{ fontSize: sizes.medium_large, fontWeight: fontWeights.semibold }}
      {...props}
    >
      {t("application_form_editor.pfizer.save_draft")}
    </Button>
  );
};
