import React from "react";
import { Select } from "antd";

/**
 * Antd already supports objects as values for the most part with labelInValue
 * and fieldNames, but things get a little funky in interacting with Form.Item.
 * This is a light wrapper that smooths out those issues.
 */
const ObjectSelect = ({ value, onChange, ...props }) => {
  const { options, fieldNames } = props;
  const labelField = fieldNames?.label || "label";
  const valueField = fieldNames?.value || "value";

  const mappedValue = value && {
    label: value[labelField],
    value: value[valueField]
  };
  return (
    <Select
      labelInValue
      value={mappedValue}
      onChange={(value) => onChange(value && options.find((opt) => opt[valueField] === value?.value))}
      {...props}
    />
  );
};

export { ObjectSelect };
