import { searchIcdToDiagnoses } from "../../../../../api/api";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Select } from "antd";
import styled from "styled-components";
import { debounce } from "lodash";
import { ReactComponent as BlueTrashIcon } from "../../../../../assets/svg/BlueTrashIcon.svg";
import { useDiagnosisSearch } from "../../../../../hooks/useDiagnosisSearch";
const { Option } = Select;

//Sets the label to be on the right side of the label
const StyledFormItem = styled(Form.Item)`
  label {
    width: ${(props) => props.width};
  }
`;

export const DiagnosisDetails = ({ label, required, fieldName, onDelete, placeholder, allowClear, allowDelete }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const { handleDiagnosisSearch, searchDiagnosisResult, searchInProgress } = useDiagnosisSearch(searchIcdToDiagnoses);

  useEffect(() => {
    const fieldValue = form?.getFieldValue(fieldName);
    if (fieldValue) {
      handleDiagnosisSearch(fieldValue);
    }
  }, [fieldName, form]);

  const diagnosisSearchResults = searchDiagnosisResult ? searchDiagnosisResult.data?.ICDtoDiagnosises || [] : [];
  const formLabel = (
    <FormLabel
      text={label}
      required={required}
      onDelete={() => {
        form.setFields([{ name: fieldName, value: null }]);
        onDelete && onDelete();
      }}
      allowDelete={allowDelete}
    />
  );

  return (
    <StyledFormItem
      // controls whether the label is on the left or right side of the input, we want asterix to be on the left and the delete button to be on the right
      width={required ? null : "100%"}
      label={formLabel}
      name={fieldName}
      rules={[{ required: required }]}
    >
      <Select
        showSearch
        allowClear={allowClear}
        loading={searchInProgress}
        onSearch={debounce(handleDiagnosisSearch, 300)}
        filterOption={false}
        notFoundContent={diagnosisSearchResults.length ? t("add_new_diagnosis.no_results_found") : t("searching")}
        placeholder={placeholder}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {diagnosisSearchResults.map(({ diagnosis, code, tmDiagnosis }, index) => (
          <Option key={index} value={code}>
            {`${code} - ${diagnosis} (${tmDiagnosis})`}
          </Option>
        ))}
      </Select>
    </StyledFormItem>
  );
};

const FormLabel = ({ text, required, onDelete, allowDelete }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "baseline"
    }}
  >
    {text}
    {!required && allowDelete && onDelete && (
      <Button type="text" icon={<BlueTrashIcon />} onClick={onDelete} style={{ height: 0 }} />
    )}
  </div>
);
