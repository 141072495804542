import React from "react";
import { Typography } from "antd";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useTranslation } from "react-i18next";

import { selectEnvironment } from "../store/selector";
import { selectIsDevEnvironment } from "@tailormed/common-client/store/selector";
import { GOLD } from "../constant/colors";

const { Text } = Typography;

const EnvironmentHeaderComponent = ({ environment, isDevEnvironment }) => {
  const { t } = useTranslation();

  if (!environment || !isDevEnvironment) return null;

  return (
    <div className="dont-print" style={{ backgroundColor: GOLD, textAlign: "center" }}>
      <Text>{t("environment_header", { environment })}</Text>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  environment: selectEnvironment,
  isDevEnvironment: selectIsDevEnvironment
});

const EnvironmentHeader = connect(mapStateToProps)(EnvironmentHeaderComponent);

export { EnvironmentHeader };
