import React from "react";
import { Form } from "antd";

import { InputPhone, InputPhoneWithPhoneTypeAddon } from "../InputPhone";
import { phoneNumberLengthRegEx } from "../../../../../utils/form/validation";

export const PhoneNumberItem = ({
  required,
  name,
  label,
  placeholder,
  typeName,
  style = { display: "flex" },
  addon = true
}) => (
  <Form.Item
    rules={[
      { required, message: "" },
      { pattern: phoneNumberLengthRegEx, message: "" }
    ]}
    name={name}
    label={label}
  >
    {addon ? (
      <InputPhoneWithPhoneTypeAddon placeholder={placeholder} typeName={typeName} style={style} />
    ) : (
      <InputPhone placeholder={placeholder} style={style} />
    )}
  </Form.Item>
);
