import React, { useState } from "react";
import { Col, Row, Radio, Tooltip } from "antd";
import styled from "styled-components";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { CustomRadioGroup } from "../customComponentNewDesign/CustomRadioGroup";
import { dateFormatter } from "../../utils/date";

const CardWithHeaders = styled(Col)`
  border-radius: 8px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
`;

const CardName = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  background: #f2f4f7;
  width: 211px;
  height: 145px;
  border-radius: 8px 0px 0px 8px;
  border-top-left-radius: ${(props) => (props.showHeaders == true ? "0%;" : null)};
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const CardRow = styled(Row)`
  border: 1px solid #eaecf0;
  border-radius: 8px;
  border-top : ${(props) => (props.showHeaders == true ? "none;" : null)}
  border-top-left-radius:${(props) => (props.showHeaders == true ? "0%;" : null)}
  border-top-right-radius:${(props) => (props.showHeaders == true ? "0%;" : null)}
`;

const CardSubRow = styled(Row)`
  max-width: 1215px;
  height: 72px;
  background: ${(props) => (props.blueBackground ? "#EFF8FF" : null)};
  border-radius: 0px 8px 0px 0px;
  border-top-right-radius: ${(props) => (props.showHeaders == true ? "0%" : null)};
`;

const SubCol = styled(Col)`
  width: ${(props) => (props.width ? props.width + "px" : "0px")};
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 16px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-right: ${(props) => (props.showRightBorder == 0 ? "1px solid #E4E7EC" : null)};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Headers = styled(Row)`
  border: 1px solid #eaecf0;
  height: 44px;
  background: #f9fafb;
  border-radius: 8px 8px 0px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
`;

const StyledColHeader = styled(Col)`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 12px 24px 12px 16px;
  width: ${(props) => (props.width ? props.width + "px" : "190px")};
`;

const StyledRadio = styled(Radio)`
  .ant-radio-checked .ant-radio-inner {
    border-color: #1570ef !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #1570ef;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #1570ef;
  }

  p {
    color: #667085;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  > * {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

const ConflictCard = ({ showHeaders, insuranceRowPair = [], handleUserPick, cardIndex }) => {
  const columnsNames = ["", "Source", "Member ID", "Payer", "Plan Name", "Plan Market", "Group ID"];
  const columnsProperties = [
    {
      propertyName: "timestamp",
      columnWidth: "190"
    },
    {
      propertyName: "memberId",
      columnWidth: "124"
    },
    {
      propertyName: "payerName",
      columnWidth: "152"
    },
    {
      propertyName: "planName",
      columnWidth: "252"
    },
    {
      propertyName: "planMarket",
      columnWidth: "162"
    },
    {
      propertyName: "group",
      columnWidth: "124"
    }
  ];
  const [selectedRow, setSelectedRow] = useState(0);
  const { t } = useTranslation();
  const emptyValue = "-------";
  return (
    <Col>
      <CardWithHeaders>
        {showHeaders ? (
          <Headers>
            {columnsNames.map((header, index) => (
              <StyledColHeader key={index} width={index == 0 ? "211" : columnsProperties[index - 1].columnWidth}>
                {header}
              </StyledColHeader>
            ))}
          </Headers>
        ) : null}
        <Row>
          <CardRow showHeaders={showHeaders}>
            <CardName showHeaders={showHeaders}>
              {_.capitalize(insuranceRowPair[0]?.coverageType ?? insuranceRowPair[1].coverageType)}
            </CardName>

            <CustomRadioGroup
              name="radiogroup"
              onChange={(e) => {
                setSelectedRow(e.target.value);
                handleUserPick(cardIndex, e.target.value);
              }}
            >
              <Col>
                {insuranceRowPair.map((originalRow, subRowIndex) => {
                  return (
                    <CardSubRow key={subRowIndex} blueBackground={subRowIndex == selectedRow} showHeaders={showHeaders}>
                      {columnsProperties.map((columnObj, indexProp) => {
                        const propName = columnObj.propertyName;
                        const row = _.pick(originalRow, _.map(columnsProperties, "propertyName"));
                        return (
                          <SubCol
                            key={`${subRowIndex}${indexProp}`}
                            style={{ fontSize: "14px" }}
                            showRightBorder={indexProp != 0}
                            width={columnsProperties[indexProp].columnWidth}
                          >
                            {indexProp == 0 ? (
                              <div id={subRowIndex == 0 ? "conflict_coverage_current" : "conflict_coverage_imported"}>
                                <StyledRadio value={subRowIndex} checked={true}>
                                  {subRowIndex == 0 ? t("current") : t("imported")}
                                  {row[propName] ? <p>{dateFormatter(row[propName])}</p> : null}
                                </StyledRadio>
                              </div>
                            ) : indexProp == 4 ? (
                              <>
                                <Tooltip title={_.capitalize(row[propName] ?? emptyValue)}>
                                  {_.capitalize(row[propName] ?? emptyValue)}
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title={row[propName] ?? null}>{row[propName] ?? emptyValue}</Tooltip>
                              </>
                            )}
                          </SubCol>
                        );
                      })}
                    </CardSubRow>
                  );
                })}
              </Col>
            </CustomRadioGroup>
          </CardRow>
        </Row>
      </CardWithHeaders>
    </Col>
  );
};

export default ConflictCard;
