import React from "react";
import moment from "moment-timezone";

import { APPLICATIONS } from "../../../../constant/applications";
import getPfizerFreeDrugSidebarItems from "./sidebarItems";
import * as pfizerFreeDrugFieldNames from "./form/fieldNames";
import { getPfizerFreeDrugFormInitialValues } from "./form/initialValues";
import PfizerFreeDrugPatient from "./form/formSections/patient";
import Prescription from "./form/formSections/prescription/Prescription";
import Financial from "./form/formSections/financial";
import Diagnosis from "./form/formSections/diagnosis";
import PfizerFreeDrugInsurance from "./form/formSections/insurance/Insurance";
import HCP from "./form/formSections/hcp/HCP";
import Attachments from "./form/formSections/attachments";
import { SubmittedFormModal } from "../../PatientApplications/enrollTab/submission/SubmittedFormModal";
import { CompleteFormButton } from "../CompleteFormButton";
import { DATE_FORMAT } from "../../../../utils/date";
import { applyTrimFields } from "../utils/updateFormData";
import { SaveButton } from "../header";
import { pfizerApprovalApplicationMockAPI } from "../../../../api/api";
import { SignedFormUploader } from "../../PatientApplications/enrollTab/utils/uploadFunctionality";
import { fileTypes } from "../../../Uploader";
import { HEADER_SUBMIT_BUTTON_TYPES } from "../constants";

const pfizerFreeDrugConfigurations = {
  getSidebarItems: getPfizerFreeDrugSidebarItems,
  activeFormSection: "#enrollment-form-patient-information-anchor",
  formId: "pfizer_smart_editor",
  fieldNames: pfizerFreeDrugFieldNames,
  getFormInitialValues: getPfizerFreeDrugFormInitialValues,
  getFormSectionComponents: () => (
    <>
      <PfizerFreeDrugPatient />
      <Prescription />
      <Financial />
      <Diagnosis />
      <PfizerFreeDrugInsurance />
      <HCP />
      <Attachments />
    </>
  ),
  getViewDetailsModal: (onCancel, visible) => <SubmittedFormModal onCancel={onCancel} visible={visible} />,
  applicationFormHeaderSubmission: (props) => <CompleteFormButton id={"EE_complete_button"} {...props} />,
  headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.COMPLETE_FORM,
  successMessage: (t, submittedDate) =>
    t("patientApplications.enroll.application_form_submission.submit_in_progress", {
      submitDate: moment(submittedDate).format(DATE_FORMAT)
    }),
  inProgressMessage: () => ({}),
  saveButton: (props) => (
    <SaveButton onSave={(form) => applyTrimFields(form)} id={"EE_pfizer_free_drug_save_pdf"} {...props} />
  ),
  shouldSaveAppear: () => true,
  getShowEnrollResult: () => false,
  prepareButtonId: "EE_pfizer_free_drug_prepare_application_form",
  viewDetailsButtonId: "EE_pfizer_free_drug_view_submission_details",
  applicationSupportsEEId: "application_supports_pfizer_free_drug_EE",
  signatureStep: {
    uploadButtonText: (t) => t("patientApplications.enroll.electronic_signatures.upload_signed_form_button"),
    ESignButtonText: (t) => t("patientApplications.enroll.electronic_signatures.get_signatures_button"),
    getDocusignRecipients: (t) => [
      { type: "patient", role: t("patientApplications.enroll.electronic_signatures.patient_recipient_one") },
      { type: "provider", role: t("patientApplications.enroll.electronic_signatures.physician_recipient_two") },
      { type: "sender", role: t("patientApplications.enroll.electronic_signatures.sender") }
    ],
    fileTypesAccepted: fileTypes.PDF,
    uploadAttachmentButton: SignedFormUploader,
    pendoUploadSignAttachment: "Upload Signed PDF",
    pendoUploadSignAttachmentActionTaken: "Signed PDF has been attached to application"
  },
  submissionStep: {
    description: (t) => t("patientApplications.enroll.application_form_submission.description"),
    getSubmitButtonText: (t) => t("patientApplications.enroll.application_form_submission.submit_button"),
    submitModal: {
      showNotice: true,
      attachments: {
        showAllowedFileTypesNotice: true
      }
    },
    getSubmitSuccessMessage: (t) => t("patientApplications.enroll.application_form_submission.submit_success_message"),
    afterSubmitCompleted: async (applicationForm, applicationId, papId) => {
      if (applicationForm?.isDemo) {
        return pfizerApprovalApplicationMockAPI(applicationId, papId);
      }
    }
  },
  afterSubmissionPayload: () => ({ status: APPLICATIONS.statuses.applied, appliedDate: moment() })
};

export default pfizerFreeDrugConfigurations;
