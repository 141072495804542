import React, { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";

import { PRIMARY_INSURANCE } from "./Insurance";
import { phoneNumberLengthRegEx } from "../../../../../../../utils/formValidation";
import { insuranceFields } from "../../fieldNames";
import { FormItem, InputPhone } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";
import { InsuranceTypeFormItem, insuranceTypes } from "./InsuranceTypeFormItem";
import { PolicyholderDetails } from "./PolicyholderDetails";

const InsuranceDetails = ({ type }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const insuranceTypeField = type === PRIMARY_INSURANCE ? insuranceFields.primaryType : insuranceFields.secondaryType;

  const policyholderSameAsPatientField =
    type === PRIMARY_INSURANCE
      ? insuranceFields.primaryPolicyholderSameAsPatient
      : insuranceFields.secondaryPolicyholderSameAsPatient;

  const policyholderNameField =
    type === PRIMARY_INSURANCE ? insuranceFields.primaryPolicyholderName : insuranceFields.secondaryPolicyholderName;

  const policyholderRelationshipField =
    type === PRIMARY_INSURANCE
      ? insuranceFields.primaryPolicyholderRelationship
      : insuranceFields.secondaryPolicyholderRelationship;

  const policyholderDOBField =
    type === PRIMARY_INSURANCE ? insuranceFields.primaryPolicyholderDOB : insuranceFields.secondaryPolicyholderDOB;

  const insuranceType = Form.useWatch(insuranceTypeField, form);

  useEffect(() => {
    form.validateFields();
  }, [insuranceType]);

  return (
    <>
      <Row gutter={14}>
        <Col span={24}>
          <InsuranceTypeFormItem type={type} name={insuranceTypeField} />
        </Col>
      </Row>
      {Object.values(insuranceTypes).includes(insuranceType) && insuranceType !== insuranceTypes.NONE && (
        <>
          <Row gutter={14}>
            <Col span={9}>
              <FormItem
                name={type === PRIMARY_INSURANCE ? insuranceFields.primaryName : insuranceFields.secondaryName}
                label={t("application_form_editor.pfizer.insurance.labels.payer_name")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input placeholder={t("application_form_editor.pfizer.insurance.placeholders.payer_name")} />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                rules={[{ pattern: phoneNumberLengthRegEx, message: "" }, { required: true }]}
                name={type === PRIMARY_INSURANCE ? insuranceFields.primaryPhone : insuranceFields.secondaryPhone}
                label={t("application_form_editor.pfizer.insurance.labels.payer_phone")}
              >
                <InputPhone placeholder={t("application_form_editor.pfizer.insurance.placeholders.payer_phone")} />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                name={type === PRIMARY_INSURANCE ? insuranceFields.primaryGroup : insuranceFields.secondaryGroup}
                label={t("application_form_editor.pfizer.insurance.labels.group_id")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={20}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.group_id")}
                />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                name={type === PRIMARY_INSURANCE ? insuranceFields.primaryMember : insuranceFields.secondaryMember}
                label={t("application_form_editor.pfizer.insurance.labels.member_id")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={20}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.member_id")}
                />
              </FormItem>
            </Col>
          </Row>
          <PolicyholderDetails
            policyholderSameAsPatientField={policyholderSameAsPatientField}
            policyholderNameField={policyholderNameField}
            policyholderRelationshipField={policyholderRelationshipField}
            policyholderDOBField={policyholderDOBField}
          />
        </>
      )}
    </>
  );
};

export { InsuranceDetails };
