import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton, Space } from "antd";

import { ApprovedResult } from "./ApprovedResult";
import { DeniedResult } from "./DeniedResult";
import { getAssistanceProgramApplicationItemsByAppId } from "../../../../../api/api";
import { useSelectedApplication } from "../../../hooks";
import ErrorMessage from "../../../../customComponent/customMessages/ErrorMessage";

const ITEM_ADMINISTRATION_TYPE = "administration";
const ITEM_STATUS_APPROVED = "approved";

export const EnrollResult = ({ id, hideDescription = false, titleStyle }) => {
  const { t } = useTranslation();
  const application = useSelectedApplication();
  const [applicationItems, setApplicationItems] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getItemNames = (items) => {
    const hasAdmin = items.some((item) => item.type === ITEM_ADMINISTRATION_TYPE);
    if (hasAdmin) {
      const drugName = items[0].rxnormDrug.bnName;
      return items.length === 1
        ? t("patientApplications.enroll.enroll_result.admin_only", { drug: drugName })
        : t("patientApplications.enroll.enroll_result.drug_and_administration", { drug: drugName });
    }

    const drugNames = items.map((item) => item.rxnormDrug.bnName);

    if (drugNames.length > 1) {
      return t("patientApplications.enroll.enroll_result.drugs", { drugs: drugNames.join(", ") });
    }

    return t("patientApplications.enroll.enroll_result.drug", { drug: drugNames[0] });
  };

  useEffect(async () => {
    setIsLoading(true);
    try {
      const applicationItems = await getAssistanceProgramApplicationItemsByAppId(application.id);
      if (applicationItems?.data) {
        setApplicationItems(applicationItems.data);
      } else {
        ErrorMessage(t("patientApplications.enroll.enroll_result.failed_to_get_submit_result"));
      }
    } catch (error) {
      ErrorMessage(t("patientApplications.enroll.enroll_result.failed_to_get_submit_result"));
    } finally {
      setIsLoading(false);
    }
  }, []);

  const approvedItems = [];
  const deniedItems = [];
  applicationItems?.forEach((item) =>
    item.status === ITEM_STATUS_APPROVED ? approvedItems.push(item) : deniedItems.push(item)
  );

  const approvedItemNames = getItemNames(approvedItems);
  const approvedTitle =
    approvedItems.length > 0 &&
    t("patientApplications.enroll.enroll_result.application_approved_for", { items: approvedItemNames });

  const deniedItemsNames = getItemNames(deniedItems);
  const deniedTitle =
    deniedItems.length > 0 &&
    t("patientApplications.enroll.enroll_result.application_denied_for", { items: deniedItemsNames });

  return (
    <div id={id}>
      {isLoading ? (
        <Skeleton style={{ paddingTop: 16 }} active paragraph={{ rows: 10 }} />
      ) : (
        <Space
          size="large"
          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
          {!!approvedItems?.length && (
            <ApprovedResult
              title={approvedTitle}
              applicationId={application?.id}
              hideDescription={hideDescription}
              titleStyle={titleStyle}
            />
          )}
          {!!deniedItems?.length && <DeniedResult title={deniedTitle} denialReason={deniedItems[0].denialReason} />}
        </Space>
      )}
    </div>
  );
};
