const patientFields = {
  biologicalMedication: ["patient", "biologicalMedication"],
  caregiverFirstName: ["patient", "caregiver", "firstName"],
  caregiverLastName: ["patient", "caregiver", "lastName"],
  caregiverDob: ["patient", "caregiver", "dob"],
  caregiverGender: ["patient", "caregiver", "gender"],
  caregiverAddress: ["patient", "caregiver", "address"],
  caregiverCity: ["patient", "caregiver", "city"],
  caregiverState: ["patient", "caregiver", "state"],
  caregiverZipCode: ["patient", "caregiver", "zip"],
  caregiverEmail: ["patient", "caregiver", "email"],
  caregiverPhoneNumber: ["patient", "caregiver", "phone"],
  caregiverPhoneType: ["patient", "caregiver", "phoneType"]
};
const conditionFields = {
  primaryCondition: ["condition", "primaryCondition"]
};
const eligibilityAndAttestationFields = {
  hipaaAuthConsent: ["eligibilityAndAttestation", "hipaaConsent"]
};
const supportOptionsFields = {
  supportOption: ["supportOptions", "supportOption"]
};

export { patientFields, conditionFields, eligibilityAndAttestationFields, supportOptionsFields };
