import { Col, Form, Row, Space, Select, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import _ from "lodash";

import { SingleSelect } from "../../customComponentNewDesign/CustomSelect";
import { CustomInput } from "../../customComponentNewDesign/CustomInput";
import { CustomDatePicker } from "../../customComponentNewDesign/customDatePicker";
import { ReactComponent as WarningMark } from "../../../assets/svg/warning-mark.svg";
import { PLAN_MARKETS_OPTIONS } from "../../../constant/planMarkets";
import { selectPatientDetails } from "../../../store/selector";
import { DATE_FORMAT } from "../../../utils/date";
import CustomSelectAutocomplete from "../../customComponentNewDesign/CustomSelectAutocomplete";
import { useGetSetting } from "../../../hooks/getSetting";

const { Option } = Select;
const { Text } = Typography;

const StyledCol = styled(Col)`
  padding: 24px 24px 0px 24px;

  width: 696px;
  background: #ffffff;
  border: 1px solid #e4e7ec;

  border-radius: 8px;
  margin: 8px 0px;
`;

const InputLabel = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
`;

const ManullyPayerAlert = styled(Space)`
  color: #667085;
  align-items: flex-start;
  padding-bottom: 24px;
`;
const ManullyPayerAlertText = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;

const ColWithMargin = styled(Col)`
  margin-right: 16px;
`;
const InsuranceCard = ({ validEbvPayers, form, index, toolTipTitle, customPayer, coveragesData, t }) => {
  const [isUnknown, setIsUnknown] = useState(customPayer);
  const [skipBenefitInvestigation] = useGetSetting(["skipBenefitInvestigation"]);

  return (
    <StyledCol>
      <Row>
        <ColWithMargin>
          <Row>
            <InputLabel>{t("patientClassicCoverageDetailsOverview.plan_market")}</InputLabel>
          </Row>
          <Row>
            <Form.Item
              name={["coverages", "patientInsuranceRows", index, "planMarket"]}
              rules={[{ required: true, message: t("patientClassicCoverageDetailsOverview.choose_plan_market") }]}
            >
              <SingleSelect
                required={true}
                showArrow={true}
                showSearch={true}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                width="300px"
                onSelect={(planMarket) => {
                  const { coverages } = form.getFieldsValue(["coverages"]);
                  coverages.patientInsuranceRows[index].planMarket = planMarket;
                  form.setFieldsValue({ coverages });
                }}
              >
                {PLAN_MARKETS_OPTIONS?.map((planMarket) => (
                  <Option key={planMarket.key} value={planMarket.value} id={planMarket.id}>
                    {_.startCase(planMarket.translationText)}
                  </Option>
                ))}
              </SingleSelect>
            </Form.Item>
          </Row>
        </ColWithMargin>
        <ColWithMargin>
          <Row>
            <InputLabel>{t("patientClassicCoverageDetailsOverview.group_id")}</InputLabel>
          </Row>
          <Row>
            <Form.Item name={["coverages", "patientInsuranceRows", index, "group"]}>
              <CustomInput width="150px" />
            </Form.Item>
          </Row>
        </ColWithMargin>
        <Col>
          <Row>
            <InputLabel>{t("patientClassicCoverageDetailsOverview.member_id")}</InputLabel>
          </Row>
          <Row>
            <Form.Item
              name={["coverages", "patientInsuranceRows", index, "memberId"]}
              rules={[
                () => ({
                  validator(_, value) {
                    const isDuplicateValuesExist = coveragesData.some(
                      (coverage, coverageIndex) =>
                        index !== coverageIndex &&
                        coverage.memberId === value &&
                        coveragesData[index].payerName === coverage.payerName
                    );

                    if (isDuplicateValuesExist) {
                      return Promise.reject(new Error());
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <CustomInput width="150px" />
            </Form.Item>
          </Row>
        </Col>
      </Row>
      <Row>
        <ColWithMargin>
          <Row>
            <InputLabel>{t("patientClassicCoverageDetailsOverview.payer")}</InputLabel>
          </Row>
          <Row>
            <Form.Item
              name={["coverages", "patientInsuranceRows", index, "payerName"]}
              rules={[
                { required: true, message: t("input_required") },
                () => ({
                  validator(_, value) {
                    const isDuplicateValuesExist = coveragesData.some(
                      (coverage, coverageIndex) =>
                        index !== coverageIndex &&
                        coverage.memberId === coveragesData[index].memberId &&
                        value === coverage.payerName
                    );
                    if (isDuplicateValuesExist) {
                      return Promise.reject(new Error());
                    }
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <CustomSelectAutocomplete setIsCustomPayer={setIsUnknown} width="300px">
                {validEbvPayers?.map((payer, index) => (
                  <Option key={index} value={payer.name}>
                    {_.startCase(payer.name)}
                  </Option>
                ))}
              </CustomSelectAutocomplete>
            </Form.Item>
          </Row>
        </ColWithMargin>
        <ColWithMargin>
          <Row>
            <InputLabel>{t("patientCoverageDetails.plan_name")}</InputLabel>
          </Row>
          <Row>
            <Tooltip title={toolTipTitle}>
              <Form.Item name={["coverages", "patientInsuranceRows", index, "planName"]}>
                <CustomInput width="150px" />
              </Form.Item>
            </Tooltip>
          </Row>
        </ColWithMargin>
        <Col>
          <Row>
            <InputLabel>{t("new_patient.effective_date_tooltip")}</InputLabel>
          </Row>
          <Row>
            <Form.Item name={["coverages", "patientInsuranceRows", index, "activeDate"]}>
              <CustomDatePicker format={DATE_FORMAT} width="150" />
            </Form.Item>
          </Row>
        </Col>
        <Row>
          {isUnknown && !skipBenefitInvestigation && (
            <ManullyPayerAlert>
              <WarningMark />
              <ManullyPayerAlertText>{t("new_patient.other_payer_selected_message")}</ManullyPayerAlertText>
            </ManullyPayerAlert>
          )}
        </Row>
      </Row>
    </StyledCol>
  );
};

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

export default compose(connect(mapStateToProps))(InsuranceCard);
