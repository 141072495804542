import { FormFields } from "./FormFields";
import _ from "lodash";
const workSheetRows = [
  { rowLabel: "managePatientDetails.salary", fieldName: FormFields.incomeSalary },
  { rowLabel: "managePatientDetails.social_security", fieldName: FormFields.incomeSocialSecurity },
  { rowLabel: "managePatientDetails.pension", fieldName: FormFields.incomePension },
  { rowLabel: "managePatientDetails.disability", fieldName: FormFields.incomeDisability },
  { rowLabel: "managePatientDetails.dividends", fieldName: FormFields.incomeDividends },
  { rowLabel: "managePatientDetails.child_support", fieldName: FormFields.incomeChildSupport },
  { rowLabel: "managePatientDetails.food_stamps", fieldName: FormFields.incomeFoodStamps },
  { rowLabel: "managePatientDetails.unemployment", fieldName: FormFields.incomeUnemployment },
  { rowLabel: "managePatientDetails.stocks", fieldName: FormFields.incomeStocksBonds },
  { rowLabel: "managePatientDetails.other", fieldName: FormFields.incomeOther }
];

const getParsedInitialFormValues = (formValues) => {
  const generateAnnualValue = (key) =>
    formValues[key] && !_.isNaN(formValues[key])
      ? parseFloat(parseFloat(cleanNumberFromCommas(formValues[key])) * 12).toFixed(2)
      : parseFloat(0).toFixed(2);
  const generateMonthlyValue = (key) =>
    formValues[key] && !_.isNaN(formValues[key])
      ? parseFloat(cleanNumberFromCommas(formValues[key])).toFixed(2)
      : parseFloat(0).toFixed(2);

  const initialAnnualValues = Object.fromEntries(
    workSheetRows.map((field) => [field.fieldName + "Annual", generateAnnualValue(field.fieldName + "Monthly")])
  );

  const initialMonthlyValues = Object.fromEntries(
    workSheetRows.map((field) => [field.fieldName + "Monthly", generateMonthlyValue(field.fieldName + "Monthly")])
  );

  const totalIncome = formValues[FormFields.totalIncome];

  const parsedInitialFormValues = {
    ...initialMonthlyValues,
    ...initialAnnualValues,
    [FormFields.totalMonthlyIncome]: totalIncome || totalIncome === 0 ? stripStringToNumber(totalIncome / 12) : null,
    [FormFields.totalIncome]: totalIncome || totalIncome === 0 ? stripStringToNumber(totalIncome) : null
  };
  return parsedInitialFormValues;
};

const addCommasToNumer = (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const cleanNumberFromCommas = (value) => value.replace(/\$\s?|(,*)/g, "").trim();
const stripStringToNumber = (value) => parseFloat(parseFloat(value).toFixed(2)) ?? "";

const parseToFloatNumberWithTwoDigits = (value) => parseFloat(parseFloat(value).toFixed(2)) ?? "";
const clearObjectValues = (object) => Object.values(object).map((value) => stripStringToNumber(value) ?? 0);

export {
  getParsedInitialFormValues,
  workSheetRows,
  addCommasToNumer,
  cleanNumberFromCommas,
  stripStringToNumber,
  parseToFloatNumberWithTwoDigits,
  clearObjectValues
};
