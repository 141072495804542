import React from "react";

export default function ElectcityIcon(props) {
  return (
    <svg width="13" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.125 0.875L6.625 5.375H12.625L5.875 12.125L6.625 7.625H1.375L8.125 0.875Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
