import React from "react";
import { Layout, Typography } from "antd";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { WHITE_GRAY } from "../constant/colors";

const { Footer: AntFooter } = Layout;
const { Text } = Typography;

const Footer = styled(AntFooter)`
  bottom: 0;
  width: 100%;
  background-color: white;
  border-top: 2px solid ${WHITE_GRAY};
  z-index: 998;
  justify-content: center;
  margin-top: 10px;
`;

const FoorterText = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.theme.dimensions.width};
`;

const MainFooterComponent = ({ t }) => (
  <Footer className="dont-print">
    <FoorterText>
      <Text>{t("footer")}</Text>
    </FoorterText>
  </Footer>
);

const MainFooter = withTranslation()(MainFooterComponent);
export { MainFooter };
