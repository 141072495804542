import React, { useState, useMemo, useRef } from "react";
import { debounce } from "lodash";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

// This component can take in objects as values, but things don't work like we
// want if we pass in an object with an empty value property, so we convert any
// effectively-empty values to undefined so that it clears the box properly
function emptyValueToUndefined(value) {
  const effectiveValue = value?.value ?? value;
  return effectiveValue ? value : undefined;
}

/**
 * This is a select that manually implements filtering for large lists,
 * because we want to be able to delay filtering, which seems to not be
 * supported in the native filtering provided by filterOption.
 *
 * It also supports a custom function to render the option, because simply
 * setting `label` runs into problems with pre-filled values
 */
const FilterSelect = ({
  minSearchLength = 2,
  debounceDelayMs = 200,
  dropdownMatchSelectWidth,
  dropdownFallbackWidth = null,
  // An arbitrary number of options above which the dropdown is "too slow" if we
  // render the whole list, which is required to auto-adjust the width
  dropdownFallbackThreshold = 100,
  onSelect = null,
  onSearch = null,
  onClear = null,
  options = undefined,
  children = undefined,
  searchingContent = undefined,
  notFoundContent = undefined,
  value = undefined,
  defaultValue = undefined,
  ...props
}) => {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);

  // This is the same in principle as the debounces we do on remote fetches,
  // but instead we're waiting to update the internal filter text, because
  // that's what triggers the filtering, which is slow, so we don't want to do
  // it more than we need to, much like a remote request.
  //
  // It's a ref because we need to make sure we have one consistent reference
  // to the debounced function so that we can cancel it in `resetFilter`. We
  // don't use useMemo() for this because that is strictly for performance
  // improvements and does _not_ guarantee once-and-only-once.
  //
  // We also purposefully don't update this if debounceDelayMs changes, because
  // doing so would make this complicated and that's not a likely use case.
  const debounceRef = useRef(
    debounce((value) => {
      setFilterText(value);
      setIsFiltering(false);
    }, debounceDelayMs)
  );
  const debouncedSetFilterText = debounceRef.current;

  // Because we have to hide the options from Antd until we have a long enough
  // filter, for selects with a pre-selected value, so we need to generate an
  // option list of one so that Ant can find an option to render
  const optionList = useMemo(() => {
    let allOptions = children || options;
    if (filterText) {
      return allOptions;
    }

    const id = value?.value ?? value?.id ?? value;
    if (!id) {
      return null;
    }

    const option = allOptions?.find((option) => option.value === id || option.props?.value === id);
    return option ? [option] : null;
  }, [filterText, value, children, options]);

  // dropdownMatchSelectWidth=false automatically adjusts the width of the
  // dropdown to the width of the widest option. This disables virtual scroll,
  // because it needs to render the whole list in order to determine the
  // widest option.
  //
  // However, if the list gets too long (thousands of entries), we need virtual
  // scroll to avoid poor performance, and thus we can't auto-adjust.
  //
  // Because this is suboptimal, we compromise: if the number of entries is
  // options is above a certain threshold, we disable the auto-width because
  // it's too slow, and either revert to matching the select list, or use a
  // hardcoded fallback width if provided.
  //
  // It would be nice if we could do this dynamically as we filter, but we
  // don't have access to how many items are in the filtered list, so we have
  // to operate on the un-filtered list.
  if (dropdownMatchSelectWidth === false) {
    if (optionList?.length > dropdownFallbackThreshold) {
      // If the list is too long, disable auto-width, using our fallback width if we have it
      if (dropdownFallbackWidth) {
        dropdownMatchSelectWidth = dropdownFallbackWidth;
      } else {
        dropdownMatchSelectWidth = true;
      }
    }
  }

  function resetFilter() {
    setFilterText(null);
    setIsFiltering(false);
    debouncedSetFilterText.cancel();
  }

  const handleSelect = (selectedItem) => {
    onSelect && onSelect(selectedItem);
    if (selectedItem) {
      resetFilter();
    }
  };

  const handleSearch = (searchText) => {
    onSearch && onSearch(searchText);
    if (searchText) {
      if (searchText.length >= minSearchLength) {
        setIsFiltering(true);
        debouncedSetFilterText(searchText);
      } else {
        resetFilter();
      }
    } else {
      resetFilter();
    }
  };

  const handleClear = () => {
    onClear && onClear();
    resetFilter();
  };

  // This is tricky: we want to show searchingContent if we're filtering the
  // list and notFoundContent if we're done filtering and have nothing, but in
  // the case where we have no results because we don't have enough characters
  // to be filtering (that is, there is no filterText), we want to not show the
  // box at all, which is accomplished by setting notFoundContent to `null`
  function getDropdownContent() {
    if (isFiltering) {
      return searchingContent || t("searching");
    }

    return filterText ? notFoundContent : t("type_to_search");
  }

  return (
    <Select
      allowClear
      showArrow
      showSearch
      defaultActiveFirstOption={false}
      onSelect={handleSelect}
      onSearch={handleSearch}
      onClear={handleClear}
      notFoundContent={getDropdownContent()}
      value={emptyValueToUndefined(value)}
      defaultValue={emptyValueToUndefined(defaultValue)}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      options={options && optionList}
      {...props}
    >
      {children && optionList}
    </Select>
  );
};

// So we can pull Option out to build options
FilterSelect.Option = Select.Option;

export { FilterSelect };
