export const parseSelectedColumns = (newTableColumns, selectedView, selectedColumns) => {
  // Note: resting the default checked columns first, then decide later if it should be displayed or not based on the user selected columns in the DB.
  for (const [columnKey, column] of Object.entries(newTableColumns[selectedView])) {
    if (column.mandatory === false && column.display === true) {
      newTableColumns[selectedView][columnKey].display = false;
    }
  }

  selectedColumns.forEach((key) => {
    if (newTableColumns[selectedView][key]) newTableColumns[selectedView][key].display = true;
  });
};
