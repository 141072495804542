import React, { useState } from "react";
import styled from "styled-components";
import { Select } from "antd";
import FormItem from "./FormItem";
import { CaretDownOutlined, CheckOutlined } from "@ant-design/icons";
import { makeStyleWrapper } from "../utils/styles";
import { PRIMARY_600 } from "../constant/colors";

const { Option } = Select;

const StyledFormItem = styled(FormItem)`
  margin: 0;
  .ant-col.ant-form-item-label > label {
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
  }
`;

const Badge = styled.span`
  background: ${(props) => props.opts.backgroundColor};
  color: ${(props) => props.opts.color};
  font-size: 12px;
  line-height: initial;
  padding: 2px 8px;
  border-radius: 16px;
  font-weight: 600;
`;

const BadgeSelect = styled(Select)`
  &.ant-select-single {
    width: fit-content; /* This is necessary so the right side of the dropdown aligns with the right side of the badge */
    .ant-select-selector {
      padding: 0;
    }
    .ant-select-arrow {
      right: initial;
      left: 8.25px;
    }
    .ant-select-selection-item {
      padding-right: initial;
      display: flex;
      ${Badge} {
        align-self: center;
        padding-left: 22px;
      }
    }
  }

  .ant-select-arrow {
    left: 13px;
    right: initial;
  }
  .anticon > svg {
    color: ${(props) => props.selectedOption?.color};
  }
`;

const wrapDropdown = makeStyleWrapper(`
  .ant-select-item {
    width: 152px;
    gap: 4px;
    .anticon {
      color: ${PRIMARY_600};
    }
  }
`);

export const DropdownBadge = ({ options, defaultValue, onChange = () => {}, ...props }) => {
  // TODO: This is only used to set the color of the dropdown arrow, can we do this elsewise?
  const [selectedValue, setSelectedValue] = useState(options?.[0].value);
  const handleChange = (e) => {
    setSelectedValue(e?.target?.value || e);
    onChange(e?.target?.value || e);
  };
  const selectedOption = options?.find((option) => option.value === selectedValue);
  return (
    <StyledFormItem {...props}>
      <BadgeSelect
        selectedOption={selectedOption}
        onChange={handleChange}
        defaultValue={defaultValue}
        bordered={false}
        suffixIcon={<CaretDownOutlined />}
        menuItemSelectedIcon={<CheckOutlined />}
        dropdownMatchSelectWidth={false}
        placement="bottomRight"
        dropdownRender={wrapDropdown}
      >
        {options?.map(({ label, value, ...opts }) => (
          <Option key={value} value={value}>
            <Badge opts={opts}>{label}</Badge>
          </Option>
        ))}
      </BadgeSelect>
    </StyledFormItem>
  );
};
