import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";

export const useGetLabels = (labels) => {
  const { t } = useTranslation();
  const translateSection = (section) =>
    Object.entries(labels[section]).reduce((acc, [key, value]) => {
      acc[key] = t(value);
      return acc;
    }, {});

  const _getLabelsBySection = useCallback(
    (sectionName) => {
      return translateSection(sectionName);
    },
    [labels, t]
  ); // Dependency array to recompute if labels or translation function changes

  const allSections = useMemo(() => {
    return Object.keys(labels).reduce((acc, sectionName) => {
      acc[sectionName] = _getLabelsBySection(sectionName);
      return acc;
    }, {});
  }, [labels, _getLabelsBySection]);

  const getAllSections = () => allSections;
  const getLabelsBySection = (sectionName) => allSections[sectionName];

  return { getLabelsBySection, getAllSections };
};
