import React, { useEffect, useState } from "react";
import { Security, LoginCallback } from "@okta/okta-react";
import { Router, Switch, Route } from "react-router-dom";

import { selectTableColumns } from "../store/selector";
import { Login } from "../screen/Login";
import { Routes } from "../constant/routes";
import { SecuredRouter } from "./SecuredRouter";
import { connect, useSelector } from "react-redux";
import ACTIONS from "../store/action";
import { createStructuredSelector } from "reselect";
import { ColumnsKeys } from "../constant/columns";
import { tableColumns } from "../constant/tableColumns";
import { history } from "./history";
import { oktaConfig } from "../constant/okta";

import { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginHandler } from "@tailormed/common-client/component/LoginHandler";
import { ServerError } from "@tailormed/common-client/component/ServerError";
import { selectServerError } from "@tailormed/common-client/store/selector";
import {
  ErrorPageLayout,
  AppLayout,
  LogoContainer,
  TailormedLogoIcon,
  TailormedVisualIcon,
  TailormedLogoContainer,
  TailormedHeaderText
} from "../component/AppLayout";
import { getAuthInstance } from "@tailormed/common-client/services/auth";

const MainRouterComponent = ({ setTableColumns, hasServerError }) => {
  const [oktaAuth, setOktaAuth] = useState(null);
  const clientId = useSelector((state) => state.oktaClientId);
  useEffect(() => {
    if (clientId) {
      setOktaAuth(getAuthInstance(oktaConfig?.redirectUri, clientId));
    }
  }, [clientId]);

  const updateColumnSelector = () => {
    const tablesColumnSaved = { ...tableColumns };
    Object.values(ColumnsKeys.tables).forEach((tableName) => {
      const tableColumnSaved = JSON.parse(localStorage.getItem(tableName));
      if (tableColumnSaved) {
        tablesColumnSaved[tableName] = { ...tableColumns[tableName], ...tableColumnSaved };
      }
    });
    setTableColumns(tablesColumnSaved);
  };

  window.addEventListener("storage", async function (event) {
    if (Object.values(ColumnsKeys.tables).includes(event.key)) {
      updateColumnSelector();
    }
  });

  useEffect(() => {
    updateColumnSelector();
  }, [setTableColumns]);

  const onAuthRequired = () => {
    history.push(Routes.ROOT + Routes.LOGIN);
  };

  if (hasServerError) {
    return (
      <Router history={history}>
        <ServerError
          ErrorPageLayout={ErrorPageLayout}
          AppLayout={AppLayout}
          LogoContainer={LogoContainer}
          TailormedLogoIcon={TailormedLogoIcon}
          TailormedVisualIcon={TailormedVisualIcon}
          TailormedLogoContainer={TailormedLogoContainer}
          TailormedHeaderText={TailormedHeaderText}
        />
      </Router>
    );
  }

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return oktaAuth ? (
    <Router history={history}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
        <LoginHandler />
        <Switch>
          <Route path={Routes.ROOT + Routes.LOGIN + Routes.CALLBACK} component={LoginCallback} />
          <Route path={Routes.ROOT + Routes.LOGIN}>
            <Login />
          </Route>
          <Route path={"/"} component={SecuredRouter} />
        </Switch>
      </Security>
    </Router>
  ) : null;
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  hasServerError: selectServerError
});

const mapDispatchToProps = (dispatch) => ({
  setTableColumns: (columns) => dispatch(ACTIONS.setTableColumns(columns))
});

const MainRouter = connect(mapStateToProps, mapDispatchToProps)(MainRouterComponent);

export { MainRouter };
