import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { Divider, Space } from "antd";

import { selectSummaryCounters } from "../store/selector";
import { ReactComponent as IconAllPatients } from "../assets/svg/icon-all-patients.svg";
import { ReactComponent as IconVisitSchedule } from "../assets/svg/icon-visit-schedule.svg";
import { ReactComponent as IconSavedList } from "../assets/svg/icon-saved-list.svg";
import { ReactComponent as IconAllApplications } from "../assets/svg/icon-all-applications.svg";
import { ReactComponent as IconAllClaims } from "../assets/svg/icon-all-claims.svg";
import { DashboardSummaryBox } from "./DashboardSummaryBox/DashboardSummaryBox";
import { selectedViews } from "../constant/table";
import { useGetSetting } from "../hooks/getSetting";
import { withTranslation } from "react-i18next";
import { DARK_BLACK } from "../constant/colors";
const DashboardSummaryComponent = ({ t, summaryCounters }) => {
  const dashboardSummaryBoxes = {
    allPatients: {
      id: selectedViews.allPatients,
      title: t("main_blocks.all_patients"),
      Icon: IconAllPatients,
      counter: null
    },
    visitSchedule: {
      id: selectedViews.visitSchedule,
      title: t("main_blocks.visit_schedule"),
      Icon: IconVisitSchedule,
      counter: null
    },
    pendingRenewal: {
      id: selectedViews.pendingRenewal,
      title: t("main_blocks.pending_renewal"),
      Icon: null,
      counter: summaryCounters && summaryCounters[selectedViews.pendingRenewal]
    },
    highPriority: {
      id: selectedViews.highPriority,
      title: t("main_blocks.high_priority"),
      Icon: null,
      counter: summaryCounters && summaryCounters[selectedViews.topOpportunity]
    },
    savedList: {
      id: selectedViews.savedList,
      title: t("main_blocks.saved_list"),
      Icon: IconSavedList,
      counter: summaryCounters && summaryCounters[selectedViews.savedList]
    },
    allApplications: {
      id: selectedViews.allApplications,
      title: t("main_blocks.all_applications"),
      Icon: IconAllApplications,
      counter: null
    },
    allClaims: {
      id: selectedViews.allClaims,
      title: t("main_blocks.all_claims"),
      Icon: IconAllClaims,
      counter: null
    }
  };

  const [displayVisitSchedule, displayPendingRenewal, displayOppsFe, displayAllClaims] = useGetSetting([
    "automation.visit-schedule.fe-display",
    "automation.pending-renewal.fe-display",
    "automation.opportunities.fe-display",
    "fe.show_claims"
  ]);

  return (
    <Space className="small-boxes" style={{ paddingBottom: 24 }} size={16}>
      <DashboardSummaryBox boxData={dashboardSummaryBoxes.allPatients} />
      {displayVisitSchedule && <DashboardSummaryBox boxData={dashboardSummaryBoxes.visitSchedule} />}
      <Divider
        type="vertical"
        style={{ top: "unset", height: "64px", alignSelf: "center", backgroundColor: DARK_BLACK, margin: "0px" }}
      />

      <DashboardSummaryBox boxData={dashboardSummaryBoxes.allApplications} />
      {displayPendingRenewal && (
        <DashboardSummaryBox
          boxData={dashboardSummaryBoxes.pendingRenewal}
          counter={dashboardSummaryBoxes.pendingRenewal.counter}
        />
      )}
      {displayAllClaims && <DashboardSummaryBox boxData={dashboardSummaryBoxes.allClaims} />}
      <Divider
        type="vertical"
        style={{ top: "unset", height: "64px", alignSelf: "center", backgroundColor: DARK_BLACK, margin: "0px" }}
      />

      {displayOppsFe && (
        <DashboardSummaryBox
          boxData={dashboardSummaryBoxes.highPriority}
          counter={dashboardSummaryBoxes.highPriority.counter}
        />
      )}
      {displayOppsFe && (
        <Divider
          type="vertical"
          style={{ top: "unset", height: "64px", alignSelf: "center", backgroundColor: DARK_BLACK, margin: "0px" }}
        />
      )}
      <DashboardSummaryBox
        boxData={dashboardSummaryBoxes.savedList}
        counter={dashboardSummaryBoxes.savedList.counter}
      />
    </Space>
  );
};

const mapStateToProps = createStructuredSelector({
  summaryCounters: selectSummaryCounters
});

const DashboardSummary = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, null)
)(DashboardSummaryComponent);

export { DashboardSummary };
