import styled from "styled-components";

import { Table as AntdTable } from "antd";
import { font12pxSB } from "../Typography";

const ManagePatientRoundCornersTable = styled(AntdTable)`
  .conflicted:hover {
    background-color: #fef3f2;
  }
  .ant-table-content {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    border: 1px solid #e4e7ec;
  }
  .ant-table-row > tr:hover {
    background-color: #f9fafb;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  .ant-table-thead > tr > th {
    ${font12pxSB}
    background: #f9fafb;
    color: #667085;
  }
  .ant-table-tbody > tr > td:first-child {
    .anticon svg {
      padding: 2px;
      color: #f04438;
      background: #fee4e2;
      border-radius: 32px;
    }
  }
  .ant-table-cell:before {
    display: none;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
  }
`;

export { ManagePatientRoundCornersTable };
