export const APPLICATIONS = {
  //columns
  name: "displayName",
  id: "id",
  applicationId: "applicationId",
  status: "status",
  approvedDate: "approvedDate",
  valid_through: "validThrough",
  status_changed: "statusChanged",
  actions: "actions",
  claimAmounts: "claimAmounts",
  claimActions: "claimActions",

  // status
  statuses: {
    prepare: "prepare",
    applied: "applied",
    approved: "approved",
    canceled: "canceled",
    expired: "expired",
    appliedToWaitlist: "applied to waitlist",
    rejected: "rejected",
    ineligible: "ineligible",
    notInterested: "not-interested",
    notReturned: "not-returned",
    discarded: "discarded",
    exhausted: "exhausted",
    missingInformation: "missing information",
    temporarilyApproved: "temporarily approved"
  },
  displayStatus: {
    associated: "associated",
    notRelevant: "not-relevant"
  },
  autoFill: {
    keysFormItem: {
      patient: "patient",
      otherPhysician: "otherPhysician",
      singleFinantialNavigationTeam: "singleFinantialNavigationTeam",
      finantialNavigationTeam: "finantialNavigationTeam"
    },
    patientAddress: "Patient address",
    physician: "physician",
    single_physician: "Physician address",
    choose_physician: "choose a physician in the physician information section",
    multiple_physician: "Physician address - multiple associated physicians",
    choosePhysician: "Choose physician from the list",
    fn_team: "FN team",
    multiple_fn_team: "FN team - multiple FN team",
    other_add_details: "Other - add details",
    other: "other",
    coverage: {
      primary: "primary",
      secondary: "secondary",
      rx: "rx"
    },
    FN: {
      multiple: "multiple",
      single: "single"
    }
  },
  pharmacyAccess: {
    default: "Default",
    upsell: "Upsell",
    none: "None"
  }
};
