import React from "react";
import { ThemeProvider as RawThemeProvider } from "styled-components";

const theme = {
  dimensions: {
    width: "1216px"
  },
  ".ant-popover-inner-content": {
    padding: "0"
  }
};

export const ThemeProvider = (props) => <RawThemeProvider {...props} theme={theme} />;
