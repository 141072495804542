import _ from "lodash";
import styled from "styled-components";
import React, { useContext } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { CloudDownloadOutlined } from "@ant-design/icons";

import { ConflictPopover } from "./ConflictPopover";
import { ManagePatientDetailsContext } from "./context";

const ConflictIndicatorWrapper = styled.div`
  position: absolute;
  z-index: 1;
  height: 20px;
  top: 36px;
  right: 10px;
  color: #f04438;
  background: #fee4e2;
  border-radius: 32px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  padding: 4px;
  .anticon svg {
    color: #f04438;
  }
`;

const FormItemStyled = styled(Form.Item)``;

const ConflictIndicator = ({ conflict, onClickReplace, onClickIgnore, ShowIconOnly }) => {
  const { t } = useTranslation();
  return (
    <ConflictPopover
      placement="bottomRight"
      conflict={conflict}
      onClickReplace={onClickReplace}
      onClickIgnore={onClickIgnore}
    >
      <ConflictIndicatorWrapper>
        <CloudDownloadOutlined />
        {!ShowIconOnly && t("conflicts")}
      </ConflictIndicatorWrapper>
    </ConflictPopover>
  );
};

const DemographicConflictFormItem = ({ name, children, ...props }) => {
  const { demographicsFormData, replaceDemographicsPendingConflict, ignoreDemographicsPendingConflict } =
    useContext(ManagePatientDetailsContext);
  const conflict = demographicsFormData.conflicts[name];
  const ShowIconOnly = _.includes(["gender"], name);
  return (
    <>
      <FormItemStyled name={name} {...props}>
        {React.cloneElement(children, { disabled: !!conflict })}
      </FormItemStyled>
      {conflict && (
        <ConflictIndicator
          conflict={conflict}
          onClickReplace={() => replaceDemographicsPendingConflict(name)}
          onClickIgnore={() => ignoreDemographicsPendingConflict(name)}
          ShowIconOnly={ShowIconOnly}
        />
      )}
    </>
  );
};
export { DemographicConflictFormItem };
