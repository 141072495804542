export const administrationSites = [{ name: "Outpatient" }, { name: "Clinic" }, { name: "Self" }];
export const ivAdminTypes = [
  "iv",
  "intradermal; subcutaneous",
  "percutaneous; subcutaneous",
  "sc",
  "percutaneous",
  "cutaneous; intradermal; subcutaneous",
  "im",
  "transdermal",
  "intradermal; percutaneous; subcutaneous",
  "intravenous",
  "intramuscular"
];

export const TM_ROUTE_TYPES = {
  IV: "IV",
  NON_IV: "Non-IV",
  BOTH: "Both"
};

export const TM_IV_ROUTE_TYPES = [TM_ROUTE_TYPES.IV, TM_ROUTE_TYPES.BOTH];

export const BRANDED_TERM_TYPES = {
  BN: "BN",
  SBDF: "SBDF",
  SBDC: "SBDC",
  SBD: "SBD"
};

export const NOT_BRANDED_TERM_TYPES = {
  PIN: "PIN",
  SCDF: "SCDF",
  SCDC: "SCDC",
  SCD: "SCD"
};

export const TERM_TYPES = {
  ...NOT_BRANDED_TERM_TYPES,
  ...BRANDED_TERM_TYPES
};
