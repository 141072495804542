import React from "react";

export default function DrugsIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.625 0.375H9.375" stroke="#667085" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.125 1.875V4.125C3.32935 4.125 2.56629 4.44107 2.00368 5.00368C1.44107 5.56629 1.125 6.32935 1.125 7.125V10.875C1.125 11.0739 1.20402 11.2647 1.34467 11.4053C1.48532 11.546 1.67609 11.625 1.875 11.625H10.125C10.3239 11.625 10.5147 11.546 10.6553 11.4053C10.796 11.2647 10.875 11.0739 10.875 10.875V7.125C10.875 6.32935 10.5589 5.56629 9.99632 5.00368C9.43371 4.44107 8.67065 4.125 7.875 4.125V1.875"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 5.625V9.375" stroke="#667085" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.125 7.5H7.875" stroke="#667085" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
