import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { Button, Spin, Tooltip, Typography, Space, Popover } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { displayedMRN } from "@tailormed/common-client/util/mrn";

import { Routes } from "../../constant/routes";
import { useGetSetting } from "../../hooks/getSetting";
import { fontWeights, sizes } from "../../constant/styles";
import {
  SEC_FONT_GREY,
  WHITE,
  DARK_GRAY3,
  BLACK,
  SUCCESS_50,
  ERROR_50,
  PRIMARY_50,
  GRAY_100,
  SUCCESS_600,
  ERROR_600,
  PRIMARY_600,
  GRAY_600,
  GRAY_700,
  GRAY_400,
  WARNING_50,
  WARNING_600
} from "../../constant/colors";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";
import { starPatient, starFilterList } from "../../api/api";
import editPencilIcon from "../../assets/svg/edit-pencil-icon.svg";
import ActiveStarIcon from "../../assets/svg/ActiveStarIcon";
import InActiveStarIcon from "../../assets/svg/InActiveStarIcon";
import { ReactComponent as AlertTrimmed } from "../../assets/svg/alert-circle-trimmed.svg";

import { ReactComponent as LockList } from "../../assets/svg/lock-list.svg";
import DrugsIcon from "../../assets/svg/DrugsIcon";
import { selectedFilterListType, selectedViewTableData, selectPatientDetails, selectUser } from "../../store/selector";
import { ReactComponent as HasConflictIcon } from "../../assets/svg/has-conflicts-icon.svg";
import { maxNumberOfRecords, selectedViewTypes } from "../../constant/table";
import { capitalizeEachFirstLetter } from "../../utils/string";
import ACTIONS from "../../store/action";

import { filtersTypes } from "../../constant/savedList";
import { TitledTooltip } from "../TitledTooltip";
import { SORT_DIRECTIONS_DEFAULT } from "../../constant/sort";
import { customerTypes } from "../../constant/customerType";
import { formatPhoneNumber } from "../../utils/formaters";
import { DENIED, PAID, PENDING, SUBMITTED, NEW_PENDING_PAYMENT, OOP, statuses } from "../../constant/claim";
import { dateFormatter } from "../../utils/date";
const { Text, Paragraph } = Typography;

export const HasConflictsColumn = ({ record }) => {
  const { t } = useTranslation();

  const hasConflicts = false;
  //record.hasDemographicsConflicts || record.hasDiagnosesConflicts || record.hasInsuranceConflicts;
  const history = useHistory();
  const { patientId, journeyId } = record;

  return (
    <div style={{ paddingTop: "8px", display: "flex", flexDirection: "column", gap: "5px" }}>
      {hasConflicts && (
        <Tooltip title={<Text>{t("view_conflicts")}</Text>} color={WHITE}>
          <div onClick={() => history.push(`/${Routes.PATIENT}${patientId}/journey/${journeyId}/edit`)}>
            <HasConflictIcon id={`patient-has-conflicts-${patientId}`} style={{ height: "17", width: "17" }} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const EditColumn = ({ record, isHovering }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { patientId, journeyId } = record;

  return (
    <>
      {isHovering && (
        <Button
          id="patient_list_edit_patient"
          type="text"
          style={{ padding: 0 }}
          onClick={() =>
            history.push(`/${Routes.PATIENT}${patientId}/journey/${journeyId}/edit`, { path: location.pathname })
          }
        >
          <Tooltip title={<Text>{t("edit_patient_details")}</Text>} color={WHITE}>
            <img src={editPencilIcon} alt="edit pencil icon" />
          </Tooltip>
        </Button>
      )}
    </>
  );
};

export const PatientNameColumn = ({ record }) => {
  const { t } = useTranslation();
  const [prefix, customerType] = useGetSetting(["id_prefix", "fe-customer-type"]);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      <Text
        style={{
          fontWeight: fontWeights.semibold,
          fontSize: sizes.large
        }}
      >
        {record.name ? record.name : "Patient"}
      </Text>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Text style={{ fontSize: sizes.small, fontWeight: fontWeights.regular }}>{`(${
          prefix ? prefix.toUpperCase() : "TM"
        }-${record.patientId})`}</Text>
        {displayedMRN(record.mrn) && (
          <Text style={{ fontSize: sizes.medium, color: SEC_FONT_GREY }}>
            {customerType.toLowerCase() === customerTypes.RETAIL
              ? t("columns.record_phone", { mrnRecord: formatPhoneNumber(record.mrn) })
              : t("columns.record_mrn", { mrnRecord: displayedMRN(record.mrn) })}
          </Text>
        )}
      </div>
    </div>
  );
};

export const ClaimStatusColumn = ({ status, isActive }) => {
  const statusLabel = statuses.find((item) => item.value === status)?.label;
  return (
    <Text
      style={{
        background:
          status === PAID
            ? SUCCESS_50
            : status === DENIED
            ? ERROR_50
            : status === SUBMITTED
            ? PRIMARY_50
            : status === PENDING
            ? GRAY_100
            : status === NEW_PENDING_PAYMENT
            ? WARNING_50
            : status === OOP
            ? GRAY_100
            : "",
        borderRadius: "16px",
        color:
          status === PAID
            ? SUCCESS_600
            : status === DENIED
            ? ERROR_600
            : status === SUBMITTED
            ? PRIMARY_600
            : status === PENDING
            ? GRAY_600
            : status === NEW_PENDING_PAYMENT
            ? WARNING_600
            : status === OOP
            ? GRAY_600
            : "",
        fontWeight: fontWeights.bold,
        fontSize: sizes.medium,
        display: "flex",
        justifyContent: "center",
        width: status === NEW_PENDING_PAYMENT ? "140px" : status === OOP ? "120px" : "91px",
        opacity: isActive === false && "0.3",
        cursor: "pointer"
      }}
    >
      {status === OOP ? statusLabel : capitalizeEachFirstLetter(statusLabel)}
    </Text>
  );
};

export const AssigneeColumn = ({ record }) => {
  const assigneesTextToDisplay = record.assignees
    .map(({ userName }) => userName)
    .sort()
    .join(" | ");
  return (
    <Tooltip title={<Text>{assigneesTextToDisplay}</Text>} color={WHITE} style={{ cursor: "pointer" }}>
      <Text ellipsis={true} style={{ maxWidth: "200px" }}>
        {assigneesTextToDisplay}
      </Text>
    </Tooltip>
  );
};

export const DateOfServiceColumn = ({ record }) => {
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      {record.minServiceDate === record.maxServiceDate
        ? dateFormatter(record.minServiceDate)
        : `${dateFormatter(record.minServiceDate)} - ${dateFormatter(record.maxServiceDate)}`}
    </div>
  );
};

export const ListNameColumn = ({ record }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const getInvalidMessage = () => {
    const isOwner = user?.id === record?.user;
    if (isOwner) {
      return t("savedList.owner_in_valid_list");
    } else {
      return t("savedList.non_owner_in_valid_list");
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0px" }}>
      <Text style={{ fontWeight: fontWeights.semibold, fontSize: sizes.large }}>
        <Space>
          {record.isPrivate ? (
            <Tooltip
              overlayInnerStyle={{
                backgroundColor: BLACK,
                width: "270px",
                borderRadius: 5,
                padding: "15px 15px 0 15px"
              }}
              title={
                <Space direction="vertical">
                  <Text style={{ color: WHITE, fontWeight: fontWeights.bold }}>
                    {t("savedList.list_name_private_title")}
                  </Text>
                  <Paragraph style={{ color: WHITE }}>{t("savedList.list_name_private_sub_title")}</Paragraph>
                </Space>
              }
            >
              <LockList />
            </Tooltip>
          ) : (
            ""
          )}
          <Text
            style={{
              fontWeight: fontWeights.semibold,
              fontSize: sizes.medium
            }}
          >
            {record.name ? record.name : t("savedList.no_list_name")}
          </Text>
          {!record.isValid && (
            <Tooltip
              overlayInnerStyle={{
                backgroundColor: BLACK,
                borderRadius: 5,
                padding: "15px"
              }}
              overlayStyle={{
                maxWidth: "450px"
              }}
              autoAdjustOverflow={false}
              title={<Text style={{ color: WHITE, fontWeight: fontWeights.medium }}>{getInvalidMessage()}</Text>}
            >
              <div style={{ paddingTop: "1.5px", position: "relative", display: "flex", alignItems: "center" }}>
                <AlertTrimmed />
              </div>
            </Tooltip>
          )}
        </Space>
      </Text>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Text style={{ fontSize: sizes.medium, color: DARK_GRAY3 }}>
          {t("savedList.description", {
            view: selectedViewTypes[record.selectedView].description,
            user: record.username
          })}
        </Text>
      </div>
    </div>
  );
};

export const NumberOfRecordsColumn = ({ record, savedListsRecordCounts }) => {
  const { t } = useTranslation();
  const currentRecord = savedListsRecordCounts[record?.id];

  if (!currentRecord) return <Spin />;

  if (currentRecord.success === false) {
    return <Text> {t("savedList.no_records")} </Text>;
  } else if (currentRecord.success) {
    return (
      <Text>{currentRecord.total > maxNumberOfRecords ? t("savedList.more_than_10000") : currentRecord.total}</Text>
    );
  }

  return null;
};

const StarColumnComponent = ({
  record,
  patientDetails,
  updatePatientDetailsAct,
  setPatientTableRecordDataAct,
  updateSearchedPatientAct
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation();
  const title = t(record.starred ? "unstar" : "star");

  const handleStarPatient = async (patientId, newValue) => {
    try {
      setIsFetching(true);
      await starPatient(patientId, newValue);
      setPatientTableRecordDataAct(patientId, { starred: newValue });
      updateSearchedPatientAct(patientId, { starred: newValue });
      if (patientDetails && patientDetails.id === patientId) {
        updatePatientDetailsAct({ ...patientDetails, starred: newValue });
      }
    } catch (error) {
      ErrorMessage(`${t("errorMessages.patient_starred")} ${error}`);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      {isFetching && <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />}
      {!isFetching && (
        <Button type="text" onClick={() => handleStarPatient(record.patientId, !record.starred)} style={{ padding: 0 }}>
          <Tooltip title={<Text>{t(title)}</Text>} color={WHITE}>
            <div style={{ minWidth: 18, minHeight: 18 }}>
              {record.starred ? <ActiveStarIcon /> : <InActiveStarIcon />}
            </div>
          </Tooltip>
        </Button>
      )}
    </div>
  );
};

const starColumnMapDispatchToProps = (dispatch) => ({
  updatePatientDetailsAct: (patientDetails) => dispatch(ACTIONS.updatePatientDetailsAction(patientDetails)),
  updateSearchedPatientAct: (patientId, sharedFields) =>
    dispatch(ACTIONS.updateSearchedPatient(patientId, sharedFields)),
  setPatientTableRecordDataAct: (patientId, sharedFields) =>
    dispatch(ACTIONS.setPatientTableRecordData(patientId, sharedFields))
});
const starColumnMapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});
const StarColumn = compose(connect(starColumnMapStateToProps, starColumnMapDispatchToProps))(StarColumnComponent);

export { StarColumn };

export const StarFilterListColumnComponent = ({
  setSavedListsTableRecordData,
  selectedListType,
  record,
  page,
  searchSavedListAct
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation();
  const title = t(record.starred ? "unstar" : "star");

  const handleStarFilterList = async (filterListId, newValue) => {
    try {
      setIsFetching(true);
      await starFilterList(filterListId, newValue);
      if (selectedListType === filtersTypes.starred) {
        searchSavedListAct(page);
      } else {
        setSavedListsTableRecordData({ ...record, starred: newValue });
      }
    } catch (error) {
      ErrorMessage(`${t("errorMessages.filterlist_starred")}`);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      {isFetching && <Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />} />}
      {!isFetching && (
        <Button
          type="text"
          id="saved_lists_options_star"
          onClick={() => handleStarFilterList(record.id, !record.starred)}
          style={{ padding: 0 }}
        >
          <Tooltip title={<Text>{t(title)}</Text>} color={WHITE}>
            <div style={{ minWidth: 18, minHeight: 18 }}>
              {record.starred ? <ActiveStarIcon /> : <InActiveStarIcon />}
            </div>
          </Tooltip>
        </Button>
      )}
    </>
  );
};
const starFilterMapStateToProps = createStructuredSelector({
  selectedListType: selectedFilterListType
});

const starFilterMapDispatchToProps = (dispatch) => ({
  searchSavedListAct: (page) => dispatch(ACTIONS.searchSavedListAction(page)),
  setSavedListsTableRecordData: (record) => dispatch(ACTIONS.setSavedListsTableRecordData(record))
});
export const StarFilterListColumn = compose(connect(starFilterMapStateToProps, starFilterMapDispatchToProps))(
  StarFilterListColumnComponent
);

export const AssociatedDrugColumnComponent = ({ record }) => {
  const { t } = useTranslation();

  const associatedDrugs = record.associatedDrugs || "[]";
  if (record?.priority === 1 || record?.priority === 2) {
    return <Text>{record.drugDisplayName}</Text>;
  } else if (record?.priority === 3) {
    const drugs = JSON.parse(associatedDrugs);
    return (
      <>
        {drugs.length === 0 ? (
          ""
        ) : drugs.length === 1 ? (
          drugs[0]
        ) : (
          <Popover
            trigger="hover"
            content={
              drugs?.length && (
                <div style={{ maxWidth: "400px", padding: "0 10px" }}>
                  <p>
                    {drugs
                      .slice(0, 50)
                      .sort((a, b) => a.localeCompare(b))
                      .join(", ")}
                    {drugs?.length > 50 && <span> ...</span>}
                  </p>
                </div>
              )
            }
          >
            <Text style={{ marginRight: "auto" }}>
              <DrugsIcon /> {JSON.parse(associatedDrugs)?.length} {t("Drugs")}
            </Text>
          </Popover>
        )}
      </>
    );
  } else {
    return <Text></Text>;
  }
};

export const ClaimMedicationsColumnComponent = ({ record }) => {
  const { t } = useTranslation();
  const medications = record.claimMedications?.split(",") || [];

  return (
    <div style={{ textAlign: "center" }}>
      {medications.length === 0 ? (
        ""
      ) : medications.length === 1 ? (
        medications[0]
      ) : (
        <Popover
          trigger="hover"
          content={
            medications.length && (
              <div style={{ maxWidth: "400px", padding: "0 10px" }}>
                <Text>
                  {medications.slice(0, 50).join(", ")}
                  {medications.length > 50 && <span> ...</span>}
                </Text>
              </div>
            )
          }
        >
          <Text style={{ marginRight: "auto" }}>
            <DrugsIcon /> {medications.length} {t("Drugs")}
          </Text>
        </Popover>
      )}
    </div>
  );
};

export const withSorting = (sortingEnabled, tableColumn, rest, sortDirection) => {
  const [threshold] = useGetSetting(["dashboard.sorting-threshold"]);
  const { t } = useTranslation();
  const selectedViewData = useSelector(selectedViewTableData);
  const { sorter } = selectedViewData;
  const sortingTooltipTitle = threshold && t("sortInfo", { threshold: threshold / 1000 });
  return {
    sorter: sortingEnabled,
    sortDirections: sortDirection ? sortDirection : SORT_DIRECTIONS_DEFAULT,
    ...tableColumn,
    title: () => (
      <div style={{ display: "flex", gap: "5px" }} id={`columnـ${tableColumn.dataIndex}`}>
        <Text style={{ color: GRAY_700 }}>{tableColumn.title}</Text>
        <div>
          {!sortingEnabled && (
            <TitledTooltip text={sortingTooltipTitle}>
              <ExclamationCircleOutlined style={{ fontSize: "12px", alignSelf: "center", color: GRAY_400 }} />
            </TitledTooltip>
          )}
        </div>
      </div>
    ),
    ...rest,
    defaultSortOrder: sorter?.column && sorter?.column?.key === tableColumn.key && sorter?.order
  };
};
