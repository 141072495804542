import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import styled from "styled-components";

import { SupportEmail } from "../../../PatientApplications/enrollTab/utils/components";
import { GRAY_500 } from "../../../../../constant/colors";
import { getExpressEnrollmentMapping } from "../../configurations";
import { fontWeights, sizes } from "../../../../../constant/styles";

const { Text } = Typography;
const TextStyled = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${GRAY_500};
  margin-bottom: 10px;
`;

const GeneralErrorSupportLink = () => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "40px", display: "inlineBlock" }}>
      <TextStyled>{t("patientApplications.enroll.enroll_result.contact_support_with_error")}</TextStyled>
      <SupportEmail />
      <TextStyled>{t("patientApplications.enroll.enroll_result.dont_forget_to_include_error")}</TextStyled>
    </div>
  );
};

export const EnrollGeneralFailure = ({ error }) => {
  const { t } = useTranslation();
  const expressEnrollMapping = getExpressEnrollmentMapping();
  const errorText = expressEnrollMapping?.getErrorText?.(t, error);

  return (
    <>
      <TextStyled>
        <span
          style={{
            fontWeight: fontWeights.semibold,
            textAlign: "center"
          }}
        >
          {t("patientApplications.enroll.enroll_result.failure_reason")}
          {error}
        </span>
      </TextStyled>
      <div style={{ fontWeight: fontWeights.regular, fontSize: sizes.medium_large, color: GRAY_500 }}>
        {errorText || t("patientApplications.enroll.enroll_result.please_try_to_resubmit_the_application")}
      </div>
      <GeneralErrorSupportLink />
    </>
  );
};
