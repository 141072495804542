import { formSections } from "./sections";

export const commonLabels = {
  [formSections.PATIENT_SECTION]: {
    firstName: "application_form_editor.common.patient.labels.first_name",
    firstNamePlaceholder: "application_form_editor.common.patient.placeholders.first_name",
    lastName: "application_form_editor.common.patient.labels.last_name",
    lastNamePlaceholder: "application_form_editor.common.patient.placeholders.last_name",
    dob: "application_form_editor.common.patient.labels.dob",
    gender: "application_form_editor.common.patient.labels.gender",
    address: "application_form_editor.common.patient.labels.street_address",
    addressPlaceholder: "application_form_editor.common.patient.placeholders.street_address",
    city: "application_form_editor.common.patient.labels.city",
    cityPlaceholder: "application_form_editor.common.patient.placeholders.city",
    zipCode: "application_form_editor.common.patient.labels.zip_code",
    zipCodePlaceholder: "application_form_editor.common.patient.placeholders.zip_code",
    state: "application_form_editor.common.patient.labels.state",
    statePlaceholder: "application_form_editor.common.patient.placeholders.state",
    phoneNumber: "application_form_editor.common.patient.labels.phone_number",
    phoneNumberPlaceholder: "application_form_editor.common.patient.placeholders.phone_number",
    additionalPhoneNumber: "application_form_editor.common.patient.labels.additional_phone_number",
    additionalPhoneNumberPlaceholder: "application_form_editor.common.patient.placeholders.additional_phone_number",
    email: "application_form_editor.common.patient.labels.email",
    emailPlaceholder: "application_form_editor.common.patient.placeholders.email",
    preferredLanguage: "application_form_editor.common.patient.labels.preferred_language",
    preferredLanguagePlaceholder: "application_form_editor.common.patient.placeholders.preferred_language",
    alternativeContact: "application_form_editor.common.patient.labels.alternative_contact"
  },
  [formSections.INSURANCE_SECTION]: {
    isInsured: "application_form_editor.common.insurance.labels.is_insured",
    primaryPayerName: "application_form_editor.common.insurance.labels.primary_payer_name",
    secondaryPayerName: "application_form_editor.common.insurance.labels.secondary_payer_name",
    payerNamePlaceholder: "application_form_editor.common.insurance.placeholders.payer_name",
    uninsuredAlreadyChosen: "application_form_editor.common.insurance.placeholders.uninsured_already_chosen",
    insuredAlreadyChosen: "application_form_editor.common.insurance.placeholders.insured_already_chosen"
  },
  [formSections.PRACTICE_SECTION]: {
    prescriberName: "application_form_editor.common.practice.labels.prescriber_name",
    prescriberNamePlaceholder: "application_form_editor.common.practice.placeholders.prescriber_name",
    prescriberFirstName: "application_form_editor.common.practice.labels.prescriber_first_name",
    prescriberLastName: "application_form_editor.common.practice.labels.prescriber_last_name",
    prescriberFirstNamePlaceholder: "application_form_editor.common.practice.placeholders.prescriber_first_name",
    prescriberLastNamePlaceholder: "application_form_editor.common.practice.placeholders.prescriber_last_name",
    prescriberNpi: "application_form_editor.common.practice.labels.prescriber_npi",
    npiPlaceholder: "application_form_editor.common.practice.placeholders.npi",
    groupTaxId: "application_form_editor.common.practice.labels.group_tax_id",
    groupTaxIdPlaceholder: "application_form_editor.common.practice.placeholders.group_tax_id"
  }
};
