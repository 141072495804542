import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { Space, Table, Typography } from "antd";
import { withTranslation } from "react-i18next";
import moment from "moment-timezone";
import { withRouter } from "react-router";
import styled from "styled-components";

import { selectTableColumns, selectIsFetching, selectHighPrioritiesData } from "../../store/selector";
import { ApiNames } from "../../api/api";
import { ReactComponent as NoPatientsFoundIcon } from "../../assets/svg/NoPatientsFoundIcon.svg";
import ACTION from "../../store/action";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import { ColumnSelector } from "../ColumnSelector";
import { EditColumn, PatientNameColumn, StarColumn, withSorting, AssigneeColumn } from "./CommonColumns";
import PatientPageCount from "./PatientPageCount";
import { TabsMap } from "../../screen/patient/Patient";
import { commonTableStyle } from "./TableStyle";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { dateFormatter, DATE_FORMAT } from "../../utils/date";
import { formatPhoneNumber, formatSSN } from "../../utils/formaters";
import { DeceasedLable } from "../DeceasedLable";

const { Text } = Typography;
const StyledTable = styled(Table)`
  ${commonTableStyle}
`;

const CentralizedText = styled.div`
  text-align: center;
`;

const HighPrioritiesTableComponent = ({
  tableColumns,
  isFetching,
  setTableDataAct,
  history,
  tableData,
  t,
  isFilterOpen,
  updateSavedFilterViewParameters
}) => {
  const [hoveringLineIndex, setHoveringLineIndex] = useState(null);

  const onClickTable = () => ({
    onCell: (patient) => ({
      onClick: () => {
        const { patientId, journeyId } = patient;
        history.push(TabsMap.optimizations.path(patientId, journeyId));
      }
    })
  });

  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;

  const fetchData = (pagination, filters, sorter) => {
    if ((!tableData?.sorter?.columnKey && sorter.columnKey) || sorter.columnKey !== tableData?.sorter?.columnKey) {
      // Note: if user try sorting another column. fetch the first page data.
      pagination.current = 1;
    }

    setTableDataAct(pagination.current, sorter);
    if (updateSavedFilterViewParameters) {
      // Note: We need to maintain the last visited paging & sorting for each saved filter view.
      updateSavedFilterViewParameters(pagination.current, sorter);
    }

    window.scrollTo(0, 0);
  };

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.highPriority]?.star?.dataIndex,
      key: tableColumns[selectedViews.highPriority]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.name_id, {
      showSorterTooltip: {
        title: t("columns.sortById")
      },
      render: (text, record) => <PatientNameColumn record={record} />,
      minWidth: 300,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => (record.isDeceased ? <DeceasedLable /> : ""),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.highPriority]?.phone_number,
      render: (text, record) => formatPhoneNumber(record.phone_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.highPriority]?.dob,
      render: (text, record) => {
        if (!record.dob || record.dob === t("invalid_date")) {
          return "";
        } else {
          const dob = moment(record.dob);
          if (dob.isValid()) {
            return dob.format(DATE_FORMAT);
          } else {
            return "";
          }
        }
      },
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.highPriority]?.ssn,
      render: (text, record) => formatSSN(record.social_security_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.highPriority]?.assignee,
      render: (text, record) => <AssigneeColumn record={record} />,
      width: 230,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.facility, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.physician, {
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.highPriority]?.employer,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.creationDate, {
      render: (text, record) => moment(record.creationDate).format("MMM D, YYYY"),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.highPriority]?.primaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.primary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.highPriority]?.secondaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.secondary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.highPriority]?.tertiaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.tertiary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.highPriority]?.agreementSignatureDate,
      render: (text, record) =>
        record.agreement_signature_date && (
          <CentralizedText>{dateFormatter(record.agreement_signature_date)}</CentralizedText>
        )
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.patientStatus, {
      render: (_, record) => <PatientStatusCell value={record.status} />,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.highPriority]?.followupDate, {
      render: (text, record) =>
        record.followupDate && <CentralizedText>{dateFormatter(record.followupDate)}</CentralizedText>
    }),
    {
      dataIndex: tableColumns[selectedViews.highPriority]?.edit?.dataIndex,
      key: tableColumns[selectedViews.highPriority]?.edit?.key,
      width: 35,
      render: (_, record, rowIndex) => <EditColumn isHovering={hoveringLineIndex === rowIndex} record={record} />
    }
  ];

  return (
    <StyledTable
      size="small"
      rowClassName="cursor-pointer"
      style={{ width: isFilterOpen ? "77%" : "100%" }}
      loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
      columns={columns.filter((col) => tableColumns[selectedViews.highPriority]?.[col.key]?.display)}
      locale={{
        emptyText: tableData && tableData.patients && tableData.patients.length == 0 && (
          <Space direction={"vertical"} size={10}>
            <NoPatientsFoundIcon />
            <Text style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.5)", fontSize: "16px" }}>
              {t("no_patients_found")}
            </Text>
          </Space>
        )
      }}
      onRow={(_, rowIndex) => {
        return {
          onMouseEnter: () => setHoveringLineIndex(rowIndex),
          onMouseLeave: () => setHoveringLineIndex(null)
        };
      }}
      onChange={fetchData}
      pagination={{
        size: "small",
        showSizeChanger: false,
        current: tableData && tableData.selectedPage,
        defaultPageSize: PAGE_SIZE,
        pageSize: PAGE_SIZE,
        position: ["topRight"],
        total: totalRecordsCount,
        showTotal: (total, range) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ColumnSelector />
            <PatientPageCount total={total} range={range} isAccurateCount={tableData.isAccurateCount} />
          </div>
        )
      }}
      dataSource={
        tableData && tableData.patients
          ? tableData.patients
              .map((patient, index) => {
                return {
                  ...patient,
                  key: index
                };
              })
              .sort((a, b) => b.isActive - a.isActive)
          : null
      }
    />
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  isFetching: selectIsFetching([ApiNames.HighPrioritiesPhiSearch, ApiNames.HighPrioritiesSearch]),
  tableData: selectHighPrioritiesData
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.highPriority, sorter))
});

const HighPrioritiesTable = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(HighPrioritiesTableComponent);

export { HighPrioritiesTable };
