import React from "react";

const TreatmentIcon = ({ style = {} }) => {
  return (
    <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_722_28099)">
        <path
          style={style}
          d="M6.5 4.5V1.5H10.5V4.5"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          style={style}
          d="M14.5 4.5H2.5C1.94772 4.5 1.5 4.94772 1.5 5.5V13.5C1.5 14.0523 1.94772 14.5 2.5 14.5H14.5C15.0523 14.5 15.5 14.0523 15.5 13.5V5.5C15.5 4.94772 15.0523 4.5 14.5 4.5Z"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 7.5V11.5"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={style}
        />
        <path
          d="M6.5 9.5H10.5"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={style}
        />
      </g>
      <defs>
        <clipPath id="clip0_722_28099">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TreatmentIcon;
