import { put, select } from "redux-saga/effects";

import { selectApplicationClaims, selectCurrentApplication, selectPatientApplications, selectUser } from "../selector";
import { calculateTotalPaidAmount, calculateTotalPendingAmount, findLatestClaimDate } from "../../utils/claims";
import ACTION from "../action";
import { selectedViews } from "../../constant/table";
import { setTableDataAction } from "./sagaActions";
import { DENIED, NEW_PENDING_PAYMENT, PAID, PENDING, SUBMITTED } from "../../constant/claim";
import { formatAssignee } from "../../utils/formaters";
import { APPLICATIONS } from "../../constant/applications";

export function* refreshClaimsTables(claim, attachmentCount, notesCount) {
  // after any change on claims (creat/update/delete/restore/duplicate), this function will reflect the changes
  // on application claims table, all claims bucket, and related amounts (available funds) on application

  const applicationId = claim.assistanceProgramApplication || claim.assistanceprogramapplication;
  claim.assistanceProgramApplication = applicationId;
  const { claims: applicationClaims } = yield select(selectApplicationClaims);
  const currentApplication = yield select(selectCurrentApplication);
  const currentUser = yield select(selectUser);
  const applicationClaimIndex = applicationClaims.findIndex((appClaim) => appClaim.id === claim.id);
  const { activeApplications, inActiveApplications } = yield select(selectPatientApplications);
  const applicationFromPatientApps = [...activeApplications, ...inActiveApplications].find(
    (item) => item.id === parseInt(applicationId)
  );
  const isWithinActiveApps = activeApplications.find((item) => item.id === parseInt(applicationId));

  if (!claim.claimLineItems) {
    claim.claimLineItems = [];
  }
  // update claim amounts if claimLineItems were changed.
  claim.totalClaimsAmount = claim.claimLineItems?.reduce(
    (accumulator, lineItem) => accumulator + (lineItem?.amount || 0),
    0
  );
  claim.balance =
    claim.totalClaimsAmount -
    (claim.paidAmount || 0) -
    (claim.deniedAmount || 0) -
    (claim.patientResponsibilityAmount || 0);

  if ([PENDING, SUBMITTED, NEW_PENDING_PAYMENT].includes(claim.status)) {
    claim.pendingAmount = claim.isActive ? claim.totalClaimsAmount : 0;
  } else if ([PAID, DENIED].includes(claim.status)) {
    claim.activePaidAmount = claim.isActive ? claim.paidAmount : 0;
  }
  claim.updatedBy = formatAssignee(currentUser);
  claim.notesCount = notesCount;
  claim.attachmentCount = attachmentCount;

  // update claim data in application claims list
  if (applicationClaimIndex !== -1) {
    applicationClaims[applicationClaimIndex] = claim;
  } else {
    applicationClaims.unshift(claim);
  }

  // calculate Amounts after changes on application claims
  const totalPendingAmount = calculateTotalPendingAmount(applicationClaims);
  const totalPaidAmount = calculateTotalPaidAmount(applicationClaims);

  // Update Latest Claim Date for related Applications in All Applications and Pending Renewals
  const latestClaimDate = findLatestClaimDate(applicationClaims);
  const fundBalance = currentApplication.approvedAmount - totalPaidAmount;

  if (applicationFromPatientApps) {
    applicationFromPatientApps.totalPaidAmount = totalPaidAmount;
    applicationFromPatientApps.totalPendingAmount = totalPendingAmount;
    applicationFromPatientApps.fundBalance = fundBalance;
    applicationFromPatientApps.status =
      fundBalance <= 0 ? APPLICATIONS.statuses.exhausted : APPLICATIONS.statuses.approved;

    applicationFromPatientApps.hasClaims = applicationClaims?.filter((claim) => claim.isActive).length > 0;
  }

  const isActiveStatus = [
    APPLICATIONS.statuses.applied,
    APPLICATIONS.statuses.appliedToWaitlist,
    APPLICATIONS.statuses.approved,
    APPLICATIONS.statuses.prepare
  ].includes(applicationFromPatientApps.status);

  // maintain inactive and active applications tables.
  if (!isActiveStatus && isWithinActiveApps) {
    inActiveApplications?.unshift(applicationFromPatientApps);

    const index = activeApplications?.findIndex((item) => item.id === parseInt(applicationId));
    index > -1 && activeApplications?.splice(index, 1);
  } else if (isActiveStatus && !isWithinActiveApps) {
    activeApplications?.unshift(applicationFromPatientApps);

    const index = inActiveApplications?.findIndex((item) => item.id === parseInt(applicationId));
    index > -1 && inActiveApplications?.splice(index, 1);
  }

  // update claims application table
  yield put(ACTION.setApplicationClaims(applicationClaims));

  // update related/current application data
  yield put(
    ACTION.setCurrentApplication({
      ...currentApplication,
      latestClaimDate,
      totalPaidAmount: totalPaidAmount,
      totalPendingAmount: totalPendingAmount,
      fundBalance: currentApplication.approvedAmount - totalPaidAmount,
      status: fundBalance <= 0 ? APPLICATIONS.statuses.exhausted : APPLICATIONS.statuses.approved
    })
  );

  // refresh all claims table(bucket)
  yield setTableDataAction({ page: 1, selectedView: selectedViews.allClaims });
  yield setTableDataAction({ page: 1, selectedView: selectedViews.pendingRenewal });
  yield setTableDataAction({ page: 1, selectedView: selectedViews.allApplications });
  yield put(ACTION.getSummaryCounters());
}
