import React from "react";
import { useTranslation } from "react-i18next";
import { fontWeights, sizes } from "../../constant/styles";
import { ReactComponent as CircledElectricityIcon } from "../../assets/svg/circledElectricityIcon.svg";
import { EEsignatureStatuses } from "../../constant/EEsignatureStatuses";
import { FileLink, SignatureStatus, Gray500Divider } from "../Patient/PatientApplications/enrollTab/utils/components";
import { ApiBaseUrl } from "../../api/api";
import { BigIconModal } from "./BigIconModal";

const ATTACHMENT_NAME_MAX_LENGTH_TO_DISPLAY = 48;

export const AttachmentModal = ({
  visible = true,
  title,
  description,
  applicationFormFileName,
  applicationFormAttachmentId,
  children
}) => {
  const { t } = useTranslation();

  return (
    <BigIconModal icon={<CircledElectricityIcon />} title={title} description={description} visible={visible}>
      <Gray500Divider style={{ fontSize: sizes.small, fontWeight: fontWeights.semibold }}>
        {t("patientApplications.enroll.application_form_submission.submit_modal.application_form_divider")}
      </Gray500Divider>
      <div style={{ display: "flex", justifyContent: "start" }}>
        <FileLink
          fileName={applicationFormFileName}
          href={`${ApiBaseUrl}applicationformattachments/view/${applicationFormAttachmentId}`}
          maxLength={ATTACHMENT_NAME_MAX_LENGTH_TO_DISPLAY}
        />
        <SignatureStatus status={EEsignatureStatuses.SIGNED} />
      </div>
      {children}
    </BigIconModal>
  );
};
