import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { Typography, Popover } from "antd";
import { FolderOutlined } from "@ant-design/icons";
import { useParams } from "react-router";

import { Uploader } from "../Uploader";
import { BLUE_DARK } from "../../constant/colors";
import { selectPatientDetails } from "../../store/selector";
import { getPatientAttachmentsCountAPI } from "../../api/api";

const { Text } = Typography;

const PatientAttachmentsMenu = ({ t, patientDetails }) => {
  const [count, setCount] = useState(null);
  const { patientId } = useParams();

  useEffect(() => {
    getAttachmentsCount();
  }, []);

  const getAttachmentsCount = async () => {
    try {
      const res = await getPatientAttachmentsCountAPI(patientId);
      if (res && res.data && res.data.attachmentsCount) {
        setCount(res.data.attachmentsCount);
      }
    } catch (error) {
      console.log(`Get attachments count failed`);
    }
  };

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      style={{ padding: 0 }}
      title={
        <div style={{ backgroundColor: BLUE_DARK, padding: 16 }}>
          <Text style={{ color: "#fff" }}>{`Attachments for ${patientDetails ? patientDetails.name : ""}`}</Text>
        </div>
      }
      content={
        <Uploader
          getAttachmentsCount={getAttachmentsCount}
          showTopLogo={false}
          formStyle={{ border: "none", boxShadow: "none", width: "100%" }}
          wrapperStyle={{ width: "100%" }}
        />
      }
    >
      <div
        id="patient_attachments"
        style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
      >
        <FolderOutlined style={{ fontSize: 16, paddingRight: 3 }} />
        <Text>{`${t("patientRSActions.attachments")}${count ? ` (${count})` : ""}`}</Text>
      </div>
    </Popover>
  );
};

const mapStateToProps = createStructuredSelector({
  patientDetails: selectPatientDetails
});

const Attachments = compose(withTranslation(), connect(mapStateToProps))(PatientAttachmentsMenu);
export { Attachments };
