import React from "react";
import { Trans } from "react-i18next";
import { Typography } from "antd";
import styled from "styled-components";

import { GRAY_500 } from "../../../../../constant/colors";
import { SupportLink } from "../../../PatientApplications/enrollTab/utils/components";

export const EnrollGenentechFailure = () => {
  const { Text } = Typography;

  const TextStyled = styled(Text)`
    font-size: 16px;
    font-weight: 500;
    color: ${GRAY_500};
  `;

  return (
    <>
      <Trans
        i18nKey="patientApplications.enroll.enroll_result.contact_genentech_error"
        components={{
          span: <TextStyled />
        }}
      />

      <SupportLink style={{ marginTop: "40px" }} />
    </>
  );
};
