import { numberWithCommas } from "./string";

export const parseFundsMaxToDisplay = (ap) => {
  const { priority, grantTotalCalcType, fundsMax } = ap;
  const grantTotalCalcTypes = ["noFundsMax", "oopAsFundsMax"];
  let fundsMaxDisplayedValue = "--";

  if (priority !== 2 && !grantTotalCalcTypes.some((type) => type === grantTotalCalcType)) {
    const parsedNumber = numberWithCommas(fundsMax);

    if (parsedNumber) {
      fundsMaxDisplayedValue = `$${parsedNumber}`;
    }
  }

  return fundsMaxDisplayedValue;
};

export const buildOneLineAddress = (parts) => {
  if (!parts) {
    return "";
  }

  return [parts.address, parts.suiteNumber, parts.city, parts.state, parts.zipCode]
    .filter((addressPart) => addressPart)
    .join(", ");
};

export const removeDecimals = (value, precsiton) => {
  const nthDecimalReg = new RegExp(`([.]\\d{${precsiton}}).*`);
  return numberWithCommas(value.replace(nthDecimalReg, "$1"));
};
