import React from "react";
import { Form } from "antd";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { cosentyxController } from "../../cosentyxController";
import { novartisSectionsController } from "../../novartisSectionsController";
import { patientFields } from "../../../../Genentech/freeDrug/form/fieldNames";

export default function NovartisCopayPatient() {
  const patientFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.NOVARTIS_COPAY)().patient;
  const controller = cosentyxController();
  const novartisController = novartisSectionsController();
  const drugList = novartisController.drugsList;
  const associatedDrug = novartisController.associatedDrug;

  const isGenderRequired = [drugList.COSENTYX, drugList.KISQALI, drugList.SCEMBLIX].includes(associatedDrug);
  const form = Form.useFormInstance();
  const patientGender = Form.useWatch(patientFields.gender, form);
  return (
    <SectionPart
      sectionId="patient"
      headerTranslationKey="application_form_editor.novartis.copay.patient.labels.header"
    >
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          patientFormComponents.firstName({
            required: true,
            inputProps: { maxLength: 49 },
            rules: [{ max: 49, message: "" }]
          }),
          patientFormComponents.lastName({
            required: true,
            inputProps: { maxLength: 49 },
            rules: [{ max: 49, message: "" }]
          }),
          patientFormComponents.dob({ required: true, disabled: controller.isCosentyx })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_2_5_1_5}>
        {[
          patientFormComponents.gender({
            required: isGenderRequired,
            minWidth: 115,
            minWidthForAdditionalOptions: 208,
            showError: isGenderRequired && !patientGender
          })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          patientFormComponents.address({
            required: true,
            inputProps: { maxLength: 49 },
            rules: [{ max: 49, message: "" }]
          }),
          patientFormComponents.city({
            required: true,
            inputProps: { maxLength: 30 },
            rules: [{ max: 30, message: "" }]
          }),
          patientFormComponents.state({ required: true })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.EQUAL}>
        {[
          patientFormComponents.zip_code({ required: true }),
          patientFormComponents.email({ required: true, additionalRules: novartisEmailRules }),
          patientFormComponents.phone_number({ required: true })
        ]}
      </ConfigurableRow>
      {controller.isCosentyx && (
        <>
          {controller.getCommonCosentyxRows()}
          {controller.isPatientBelow18 && controller.getBelow18CosentyxRows()}
        </>
      )}
    </SectionPart>
  );
}
export const novartisEmailRules = [
  {
    min: 8,
    message: "Minimum length is 8 characters!"
  },
  {
    max: 100,
    message: "Maximum length is 100 characters!"
  }
];
