import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import { Form } from "antd";

import MedicationForm from "../MedicationForm/MedicationForm";
import { PopupStyledModal } from "../../../customComponentNewDesign/CustomPopupModal";
import { OutlinedButton, PrimaryButton } from "../../../customComponent/Button";
import { getObjectDiff } from "../../../../utils/misc";
import { isRxNormDrugIVAdministered } from "../../../../utils/drugs";
import { searchRxnormDrugsAPI } from "../../../../api/api";

const TREATMENT_TYPE = "manualTreatments";

const Popup = styled(PopupStyledModal)`
  .ant-modal-title {
    margin-bottom: 20px;
  }
  .ant-select-dropdown {
    min-width: 472px !important;
  }
`;

const EditMedicationModal = ({ showPopupRef, updateMedications }) => {
  const { t } = useTranslation();
  const [popupShown, setPopupShown] = useState(false);
  const [currentDrug, setCurrentDrug] = useState();
  const [isFetchingOptions, setIsFetchingOptions] = useState(false);
  const editedDrugOptions = useRef([]);
  const [form] = Form.useForm();

  const showPopup = (showPopup, drug) => {
    setPopupShown(showPopup);
    setCurrentDrug({ ...drug });
    setIsFetchingOptions(true);
  };

  showPopupRef.current = showPopup;

  useEffect(() => {
    async function fetchRxnormDrugsOptionsForSelectedDrug() {
      const { bnCui, pinCui } = currentDrug.rxnormDrug;
      const response = await searchRxnormDrugsAPI({ bnCui, pinCui });
      const rxnormDrugs = response?.data?.rxnormDrugs || [];
      editedDrugOptions.current = rxnormDrugs;
      setIsFetchingOptions(false);
    }

    if (currentDrug) {
      fetchRxnormDrugsOptionsForSelectedDrug();
    }
  }, [currentDrug]);

  const calculateQuantityValueForOralDrug = (quantityValue, frequency) => {
    return quantityValue * parseInt(frequency || 1);
  };

  const calculateTreatmentEndDate = (startDate, frequency, numOfCycles) => {
    return moment(startDate).add(frequency * numOfCycles, "d");
  };

  const onSave = ({ rxnormDrug, medicationData }) => {
    // We should get rid of this shortsighted physician wrapping, but for now,
    // we have to keep working around it
    const currentValues = {
      ...medicationData,
      physician: medicationData.physician
    };

    const previousValues = _.omitBy(
      _.pick(currentDrug, [
        "dosageUnit",
        "dosageValue",
        "frequency",
        "numberOfRefillsAuthorized",
        "numOfCycles",
        "quantityValue",
        "route",
        "startDate",
        "physician",
        "quantity",
        "calculatedDaysBetweenCycle"
      ]),
      _.isNil
    );

    // For non-IV drugs, this is a calculated field. for IV this is not in use
    const ignoredFields = ["quantityValue"];
    let changedFields = [];
    const { tmRouteType } = rxnormDrug;
    if (!isRxNormDrugIVAdministered({ tmRouteType })) {
      const currentQuantityValue = currentValues.quantityValue * parseInt(currentValues.frequency || 1);
      if (currentQuantityValue !== previousValues.quantityValue) {
        changedFields.push("quantityValue");
      }
    }
    changedFields.push(..._.difference(getObjectDiff(previousValues, currentValues), ignoredFields));

    const changedValues = _.pick(currentValues, changedFields);
    const recalculatedValues = isRxNormDrugIVAdministered(rxnormDrug)
      ? getComputedUpdatesForIvDrug(currentDrug, currentValues, changedFields)
      : getComputedUpdatesForNonIvDrug(currentValues, changedFields);

    updateMedications(TREATMENT_TYPE, { ...changedValues, ...recalculatedValues, rxnormDrug }, currentDrug);
    setPopupShown(false);
  };

  function getComputedUpdatesForIvDrug(currentDrug, currentValues, changedFields) {
    if (_.isEmpty(_.intersection(changedFields, ["startDate", "numOfCycles", "quantity"]))) {
      return {};
    }
    return {
      endDate: calculateTreatmentEndDate(
        currentValues.startDate,
        currentValues.quantity,
        currentValues.numOfCycles - 1
      ),
      daysBetweenCycleForIV: changedFields.includes("quantity")
        ? currentValues.quantity
        : currentDrug?.calculatedDaysBetweenCycle
    };
  }

  function getComputedUpdatesForNonIvDrug(currentValues, changedFields) {
    if (
      _.isEmpty(_.intersection(changedFields, ["startDate", "numberOfRefillsAuthorized", "quantityValue", "frequency"]))
    ) {
      return {};
    }
    return {
      quantityValue: calculateQuantityValueForOralDrug(currentValues.quantityValue, currentValues.frequency),
      endDate: calculateTreatmentEndDate(
        currentValues.startDate,
        currentValues.quantityValue,
        currentValues.numberOfRefillsAuthorized
      )
    };
  }

  return (
    <Popup
      destroyOnClose={true}
      open={popupShown}
      title={t("managePatientDetails.medications_section.medication_modal.edit_medication")}
      footer={[
        <OutlinedButton key="cancelButton" onClick={() => setPopupShown(false)}>
          {t("managePatientDetails.medications_section.medication_modal.cancel")}
        </OutlinedButton>,
        <PrimaryButton form="medication_editor" key="submit" htmlType="submit">
          {t("managePatientDetails.medications_section.medication_modal.update")}
        </PrimaryButton>
      ]}
    >
      <MedicationForm
        handleSubmitEvent={onSave}
        drugToEdit={currentDrug}
        editedDrugOptions={editedDrugOptions.current}
        isFetchingOptions={isFetchingOptions}
        form={form}
      />
    </Popup>
  );
};

export default EditMedicationModal;
