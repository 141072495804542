export const sizes = {
  xxlarge: "36px",
  xlarge: "24px",
  medium_xlarge: "20px",
  large: "18px",
  medium_large: "16px",
  medium: "14px",
  small: "12px",
  xsmall: "10px"
};

export const fontWeights = {
  bold: 700,
  semibold: 600,
  medium: 500,
  regular: 400
};
