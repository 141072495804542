import React from "react";
import { Divider as AntdDivider, Dropdown as AntdDropdown, Menu } from "antd";
import styled from "styled-components";
import _ from "lodash";

const Dropdown = styled(AntdDropdown)`
  .ant-dropdown-menu {
    border-radius: 8px;
    padding: 12px 0;
    ${(props) => props.menuWidth && `width: ${props.menuWidth};`}
  }
  .ant-dropdown-menu-item-icon {
    margin-right: 13px;
  }
`;

const Divider = styled(AntdDivider)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const DropdownMenu = ({ menu, children, onClick = () => {}, ...props }) => {
  const overlayMenu = (
    <Menu onClick={({ key }) => onClick(key)}>
      {menu.map(({ text, icon, key, subMenu, subMenuTitle, subMenuItems, divider, id }) => {
        if (divider) {
          return <Divider key={"divider_" + _.uniqueId()} />;
        }

        if (subMenu) {
          return (
            <Menu.SubMenu key={key} icon={icon} title={subMenuTitle}>
              {subMenuItems}
            </Menu.SubMenu>
          );
        }

        return (
          <Menu.Item key={key} icon={icon} id={id}>
            {text}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <Dropdown {..._.omit(props, _.isNil)} overlay={overlayMenu}>
      {children}
    </Dropdown>
  );
};
