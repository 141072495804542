import React, { useState } from "react";
import { withRouter, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import { Typography, Space, Button, Row, Col, Popover } from "antd";
import styled from "styled-components";

import "../Patient/PatientPotentialSavings/PatientPotentialSavings.css";
import { WHITE, DARK_GRAY3, BLACK, DARK_BLUE, LIGHT_GRAY2, DARK_GRAY2, LIGHT_BLUE_6 } from "../../constant/colors";
import {
  selectFilterList,
  selectUser,
  selectFilters,
  selectIsFetching,
  selectIsFilterListFiltersModified,
  getFilterListSelectedColumns,
  selectTableColumnsModified
} from "../../store/selector";
import { fontWeights, sizes } from "../../constant/styles";

import { ReactComponent as ArrowBack } from "../../assets/svg/arrow-back-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/delete-new-icon.svg";
import { ReactComponent as LockList } from "../../assets/svg/lock-list.svg";

import SuccessMessage from "../../component/customComponent/customMessages/SuccessMessage";
import ErrorMessage from "../../component/customComponent/customMessages/ErrorMessage";

import SaveAsNewList from "../SaveAsNewList";
import { SavedListActionsMenu } from "../SavedList/SavedListActionsMenu";
import { ModalComponent } from "../ModalComponent";
import { dateFormatter } from "../../utils/date";
import { defaultFilters } from "../../constant/filters";
import { selectedViewTypes, selectedViews } from "../../constant/table";
import ACTIONS from "../../store/action";
import { ApiNames, deleteSavedListAPI } from "../../api/api";

const { Text } = Typography;
const POP_ACTION = "POP";

const StyledPopover = styled(Popover)`
  .ant-popover-inner {
    box-shadow: 0px 10px 22px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }
`;
const StyledPopoverText = styled(Text)`
  font-weight: ${fontWeights.regular};
  font-size: ${sizes.medium};
  color: ${LIGHT_GRAY2};
`;
const StyledSaveButton = styled(Button)`
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.semibold};
  border: 1px solid ${DARK_BLUE};
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background-color: ${DARK_BLUE};
  width: 56px;
  display: flex;
  justify-content: center;

  :disabled {
    background-color: ${LIGHT_BLUE_6};
    border: 0px solid ${LIGHT_BLUE_6};
    color: ${WHITE};
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    :hover {
      background-color: ${LIGHT_BLUE_6};
      border: 1px solid ${LIGHT_BLUE_6};
      color: ${WHITE};
      border-radius: 4px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
  }
`;

const FilterListControlsComponent = ({
  t,
  history,
  filterList,
  user,
  filters,
  updateFilterListAct,
  isFetching,
  setFiltersAct,
  clearSavedListRelatedDataAct,
  setSelectedView,
  clearFiltersInputs,
  setSelectedViewAct,
  filterListFiltersModified,
  markSavedFilterAsChanged,
  filterListSelectedColumns,
  tableColumnsModified,
  clearSorterAct
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [fromSavedBackButton, setFromSavedBackButton] = useState(false);
  const isOwner = user?.id === filterList?.user;
  const isModified = tableColumnsModified || filterListFiltersModified;
  const canModify = isOwner && isModified;

  const handleSaveButton = () => {
    const newFilterListValues = {
      name: filterList?.name,
      description: filterList?.description,
      isPrivate: filterList?.isPrivate === 1 ? true : false,
      selectedView: filterList?.selectedView,
      params: { filters, selectedColumns: filterListSelectedColumns }
    };

    markSavedFilterAsChanged(filterList?.id, false);
    updateFilterListAct(newFilterListValues, filterList?.id);
  };

  const handleBrowserBack = (fromSavedListBack = false) => {
    if (!fromSavedListBack) {
      setFiltersAct(defaultFilters);
      clearFiltersInputs();
    }
    clearSavedListRelatedDataAct();
    clearSorterAct(filterList.selectedView);
    markSavedFilterAsChanged(undefined, false);

    if (history.location.state?.from === selectedViews.savedList || fromSavedListBack) {
      setSelectedView(selectedViews.savedList);
      history.push(`/?selectedView=${selectedViews.savedList}`);
    } else {
      setSelectedView(filterList.selectedView);
      history.push(`/?selectedView=${filterList.selectedView}`);
    }
  };

  return (
    <div style={{ backgroundColor: WHITE, padding: 16 }}>
      <Prompt
        message={(location, action) => {
          if (action === POP_ACTION) {
            if (canModify) {
              setShowDiscardModal(true);
              return false;
            } else {
              handleBrowserBack();
              return true;
            }
          }
          return true;
        }}
      />
      <Row style={{ borderBottom: `1px solid ${DARK_GRAY2}`, paddingBottom: "20px" }}>
        <Col span={15} style={{ paddingRight: 12 }}>
          <Space direction="vertical">
            <Space direction="horizontal">
              <Button
                type="text"
                onClick={() => {
                  setFromSavedBackButton(true);
                  if (canModify) {
                    setShowDiscardModal(true);
                  } else {
                    handleBrowserBack(true);
                  }
                }}
                style={{ padding: 0 }}
              >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Space direction="horizontal">
                    <ArrowBack />
                    <Text
                      style={{
                        fontSize: sizes.medium,
                        color: DARK_GRAY3,
                        fontWeight: fontWeights.semibold,
                        paddingLeft: "5px"
                      }}
                    >
                      {t("filter_list_view.filter_list_top_section.saved_lists")}
                    </Text>
                  </Space>
                </div>
              </Button>
            </Space>
            <Space direction="vertical" size={6}>
              <Space>
                {!!filterList?.isPrivate && (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <LockList />
                  </div>
                )}
                <Text
                  style={{
                    fontSize: sizes.large,
                    color: BLACK,
                    fontWeight: fontWeights.medium
                  }}
                >
                  {filterList?.name}
                </Text>
                <StyledPopover
                  content={
                    <Space
                      direction="vertical"
                      style={{
                        padding: "0px 12px",
                        width: "298px"
                      }}
                    >
                      {filterList?.description && <StyledPopoverText>{filterList.description}</StyledPopoverText>}
                      <StyledPopoverText>
                        {t("filter_list_view.filter_list_top_section.last_modified_lbl")}
                        <Text style={{ fontWeight: fontWeights.semibold }}>{dateFormatter(filterList?.updatedAt)}</Text>
                      </StyledPopoverText>
                    </Space>
                  }
                  placement="bottomLeft"
                >
                  <Button id="list_screen_details" type="text" style={{ color: DARK_BLUE, paddingLeft: 10 }}>
                    {t("filter_list_view.filter_list_top_section.details")}
                  </Button>
                </StyledPopover>
              </Space>
              <Space>
                <Text
                  style={{
                    fontSize: sizes.medium,
                    color: DARK_GRAY3,
                    fontWeight: fontWeights.regular
                  }}
                >
                  {t("filter_list_view.filter_list_top_section.description", {
                    view: selectedViewTypes[filterList.selectedView]?.description,
                    user: filterList?.username
                  })}
                </Text>
              </Space>
            </Space>
          </Space>
        </Col>
        <Col span={9} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space direction="horizontal" size={16}>
            {canModify && (
              <Text
                style={{
                  fontSize: sizes.medium,
                  color: DARK_GRAY3,
                  fontWeight: fontWeights.regular
                }}
              >
                {t("filter_list_view.filter_list_top_section.unsaved_changes")}
              </Text>
            )}
            <StyledSaveButton
              id="list_screen_save"
              type="primary"
              disabled={!canModify}
              loading={isFetching}
              onClick={handleSaveButton}
            >
              {t("filter_list_view.filter_list_top_section.save")}
            </StyledSaveButton>
            <SaveAsNewList disabled={false} id="list_screen_save_as_new_list" />
            <SavedListActionsMenu
              record={filterList}
              deleteAction={() => {
                setShowDeleteModal(true);
              }}
              source="lists_screen"
            />
          </Space>
        </Col>
        {filterList && filterList?.name && (
          <ModalComponent
            open={showDiscardModal}
            onCancel={() => setShowDiscardModal(false)}
            okText={t("savedList.discard_modal.ok_text")}
            cancelText={t("savedList.discard_modal.cancel_text")}
            onOk={() => {
              handleBrowserBack(fromSavedBackButton);
              setShowDiscardModal(false);
            }}
          >
            <Space direction="vertical">
              <DeleteIcon style={{ width: "48px" }} />
              <Text className="title">{t("savedList.discard_modal.title")}</Text>
              <Text className="content">
                {t("savedList.discard_modal.content", {
                  listName: filterList?.name
                })}
              </Text>
            </Space>
          </ModalComponent>
        )}

        <ModalComponent
          open={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          okText={t("savedList.confirm_modal.ok_text")}
          onOk={async () => {
            try {
              const deleteListRes = await deleteSavedListAPI(filterList.id);
              if (deleteListRes) {
                setSelectedViewAct(selectedViews.savedList);
                history.push({ pathname: "/", search: `?selectedView=` + selectedViews.savedList });
                SuccessMessage(t("savedList.confirm_modal.success_message"));
              }
            } catch (error) {
              ErrorMessage(t("savedList.confirm_modal.error_message"));
            }
          }}
        >
          <Space direction="vertical">
            <DeleteIcon style={{ width: "48px" }} />
            <Text className="title">{t("savedList.confirm_modal.title")}</Text>
            <Text className="content">
              {t("savedList.confirm_modal.content", {
                listName: filterList.name
              })}
            </Text>
          </Space>
        </ModalComponent>
      </Row>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  filterList: selectFilterList,
  user: selectUser,
  filters: selectFilters,
  isFetching: selectIsFetching([ApiNames.UpdateFilterList]),
  filterListFiltersModified: selectIsFilterListFiltersModified,
  filterListSelectedColumns: getFilterListSelectedColumns,
  tableColumnsModified: selectTableColumnsModified
});

const mapDispatchToProps = (dispatch) => ({
  updateFilterListAct: (newFilterListData, filterListId) =>
    dispatch(ACTIONS.updateFilterListAction(newFilterListData, filterListId)),
  setFiltersAct: (filters) => dispatch(ACTIONS.setFiltersAction(filters)),
  setSelectedViewAct: (selectedView) => dispatch(ACTIONS.setSelectedView(selectedView)),
  clearFiltersInputs: () => dispatch(ACTIONS.clearFiltersInputs()),
  clearSorterAct: (view) => dispatch(ACTIONS.clearSorterAction(view)),
  clearSavedListRelatedDataAct: () => dispatch(ACTIONS.clearSavedListRelatedDataAction()),
  setSelectedView: (selectedView) => dispatch(ACTIONS.setSelectedView(selectedView)),
  markSavedFilterAsChanged: (filterListID, isModified) =>
    dispatch(ACTIONS.markSavedFilterAsChanged(filterListID, isModified))
});

const FilterListControls = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(FilterListControlsComponent);

export { FilterListControls };
