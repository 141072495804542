import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Form } from "antd";

import MedicationForm from "../MedicationForm/MedicationForm";
import { OutlinedButton, PrimaryButton } from "../../../customComponent/Button";
import { PopupStyledModal } from "../../../customComponentNewDesign/CustomPopupModal";

const AddMedicationModal = ({ addNewMedication, showPopupRef }) => {
  const { t } = useTranslation();
  const [popupShown, setPopupShown] = useState(false);
  const showPopup = () => setPopupShown(true);
  showPopupRef.current = showPopup;
  const [form] = Form.useForm();

  const Popup = styled(PopupStyledModal)`
    .ant-modal-title {
      margin-bottom: 20px;
    }
  `;

  const handleSubmitEvent = ({ rxnormDrug, medicationData }) => {
    addNewMedication({ rxnormDrug, medicationData });
    setPopupShown(false);
  };

  return (
    <Popup
      destroyOnClose={true}
      open={popupShown}
      title={t("managePatientDetails.medications_section.medication_modal.add_new_medication")}
      footer={[
        <OutlinedButton key="cancelButton" onClick={() => setPopupShown(false)}>
          {t("managePatientDetails.medications_section.medication_modal.cancel")}
        </OutlinedButton>,
        <PrimaryButton form="medication_editor" key="submit" htmlType="submit">
          {t("managePatientDetails.medications_section.medication_modal.add_medication")}
        </PrimaryButton>
      ]}
    >
      <MedicationForm handleSubmitEvent={handleSubmitEvent} form={form} />
    </Popup>
  );
};

export default AddMedicationModal;
