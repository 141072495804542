import React from "react";
import styled from "styled-components";
import { LIGHT_GRAY2, DARK_BLUE } from "../../constant/colors";

const StyledDot = styled.span`
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  width: 4px;
  background-color: ${LIGHT_GRAY2};
  margin: 1px;
  :hover {
    background-color: ${DARK_BLUE};
  }
`;

const ThreeDots = () => {
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <StyledDot />
      <StyledDot />
      <StyledDot />
    </div>
  );
};

export { ThreeDots };
