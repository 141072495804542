import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { createStructuredSelector } from "reselect";
import styled from "styled-components";

import ACTION from "../store/action";
import {
  selectIsFetching,
  selectFilterList,
  selectFilterListStatuses,
  selectIsFilterListFiltersModified,
  selectTableColumnsModified
} from "../store/selector";
import { AllPatientsTable } from "../component/TableSection/AllPatientsTable";
import { VisitScheduleTable } from "../component/TableSection/VisitScheduleTable";
import { HighPrioritiesTable } from "../component/TableSection/HighPrioritiesTable";
import { PendingRenewalTable } from "../component/TableSection/PendingRenewalTable";
import { AllApplicationsTable } from "../component/TableSection/AllApplicationsTable";
import { FilterListControls } from "../component/FilterList/FilterListControls";
import { ApiNames } from "../api/api";
import TableFilters from "../component/TableFilters";
import { selectedViews } from "../constant/table";
import { TailormedSpinner } from "../component/TailormedSpinner/TailormedSpinner";
import { FilterListForm } from "../component/FormPopups/FilterListForm";
import { AllClaimsTable } from "../component/TableSection/AllClaimsTable";
import ACTIONS from "../store/action";

const DashboardScreen = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.dimensions.width};
  margin: 0 auto;
  padding: 0px 0px 24px 0px;
  position: relative;
`;

const DEFAULT_PAGE = 1;
const DEFAULT_SORTER = {};

const FilterListViewComponent = ({
  isFetching,
  searchSavedListByIdAct,
  filterList,
  filterListStatuses,
  markSavedFilterAsChanged,
  filterListFiltersModified,
  tableColumnsModified,
  updateSavedFilterViewParameters
}) => {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const filterListId = parseInt(useParams().filterListId);
  const updateSavedFilterViewParametersAct = updateSavedFilterViewParameters(filterListId);
  useEffect(() => {
    if (filterListId && filterListStatuses.id !== filterListId) {
      //Note: Reset the filter parameters when changing the view to another filter page.
      updateSavedFilterViewParametersAct(DEFAULT_PAGE, DEFAULT_SORTER);
    }

    // Note: we need to fetch data always to make data consistent for the user.
    searchSavedListByIdAct(filterListId);

    if (filterListId) {
      // Note: Reset the filter modified status to mark it as not modified.
      markSavedFilterAsChanged(filterListId, false);
    }
  }, [filterListId]);

  useEffect(() => {
    if (filterList?.id && (tableColumnsModified || filterListFiltersModified)) {
      markSavedFilterAsChanged(filterList?.id, true);
    }
  }, [tableColumnsModified, filterListFiltersModified]);

  return (
    <DashboardScreen direction={"vertical"}>
      {!isFetching ? (
        <>
          <FilterListControls />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: filtersVisible ? "row" : "column",
              justifyContent: "flex-start",
              padding: "0px 16px"
            }}
          >
            <TableFilters
              filtersVisible={filtersVisible}
              setFiltersVisible={setFiltersVisible}
              showSaveNewListBtn={false}
            />
            {filterList?.selectedView === selectedViews.allPatients && (
              <AllPatientsTable
                isFilterOpen={filtersVisible}
                isSearch={false}
                updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
              />
            )}
            {filterList?.selectedView === selectedViews.visitSchedule && (
              <VisitScheduleTable
                isFilterOpen={filtersVisible}
                filterListId={filterListId}
                updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
              />
            )}
            {filterList?.selectedView === selectedViews.pendingRenewal && (
              <PendingRenewalTable
                isFilterOpen={filtersVisible}
                filterListId={filterListId}
                updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
              />
            )}
            {filterList?.selectedView === selectedViews.highPriority && (
              <HighPrioritiesTable
                isFilterOpen={filtersVisible}
                filterListId={filterListId}
                updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
              />
            )}
            {filterList?.selectedView === selectedViews.allApplications && (
              <AllApplicationsTable
                isFilterOpen={filtersVisible}
                filterListId={filterListId}
                updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
              />
            )}
            {filterList?.selectedView === selectedViews.allClaims && (
              <AllClaimsTable
                isFilterOpen={filtersVisible}
                filterListId={filterListId}
                updateSavedFilterViewParameters={updateSavedFilterViewParametersAct}
              />
            )}
            <FilterListForm />
          </div>
        </>
      ) : (
        <TailormedSpinner />
      )}
    </DashboardScreen>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchSavedListByIdAct: (filterListId) => dispatch(ACTION.searchSavedListByIdAction(filterListId)),
  markSavedFilterAsChanged: (filterListID, isModified) =>
    dispatch(ACTIONS.markSavedFilterAsChanged(filterListID, isModified)),
  updateSavedFilterViewParameters: (filterListID) => (page, sorter) =>
    dispatch(ACTION.updateSavedFilterViewParameters(filterListID, page, sorter))
});
const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching([ApiNames.FindSavedListById]),
  filterList: selectFilterList,
  filterListStatuses: selectFilterListStatuses,
  filterListFiltersModified: selectIsFilterListFiltersModified,
  tableColumnsModified: selectTableColumnsModified
});

const FilterListView = compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(FilterListViewComponent);
export { FilterListView };
