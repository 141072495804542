import React, { useCallback, useContext, useState } from "react";
import { Layout, Dropdown, Menu, Space, Typography, Button, Affix } from "antd";
import { HomeFilled, LogoutOutlined, UploadOutlined } from "@ant-design/icons";
import { withRouter, useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withOktaAuth } from "@okta/okta-react";
import styled from "styled-components";

import ACTIONS from "./../store/action";
import { GREEN, HEADER_BLUE, WHITE_GRAY } from "../constant/colors";
import { Routes } from "../constant/routes";
import { selectIsPhiAllowed, selectUser, selectIDPConfig, selectSelectedView } from "../store/selector";
import { HeaderSearchBar } from "./HeaderSearchBar";
import { Tasks } from "./Tasks/Tasks";
import { ContactSupport } from "./ContactSupport";
import SupportIcon from "../assets/svg/SupportIcon";
import { Notifications } from "./Notifications";
import { PatientConfirmationAlertContext } from "../context/PatientConfirmationAlertContext";
import { CircleIconButton } from "./UI/CircleIconButton";
import { Tooltip } from "./UI/Tooltip";
import { logout } from "@tailormed/common-client/services/auth";
import { TailormedLogoIcon, TailormedVisualIcon } from "./AppLayout";
import { defaultFilters } from "../constant/filters";
import { oktaConfig } from "../constant/okta";

const { Text } = Typography;
const { Header: AntHeader } = Layout;

const Header = styled(AntHeader)`
  padding: 0;
  background-color: ${GREEN};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: ${(props) => props.theme.dimensions.width};
`;

const Substrate = styled.div`
  background-color: ${GREEN};
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderSearchBarHolder = styled.div`
  stify-content: center;
  align-items: center;
`;

const IconsHolder = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > :not(:last-child) {
    margin-right: 10px;
  }
`;

const DropdownCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${HEADER_BLUE};
  margin: auto 0;
`;

const MainHeaderComponent = ({
  t,
  history,
  user,
  setShowSupport,
  clearSelectedView,
  selectedView,
  clearSorterAct,
  clearFiltersInputs,
  isPhiAllowed,
  setFiltersAct,
  idpConfig,
  authState,
  resetCurrentSavedListStatus
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { pathname } = useLocation();
  const { showCancelConfirm } = useContext(PatientConfirmationAlertContext);
  const handleLogOut = useCallback(() => {
    logout(user, idpConfig, oktaConfig.loginURI);
  }, []);

  const handleDashboardClick = useCallback(() => {
    if (pathname.includes(Routes.NEW_PATIENT)) {
      return showCancelConfirm(Routes.ROOT);
    }
    if (pathname !== Routes.ROOT) {
      clearSorterAct(selectedView);
      setMenuVisible(false);
      setFiltersAct(defaultFilters);
      clearSelectedView();
      clearFiltersInputs();
      resetCurrentSavedListStatus();
      history.push(Routes.ROOT);
    }
  }, [pathname]);

  const handleUploadClick = useCallback(() => {
    if (pathname.includes(Routes.NEW_PATIENT)) {
      return showCancelConfirm(Routes.UPLOADER);
    }
    if (pathname !== Routes.UPLOADER) {
      setMenuVisible(false);
      history.push(Routes.UPLOADER);
    }
  }, [pathname]);

  const menuKeys = {
    DASHBOARD: "dashboard",
    UPLOADER: "uploader",
    LOGOUT: "logout"
  };

  const menu = (
    <Menu>
      {authState?.isAuthenticated &&
        user && [
          <Menu.Item key={menuKeys.DASHBOARD} onClick={handleDashboardClick}>
            <Space>
              <HomeFilled />
              <Text>{t("dashboard")}</Text>
            </Space>
          </Menu.Item>,
          <Menu.Item key={menuKeys.UPLOADER} onClick={handleUploadClick}>
            <Space>
              <UploadOutlined />
              <Text>{t("uploader_secure_upload")}</Text>
            </Space>
          </Menu.Item>,
          <Menu.Item key={menuKeys.LOGOUT} onClick={handleLogOut}>
            <Space>
              <LogoutOutlined />
              <Text>{t("sign_out")}</Text>
            </Space>
          </Menu.Item>
        ]}
    </Menu>
  );
  const circleIconButtonClickEvent = useCallback(() => {
    setShowSupport(true, undefined);
  }, []);

  const dropdownOnOpenChangeEvent = useCallback((event) => {
    setMenuVisible(event);
  }, []);
  return (
    <Affix>
      <Substrate>
        <Header>
          <Logo>
            <Button
              type={"text"}
              style={{ display: "flex", alignItems: "center", paddingRight: 12, paddingLeft: 0 }}
              onClick={handleDashboardClick}
            >
              <TailormedLogoIcon />
            </Button>
            <TailormedVisualIcon />
          </Logo>
          <Space>
            <HeaderSearchBarHolder>
              <HeaderSearchBar />
            </HeaderSearchBarHolder>
            <IconsHolder>
              {isPhiAllowed && pathname.split("/")[1] !== "patient" && <Tasks />}
              <Notifications />
              <Tooltip title={t("contact_support.title")}>
                <CircleIconButton onClick={circleIconButtonClickEvent} icon={<SupportIcon />} />
              </Tooltip>
            </IconsHolder>

            <Tooltip title={t("profile.tooltip")} overlayStyle={{ ...(menuVisible ? { display: "none" } : {}) }}>
              <Dropdown
                trigger="click"
                placement="bottomRight"
                overlay={menu}
                open={menuVisible && authState?.isAuthenticated}
                onOpenChange={dropdownOnOpenChangeEvent}
                overlayStyle={{ paddingTop: 10 }}
              >
                <Button type="text" style={{ padding: 0 }}>
                  <DropdownCircle>
                    <Text strong style={{ color: WHITE_GRAY }}>
                      {`${user ? user.firstName.substring(0, 1) : ""} ${user ? user.lastName.substring(0, 1) : ""}`}
                    </Text>
                  </DropdownCircle>
                </Button>
              </Dropdown>
            </Tooltip>
          </Space>
          <ContactSupport />
        </Header>
      </Substrate>
    </Affix>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setShowSupport: (visible) => dispatch(ACTIONS.setShowSupport(visible)),
  clearSelectedView: () => dispatch(ACTIONS.clearSelectedView()),
  clearSorterAct: (view) => dispatch(ACTIONS.clearSorterAction(view)),
  clearFiltersInputs: () => dispatch(ACTIONS.clearFiltersInputs()),
  setFiltersAct: (filters) => dispatch(ACTIONS.setFiltersAction(filters)),
  resetCurrentSavedListStatus: () => dispatch(ACTIONS.markSavedFilterAsChanged(undefined, false))
});

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  selectedView: selectSelectedView,
  isPhiAllowed: selectIsPhiAllowed,
  idpConfig: selectIDPConfig
});

const MainHeader = compose(
  withOktaAuth,
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(MainHeaderComponent);
export { MainHeader };
