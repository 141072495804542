import React from "react";
import { Select } from "antd";

import { Form } from "antd";
import { SingleSelect } from "../../../../customComponent/CustomSelect";
import { Languages } from "../../../../../constant/languages";

const { Option } = Select;

export const PreferredLanguageItem = ({ required, name, label, placeholder }) => {
  return (
    <Form.Item rules={[{ required }]} name={name} label={label}>
      <SingleSelect useCommonStyle={false} bordered={true} placeholder={placeholder}>
        {Object.values(Languages).map((lang, index) => (
          <Option key={index} value={lang.code}>
            {lang.name}
          </Option>
        ))}
      </SingleSelect>
    </Form.Item>
  );
};
