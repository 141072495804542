import React from "react";

export default function ActiveStarIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.966 6.80944C17.8933 6.57568 17.7133 6.39637 17.4869 6.33249L12.4309 4.91252L9.58069 0.309256C9.32522 -0.103085 8.67445 -0.103085 8.41898 0.309256L5.56872 4.91252L0.512749 6.33249C0.286362 6.39565 0.10636 6.57496 0.0336673 6.80944C-0.0383335 7.0432 0.006667 7.29946 0.15413 7.49038L3.44886 11.7554L3.1747 17.2363C3.16224 17.4817 3.26955 17.7169 3.45924 17.8613C3.64893 18.0065 3.8954 18.0392 4.11417 17.9521L8.99984 15.9848L13.8862 17.9521C13.9665 17.984 14.0503 18 14.134 18C14.278 18 14.4214 17.9528 14.5411 17.8613C14.7308 17.7169 14.8381 17.4817 14.8257 17.2363L14.5515 11.7554L17.8462 7.49038C17.993 7.29946 18.0387 7.0432 17.966 6.80944Z"
        fill="#FDB022"
      />
    </svg>
  );
}
