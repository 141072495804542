import React from "react";
import { Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

import { Form } from "antd";
import { emailInputRules } from "../../utils";

export const EmailItem = ({ name, label, placeholder, required, inputProps = {}, additionalRules = [] }) => {
  return (
    <Form.Item rules={[{ required }, ...emailInputRules, ...additionalRules]} name={name} label={label}>
      <Input addonBefore={<MailOutlined />} placeholder={inputProps.disabled ? "" : placeholder} {...inputProps} />
    </Form.Item>
  );
};
