import React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const DownloadButton = ({ isLoading, customText, buttonComponent: CustomComponent, ...props }) => {
  const { t } = useTranslation();
  let text = customText ? customText : t("download");
  const ButtonComponent = CustomComponent || Button;

  return (
    <ButtonComponent {...props} loading={isLoading}>
      {!isLoading ? text : null}
      {props.children}
    </ButtonComponent>
  );
};

export { DownloadButton };
