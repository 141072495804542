export const medicationFieldNames = {
  rxnormDrug: "rxnormDrug",
  doseForm: "dfName",
  strength: "strength",
  startDate: "startDate",
  physician: "physician",
  dosageDisplay: "dosageDisplay",
  dosageValue: "dosageValue",
  dosageUnit: "dosageUnit"
};
export const ivMedicationFields = { quantity: "quantity", numOfCycles: "numOfCycles" };
export const nonIvMedicationFields = {
  frequency: "frequency",
  numberOfRefillsAuthorized: "numberOfRefillsAuthorized",
  quantityValue: "quantityValue"
};
