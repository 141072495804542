import React from "react";
import { Col, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";

import { phoneNumberLengthRegEx } from "../../../../../../../utils/formValidation";
import { hcpFields } from "../../fieldNames";
import { FormItem, InputPhone } from "../../../../components";
import { onlyWhitespacesRule, zipInputRules } from "../../../../utils";
import { SingleSelect } from "../../../../../../customComponent/CustomSelect";
import { States as STATES } from "../../../../../../../constant/states";

const { Option } = Select;

export const OtherFacilityAddressForm = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            name={hcpFields.shippingAddress}
            label={t("application_form_editor.pfizer.hcp.labels.shipment_address")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.shipment_address")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.shippingCity}
            label={t("application_form_editor.pfizer.hcp.labels.shipment_city")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.shipment_city")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.shippingState}
            label={t("application_form_editor.pfizer.hcp.labels.shipment_state")}
            rules={[{ required: true }]}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              placeholder={t("application_form_editor.pfizer.hcp.placeholders.shipment_state")}
            >
              {Object.values(STATES).map((state, index) => (
                <Option key={index} value={state.code}>
                  {state.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, ...zipInputRules]}
            name={hcpFields.shippingZip}
            label={t("application_form_editor.pfizer.hcp.labels.shipment_zip_code")}
          >
            <Input maxLength={5} placeholder={t("application_form_editor.pfizer.hcp.placeholders.shipment_zip_code")} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            name={hcpFields.shippingName}
            label={t("application_form_editor.pfizer.hcp.labels.shipment_facility_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.hcp.placeholders.shipment_facility_name")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={hcpFields.shippingPhone}
            label={t("application_form_editor.pfizer.hcp.labels.shipment_contact_phone")}
            rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
          >
            <InputPhone placeholder={t("application_form_editor.pfizer.hcp.placeholders.shipment_contact_phone")} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};
