import { GRAY9, LIGHT_BLUE_2, LIGHT_GRAY2, DARK_BLUE, GRAY7, GRAY_400 } from "../../constant/colors";
import { PaginationStyle } from "./PaginationStyle.js";
export const SavedListTableStyle = `
  .ant-table table{
    margin-top: 20px;
    border: 1px solid ${GRAY9};
    border-radius: 10px;
  }
  
  .ant-table-thead {
    .ant-table-cell{
      :before{
        display: none;
      }
      background-color: ${GRAY9};    
    }
    tr:first-child th:first-child{
      border-top-left-radius: 10px;
    }
    tr:first-child th:last-child{
      border-top-right-radius: 10px;
    }

  }
  .ant-table-tbody {
    .ant-table-row:hover{
      .ant-table-cell{
        background: ${GRAY9};
      }
    }
    .disabled-row {
      cursor:not-allowed;
      color:${GRAY_400} !important ;
      .ant-typography {
        color:${GRAY_400} !important;
      }
    }
     
    tr:last-child td:first-child{
      border-bottom-left-radius: 10px;
    }
    tr:last-child td:last-child{
      border-bottom-right-radius: 10px;
    }
    .ant-dropdown-trigger:hover, .ant-btn-text:hover{
      background: ${GRAY7};
         span{
          background-color: ${LIGHT_GRAY2} ;
       }
    }

    .ant-dropdown-trigger:active, .ant-btn-text:active, .ant-btn-text:focus{
      background: ${LIGHT_BLUE_2};
         span{
          background-color: ${DARK_BLUE} ;
        }
    }
  }
  ${PaginationStyle}
`;
