import styled from "styled-components";
import { Button, Modal, Steps, Typography } from "antd";
import { fontWeights, sizes } from "../../../../../constant/styles";
import { BLUE_4, GRAY_400, PRIMARY_400, PRIMARY_50, PRIMARY_600, PRIMARY_800 } from "../../../../../constant/colors";
import { font14pxR } from "../../../../Typography";

const { Step } = Steps;
const { Text } = Typography;

export const ApplicationButton = styled(Button)`
  background: ${PRIMARY_50} !important;
  border: 2px solid ${BLUE_4} !important;
  border-radius: 4px;
  color: ${PRIMARY_600} !important;
  font-style: normal;
  font-weight: ${fontWeights.semibold};
  font-size: ${sizes.medium};
  line-height: 25px !important;

  :hover {
    border: 2px solid ${PRIMARY_800} !important;
    border-radius: 4px;
    color: ${PRIMARY_800} !important;
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.medium};
  }

  :active {
    background: ${PRIMARY_50} !important;
    border: 2px solid ${PRIMARY_600} !important;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 4px ${PRIMARY_400} !important;
    color: ${PRIMARY_600} !important;
    padding-top: 3px !important;
    line-height: 20px !important;
  }
`;

export const StepsStyled = styled(Steps)`
  .ant-steps-item-title {
    font-size: ${sizes.medium};
    font-weight: ${fontWeights.bold};
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${({ isBlueTheme }) => isBlueTheme && "#D1E9FF"};
  }
`;

export const StepStyled = styled(Step)`
  .ant-steps-item-description {
    margin: 0px;
    padding: 0px !important;
  }
`;

export const StyledModal = styled(Modal)`
  margin: 1em;
  .ant-form {
    max-height: 75vh;
    overflow-y: scroll;
  }
  .ant-modal-close {
    .ant-modal-close-x {
      width: 32px;
      height: 32px;
    }
  }
  .ant-modal-body {
    padding: 12px;
  }
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-footer {
    border-top: hidden;
  }
`;

export const StyledStepText = styled(Text)`
  color: ${GRAY_400};
  ${font14pxR}
`;
