import { combineReducers } from "redux";

/**
 * @callback Reducer
 * @param {Object} state - The input state
 * @param {Object} action - The action to apply
 * @returns {Object} New state
 */

/**
 * @typedef SplitState
 * @property {Object} slices - The slices from the given state
 * @property {Object} remainder - The remaining unsliced state
 */

/**
 * Splits the state into two halves, separating the sliced, state
 * from the remaining unsliced "flat" state
 *
 * @param {object} state - The combined sliced and unsliced state
 * @param {string[]} sliceKeys - A list of keys that contain sliced state
 * @returns {SplitState}
 */

function splitState(state, sliceKeys) {
  if (!state) {
    return [undefined, undefined];
  }

  const slices = {};
  const remainder = {};
  for (const [key, val] of Object.entries(state)) {
    if (sliceKeys.has(key)) {
      slices[key] = val;
    } else {
      remainder[key] = val;
    }
  }

  return { slices, remainder };
}

/**
 * A reducer that allows for partially-sliced state
 *
 * This allows incrementally transitioning from one giant reducer into
 * sliced reducers without splitting up everything at once. It takes
 * a set of sliced reducers that are responsible for one slice of
 * state, and then a catch-all reducer that is responsible for the
 * remaining state.
 *
 * This reducer splits up the input state, passing each state slice into
 * the matching sliced reducer, and then passes any remaining state
 * to the catch-all reducer. It then merges the new state back together,
 * checking to make sure there are no conflicts.
 *
 * @param {Object.<string, Reducer>} sliceReducers - A key-value map of sliced reducers, whose state will be added to the resulting state at the given key
 * @param {Reducer} flatReducer - A reducer whose state will be merged with the sliced reducers. Any keys that duplicate a sliced reducer will throw an error
 * @returns Reducer
 */
export default function (sliceReducers, flatReducer) {
  const slicedKeys = new Set(Object.keys(sliceReducers));
  if (slicedKeys.size === 0) {
    return flatReducer;
  }

  const slicedReducer = combineReducers(sliceReducers);

  return function (state, action) {
    // Split state up
    const { slices, remainder } = splitState(state, slicedKeys);

    // Calculate new state
    const newSlicedState = slicedReducer(slices, action);
    const newFlatState = flatReducer(remainder, action);

    // Safety check to log an error if we're trying to manage the same
    // state with both reducers
    for (const key in newSlicedState) {
      if (key in newFlatState) {
        console.error(
          `State slice for ${key} is being overwritten by the flat reducer. This is a bug, and is probably causing issues. Move any action handlers that are updating ${key} into the ${key} reducer.`
        );
      }
    }

    // Return merged state
    return {
      ...newSlicedState,
      ...newFlatState
    };
  };
}
