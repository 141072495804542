import React from "react";

const MedicalIcon = ({ style = {} }) => {
  return (
    <svg style={style} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1042_85604)">
        <path
          style={style}
          d="M14.808 6.99963C15.2171 6.22626 15.4532 5.37325 15.5 4.49963C15.5033 3.61012 15.209 2.74506 14.6639 2.04209C14.1189 1.33911 13.3544 0.838585 12.4922 0.620141C11.6299 0.401697 10.7193 0.477881 9.90533 0.836571C9.09134 1.19526 8.42067 1.81586 8.00003 2.59963C7.57938 1.81586 6.90872 1.19526 6.09472 0.836571C5.28073 0.477881 4.37017 0.401697 3.50789 0.620141C2.64561 0.838585 1.88115 1.33911 1.33611 2.04209C0.791075 2.74506 0.496774 3.61012 0.500027 4.49963C0.500027 8.49963 7.98503 15.4856 8.00003 15.4996"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          style={style}
          d="M0.5 9.5H3.5L6.5 5.5L10.5 12.5L12.5 9.5H15.5"
          stroke="#98A2B3"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1042_85604">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MedicalIcon;
