export const ASCEND = "ascend";
export const DECEND = "descend";

export const apiSortOptions = {
  desc: "DESC",
  asc: "ASC"
};
export const SORT_DIRECTIONS = [ASCEND, DECEND, ASCEND];
export const SORT_DIRECTIONS_DEFAULT = [ASCEND, DECEND];
export const SORT_DIRECTIONS_REVERSE = [DECEND, ASCEND, DECEND];

export const desiredOrderOfStatuses = [
  { value: "awaiting-assistance", text: "Awaiting Assistance" },
  { value: "mfg-recapture", text: "MFG Recapture" },
  { value: "fhp-recapture", text: "FHP Recapture" },
  { value: "new", text: "New" },
  { value: "in-progress", text: "In Progress" },
  { value: "follow-up", text: "Follow Up" },
  { value: "established", text: "Established" },
  { value: "done", text: "Done" }
];
