import React, { useCallback, useEffect } from "react";
import { Col, Row, Typography } from "antd";
import { withTranslation } from "react-i18next";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { debounce } from "lodash";
import styled, { css } from "styled-components";
import { AllPatientsTable } from "./TableSection/AllPatientsTable";
import ACTIONS from "../store/action";
import { decodeNumbersForSearch } from "../utils/formaters";

const PHONE = "phone_number";
const SSN = "social_security_number";

const SharedStyle = css`
  width: 100%;
  max-width: ${(props) => props.theme.dimensions.width};
  margin: 0 auto;
`;

const LimitedCol = styled(Col)`
  ${SharedStyle}
  position: relative;
`;

const LimitedRow = styled(Col)`
  ${SharedStyle}
  position: relative;
`;

const formatSearchQuery = (searchText, searchBy) =>
  searchBy === SSN || searchBy === PHONE ? decodeNumbersForSearch(searchText) : searchText;

const SearchResultsComponent = ({ t, searchPatientAct }) => {
  const { Text } = Typography;

  const { search, state } = useLocation();
  const searchText = state?.searchText;

  const searchParams = new URLSearchParams(search);
  const searchBy = searchParams.get("searchBy");

  useEffect(() => {
    if (searchText && searchBy) {
      debouncedSearch(1, searchText, searchBy);
    }
  }, [searchText, searchBy]);

  const debouncedSearch = useCallback(
    debounce(
      (page, searchText, searchBy, sorter) =>
        searchPatientAct(page, formatSearchQuery(searchText, searchBy), searchBy, sorter),
      500
    ),
    []
  );

  return (
    <Col>
      <Row
        style={{ background: "#f5f5f5", paddingTop: "16px", paddingBottom: "16px", borderBottom: "1px solid #d4d4d5" }}
      >
        <LimitedCol>
          <Link to={`/`}>
            <div style={{ color: "#acacac", fontSize: "14px", cursor: "pointer" }}>
              <ArrowLeftOutlined />
              <Text style={{ color: "#acacac", marginLeft: "5px" }}> {t("back")}</Text>
            </div>
          </Link>
          <div style={{ paddingTop: 8 }}>
            <Text style={{ fontWeight: "700", color: "#525055" }}>{t("search_results")}</Text>
            <Text style={{ marginLeft: "5px", color: "#525055" }}>for {searchText}</Text>
          </div>
        </LimitedCol>
      </Row>
      <LimitedRow>
        <AllPatientsTable
          isSearch={true}
          fetchOrders={(page, sorter) => debouncedSearch(page, searchText, searchBy, sorter)}
        />
      </LimitedRow>
    </Col>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchPatientAct: (page, searchText, searchBy, sorter) =>
    dispatch(ACTIONS.searchPatientsAction(page, searchText, searchBy, sorter))
});

const SearchResults = compose(withTranslation(), connect(null, mapDispatchToProps))(SearchResultsComponent);
export { SearchResults };
