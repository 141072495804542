import React from "react";
import { Tooltip, Typography } from "antd";
import { shorterString, escapeRegExp } from "../utils/string";
import Highlighter from "react-highlight-words";

const { Text } = Typography;

function highlightResult(text, searchInput, maxLength) {
  let shortenedText = shorterString(text, maxLength);
  searchInput = escapeRegExp(searchInput);
  let regex = new RegExp("(" + searchInput + ")", "i");
  let result = `<Text>${shortenedText?.replace(regex, "<b>$1</b>")}</Text>`;
  return result;
}

const ShorterText = ({ text, maxLength, searchInput, textStyle, ...props }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: highlightResult(text, searchInput, maxLength) }}
      style={textStyle}
      {...props}
    ></div>
  );
};

const ShorterTextWithTooltip = ({ text, maxLength, searchInput, textStyle, placement = "right" }) => {
  return (
    <>
      {text?.length > maxLength && (
        <Tooltip title={<Text>{text}</Text>} color={"white"} placement={placement}>
          <ShorterText
            text={text}
            maxLength={maxLength}
            searchInput={searchInput}
            textStyle={{ ...textStyle, cursor: "pointer" }}
          />
        </Tooltip>
      )}
      {text?.length <= maxLength && (
        <ShorterText text={text} maxLength={maxLength} searchInput={searchInput} textStyle={textStyle} />
      )}
    </>
  );
};

const HighlighterShorterTextWithTooltip = ({ text, maxLength, searchInput, ...props }) => {
  return (
    <>
      {text?.length > maxLength && (
        <Tooltip title={<Text style={{ color: "white" }}>{text}</Text>}>
          <Highlighter
            {...props}
            searchWords={[searchInput]}
            autoEscape
            textToHighlight={shorterString(text, maxLength)}
          />
        </Tooltip>
      )}
      {text?.length <= maxLength && (
        <Highlighter {...props} searchWords={[searchInput]} autoEscape textToHighlight={text} />
      )}
    </>
  );
};

export { ShorterTextWithTooltip, HighlighterShorterTextWithTooltip, ShorterText };
