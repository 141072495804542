export const PLAN_MARKETS = Object.freeze({
  Commercial: "commercial",
  VA: "va",
  Tricare: "tricare",
  MedicarePartA: "medicare_part_a",
  MedicarePartB: "medicare_part_b",
  MedicarePartAB: "medicare_part_a_and_b",
  Medigap: "medigap",
  MedicareAdvantage: "medicare_advantage",
  MedicarePartD: "medicare_part_d",
  Medicaid: "medicaid",
  Uninsured: "uninsured",
  Unknown: "unknown"
});

export const PLAN_MARKETS_CATEGORIES = Object.freeze({
  commercial: "Commercial",
  federal: "Federal",
  medicaid: "Medicaid",
  medicare: "Medicare",
  uninsured: "Uninsured"
});

export const PLAN_MARKETS_OPTIONS = [
  { key: "1", value: PLAN_MARKETS.Commercial, translationText: "Commercial", id: "plan_commercial" },
  { key: "2", value: PLAN_MARKETS.Medicaid, translationText: "Medicaid", id: "plan_medicaid" },
  {
    key: "3",
    value: PLAN_MARKETS.MedicareAdvantage,
    translationText: "Medicare Advantage",
    id: "plan_medicare_advantage"
  },
  { key: "4", value: PLAN_MARKETS.MedicarePartA, translationText: "Medicare Part A", id: "plan_medicare_part_a" },
  {
    key: "5",
    value: PLAN_MARKETS.MedicarePartAB,
    translationText: "Medicare Part A and B",
    id: "plan_medicare_part_a_and_b"
  },
  { key: "6", value: PLAN_MARKETS.MedicarePartB, translationText: "Medicare Part B", id: "plan_medicare_part_b" },
  { key: "7", value: PLAN_MARKETS.MedicarePartD, translationText: "Medicare Part D", id: "plan_medicare_part_d" },
  { key: "8", value: PLAN_MARKETS.Medigap, translationText: "Medigap", id: "plan_medigap" },
  { key: "9", value: PLAN_MARKETS.Tricare, translationText: "Tricare", id: "plan_tricare" },
  { key: "10", value: PLAN_MARKETS.Uninsured, translationText: "Uninsured", id: "plan_uninsured" },
  { key: "11", value: PLAN_MARKETS.VA, translationText: "VA", id: "va" }
];

export const PLAN_MARKET_FILTER_OPTIONS = [
  ...PLAN_MARKETS_OPTIONS,
  { key: "12", value: PLAN_MARKETS.Unknown, translationText: "Unknown", id: "plan_unknown" }
];

export const translatePlanMarket = (planMarketInput) =>
  PLAN_MARKET_FILTER_OPTIONS.find((planMarket) => planMarketInput == planMarket.value)?.translationText;
