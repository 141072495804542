//This Will Be Moved To expressEnrollMapping Inside ApplicationFormConfiguration Table

export const insuranceMedicalName = [
  "Aetna",
  "Alliance Health",
  "Amerihealth",
  "Anthem",
  "Assurant",
  "Blue Cross",
  "Blue Shield",
  "BCBS",
  "Cigna",
  "Cox Health",
  "First Choice",
  "Geisinger",
  "Harvard Pilgrim",
  "Healthnet",
  "Humana",
  "Kaiser",
  "Medco",
  "Medica",
  "Medical Mutual",
  "Optima",
  "Preferred One",
  "Providence",
  "Select Health",
  "United Healthcare (UHC)",
  "United Medical Resources (UMR)"
];

export const insuranceMedicalPlanType = [
  "CDHP Consumer-directed Health Plan",
  "EPO Exclusive Provider Organization",
  "FFS Fee for Service",
  "HDHP High-deductible Health Plan",
  "HMO Health Maintenance Organization",
  "HRA Health Reimbursement Account",
  "HSA Health Savings Account",
  "Indemnity",
  "POS Point of Service",
  "PPO Preferred Provider Organization",
  "Union"
];
