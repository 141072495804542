import React from "react";
import { Button } from "antd";
import styled from "styled-components";

const MenuButton = styled(Button)`
  padding-top: 6px;
  &.ant-btn-text:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;

export const CircleIconButton = React.forwardRef((props, ref) => (
  <MenuButton {...props} ref={ref} shape="circle" type="text" />
));
