import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Typography, Row, Col, Spin, Form } from "antd";
import styled from "styled-components";
import _ from "lodash";
import { useHistory, useLocation, useParams } from "react-router";

import { selectIsFetching, selectPatientDetails, selectSelectedView } from "../store/selector";
import { selectedViews } from "../constant/table";
import { SubHeader } from "../component/ManagePatientDetails/SubHeader";
import ConflictCard from "../component/coverageConflicts/ConflictCard";
import { useFetch } from "../hooks/fetch";
import { ApiNames, resolveInsuranceConflicts } from "../api/api";
import EmptyListMessage from "../component/sharedComponents/EmptyListMessage";
import SuccessMessage from "../component/customComponent/customMessages/SuccessMessage";
import ErrorMessage from "../component/customComponent/customMessages/ErrorMessage";
import ACTIONS from "../store/action";

const { Text } = Typography;

const StyledContainer = styled(Col)`
  > * {
    display: flex;
    justify-content: center;
  }
`;

const StyledTitleContainer = styled(Row)`
  padding-top: 24px;
  padding-bottom: 24px;
  > * {
    width: 1216px;
  }
`;
const StyledTitle = styled(Text)`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 4px;
`;

const StyledSubTitle = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
`;

const CoverageConflicts = ({ setTableDataAct, selectedView = selectedViews.allPatients, t }) => {
  const [form] = Form.useForm();
  const [isFetching, setIsFetching] = useState(false);
  const [insurancePairs, setInsurancePairs] = useState([]);

  const patientDetails = useSelector(selectPatientDetails);
  const [pickedRows, setPickedRows] = useState(patientDetails?.coverageDetailsFromDatum);

  const [invokeInsuranceConflictsAPI] = useFetch(resolveInsuranceConflicts);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const getPatientDetailsAction = (patientId) => dispatch(ACTIONS.getPatientDetailsAction(patientId));

  const { patientId, journeyId } = useParams();

  useEffect(() => {
    getPatientDetailsAction(patientId);
  }, []);

  useEffect(() => {
    let groupedElements = [];
    if (
      patientDetails?.coverageDetailsFromDatum?.length > patientDetails?.pendingInsuranceRows.length &&
      patientDetails?.hasInsuranceConflicts
    ) {
      groupedElements = patientDetails?.coverageDetailsFromDatum.map((insuranceRow, index) => [
        insuranceRow,
        patientDetails?.pendingInsuranceRows[index]
      ]);
    } else {
      groupedElements = patientDetails?.pendingInsuranceRows.map((insuranceRow, index) => [
        patientDetails?.coverageDetailsFromDatum[index],
        insuranceRow
      ]);
    }

    if (patientDetails?.coverageDetailsFromDatum) {
      setPickedRows(patientDetails?.coverageDetailsFromDatum);
    }
    setInsurancePairs(groupedElements);
  }, [patientDetails]);

  const goBackToPatient = () =>
    location.key ? history.goBack() : history.push(`/patient/${patientId}/journey/${journeyId}/edit`);

  const handleUserPick = (cardIndex, insuranceTypeIndex) => {
    const pickedRow = insurancePairs[cardIndex][insuranceTypeIndex];
    const updatedPickedRows = _.cloneDeep(pickedRows);
    updatedPickedRows[cardIndex] = pickedRow;
    setPickedRows(updatedPickedRows);
  };

  const handleResolveConflict = async () => {
    try {
      setIsFetching(true);
      const ignoredPairs = pickedRows.flatMap((pickedRow, index) =>
        _.differenceWith(insurancePairs[index], [pickedRow], _.isEqual)
      );
      await invokeInsuranceConflictsAPI(patientDetails.id, _.compact(pickedRows), _.compact(ignoredPairs));
      SuccessMessage(t("successMessages.successfully_conflict_resolution"));
      setTableDataAct(selectedView);

      goBackToPatient();
    } catch (error) {
      ErrorMessage(t("patientApplications.failed_conflict_resolution"));
      setIsFetching(false);
    }
  };

  return (
    <Form form={form} onFinish={handleResolveConflict}>
      <SubHeader
        handleSubmit={handleResolveConflict}
        //NOTE : need to be fix - !_.isEqual(pickedRows?.length, insurancePairs?.length / 2)
        isFormModified={true}
        isFetching={isFetching}
        isInConflictScreen={insurancePairs?.length > 0}
        submitButtonText={t("new_patient.update")}
      />
      <StyledContainer>
        {patientDetails && !isFetching ? (
          <>
            {insurancePairs?.length > 0 ? (
              <StyledTitleContainer>
                <Col>
                  <Row>
                    <StyledTitle>{t("coverage_conflicts.title")}</StyledTitle>
                  </Row>
                  <Row>
                    <StyledSubTitle>{t("coverage_conflicts.sub_title")}</StyledSubTitle>
                  </Row>
                </Col>
              </StyledTitleContainer>
            ) : (
              <EmptyListMessage
                subTitleText={[t("coverage_conflicts.no_issues_to_resolve")]}
                title={t("coverage_conflicts.no_conflicts_to_resolve")}
                icon={null}
                buttonTitle="Back"
                buttonAction={goBackToPatient}
              />
            )}
            {insurancePairs?.length > 0 &&
              insurancePairs.map((insuranceRowPair, cardIndex) => (
                <Form.Item
                  key={cardIndex}
                  name={`radioButtons-${cardIndex}`}
                  rules={[
                    {
                      required: true,
                      message: (
                        <Text style={{ display: "flex", justifyContent: "center", color: "red", paddingTop: "15px" }}>
                          {t("coverage_conflicts.required_message")}
                        </Text>
                      )
                    }
                  ]}
                >
                  <Row style={{ display: "flex", justifyContent: "center", alignItems: "top" }} key={cardIndex}>
                    <ConflictCard
                      showHeaders={cardIndex == 0}
                      insuranceRowPair={insuranceRowPair}
                      index={cardIndex}
                      handleUserPick={handleUserPick}
                      cardIndex={cardIndex}
                    />
                  </Row>
                </Form.Item>
              ))}
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Spin size="large" />
          </div>
        )}
      </StyledContainer>
    </Form>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedView: selectSelectedView,
  patientDetails: selectPatientDetails,
  isFetchingPatientDetails: selectIsFetching([ApiNames.PatientDetails])
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (selectedView) => dispatch(ACTIONS.setTableDataAction(1, selectedView))
});

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CoverageConflicts);
