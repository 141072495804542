import { getGenentechSpecificSections } from "./Genentech/freeDrug/genentechPapFieldsConfiguration";
import { getNovartisSpecificSections } from "./Novartis/copay/novartisCopayFieldsConfiguration";

export const vendors = {
  GENENTECH_PAP: "genentech_pap",
  NOVARTIS_COPAY: "novartis_copay"
};

export const formSectionsController = () => {
  const configurations = {
    [vendors.GENENTECH_PAP]: () => getGenentechSpecificSections(),
    [vendors.NOVARTIS_COPAY]: () => getNovartisSpecificSections()
  };

  return {
    getSectionFieldsByVendor: (vendor) => configurations[vendor]
  };
};
