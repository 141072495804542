import React from "react";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";

import { GRAY_500 } from "../../../../../../constant/colors";
import { useSelector } from "react-redux";
import { selectCurrentApplication } from "../../../../../../store/selector";
import { useParams } from "react-router";
import PatientApplicationAttachments from "../../../../PatientApplications/PatientApplicationAttachments";

export default function Attachments() {
  const { t } = useTranslation();
  const application = useSelector(selectCurrentApplication);
  const { patientId } = useParams();

  return (
    <div style={{ height: 500 }}>
      <Divider id="enrollment-form-attachments" orientation="left" orientationMargin="0" style={{ color: GRAY_500 }}>
        {t("application_form_editor.pfizer.attachments.labels.header")}
      </Divider>

      <PatientApplicationAttachments
        patientId={patientId}
        applicationId={application?.id}
        hasExpressEnroll={application?.potentialSaving?.hasExpressEnroll}
      />
    </div>
  );
}
