import styled from "styled-components";
import { Input } from "antd";
const CustomInput = styled(Input)`
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  width: ${(props) => props.width};
  text-overflow: ellipsis;
  text-transform: capitalize;
  height: 40px;
  .ant-input {
    height: 40px;
  }
`;

export { CustomInput };
