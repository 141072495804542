/* Figma Colors
 * These are from the Figma Design System, and should be used for all general usage.
 * Designs files will reference colors like "Primary/600", which is PRIMARY_600
 */
exports.ERROR_25 = "#FFFBFA";
exports.ERROR_50 = "#FEF3F2";
exports.ERROR_100 = "#FEE4E2";
exports.ERROR_200 = "#FECDCA";
exports.ERROR_300 = "#FDA29B";
exports.ERROR_400 = "#F97066";
exports.ERROR_500 = "#F04438";
exports.ERROR_600 = "#D92D20";
exports.ERROR_700 = "#B42318";
exports.ERROR_800 = "#912018";
exports.ERROR_900 = "#7A271A";

exports.GRAY_25 = "#FCFCFD";
exports.GRAY_50 = "#F9FAFB";
exports.GRAY_60 = "#F9FCFF";
exports.GRAY_75 = "#fafafa";
exports.GRAY_90 = "#dadee4";
exports.GRAY_100 = "#F2F4F7";
exports.GRAY_200 = "#E4E7EC";
exports.GRAY_250 = "#F4F4F4";
exports.GRAY_300 = "#D0D5DD";
exports.GRAY_400 = "#98A2B3";
exports.GRAY_450 = "#D1D1D6";
exports.GRAY_500 = "#667085";
exports.GRAY_600 = "#475467";
exports.GRAY_700 = "#344054";
exports.GRAY_800 = "#1D2939";
exports.GRAY_850 = "#636366";
exports.GRAY_900 = "#101828";
exports.GRAY_1000 = "#3A3A3C";
exports.GRAY_1100 = "#2C2C2E";
exports.GRAY_1200 = "#64666B";
exports.GRAY_1300 = "#ba437c";

exports.PRIMARY_25 = "#F5FAFF";
exports.PRIMARY_50 = "#EFF8FF";
exports.PRIMARY_100 = "#D1E9FF";
exports.PRIMARY_150 = "#2854A1";
exports.PRIMARY_200 = "#B2DDFF";
exports.PRIMARY_300 = "#84CAFF";
exports.PRIMARY_400 = "#53B1FD";
exports.PRIMARY_450 = "#53b1fd";
exports.PRIMARY_500 = "#2E90FA";
exports.PRIMARY_600 = "#1570EF";
exports.PRIMARY_650 = "#0e9384";
exports.PRIMARY_700 = "#175CD3";
exports.PRIMARY_800 = "#1849A9";
exports.PRIMARY_850 = "#1890ff";
exports.PRIMARY_900 = "#194185";
exports.PRIMARY_950 = "#3f94fc";

exports.SUCCESS_25 = "#F6FEF9";
exports.SUCCESS_50 = "#ECFDF3";
exports.SUCCESS_100 = "#D1FADF";
exports.SUCCESS_200 = "#A6F4C5";
exports.SUCCESS_300 = "#6CE9A6";
exports.SUCCESS_400 = "#32D583";
exports.SUCCESS_500 = "#12B76A";
exports.SUCCESS_600 = "#039855";
exports.SUCCESS_700 = "#027A48";
exports.SUCCESS_800 = "#05603A";
exports.SUCCESS_900 = "#054F31";

exports.WARNING_25 = "#FFFCF5";
exports.WARNING_50 = "#FFFAEB";
exports.WARNING_100 = "#FEF0C7";
exports.WARNING_200 = "#FEDF89";
exports.WARNING_300 = "#FEC84B";
exports.WARNING_400 = "#FDB022";
exports.WARNING_500 = "#F79009";
exports.WARNING_600 = "#DC6803";
exports.WARNING_700 = "#B54708";
exports.WARNING_800 = "#93370D";
exports.WARNING_900 = "#7A2E0E";

/* Brand colors
 *
 * Additional colors specific to our brand
 *
 * Do not add colors to this unless they are _significantly_ different from a
 * color available in the Figma colors!
 */
exports.BRAND_TURQUOISE = "#80DDCD";
exports.BRAND_PINK = "#CA367E";

/*
 * Legacy colors
 *
 * These are here because existing code uses them, do not use them for new
 * code. Instead, use the colors above that are sourced from the Figma design
 * palette.
 *
 * Many colors below are exact or close matches for design palette colors, and
 * are noted as deprecated or avoid, respectively.
 *
 * These colors should be replaced with design palette colors as code is updated.
 */
exports.SEC_FONT_GREY = "#9b9b9b";
exports.BLUE_DARK = "#2955a2";
exports.SECONDARY_BLUE = "#36569E";
exports.WHITE_GRAY = "#F5F5F5";
exports.GRAY = "#64666c";
exports.GOLD = "#FEEFB3";
exports.NEW_BLUE = "#2B56A3";
exports.DARK_BLUE = "#1570EF"; // Deprecated, use PRIMARY_600 instead
exports.LIGHT_BLUE = "#64666C";
exports.HEADER_BLUE = "#4776C7";
exports.PRIMARY_BLUE = "#1570EF"; // Deprecated, use PRIMARY_600 instead
exports.BRAND_BLUE = "#3073E2";
exports.BLUE = "#2F549B";
exports.BLUE_2 = "#1570ef";
exports.BLUE_3 = "#175CD3"; // Deprecated, use PRIMARY_700 instead
exports.BLUE_4 = "#1890ff";
exports.LIGHT_BLUE_2 = "#EFF8FF"; // Deprecated, use PRIMARY_50 instead
exports.LIGHT_BLUE_3 = "#2E90FA"; // Deprecated, use PRIMARY_500 instead
exports.LIGHT_BLUE_4 = "#84caff";
exports.LIGHT_BLUE_6 = "#b2ddff";
exports.LIGHT_BLUE_5 = "#e3f3ff"; // Avoid, prefer PRIMARY_50
exports.PURPLE = "#3F51B5";
exports.GREEN = "#80DDCD"; // Deprecated, use TM_GREEN instead
exports.STRONG_GREEN = "#3FCA89";
exports.LIGHT_GRAY = "#E1E1E1";
exports.LIGHT_GRAY2 = "#344054"; // Deprecated, use GRAY_700 instead
exports.LIGHT_GRAY3 = "#F5FAFF"; // Deprecated, use PRIMARY_25 instead
exports.LIGHT_GRAY4 = "#BBBBBB";

exports.GREEN_DARK = "#54cda8";
exports.GREEN_DARK2 = "#054F31"; // Deprecated, use SUCCESS_900 instead
exports.GREEN_DARK3 = "#0e9384";
exports.LIGHT_GREEN = "#2ED3B7";

exports.FADE_GREEN = "#DEF9F4";
exports.RED = "#FF0000"; // Avoid, use ERROR_600 instead
exports.RED_DARK = "#D92D20"; // Deprecated, use ERROR_600 instead
exports.ORANGE = "#F85D5D";
exports.WARNING_YELLOW = "#f5a623";
exports.WARNING_YELLOW2 = "#FEF0C7"; // Deprecated, use WARNING_100 instead

exports.DELETE_GREY = "#D9D9D9";
exports.DARK_GRAY = "#727272";
exports.DARK_GRAY2 = "#D0D5DD"; // Deprecated, use GRAY_300 instead
exports.DARK_GRAY3 = "#667085"; // Deprecated, use GRAY_500 instead
exports.DARK_GRAY4 = "#344054"; // Deprecated, use GRAY_700 instead
exports.WHITE_90 = "#FFFFFFE6";
exports.DARK_BLACK = "#191938";
exports.RAISIN_BLACK_15 = "#22242626";
exports.AERO = "#85c0ee";
exports.GAINSBORO = "#DEDEDE";
exports.DARK_LIVER = "#525055";
exports.WARNING_RED = "#f75d70";
exports.WARNING_RED2 = "#B42318"; // Deprecated, use ERROR_700 instead
exports.WARNING_RED3 = "#FEE4E2"; // Deprecated, use ERROR_100 instead
exports.WARNING_RED4 = "#B54708"; // Deprecated, use WARNING_700 instead
exports.WARNING_RED5 = "#FDA29B"; // Deprecated, use ERROR_300 instead
exports.WARNING_RED6 = "#FFFBFA"; // Deprecated, use ERROR_25 instead
exports.WHITE = "#FFFFFF";
exports.CULTURED = "#F3F3F3";
exports.PLATINUM = "#E7E7E7";
exports.OLD_GOLD = "#D8C453";
exports.DIM_GRAY = "#64666C"; // Deprecated, use LIGHT_BLUE instead
exports.BLACK = "#000";
exports.BLACK_99 = "#00000099";
exports.GHOST_WHITE = "#f4f7fd";
exports.GRAY_X11 = "#BDBDBD";
exports.GRAY_PLACEHOLDER = "#979797";
exports.DAVYS_GRAY = "#4f4f4f";
exports.SONIC_SILVER = "#787878";
exports.LIGHT_PERIWINKLE = "#C9D4E7";
exports.ALICE_BLUE = "#eff7ff";
exports.ALICE_LIGHTER_BLUE = "#ebeff5";
exports.TEXT1 = "#202020";
exports.TEXT2 = "#525252";
exports.TEXT3 = "#A0A0A0";
exports.TEXT4 = "#475467"; // Deprecated, use GRAY_600 instead
exports.GRAY1 = "#333333";
exports.GRAY2 = "#4F4F4F";
exports.GRAY3 = "#828282";
exports.GRAY4 = "#C4C4C4";
exports.GRAY5 = "#E0E0E0";
exports.GRAY6 = "#F2F2F2";
exports.GRAY7 = "#F2F4F7"; // Deprecated, use GRAY_100 instead
exports.GRAY8 = "#1D2939"; // Deprecated, use GRAY_800 instead
exports.GRAY9 = "#F9FAFB"; // Deprecated, use GRAY_50 instead
exports.GRAY10 = "#98A2B3"; // Deprecated, use GRAY_400 instead
exports.GRAY11 = "#EAECF0";
exports.GRAY12 = "#E4E7EC"; // Deprecated, use GRAY_200 instead
exports.WARNING = "#FFAA15";
exports.WARNING2 = "#DC6803"; // Deprecated, use WARNING_600 instead
exports.GREEN_GRAY = "#EEF4F3";
exports.GREEN_1 = "#7F7F7F";
exports.ERROR = "#FA4E4E";
exports.OK = "#00C781";

exports.HIGHLIGHT_COLOR = "rgba(255, 0, 124, 0.1)";
exports.DISABLED_BLUE = "#B4CBF3";
exports.DISABLED_GREEN = "#BFDEDA";
